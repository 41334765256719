import Tabs, { TabPaneProps, TabsProps } from 'antd/lib/tabs';
import React from 'react';
import './index.scss';

export const ButtonTabs = (props: TabsProps) => {
  return (
    <Tabs className={'button__tabs'} {...props}>
      {(props as any).children}
    </Tabs>
  );
};

export class ButtonTabPane extends React.Component<TabPaneProps> {
  render() {
    return <Tabs.TabPane {...this.props}>{this.props.children}</Tabs.TabPane>;
  }
}
