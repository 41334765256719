import { OutgoingInvoiceDetail } from '@/apis/einvoice/interfaces';
import { InvoiceTypeCodeLabel } from '@/components';
import { Close } from '@/components/Buttons';
import InvoiceAnswer from '@/components/InvoiceAnswer';
import { ErrorViewer } from '@/components/TableBody';
import { DocumentModal } from '@/interfaces';
import { AnyType } from '@/type';
import { faListAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton, Table, Tabs } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import _ from 'lodash';
import React, { memo } from 'react';
import intl from 'react-intl-universal';

const Body = ({ data }: { data: OutgoingInvoiceDetail }) => {
  const invoiceInfo = [
    {
      properties: `UUID (${intl.get('UUID')})`,
      value: data.id
    },
    {
      properties: intl.get('OLUSTURMA_ZAMANI'),
      value: data.createdAt
    },
    {
      properties: intl.get('BELGE_TARIHI'),
      value: data.issueDate
    },
    {
      properties: intl.get('BELGE_NUMARASI'),
      value: data.documentNumber
    },
    {
      properties: intl.get('BELGE_TIPI'),
      value: <InvoiceTypeCodeLabel profileId={data.profileId} invoiceTypeCode={data.invoiceTypeCode} />
    },
    {
      properties: intl.get('FATURA_SENARYOSU'),
      value: data.profileId
    },
    {
      properties: intl.get('PARA_BIRIMI'),
      value: data.documentCurrencyCode
    }
  ];

  let envelopeInfo: AnyType = [];

  if (data.outgoingEnvelope !== null) {
    envelopeInfo = [
      {
        properties: 'ID',
        value: data.outgoingEnvelope.id
      },
      {
        properties: intl.get('OLUSTURMA_ZAMANI'),
        value: data.outgoingEnvelope.createdAt
      },
      {
        properties: intl.get('ZARF_NUMARASI'),
        value: data.outgoingEnvelope.instanceIdentifier
      },
      {
        properties: intl.get('ZARF_DURUM_KODU'),
        value: data.outgoingEnvelope.code
      },
      {
        properties: intl.get('ZARF_DURUM_ACIKLAMASI'),
        value: data.outgoingEnvelope.description
      },
      {
        properties: intl.get('GONDERICI_BIRIM_ETIKET'),
        value: data.outgoingEnvelope.senderIdentifier
      },
      {
        properties: intl.get('ALICI_POSTA_KUTUSU_ETIKETI'),
        value: data.outgoingEnvelope.receiverIdentifier
      }
    ];
  }

  let answerInfo: AnyType[] = [];
  if (data.incomingAnswer) {
    answerInfo = [
      {
        properties: intl.get('CEVAP'),
        value: <InvoiceAnswer answer={data.incomingAnswer.documentAnswer} />
      },
      {
        properties: intl.get('FATURA_CEVABI'),
        value: data.incomingAnswer.answerNote
      },
      {
        properties: intl.get('HATA_ACIKLAMASI'),
        value: data.incomingAnswer.errorDescription === null ? '' : data.incomingAnswer.errorDescription
      }
    ];

    if (data.incomingAnswer.incomingAnswerEnvelope) {
      answerInfo.push([
        {
          properties: intl.get('CEVAP_KODU'),
          value: data.incomingAnswer.incomingAnswerEnvelope.code
        },
        {
          properties: intl.get('CEVAP_ACIKLAMASI'),
          value: data.incomingAnswer.incomingAnswerEnvelope.description
        },
        {
          properties: intl.get('CEVAP_ZARF_NUMARASI'),
          value: data.incomingAnswer.incomingAnswerEnvelope.instanceIdentifier
        }
      ]);
    }
  }

  return (
    <Tabs defaultActiveKey="1">
      <Tabs.TabPane tab={intl.get('BELGE_BILGILERI')} key="1">
        <Table dataSource={invoiceInfo} bordered pagination={false}>
          <Table.Column key="properties" title={intl.get('BASLIK')} dataIndex="properties" />
          <Table.Column key="value" title={intl.get('DEGER')} dataIndex="value" />
        </Table>
      </Tabs.TabPane>

      {data.outgoingEnvelope !== null && (
        <Tabs.TabPane tab={intl.get('ZARF_BILGILERI')} key="2">
          <Table dataSource={envelopeInfo} bordered pagination={false}>
            <Table.Column key="properties" title={intl.get('BASLIK')} dataIndex="properties" />
            <Table.Column key="value" title={intl.get('DEGER')} dataIndex="value" />
          </Table>
        </Tabs.TabPane>
      )}

      {data.incomingAnswer !== null && (
        <Tabs.TabPane tab={intl.get('CEVAP_BILGILERI')} key="3">
          <Table dataSource={answerInfo} bordered pagination={false}>
            <Table.Column key="properties" title={intl.get('BASLIK')} dataIndex="properties" />
            <Table.Column key="value" title={intl.get('DEGER')} dataIndex="value" />
          </Table>
        </Tabs.TabPane>
      )}
    </Tabs>
  );
};

export const OutgoingInvoiceDetailModal = memo(
  ({ closeModal, visible, documentNumber, uuid, data, loading, error }: DocumentModal<OutgoingInvoiceDetail>) => (
    <Modal
      centered
      title={
        <span>
          <FontAwesomeIcon icon={faListAlt} /> {intl.get('BELGE_DETAYLARI')} {error && ` - ${documentNumber} - ${uuid}`}
        </span>
      }
      onCancel={closeModal}
      width={1024}
      visible={visible}
      footer={[<Close key="close-button" onClick={closeModal} />]}
    >
      {data && <Body data={data} />}
      {loading && <Skeleton active />}
      {error && <ErrorViewer error={error} />}
    </Modal>
  ),
  (prev, next) => _.isEqual(prev.data, next.data) && prev.visible === next.visible && prev.loading === next.loading
);
