import { DynamicNotificationRuleModel } from '@/apis/einvoice/interfaces';
import { Close } from '@/components/Buttons';
import { TableDefaultLoading } from '@/components/TableBody';
import { DynamicNotification } from '@/interfaces';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { faArrowsH, faMailBulk, faSearch, faTriangle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Modal, Row, Table, Tabs } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import { TabFeedback } from '..';

export interface PreviewRuleDetailModalProps {
  visible: boolean;
  rules?: DynamicNotificationRuleModel;
}

export const PreviewRuleDetailModal = ({
  modalState: { visible, rules },
  closeModal,
  properties
}: {
  modalState: PreviewRuleDetailModalProps;
  closeModal: () => void;
  properties: Array<DynamicNotification>;
}) => {
  return (
    <Modal
      title={
        <span>
          <FontAwesomeIcon icon={faSearch} className="mr-2" /> Kural Detayları - {rules && rules.ruleName}
        </span>
      }
      onCancel={closeModal}
      centered
      width={800}
      transitionName="fade"
      maskClosable={false}
      visible={visible}
      footer={[
        <React.Fragment key="xslt-upsert-modal-footer">
          <Close onClick={closeModal} />
        </React.Fragment>
      ]}
      destroyOnClose
    >
      <Tabs defaultActiveKey="1" animated={false} type="card">
        <Tabs.TabPane key="1" tab={<TabFeedback icon={faTriangle} title={intl.get('KURALLAR')} isValid={undefined} />}>
          <Row>
            <Col sm={24}>
              <Table
                style={{ marginTop: '10px' }}
                loading={TableDefaultLoading(false)}
                bordered
                pagination={false}
                dataSource={rules?.details}
                rowKey={(value) => value.operator}
                columns={[
                  {
                    title: 'Özellik',
                    dataIndex: 'property',
                    key: 'property',
                    render: (value) => {
                      // eslint-disable-next-line react/prop-types
                      const propertyDetail = properties.find((x) => x.property.value === value);

                      return (
                        <div>
                          {propertyDetail?.property.icon}
                          <span>{propertyDetail?.property.text}</span>
                        </div>
                      );
                    }
                  },
                  {
                    title: 'Koşul Operatörü',
                    dataIndex: 'operator',
                    key: 'operator',
                    render: (value) => {
                      const operatorDetail = properties
                        // eslint-disable-next-line react/prop-types
                        .find((x) => x.operators.find((item) => item.value === value))
                        ?.operators.find((item) => item.value === value);

                      return (
                        <div>
                          {operatorDetail?.icon}
                          <span>{operatorDetail?.text}</span>
                        </div>
                      );
                    }
                  },
                  {
                    title: 'Değer',
                    key: 'values',
                    render: (value) => {
                      if (value.operator === 'Between')
                        return (
                          <b>
                            {value.value1} <FontAwesomeIcon icon={faArrowsH} /> {value.value2}
                          </b>
                        );
                      if (typeof value.value1) return <b>{value.value1}</b>;
                    }
                  }
                ]}
              />
            </Col>
          </Row>
        </Tabs.TabPane>

        <Tabs.TabPane key="2" tab={<TabFeedback icon={faMailBulk} title={intl.get('ALICILAR')} isValid={undefined} />}>
          <Table
            style={{ marginTop: '10px', width: '100%' }}
            loading={TableDefaultLoading(false)}
            bordered
            pagination={false}
            dataSource={rules?.receivers}
            rowKey={(record) => record.receiverValue}
            columns={[
              {
                title: 'E-electronicMail',
                dataIndex: 'receiverValue',
                key: 'receiverValue',

                render: (value) => {
                  return (
                    <div>
                      <FontAwesomeIcon className={'mr-2'} icon={faEnvelope} />
                      {value}
                    </div>
                  );
                }
              }
            ]}
          ></Table>
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};

export default PreviewRuleDetailModal;
