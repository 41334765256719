import moment from 'moment';
import React, { memo } from 'react';
import intl from 'react-intl-universal';

export const DateRange = memo(({ startDate, endDate, format }: { startDate: string | Date; endDate: string | Date; format?: string }) => {
  return (
    <div>
      <div>
        <b>{intl.get('BASLANGIC_TARIHI')} : </b> <span>{moment(startDate).format(format || 'DD.MM.YYYY HH:mm')}</span>
      </div>
      <div>
        <b>{intl.get('BITIS_TARIHI')} : </b> <span>{moment(endDate).format(format || 'DD.MM.YYYY HH:mm')}</span>
      </div>
    </div>
  );
});

export default DateRange;
