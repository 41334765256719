import { PartyType } from './cac';

export const NS_CBC = 'urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2';
export const NS_CAC = 'urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2';
export const NS_INV = 'urn:oasis:names:specification:ubl:schema:xsd:Invoice-2';
export const NS_DES = 'urn:oasis:names:specification:ubl:schema:xsd:DespatchAdvice-2';
export const NS_REC = 'urn:oasis:names:specification:ubl:schema:xsd:ReceiptAdvice-2';
export const NS_EMM = 'urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2';
export const NS_SMM = 'urn:oasis:names:specification:ubl:schema:xsd:FreelancerVoucher-2';

export const DefaultValue = {} as any;

export const GTB_PARTY: PartyType = {
  partyIdentification: [{ id: { schemeID: 'VKN', value: '1460415308' } }],
  partyName: { name: 'Gümrük ve Ticaret Bakanlığı Gümrükler Genel Müdürlüğü- Bilgi İşlem Dairesi Başkanlığı' },
  postalAddress: {
    streetName: '',
    citySubdivisionName: { value: undefined },
    cityName: { value: 'Ankara' },
    country: { name: { value: 'Türkiye' } }
  },
  partyTaxScheme: {
    taxScheme: {
      name: 'Ulus'
    }
  },
  contact: undefined
};
