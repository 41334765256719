import React from 'react';
import AccountSelector from './AccountSelector';
import CreditSummaryComponent from './CreditSummary';
import LanguageSwitcher from './LanguageSwitcher';
import UserMenu from './UserMenu';
import style from './style.module.scss';
import { Button } from 'antd';
import { BookOutlined } from '@ant-design/icons';
import { WORKING_ENVIRONMENT } from '@/config';

const TopBar = () => {
  return (
    <div className={style.topbar}>
      <div className="mr-md-4 mr-auto">
        {/* <Search /> */}
        <CreditSummaryComponent />
      </div>

      <div className="mr-auto d-none d-md-block">{/* <IssuesHistory /> */}</div>
      <div className="mb-0 mr-4 d-xl-block d-none" style={{ width: 300 }}>
        <AccountSelector />
      </div>
      <div className="mr-4 d-none d-sm-block">
        {/* <LanguageSwitcher /> */}

        <Button
          type="default"
          icon={<BookOutlined />}
          onClick={() => window.open(WORKING_ENVIRONMENT === 'Test' ? 'http://portaltest.dokuman.nes.com.tr' : 'http://portal.dokuman.nes.com.tr', '_blank')}
        >
          Kullanım Kılavuzu
        </Button>
      </div>

      <div className="">
        <UserMenu />
      </div>
    </div>
  );
};

export default TopBar;
