/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { NS_CBC } from '../constants';
import IDType from './IDType';

/*
  10.05.2021 : Partially completed
  cbc:SalesOrderLineID 
  cbc:UUID
  cbc:LineStatusCode 
  cac:OrderReference 
*/
export class OrderLineReferenceType {
  @XMLElement({ types: [{ name: 'LineID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  lineID?: IDType;

  // cbc:SalesOrderLineID
  // cbc:UUID
  // cbc:LineStatusCode
  // cac:OrderReference
}

export default OrderLineReferenceType;
