import { UserOutlined } from '@ant-design/icons';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { faSignOut } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Dropdown, Menu } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import styles from './style.module.scss';
const UserMenu = () => {
  const { oidcUser, logout } = useReactOidc();

  const menu = () => (
    <Menu selectable={false}>
      <Menu.Item>
        <strong>
          {intl.get('topBar.profileMenu.hello')}, {(oidcUser && oidcUser.profile['FullName']) || 'Anonymous'}
        </strong>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <div>
          <strong>{intl.get('topBar.profileMenu.email')}: </strong>
          {oidcUser && oidcUser.profile.email}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={() => logout()}>
          <FontAwesomeIcon icon={faSignOut} className={`${styles.menuIcon}`} />
          {intl.get('topBar.profileMenu.logout')}
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div className={styles.dropdown}>
        <Avatar className={styles.avatar} shape="square" size="large" icon={<UserOutlined />} />
      </div>
    </Dropdown>
  );
};

export default UserMenu;
