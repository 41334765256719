import yupTR from '@/constants/locales/yup.tr-TR';
import * as serviceWorker from '@/serviceWorker';
import { AuthenticationProvider, oidcLog, OidcSecure } from '@axa-fr/react-oidc-context';
import * as Sentry from '@sentry/browser';
import 'antd/lib/style/index.less'; // antd core styles
import dotenv from 'dotenv';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import Routes from 'routes';
import * as Yup from 'yup';
import './@kit/vendors/antd/themes/dark.less'; // dark theme antd components
import './@kit/vendors/antd/themes/default.less'; // default theme antd components
import { Authenticating, Callback, NotAuthenticated, NotAuthorized, SessionLost } from './components/Auth';
import { IDENTITY_CONFIG } from './constants/auth';
import { PageFilterProvider } from './contexts/PageFilterContext';
import { ThemeProvider } from './contexts/ThemeContext';
import './extensions/Date';
import './extensions/Number';
import './extensions/String';
import './global.scss'; // app & third-party component styles
import { AnyType } from './type';

Yup.setLocale(yupTR);

dotenv.config();

if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: 'https://c84f59fed8c54bd9b8b00ba23519ad71@exception.nesbilgi.com.tr/13' });
}

const AppRoutes = () => <Route path="*" render={(props: AnyType) => <Routes {...props} />} />;
const authProps = {
  configuration: IDENTITY_CONFIG,
  loggerLevel: oidcLog.DEBUG,
  notAuthenticated: NotAuthenticated,
  notAuthorized: NotAuthorized,
  authenticating: Authenticating,
  callbackComponentOverride: Callback,
  sessionLostComponent: SessionLost,
  customEvents: {
    onUserLoaded: (a, b) => {
      console.log('event_log', { a, b });
    }
  },
  onEvent: (configurationName, eventName, data) => {
    console.log('event_log', `oidc:${configurationName}:${eventName}`, data);
  }
};

ReactDOM.render(
  <AuthenticationProvider {...authProps}>
    <BrowserRouter>
      <OidcSecure>
        <ThemeProvider>
          <PageFilterProvider>
            <AppRoutes />
          </PageFilterProvider>
        </ThemeProvider>
      </OidcSecure>
    </BrowserRouter>
  </AuthenticationProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
