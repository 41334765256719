import { DateCriteria } from '@/constants/enums';
import { AnyType } from '@/type';
import moment from 'moment';
import { GroupSelectOptionItem } from '.';

/**
 * Sayfa bazında istek tercihleri bu arayüz içerisinde tutuluyor
 */
export interface PageFilter<TColumn> {
  query?: string;
  startDate?: Date;
  endDate?: Date;
  orderColumn?: TColumn;
  orderType?: 'asc' | 'desc';
  pageSize: 10 | 50 | 100 | 250;
  filters?: GroupSelectOptionItem[];
  dateCriteria?: DateCriteria;
}

export const INITIAL_IPAGEFILTER = <T1 extends AnyType>() => {
  return {
    pageSize: 10,
    startDate: moment().add(-1, 'month').toDate(),
    endDate: moment().toDate(),
    dateCriteria: DateCriteria.DocumentDate
  } as PageFilter<T1>;
};

export default PageFilter;
