import { ApiError, ApiGenericResponse } from '@/interfaces';
import { AnyType } from '@/type';
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import { useRef, useState } from 'react';

export const useApi = <TResponse, TRequest = null, TAdditionalProps = null>({
  asyncFunction,
  successCallback,
  errorCallback
}: {
  asyncFunction;
  successCallback?: (response: AxiosResponse<TResponse>, request?: TRequest, additionalProps?: TAdditionalProps) => void;
  errorCallback?: (error: AxiosError<ApiError>, request?: TRequest, additionalProps?: TAdditionalProps) => void;
}) => {
  const counter = useRef(0);
  const [value, setValue] = useState<ApiGenericResponse<TResponse>>({
    loading: false,
    data: undefined,
    error: undefined
  });

  const call = (request?: TRequest, additionalProps?: TAdditionalProps) => {
    counter.current++;
    setValue({ ...value, loading: true, error: undefined });
    return (asyncFunction(request) as AxiosPromise<TResponse>)
      .then((response: AnyType) => {
        if (response.isAxiosError) {
          if (errorCallback) errorCallback(response, request, additionalProps);
          setValue({ ...value, loading: false, data: undefined, error: response });
        } else {
          if (successCallback) successCallback(response, request, additionalProps);
          setValue({ ...value, loading: false, data: response.data as TResponse, error: undefined });
        }
      })
      .catch((error) => {
        setValue({ ...value, loading: false, data: undefined, error: error });
      });
  };

  const setData = (data: TResponse) => {
    setValue({ ...value, data, loading: false, error: undefined });
  };

  const clear = () => {
    setValue({ loading: false, data: undefined, error: undefined });
  };

  return { ...value, call, clear, setData };
};

export default useApi;
