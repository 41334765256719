// tslint:disable
// eslint:disable
/**
 * E-Invoice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Attachment } from '../interfaces';
// @ts-ignore
import { Company } from '../interfaces';
// @ts-ignore
import { DespatchDocumentReferencePaginationResult } from '../interfaces';
// @ts-ignore
import { DocumentAnswer } from '../interfaces';
// @ts-ignore
import { EmailSendDto } from '../interfaces';
// @ts-ignore
import { FileExportTypeParameter } from '../interfaces';
// @ts-ignore
import { HistoryPaginationResult } from '../interfaces';
// @ts-ignore
import { InvoiceProfileIdParameter } from '../interfaces';
// @ts-ignore
import { InvoiceTypeParameter } from '../interfaces';
// @ts-ignore
import { LucaTransferStatusParameter } from '../interfaces';
// @ts-ignore
import { Note } from '../interfaces';
// @ts-ignore
import { NoteDto } from '../interfaces';
// @ts-ignore
import { NotePaginationResult } from '../interfaces';
// @ts-ignore
import { OutgoingInvoice } from '../interfaces';
// @ts-ignore
import { OutgoingInvoiceDetail } from '../interfaces';
// @ts-ignore
import { OutgoingInvoiceInfo } from '../interfaces';
// @ts-ignore
import { OutgoingInvoicePaginationResult } from '../interfaces';
// @ts-ignore
import { OutgoingInvoiceSetParameter } from '../interfaces';
// @ts-ignore
import { ReceiverAliasPutDto } from '../interfaces';
// @ts-ignore
import { RecordStatus } from '../interfaces';
// @ts-ignore
import { TaggingDto } from '../interfaces';
// @ts-ignore
import { TaxInfoPaginationResult } from '../interfaces';
/**
 * OutgoingInvoicesApi - axios parameter creator
 * @export
 */
export const OutgoingInvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Belgelere toplu olarak yeni durum atamak için bu uç kullanılabilir.
         * @summary Yeni durum atar
         * @param {OutgoingInvoiceSetParameter} operation **İşlem**
         * @param {Array<string>} requestBody **Belge uuid listesi**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesBulkOperationPut: async (requestParameters: V1OutgoingInvoicesBulkOperationPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'operation' is not null or undefined
            if (requestParameters.operation === null || requestParameters.operation === undefined) {
                throw new RequiredError('operation','Required parameter operation was null or undefined when calling v1OutgoingInvoicesBulkOperationPut.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling v1OutgoingInvoicesBulkOperationPut.');
            }
            const localVarPath = `/v1/outgoing/invoices/bulk/{operation}`.replace(`{${"operation"}}`, encodeURIComponent(String(requestParameters.operation)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.requestBody !== undefined ? requestParameters.requestBody : {}) : (requestParameters.requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Taslak belgeleri silmek için bu uç kullanılablir
         * @param {Array<string>} requestBody #### Taslak belge UUID listesi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesDraftsDelete: async (requestParameters: V1OutgoingInvoicesDraftsDeleteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling v1OutgoingInvoicesDraftsDelete.');
            }
            const localVarPath = `/v1/outgoing/invoices/drafts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.requestBody !== undefined ? requestParameters.requestBody : {}) : (requestParameters.requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Giden e-Fatura taslaklarınıza bu uç ile ulaşabilirsiniz.
         * @summary Taslak faturaları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesDraftsGet: async (requestParameters: V1OutgoingInvoicesDraftsGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1OutgoingInvoicesDraftsGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1OutgoingInvoicesDraftsGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1OutgoingInvoicesDraftsGet.');
            }
            const localVarPath = `/v1/outgoing/invoices/drafts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.company !== undefined) {
                localVarQueryParameter['company'] = requestParameters.company;
            }
            if (requestParameters.uuid !== undefined) {
                localVarQueryParameter['uuid'] = requestParameters.uuid;
            }
            if (requestParameters.documentNumber !== undefined) {
                localVarQueryParameter['documentNumber'] = requestParameters.documentNumber;
            }
            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgelerinizi mail olarak iletmek için bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **50** farklı belge, **10** farklı mail adresi gönderilebilir.
         * @summary Belgeyi mail olarak iletir
         * @param {EmailSendDto} emailSendDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesEmailSendPost: async (requestParameters: V1OutgoingInvoicesEmailSendPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailSendDto' is not null or undefined
            if (requestParameters.emailSendDto === null || requestParameters.emailSendDto === undefined) {
                throw new RequiredError('emailSendDto','Required parameter emailSendDto was null or undefined when calling v1OutgoingInvoicesEmailSendPost.');
            }
            const localVarPath = `/v1/outgoing/invoices/email/send`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.emailSendDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.emailSendDto !== undefined ? requestParameters.emailSendDto : {}) : (requestParameters.emailSendDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgelerinizi toplu olarak dışarı aktarmak isterseniz bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **100** kayıt dışarı aktarılabilir.    **fileType** Parametre detayları    > **Xml** : Belgenizin XML halini dışarı aktarır    > **Envelope** : Belgenizin Zarf XML halini dışarı aktarır    > **Pdf** : Belgenize ait PDF görüntüsünü dışarı aktarır    > **UnifiedPdf** : ***uuids*** parametresinde gönderilen belgeleri tek PDF dosyası halinde dışarı aktarır    > **Excel** : ***uuids*** parametresinde gönderilen belgeleri Excel\'e aktarır
         * @summary Toplu aktar
         * @param {FileExportTypeParameter} fileType 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesExportFileTypePost: async (requestParameters: V1OutgoingInvoicesExportFileTypePostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileType' is not null or undefined
            if (requestParameters.fileType === null || requestParameters.fileType === undefined) {
                throw new RequiredError('fileType','Required parameter fileType was null or undefined when calling v1OutgoingInvoicesExportFileTypePost.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling v1OutgoingInvoicesExportFileTypePost.');
            }
            const localVarPath = `/v1/outgoing/invoices/export/{fileType}`.replace(`{${"fileType"}}`, encodeURIComponent(String(requestParameters.fileType)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.requestBody !== undefined ? requestParameters.requestBody : {}) : (requestParameters.requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Giden e-Faturalarınıza bu uç ile ulaşabilirsiniz.
         * @summary Faturaları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {Array<string>} [tags] Faturaya atanmış etiketlere göre filtreleme yapabilirsiniz
         * @param {string} [userNote] Kullanıcı notuna göre filtreleme yapabilirsiniz
         * @param {string} [documentNote] Belge içinde geçen nota göre filtreleme yapabilirsiniz
         * @param {string} [despatchNumber] İrsaliye numarasına göre filtreleme yapabilirsiniz
         * @param {string} [orderNumber] Sipariş numarasına göre filtreleme yapabilirsiniz
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {string} [startCreateDate] Oluşturma tarihi başlangıcı
         * @param {string} [endCreateDate] Oluşturma tarihi sonu
         * @param {RecordStatus} [invoiceStatus] Fatura durumuna göre filtreleme yapabilirsiniz
         * @param {boolean} [archived] Arşivlenen belgeleri göster
         * @param {InvoiceProfileIdParameter} [profileId] Fatura profiline göre filtreleme yapabilirsiniz
         * @param {InvoiceTypeParameter} [invoiceTypeCode] Fatura tipine göre filtreleme yapabilirsiniz
         * @param {DocumentAnswer} [documentAnswer] Cevap durumuna göre filtreleme yapabilirsiniz
         * @param {LucaTransferStatusParameter} [lucaTransferStatus] 
         * @param {string} [startDate] Başlangıç tarihi
         * @param {string} [endDate] Bitiş tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesGet: async (requestParameters: V1OutgoingInvoicesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1OutgoingInvoicesGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1OutgoingInvoicesGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1OutgoingInvoicesGet.');
            }
            const localVarPath = `/v1/outgoing/invoices`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.tags) {
                localVarQueryParameter['tags'] = requestParameters.tags;
            }
            if (requestParameters.userNote !== undefined) {
                localVarQueryParameter['userNote'] = requestParameters.userNote;
            }
            if (requestParameters.documentNote !== undefined) {
                localVarQueryParameter['documentNote'] = requestParameters.documentNote;
            }
            if (requestParameters.despatchNumber !== undefined) {
                localVarQueryParameter['despatchNumber'] = requestParameters.despatchNumber;
            }
            if (requestParameters.orderNumber !== undefined) {
                localVarQueryParameter['orderNumber'] = requestParameters.orderNumber;
            }
            if (requestParameters.company !== undefined) {
                localVarQueryParameter['company'] = requestParameters.company;
            }
            if (requestParameters.uuid !== undefined) {
                localVarQueryParameter['uuid'] = requestParameters.uuid;
            }
            if (requestParameters.documentNumber !== undefined) {
                localVarQueryParameter['documentNumber'] = requestParameters.documentNumber;
            }
            if (requestParameters.startCreateDate !== undefined) {
                localVarQueryParameter['startCreateDate'] = (requestParameters.startCreateDate as any instanceof Date) ?
                    (requestParameters.startCreateDate as any).toISOString() : requestParameters.startCreateDate;
            }
            if (requestParameters.endCreateDate !== undefined) {
                localVarQueryParameter['endCreateDate'] = (requestParameters.endCreateDate as any instanceof Date) ?
                    (requestParameters.endCreateDate as any).toISOString() : requestParameters.endCreateDate;
            }
            if (requestParameters.invoiceStatus !== undefined) {
                localVarQueryParameter['invoiceStatus'] = requestParameters.invoiceStatus;
            }
            if (requestParameters.archived !== undefined) {
                localVarQueryParameter['archived'] = requestParameters.archived;
            }
            if (requestParameters.profileId !== undefined) {
                localVarQueryParameter['profileId'] = requestParameters.profileId;
            }
            if (requestParameters.invoiceTypeCode !== undefined) {
                localVarQueryParameter['invoiceTypeCode'] = requestParameters.invoiceTypeCode;
            }
            if (requestParameters.documentAnswer !== undefined) {
                localVarQueryParameter['documentAnswer'] = requestParameters.documentAnswer;
            }
            if (requestParameters.lucaTransferStatus !== undefined) {
                localVarQueryParameter['lucaTransferStatus'] = requestParameters.lucaTransferStatus;
            }
            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }
            if (requestParameters.startDate !== undefined) {
                localVarQueryParameter['startDate'] = requestParameters.startDate;
            }
            if (requestParameters.endDate !== undefined) {
                localVarQueryParameter['endDate'] = requestParameters.endDate;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Giden son 10 faturaya bu uç ile ulaşabilirsiniz.
         * @summary Giden son 10 faturayı listeler
         * @param {RecordStatus} [invoiceStatus] Belge durumu
         * @param {boolean} [archived] Arşivlenen belgeleri göster
         * @param {InvoiceProfileIdParameter} [profileId] Fatura profiline göre filtreleme yapabilirsiniz
         * @param {InvoiceTypeParameter} [invoiceTypeCode] Fatura tipine göre filtreleme yapabilirsiniz
         * @param {DocumentAnswer} [documentAnswer] Cevap durumuna göre filtreleme yapabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesLastGet: async (requestParameters: V1OutgoingInvoicesLastGetRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/outgoing/invoices/last`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.invoiceStatus !== undefined) {
                localVarQueryParameter['invoiceStatus'] = requestParameters.invoiceStatus;
            }
            if (requestParameters.archived !== undefined) {
                localVarQueryParameter['archived'] = requestParameters.archived;
            }
            if (requestParameters.profileId !== undefined) {
                localVarQueryParameter['profileId'] = requestParameters.profileId;
            }
            if (requestParameters.invoiceTypeCode !== undefined) {
                localVarQueryParameter['invoiceTypeCode'] = requestParameters.invoiceTypeCode;
            }
            if (requestParameters.documentAnswer !== undefined) {
                localVarQueryParameter['documentAnswer'] = requestParameters.documentAnswer;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye etiket eklemek veya çıkarmak için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **100** belgeye tag eklenebilir/çıkarılabilir.
         * @summary Etiket ekler/çıkarır
         * @param {TaggingDto} taggingDto #### Etiket eklemek veya çıkarmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesTagsPut: async (requestParameters: V1OutgoingInvoicesTagsPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'taggingDto' is not null or undefined
            if (requestParameters.taggingDto === null || requestParameters.taggingDto === undefined) {
                throw new RequiredError('taggingDto','Required parameter taggingDto was null or undefined when calling v1OutgoingInvoicesTagsPut.');
            }
            const localVarPath = `/v1/outgoing/invoices/tags`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.taggingDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.taggingDto !== undefined ? requestParameters.taggingDto : {}) : (requestParameters.taggingDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye ait ek dokumanların listesine bu uç ile ulaşabilirsiniz.
         * @summary Belge eklerini listeler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidAttachmentsGet: async (requestParameters: V1OutgoingInvoicesUuidAttachmentsGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1OutgoingInvoicesUuidAttachmentsGet.');
            }
            const localVarPath = `/v1/outgoing/invoices/{uuid}/attachments`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye ait ek dokumanların indirilmesi için bu uç kullanılabilir.
         * @summary Belge ekini indirir
         * @param {string} uuid **Belge uuid**
         * @param {number} index **Ek belge sırası**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidAttachmentsIndexGet: async (requestParameters: V1OutgoingInvoicesUuidAttachmentsIndexGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1OutgoingInvoicesUuidAttachmentsIndexGet.');
            }
            // verify required parameter 'index' is not null or undefined
            if (requestParameters.index === null || requestParameters.index === undefined) {
                throw new RequiredError('index','Required parameter index was null or undefined when calling v1OutgoingInvoicesUuidAttachmentsIndexGet.');
            }
            const localVarPath = `/v1/outgoing/invoices/{uuid}/attachments/{index}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))).replace(`{${"index"}}`, encodeURIComponent(String(requestParameters.index)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belge içerisinde belirtilen irsaliye bilgilerine bu uç ile ulaşabilirsiniz.
         * @summary İrsaliye bilgilerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidDespatchesGet: async (requestParameters: V1OutgoingInvoicesUuidDespatchesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1OutgoingInvoicesUuidDespatchesGet.');
            }
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1OutgoingInvoicesUuidDespatchesGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1OutgoingInvoicesUuidDespatchesGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1OutgoingInvoicesUuidDespatchesGet.');
            }
            const localVarPath = `/v1/outgoing/invoices/{uuid}/despatches`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye ait Zarf XML dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary Zarf XML İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidEnvelopeGet: async (requestParameters: V1OutgoingInvoicesUuidEnvelopeGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1OutgoingInvoicesUuidEnvelopeGet.');
            }
            const localVarPath = `/v1/outgoing/invoices/{uuid}/envelope`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Belge detaylarını getirir
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidGet: async (requestParameters: V1OutgoingInvoicesUuidGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1OutgoingInvoicesUuidGet.');
            }
            const localVarPath = `/v1/outgoing/invoices/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sorgulanan belgeye ait işlem geçmişine bu uç ile ulaşabilirsiniz.
         * @summary İşlem geçmişini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidHistoriesGet: async (requestParameters: V1OutgoingInvoicesUuidHistoriesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1OutgoingInvoicesUuidHistoriesGet.');
            }
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1OutgoingInvoicesUuidHistoriesGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1OutgoingInvoicesUuidHistoriesGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1OutgoingInvoicesUuidHistoriesGet.');
            }
            const localVarPath = `/v1/outgoing/invoices/{uuid}/histories`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye ait HTML görüntüye bu uç ile ulaşabilirsiniz.
         * @summary Belgeyi görüntüler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidHtmlGet: async (requestParameters: V1OutgoingInvoicesUuidHtmlGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1OutgoingInvoicesUuidHtmlGet.');
            }
            const localVarPath = `/v1/outgoing/invoices/{uuid}/html`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Giden e-Faturaya ait diğer bilgilere bu uç ile ulaşabilirsiniz.    > İhracat faturalarında GTP Ref No vb. gibi bilgiler
         * @summary Belgenin diğer bilgileri
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidInfosGet: async (requestParameters: V1OutgoingInvoicesUuidInfosGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1OutgoingInvoicesUuidInfosGet.');
            }
            const localVarPath = `/v1/outgoing/invoices/{uuid}/infos`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sorgulanan belge içerisindeki mail adreslerinin listesine bu uç ile ulaşabilirsiniz.
         * @summary Mail adreslerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidMailaddressesGet: async (requestParameters: V1OutgoingInvoicesUuidMailaddressesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1OutgoingInvoicesUuidMailaddressesGet.');
            }
            const localVarPath = `/v1/outgoing/invoices/{uuid}/mailaddresses`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye ait PDF dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary PDF İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidPdfGet: async (requestParameters: V1OutgoingInvoicesUuidPdfGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1OutgoingInvoicesUuidPdfGet.');
            }
            const localVarPath = `/v1/outgoing/invoices/{uuid}/pdf`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Taslak belgelerin alıcı etiketi bu uç ile güncellenebilir
         * @param {string} uuid 
         * @param {ReceiverAliasPutDto} receiverAliasPutDto #### Taslak belge alıcı etiketi güncellemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidReceiveraliasPut: async (requestParameters: V1OutgoingInvoicesUuidReceiveraliasPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1OutgoingInvoicesUuidReceiveraliasPut.');
            }
            // verify required parameter 'receiverAliasPutDto' is not null or undefined
            if (requestParameters.receiverAliasPutDto === null || requestParameters.receiverAliasPutDto === undefined) {
                throw new RequiredError('receiverAliasPutDto','Required parameter receiverAliasPutDto was null or undefined when calling v1OutgoingInvoicesUuidReceiveraliasPut.');
            }
            const localVarPath = `/v1/outgoing/invoices/{uuid}/receiveralias`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.receiverAliasPutDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.receiverAliasPutDto !== undefined ? requestParameters.receiverAliasPutDto : {}) : (requestParameters.receiverAliasPutDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gelen belgeler için göndericiyi, giden belgeler içinse alıcıyı **Firma Listesi**\'ne kaydetmek için bu uç kullanılabilir.
         * @summary Firma olarak kaydet
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidSavecompanyindocumentPost: async (requestParameters: V1OutgoingInvoicesUuidSavecompanyindocumentPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1OutgoingInvoicesUuidSavecompanyindocumentPost.');
            }
            const localVarPath = `/v1/outgoing/invoices/{uuid}/savecompanyindocument`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belge içerisinde belirtilen vergi bilgilerine bu uç ile ulaşabilirsiniz.
         * @summary Vergi bilgilerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidTaxesGet: async (requestParameters: V1OutgoingInvoicesUuidTaxesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1OutgoingInvoicesUuidTaxesGet.');
            }
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1OutgoingInvoicesUuidTaxesGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1OutgoingInvoicesUuidTaxesGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1OutgoingInvoicesUuidTaxesGet.');
            }
            const localVarPath = `/v1/outgoing/invoices/{uuid}/taxes`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sorgulanan belgeye ait kullanıcı tarafından eklenmiş notlara bu uç ile ulaşabilirsiniz.
         * @summary Kullanıcı notu listeler
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidUsernotesGet: async (requestParameters: V1OutgoingInvoicesUuidUsernotesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1OutgoingInvoicesUuidUsernotesGet.');
            }
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1OutgoingInvoicesUuidUsernotesGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1OutgoingInvoicesUuidUsernotesGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1OutgoingInvoicesUuidUsernotesGet.');
            }
            const localVarPath = `/v1/outgoing/invoices/{uuid}/usernotes`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Kullanıcı tarafından oluşturulmuş olan notu silmek için bu uç kullanılabilir.
         * @summary Kullanıcı notunu siler
         * @param {string} uuid **Belge uuid**
         * @param {string} id **Not id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidUsernotesIdDelete: async (requestParameters: V1OutgoingInvoicesUuidUsernotesIdDeleteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1OutgoingInvoicesUuidUsernotesIdDelete.');
            }
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1OutgoingInvoicesUuidUsernotesIdDelete.');
            }
            const localVarPath = `/v1/outgoing/invoices/{uuid}/usernotes/{id}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye daha önce eklenmiş olan kullanıcı notunu güncellemek için bu uç kullanılabilir.
         * @summary Kullanıcı notunu günceller
         * @param {string} uuid #### Belgeye ait uuid
         * @param {string} id #### Not id
         * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidUsernotesIdPut: async (requestParameters: V1OutgoingInvoicesUuidUsernotesIdPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1OutgoingInvoicesUuidUsernotesIdPut.');
            }
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1OutgoingInvoicesUuidUsernotesIdPut.');
            }
            // verify required parameter 'noteDto' is not null or undefined
            if (requestParameters.noteDto === null || requestParameters.noteDto === undefined) {
                throw new RequiredError('noteDto','Required parameter noteDto was null or undefined when calling v1OutgoingInvoicesUuidUsernotesIdPut.');
            }
            const localVarPath = `/v1/outgoing/invoices/{uuid}/usernotes/{id}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.noteDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.noteDto !== undefined ? requestParameters.noteDto : {}) : (requestParameters.noteDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye kullanıcı notu eklemek için bu uç kullanılabilir.
         * @summary Kullanıcı notu ekler
         * @param {string} uuid #### Belge uuid
         * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidUsernotesPost: async (requestParameters: V1OutgoingInvoicesUuidUsernotesPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1OutgoingInvoicesUuidUsernotesPost.');
            }
            // verify required parameter 'noteDto' is not null or undefined
            if (requestParameters.noteDto === null || requestParameters.noteDto === undefined) {
                throw new RequiredError('noteDto','Required parameter noteDto was null or undefined when calling v1OutgoingInvoicesUuidUsernotesPost.');
            }
            const localVarPath = `/v1/outgoing/invoices/{uuid}/usernotes`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.noteDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.noteDto !== undefined ? requestParameters.noteDto : {}) : (requestParameters.noteDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye ait XML dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary XML İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidXmlGet: async (requestParameters: V1OutgoingInvoicesUuidXmlGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1OutgoingInvoicesUuidXmlGet.');
            }
            const localVarPath = `/v1/outgoing/invoices/{uuid}/xml`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OutgoingInvoicesApi - functional programming interface
 * @export
 */
export const OutgoingInvoicesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Belgelere toplu olarak yeni durum atamak için bu uç kullanılabilir.
         * @summary Yeni durum atar
         * @param {OutgoingInvoiceSetParameter} operation **İşlem**
         * @param {Array<string>} requestBody **Belge uuid listesi**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingInvoicesBulkOperationPut(requestParameters: V1OutgoingInvoicesBulkOperationPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OutgoingInvoicesApiAxiosParamCreator(configuration).v1OutgoingInvoicesBulkOperationPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Taslak belgeleri silmek için bu uç kullanılablir
         * @param {Array<string>} requestBody #### Taslak belge UUID listesi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingInvoicesDraftsDelete(requestParameters: V1OutgoingInvoicesDraftsDeleteRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OutgoingInvoicesApiAxiosParamCreator(configuration).v1OutgoingInvoicesDraftsDelete(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Giden e-Fatura taslaklarınıza bu uç ile ulaşabilirsiniz.
         * @summary Taslak faturaları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingInvoicesDraftsGet(requestParameters: V1OutgoingInvoicesDraftsGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutgoingInvoicePaginationResult>> {
            const localVarAxiosArgs = await OutgoingInvoicesApiAxiosParamCreator(configuration).v1OutgoingInvoicesDraftsGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgelerinizi mail olarak iletmek için bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **50** farklı belge, **10** farklı mail adresi gönderilebilir.
         * @summary Belgeyi mail olarak iletir
         * @param {EmailSendDto} emailSendDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingInvoicesEmailSendPost(requestParameters: V1OutgoingInvoicesEmailSendPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OutgoingInvoicesApiAxiosParamCreator(configuration).v1OutgoingInvoicesEmailSendPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgelerinizi toplu olarak dışarı aktarmak isterseniz bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **100** kayıt dışarı aktarılabilir.    **fileType** Parametre detayları    > **Xml** : Belgenizin XML halini dışarı aktarır    > **Envelope** : Belgenizin Zarf XML halini dışarı aktarır    > **Pdf** : Belgenize ait PDF görüntüsünü dışarı aktarır    > **UnifiedPdf** : ***uuids*** parametresinde gönderilen belgeleri tek PDF dosyası halinde dışarı aktarır    > **Excel** : ***uuids*** parametresinde gönderilen belgeleri Excel\'e aktarır
         * @summary Toplu aktar
         * @param {FileExportTypeParameter} fileType 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingInvoicesExportFileTypePost(requestParameters: V1OutgoingInvoicesExportFileTypePostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OutgoingInvoicesApiAxiosParamCreator(configuration).v1OutgoingInvoicesExportFileTypePost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Giden e-Faturalarınıza bu uç ile ulaşabilirsiniz.
         * @summary Faturaları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {Array<string>} [tags] Faturaya atanmış etiketlere göre filtreleme yapabilirsiniz
         * @param {string} [userNote] Kullanıcı notuna göre filtreleme yapabilirsiniz
         * @param {string} [documentNote] Belge içinde geçen nota göre filtreleme yapabilirsiniz
         * @param {string} [despatchNumber] İrsaliye numarasına göre filtreleme yapabilirsiniz
         * @param {string} [orderNumber] Sipariş numarasına göre filtreleme yapabilirsiniz
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {string} [startCreateDate] Oluşturma tarihi başlangıcı
         * @param {string} [endCreateDate] Oluşturma tarihi sonu
         * @param {RecordStatus} [invoiceStatus] Fatura durumuna göre filtreleme yapabilirsiniz
         * @param {boolean} [archived] Arşivlenen belgeleri göster
         * @param {InvoiceProfileIdParameter} [profileId] Fatura profiline göre filtreleme yapabilirsiniz
         * @param {InvoiceTypeParameter} [invoiceTypeCode] Fatura tipine göre filtreleme yapabilirsiniz
         * @param {DocumentAnswer} [documentAnswer] Cevap durumuna göre filtreleme yapabilirsiniz
         * @param {LucaTransferStatusParameter} [lucaTransferStatus] 
         * @param {string} [startDate] Başlangıç tarihi
         * @param {string} [endDate] Bitiş tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingInvoicesGet(requestParameters: V1OutgoingInvoicesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutgoingInvoicePaginationResult>> {
            const localVarAxiosArgs = await OutgoingInvoicesApiAxiosParamCreator(configuration).v1OutgoingInvoicesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Giden son 10 faturaya bu uç ile ulaşabilirsiniz.
         * @summary Giden son 10 faturayı listeler
         * @param {RecordStatus} [invoiceStatus] Belge durumu
         * @param {boolean} [archived] Arşivlenen belgeleri göster
         * @param {InvoiceProfileIdParameter} [profileId] Fatura profiline göre filtreleme yapabilirsiniz
         * @param {InvoiceTypeParameter} [invoiceTypeCode] Fatura tipine göre filtreleme yapabilirsiniz
         * @param {DocumentAnswer} [documentAnswer] Cevap durumuna göre filtreleme yapabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingInvoicesLastGet(requestParameters: V1OutgoingInvoicesLastGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutgoingInvoice>>> {
            const localVarAxiosArgs = await OutgoingInvoicesApiAxiosParamCreator(configuration).v1OutgoingInvoicesLastGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye etiket eklemek veya çıkarmak için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **100** belgeye tag eklenebilir/çıkarılabilir.
         * @summary Etiket ekler/çıkarır
         * @param {TaggingDto} taggingDto #### Etiket eklemek veya çıkarmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingInvoicesTagsPut(requestParameters: V1OutgoingInvoicesTagsPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OutgoingInvoicesApiAxiosParamCreator(configuration).v1OutgoingInvoicesTagsPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye ait ek dokumanların listesine bu uç ile ulaşabilirsiniz.
         * @summary Belge eklerini listeler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingInvoicesUuidAttachmentsGet(requestParameters: V1OutgoingInvoicesUuidAttachmentsGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Attachment>>> {
            const localVarAxiosArgs = await OutgoingInvoicesApiAxiosParamCreator(configuration).v1OutgoingInvoicesUuidAttachmentsGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye ait ek dokumanların indirilmesi için bu uç kullanılabilir.
         * @summary Belge ekini indirir
         * @param {string} uuid **Belge uuid**
         * @param {number} index **Ek belge sırası**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingInvoicesUuidAttachmentsIndexGet(requestParameters: V1OutgoingInvoicesUuidAttachmentsIndexGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Attachment>>> {
            const localVarAxiosArgs = await OutgoingInvoicesApiAxiosParamCreator(configuration).v1OutgoingInvoicesUuidAttachmentsIndexGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belge içerisinde belirtilen irsaliye bilgilerine bu uç ile ulaşabilirsiniz.
         * @summary İrsaliye bilgilerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingInvoicesUuidDespatchesGet(requestParameters: V1OutgoingInvoicesUuidDespatchesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DespatchDocumentReferencePaginationResult>> {
            const localVarAxiosArgs = await OutgoingInvoicesApiAxiosParamCreator(configuration).v1OutgoingInvoicesUuidDespatchesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye ait Zarf XML dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary Zarf XML İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingInvoicesUuidEnvelopeGet(requestParameters: V1OutgoingInvoicesUuidEnvelopeGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OutgoingInvoicesApiAxiosParamCreator(configuration).v1OutgoingInvoicesUuidEnvelopeGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Belge detaylarını getirir
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingInvoicesUuidGet(requestParameters: V1OutgoingInvoicesUuidGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutgoingInvoiceDetail>> {
            const localVarAxiosArgs = await OutgoingInvoicesApiAxiosParamCreator(configuration).v1OutgoingInvoicesUuidGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Sorgulanan belgeye ait işlem geçmişine bu uç ile ulaşabilirsiniz.
         * @summary İşlem geçmişini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingInvoicesUuidHistoriesGet(requestParameters: V1OutgoingInvoicesUuidHistoriesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoryPaginationResult>> {
            const localVarAxiosArgs = await OutgoingInvoicesApiAxiosParamCreator(configuration).v1OutgoingInvoicesUuidHistoriesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye ait HTML görüntüye bu uç ile ulaşabilirsiniz.
         * @summary Belgeyi görüntüler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingInvoicesUuidHtmlGet(requestParameters: V1OutgoingInvoicesUuidHtmlGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OutgoingInvoicesApiAxiosParamCreator(configuration).v1OutgoingInvoicesUuidHtmlGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Giden e-Faturaya ait diğer bilgilere bu uç ile ulaşabilirsiniz.    > İhracat faturalarında GTP Ref No vb. gibi bilgiler
         * @summary Belgenin diğer bilgileri
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingInvoicesUuidInfosGet(requestParameters: V1OutgoingInvoicesUuidInfosGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutgoingInvoiceInfo>>> {
            const localVarAxiosArgs = await OutgoingInvoicesApiAxiosParamCreator(configuration).v1OutgoingInvoicesUuidInfosGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Sorgulanan belge içerisindeki mail adreslerinin listesine bu uç ile ulaşabilirsiniz.
         * @summary Mail adreslerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingInvoicesUuidMailaddressesGet(requestParameters: V1OutgoingInvoicesUuidMailaddressesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await OutgoingInvoicesApiAxiosParamCreator(configuration).v1OutgoingInvoicesUuidMailaddressesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye ait PDF dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary PDF İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingInvoicesUuidPdfGet(requestParameters: V1OutgoingInvoicesUuidPdfGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OutgoingInvoicesApiAxiosParamCreator(configuration).v1OutgoingInvoicesUuidPdfGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Taslak belgelerin alıcı etiketi bu uç ile güncellenebilir
         * @param {string} uuid 
         * @param {ReceiverAliasPutDto} receiverAliasPutDto #### Taslak belge alıcı etiketi güncellemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingInvoicesUuidReceiveraliasPut(requestParameters: V1OutgoingInvoicesUuidReceiveraliasPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OutgoingInvoicesApiAxiosParamCreator(configuration).v1OutgoingInvoicesUuidReceiveraliasPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Gelen belgeler için göndericiyi, giden belgeler içinse alıcıyı **Firma Listesi**\'ne kaydetmek için bu uç kullanılabilir.
         * @summary Firma olarak kaydet
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingInvoicesUuidSavecompanyindocumentPost(requestParameters: V1OutgoingInvoicesUuidSavecompanyindocumentPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await OutgoingInvoicesApiAxiosParamCreator(configuration).v1OutgoingInvoicesUuidSavecompanyindocumentPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belge içerisinde belirtilen vergi bilgilerine bu uç ile ulaşabilirsiniz.
         * @summary Vergi bilgilerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingInvoicesUuidTaxesGet(requestParameters: V1OutgoingInvoicesUuidTaxesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxInfoPaginationResult>> {
            const localVarAxiosArgs = await OutgoingInvoicesApiAxiosParamCreator(configuration).v1OutgoingInvoicesUuidTaxesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Sorgulanan belgeye ait kullanıcı tarafından eklenmiş notlara bu uç ile ulaşabilirsiniz.
         * @summary Kullanıcı notu listeler
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingInvoicesUuidUsernotesGet(requestParameters: V1OutgoingInvoicesUuidUsernotesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotePaginationResult>> {
            const localVarAxiosArgs = await OutgoingInvoicesApiAxiosParamCreator(configuration).v1OutgoingInvoicesUuidUsernotesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Kullanıcı tarafından oluşturulmuş olan notu silmek için bu uç kullanılabilir.
         * @summary Kullanıcı notunu siler
         * @param {string} uuid **Belge uuid**
         * @param {string} id **Not id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingInvoicesUuidUsernotesIdDelete(requestParameters: V1OutgoingInvoicesUuidUsernotesIdDeleteRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OutgoingInvoicesApiAxiosParamCreator(configuration).v1OutgoingInvoicesUuidUsernotesIdDelete(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye daha önce eklenmiş olan kullanıcı notunu güncellemek için bu uç kullanılabilir.
         * @summary Kullanıcı notunu günceller
         * @param {string} uuid #### Belgeye ait uuid
         * @param {string} id #### Not id
         * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingInvoicesUuidUsernotesIdPut(requestParameters: V1OutgoingInvoicesUuidUsernotesIdPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OutgoingInvoicesApiAxiosParamCreator(configuration).v1OutgoingInvoicesUuidUsernotesIdPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye kullanıcı notu eklemek için bu uç kullanılabilir.
         * @summary Kullanıcı notu ekler
         * @param {string} uuid #### Belge uuid
         * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingInvoicesUuidUsernotesPost(requestParameters: V1OutgoingInvoicesUuidUsernotesPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Note>> {
            const localVarAxiosArgs = await OutgoingInvoicesApiAxiosParamCreator(configuration).v1OutgoingInvoicesUuidUsernotesPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye ait XML dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary XML İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingInvoicesUuidXmlGet(requestParameters: V1OutgoingInvoicesUuidXmlGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OutgoingInvoicesApiAxiosParamCreator(configuration).v1OutgoingInvoicesUuidXmlGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OutgoingInvoicesApi - factory interface
 * @export
 */
export const OutgoingInvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Belgelere toplu olarak yeni durum atamak için bu uç kullanılabilir.
         * @summary Yeni durum atar
         * @param {OutgoingInvoiceSetParameter} operation **İşlem**
         * @param {Array<string>} requestBody **Belge uuid listesi**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesBulkOperationPut(requestParameters: V1OutgoingInvoicesBulkOperationPutRequest, options?: any): AxiosPromise<void> {
            return OutgoingInvoicesApiFp(configuration).v1OutgoingInvoicesBulkOperationPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Taslak belgeleri silmek için bu uç kullanılablir
         * @param {Array<string>} requestBody #### Taslak belge UUID listesi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesDraftsDelete(requestParameters: V1OutgoingInvoicesDraftsDeleteRequest, options?: any): AxiosPromise<void> {
            return OutgoingInvoicesApiFp(configuration).v1OutgoingInvoicesDraftsDelete(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Giden e-Fatura taslaklarınıza bu uç ile ulaşabilirsiniz.
         * @summary Taslak faturaları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesDraftsGet(requestParameters: V1OutgoingInvoicesDraftsGetRequest, options?: any): AxiosPromise<OutgoingInvoicePaginationResult> {
            return OutgoingInvoicesApiFp(configuration).v1OutgoingInvoicesDraftsGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgelerinizi mail olarak iletmek için bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **50** farklı belge, **10** farklı mail adresi gönderilebilir.
         * @summary Belgeyi mail olarak iletir
         * @param {EmailSendDto} emailSendDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesEmailSendPost(requestParameters: V1OutgoingInvoicesEmailSendPostRequest, options?: any): AxiosPromise<void> {
            return OutgoingInvoicesApiFp(configuration).v1OutgoingInvoicesEmailSendPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgelerinizi toplu olarak dışarı aktarmak isterseniz bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **100** kayıt dışarı aktarılabilir.    **fileType** Parametre detayları    > **Xml** : Belgenizin XML halini dışarı aktarır    > **Envelope** : Belgenizin Zarf XML halini dışarı aktarır    > **Pdf** : Belgenize ait PDF görüntüsünü dışarı aktarır    > **UnifiedPdf** : ***uuids*** parametresinde gönderilen belgeleri tek PDF dosyası halinde dışarı aktarır    > **Excel** : ***uuids*** parametresinde gönderilen belgeleri Excel\'e aktarır
         * @summary Toplu aktar
         * @param {FileExportTypeParameter} fileType 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesExportFileTypePost(requestParameters: V1OutgoingInvoicesExportFileTypePostRequest, options?: any): AxiosPromise<void> {
            return OutgoingInvoicesApiFp(configuration).v1OutgoingInvoicesExportFileTypePost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Giden e-Faturalarınıza bu uç ile ulaşabilirsiniz.
         * @summary Faturaları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {Array<string>} [tags] Faturaya atanmış etiketlere göre filtreleme yapabilirsiniz
         * @param {string} [userNote] Kullanıcı notuna göre filtreleme yapabilirsiniz
         * @param {string} [documentNote] Belge içinde geçen nota göre filtreleme yapabilirsiniz
         * @param {string} [despatchNumber] İrsaliye numarasına göre filtreleme yapabilirsiniz
         * @param {string} [orderNumber] Sipariş numarasına göre filtreleme yapabilirsiniz
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {string} [startCreateDate] Oluşturma tarihi başlangıcı
         * @param {string} [endCreateDate] Oluşturma tarihi sonu
         * @param {RecordStatus} [invoiceStatus] Fatura durumuna göre filtreleme yapabilirsiniz
         * @param {boolean} [archived] Arşivlenen belgeleri göster
         * @param {InvoiceProfileIdParameter} [profileId] Fatura profiline göre filtreleme yapabilirsiniz
         * @param {InvoiceTypeParameter} [invoiceTypeCode] Fatura tipine göre filtreleme yapabilirsiniz
         * @param {DocumentAnswer} [documentAnswer] Cevap durumuna göre filtreleme yapabilirsiniz
         * @param {LucaTransferStatusParameter} [lucaTransferStatus] 
         * @param {string} [startDate] Başlangıç tarihi
         * @param {string} [endDate] Bitiş tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesGet(requestParameters: V1OutgoingInvoicesGetRequest, options?: any): AxiosPromise<OutgoingInvoicePaginationResult> {
            return OutgoingInvoicesApiFp(configuration).v1OutgoingInvoicesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Giden son 10 faturaya bu uç ile ulaşabilirsiniz.
         * @summary Giden son 10 faturayı listeler
         * @param {RecordStatus} [invoiceStatus] Belge durumu
         * @param {boolean} [archived] Arşivlenen belgeleri göster
         * @param {InvoiceProfileIdParameter} [profileId] Fatura profiline göre filtreleme yapabilirsiniz
         * @param {InvoiceTypeParameter} [invoiceTypeCode] Fatura tipine göre filtreleme yapabilirsiniz
         * @param {DocumentAnswer} [documentAnswer] Cevap durumuna göre filtreleme yapabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesLastGet(requestParameters: V1OutgoingInvoicesLastGetRequest, options?: any): AxiosPromise<Array<OutgoingInvoice>> {
            return OutgoingInvoicesApiFp(configuration).v1OutgoingInvoicesLastGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye etiket eklemek veya çıkarmak için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **100** belgeye tag eklenebilir/çıkarılabilir.
         * @summary Etiket ekler/çıkarır
         * @param {TaggingDto} taggingDto #### Etiket eklemek veya çıkarmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesTagsPut(requestParameters: V1OutgoingInvoicesTagsPutRequest, options?: any): AxiosPromise<void> {
            return OutgoingInvoicesApiFp(configuration).v1OutgoingInvoicesTagsPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye ait ek dokumanların listesine bu uç ile ulaşabilirsiniz.
         * @summary Belge eklerini listeler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidAttachmentsGet(requestParameters: V1OutgoingInvoicesUuidAttachmentsGetRequest, options?: any): AxiosPromise<Array<Attachment>> {
            return OutgoingInvoicesApiFp(configuration).v1OutgoingInvoicesUuidAttachmentsGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye ait ek dokumanların indirilmesi için bu uç kullanılabilir.
         * @summary Belge ekini indirir
         * @param {string} uuid **Belge uuid**
         * @param {number} index **Ek belge sırası**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidAttachmentsIndexGet(requestParameters: V1OutgoingInvoicesUuidAttachmentsIndexGetRequest, options?: any): AxiosPromise<Array<Attachment>> {
            return OutgoingInvoicesApiFp(configuration).v1OutgoingInvoicesUuidAttachmentsIndexGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belge içerisinde belirtilen irsaliye bilgilerine bu uç ile ulaşabilirsiniz.
         * @summary İrsaliye bilgilerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidDespatchesGet(requestParameters: V1OutgoingInvoicesUuidDespatchesGetRequest, options?: any): AxiosPromise<DespatchDocumentReferencePaginationResult> {
            return OutgoingInvoicesApiFp(configuration).v1OutgoingInvoicesUuidDespatchesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye ait Zarf XML dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary Zarf XML İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidEnvelopeGet(requestParameters: V1OutgoingInvoicesUuidEnvelopeGetRequest, options?: any): AxiosPromise<void> {
            return OutgoingInvoicesApiFp(configuration).v1OutgoingInvoicesUuidEnvelopeGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Belge detaylarını getirir
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidGet(requestParameters: V1OutgoingInvoicesUuidGetRequest, options?: any): AxiosPromise<OutgoingInvoiceDetail> {
            return OutgoingInvoicesApiFp(configuration).v1OutgoingInvoicesUuidGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Sorgulanan belgeye ait işlem geçmişine bu uç ile ulaşabilirsiniz.
         * @summary İşlem geçmişini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidHistoriesGet(requestParameters: V1OutgoingInvoicesUuidHistoriesGetRequest, options?: any): AxiosPromise<HistoryPaginationResult> {
            return OutgoingInvoicesApiFp(configuration).v1OutgoingInvoicesUuidHistoriesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye ait HTML görüntüye bu uç ile ulaşabilirsiniz.
         * @summary Belgeyi görüntüler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidHtmlGet(requestParameters: V1OutgoingInvoicesUuidHtmlGetRequest, options?: any): AxiosPromise<void> {
            return OutgoingInvoicesApiFp(configuration).v1OutgoingInvoicesUuidHtmlGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Giden e-Faturaya ait diğer bilgilere bu uç ile ulaşabilirsiniz.    > İhracat faturalarında GTP Ref No vb. gibi bilgiler
         * @summary Belgenin diğer bilgileri
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidInfosGet(requestParameters: V1OutgoingInvoicesUuidInfosGetRequest, options?: any): AxiosPromise<Array<OutgoingInvoiceInfo>> {
            return OutgoingInvoicesApiFp(configuration).v1OutgoingInvoicesUuidInfosGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Sorgulanan belge içerisindeki mail adreslerinin listesine bu uç ile ulaşabilirsiniz.
         * @summary Mail adreslerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidMailaddressesGet(requestParameters: V1OutgoingInvoicesUuidMailaddressesGetRequest, options?: any): AxiosPromise<Array<string>> {
            return OutgoingInvoicesApiFp(configuration).v1OutgoingInvoicesUuidMailaddressesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye ait PDF dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary PDF İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidPdfGet(requestParameters: V1OutgoingInvoicesUuidPdfGetRequest, options?: any): AxiosPromise<void> {
            return OutgoingInvoicesApiFp(configuration).v1OutgoingInvoicesUuidPdfGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Taslak belgelerin alıcı etiketi bu uç ile güncellenebilir
         * @param {string} uuid 
         * @param {ReceiverAliasPutDto} receiverAliasPutDto #### Taslak belge alıcı etiketi güncellemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidReceiveraliasPut(requestParameters: V1OutgoingInvoicesUuidReceiveraliasPutRequest, options?: any): AxiosPromise<void> {
            return OutgoingInvoicesApiFp(configuration).v1OutgoingInvoicesUuidReceiveraliasPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Gelen belgeler için göndericiyi, giden belgeler içinse alıcıyı **Firma Listesi**\'ne kaydetmek için bu uç kullanılabilir.
         * @summary Firma olarak kaydet
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidSavecompanyindocumentPost(requestParameters: V1OutgoingInvoicesUuidSavecompanyindocumentPostRequest, options?: any): AxiosPromise<Company> {
            return OutgoingInvoicesApiFp(configuration).v1OutgoingInvoicesUuidSavecompanyindocumentPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belge içerisinde belirtilen vergi bilgilerine bu uç ile ulaşabilirsiniz.
         * @summary Vergi bilgilerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidTaxesGet(requestParameters: V1OutgoingInvoicesUuidTaxesGetRequest, options?: any): AxiosPromise<TaxInfoPaginationResult> {
            return OutgoingInvoicesApiFp(configuration).v1OutgoingInvoicesUuidTaxesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Sorgulanan belgeye ait kullanıcı tarafından eklenmiş notlara bu uç ile ulaşabilirsiniz.
         * @summary Kullanıcı notu listeler
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidUsernotesGet(requestParameters: V1OutgoingInvoicesUuidUsernotesGetRequest, options?: any): AxiosPromise<NotePaginationResult> {
            return OutgoingInvoicesApiFp(configuration).v1OutgoingInvoicesUuidUsernotesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Kullanıcı tarafından oluşturulmuş olan notu silmek için bu uç kullanılabilir.
         * @summary Kullanıcı notunu siler
         * @param {string} uuid **Belge uuid**
         * @param {string} id **Not id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidUsernotesIdDelete(requestParameters: V1OutgoingInvoicesUuidUsernotesIdDeleteRequest, options?: any): AxiosPromise<void> {
            return OutgoingInvoicesApiFp(configuration).v1OutgoingInvoicesUuidUsernotesIdDelete(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye daha önce eklenmiş olan kullanıcı notunu güncellemek için bu uç kullanılabilir.
         * @summary Kullanıcı notunu günceller
         * @param {string} uuid #### Belgeye ait uuid
         * @param {string} id #### Not id
         * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidUsernotesIdPut(requestParameters: V1OutgoingInvoicesUuidUsernotesIdPutRequest, options?: any): AxiosPromise<void> {
            return OutgoingInvoicesApiFp(configuration).v1OutgoingInvoicesUuidUsernotesIdPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye kullanıcı notu eklemek için bu uç kullanılabilir.
         * @summary Kullanıcı notu ekler
         * @param {string} uuid #### Belge uuid
         * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidUsernotesPost(requestParameters: V1OutgoingInvoicesUuidUsernotesPostRequest, options?: any): AxiosPromise<Note> {
            return OutgoingInvoicesApiFp(configuration).v1OutgoingInvoicesUuidUsernotesPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye ait XML dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary XML İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingInvoicesUuidXmlGet(requestParameters: V1OutgoingInvoicesUuidXmlGetRequest, options?: any): AxiosPromise<void> {
            return OutgoingInvoicesApiFp(configuration).v1OutgoingInvoicesUuidXmlGet(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OutgoingInvoicesApi - object-oriented interface
 * @export
 * @class OutgoingInvoicesApi
 * @extends {BaseAPI}
 */
export class OutgoingInvoicesApi extends BaseAPI {
    /**
     * Belgelere toplu olarak yeni durum atamak için bu uç kullanılabilir.
     * @summary Yeni durum atar
     * @param {OutgoingInvoiceSetParameter} operation **İşlem**
     * @param {Array<string>} requestBody **Belge uuid listesi**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingInvoicesApi
     */
    public v1OutgoingInvoicesBulkOperationPut(requestParameters: V1OutgoingInvoicesBulkOperationPutRequest, options?: any) {
        return OutgoingInvoicesApiFp(this.configuration).v1OutgoingInvoicesBulkOperationPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Taslak belgeleri silmek için bu uç kullanılablir
     * @param {Array<string>} requestBody #### Taslak belge UUID listesi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingInvoicesApi
     */
    public v1OutgoingInvoicesDraftsDelete(requestParameters: V1OutgoingInvoicesDraftsDeleteRequest, options?: any) {
        return OutgoingInvoicesApiFp(this.configuration).v1OutgoingInvoicesDraftsDelete(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Giden e-Fatura taslaklarınıza bu uç ile ulaşabilirsiniz.
     * @summary Taslak faturaları listeler
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
     * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
     * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingInvoicesApi
     */
    public v1OutgoingInvoicesDraftsGet(requestParameters: V1OutgoingInvoicesDraftsGetRequest, options?: any) {
        return OutgoingInvoicesApiFp(this.configuration).v1OutgoingInvoicesDraftsGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgelerinizi mail olarak iletmek için bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **50** farklı belge, **10** farklı mail adresi gönderilebilir.
     * @summary Belgeyi mail olarak iletir
     * @param {EmailSendDto} emailSendDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingInvoicesApi
     */
    public v1OutgoingInvoicesEmailSendPost(requestParameters: V1OutgoingInvoicesEmailSendPostRequest, options?: any) {
        return OutgoingInvoicesApiFp(this.configuration).v1OutgoingInvoicesEmailSendPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgelerinizi toplu olarak dışarı aktarmak isterseniz bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **100** kayıt dışarı aktarılabilir.    **fileType** Parametre detayları    > **Xml** : Belgenizin XML halini dışarı aktarır    > **Envelope** : Belgenizin Zarf XML halini dışarı aktarır    > **Pdf** : Belgenize ait PDF görüntüsünü dışarı aktarır    > **UnifiedPdf** : ***uuids*** parametresinde gönderilen belgeleri tek PDF dosyası halinde dışarı aktarır    > **Excel** : ***uuids*** parametresinde gönderilen belgeleri Excel\'e aktarır
     * @summary Toplu aktar
     * @param {FileExportTypeParameter} fileType 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingInvoicesApi
     */
    public v1OutgoingInvoicesExportFileTypePost(requestParameters: V1OutgoingInvoicesExportFileTypePostRequest, options?: any) {
        return OutgoingInvoicesApiFp(this.configuration).v1OutgoingInvoicesExportFileTypePost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Giden e-Faturalarınıza bu uç ile ulaşabilirsiniz.
     * @summary Faturaları listeler
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {Array<string>} [tags] Faturaya atanmış etiketlere göre filtreleme yapabilirsiniz
     * @param {string} [userNote] Kullanıcı notuna göre filtreleme yapabilirsiniz
     * @param {string} [documentNote] Belge içinde geçen nota göre filtreleme yapabilirsiniz
     * @param {string} [despatchNumber] İrsaliye numarasına göre filtreleme yapabilirsiniz
     * @param {string} [orderNumber] Sipariş numarasına göre filtreleme yapabilirsiniz
     * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
     * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
     * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
     * @param {string} [startCreateDate] Oluşturma tarihi başlangıcı
     * @param {string} [endCreateDate] Oluşturma tarihi sonu
     * @param {RecordStatus} [invoiceStatus] Fatura durumuna göre filtreleme yapabilirsiniz
     * @param {boolean} [archived] Arşivlenen belgeleri göster
     * @param {InvoiceProfileIdParameter} [profileId] Fatura profiline göre filtreleme yapabilirsiniz
     * @param {InvoiceTypeParameter} [invoiceTypeCode] Fatura tipine göre filtreleme yapabilirsiniz
     * @param {DocumentAnswer} [documentAnswer] Cevap durumuna göre filtreleme yapabilirsiniz
     * @param {LucaTransferStatusParameter} [lucaTransferStatus] 
     * @param {string} [startDate] Başlangıç tarihi
     * @param {string} [endDate] Bitiş tarihi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingInvoicesApi
     */
    public v1OutgoingInvoicesGet(requestParameters: V1OutgoingInvoicesGetRequest, options?: any) {
        return OutgoingInvoicesApiFp(this.configuration).v1OutgoingInvoicesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Giden son 10 faturaya bu uç ile ulaşabilirsiniz.
     * @summary Giden son 10 faturayı listeler
     * @param {RecordStatus} [invoiceStatus] Belge durumu
     * @param {boolean} [archived] Arşivlenen belgeleri göster
     * @param {InvoiceProfileIdParameter} [profileId] Fatura profiline göre filtreleme yapabilirsiniz
     * @param {InvoiceTypeParameter} [invoiceTypeCode] Fatura tipine göre filtreleme yapabilirsiniz
     * @param {DocumentAnswer} [documentAnswer] Cevap durumuna göre filtreleme yapabilirsiniz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingInvoicesApi
     */
    public v1OutgoingInvoicesLastGet(requestParameters: V1OutgoingInvoicesLastGetRequest, options?: any) {
        return OutgoingInvoicesApiFp(this.configuration).v1OutgoingInvoicesLastGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye etiket eklemek veya çıkarmak için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **100** belgeye tag eklenebilir/çıkarılabilir.
     * @summary Etiket ekler/çıkarır
     * @param {TaggingDto} taggingDto #### Etiket eklemek veya çıkarmak için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingInvoicesApi
     */
    public v1OutgoingInvoicesTagsPut(requestParameters: V1OutgoingInvoicesTagsPutRequest, options?: any) {
        return OutgoingInvoicesApiFp(this.configuration).v1OutgoingInvoicesTagsPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye ait ek dokumanların listesine bu uç ile ulaşabilirsiniz.
     * @summary Belge eklerini listeler
     * @param {string} uuid **Belge uuid**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingInvoicesApi
     */
    public v1OutgoingInvoicesUuidAttachmentsGet(requestParameters: V1OutgoingInvoicesUuidAttachmentsGetRequest, options?: any) {
        return OutgoingInvoicesApiFp(this.configuration).v1OutgoingInvoicesUuidAttachmentsGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye ait ek dokumanların indirilmesi için bu uç kullanılabilir.
     * @summary Belge ekini indirir
     * @param {string} uuid **Belge uuid**
     * @param {number} index **Ek belge sırası**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingInvoicesApi
     */
    public v1OutgoingInvoicesUuidAttachmentsIndexGet(requestParameters: V1OutgoingInvoicesUuidAttachmentsIndexGetRequest, options?: any) {
        return OutgoingInvoicesApiFp(this.configuration).v1OutgoingInvoicesUuidAttachmentsIndexGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belge içerisinde belirtilen irsaliye bilgilerine bu uç ile ulaşabilirsiniz.
     * @summary İrsaliye bilgilerini getirir
     * @param {string} uuid **Belge uuid**
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingInvoicesApi
     */
    public v1OutgoingInvoicesUuidDespatchesGet(requestParameters: V1OutgoingInvoicesUuidDespatchesGetRequest, options?: any) {
        return OutgoingInvoicesApiFp(this.configuration).v1OutgoingInvoicesUuidDespatchesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye ait Zarf XML dosyasını indirmek için bu ucu kullanabilirsiniz.
     * @summary Zarf XML İndir
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingInvoicesApi
     */
    public v1OutgoingInvoicesUuidEnvelopeGet(requestParameters: V1OutgoingInvoicesUuidEnvelopeGetRequest, options?: any) {
        return OutgoingInvoicesApiFp(this.configuration).v1OutgoingInvoicesUuidEnvelopeGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Belge detaylarını getirir
     * @param {string} uuid **Belge uuid**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingInvoicesApi
     */
    public v1OutgoingInvoicesUuidGet(requestParameters: V1OutgoingInvoicesUuidGetRequest, options?: any) {
        return OutgoingInvoicesApiFp(this.configuration).v1OutgoingInvoicesUuidGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sorgulanan belgeye ait işlem geçmişine bu uç ile ulaşabilirsiniz.
     * @summary İşlem geçmişini getirir
     * @param {string} uuid **Belge uuid**
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingInvoicesApi
     */
    public v1OutgoingInvoicesUuidHistoriesGet(requestParameters: V1OutgoingInvoicesUuidHistoriesGetRequest, options?: any) {
        return OutgoingInvoicesApiFp(this.configuration).v1OutgoingInvoicesUuidHistoriesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye ait HTML görüntüye bu uç ile ulaşabilirsiniz.
     * @summary Belgeyi görüntüler
     * @param {string} uuid **Belge uuid**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingInvoicesApi
     */
    public v1OutgoingInvoicesUuidHtmlGet(requestParameters: V1OutgoingInvoicesUuidHtmlGetRequest, options?: any) {
        return OutgoingInvoicesApiFp(this.configuration).v1OutgoingInvoicesUuidHtmlGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Giden e-Faturaya ait diğer bilgilere bu uç ile ulaşabilirsiniz.    > İhracat faturalarında GTP Ref No vb. gibi bilgiler
     * @summary Belgenin diğer bilgileri
     * @param {string} uuid **Belge uuid**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingInvoicesApi
     */
    public v1OutgoingInvoicesUuidInfosGet(requestParameters: V1OutgoingInvoicesUuidInfosGetRequest, options?: any) {
        return OutgoingInvoicesApiFp(this.configuration).v1OutgoingInvoicesUuidInfosGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sorgulanan belge içerisindeki mail adreslerinin listesine bu uç ile ulaşabilirsiniz.
     * @summary Mail adreslerini getirir
     * @param {string} uuid **Belge uuid**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingInvoicesApi
     */
    public v1OutgoingInvoicesUuidMailaddressesGet(requestParameters: V1OutgoingInvoicesUuidMailaddressesGetRequest, options?: any) {
        return OutgoingInvoicesApiFp(this.configuration).v1OutgoingInvoicesUuidMailaddressesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye ait PDF dosyasını indirmek için bu ucu kullanabilirsiniz.
     * @summary PDF İndir
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingInvoicesApi
     */
    public v1OutgoingInvoicesUuidPdfGet(requestParameters: V1OutgoingInvoicesUuidPdfGetRequest, options?: any) {
        return OutgoingInvoicesApiFp(this.configuration).v1OutgoingInvoicesUuidPdfGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Taslak belgelerin alıcı etiketi bu uç ile güncellenebilir
     * @param {string} uuid 
     * @param {ReceiverAliasPutDto} receiverAliasPutDto #### Taslak belge alıcı etiketi güncellemek için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingInvoicesApi
     */
    public v1OutgoingInvoicesUuidReceiveraliasPut(requestParameters: V1OutgoingInvoicesUuidReceiveraliasPutRequest, options?: any) {
        return OutgoingInvoicesApiFp(this.configuration).v1OutgoingInvoicesUuidReceiveraliasPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gelen belgeler için göndericiyi, giden belgeler içinse alıcıyı **Firma Listesi**\'ne kaydetmek için bu uç kullanılabilir.
     * @summary Firma olarak kaydet
     * @param {string} uuid **Belge uuid**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingInvoicesApi
     */
    public v1OutgoingInvoicesUuidSavecompanyindocumentPost(requestParameters: V1OutgoingInvoicesUuidSavecompanyindocumentPostRequest, options?: any) {
        return OutgoingInvoicesApiFp(this.configuration).v1OutgoingInvoicesUuidSavecompanyindocumentPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belge içerisinde belirtilen vergi bilgilerine bu uç ile ulaşabilirsiniz.
     * @summary Vergi bilgilerini getirir
     * @param {string} uuid **Belge uuid**
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingInvoicesApi
     */
    public v1OutgoingInvoicesUuidTaxesGet(requestParameters: V1OutgoingInvoicesUuidTaxesGetRequest, options?: any) {
        return OutgoingInvoicesApiFp(this.configuration).v1OutgoingInvoicesUuidTaxesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sorgulanan belgeye ait kullanıcı tarafından eklenmiş notlara bu uç ile ulaşabilirsiniz.
     * @summary Kullanıcı notu listeler
     * @param {string} uuid **Belge uuid**
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingInvoicesApi
     */
    public v1OutgoingInvoicesUuidUsernotesGet(requestParameters: V1OutgoingInvoicesUuidUsernotesGetRequest, options?: any) {
        return OutgoingInvoicesApiFp(this.configuration).v1OutgoingInvoicesUuidUsernotesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Kullanıcı tarafından oluşturulmuş olan notu silmek için bu uç kullanılabilir.
     * @summary Kullanıcı notunu siler
     * @param {string} uuid **Belge uuid**
     * @param {string} id **Not id**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingInvoicesApi
     */
    public v1OutgoingInvoicesUuidUsernotesIdDelete(requestParameters: V1OutgoingInvoicesUuidUsernotesIdDeleteRequest, options?: any) {
        return OutgoingInvoicesApiFp(this.configuration).v1OutgoingInvoicesUuidUsernotesIdDelete(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye daha önce eklenmiş olan kullanıcı notunu güncellemek için bu uç kullanılabilir.
     * @summary Kullanıcı notunu günceller
     * @param {string} uuid #### Belgeye ait uuid
     * @param {string} id #### Not id
     * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingInvoicesApi
     */
    public v1OutgoingInvoicesUuidUsernotesIdPut(requestParameters: V1OutgoingInvoicesUuidUsernotesIdPutRequest, options?: any) {
        return OutgoingInvoicesApiFp(this.configuration).v1OutgoingInvoicesUuidUsernotesIdPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye kullanıcı notu eklemek için bu uç kullanılabilir.
     * @summary Kullanıcı notu ekler
     * @param {string} uuid #### Belge uuid
     * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingInvoicesApi
     */
    public v1OutgoingInvoicesUuidUsernotesPost(requestParameters: V1OutgoingInvoicesUuidUsernotesPostRequest, options?: any) {
        return OutgoingInvoicesApiFp(this.configuration).v1OutgoingInvoicesUuidUsernotesPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye ait XML dosyasını indirmek için bu ucu kullanabilirsiniz.
     * @summary XML İndir
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingInvoicesApi
     */
    public v1OutgoingInvoicesUuidXmlGet(requestParameters: V1OutgoingInvoicesUuidXmlGetRequest, options?: any) {
        return OutgoingInvoicesApiFp(this.configuration).v1OutgoingInvoicesUuidXmlGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1OutgoingInvoicesBulkOperationPutRequest {
    /**
     * **İşlem**
     */
    operation: OutgoingInvoiceSetParameter;
    /**
     * **Belge uuid listesi**
     */
    requestBody: Array<string>;
    }
    export interface V1OutgoingInvoicesDraftsDeleteRequest {
    /**
     * #### Taslak belge UUID listesi
     */
    requestBody: Array<string>;
    }
    export interface V1OutgoingInvoicesDraftsGetRequest {
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    /**
     * Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
     */
    company?: string;
    /**
     * UUID numarasına göre filtreleme yapabilirsiniz
     */
    uuid?: string;
    /**
     * Belge numarasına göre filtreleme yapabilirsiniz
     */
    documentNumber?: string;
    }
    export interface V1OutgoingInvoicesEmailSendPostRequest {
    /**
     * 
     */
    emailSendDto: EmailSendDto;
    }
    export interface V1OutgoingInvoicesExportFileTypePostRequest {
    /**
     * 
     */
    fileType: FileExportTypeParameter;
    /**
     * 
     */
    requestBody: Array<string>;
    }
    export interface V1OutgoingInvoicesGetRequest {
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    /**
     * Faturaya atanmış etiketlere göre filtreleme yapabilirsiniz
     */
    tags?: Array<string>;
    /**
     * Kullanıcı notuna göre filtreleme yapabilirsiniz
     */
    userNote?: string;
    /**
     * Belge içinde geçen nota göre filtreleme yapabilirsiniz
     */
    documentNote?: string;
    /**
     * İrsaliye numarasına göre filtreleme yapabilirsiniz
     */
    despatchNumber?: string;
    /**
     * Sipariş numarasına göre filtreleme yapabilirsiniz
     */
    orderNumber?: string;
    /**
     * Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
     */
    company?: string;
    /**
     * UUID numarasına göre filtreleme yapabilirsiniz
     */
    uuid?: string;
    /**
     * Belge numarasına göre filtreleme yapabilirsiniz
     */
    documentNumber?: string;
    /**
     * Oluşturma tarihi başlangıcı
     */
    startCreateDate?: string;
    /**
     * Oluşturma tarihi sonu
     */
    endCreateDate?: string;
    /**
     * Fatura durumuna göre filtreleme yapabilirsiniz
     */
    invoiceStatus?: RecordStatus;
    /**
     * Arşivlenen belgeleri göster
     */
    archived?: boolean;
    /**
     * Fatura profiline göre filtreleme yapabilirsiniz
     */
    profileId?: InvoiceProfileIdParameter;
    /**
     * Fatura tipine göre filtreleme yapabilirsiniz
     */
    invoiceTypeCode?: InvoiceTypeParameter;
    /**
     * Cevap durumuna göre filtreleme yapabilirsiniz
     */
    documentAnswer?: DocumentAnswer;
    /**
     * 
     */
    lucaTransferStatus?: LucaTransferStatusParameter;
    /**
     * Başlangıç tarihi
     */
    startDate?: string;
    /**
     * Bitiş tarihi
     */
    endDate?: string;
    }
    export interface V1OutgoingInvoicesLastGetRequest {
    /**
     * Belge durumu
     */
    invoiceStatus?: RecordStatus;
    /**
     * Arşivlenen belgeleri göster
     */
    archived?: boolean;
    /**
     * Fatura profiline göre filtreleme yapabilirsiniz
     */
    profileId?: InvoiceProfileIdParameter;
    /**
     * Fatura tipine göre filtreleme yapabilirsiniz
     */
    invoiceTypeCode?: InvoiceTypeParameter;
    /**
     * Cevap durumuna göre filtreleme yapabilirsiniz
     */
    documentAnswer?: DocumentAnswer;
    }
    export interface V1OutgoingInvoicesTagsPutRequest {
    /**
     * #### Etiket eklemek veya çıkarmak için gönderilmesi gereken model
     */
    taggingDto: TaggingDto;
    }
    export interface V1OutgoingInvoicesUuidAttachmentsGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    }
    export interface V1OutgoingInvoicesUuidAttachmentsIndexGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    /**
     * **Ek belge sırası**
     */
    index: number;
    }
    export interface V1OutgoingInvoicesUuidDespatchesGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    }
    export interface V1OutgoingInvoicesUuidEnvelopeGetRequest {
    /**
     * 
     */
    uuid: string;
    }
    export interface V1OutgoingInvoicesUuidGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    }
    export interface V1OutgoingInvoicesUuidHistoriesGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    }
    export interface V1OutgoingInvoicesUuidHtmlGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    }
    export interface V1OutgoingInvoicesUuidInfosGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    }
    export interface V1OutgoingInvoicesUuidMailaddressesGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    }
    export interface V1OutgoingInvoicesUuidPdfGetRequest {
    /**
     * 
     */
    uuid: string;
    }
    export interface V1OutgoingInvoicesUuidReceiveraliasPutRequest {
    /**
     * 
     */
    uuid: string;
    /**
     * #### Taslak belge alıcı etiketi güncellemek için gönderilmesi gereken model
     */
    receiverAliasPutDto: ReceiverAliasPutDto;
    }
    export interface V1OutgoingInvoicesUuidSavecompanyindocumentPostRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    }
    export interface V1OutgoingInvoicesUuidTaxesGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    }
    export interface V1OutgoingInvoicesUuidUsernotesGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    }
    export interface V1OutgoingInvoicesUuidUsernotesIdDeleteRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    /**
     * **Not id**
     */
    id: string;
    }
    export interface V1OutgoingInvoicesUuidUsernotesIdPutRequest {
    /**
     * #### Belgeye ait uuid
     */
    uuid: string;
    /**
     * #### Not id
     */
    id: string;
    /**
     * #### Not eklemek için gönderilmesi gereken model
     */
    noteDto: NoteDto;
    }
    export interface V1OutgoingInvoicesUuidUsernotesPostRequest {
    /**
     * #### Belge uuid
     */
    uuid: string;
    /**
     * #### Not eklemek için gönderilmesi gereken model
     */
    noteDto: NoteDto;
    }
    export interface V1OutgoingInvoicesUuidXmlGetRequest {
    /**
     * 
     */
    uuid: string;
    }
