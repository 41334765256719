/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/camelcase */

import { ProductType } from '@/apis/identity/interfaces';
import { AuthRole, AuthScope } from '@/interfaces';
import { faCogs } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataNode } from 'antd/lib/tree';
import {
  APP_BASE_URL,
  APP_REDIRECT_URL,
  IDENTITY_CLIENT_ID,
  IDENTITY_ISSUER,
  IDENTITY_SCOPE,
  IDENTITY_SERVER_AUTHORITY_URL,
  SILENT_REDIRECT_URL
} from 'config';
import React from 'react';
import intl from 'react-intl-universal';
import { GENERAL_COMPANIES_ROLES } from './companies';
import { EARCHIVE_ROLES } from './earchive';
import { EARCHIVE_DOCUMENT_REPORT_ROLES } from './earchiveDocumentReports';
import { EDESPATCH_ROLES } from './edespatch';
import { EINVOICES_ROLES } from './einvoice';
import { EMM_ROLES } from './emm';
import { ESMM_ROLES } from './esmm';

export const IDENTITY_CONFIG = {
  authority: IDENTITY_SERVER_AUTHORITY_URL,
  client_id: IDENTITY_CLIENT_ID,
  redirect_uri: APP_REDIRECT_URL,
  silent_redirect_uri: SILENT_REDIRECT_URL,
  post_logout_redirect_uri: APP_BASE_URL,
  response_type: 'id_token token',
  automaticSilentRenew: false,
  loadUserInfo: true,
  scope: IDENTITY_SCOPE
};

export const METADATA_OIDC = {
  issuer: IDENTITY_ISSUER,
  jwks_uri: IDENTITY_SERVER_AUTHORITY_URL + '/.well-known/openid-configuration/jwks',
  authorization_endpoint: IDENTITY_SERVER_AUTHORITY_URL + '/connect/authorize',
  token_endpoint: IDENTITY_SERVER_AUTHORITY_URL + '/connect/token',
  userinfo_endpoint: IDENTITY_SERVER_AUTHORITY_URL + '/connect/userinfo',
  end_session_endpoint: IDENTITY_SERVER_AUTHORITY_URL + '/connect/endsession',
  check_session_iframe: IDENTITY_SERVER_AUTHORITY_URL + '/connect/checksession',
  revocation_endpoint: IDENTITY_SERVER_AUTHORITY_URL + '/connect/revocation',
  introspection_endpoint: IDENTITY_SERVER_AUTHORITY_URL + '/connect/introspect'
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export type MODULE_ROLE_TYPE = 'EI' | 'ED' | 'ESMM' | 'EMM' | 'EA';

export const MODULE_ROLES: MODULE_ROLE_TYPE[] = ['EI', 'ED', 'ESMM', 'EMM', 'EA'];

// export const ALL_SCOPES: AuthScope[] = [EINVOICE_SCOPES, EARCHIVE_SCOPES, EDESPATCH_SCOPES, ESMM_SCOPES, EMM_SCOPES];

export const ALLROLES = [
  ...[{ value: 'ADMINISTRATOR', icon: faCogs, checkable: true }],
  ...EINVOICES_ROLES,
  ...EARCHIVE_ROLES,
  ...ESMM_ROLES,
  ...EMM_ROLES,
  ...EDESPATCH_ROLES,
  ...EARCHIVE_DOCUMENT_REPORT_ROLES,
  ...GENERAL_COMPANIES_ROLES
];

export const ScopeToTreeDataNode = (scope: AuthScope) => {
  return {
    key: scope.value,
    checkable: scope.checkable === undefined ? true : scope.checkable,
    selectable: false,
    title: (
      <div>
        <span className="scope-title">{scope.value}</span>
        <span className="scope-description">{intl.get(`scope.${scope.value}`)}</span>
      </div>
    ),
    children: (scope.children || []).map((firstChild) => {
      return {
        key: firstChild.value,
        checkable: firstChild.checkable === undefined ? true : firstChild.checkable,
        selectable: false,
        title: (
          <div>
            <span className="scope-title">{firstChild.value}</span>
            <span className="scope-description">{intl.get(`scope.${firstChild.value}`)}</span>
          </div>
        ),
        children: (firstChild.children || []).map((secondChild) => {
          return {
            key: secondChild.value,
            checkable: secondChild.checkable === undefined ? true : secondChild.checkable,
            selectable: false,
            title: (
              <div>
                <span className="scope-title">{secondChild.value}</span>
                <span className="scope-description">{intl.get(`scope.${secondChild.value}`)}</span>
              </div>
            )
          };
        }) as DataNode[]
      };
    }) as DataNode[]
  };
};

export const RoleToTreeDataNode = (role: AuthRole) => {
  return {
    key: role.value,
    checkable: role.checkable === undefined ? true : role.checkable,
    selectable: false,
    title: (
      <div>
        <span className="role-title">
          {role.icon && <FontAwesomeIcon icon={role.icon} className="mr-2" />}
          {intl.get(`role.${role.value}`) || role.value}
        </span>
      </div>
    ),
    children: (role.children || []).map((firstChild) => {
      return {
        checkable: firstChild.checkable === undefined ? true : firstChild.checkable,
        key: firstChild.value,
        selectable: false,
        title: (
          <div>
            <span className="role-title">
              {firstChild.icon && <FontAwesomeIcon icon={firstChild.icon} className="mr-2" />}
              {intl.get(`role.${firstChild.value}`) || firstChild.value}
            </span>
          </div>
        ),
        children: (firstChild.children || []).map((secondChild) => {
          return {
            key: secondChild.value,
            checkable: secondChild.checkable === undefined ? true : secondChild.checkable,
            selectable: false,
            title: (
              <div>
                {secondChild.icon && <FontAwesomeIcon icon={secondChild.icon} className="mr-2" />}
                {intl.get(`role.${secondChild.value}`) || secondChild.value}
              </div>
            )
          };
        }) as DataNode[]
      };
    }) as DataNode[]
  };
};

export const getProductTypeFromRole = (role: string) => {
  const product = role.substring(0, role.indexOf(':'));

  switch (product) {
    case 'EI':
      return ProductType.EInvoice;
    case 'EA':
      return ProductType.EArchive;
    case 'ED':
      return ProductType.EDespatch;
    case 'EMM':
      return ProductType.EMm;
    case 'ESMM':
      return ProductType.ESmm;
    default:
      return null;
  }
};

export const GetScopeOrRoleList = (data: any[]) => {
  const scopes: { value: string; content: React.ReactNode }[] = [];

  data.map((scope) => {
    const productType = getProductTypeFromRole(scope.value);

    scope.checkable !== false &&
      scopes.push({
        value: scope.value,
        content: (
          <div>
            {productType && intl.get(`productType.${productType}`)}
            {productType && <span className="ml-2 mr-2">|</span>}
            {scope.icon && <FontAwesomeIcon icon={scope.icon} className="mr-2" />}
            {intl.get(`role.${scope.value}`)}
          </div>
        )
      });

    (scope.children || []).map((firstChild) => {
      firstChild.checkable !== false &&
        scopes.push({
          value: firstChild.value,
          content: (
            <div>
              {intl.get(`productType.${getProductTypeFromRole(firstChild.value)}`)}
              <span className="ml-2 mr-2">|</span>
              {scope.icon && <FontAwesomeIcon icon={scope.icon} className="mr-2" />}
              {intl.get(`role.${scope.value}`)}
              <span className="ml-2 mr-2">|</span>
              {firstChild.icon && <FontAwesomeIcon icon={firstChild.icon} className="mr-2" />}
              {intl.get(`role.${firstChild.value}`)}
            </div>
          )
        });

      (firstChild.children || []).map((secondChild) => {
        secondChild.checkable !== false &&
          scopes.push({
            value: secondChild.value,
            content: (
              <div>
                {intl.get(`productType.${getProductTypeFromRole(secondChild.value)}`)}
                <span className="ml-2 mr-2">|</span>
                {scope.icon && <FontAwesomeIcon icon={scope.icon} className="mr-2" />}
                {intl.get(`role.${scope.value}`)}
                <span className="ml-2 mr-2">|</span>
                {firstChild.icon && <FontAwesomeIcon icon={firstChild.icon} className="mr-2" />}
                {intl.get(`role.${firstChild.value}`)}
                <span className="ml-2 mr-2">|</span>
                {secondChild.icon && <FontAwesomeIcon icon={secondChild.icon} className="mr-2" />}
                {intl.get(`role.${secondChild.value}`)}
              </div>
            )
          });

        (secondChild.children || []).map((thirdChild) => {
          thirdChild.checkable !== false &&
            scopes.push({
              value: thirdChild.value,
              content: (
                <div>
                  {intl.get(`productType.${getProductTypeFromRole(thirdChild.value)}`)}
                  <span className="ml-2 mr-2">|</span>
                  {scope.icon && <FontAwesomeIcon icon={scope.icon} className="mr-2" />}
                  {intl.get(`role.${scope.value}`)}
                  <span className="ml-2 mr-2">|</span>
                  {firstChild.icon && <FontAwesomeIcon icon={firstChild.icon} className="mr-2" />}
                  {intl.get(`role.${firstChild.value}`)}
                  <span className="ml-2 mr-2">|</span>
                  {secondChild.icon && <FontAwesomeIcon icon={secondChild.icon} className="mr-2" />}
                  {intl.get(`role.${secondChild.value}`)}
                  <span className="ml-2 mr-2">|</span>
                  {thirdChild.icon && <FontAwesomeIcon icon={thirdChild.icon} className="mr-2" />}
                  {intl.get(`role.${thirdChild.value}`)}
                </div>
              )
            });
        });
      });
    });
  });

  return scopes;
};
