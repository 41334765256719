import cleanDeep from 'clean-deep';
import _ from 'lodash';
import { getPath } from 'ts-object-path';

export const nameOf = (paths: any): string => {
  return _.trimEnd(
    _.reduce(
      getPath(paths),
      function (concat, n, key) {
        return concat + (typeof n === 'number' ? `[${n.toString()}].` : `${n.toString()}.`);
      },
      ''
    ),
    '.'
  );
};

export const isDeepEmpty = (values) => {
  const cleanObject = cleanDeep(values, { undefinedValues: true, emptyObjects: true, emptyStrings: true, cleanValues: ['0'] });
  return _.isEmpty(cleanObject);
};

export const checkEArchive = (firstCreationTime, issueDate) => {
  const issueDateNew = new Date(issueDate);
  const date = new Date(issueDateNew.getFullYear(), issueDateNew.getMonth(), issueDateNew.getDate(), 23, 59, 59);
  return date < new Date(firstCreationTime);
};
