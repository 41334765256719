import { DailyStatistics } from '@/apis/general/interfaces';
import { getDays } from '@/utils/array';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { Skeleton } from 'antd';
import moment from 'moment';
import React, { useImperativeHandle } from 'react';
import { Responsive } from 'react-grid-layout';
import { SizeMe } from 'react-sizeme';
import DailyLineChart from '../Components/DailyLineChart';
import Doughnut from '../Components/Doughnut';
import LastCanceledVoucher from './LastCanceledVoucher';
import LastVoucher from './LastVoucher';
import { EMmLayouts } from './Layouts';

const EMM = React.forwardRef(
  (
    {
      startDate,
      endDate,
      flattenData,
      data,
      loading,
      generalStats
    }: {
      startDate: string;
      endDate: string;
      flattenData: any;
      loading: boolean;
      data?: DailyStatistics[];
      generalStats: any;
    },
    ref
  ) => {
    const lastInvoicesRef = React.createRef() as any;
    const lastCanceledInvoicesRef = React.createRef() as any;

    useImperativeHandle(ref, () => ({
      refreshData() {
        lastInvoicesRef.current.refresh();
        lastCanceledInvoicesRef.current.refresh();
      }
    }));

    return (
      <SizeMe>
        {({ size }) => {
          const divWidth = window.document.getElementsByClassName('air__utils__content')[0]?.clientWidth;
          return (
            <Responsive
              width={size !== null ? size.width || divWidth : divWidth}
              draggableHandle=".dashboard_drag_handle"
              layouts={
                JSON.parse(localStorage.getItem('apps.dashboard.emm-f4975ba9-072c-439e-8089-d0d5facfc7e5') || '[]').length != 0
                  ? JSON.parse(localStorage.getItem('apps.dashboard.emm-f4975ba9-072c-439e-8089-d0d5facfc7e5') || '[]')
                  : EMmLayouts
              }
              // layouts={EInvoiceLayouts}
              breakpoints={{ xxl: 1600, xl: 1200, lg: 1000, md: 800, sm: 600, xs: 400, xxs: 10 }}
              cols={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 24, xxs: 24 }}
              margin={[10, 10]}
              rowHeight={40}
              onLayoutChange={(_, layouts) => {
                localStorage.setItem('apps.dashboard.emm-f4975ba9-072c-439e-8089-d0d5facfc7e5', JSON.stringify(layouts));
              }}
            >
              <div key="emm-last-invoices" className="drag-item">
                <span className="dashboard_drag_handle" />
                <LastVoucher ref={lastInvoicesRef} />
              </div>

              <div key="emm-last-canceled-invoices" className="drag-item">
                <span className="dashboard_drag_handle" />
                <LastCanceledVoucher ref={lastCanceledInvoicesRef} />
              </div>

              <div key="emm-mailing-mail" className="drag-item">
                <span className="dashboard_drag_handle" />
                <Doughnut
                  icon={faPencil}
                  loading={loading}
                  title="Mail Durumları"
                  data={
                    flattenData['EMM:M:S'] || flattenData['EMM:M:F']
                      ? [
                          {
                            color: '#41b883',
                            label: 'Başarılı',
                            value: flattenData['EMM:M:S'] || 0,
                            url: `/emm/list?startDate=${startDate}&endDate=${endDate}&mailStatus=Succeed`
                          },
                          {
                            color: '#dc3545',
                            label: 'Başarısız',
                            value: flattenData['EMM:M:F'] || 0,
                            url: `/emm/list?startDate=${startDate}&endDate=${endDate}&mailStatus=Failed`
                          }
                        ]
                      : []
                  }
                />
              </div>

              <div key="emm-mailing-sms" className="drag-item">
                <span className="dashboard_drag_handle" />
                <Doughnut
                  icon={faPencil}
                  loading={loading}
                  title="Sms Durumları"
                  data={
                    flattenData['EMM:S:S'] || flattenData['EMM:S:F']
                      ? [
                          {
                            color: '#41b883',
                            label: 'Başarılı',
                            value: flattenData['EMM:S:S'] || 0,
                            url: `/emm/list?startDate=${startDate}&endDate=${endDate}&smsStatus=Succeed`
                          },
                          {
                            color: '#dc3545',
                            label: 'Başarısız',
                            value: flattenData['EMM:S:F'] || 0,
                            url: `/emm/list?startDate=${startDate}&endDate=${endDate}&smsStatus=Failed`
                          }
                        ]
                      : []
                  }
                />
              </div>

              <div key="emm-invoice-total" className="drag-item">
                <span className="dashboard_drag_handle" />
                {loading && <Skeleton active loading={loading} />}
                {!loading && (
                  <div className="card" style={{ border: '1px solid #bfc2c5' }}>
                    <div className="card-body">
                      <div className="d-flex flex-wrap align-items-center">
                        <div className="mr-auto">
                          <p className="text-uppercase font-weight-bold mb-1">E-MM</p>
                          <p className="mb-0">Tüm Zamanlar</p>
                        </div>
                        <p className="text-success font-weight-bold font-size-24 mb-0">{(generalStats && generalStats['EMM:V']) || 0}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div key="emm-invoice-canceled-total" className="drag-item">
                <span className="dashboard_drag_handle" />
                {loading && <Skeleton active loading={loading} />}
                {!loading && (
                  <div className="card" style={{ border: '1px solid #bfc2c5' }}>
                    <div className="card-body">
                      <div className="d-flex flex-wrap align-items-center">
                        <div className="mr-auto">
                          <p className="text-uppercase font-weight-bold mb-1">E-MM İptal</p>
                          <p className="mb-0">Tüm Zamanlar</p>
                        </div>
                        <p className="text-danger font-weight-bold font-size-24 mb-0">{(generalStats && generalStats['EMM:C']) || 0}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div key="emm-invoice-mail-total" className="drag-item">
                <span className="dashboard_drag_handle" />
                {loading && <Skeleton active loading={loading} />}
                {!loading && (
                  <div className="card" style={{ border: '1px solid #bfc2c5' }}>
                    <div className="card-body">
                      <div className="d-flex flex-wrap align-items-center">
                        <div className="mr-auto">
                          <p className="text-uppercase font-weight-bold mb-1">E-MM Mail</p>
                          <p className="mb-0">Tüm Zamanlar</p>
                        </div>
                        <p className="font-weight-bold font-size-24 mb-0" style={{ color: '#007bff' }}>
                          {(generalStats && generalStats['EMM:M']) || 0}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div key="emm-invoice-sms-total" className="drag-item">
                <span className="dashboard_drag_handle" />
                {loading && <Skeleton active loading={loading} />}
                {!loading && (
                  <div className="card" style={{ border: '1px solid #bfc2c5' }}>
                    <div className="card-body">
                      <div className="d-flex flex-wrap align-items-center">
                        <div className="mr-auto">
                          <p className="text-uppercase font-weight-bold mb-1">E-MM Sms</p>
                          <p className="mb-0">Tüm Zamanlar</p>
                        </div>
                        <p className="font-weight-bold font-size-24 mb-0" style={{ color: '#17a2b8' }}>
                          {(generalStats && generalStats['EMM:S']) || 0}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div key="emm-daily" className="drag-item">
                <span className="dashboard_drag_handle" />
                <DailyLineChart
                  title="E-Müstahsil Makbuzu İstatistikleri"
                  icon={faPencil}
                  loading={loading}
                  data={
                    data === undefined ||
                    data.length === 0 ||
                    (data.length !== 0 && !data.some((x) => x.statistics['EMM:V'] !== undefined) && !data.some((x) => x.statistics['EMM:C'] !== undefined))
                      ? getDays(startDate, endDate).map((item) => {
                          return {
                            date: item,
                            data: [
                              {
                                color: '#007bff',
                                label: 'e-Müstahsil Makbuzu',
                                value: 0
                              },
                              {
                                color: '#dc3545',
                                label: 'e-Müstahsil Makbuzu İptal',
                                value: 0
                              }
                            ]
                          };
                        })
                      : data.map((item) => {
                          return {
                            date: moment(item.date),
                            data: [
                              {
                                color: '#007bff',
                                label: 'e-Müstahsil Makbuzu',
                                value: item.statistics['EMM:V'],
                                url: `/emm/list?&startDate=${startDate}&endDate=${endDate}`
                              },
                              {
                                color: '#dc3545',
                                label: 'e-Müstahsil Makbuzu İptal',
                                value: item.statistics['EMM:C'],
                                url: `/emm/list/canceled?&startDate=${startDate}&endDate=${endDate}`
                              }
                            ]
                          };
                        })
                  }
                />
              </div>
            </Responsive>
          );
        }}
      </SizeMe>
    );
  }
);

export default EMM;
