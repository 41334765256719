/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLArray, XMLElement } from '@/xmldom-decorators';
import { AmountType, TaxSubTotalType } from '.';
import { NS_CAC, NS_CBC } from '../constants';

/*
  27.04.2021 : Fully completed
*/
export class TaxTotalType {
  @XMLElement({ types: [{ name: 'TaxAmount', namespaceUri: NS_CBC, itemType: () => AmountType }] })
  taxAmount: AmountType = { value: 0 };

  @XMLArray({ nested: false, itemTypes: [{ name: 'TaxSubtotal', namespaceUri: NS_CAC, itemType: () => TaxSubTotalType }] })
  taxSubtotal: TaxSubTotalType[] = [];
}

export default TaxTotalType;
