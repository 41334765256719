import { DateFormat } from '@/constants/page';
import { DatePicker } from 'antd';
import moment from 'moment';
import React from 'react';

export const GibDatePicker = (props: {
  name?: string;
  value?: string;
  onChange?: (value?: string) => void;
  enableFutureDate?: boolean;
  disabled?: boolean;
}) => {
  const { onChange, value, enableFutureDate, disabled, ...ownProps } = props;
  return (
    <DatePicker
      {...ownProps}
      value={value ? moment(value) : undefined}
      disabled={disabled}
      className="w-100"
      format={DateFormat.dmy}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onChange={(value) => onChange && (value ? onChange(value.format(DateFormat.ymd)) : onChange(undefined))}
      allowClear={true}
      showToday={true}
      disabledDate={(current) => (enableFutureDate !== true ? current > moment().endOf('day') : false)}
    />
  );
};

export default GibDatePicker;
