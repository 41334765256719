import { faFileExport } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'antd';
import React, { ReactElement } from 'react';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';

export const ExportDropdownButton = ({ menu }: { menu: ReactElement }) => {
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button color="warning" size={'sm'} className="btn-labeled dropdown-toggle">
        <FontAwesomeIcon icon={faFileExport} className="btn-label" />
        <span>{intl.get('DISA_AKTAR')}</span>
      </Button>
    </Dropdown>
  );
};

export default ExportDropdownButton;
