import { PageFilterContextConsumer } from '@/contexts/PageFilterContext';
import { usePreviousValue } from '@/hooks';
import { AnyType } from '@/type';
import { Pagination as AntdPagination } from 'antd';
import _ from 'lodash';
import React, { memo, useEffect } from 'react';
import intl from 'react-intl-universal';
import { CardFooter } from 'reactstrap';
import SelectedCount from './SelectedCount';
import styles from './style.module.scss';
interface Pagination {
  data: AnyType;
  execute: () => void;
  stateKey: AnyType;
  filter: AnyType;

  /**
   * seçilen kayıt sayısı
   */
  selectedCount: number;
}

export const Pagination = memo(
  ({ data, execute, stateKey, filter, selectedCount }: Pagination) => {
    const prevParameters = usePreviousValue<AnyType>(filter);

    useEffect(() => {
      if (prevParameters && (filter.page !== prevParameters.page || filter.pageSize !== prevParameters.pageSize)) {
        execute();
      }
    }, [execute, filter.page, filter.pageSize, prevParameters]);

    return (
      <PageFilterContextConsumer>
        {([state, dispatch]) => (
          <CardFooter className={styles.pagination__footer}>
            <SelectedCount count={selectedCount} />
            <AntdPagination
              className={styles.pagination}
              defaultPageSize={50}
              showSizeChanger
              onChange={(page, pageSize) => {
                dispatch({
                  type: 'SET_FILTER',
                  key: stateKey,
                  value: {
                    ...state[stateKey],
                    requestParameters: { ...filter, page: page, pageSize: pageSize }
                  }
                });
              }}
              current={data.page}
              pageSize={data.pageSize}
              total={data.totalCount}
              hideOnSinglePage={false}
              pageSizeOptions={['50', '100']}
              showQuickJumper
              showTotal={(total: number, range: [number, number]) => `${intl.get('TOPLAM')} : ${total} | ${intl.get('GOSTERILEN')} : ${range[0]} - ${range[1]}`}
            />
          </CardFooter>
        )}
      </PageFilterContextConsumer>
    );
  },
  (prev, next) => {
    return (
      _.isEqual(prev.filter, next.filter) &&
      prev.data.page === next.data.page &&
      prev.data.pageSize === next.data.pageSize &&
      prev.data.totalCount === next.data.totalCount &&
      prev.selectedCount === next.selectedCount
    );
  }
);

export default Pagination;
