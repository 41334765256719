// tslint:disable
// eslint:disable
/**
 * E-Archive API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ArchiveDocumentStatusParameter } from '../interfaces';
// @ts-ignore
import { Attachment } from '../interfaces';
// @ts-ignore
import { BaseReportDivisionStatusParameter } from '../interfaces';
// @ts-ignore
import { Company } from '../interfaces';
// @ts-ignore
import { DespatchDocumentReferencePaginationResult } from '../interfaces';
// @ts-ignore
import { DocumentCancellationDto } from '../interfaces';
// @ts-ignore
import { EmailSendDto } from '../interfaces';
// @ts-ignore
import { FileExportTypeParameter } from '../interfaces';
// @ts-ignore
import { HistoryPaginationResult } from '../interfaces';
// @ts-ignore
import { InternetInfo } from '../interfaces';
// @ts-ignore
import { Invoice } from '../interfaces';
// @ts-ignore
import { InvoiceCanceled } from '../interfaces';
// @ts-ignore
import { InvoiceCanceledPaginationResult } from '../interfaces';
// @ts-ignore
import { InvoiceDetail } from '../interfaces';
// @ts-ignore
import { InvoicePaginationResult } from '../interfaces';
// @ts-ignore
import { InvoiceSetParameter } from '../interfaces';
// @ts-ignore
import { InvoiceTypeParameter } from '../interfaces';
// @ts-ignore
import { LucaTransferStatusParameter } from '../interfaces';
// @ts-ignore
import { MailHistoryPaginationResult } from '../interfaces';
// @ts-ignore
import { MailStatusFilterParameter } from '../interfaces';
// @ts-ignore
import { Note } from '../interfaces';
// @ts-ignore
import { NoteDto } from '../interfaces';
// @ts-ignore
import { NotePaginationResult } from '../interfaces';
// @ts-ignore
import { SalesPlatformParameter } from '../interfaces';
// @ts-ignore
import { SendTypeParameter } from '../interfaces';
// @ts-ignore
import { SmsHistoryPaginationResult } from '../interfaces';
// @ts-ignore
import { SmsStatusFilterParameter } from '../interfaces';
// @ts-ignore
import { TaggingDto } from '../interfaces';
// @ts-ignore
import { TaxInfoPaginationResult } from '../interfaces';
/**
 * InvoicesApi - axios parameter creator
 * @export
 */
export const InvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Belgelere toplu olarak yeni durum atamak için bu uç kullanılabilir.
         * @summary Yeni durum atar
         * @param {InvoiceSetParameter} operation **İşlem**
         * @param {Array<string>} requestBody **Belge uuid listesi**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesBulkOperationPut: async (requestParameters: V1InvoicesBulkOperationPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'operation' is not null or undefined
            if (requestParameters.operation === null || requestParameters.operation === undefined) {
                throw new RequiredError('operation','Required parameter operation was null or undefined when calling v1InvoicesBulkOperationPut.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling v1InvoicesBulkOperationPut.');
            }
            const localVarPath = `/v1/invoices/bulk/{operation}`.replace(`{${"operation"}}`, encodeURIComponent(String(requestParameters.operation)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.requestBody !== undefined ? requestParameters.requestBody : {}) : (requestParameters.requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeyi iptal etmek için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **50** kayıt iptal edilebilir.
         * @summary Belgeyi iptal eder
         * @param {DocumentCancellationDto} [documentCancellationDto] #### İptal için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesCancelPost: async (requestParameters: V1InvoicesCancelPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/invoices/cancel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.documentCancellationDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.documentCancellationDto !== undefined ? requestParameters.documentCancellationDto : {}) : (requestParameters.documentCancellationDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * İptal edilmiş olan e-Arşiv faturalarınıza bu uç ile ulaşabilirsiniz.
         * @summary İptal edilen faturalar
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {string} [startCreateDate] Oluşturma tarihi başlangıcı
         * @param {string} [endCreateDate] Oluşturma tarihi sonu
         * @param {SendTypeParameter} [sendType] Gönderim tipine göre filtreleme yapabilirsiniz
         * @param {SalesPlatformParameter} [salesPlatform] Satış tipine göre filtreleme yapabilirsiniz
         * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
         * @param {string} [startDate] Başlangıç tarihi
         * @param {string} [endDate] Bitiş tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesCanceledGet: async (requestParameters: V1InvoicesCanceledGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1InvoicesCanceledGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1InvoicesCanceledGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1InvoicesCanceledGet.');
            }
            const localVarPath = `/v1/invoices/canceled`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.company !== undefined) {
                localVarQueryParameter['company'] = requestParameters.company;
            }
            if (requestParameters.uuid !== undefined) {
                localVarQueryParameter['uuid'] = requestParameters.uuid;
            }
            if (requestParameters.documentNumber !== undefined) {
                localVarQueryParameter['documentNumber'] = requestParameters.documentNumber;
            }
            if (requestParameters.startCreateDate !== undefined) {
                localVarQueryParameter['startCreateDate'] = (requestParameters.startCreateDate as any instanceof Date) ?
                    (requestParameters.startCreateDate as any).toISOString() : requestParameters.startCreateDate;
            }
            if (requestParameters.endCreateDate !== undefined) {
                localVarQueryParameter['endCreateDate'] = (requestParameters.endCreateDate as any instanceof Date) ?
                    (requestParameters.endCreateDate as any).toISOString() : requestParameters.endCreateDate;
            }
            if (requestParameters.sendType !== undefined) {
                localVarQueryParameter['sendType'] = requestParameters.sendType;
            }
            if (requestParameters.salesPlatform !== undefined) {
                localVarQueryParameter['salesPlatform'] = requestParameters.salesPlatform;
            }
            if (requestParameters.reportDivisionStatus !== undefined) {
                localVarQueryParameter['reportDivisionStatus'] = requestParameters.reportDivisionStatus;
            }
            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }
            if (requestParameters.startDate !== undefined) {
                localVarQueryParameter['startDate'] = requestParameters.startDate;
            }
            if (requestParameters.endDate !== undefined) {
                localVarQueryParameter['endDate'] = requestParameters.endDate;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * İptal edilmiş son 10 e-Arşiv faturanıza bu uç ile ulaşabilirsiniz.
         * @summary İptal edilmiş son 10 e-Arşiv faturayı listeler
         * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesCanceledLastGet: async (requestParameters: V1InvoicesCanceledLastGetRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/invoices/canceled/last`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.reportDivisionStatus !== undefined) {
                localVarQueryParameter['reportDivisionStatus'] = requestParameters.reportDivisionStatus;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * İptal edilmiş ve henüz raporlanmamış olan belgeye ait iptal işlemini geri almak için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **50** iptal kaydı geri alınabilir.
         * @summary İptal işlemini geri alır
         * @param {DocumentCancellationDto} documentCancellationDto **İptal işlemini geri almak için kullanılacak model**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesCanceledWithdrawPost: async (requestParameters: V1InvoicesCanceledWithdrawPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentCancellationDto' is not null or undefined
            if (requestParameters.documentCancellationDto === null || requestParameters.documentCancellationDto === undefined) {
                throw new RequiredError('documentCancellationDto','Required parameter documentCancellationDto was null or undefined when calling v1InvoicesCanceledWithdrawPost.');
            }
            const localVarPath = `/v1/invoices/canceled/withdraw`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.documentCancellationDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.documentCancellationDto !== undefined ? requestParameters.documentCancellationDto : {}) : (requestParameters.documentCancellationDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Taslak belgeleri silmek için bu uç kullanılablir
         * @param {Array<string>} requestBody #### Taslak belge UUID listesi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesDraftsDelete: async (requestParameters: V1InvoicesDraftsDeleteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling v1InvoicesDraftsDelete.');
            }
            const localVarPath = `/v1/invoices/drafts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.requestBody !== undefined ? requestParameters.requestBody : {}) : (requestParameters.requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * E-Arşiv Fatura taslaklarınıza bu uç ile ulaşabilirsiniz.
         * @summary Taslak faturaları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesDraftsGet: async (requestParameters: V1InvoicesDraftsGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1InvoicesDraftsGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1InvoicesDraftsGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1InvoicesDraftsGet.');
            }
            const localVarPath = `/v1/invoices/drafts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.company !== undefined) {
                localVarQueryParameter['company'] = requestParameters.company;
            }
            if (requestParameters.uuid !== undefined) {
                localVarQueryParameter['uuid'] = requestParameters.uuid;
            }
            if (requestParameters.documentNumber !== undefined) {
                localVarQueryParameter['documentNumber'] = requestParameters.documentNumber;
            }
            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgelerinizi mail olarak iletmek için bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **50** farklı belge, **10** farklı mail adresi gönderilebilir.
         * @summary Belgeyi mail olarak iletir
         * @param {EmailSendDto} emailSendDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesEmailSendPost: async (requestParameters: V1InvoicesEmailSendPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailSendDto' is not null or undefined
            if (requestParameters.emailSendDto === null || requestParameters.emailSendDto === undefined) {
                throw new RequiredError('emailSendDto','Required parameter emailSendDto was null or undefined when calling v1InvoicesEmailSendPost.');
            }
            const localVarPath = `/v1/invoices/email/send`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.emailSendDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.emailSendDto !== undefined ? requestParameters.emailSendDto : {}) : (requestParameters.emailSendDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgelerinizi toplu olarak dışarı aktarmak isterseniz bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **100** kayıt dışarı aktarılabilir.    **fileType** Parametre detayları    > **Xml** : Belgenizin XML halini dışarı aktarır    > **Envelope** : Belgenizin Zarf XML halini dışarı aktarır    > **Pdf** : Belgenize ait PDF görüntüsünü dışarı aktarır    > **UnifiedPdf** : ***uuids*** parametresinde gönderilen belgeleri tek PDF dosyası halinde dışarı aktarır    > **Excel** : ***uuids*** parametresinde gönderilen belgeleri Excel\'e aktarır
         * @summary Toplu aktar
         * @param {FileExportTypeParameter} fileType 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesExportFileTypePost: async (requestParameters: V1InvoicesExportFileTypePostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileType' is not null or undefined
            if (requestParameters.fileType === null || requestParameters.fileType === undefined) {
                throw new RequiredError('fileType','Required parameter fileType was null or undefined when calling v1InvoicesExportFileTypePost.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling v1InvoicesExportFileTypePost.');
            }
            const localVarPath = `/v1/invoices/export/{fileType}`.replace(`{${"fileType"}}`, encodeURIComponent(String(requestParameters.fileType)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.requestBody !== undefined ? requestParameters.requestBody : {}) : (requestParameters.requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Düzenlenmiş olan e-Arşiv faturalarınıza bu uç ile ulaşabilirsiniz.
         * @summary Faturaları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {Array<string>} [tags] Faturaya atanmış etiketlere göre filtreleme yapabilirsiniz
         * @param {string} [userNote] Kullanıcı notuna göre filtreleme yapabilirsiniz
         * @param {string} [documentNote] Belge içinde geçen nota göre filtreleme yapabilirsiniz
         * @param {string} [despatchNumber] İrsaliye numarasına göre filtreleme yapabilirsiniz
         * @param {string} [orderNumber] Sipariş numarasına göre filtreleme yapabilirsiniz
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {string} [startCreateDate] Oluşturma tarihi başlangıcı
         * @param {string} [endCreateDate] Oluşturma tarihi sonu
         * @param {ArchiveDocumentStatusParameter} [invoiceStatus] Fatura durumuna göre filtreleme yapabilirsiniz
         * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
         * @param {boolean} [includeCanceledDocuments] İptal edilmiş olan belgeleri de listelemek isterseniz **true** göndermelisiniz
         * @param {SendTypeParameter} [sendType] Gönderim tipine göre filtreleme yapabilirsiniz
         * @param {SalesPlatformParameter} [salesPlatform] Satış tipine göre filtreleme yapabilirsiniz
         * @param {MailStatusFilterParameter} [mailStatus] Mail durumuna göre filtreleme yapabilirsiniz
         * @param {InvoiceTypeParameter} [invoiceTypeCode] Fatura tipine göre filtreleme yapabilirsiniz
         * @param {SmsStatusFilterParameter} [smsStatus] Sms durumuna göre filtreleme yapabilirsiniz
         * @param {LucaTransferStatusParameter} [lucaTransferStatus] 
         * @param {string} [startDate] Başlangıç tarihi
         * @param {string} [endDate] Bitiş tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesGet: async (requestParameters: V1InvoicesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1InvoicesGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1InvoicesGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1InvoicesGet.');
            }
            const localVarPath = `/v1/invoices`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.tags) {
                localVarQueryParameter['tags'] = requestParameters.tags;
            }
            if (requestParameters.userNote !== undefined) {
                localVarQueryParameter['userNote'] = requestParameters.userNote;
            }
            if (requestParameters.documentNote !== undefined) {
                localVarQueryParameter['documentNote'] = requestParameters.documentNote;
            }
            if (requestParameters.despatchNumber !== undefined) {
                localVarQueryParameter['despatchNumber'] = requestParameters.despatchNumber;
            }
            if (requestParameters.orderNumber !== undefined) {
                localVarQueryParameter['orderNumber'] = requestParameters.orderNumber;
            }
            if (requestParameters.company !== undefined) {
                localVarQueryParameter['company'] = requestParameters.company;
            }
            if (requestParameters.uuid !== undefined) {
                localVarQueryParameter['uuid'] = requestParameters.uuid;
            }
            if (requestParameters.documentNumber !== undefined) {
                localVarQueryParameter['documentNumber'] = requestParameters.documentNumber;
            }
            if (requestParameters.startCreateDate !== undefined) {
                localVarQueryParameter['startCreateDate'] = (requestParameters.startCreateDate as any instanceof Date) ?
                    (requestParameters.startCreateDate as any).toISOString() : requestParameters.startCreateDate;
            }
            if (requestParameters.endCreateDate !== undefined) {
                localVarQueryParameter['endCreateDate'] = (requestParameters.endCreateDate as any instanceof Date) ?
                    (requestParameters.endCreateDate as any).toISOString() : requestParameters.endCreateDate;
            }
            if (requestParameters.invoiceStatus !== undefined) {
                localVarQueryParameter['invoiceStatus'] = requestParameters.invoiceStatus;
            }
            if (requestParameters.reportDivisionStatus !== undefined) {
                localVarQueryParameter['reportDivisionStatus'] = requestParameters.reportDivisionStatus;
            }
            if (requestParameters.includeCanceledDocuments !== undefined) {
                localVarQueryParameter['includeCanceledDocuments'] = requestParameters.includeCanceledDocuments;
            }
            if (requestParameters.sendType !== undefined) {
                localVarQueryParameter['sendType'] = requestParameters.sendType;
            }
            if (requestParameters.salesPlatform !== undefined) {
                localVarQueryParameter['salesPlatform'] = requestParameters.salesPlatform;
            }
            if (requestParameters.mailStatus !== undefined) {
                localVarQueryParameter['mailStatus'] = requestParameters.mailStatus;
            }
            if (requestParameters.invoiceTypeCode !== undefined) {
                localVarQueryParameter['invoiceTypeCode'] = requestParameters.invoiceTypeCode;
            }
            if (requestParameters.smsStatus !== undefined) {
                localVarQueryParameter['smsStatus'] = requestParameters.smsStatus;
            }
            if (requestParameters.lucaTransferStatus !== undefined) {
                localVarQueryParameter['lucaTransferStatus'] = requestParameters.lucaTransferStatus;
            }
            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }
            if (requestParameters.startDate !== undefined) {
                localVarQueryParameter['startDate'] = requestParameters.startDate;
            }
            if (requestParameters.endDate !== undefined) {
                localVarQueryParameter['endDate'] = requestParameters.endDate;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Düzenlenmiş olan son 10 e-Arşiv faturanıza bu uç ile ulaşabilirsiniz.
         * @summary Son 10 e-Arşiv faturayı listeler
         * @param {ArchiveDocumentStatusParameter} [invoiceStatus] Fatura durumuna göre filtreleme yapabilirsiniz
         * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
         * @param {boolean} [includeCanceledDocuments] İptal edilmiş olan belgeleri de listelemek isterseniz **true** göndermelisiniz
         * @param {InvoiceTypeParameter} [invoiceTypeCode] Fatura tipine göre filtreleme yapabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesLastGet: async (requestParameters: V1InvoicesLastGetRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/invoices/last`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.invoiceStatus !== undefined) {
                localVarQueryParameter['invoiceStatus'] = requestParameters.invoiceStatus;
            }
            if (requestParameters.reportDivisionStatus !== undefined) {
                localVarQueryParameter['reportDivisionStatus'] = requestParameters.reportDivisionStatus;
            }
            if (requestParameters.includeCanceledDocuments !== undefined) {
                localVarQueryParameter['includeCanceledDocuments'] = requestParameters.includeCanceledDocuments;
            }
            if (requestParameters.invoiceTypeCode !== undefined) {
                localVarQueryParameter['invoiceTypeCode'] = requestParameters.invoiceTypeCode;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye etiket eklemek veya çıkarmak için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **100** belgeye tag eklenebilir/çıkarılabilir.
         * @summary Etiket ekler/çıkarır
         * @param {TaggingDto} taggingDto #### Etiket eklemek veya çıkarmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesTagsPut: async (requestParameters: V1InvoicesTagsPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'taggingDto' is not null or undefined
            if (requestParameters.taggingDto === null || requestParameters.taggingDto === undefined) {
                throw new RequiredError('taggingDto','Required parameter taggingDto was null or undefined when calling v1InvoicesTagsPut.');
            }
            const localVarPath = `/v1/invoices/tags`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.taggingDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.taggingDto !== undefined ? requestParameters.taggingDto : {}) : (requestParameters.taggingDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye ait ek dokumanların listesine bu uç ile ulaşabilirsiniz.
         * @summary Belge eklerini listeler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidAttachmentsGet: async (requestParameters: V1InvoicesUuidAttachmentsGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1InvoicesUuidAttachmentsGet.');
            }
            const localVarPath = `/v1/invoices/{uuid}/attachments`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye ait ek dokumanların indirilmesi için bu uç kullanılabilir.
         * @summary Belge ekini indirir
         * @param {string} uuid **Belge uuid**
         * @param {number} index **Ek belge sırası**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidAttachmentsIndexGet: async (requestParameters: V1InvoicesUuidAttachmentsIndexGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1InvoicesUuidAttachmentsIndexGet.');
            }
            // verify required parameter 'index' is not null or undefined
            if (requestParameters.index === null || requestParameters.index === undefined) {
                throw new RequiredError('index','Required parameter index was null or undefined when calling v1InvoicesUuidAttachmentsIndexGet.');
            }
            const localVarPath = `/v1/invoices/{uuid}/attachments/{index}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))).replace(`{${"index"}}`, encodeURIComponent(String(requestParameters.index)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belge içerisinde belirtilen irsaliye bilgilerine bu uç ile ulaşabilirsiniz.
         * @summary İrsaliye bilgilerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidDespatchesGet: async (requestParameters: V1InvoicesUuidDespatchesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1InvoicesUuidDespatchesGet.');
            }
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1InvoicesUuidDespatchesGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1InvoicesUuidDespatchesGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1InvoicesUuidDespatchesGet.');
            }
            const localVarPath = `/v1/invoices/{uuid}/despatches`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Belge detaylarını getirir
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidGet: async (requestParameters: V1InvoicesUuidGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1InvoicesUuidGet.');
            }
            const localVarPath = `/v1/invoices/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sorgulanan belgeye ait işlem geçmişine bu uç ile ulaşabilirsiniz.
         * @summary İşlem geçmişini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidHistoriesGet: async (requestParameters: V1InvoicesUuidHistoriesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1InvoicesUuidHistoriesGet.');
            }
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1InvoicesUuidHistoriesGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1InvoicesUuidHistoriesGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1InvoicesUuidHistoriesGet.');
            }
            const localVarPath = `/v1/invoices/{uuid}/histories`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye ait HTML görüntüye bu uç ile ulaşabilirsiniz.
         * @summary Belgeyi görüntüler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidHtmlGet: async (requestParameters: V1InvoicesUuidHtmlGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1InvoicesUuidHtmlGet.');
            }
            const localVarPath = `/v1/invoices/{uuid}/html`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sorgulanan belgeye ait internet satış bilgilerine bu uç ile ulaşabilirsiniz.
         * @summary İnternet satış bilgileri getir
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidInternetinfosGet: async (requestParameters: V1InvoicesUuidInternetinfosGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1InvoicesUuidInternetinfosGet.');
            }
            const localVarPath = `/v1/invoices/{uuid}/internetinfos`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sorgulanan belge içerisindeki mail adreslerinin listesine bu uç ile ulaşabilirsiniz.
         * @summary Mail adreslerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidMailaddressesGet: async (requestParameters: V1InvoicesUuidMailaddressesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1InvoicesUuidMailaddressesGet.');
            }
            const localVarPath = `/v1/invoices/{uuid}/mailaddresses`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sorgulanan belgeye ait mail gönderim geçmişine bu uç ile ulaşabilirsiniz.
         * @summary Mail geçmişini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidMailhistoriesGet: async (requestParameters: V1InvoicesUuidMailhistoriesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1InvoicesUuidMailhistoriesGet.');
            }
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1InvoicesUuidMailhistoriesGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1InvoicesUuidMailhistoriesGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1InvoicesUuidMailhistoriesGet.');
            }
            const localVarPath = `/v1/invoices/{uuid}/mailhistories`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye ait PDF dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary PDF İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidPdfGet: async (requestParameters: V1InvoicesUuidPdfGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1InvoicesUuidPdfGet.');
            }
            const localVarPath = `/v1/invoices/{uuid}/pdf`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gelen belgeler için göndericiyi, giden belgeler içinse alıcıyı **Firma Listesi**\'ne kaydetmek için bu uç kullanılabilir.
         * @summary Firma olarak kaydet
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidSavecompanyindocumentPost: async (requestParameters: V1InvoicesUuidSavecompanyindocumentPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1InvoicesUuidSavecompanyindocumentPost.');
            }
            const localVarPath = `/v1/invoices/{uuid}/savecompanyindocument`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sorgulanan belgeye ait sms gönderim geçmişine bu uç ile ulaşabilirsiniz.
         * @summary Sms geçmişini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidSmshistoriesGet: async (requestParameters: V1InvoicesUuidSmshistoriesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1InvoicesUuidSmshistoriesGet.');
            }
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1InvoicesUuidSmshistoriesGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1InvoicesUuidSmshistoriesGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1InvoicesUuidSmshistoriesGet.');
            }
            const localVarPath = `/v1/invoices/{uuid}/smshistories`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belge içerisinde belirtilen vergi bilgilerine bu uç ile ulaşabilirsiniz.
         * @summary Vergi bilgilerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidTaxesGet: async (requestParameters: V1InvoicesUuidTaxesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1InvoicesUuidTaxesGet.');
            }
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1InvoicesUuidTaxesGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1InvoicesUuidTaxesGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1InvoicesUuidTaxesGet.');
            }
            const localVarPath = `/v1/invoices/{uuid}/taxes`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sorgulanan belgeye ait kullanıcı tarafından eklenmiş notlara bu uç ile ulaşabilirsiniz.
         * @summary Kullanıcı notu listeler
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidUsernotesGet: async (requestParameters: V1InvoicesUuidUsernotesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1InvoicesUuidUsernotesGet.');
            }
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1InvoicesUuidUsernotesGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1InvoicesUuidUsernotesGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1InvoicesUuidUsernotesGet.');
            }
            const localVarPath = `/v1/invoices/{uuid}/usernotes`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Kullanıcı tarafından oluşturulmuş olan notu silmek için bu uç kullanılabilir.
         * @summary Kullanıcı notunu siler
         * @param {string} uuid **Belge uuid**
         * @param {string} id **Not id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidUsernotesIdDelete: async (requestParameters: V1InvoicesUuidUsernotesIdDeleteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1InvoicesUuidUsernotesIdDelete.');
            }
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1InvoicesUuidUsernotesIdDelete.');
            }
            const localVarPath = `/v1/invoices/{uuid}/usernotes/{id}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye daha önce eklenmiş olan kullanıcı notunu güncellemek için bu uç kullanılabilir.
         * @summary Kullanıcı notunu günceller
         * @param {string} uuid #### Belgeye ait uuid
         * @param {string} id #### Not id
         * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidUsernotesIdPut: async (requestParameters: V1InvoicesUuidUsernotesIdPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1InvoicesUuidUsernotesIdPut.');
            }
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1InvoicesUuidUsernotesIdPut.');
            }
            // verify required parameter 'noteDto' is not null or undefined
            if (requestParameters.noteDto === null || requestParameters.noteDto === undefined) {
                throw new RequiredError('noteDto','Required parameter noteDto was null or undefined when calling v1InvoicesUuidUsernotesIdPut.');
            }
            const localVarPath = `/v1/invoices/{uuid}/usernotes/{id}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.noteDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.noteDto !== undefined ? requestParameters.noteDto : {}) : (requestParameters.noteDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye kullanıcı notu eklemek için bu uç kullanılabilir.
         * @summary Kullanıcı notu ekler
         * @param {string} uuid #### Belge uuid
         * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidUsernotesPost: async (requestParameters: V1InvoicesUuidUsernotesPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1InvoicesUuidUsernotesPost.');
            }
            // verify required parameter 'noteDto' is not null or undefined
            if (requestParameters.noteDto === null || requestParameters.noteDto === undefined) {
                throw new RequiredError('noteDto','Required parameter noteDto was null or undefined when calling v1InvoicesUuidUsernotesPost.');
            }
            const localVarPath = `/v1/invoices/{uuid}/usernotes`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.noteDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.noteDto !== undefined ? requestParameters.noteDto : {}) : (requestParameters.noteDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye ait XML dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary XML İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidXmlGet: async (requestParameters: V1InvoicesUuidXmlGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1InvoicesUuidXmlGet.');
            }
            const localVarPath = `/v1/invoices/{uuid}/xml`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoicesApi - functional programming interface
 * @export
 */
export const InvoicesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Belgelere toplu olarak yeni durum atamak için bu uç kullanılabilir.
         * @summary Yeni durum atar
         * @param {InvoiceSetParameter} operation **İşlem**
         * @param {Array<string>} requestBody **Belge uuid listesi**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesBulkOperationPut(requestParameters: V1InvoicesBulkOperationPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesBulkOperationPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeyi iptal etmek için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **50** kayıt iptal edilebilir.
         * @summary Belgeyi iptal eder
         * @param {DocumentCancellationDto} [documentCancellationDto] #### İptal için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesCancelPost(requestParameters: V1InvoicesCancelPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesCancelPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * İptal edilmiş olan e-Arşiv faturalarınıza bu uç ile ulaşabilirsiniz.
         * @summary İptal edilen faturalar
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {string} [startCreateDate] Oluşturma tarihi başlangıcı
         * @param {string} [endCreateDate] Oluşturma tarihi sonu
         * @param {SendTypeParameter} [sendType] Gönderim tipine göre filtreleme yapabilirsiniz
         * @param {SalesPlatformParameter} [salesPlatform] Satış tipine göre filtreleme yapabilirsiniz
         * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
         * @param {string} [startDate] Başlangıç tarihi
         * @param {string} [endDate] Bitiş tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesCanceledGet(requestParameters: V1InvoicesCanceledGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceCanceledPaginationResult>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesCanceledGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * İptal edilmiş son 10 e-Arşiv faturanıza bu uç ile ulaşabilirsiniz.
         * @summary İptal edilmiş son 10 e-Arşiv faturayı listeler
         * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesCanceledLastGet(requestParameters: V1InvoicesCanceledLastGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceCanceled>>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesCanceledLastGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * İptal edilmiş ve henüz raporlanmamış olan belgeye ait iptal işlemini geri almak için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **50** iptal kaydı geri alınabilir.
         * @summary İptal işlemini geri alır
         * @param {DocumentCancellationDto} documentCancellationDto **İptal işlemini geri almak için kullanılacak model**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesCanceledWithdrawPost(requestParameters: V1InvoicesCanceledWithdrawPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesCanceledWithdrawPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Taslak belgeleri silmek için bu uç kullanılablir
         * @param {Array<string>} requestBody #### Taslak belge UUID listesi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesDraftsDelete(requestParameters: V1InvoicesDraftsDeleteRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesDraftsDelete(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * E-Arşiv Fatura taslaklarınıza bu uç ile ulaşabilirsiniz.
         * @summary Taslak faturaları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesDraftsGet(requestParameters: V1InvoicesDraftsGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoicePaginationResult>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesDraftsGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgelerinizi mail olarak iletmek için bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **50** farklı belge, **10** farklı mail adresi gönderilebilir.
         * @summary Belgeyi mail olarak iletir
         * @param {EmailSendDto} emailSendDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesEmailSendPost(requestParameters: V1InvoicesEmailSendPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesEmailSendPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgelerinizi toplu olarak dışarı aktarmak isterseniz bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **100** kayıt dışarı aktarılabilir.    **fileType** Parametre detayları    > **Xml** : Belgenizin XML halini dışarı aktarır    > **Envelope** : Belgenizin Zarf XML halini dışarı aktarır    > **Pdf** : Belgenize ait PDF görüntüsünü dışarı aktarır    > **UnifiedPdf** : ***uuids*** parametresinde gönderilen belgeleri tek PDF dosyası halinde dışarı aktarır    > **Excel** : ***uuids*** parametresinde gönderilen belgeleri Excel\'e aktarır
         * @summary Toplu aktar
         * @param {FileExportTypeParameter} fileType 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesExportFileTypePost(requestParameters: V1InvoicesExportFileTypePostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesExportFileTypePost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Düzenlenmiş olan e-Arşiv faturalarınıza bu uç ile ulaşabilirsiniz.
         * @summary Faturaları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {Array<string>} [tags] Faturaya atanmış etiketlere göre filtreleme yapabilirsiniz
         * @param {string} [userNote] Kullanıcı notuna göre filtreleme yapabilirsiniz
         * @param {string} [documentNote] Belge içinde geçen nota göre filtreleme yapabilirsiniz
         * @param {string} [despatchNumber] İrsaliye numarasına göre filtreleme yapabilirsiniz
         * @param {string} [orderNumber] Sipariş numarasına göre filtreleme yapabilirsiniz
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {string} [startCreateDate] Oluşturma tarihi başlangıcı
         * @param {string} [endCreateDate] Oluşturma tarihi sonu
         * @param {ArchiveDocumentStatusParameter} [invoiceStatus] Fatura durumuna göre filtreleme yapabilirsiniz
         * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
         * @param {boolean} [includeCanceledDocuments] İptal edilmiş olan belgeleri de listelemek isterseniz **true** göndermelisiniz
         * @param {SendTypeParameter} [sendType] Gönderim tipine göre filtreleme yapabilirsiniz
         * @param {SalesPlatformParameter} [salesPlatform] Satış tipine göre filtreleme yapabilirsiniz
         * @param {MailStatusFilterParameter} [mailStatus] Mail durumuna göre filtreleme yapabilirsiniz
         * @param {InvoiceTypeParameter} [invoiceTypeCode] Fatura tipine göre filtreleme yapabilirsiniz
         * @param {SmsStatusFilterParameter} [smsStatus] Sms durumuna göre filtreleme yapabilirsiniz
         * @param {LucaTransferStatusParameter} [lucaTransferStatus] 
         * @param {string} [startDate] Başlangıç tarihi
         * @param {string} [endDate] Bitiş tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesGet(requestParameters: V1InvoicesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoicePaginationResult>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Düzenlenmiş olan son 10 e-Arşiv faturanıza bu uç ile ulaşabilirsiniz.
         * @summary Son 10 e-Arşiv faturayı listeler
         * @param {ArchiveDocumentStatusParameter} [invoiceStatus] Fatura durumuna göre filtreleme yapabilirsiniz
         * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
         * @param {boolean} [includeCanceledDocuments] İptal edilmiş olan belgeleri de listelemek isterseniz **true** göndermelisiniz
         * @param {InvoiceTypeParameter} [invoiceTypeCode] Fatura tipine göre filtreleme yapabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesLastGet(requestParameters: V1InvoicesLastGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Invoice>>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesLastGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye etiket eklemek veya çıkarmak için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **100** belgeye tag eklenebilir/çıkarılabilir.
         * @summary Etiket ekler/çıkarır
         * @param {TaggingDto} taggingDto #### Etiket eklemek veya çıkarmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesTagsPut(requestParameters: V1InvoicesTagsPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesTagsPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye ait ek dokumanların listesine bu uç ile ulaşabilirsiniz.
         * @summary Belge eklerini listeler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesUuidAttachmentsGet(requestParameters: V1InvoicesUuidAttachmentsGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Attachment>>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesUuidAttachmentsGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye ait ek dokumanların indirilmesi için bu uç kullanılabilir.
         * @summary Belge ekini indirir
         * @param {string} uuid **Belge uuid**
         * @param {number} index **Ek belge sırası**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesUuidAttachmentsIndexGet(requestParameters: V1InvoicesUuidAttachmentsIndexGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Attachment>>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesUuidAttachmentsIndexGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belge içerisinde belirtilen irsaliye bilgilerine bu uç ile ulaşabilirsiniz.
         * @summary İrsaliye bilgilerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesUuidDespatchesGet(requestParameters: V1InvoicesUuidDespatchesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DespatchDocumentReferencePaginationResult>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesUuidDespatchesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Belge detaylarını getirir
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesUuidGet(requestParameters: V1InvoicesUuidGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceDetail>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesUuidGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Sorgulanan belgeye ait işlem geçmişine bu uç ile ulaşabilirsiniz.
         * @summary İşlem geçmişini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesUuidHistoriesGet(requestParameters: V1InvoicesUuidHistoriesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoryPaginationResult>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesUuidHistoriesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye ait HTML görüntüye bu uç ile ulaşabilirsiniz.
         * @summary Belgeyi görüntüler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesUuidHtmlGet(requestParameters: V1InvoicesUuidHtmlGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesUuidHtmlGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Sorgulanan belgeye ait internet satış bilgilerine bu uç ile ulaşabilirsiniz.
         * @summary İnternet satış bilgileri getir
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesUuidInternetinfosGet(requestParameters: V1InvoicesUuidInternetinfosGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternetInfo>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesUuidInternetinfosGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Sorgulanan belge içerisindeki mail adreslerinin listesine bu uç ile ulaşabilirsiniz.
         * @summary Mail adreslerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesUuidMailaddressesGet(requestParameters: V1InvoicesUuidMailaddressesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesUuidMailaddressesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Sorgulanan belgeye ait mail gönderim geçmişine bu uç ile ulaşabilirsiniz.
         * @summary Mail geçmişini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesUuidMailhistoriesGet(requestParameters: V1InvoicesUuidMailhistoriesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailHistoryPaginationResult>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesUuidMailhistoriesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye ait PDF dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary PDF İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesUuidPdfGet(requestParameters: V1InvoicesUuidPdfGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesUuidPdfGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Gelen belgeler için göndericiyi, giden belgeler içinse alıcıyı **Firma Listesi**\'ne kaydetmek için bu uç kullanılabilir.
         * @summary Firma olarak kaydet
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesUuidSavecompanyindocumentPost(requestParameters: V1InvoicesUuidSavecompanyindocumentPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesUuidSavecompanyindocumentPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Sorgulanan belgeye ait sms gönderim geçmişine bu uç ile ulaşabilirsiniz.
         * @summary Sms geçmişini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesUuidSmshistoriesGet(requestParameters: V1InvoicesUuidSmshistoriesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SmsHistoryPaginationResult>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesUuidSmshistoriesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belge içerisinde belirtilen vergi bilgilerine bu uç ile ulaşabilirsiniz.
         * @summary Vergi bilgilerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesUuidTaxesGet(requestParameters: V1InvoicesUuidTaxesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxInfoPaginationResult>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesUuidTaxesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Sorgulanan belgeye ait kullanıcı tarafından eklenmiş notlara bu uç ile ulaşabilirsiniz.
         * @summary Kullanıcı notu listeler
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesUuidUsernotesGet(requestParameters: V1InvoicesUuidUsernotesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotePaginationResult>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesUuidUsernotesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Kullanıcı tarafından oluşturulmuş olan notu silmek için bu uç kullanılabilir.
         * @summary Kullanıcı notunu siler
         * @param {string} uuid **Belge uuid**
         * @param {string} id **Not id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesUuidUsernotesIdDelete(requestParameters: V1InvoicesUuidUsernotesIdDeleteRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesUuidUsernotesIdDelete(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye daha önce eklenmiş olan kullanıcı notunu güncellemek için bu uç kullanılabilir.
         * @summary Kullanıcı notunu günceller
         * @param {string} uuid #### Belgeye ait uuid
         * @param {string} id #### Not id
         * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesUuidUsernotesIdPut(requestParameters: V1InvoicesUuidUsernotesIdPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesUuidUsernotesIdPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye kullanıcı notu eklemek için bu uç kullanılabilir.
         * @summary Kullanıcı notu ekler
         * @param {string} uuid #### Belge uuid
         * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesUuidUsernotesPost(requestParameters: V1InvoicesUuidUsernotesPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Note>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesUuidUsernotesPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye ait XML dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary XML İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesUuidXmlGet(requestParameters: V1InvoicesUuidXmlGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).v1InvoicesUuidXmlGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * InvoicesApi - factory interface
 * @export
 */
export const InvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Belgelere toplu olarak yeni durum atamak için bu uç kullanılabilir.
         * @summary Yeni durum atar
         * @param {InvoiceSetParameter} operation **İşlem**
         * @param {Array<string>} requestBody **Belge uuid listesi**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesBulkOperationPut(requestParameters: V1InvoicesBulkOperationPutRequest, options?: any): AxiosPromise<void> {
            return InvoicesApiFp(configuration).v1InvoicesBulkOperationPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeyi iptal etmek için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **50** kayıt iptal edilebilir.
         * @summary Belgeyi iptal eder
         * @param {DocumentCancellationDto} [documentCancellationDto] #### İptal için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesCancelPost(requestParameters: V1InvoicesCancelPostRequest, options?: any): AxiosPromise<void> {
            return InvoicesApiFp(configuration).v1InvoicesCancelPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * İptal edilmiş olan e-Arşiv faturalarınıza bu uç ile ulaşabilirsiniz.
         * @summary İptal edilen faturalar
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {string} [startCreateDate] Oluşturma tarihi başlangıcı
         * @param {string} [endCreateDate] Oluşturma tarihi sonu
         * @param {SendTypeParameter} [sendType] Gönderim tipine göre filtreleme yapabilirsiniz
         * @param {SalesPlatformParameter} [salesPlatform] Satış tipine göre filtreleme yapabilirsiniz
         * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
         * @param {string} [startDate] Başlangıç tarihi
         * @param {string} [endDate] Bitiş tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesCanceledGet(requestParameters: V1InvoicesCanceledGetRequest, options?: any): AxiosPromise<InvoiceCanceledPaginationResult> {
            return InvoicesApiFp(configuration).v1InvoicesCanceledGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * İptal edilmiş son 10 e-Arşiv faturanıza bu uç ile ulaşabilirsiniz.
         * @summary İptal edilmiş son 10 e-Arşiv faturayı listeler
         * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesCanceledLastGet(requestParameters: V1InvoicesCanceledLastGetRequest, options?: any): AxiosPromise<Array<InvoiceCanceled>> {
            return InvoicesApiFp(configuration).v1InvoicesCanceledLastGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * İptal edilmiş ve henüz raporlanmamış olan belgeye ait iptal işlemini geri almak için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **50** iptal kaydı geri alınabilir.
         * @summary İptal işlemini geri alır
         * @param {DocumentCancellationDto} documentCancellationDto **İptal işlemini geri almak için kullanılacak model**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesCanceledWithdrawPost(requestParameters: V1InvoicesCanceledWithdrawPostRequest, options?: any): AxiosPromise<void> {
            return InvoicesApiFp(configuration).v1InvoicesCanceledWithdrawPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Taslak belgeleri silmek için bu uç kullanılablir
         * @param {Array<string>} requestBody #### Taslak belge UUID listesi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesDraftsDelete(requestParameters: V1InvoicesDraftsDeleteRequest, options?: any): AxiosPromise<void> {
            return InvoicesApiFp(configuration).v1InvoicesDraftsDelete(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * E-Arşiv Fatura taslaklarınıza bu uç ile ulaşabilirsiniz.
         * @summary Taslak faturaları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesDraftsGet(requestParameters: V1InvoicesDraftsGetRequest, options?: any): AxiosPromise<InvoicePaginationResult> {
            return InvoicesApiFp(configuration).v1InvoicesDraftsGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgelerinizi mail olarak iletmek için bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **50** farklı belge, **10** farklı mail adresi gönderilebilir.
         * @summary Belgeyi mail olarak iletir
         * @param {EmailSendDto} emailSendDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesEmailSendPost(requestParameters: V1InvoicesEmailSendPostRequest, options?: any): AxiosPromise<void> {
            return InvoicesApiFp(configuration).v1InvoicesEmailSendPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgelerinizi toplu olarak dışarı aktarmak isterseniz bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **100** kayıt dışarı aktarılabilir.    **fileType** Parametre detayları    > **Xml** : Belgenizin XML halini dışarı aktarır    > **Envelope** : Belgenizin Zarf XML halini dışarı aktarır    > **Pdf** : Belgenize ait PDF görüntüsünü dışarı aktarır    > **UnifiedPdf** : ***uuids*** parametresinde gönderilen belgeleri tek PDF dosyası halinde dışarı aktarır    > **Excel** : ***uuids*** parametresinde gönderilen belgeleri Excel\'e aktarır
         * @summary Toplu aktar
         * @param {FileExportTypeParameter} fileType 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesExportFileTypePost(requestParameters: V1InvoicesExportFileTypePostRequest, options?: any): AxiosPromise<void> {
            return InvoicesApiFp(configuration).v1InvoicesExportFileTypePost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Düzenlenmiş olan e-Arşiv faturalarınıza bu uç ile ulaşabilirsiniz.
         * @summary Faturaları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {Array<string>} [tags] Faturaya atanmış etiketlere göre filtreleme yapabilirsiniz
         * @param {string} [userNote] Kullanıcı notuna göre filtreleme yapabilirsiniz
         * @param {string} [documentNote] Belge içinde geçen nota göre filtreleme yapabilirsiniz
         * @param {string} [despatchNumber] İrsaliye numarasına göre filtreleme yapabilirsiniz
         * @param {string} [orderNumber] Sipariş numarasına göre filtreleme yapabilirsiniz
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {string} [startCreateDate] Oluşturma tarihi başlangıcı
         * @param {string} [endCreateDate] Oluşturma tarihi sonu
         * @param {ArchiveDocumentStatusParameter} [invoiceStatus] Fatura durumuna göre filtreleme yapabilirsiniz
         * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
         * @param {boolean} [includeCanceledDocuments] İptal edilmiş olan belgeleri de listelemek isterseniz **true** göndermelisiniz
         * @param {SendTypeParameter} [sendType] Gönderim tipine göre filtreleme yapabilirsiniz
         * @param {SalesPlatformParameter} [salesPlatform] Satış tipine göre filtreleme yapabilirsiniz
         * @param {MailStatusFilterParameter} [mailStatus] Mail durumuna göre filtreleme yapabilirsiniz
         * @param {InvoiceTypeParameter} [invoiceTypeCode] Fatura tipine göre filtreleme yapabilirsiniz
         * @param {SmsStatusFilterParameter} [smsStatus] Sms durumuna göre filtreleme yapabilirsiniz
         * @param {LucaTransferStatusParameter} [lucaTransferStatus] 
         * @param {string} [startDate] Başlangıç tarihi
         * @param {string} [endDate] Bitiş tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesGet(requestParameters: V1InvoicesGetRequest, options?: any): AxiosPromise<InvoicePaginationResult> {
            return InvoicesApiFp(configuration).v1InvoicesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Düzenlenmiş olan son 10 e-Arşiv faturanıza bu uç ile ulaşabilirsiniz.
         * @summary Son 10 e-Arşiv faturayı listeler
         * @param {ArchiveDocumentStatusParameter} [invoiceStatus] Fatura durumuna göre filtreleme yapabilirsiniz
         * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
         * @param {boolean} [includeCanceledDocuments] İptal edilmiş olan belgeleri de listelemek isterseniz **true** göndermelisiniz
         * @param {InvoiceTypeParameter} [invoiceTypeCode] Fatura tipine göre filtreleme yapabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesLastGet(requestParameters: V1InvoicesLastGetRequest, options?: any): AxiosPromise<Array<Invoice>> {
            return InvoicesApiFp(configuration).v1InvoicesLastGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye etiket eklemek veya çıkarmak için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **100** belgeye tag eklenebilir/çıkarılabilir.
         * @summary Etiket ekler/çıkarır
         * @param {TaggingDto} taggingDto #### Etiket eklemek veya çıkarmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesTagsPut(requestParameters: V1InvoicesTagsPutRequest, options?: any): AxiosPromise<void> {
            return InvoicesApiFp(configuration).v1InvoicesTagsPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye ait ek dokumanların listesine bu uç ile ulaşabilirsiniz.
         * @summary Belge eklerini listeler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidAttachmentsGet(requestParameters: V1InvoicesUuidAttachmentsGetRequest, options?: any): AxiosPromise<Array<Attachment>> {
            return InvoicesApiFp(configuration).v1InvoicesUuidAttachmentsGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye ait ek dokumanların indirilmesi için bu uç kullanılabilir.
         * @summary Belge ekini indirir
         * @param {string} uuid **Belge uuid**
         * @param {number} index **Ek belge sırası**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidAttachmentsIndexGet(requestParameters: V1InvoicesUuidAttachmentsIndexGetRequest, options?: any): AxiosPromise<Array<Attachment>> {
            return InvoicesApiFp(configuration).v1InvoicesUuidAttachmentsIndexGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belge içerisinde belirtilen irsaliye bilgilerine bu uç ile ulaşabilirsiniz.
         * @summary İrsaliye bilgilerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidDespatchesGet(requestParameters: V1InvoicesUuidDespatchesGetRequest, options?: any): AxiosPromise<DespatchDocumentReferencePaginationResult> {
            return InvoicesApiFp(configuration).v1InvoicesUuidDespatchesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Belge detaylarını getirir
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidGet(requestParameters: V1InvoicesUuidGetRequest, options?: any): AxiosPromise<InvoiceDetail> {
            return InvoicesApiFp(configuration).v1InvoicesUuidGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Sorgulanan belgeye ait işlem geçmişine bu uç ile ulaşabilirsiniz.
         * @summary İşlem geçmişini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidHistoriesGet(requestParameters: V1InvoicesUuidHistoriesGetRequest, options?: any): AxiosPromise<HistoryPaginationResult> {
            return InvoicesApiFp(configuration).v1InvoicesUuidHistoriesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye ait HTML görüntüye bu uç ile ulaşabilirsiniz.
         * @summary Belgeyi görüntüler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidHtmlGet(requestParameters: V1InvoicesUuidHtmlGetRequest, options?: any): AxiosPromise<void> {
            return InvoicesApiFp(configuration).v1InvoicesUuidHtmlGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Sorgulanan belgeye ait internet satış bilgilerine bu uç ile ulaşabilirsiniz.
         * @summary İnternet satış bilgileri getir
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidInternetinfosGet(requestParameters: V1InvoicesUuidInternetinfosGetRequest, options?: any): AxiosPromise<InternetInfo> {
            return InvoicesApiFp(configuration).v1InvoicesUuidInternetinfosGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Sorgulanan belge içerisindeki mail adreslerinin listesine bu uç ile ulaşabilirsiniz.
         * @summary Mail adreslerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidMailaddressesGet(requestParameters: V1InvoicesUuidMailaddressesGetRequest, options?: any): AxiosPromise<Array<string>> {
            return InvoicesApiFp(configuration).v1InvoicesUuidMailaddressesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Sorgulanan belgeye ait mail gönderim geçmişine bu uç ile ulaşabilirsiniz.
         * @summary Mail geçmişini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidMailhistoriesGet(requestParameters: V1InvoicesUuidMailhistoriesGetRequest, options?: any): AxiosPromise<MailHistoryPaginationResult> {
            return InvoicesApiFp(configuration).v1InvoicesUuidMailhistoriesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye ait PDF dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary PDF İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidPdfGet(requestParameters: V1InvoicesUuidPdfGetRequest, options?: any): AxiosPromise<void> {
            return InvoicesApiFp(configuration).v1InvoicesUuidPdfGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Gelen belgeler için göndericiyi, giden belgeler içinse alıcıyı **Firma Listesi**\'ne kaydetmek için bu uç kullanılabilir.
         * @summary Firma olarak kaydet
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidSavecompanyindocumentPost(requestParameters: V1InvoicesUuidSavecompanyindocumentPostRequest, options?: any): AxiosPromise<Company> {
            return InvoicesApiFp(configuration).v1InvoicesUuidSavecompanyindocumentPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Sorgulanan belgeye ait sms gönderim geçmişine bu uç ile ulaşabilirsiniz.
         * @summary Sms geçmişini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidSmshistoriesGet(requestParameters: V1InvoicesUuidSmshistoriesGetRequest, options?: any): AxiosPromise<SmsHistoryPaginationResult> {
            return InvoicesApiFp(configuration).v1InvoicesUuidSmshistoriesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belge içerisinde belirtilen vergi bilgilerine bu uç ile ulaşabilirsiniz.
         * @summary Vergi bilgilerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidTaxesGet(requestParameters: V1InvoicesUuidTaxesGetRequest, options?: any): AxiosPromise<TaxInfoPaginationResult> {
            return InvoicesApiFp(configuration).v1InvoicesUuidTaxesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Sorgulanan belgeye ait kullanıcı tarafından eklenmiş notlara bu uç ile ulaşabilirsiniz.
         * @summary Kullanıcı notu listeler
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidUsernotesGet(requestParameters: V1InvoicesUuidUsernotesGetRequest, options?: any): AxiosPromise<NotePaginationResult> {
            return InvoicesApiFp(configuration).v1InvoicesUuidUsernotesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Kullanıcı tarafından oluşturulmuş olan notu silmek için bu uç kullanılabilir.
         * @summary Kullanıcı notunu siler
         * @param {string} uuid **Belge uuid**
         * @param {string} id **Not id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidUsernotesIdDelete(requestParameters: V1InvoicesUuidUsernotesIdDeleteRequest, options?: any): AxiosPromise<void> {
            return InvoicesApiFp(configuration).v1InvoicesUuidUsernotesIdDelete(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye daha önce eklenmiş olan kullanıcı notunu güncellemek için bu uç kullanılabilir.
         * @summary Kullanıcı notunu günceller
         * @param {string} uuid #### Belgeye ait uuid
         * @param {string} id #### Not id
         * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidUsernotesIdPut(requestParameters: V1InvoicesUuidUsernotesIdPutRequest, options?: any): AxiosPromise<void> {
            return InvoicesApiFp(configuration).v1InvoicesUuidUsernotesIdPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye kullanıcı notu eklemek için bu uç kullanılabilir.
         * @summary Kullanıcı notu ekler
         * @param {string} uuid #### Belge uuid
         * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidUsernotesPost(requestParameters: V1InvoicesUuidUsernotesPostRequest, options?: any): AxiosPromise<Note> {
            return InvoicesApiFp(configuration).v1InvoicesUuidUsernotesPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye ait XML dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary XML İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesUuidXmlGet(requestParameters: V1InvoicesUuidXmlGetRequest, options?: any): AxiosPromise<void> {
            return InvoicesApiFp(configuration).v1InvoicesUuidXmlGet(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoicesApi - object-oriented interface
 * @export
 * @class InvoicesApi
 * @extends {BaseAPI}
 */
export class InvoicesApi extends BaseAPI {
    /**
     * Belgelere toplu olarak yeni durum atamak için bu uç kullanılabilir.
     * @summary Yeni durum atar
     * @param {InvoiceSetParameter} operation **İşlem**
     * @param {Array<string>} requestBody **Belge uuid listesi**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesBulkOperationPut(requestParameters: V1InvoicesBulkOperationPutRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesBulkOperationPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeyi iptal etmek için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **50** kayıt iptal edilebilir.
     * @summary Belgeyi iptal eder
     * @param {DocumentCancellationDto} [documentCancellationDto] #### İptal için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesCancelPost(requestParameters: V1InvoicesCancelPostRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesCancelPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * İptal edilmiş olan e-Arşiv faturalarınıza bu uç ile ulaşabilirsiniz.
     * @summary İptal edilen faturalar
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
     * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
     * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
     * @param {string} [startCreateDate] Oluşturma tarihi başlangıcı
     * @param {string} [endCreateDate] Oluşturma tarihi sonu
     * @param {SendTypeParameter} [sendType] Gönderim tipine göre filtreleme yapabilirsiniz
     * @param {SalesPlatformParameter} [salesPlatform] Satış tipine göre filtreleme yapabilirsiniz
     * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
     * @param {string} [startDate] Başlangıç tarihi
     * @param {string} [endDate] Bitiş tarihi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesCanceledGet(requestParameters: V1InvoicesCanceledGetRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesCanceledGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * İptal edilmiş son 10 e-Arşiv faturanıza bu uç ile ulaşabilirsiniz.
     * @summary İptal edilmiş son 10 e-Arşiv faturayı listeler
     * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesCanceledLastGet(requestParameters: V1InvoicesCanceledLastGetRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesCanceledLastGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * İptal edilmiş ve henüz raporlanmamış olan belgeye ait iptal işlemini geri almak için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **50** iptal kaydı geri alınabilir.
     * @summary İptal işlemini geri alır
     * @param {DocumentCancellationDto} documentCancellationDto **İptal işlemini geri almak için kullanılacak model**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesCanceledWithdrawPost(requestParameters: V1InvoicesCanceledWithdrawPostRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesCanceledWithdrawPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Taslak belgeleri silmek için bu uç kullanılablir
     * @param {Array<string>} requestBody #### Taslak belge UUID listesi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesDraftsDelete(requestParameters: V1InvoicesDraftsDeleteRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesDraftsDelete(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * E-Arşiv Fatura taslaklarınıza bu uç ile ulaşabilirsiniz.
     * @summary Taslak faturaları listeler
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
     * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
     * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesDraftsGet(requestParameters: V1InvoicesDraftsGetRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesDraftsGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgelerinizi mail olarak iletmek için bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **50** farklı belge, **10** farklı mail adresi gönderilebilir.
     * @summary Belgeyi mail olarak iletir
     * @param {EmailSendDto} emailSendDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesEmailSendPost(requestParameters: V1InvoicesEmailSendPostRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesEmailSendPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgelerinizi toplu olarak dışarı aktarmak isterseniz bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **100** kayıt dışarı aktarılabilir.    **fileType** Parametre detayları    > **Xml** : Belgenizin XML halini dışarı aktarır    > **Envelope** : Belgenizin Zarf XML halini dışarı aktarır    > **Pdf** : Belgenize ait PDF görüntüsünü dışarı aktarır    > **UnifiedPdf** : ***uuids*** parametresinde gönderilen belgeleri tek PDF dosyası halinde dışarı aktarır    > **Excel** : ***uuids*** parametresinde gönderilen belgeleri Excel\'e aktarır
     * @summary Toplu aktar
     * @param {FileExportTypeParameter} fileType 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesExportFileTypePost(requestParameters: V1InvoicesExportFileTypePostRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesExportFileTypePost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Düzenlenmiş olan e-Arşiv faturalarınıza bu uç ile ulaşabilirsiniz.
     * @summary Faturaları listeler
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {Array<string>} [tags] Faturaya atanmış etiketlere göre filtreleme yapabilirsiniz
     * @param {string} [userNote] Kullanıcı notuna göre filtreleme yapabilirsiniz
     * @param {string} [documentNote] Belge içinde geçen nota göre filtreleme yapabilirsiniz
     * @param {string} [despatchNumber] İrsaliye numarasına göre filtreleme yapabilirsiniz
     * @param {string} [orderNumber] Sipariş numarasına göre filtreleme yapabilirsiniz
     * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
     * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
     * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
     * @param {string} [startCreateDate] Oluşturma tarihi başlangıcı
     * @param {string} [endCreateDate] Oluşturma tarihi sonu
     * @param {ArchiveDocumentStatusParameter} [invoiceStatus] Fatura durumuna göre filtreleme yapabilirsiniz
     * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
     * @param {boolean} [includeCanceledDocuments] İptal edilmiş olan belgeleri de listelemek isterseniz **true** göndermelisiniz
     * @param {SendTypeParameter} [sendType] Gönderim tipine göre filtreleme yapabilirsiniz
     * @param {SalesPlatformParameter} [salesPlatform] Satış tipine göre filtreleme yapabilirsiniz
     * @param {MailStatusFilterParameter} [mailStatus] Mail durumuna göre filtreleme yapabilirsiniz
     * @param {InvoiceTypeParameter} [invoiceTypeCode] Fatura tipine göre filtreleme yapabilirsiniz
     * @param {SmsStatusFilterParameter} [smsStatus] Sms durumuna göre filtreleme yapabilirsiniz
     * @param {LucaTransferStatusParameter} [lucaTransferStatus] 
     * @param {string} [startDate] Başlangıç tarihi
     * @param {string} [endDate] Bitiş tarihi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesGet(requestParameters: V1InvoicesGetRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Düzenlenmiş olan son 10 e-Arşiv faturanıza bu uç ile ulaşabilirsiniz.
     * @summary Son 10 e-Arşiv faturayı listeler
     * @param {ArchiveDocumentStatusParameter} [invoiceStatus] Fatura durumuna göre filtreleme yapabilirsiniz
     * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
     * @param {boolean} [includeCanceledDocuments] İptal edilmiş olan belgeleri de listelemek isterseniz **true** göndermelisiniz
     * @param {InvoiceTypeParameter} [invoiceTypeCode] Fatura tipine göre filtreleme yapabilirsiniz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesLastGet(requestParameters: V1InvoicesLastGetRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesLastGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye etiket eklemek veya çıkarmak için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **100** belgeye tag eklenebilir/çıkarılabilir.
     * @summary Etiket ekler/çıkarır
     * @param {TaggingDto} taggingDto #### Etiket eklemek veya çıkarmak için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesTagsPut(requestParameters: V1InvoicesTagsPutRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesTagsPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye ait ek dokumanların listesine bu uç ile ulaşabilirsiniz.
     * @summary Belge eklerini listeler
     * @param {string} uuid **Belge uuid**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesUuidAttachmentsGet(requestParameters: V1InvoicesUuidAttachmentsGetRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesUuidAttachmentsGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye ait ek dokumanların indirilmesi için bu uç kullanılabilir.
     * @summary Belge ekini indirir
     * @param {string} uuid **Belge uuid**
     * @param {number} index **Ek belge sırası**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesUuidAttachmentsIndexGet(requestParameters: V1InvoicesUuidAttachmentsIndexGetRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesUuidAttachmentsIndexGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belge içerisinde belirtilen irsaliye bilgilerine bu uç ile ulaşabilirsiniz.
     * @summary İrsaliye bilgilerini getirir
     * @param {string} uuid **Belge uuid**
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesUuidDespatchesGet(requestParameters: V1InvoicesUuidDespatchesGetRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesUuidDespatchesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Belge detaylarını getirir
     * @param {string} uuid **Belge uuid**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesUuidGet(requestParameters: V1InvoicesUuidGetRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesUuidGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sorgulanan belgeye ait işlem geçmişine bu uç ile ulaşabilirsiniz.
     * @summary İşlem geçmişini getirir
     * @param {string} uuid **Belge uuid**
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesUuidHistoriesGet(requestParameters: V1InvoicesUuidHistoriesGetRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesUuidHistoriesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye ait HTML görüntüye bu uç ile ulaşabilirsiniz.
     * @summary Belgeyi görüntüler
     * @param {string} uuid **Belge uuid**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesUuidHtmlGet(requestParameters: V1InvoicesUuidHtmlGetRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesUuidHtmlGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sorgulanan belgeye ait internet satış bilgilerine bu uç ile ulaşabilirsiniz.
     * @summary İnternet satış bilgileri getir
     * @param {string} uuid **Belge uuid**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesUuidInternetinfosGet(requestParameters: V1InvoicesUuidInternetinfosGetRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesUuidInternetinfosGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sorgulanan belge içerisindeki mail adreslerinin listesine bu uç ile ulaşabilirsiniz.
     * @summary Mail adreslerini getirir
     * @param {string} uuid **Belge uuid**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesUuidMailaddressesGet(requestParameters: V1InvoicesUuidMailaddressesGetRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesUuidMailaddressesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sorgulanan belgeye ait mail gönderim geçmişine bu uç ile ulaşabilirsiniz.
     * @summary Mail geçmişini getirir
     * @param {string} uuid **Belge uuid**
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesUuidMailhistoriesGet(requestParameters: V1InvoicesUuidMailhistoriesGetRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesUuidMailhistoriesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye ait PDF dosyasını indirmek için bu ucu kullanabilirsiniz.
     * @summary PDF İndir
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesUuidPdfGet(requestParameters: V1InvoicesUuidPdfGetRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesUuidPdfGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gelen belgeler için göndericiyi, giden belgeler içinse alıcıyı **Firma Listesi**\'ne kaydetmek için bu uç kullanılabilir.
     * @summary Firma olarak kaydet
     * @param {string} uuid **Belge uuid**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesUuidSavecompanyindocumentPost(requestParameters: V1InvoicesUuidSavecompanyindocumentPostRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesUuidSavecompanyindocumentPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sorgulanan belgeye ait sms gönderim geçmişine bu uç ile ulaşabilirsiniz.
     * @summary Sms geçmişini getirir
     * @param {string} uuid **Belge uuid**
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesUuidSmshistoriesGet(requestParameters: V1InvoicesUuidSmshistoriesGetRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesUuidSmshistoriesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belge içerisinde belirtilen vergi bilgilerine bu uç ile ulaşabilirsiniz.
     * @summary Vergi bilgilerini getirir
     * @param {string} uuid **Belge uuid**
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesUuidTaxesGet(requestParameters: V1InvoicesUuidTaxesGetRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesUuidTaxesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sorgulanan belgeye ait kullanıcı tarafından eklenmiş notlara bu uç ile ulaşabilirsiniz.
     * @summary Kullanıcı notu listeler
     * @param {string} uuid **Belge uuid**
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesUuidUsernotesGet(requestParameters: V1InvoicesUuidUsernotesGetRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesUuidUsernotesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Kullanıcı tarafından oluşturulmuş olan notu silmek için bu uç kullanılabilir.
     * @summary Kullanıcı notunu siler
     * @param {string} uuid **Belge uuid**
     * @param {string} id **Not id**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesUuidUsernotesIdDelete(requestParameters: V1InvoicesUuidUsernotesIdDeleteRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesUuidUsernotesIdDelete(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye daha önce eklenmiş olan kullanıcı notunu güncellemek için bu uç kullanılabilir.
     * @summary Kullanıcı notunu günceller
     * @param {string} uuid #### Belgeye ait uuid
     * @param {string} id #### Not id
     * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesUuidUsernotesIdPut(requestParameters: V1InvoicesUuidUsernotesIdPutRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesUuidUsernotesIdPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye kullanıcı notu eklemek için bu uç kullanılabilir.
     * @summary Kullanıcı notu ekler
     * @param {string} uuid #### Belge uuid
     * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesUuidUsernotesPost(requestParameters: V1InvoicesUuidUsernotesPostRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesUuidUsernotesPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye ait XML dosyasını indirmek için bu ucu kullanabilirsiniz.
     * @summary XML İndir
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesUuidXmlGet(requestParameters: V1InvoicesUuidXmlGetRequest, options?: any) {
        return InvoicesApiFp(this.configuration).v1InvoicesUuidXmlGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1InvoicesBulkOperationPutRequest {
    /**
     * **İşlem**
     */
    operation: InvoiceSetParameter;
    /**
     * **Belge uuid listesi**
     */
    requestBody: Array<string>;
    }
    export interface V1InvoicesCancelPostRequest {
    /**
     * #### İptal için gönderilmesi gereken model
     */
    documentCancellationDto?: DocumentCancellationDto;
    }
    export interface V1InvoicesCanceledGetRequest {
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    /**
     * Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
     */
    company?: string;
    /**
     * UUID numarasına göre filtreleme yapabilirsiniz
     */
    uuid?: string;
    /**
     * Belge numarasına göre filtreleme yapabilirsiniz
     */
    documentNumber?: string;
    /**
     * Oluşturma tarihi başlangıcı
     */
    startCreateDate?: string;
    /**
     * Oluşturma tarihi sonu
     */
    endCreateDate?: string;
    /**
     * Gönderim tipine göre filtreleme yapabilirsiniz
     */
    sendType?: SendTypeParameter;
    /**
     * Satış tipine göre filtreleme yapabilirsiniz
     */
    salesPlatform?: SalesPlatformParameter;
    /**
     * Rapor durumuna göre filtreleme yapabilirsiniz
     */
    reportDivisionStatus?: BaseReportDivisionStatusParameter;
    /**
     * Başlangıç tarihi
     */
    startDate?: string;
    /**
     * Bitiş tarihi
     */
    endDate?: string;
    }
    export interface V1InvoicesCanceledLastGetRequest {
    /**
     * Rapor durumuna göre filtreleme yapabilirsiniz
     */
    reportDivisionStatus?: BaseReportDivisionStatusParameter;
    }
    export interface V1InvoicesCanceledWithdrawPostRequest {
    /**
     * **İptal işlemini geri almak için kullanılacak model**
     */
    documentCancellationDto: DocumentCancellationDto;
    }
    export interface V1InvoicesDraftsDeleteRequest {
    /**
     * #### Taslak belge UUID listesi
     */
    requestBody: Array<string>;
    }
    export interface V1InvoicesDraftsGetRequest {
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    /**
     * Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
     */
    company?: string;
    /**
     * UUID numarasına göre filtreleme yapabilirsiniz
     */
    uuid?: string;
    /**
     * Belge numarasına göre filtreleme yapabilirsiniz
     */
    documentNumber?: string;
    }
    export interface V1InvoicesEmailSendPostRequest {
    /**
     * 
     */
    emailSendDto: EmailSendDto;
    }
    export interface V1InvoicesExportFileTypePostRequest {
    /**
     * 
     */
    fileType: FileExportTypeParameter;
    /**
     * 
     */
    requestBody: Array<string>;
    }
    export interface V1InvoicesGetRequest {
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    /**
     * Faturaya atanmış etiketlere göre filtreleme yapabilirsiniz
     */
    tags?: Array<string>;
    /**
     * Kullanıcı notuna göre filtreleme yapabilirsiniz
     */
    userNote?: string;
    /**
     * Belge içinde geçen nota göre filtreleme yapabilirsiniz
     */
    documentNote?: string;
    /**
     * İrsaliye numarasına göre filtreleme yapabilirsiniz
     */
    despatchNumber?: string;
    /**
     * Sipariş numarasına göre filtreleme yapabilirsiniz
     */
    orderNumber?: string;
    /**
     * Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
     */
    company?: string;
    /**
     * UUID numarasına göre filtreleme yapabilirsiniz
     */
    uuid?: string;
    /**
     * Belge numarasına göre filtreleme yapabilirsiniz
     */
    documentNumber?: string;
    /**
     * Oluşturma tarihi başlangıcı
     */
    startCreateDate?: string;
    /**
     * Oluşturma tarihi sonu
     */
    endCreateDate?: string;
    /**
     * Fatura durumuna göre filtreleme yapabilirsiniz
     */
    invoiceStatus?: ArchiveDocumentStatusParameter;
    /**
     * Rapor durumuna göre filtreleme yapabilirsiniz
     */
    reportDivisionStatus?: BaseReportDivisionStatusParameter;
    /**
     * İptal edilmiş olan belgeleri de listelemek isterseniz **true** göndermelisiniz
     */
    includeCanceledDocuments?: boolean;
    /**
     * Gönderim tipine göre filtreleme yapabilirsiniz
     */
    sendType?: SendTypeParameter;
    /**
     * Satış tipine göre filtreleme yapabilirsiniz
     */
    salesPlatform?: SalesPlatformParameter;
    /**
     * Mail durumuna göre filtreleme yapabilirsiniz
     */
    mailStatus?: MailStatusFilterParameter;
    /**
     * Fatura tipine göre filtreleme yapabilirsiniz
     */
    invoiceTypeCode?: InvoiceTypeParameter;
    /**
     * Sms durumuna göre filtreleme yapabilirsiniz
     */
    smsStatus?: SmsStatusFilterParameter;
    /**
     * 
     */
    lucaTransferStatus?: LucaTransferStatusParameter;
    /**
     * Başlangıç tarihi
     */
    startDate?: string;
    /**
     * Bitiş tarihi
     */
    endDate?: string;
    }
    export interface V1InvoicesLastGetRequest {
    /**
     * Fatura durumuna göre filtreleme yapabilirsiniz
     */
    invoiceStatus?: ArchiveDocumentStatusParameter;
    /**
     * Rapor durumuna göre filtreleme yapabilirsiniz
     */
    reportDivisionStatus?: BaseReportDivisionStatusParameter;
    /**
     * İptal edilmiş olan belgeleri de listelemek isterseniz **true** göndermelisiniz
     */
    includeCanceledDocuments?: boolean;
    /**
     * Fatura tipine göre filtreleme yapabilirsiniz
     */
    invoiceTypeCode?: InvoiceTypeParameter;
    }
    export interface V1InvoicesTagsPutRequest {
    /**
     * #### Etiket eklemek veya çıkarmak için gönderilmesi gereken model
     */
    taggingDto: TaggingDto;
    }
    export interface V1InvoicesUuidAttachmentsGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    }
    export interface V1InvoicesUuidAttachmentsIndexGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    /**
     * **Ek belge sırası**
     */
    index: number;
    }
    export interface V1InvoicesUuidDespatchesGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    }
    export interface V1InvoicesUuidGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    }
    export interface V1InvoicesUuidHistoriesGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    }
    export interface V1InvoicesUuidHtmlGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    }
    export interface V1InvoicesUuidInternetinfosGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    }
    export interface V1InvoicesUuidMailaddressesGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    }
    export interface V1InvoicesUuidMailhistoriesGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    }
    export interface V1InvoicesUuidPdfGetRequest {
    /**
     * 
     */
    uuid: string;
    }
    export interface V1InvoicesUuidSavecompanyindocumentPostRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    }
    export interface V1InvoicesUuidSmshistoriesGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    }
    export interface V1InvoicesUuidTaxesGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    }
    export interface V1InvoicesUuidUsernotesGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    }
    export interface V1InvoicesUuidUsernotesIdDeleteRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    /**
     * **Not id**
     */
    id: string;
    }
    export interface V1InvoicesUuidUsernotesIdPutRequest {
    /**
     * #### Belgeye ait uuid
     */
    uuid: string;
    /**
     * #### Not id
     */
    id: string;
    /**
     * #### Not eklemek için gönderilmesi gereken model
     */
    noteDto: NoteDto;
    }
    export interface V1InvoicesUuidUsernotesPostRequest {
    /**
     * #### Belge uuid
     */
    uuid: string;
    /**
     * #### Not eklemek için gönderilmesi gereken model
     */
    noteDto: NoteDto;
    }
    export interface V1InvoicesUuidXmlGetRequest {
    /**
     * 
     */
    uuid: string;
    }
