/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { IDType } from '.';
import { NS_CBC } from '../constants';

/*
  27.04.2021 : Fully completed
*/
export class PartyIdentificationType {
  @XMLElement({ types: [{ name: 'ID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  id?: IDType;
}

export default PartyIdentificationType;
