/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLArray, XMLElement } from '@/xmldom-decorators';
import { IDType } from '.';
import { NS_CAC, NS_CBC } from '../constants';
import { DeliveryType } from './Delivery';
import GoodsItemType from './GoodsItemType';
import ShipmentStageType from './ShipmentStageType';
import TransportHandlingUnitType from './TransportHandlingUnitType';

/*
  10.05.2021 : Partially completed
  cbc:HandlingCode 
  cbc:HandlingInstructions 
  cbc:GrossWeightMeasure 
  cbc:NetWeightMeasure 
  cbc:GrossVolumeMeasure 
  cbc:NetVolumeMeasure 
  cbc:TotalGoodsItemQuantity 
  cbc:TotalTransportHandlingUnitQuantity 
  cbc:InsuranceValueAmount 
  cbc:DeclaredCustomsValueAmount 
  cbc:DeclaredForCarriageValueAmount 
  cbc:DeclaredStatisticsValueAmount 
  cbc:FreeOnBoardValueAmount 
  cbc:SpecialInstructions 
  cbc:GoodsItem 
  cac:ShipmentStage 
  cac:Delivery 
  cac:TransportHandlingUnit 
  cac:ReturnAddress 
  cac:FirstArrivalPortLocation 
  cac:LastExitPortLocation 
*/
export class ShipmentType {
  @XMLElement({ types: [{ name: 'ID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  id: IDType = { schemeID: '', value: undefined };

  //cbc:HandlingCode
  //cbc:HandlingInstructions
  //cbc:GrossWeightMeasure
  //cbc:NetWeightMeasure
  //cbc:GrossVolumeMeasure
  //cbc:NetVolumeMeasure
  //cbc:TotalGoodsItemQuantity
  //cbc:TotalTransportHandlingUnitQuantity
  //cbc:InsuranceValueAmount
  //cbc:DeclaredCustomsValueAmount
  //cbc:DeclaredForCarriageValueAmount
  //cbc:DeclaredStatisticsValueAmount
  //cbc:FreeOnBoardValueAmount
  //cbc:SpecialInstructions

  @XMLArray({ nested: false, itemTypes: [{ name: 'GoodsItem', namespaceUri: NS_CAC, itemType: () => GoodsItemType }] })
  goodsItem?: GoodsItemType[] = [];

  @XMLArray({ nested: false, itemTypes: [{ name: 'ShipmentStage', namespaceUri: NS_CAC, itemType: () => ShipmentStageType }] })
  shipmentStage?: ShipmentStageType[] = [];

  @XMLElement({ types: [{ name: 'Delivery', namespaceUri: NS_CAC, itemType: () => DeliveryType }] })
  delivery?: DeliveryType;

  @XMLArray({ nested: false, itemTypes: [{ name: 'TransportHandlingUnit', namespaceUri: NS_CAC, itemType: () => TransportHandlingUnitType }] })
  transportHandlingUnit?: TransportHandlingUnitType[] = [];

  //cac:ReturnAddress
  //cac:FirstArrivalPortLocation
  //cac:LastExitPortLocation
}

export default ShipmentType;
