import { Close } from '@/components/Buttons';
import { PageConst } from '@/constants/page';
import { barcodeXsltText } from '@/example';
import { Invoice } from '@/models/xmlDocuments/invoice';
import { Modal } from 'antd';
import React from 'react';
import XML from 'xml.one';
import { BarcodesModalProps } from '.';

const PreviewModal = ({ closeModal, modalState: { visible, barcodeInformation } }: { closeModal: () => void; modalState: BarcodesModalProps }) => {
  if (!barcodeInformation) {
    return null;
  }

  const createdInvoice = new Invoice().createDummyInvoice();

  const transform = XML.transform(
    createdInvoice.serialize({
      ...createdInvoice,
      additionalDocumentReference: [
        {
          documentTypeCode: 'BARCODE',
          id: { value: '8690632876' }
        }
      ]
    }),
    barcodeXsltText
      .replace('<!--HEADER_CONTENT-->', `<div style="display: block;  width:100%;"><b>${barcodeInformation!.header}</b></div>`)
      .replace('<!--FOOTER_CONTENT-->', `<div style="display: block;  width:100%;"><b>${barcodeInformation!.footer}</b></div>`)
      .replace('BARCODE_WIDTH', `${(barcodeInformation!.width / 1000).toFixed(4)}in`)
      .replace('PRINT_TEXT', barcodeInformation!.hideBarcodeValueOnFooter ? 'false' : 'true')
      .replace('BARCODE_HEIGHT', `${(barcodeInformation!.height / 108).toFixed(2)}in`)
  );

  return (
    <Modal
      title={'Önizleme'}
      onCancel={closeModal}
      width={PageConst.LargeModalWidth}
      centered
      transitionName="fade"
      maskClosable={false}
      visible={visible}
      footer={[
        <React.Fragment key="tag-modal-footer">
          <Close onClick={closeModal} />
        </React.Fragment>
      ]}
      destroyOnClose
    >
      {transform && transform.documentElement && (
        <iframe
          title={'Xlsts Önizleme'}
          id="xsltsPreview"
          src="#"
          srcDoc={transform.documentElement.outerHTML}
          width={PageConst.LargeModalWidth}
          height="100%"
          frameBorder="0"
          style={{ width: '100%', minHeight: 360, paddingLeft: 10, border: '1px solid #e4e9f0', borderRadius: '5px' }}
        />
      )}
    </Modal>
  );
};

export default PreviewModal;
