/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/camelcase */
import ChatwootWidget from '@/components/Chatwoot';
import { LeftMenuData } from '@/constants/menu';
import { Layout } from 'antd';
import classNames from 'classnames';
import ThemeContext from 'contexts/ThemeContext';
import React, { memo, ReactNode, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import MenuLeft from './MenuLeft';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import EnvelopeSearchModal from './TopBar/EnvelopeSearchModal';
import UserSearchModal from './UserSearchModal';

const ThemeLayout = memo(({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useContext(ThemeContext);

  if (state.menuData.length === 0) {
    dispatch({ type: 'CHANGE', key: 'menuData', value: LeftMenuData() });
  }

  const { pathname } = useLocation();

  return (
    <>
      {state.userSearchVisible && <UserSearchModal visible={true} closeModal={() => dispatch({ type: 'CHANGE', key: 'userSearchVisible', value: false })} />}
      {state.envelopeSearchVisible && (
        <EnvelopeSearchModal visible={true} closeModal={() => dispatch({ type: 'CHANGE', key: 'envelopeSearchVisible', value: false })} />
      )}
      {/* <ToastContainer closeOnClick={false} autoClose={false} enableMultiContainer /> */}
      <Helmet titleTemplate="%s | NES BİLGİ" title="e-Belge Özel Entegratör" />
      <ToastContainer closeOnClick={false} autoClose={false} enableMultiContainer />
      <div className={classNames({ air__layout__grayBackground: state.isGrayBackground })}>
        <Layout
          className={classNames({
            air__layout__contentNoMaxWidth: state.isContentNoMaxWidth,
            air__layout__appMaxWidth: state.isAppMaxWidth,
            air__layout__grayBackground: state.isGrayBackground,
            air__layout__squaredBorders: state.isSquaredBorders,
            air__layout__cardsShadow: state.isCardShadow,
            air__layout__borderless: state.isBorderless
          })}
        >
          <Sidebar />
          {/* <SupportChat /> */}
          {state.menuLayoutType === 'left' && <MenuLeft />}

          {/* TODO */}
          {/* {menuLayoutType === 'top' && <MenuTop />} */}
          <Layout>
            <Layout.Header
              className={classNames('air__layout__header', {
                air__layout__fixedHeader: state.isTopbarFixed,
                air__layout__headerGray: state.isGrayTopbar
              })}
            >
              {state.menuLayoutType !== 'top-dark' && <TopBar />}
              {/* TODO */}
              {/* {menuLayoutType === 'top-dark' && <TopBarDark />} */}
              {/* <SubBar /> */}
            </Layout.Header>
            <Layout.Content style={{ height: '100%', position: 'relative' }}>
              <ChatwootWidget />

              <div className={classNames('air__utils__content', { 'main-dashboard': pathname.startsWith('/dashboard') })}>{children}</div>
            </Layout.Content>
            {/* <Layout.Footer>
              {!isFooterDark && <Footer />}
              {isFooterDark && <FooterDark />}
            </Layout.Footer> */}
            {/* 
            <Layout.Footer>
              <Footer />
            </Layout.Footer> */}
          </Layout>
        </Layout>
      </div>

      {/* <RootModal /> */}
    </>
  );
});

export default ThemeLayout;
