import { DateCriteria as DateCriteriaEnum } from '@/constants/enums';
import { Select } from 'antd';
import React, { memo } from 'react';
import intl from 'react-intl-universal';
export const DateCriteria = memo(({ defaultValue, onChange }: { defaultValue?: DateCriteriaEnum; onChange?: (value: DateCriteriaEnum) => void }) => {
  return (
    <Select className="w-100" onChange={onChange} defaultValue={defaultValue === undefined ? DateCriteriaEnum.DocumentDate : defaultValue}>
      <Select.Option value="CreateDate">{intl.get('EKLENME_T')}</Select.Option>
      <Select.Option value="DocumentDate">{intl.get('BELGE_TARIHI')}</Select.Option>
    </Select>
  );
});

export default DateCriteria;
