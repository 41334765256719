/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { NS_CBC } from '../constants';

/*
  27.04.2021 : Fully completed
*/
export class PartyNameType {
  @XMLElement({ types: [{ name: 'Name', namespaceUri: NS_CBC, itemType: () => String }] })
  name?: string;
}

export default PartyNameType;
