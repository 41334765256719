import { AnnouncementsApiFactory, V1AnnouncementsFeedbackPostRequest } from '@/apis/general';
import { AnnouncementDashboardDto, AnnouncementModalSize, AnnouncementRating, SetAnnouncementFeedbackCommand } from '@/apis/general/interfaces';
import { WarningToastify } from '@/components';
import { useApi } from '@/hooks';
import { CommentOutlined, FrownOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons';
import { faNewspaper } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Form, Input, Modal, Rate, Row } from 'antd';
import React, { useState } from 'react';

const CreateAnnouncementRatingModal = ({
  announcement,
  visible,
  setVisible
}: {
  setVisible: (visible: boolean, id: string, isRated: boolean) => void;
  visible: boolean;
  announcement: AnnouncementDashboardDto;
}) => {
  const customIcons: Record<number, React.ReactNode> = {
    1: <FrownOutlined />,
    2: <FrownOutlined />,
    3: <MehOutlined />,
    4: <SmileOutlined />,
    5: <SmileOutlined />
  };

  const [values, setValues] = useState<SetAnnouncementFeedbackCommand>({
    announcementRating: AnnouncementRating.None,
    description: '',
    announcementId: announcement.id
  });

  const [form] = Form.useForm();

  const setFeedBack = useApi<any, V1AnnouncementsFeedbackPostRequest>({
    asyncFunction: AnnouncementsApiFactory().v1AnnouncementsFeedbackPost,
    successCallback(response, request, additionalProps) {
      setVisible(false, announcement.id!, true);
    }
  });

  const onSubmit = () => {
    if (values.announcementRating === AnnouncementRating.None) {
      WarningToastify('Lütfen bir ifade seçiniz');
      return;
    }

    setFeedBack.call({ setAnnouncementFeedbackCommand: values });
  };

  const onCancel = () => {
    setVisible(false, announcement.id!, false);
  };

  return (
    <Modal
      visible={visible}
      //   className="announcement-modal"
      width={announcement.announcementModalSize == AnnouncementModalSize.Medium ? 720 : 720}
      title={
        <>
          <FontAwesomeIcon icon={faNewspaper} /> {announcement.title}
        </>
      }
      onCancel={onCancel}
      footer={[
        <Button key="submit" onClick={onCancel}>
          Kapat
        </Button>,
        <Button
          key="back"
          type="primary"
          onClick={() => {
            form.submit();
          }}
        >
          <CommentOutlined />
          Değerlendirmeyi Gönder
        </Button>
      ]}
    >
      <Form
        layout="vertical"
        form={form}
        onValuesChange={(_, changedValuses) => {
          const newValues = { ...changedValuses };

          switch (newValues.announcementRating) {
            case 1:
              newValues.announcementRating = AnnouncementRating.OneStar;
              break;
            case 2:
              newValues.announcementRating = AnnouncementRating.TwoStar;
              break;
            case 3:
              newValues.announcementRating = AnnouncementRating.ThreeStar;
              break;
            case 4:
              newValues.announcementRating = AnnouncementRating.FourStar;
              break;
            case 5:
              newValues.announcementRating = AnnouncementRating.FiveStar;
              break;
          }

          setValues({ ...values, ...newValues });
        }}
        onFinish={onSubmit}
        initialValues={values}
      >
        <Row style={{ marginBottom: 0 }}>
          <Col sm={24}>
            <Form.Item label="Yorumunuz" name={'description'}>
              <Input.TextArea placeholder={'Duyurumuzla ilgili geri bildirimi bu alandan gönderebilirsiniz'} rows={6} />
            </Form.Item>
          </Col>
        </Row>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Form.Item name={'announcementRating'}>
            <Rate defaultValue={0} style={{ fontSize: 36 }} character={({ index }: { index: number }) => customIcons[index + 1]} />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateAnnouncementRatingModal;
