/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { AmountType } from '.';
import { NS_CBC } from '../constants';

/*
  27.04.2021 : Fully completed
*/
export class PriceType {
  @XMLElement({ types: [{ name: 'PriceAmount', namespaceUri: NS_CBC, itemType: () => AmountType }] })
  priceAmount: AmountType = { value: 0 };
}

export default PriceType;
