import { DespatchDocumentReference, DespatchDocumentReferencePaginationResult } from '@/apis/einvoice/interfaces';
import { DocumentInfo, ErrorWrapper } from '@/components';
import { Close } from '@/components/Buttons';
import LocalPagination from '@/components/Pagination/LocalPagination';
import { TableDefaultLoading } from '@/components/TableBody';
import { DateFormat } from '@/constants/page';
import { DocumentModal, Pagination } from '@/interfaces';
import { faTruck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import _ from 'lodash';
import moment from 'moment';
import React, { memo, useState } from 'react';
import intl from 'react-intl-universal';

export interface DocumentDespatchesModalAdditionalProps {
  refetch: (uuid?: string, documentNumber?: string, pagination?: Pagination, additionalProps?: DocumentDespatchesModalAdditionalProps) => void;
}

export const DocumentDespatchesModal = memo(
  ({
    closeModal,
    visible,
    documentNumber,
    uuid,
    data,
    loading,
    error,
    additionalProps
  }: DocumentModal<DespatchDocumentReferencePaginationResult, DocumentDespatchesModalAdditionalProps>) => {
    const [pagination, setPagination] = useState({ page: 1, pageSize: 10, sort: 'CreatedAt desc' });

    return (
      <Modal
        centered
        title={
          <span>
            <FontAwesomeIcon icon={faTruck} /> {intl.get('IRSALIYE_BILGILERI')}
          </span>
        }
        onCancel={closeModal}
        width={1024}
        visible={visible}
        footer={[<Close key="close" onClick={closeModal} />]}
      >
        <DocumentInfo documentNumber={documentNumber || ''} uuid={uuid || ''} />

        <Table
          showHeader={data !== undefined}
          components={ErrorWrapper({ error })}
          style={{ marginTop: '10px' }}
          scroll={{ x: true, y: 512 }}
          bordered
          loading={TableDefaultLoading(loading)}
          pagination={false}
          dataSource={(data && data.data) || []}
          rowKey={(record) => record.documentReferenceId}
        >
          <Table.Column key="documentReferenceId" title={intl.get('IRSALIYE_NO')} dataIndex="documentReferenceId" />
          <Table.Column
            key="issueDate"
            title={intl.get('IRSALIYE_TARIHI')}
            dataIndex="issueDate"
            render={(text, record: DespatchDocumentReference) => {
              return moment.parseZone(record.issueDate).format(DateFormat.ymd);
            }}
          />
        </Table>

        {data && data.data && data.data.length > 0 && (
          <LocalPagination
            data={data}
            selectedCount={0}
            execute={() => additionalProps && additionalProps.refetch(uuid, documentNumber, pagination, additionalProps)}
            page={pagination.page}
            pageSize={pagination.pageSize}
            onPageChange={(page, pageSize) => {
              setPagination({ ...pagination, page, pageSize });
            }}
          />
        )}
      </Modal>
    );
  },
  (prev, next) => _.isEqual(prev.data, next.data) && prev.visible === next.visible && prev.loading === next.loading
);
