// tslint:disable
// eslint:disable
/**
 * E-MM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ArchiveDocumentStatusParameter } from '../interfaces';
// @ts-ignore
import { BaseReportDivisionStatusParameter } from '../interfaces';
// @ts-ignore
import { Company } from '../interfaces';
// @ts-ignore
import { DocumentCancellationDto } from '../interfaces';
// @ts-ignore
import { EmailSendDto } from '../interfaces';
// @ts-ignore
import { FileExportTypeParameter } from '../interfaces';
// @ts-ignore
import { HistoryPaginationResult } from '../interfaces';
// @ts-ignore
import { MailHistoryPaginationResult } from '../interfaces';
// @ts-ignore
import { MailStatusFilterParameter } from '../interfaces';
// @ts-ignore
import { Note } from '../interfaces';
// @ts-ignore
import { NoteDto } from '../interfaces';
// @ts-ignore
import { NotePaginationResult } from '../interfaces';
// @ts-ignore
import { TaggingDto } from '../interfaces';
// @ts-ignore
import { TaxInfoPaginationResult } from '../interfaces';
// @ts-ignore
import { Voucher } from '../interfaces';
// @ts-ignore
import { VoucherCanceled } from '../interfaces';
// @ts-ignore
import { VoucherCanceledPaginationResult } from '../interfaces';
// @ts-ignore
import { VoucherPaginationResult } from '../interfaces';
// @ts-ignore
import { VoucherSetParameter } from '../interfaces';
/**
 * VouchersApi - axios parameter creator
 * @export
 */
export const VouchersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Belgelere toplu olarak yeni durum atamak için bu uç kullanılabilir.
         * @summary Yeni durum atar
         * @param {VoucherSetParameter} operation **İşlem**
         * @param {Array<string>} requestBody **Belge uuid listesi**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersBulkOperationPut: async (requestParameters: V1VouchersBulkOperationPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'operation' is not null or undefined
            if (requestParameters.operation === null || requestParameters.operation === undefined) {
                throw new RequiredError('operation','Required parameter operation was null or undefined when calling v1VouchersBulkOperationPut.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling v1VouchersBulkOperationPut.');
            }
            const localVarPath = `/v1/vouchers/bulk/{operation}`.replace(`{${"operation"}}`, encodeURIComponent(String(requestParameters.operation)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.requestBody !== undefined ? requestParameters.requestBody : {}) : (requestParameters.requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeyi iptal etmek için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **50** kayıt iptal edilebilir.
         * @summary Belgeyi iptal eder
         * @param {DocumentCancellationDto} [documentCancellationDto] #### İptal için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersCancelPost: async (requestParameters: V1VouchersCancelPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/vouchers/cancel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.documentCancellationDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.documentCancellationDto !== undefined ? requestParameters.documentCancellationDto : {}) : (requestParameters.documentCancellationDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * İptal edilmiş olan e-Müstahsil makbuzlarınıza bu uç ile ulaşabilirsiniz.
         * @summary İptal edilen makbuzlar
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [documentNote] Belge içinde geçen nota göre filtreleme yapabilirsiniz
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {string} [startCreateDate] Oluşturma tarihi başlangıcı
         * @param {string} [endCreateDate] Oluşturma tarihi sonu
         * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
         * @param {string} [startDate] Başlangıç tarihi
         * @param {string} [endDate] Bitiş tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersCanceledGet: async (requestParameters: V1VouchersCanceledGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1VouchersCanceledGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1VouchersCanceledGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1VouchersCanceledGet.');
            }
            const localVarPath = `/v1/vouchers/canceled`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.documentNote !== undefined) {
                localVarQueryParameter['documentNote'] = requestParameters.documentNote;
            }
            if (requestParameters.company !== undefined) {
                localVarQueryParameter['company'] = requestParameters.company;
            }
            if (requestParameters.uuid !== undefined) {
                localVarQueryParameter['uuid'] = requestParameters.uuid;
            }
            if (requestParameters.documentNumber !== undefined) {
                localVarQueryParameter['documentNumber'] = requestParameters.documentNumber;
            }
            if (requestParameters.startCreateDate !== undefined) {
                localVarQueryParameter['startCreateDate'] = (requestParameters.startCreateDate as any instanceof Date) ?
                    (requestParameters.startCreateDate as any).toISOString() : requestParameters.startCreateDate;
            }
            if (requestParameters.endCreateDate !== undefined) {
                localVarQueryParameter['endCreateDate'] = (requestParameters.endCreateDate as any instanceof Date) ?
                    (requestParameters.endCreateDate as any).toISOString() : requestParameters.endCreateDate;
            }
            if (requestParameters.reportDivisionStatus !== undefined) {
                localVarQueryParameter['reportDivisionStatus'] = requestParameters.reportDivisionStatus;
            }
            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }
            if (requestParameters.startDate !== undefined) {
                localVarQueryParameter['startDate'] = requestParameters.startDate;
            }
            if (requestParameters.endDate !== undefined) {
                localVarQueryParameter['endDate'] = requestParameters.endDate;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * İptal edilmiş olan son 10 e-Müstahsil makbuzlarınıza bu uç ile ulaşabilirsiniz.
         * @summary İptal edilen son 10 makbuzlar
         * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersCanceledLastGet: async (requestParameters: V1VouchersCanceledLastGetRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/vouchers/canceled/last`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.reportDivisionStatus !== undefined) {
                localVarQueryParameter['reportDivisionStatus'] = requestParameters.reportDivisionStatus;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * İptal edilmiş ve henüz raporlanmamış olan belgeye ait iptal işlemini geri almak için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **50** iptal kaydı geri alınabilir.
         * @summary İptal işlemini geri alır
         * @param {DocumentCancellationDto} documentCancellationDto **İptal işlemini geri almak için kullanılacak model**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersCanceledWithdrawPost: async (requestParameters: V1VouchersCanceledWithdrawPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentCancellationDto' is not null or undefined
            if (requestParameters.documentCancellationDto === null || requestParameters.documentCancellationDto === undefined) {
                throw new RequiredError('documentCancellationDto','Required parameter documentCancellationDto was null or undefined when calling v1VouchersCanceledWithdrawPost.');
            }
            const localVarPath = `/v1/vouchers/canceled/withdraw`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.documentCancellationDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.documentCancellationDto !== undefined ? requestParameters.documentCancellationDto : {}) : (requestParameters.documentCancellationDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Taslak belgeleri silmek için bu uç kullanılablir
         * @param {Array<string>} requestBody #### Taslak belge UUID listesi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersDraftsDelete: async (requestParameters: V1VouchersDraftsDeleteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling v1VouchersDraftsDelete.');
            }
            const localVarPath = `/v1/vouchers/drafts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.requestBody !== undefined ? requestParameters.requestBody : {}) : (requestParameters.requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * E-Müstahsil Makbuzu taslaklarınıza bu uç ile ulaşabilirsiniz.
         * @summary Taslak makbuzları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersDraftsGet: async (requestParameters: V1VouchersDraftsGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1VouchersDraftsGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1VouchersDraftsGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1VouchersDraftsGet.');
            }
            const localVarPath = `/v1/vouchers/drafts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.company !== undefined) {
                localVarQueryParameter['company'] = requestParameters.company;
            }
            if (requestParameters.uuid !== undefined) {
                localVarQueryParameter['uuid'] = requestParameters.uuid;
            }
            if (requestParameters.documentNumber !== undefined) {
                localVarQueryParameter['documentNumber'] = requestParameters.documentNumber;
            }
            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgelerinizi mail olarak iletmek için bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **50** farklı belge, **10** farklı mail adresi gönderilebilir.
         * @summary Belgeyi mail olarak iletir
         * @param {EmailSendDto} emailSendDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersEmailSendPost: async (requestParameters: V1VouchersEmailSendPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailSendDto' is not null or undefined
            if (requestParameters.emailSendDto === null || requestParameters.emailSendDto === undefined) {
                throw new RequiredError('emailSendDto','Required parameter emailSendDto was null or undefined when calling v1VouchersEmailSendPost.');
            }
            const localVarPath = `/v1/vouchers/email/send`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.emailSendDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.emailSendDto !== undefined ? requestParameters.emailSendDto : {}) : (requestParameters.emailSendDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgelerinizi toplu olarak dışarı aktarmak isterseniz bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **100** kayıt dışarı aktarılabilir.    **fileType** Parametre detayları    > **Xml** : Belgenizin XML halini dışarı aktarır    > **Envelope** : Belgenizin Zarf XML halini dışarı aktarır    > **Pdf** : Belgenize ait PDF görüntüsünü dışarı aktarır    > **UnifiedPdf** : ***uuids*** parametresinde gönderilen belgeleri tek PDF dosyası halinde dışarı aktarır    > **Excel** : ***uuids*** parametresinde gönderilen belgeleri Excel\'e aktarır
         * @summary Toplu aktar
         * @param {FileExportTypeParameter} fileType 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersExportFileTypePost: async (requestParameters: V1VouchersExportFileTypePostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileType' is not null or undefined
            if (requestParameters.fileType === null || requestParameters.fileType === undefined) {
                throw new RequiredError('fileType','Required parameter fileType was null or undefined when calling v1VouchersExportFileTypePost.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling v1VouchersExportFileTypePost.');
            }
            const localVarPath = `/v1/vouchers/export/{fileType}`.replace(`{${"fileType"}}`, encodeURIComponent(String(requestParameters.fileType)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.requestBody !== undefined ? requestParameters.requestBody : {}) : (requestParameters.requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Düzenlenmiş olan e-Müstahsil Makbuzlarınıza bu uç ile ulaşabilirsiniz.
         * @summary Makbuzları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {Array<string>} [tags] Makbuza atanmış etiketlere göre filtreleme yapabilirsiniz
         * @param {string} [userNote] Kullanıcı notuna göre filtreleme yapabilirsiniz
         * @param {string} [documentNote] Belge içinde geçen nota göre filtreleme yapabilirsiniz
         * @param {string} [orderNumber] Sipariş numarasına göre filtreleme yapabilirsiniz
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {string} [startCreateDate] Oluşturma tarihi başlangıcı
         * @param {string} [endCreateDate] Oluşturma tarihi sonu
         * @param {ArchiveDocumentStatusParameter} [voucherStatus] Makbuz durumuna göre filtreleme yapabilirsiniz
         * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
         * @param {boolean} [includeCanceledDocuments] İptal edilmiş olan belgeleri de listelemek isterseniz **true** göndermelisiniz
         * @param {MailStatusFilterParameter} [mailStatus] Mail durumuna göre filtreleme yapabilirsiniz
         * @param {string} [startDate] Başlangıç tarihi
         * @param {string} [endDate] Bitiş tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersGet: async (requestParameters: V1VouchersGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1VouchersGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1VouchersGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1VouchersGet.');
            }
            const localVarPath = `/v1/vouchers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.tags) {
                localVarQueryParameter['tags'] = requestParameters.tags;
            }
            if (requestParameters.userNote !== undefined) {
                localVarQueryParameter['userNote'] = requestParameters.userNote;
            }
            if (requestParameters.documentNote !== undefined) {
                localVarQueryParameter['documentNote'] = requestParameters.documentNote;
            }
            if (requestParameters.orderNumber !== undefined) {
                localVarQueryParameter['orderNumber'] = requestParameters.orderNumber;
            }
            if (requestParameters.company !== undefined) {
                localVarQueryParameter['company'] = requestParameters.company;
            }
            if (requestParameters.uuid !== undefined) {
                localVarQueryParameter['uuid'] = requestParameters.uuid;
            }
            if (requestParameters.documentNumber !== undefined) {
                localVarQueryParameter['documentNumber'] = requestParameters.documentNumber;
            }
            if (requestParameters.startCreateDate !== undefined) {
                localVarQueryParameter['startCreateDate'] = (requestParameters.startCreateDate as any instanceof Date) ?
                    (requestParameters.startCreateDate as any).toISOString() : requestParameters.startCreateDate;
            }
            if (requestParameters.endCreateDate !== undefined) {
                localVarQueryParameter['endCreateDate'] = (requestParameters.endCreateDate as any instanceof Date) ?
                    (requestParameters.endCreateDate as any).toISOString() : requestParameters.endCreateDate;
            }
            if (requestParameters.voucherStatus !== undefined) {
                localVarQueryParameter['voucherStatus'] = requestParameters.voucherStatus;
            }
            if (requestParameters.reportDivisionStatus !== undefined) {
                localVarQueryParameter['reportDivisionStatus'] = requestParameters.reportDivisionStatus;
            }
            if (requestParameters.includeCanceledDocuments !== undefined) {
                localVarQueryParameter['includeCanceledDocuments'] = requestParameters.includeCanceledDocuments;
            }
            if (requestParameters.mailStatus !== undefined) {
                localVarQueryParameter['mailStatus'] = requestParameters.mailStatus;
            }
            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }
            if (requestParameters.startDate !== undefined) {
                localVarQueryParameter['startDate'] = requestParameters.startDate;
            }
            if (requestParameters.endDate !== undefined) {
                localVarQueryParameter['endDate'] = requestParameters.endDate;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Düzenlenmiş olan son 10 e-Müstahsil Makbuzlarınıza bu uç ile ulaşabilirsiniz.
         * @summary Son 10 makbuzları listeler
         * @param {ArchiveDocumentStatusParameter} [voucherStatus] Makbuz durumuna göre filtreleme yapabilirsiniz
         * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
         * @param {boolean} [includeCanceledDocuments] İptal edilmiş olan belgeleri de listelemek isterseniz **true** göndermelisiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersLastGet: async (requestParameters: V1VouchersLastGetRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/vouchers/last`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.voucherStatus !== undefined) {
                localVarQueryParameter['voucherStatus'] = requestParameters.voucherStatus;
            }
            if (requestParameters.reportDivisionStatus !== undefined) {
                localVarQueryParameter['reportDivisionStatus'] = requestParameters.reportDivisionStatus;
            }
            if (requestParameters.includeCanceledDocuments !== undefined) {
                localVarQueryParameter['includeCanceledDocuments'] = requestParameters.includeCanceledDocuments;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye etiket eklemek veya çıkarmak için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **100** belgeye tag eklenebilir/çıkarılabilir.
         * @summary Etiket ekler/çıkarır
         * @param {TaggingDto} taggingDto #### Etiket eklemek veya çıkarmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersTagsPut: async (requestParameters: V1VouchersTagsPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'taggingDto' is not null or undefined
            if (requestParameters.taggingDto === null || requestParameters.taggingDto === undefined) {
                throw new RequiredError('taggingDto','Required parameter taggingDto was null or undefined when calling v1VouchersTagsPut.');
            }
            const localVarPath = `/v1/vouchers/tags`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.taggingDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.taggingDto !== undefined ? requestParameters.taggingDto : {}) : (requestParameters.taggingDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sorgulanan belgeye ait işlem geçmişine bu uç ile ulaşabilirsiniz.
         * @summary İşlem geçmişini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersUuidHistoriesGet: async (requestParameters: V1VouchersUuidHistoriesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1VouchersUuidHistoriesGet.');
            }
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1VouchersUuidHistoriesGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1VouchersUuidHistoriesGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1VouchersUuidHistoriesGet.');
            }
            const localVarPath = `/v1/vouchers/{uuid}/histories`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye ait HTML görüntüye bu uç ile ulaşabilirsiniz.
         * @summary Belgeyi görüntüler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersUuidHtmlGet: async (requestParameters: V1VouchersUuidHtmlGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1VouchersUuidHtmlGet.');
            }
            const localVarPath = `/v1/vouchers/{uuid}/html`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sorgulanan belge içerisindeki mail adreslerinin listesine bu uç ile ulaşabilirsiniz.
         * @summary Mail adreslerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersUuidMailaddressesGet: async (requestParameters: V1VouchersUuidMailaddressesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1VouchersUuidMailaddressesGet.');
            }
            const localVarPath = `/v1/vouchers/{uuid}/mailaddresses`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sorgulanan belgeye ait mail gönderim geçmişine bu uç ile ulaşabilirsiniz.
         * @summary Mail geçmişini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersUuidMailhistoriesGet: async (requestParameters: V1VouchersUuidMailhistoriesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1VouchersUuidMailhistoriesGet.');
            }
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1VouchersUuidMailhistoriesGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1VouchersUuidMailhistoriesGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1VouchersUuidMailhistoriesGet.');
            }
            const localVarPath = `/v1/vouchers/{uuid}/mailhistories`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye ait PDF dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary PDF İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersUuidPdfGet: async (requestParameters: V1VouchersUuidPdfGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1VouchersUuidPdfGet.');
            }
            const localVarPath = `/v1/vouchers/{uuid}/pdf`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gelen belgeler için göndericiyi, giden belgeler içinse alıcıyı **Firma Listesi**\'ne kaydetmek için bu uç kullanılabilir.
         * @summary Firma olarak kaydet
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersUuidSavecompanyindocumentPost: async (requestParameters: V1VouchersUuidSavecompanyindocumentPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1VouchersUuidSavecompanyindocumentPost.');
            }
            const localVarPath = `/v1/vouchers/{uuid}/savecompanyindocument`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belge içerisinde belirtilen vergi bilgilerine bu uç ile ulaşabilirsiniz.
         * @summary Vergi bilgilerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersUuidTaxesGet: async (requestParameters: V1VouchersUuidTaxesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1VouchersUuidTaxesGet.');
            }
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1VouchersUuidTaxesGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1VouchersUuidTaxesGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1VouchersUuidTaxesGet.');
            }
            const localVarPath = `/v1/vouchers/{uuid}/taxes`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sorgulanan belgeye ait kullanıcı tarafından eklenmiş notlara bu uç ile ulaşabilirsiniz.
         * @summary Kullanıcı notu listeler
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersUuidUsernotesGet: async (requestParameters: V1VouchersUuidUsernotesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1VouchersUuidUsernotesGet.');
            }
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1VouchersUuidUsernotesGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1VouchersUuidUsernotesGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1VouchersUuidUsernotesGet.');
            }
            const localVarPath = `/v1/vouchers/{uuid}/usernotes`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Kullanıcı tarafından oluşturulmuş olan notu silmek için bu uç kullanılabilir.
         * @summary Kullanıcı notunu siler
         * @param {string} uuid **Belge uuid**
         * @param {string} id **Not id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersUuidUsernotesIdDelete: async (requestParameters: V1VouchersUuidUsernotesIdDeleteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1VouchersUuidUsernotesIdDelete.');
            }
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1VouchersUuidUsernotesIdDelete.');
            }
            const localVarPath = `/v1/vouchers/{uuid}/usernotes/{id}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye daha önce eklenmiş olan kullanıcı notunu güncellemek için bu uç kullanılabilir.
         * @summary Kullanıcı notunu günceller
         * @param {string} uuid #### Belgeye ait uuid
         * @param {string} id #### Not id
         * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersUuidUsernotesIdPut: async (requestParameters: V1VouchersUuidUsernotesIdPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1VouchersUuidUsernotesIdPut.');
            }
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1VouchersUuidUsernotesIdPut.');
            }
            // verify required parameter 'noteDto' is not null or undefined
            if (requestParameters.noteDto === null || requestParameters.noteDto === undefined) {
                throw new RequiredError('noteDto','Required parameter noteDto was null or undefined when calling v1VouchersUuidUsernotesIdPut.');
            }
            const localVarPath = `/v1/vouchers/{uuid}/usernotes/{id}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.noteDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.noteDto !== undefined ? requestParameters.noteDto : {}) : (requestParameters.noteDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye kullanıcı notu eklemek için bu uç kullanılabilir.
         * @summary Kullanıcı notu ekler
         * @param {string} uuid #### Belge uuid
         * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersUuidUsernotesPost: async (requestParameters: V1VouchersUuidUsernotesPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1VouchersUuidUsernotesPost.');
            }
            // verify required parameter 'noteDto' is not null or undefined
            if (requestParameters.noteDto === null || requestParameters.noteDto === undefined) {
                throw new RequiredError('noteDto','Required parameter noteDto was null or undefined when calling v1VouchersUuidUsernotesPost.');
            }
            const localVarPath = `/v1/vouchers/{uuid}/usernotes`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.noteDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.noteDto !== undefined ? requestParameters.noteDto : {}) : (requestParameters.noteDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye ait XML dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary XML İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersUuidXmlGet: async (requestParameters: V1VouchersUuidXmlGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1VouchersUuidXmlGet.');
            }
            const localVarPath = `/v1/vouchers/{uuid}/xml`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VouchersApi - functional programming interface
 * @export
 */
export const VouchersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Belgelere toplu olarak yeni durum atamak için bu uç kullanılabilir.
         * @summary Yeni durum atar
         * @param {VoucherSetParameter} operation **İşlem**
         * @param {Array<string>} requestBody **Belge uuid listesi**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VouchersBulkOperationPut(requestParameters: V1VouchersBulkOperationPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VouchersApiAxiosParamCreator(configuration).v1VouchersBulkOperationPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeyi iptal etmek için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **50** kayıt iptal edilebilir.
         * @summary Belgeyi iptal eder
         * @param {DocumentCancellationDto} [documentCancellationDto] #### İptal için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VouchersCancelPost(requestParameters: V1VouchersCancelPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VouchersApiAxiosParamCreator(configuration).v1VouchersCancelPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * İptal edilmiş olan e-Müstahsil makbuzlarınıza bu uç ile ulaşabilirsiniz.
         * @summary İptal edilen makbuzlar
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [documentNote] Belge içinde geçen nota göre filtreleme yapabilirsiniz
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {string} [startCreateDate] Oluşturma tarihi başlangıcı
         * @param {string} [endCreateDate] Oluşturma tarihi sonu
         * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
         * @param {string} [startDate] Başlangıç tarihi
         * @param {string} [endDate] Bitiş tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VouchersCanceledGet(requestParameters: V1VouchersCanceledGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoucherCanceledPaginationResult>> {
            const localVarAxiosArgs = await VouchersApiAxiosParamCreator(configuration).v1VouchersCanceledGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * İptal edilmiş olan son 10 e-Müstahsil makbuzlarınıza bu uç ile ulaşabilirsiniz.
         * @summary İptal edilen son 10 makbuzlar
         * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VouchersCanceledLastGet(requestParameters: V1VouchersCanceledLastGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VoucherCanceled>>> {
            const localVarAxiosArgs = await VouchersApiAxiosParamCreator(configuration).v1VouchersCanceledLastGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * İptal edilmiş ve henüz raporlanmamış olan belgeye ait iptal işlemini geri almak için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **50** iptal kaydı geri alınabilir.
         * @summary İptal işlemini geri alır
         * @param {DocumentCancellationDto} documentCancellationDto **İptal işlemini geri almak için kullanılacak model**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VouchersCanceledWithdrawPost(requestParameters: V1VouchersCanceledWithdrawPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VouchersApiAxiosParamCreator(configuration).v1VouchersCanceledWithdrawPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Taslak belgeleri silmek için bu uç kullanılablir
         * @param {Array<string>} requestBody #### Taslak belge UUID listesi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VouchersDraftsDelete(requestParameters: V1VouchersDraftsDeleteRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VouchersApiAxiosParamCreator(configuration).v1VouchersDraftsDelete(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * E-Müstahsil Makbuzu taslaklarınıza bu uç ile ulaşabilirsiniz.
         * @summary Taslak makbuzları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VouchersDraftsGet(requestParameters: V1VouchersDraftsGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoucherPaginationResult>> {
            const localVarAxiosArgs = await VouchersApiAxiosParamCreator(configuration).v1VouchersDraftsGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgelerinizi mail olarak iletmek için bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **50** farklı belge, **10** farklı mail adresi gönderilebilir.
         * @summary Belgeyi mail olarak iletir
         * @param {EmailSendDto} emailSendDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VouchersEmailSendPost(requestParameters: V1VouchersEmailSendPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VouchersApiAxiosParamCreator(configuration).v1VouchersEmailSendPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgelerinizi toplu olarak dışarı aktarmak isterseniz bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **100** kayıt dışarı aktarılabilir.    **fileType** Parametre detayları    > **Xml** : Belgenizin XML halini dışarı aktarır    > **Envelope** : Belgenizin Zarf XML halini dışarı aktarır    > **Pdf** : Belgenize ait PDF görüntüsünü dışarı aktarır    > **UnifiedPdf** : ***uuids*** parametresinde gönderilen belgeleri tek PDF dosyası halinde dışarı aktarır    > **Excel** : ***uuids*** parametresinde gönderilen belgeleri Excel\'e aktarır
         * @summary Toplu aktar
         * @param {FileExportTypeParameter} fileType 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VouchersExportFileTypePost(requestParameters: V1VouchersExportFileTypePostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VouchersApiAxiosParamCreator(configuration).v1VouchersExportFileTypePost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Düzenlenmiş olan e-Müstahsil Makbuzlarınıza bu uç ile ulaşabilirsiniz.
         * @summary Makbuzları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {Array<string>} [tags] Makbuza atanmış etiketlere göre filtreleme yapabilirsiniz
         * @param {string} [userNote] Kullanıcı notuna göre filtreleme yapabilirsiniz
         * @param {string} [documentNote] Belge içinde geçen nota göre filtreleme yapabilirsiniz
         * @param {string} [orderNumber] Sipariş numarasına göre filtreleme yapabilirsiniz
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {string} [startCreateDate] Oluşturma tarihi başlangıcı
         * @param {string} [endCreateDate] Oluşturma tarihi sonu
         * @param {ArchiveDocumentStatusParameter} [voucherStatus] Makbuz durumuna göre filtreleme yapabilirsiniz
         * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
         * @param {boolean} [includeCanceledDocuments] İptal edilmiş olan belgeleri de listelemek isterseniz **true** göndermelisiniz
         * @param {MailStatusFilterParameter} [mailStatus] Mail durumuna göre filtreleme yapabilirsiniz
         * @param {string} [startDate] Başlangıç tarihi
         * @param {string} [endDate] Bitiş tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VouchersGet(requestParameters: V1VouchersGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoucherPaginationResult>> {
            const localVarAxiosArgs = await VouchersApiAxiosParamCreator(configuration).v1VouchersGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Düzenlenmiş olan son 10 e-Müstahsil Makbuzlarınıza bu uç ile ulaşabilirsiniz.
         * @summary Son 10 makbuzları listeler
         * @param {ArchiveDocumentStatusParameter} [voucherStatus] Makbuz durumuna göre filtreleme yapabilirsiniz
         * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
         * @param {boolean} [includeCanceledDocuments] İptal edilmiş olan belgeleri de listelemek isterseniz **true** göndermelisiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VouchersLastGet(requestParameters: V1VouchersLastGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Voucher>>> {
            const localVarAxiosArgs = await VouchersApiAxiosParamCreator(configuration).v1VouchersLastGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye etiket eklemek veya çıkarmak için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **100** belgeye tag eklenebilir/çıkarılabilir.
         * @summary Etiket ekler/çıkarır
         * @param {TaggingDto} taggingDto #### Etiket eklemek veya çıkarmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VouchersTagsPut(requestParameters: V1VouchersTagsPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VouchersApiAxiosParamCreator(configuration).v1VouchersTagsPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Sorgulanan belgeye ait işlem geçmişine bu uç ile ulaşabilirsiniz.
         * @summary İşlem geçmişini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VouchersUuidHistoriesGet(requestParameters: V1VouchersUuidHistoriesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoryPaginationResult>> {
            const localVarAxiosArgs = await VouchersApiAxiosParamCreator(configuration).v1VouchersUuidHistoriesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye ait HTML görüntüye bu uç ile ulaşabilirsiniz.
         * @summary Belgeyi görüntüler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VouchersUuidHtmlGet(requestParameters: V1VouchersUuidHtmlGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VouchersApiAxiosParamCreator(configuration).v1VouchersUuidHtmlGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Sorgulanan belge içerisindeki mail adreslerinin listesine bu uç ile ulaşabilirsiniz.
         * @summary Mail adreslerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VouchersUuidMailaddressesGet(requestParameters: V1VouchersUuidMailaddressesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await VouchersApiAxiosParamCreator(configuration).v1VouchersUuidMailaddressesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Sorgulanan belgeye ait mail gönderim geçmişine bu uç ile ulaşabilirsiniz.
         * @summary Mail geçmişini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VouchersUuidMailhistoriesGet(requestParameters: V1VouchersUuidMailhistoriesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailHistoryPaginationResult>> {
            const localVarAxiosArgs = await VouchersApiAxiosParamCreator(configuration).v1VouchersUuidMailhistoriesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye ait PDF dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary PDF İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VouchersUuidPdfGet(requestParameters: V1VouchersUuidPdfGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VouchersApiAxiosParamCreator(configuration).v1VouchersUuidPdfGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Gelen belgeler için göndericiyi, giden belgeler içinse alıcıyı **Firma Listesi**\'ne kaydetmek için bu uç kullanılabilir.
         * @summary Firma olarak kaydet
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VouchersUuidSavecompanyindocumentPost(requestParameters: V1VouchersUuidSavecompanyindocumentPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await VouchersApiAxiosParamCreator(configuration).v1VouchersUuidSavecompanyindocumentPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belge içerisinde belirtilen vergi bilgilerine bu uç ile ulaşabilirsiniz.
         * @summary Vergi bilgilerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VouchersUuidTaxesGet(requestParameters: V1VouchersUuidTaxesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxInfoPaginationResult>> {
            const localVarAxiosArgs = await VouchersApiAxiosParamCreator(configuration).v1VouchersUuidTaxesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Sorgulanan belgeye ait kullanıcı tarafından eklenmiş notlara bu uç ile ulaşabilirsiniz.
         * @summary Kullanıcı notu listeler
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VouchersUuidUsernotesGet(requestParameters: V1VouchersUuidUsernotesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotePaginationResult>> {
            const localVarAxiosArgs = await VouchersApiAxiosParamCreator(configuration).v1VouchersUuidUsernotesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Kullanıcı tarafından oluşturulmuş olan notu silmek için bu uç kullanılabilir.
         * @summary Kullanıcı notunu siler
         * @param {string} uuid **Belge uuid**
         * @param {string} id **Not id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VouchersUuidUsernotesIdDelete(requestParameters: V1VouchersUuidUsernotesIdDeleteRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VouchersApiAxiosParamCreator(configuration).v1VouchersUuidUsernotesIdDelete(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye daha önce eklenmiş olan kullanıcı notunu güncellemek için bu uç kullanılabilir.
         * @summary Kullanıcı notunu günceller
         * @param {string} uuid #### Belgeye ait uuid
         * @param {string} id #### Not id
         * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VouchersUuidUsernotesIdPut(requestParameters: V1VouchersUuidUsernotesIdPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VouchersApiAxiosParamCreator(configuration).v1VouchersUuidUsernotesIdPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye kullanıcı notu eklemek için bu uç kullanılabilir.
         * @summary Kullanıcı notu ekler
         * @param {string} uuid #### Belge uuid
         * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VouchersUuidUsernotesPost(requestParameters: V1VouchersUuidUsernotesPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Note>> {
            const localVarAxiosArgs = await VouchersApiAxiosParamCreator(configuration).v1VouchersUuidUsernotesPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye ait XML dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary XML İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VouchersUuidXmlGet(requestParameters: V1VouchersUuidXmlGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VouchersApiAxiosParamCreator(configuration).v1VouchersUuidXmlGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * VouchersApi - factory interface
 * @export
 */
export const VouchersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Belgelere toplu olarak yeni durum atamak için bu uç kullanılabilir.
         * @summary Yeni durum atar
         * @param {VoucherSetParameter} operation **İşlem**
         * @param {Array<string>} requestBody **Belge uuid listesi**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersBulkOperationPut(requestParameters: V1VouchersBulkOperationPutRequest, options?: any): AxiosPromise<void> {
            return VouchersApiFp(configuration).v1VouchersBulkOperationPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeyi iptal etmek için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **50** kayıt iptal edilebilir.
         * @summary Belgeyi iptal eder
         * @param {DocumentCancellationDto} [documentCancellationDto] #### İptal için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersCancelPost(requestParameters: V1VouchersCancelPostRequest, options?: any): AxiosPromise<void> {
            return VouchersApiFp(configuration).v1VouchersCancelPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * İptal edilmiş olan e-Müstahsil makbuzlarınıza bu uç ile ulaşabilirsiniz.
         * @summary İptal edilen makbuzlar
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [documentNote] Belge içinde geçen nota göre filtreleme yapabilirsiniz
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {string} [startCreateDate] Oluşturma tarihi başlangıcı
         * @param {string} [endCreateDate] Oluşturma tarihi sonu
         * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
         * @param {string} [startDate] Başlangıç tarihi
         * @param {string} [endDate] Bitiş tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersCanceledGet(requestParameters: V1VouchersCanceledGetRequest, options?: any): AxiosPromise<VoucherCanceledPaginationResult> {
            return VouchersApiFp(configuration).v1VouchersCanceledGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * İptal edilmiş olan son 10 e-Müstahsil makbuzlarınıza bu uç ile ulaşabilirsiniz.
         * @summary İptal edilen son 10 makbuzlar
         * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersCanceledLastGet(requestParameters: V1VouchersCanceledLastGetRequest, options?: any): AxiosPromise<Array<VoucherCanceled>> {
            return VouchersApiFp(configuration).v1VouchersCanceledLastGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * İptal edilmiş ve henüz raporlanmamış olan belgeye ait iptal işlemini geri almak için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **50** iptal kaydı geri alınabilir.
         * @summary İptal işlemini geri alır
         * @param {DocumentCancellationDto} documentCancellationDto **İptal işlemini geri almak için kullanılacak model**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersCanceledWithdrawPost(requestParameters: V1VouchersCanceledWithdrawPostRequest, options?: any): AxiosPromise<void> {
            return VouchersApiFp(configuration).v1VouchersCanceledWithdrawPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Taslak belgeleri silmek için bu uç kullanılablir
         * @param {Array<string>} requestBody #### Taslak belge UUID listesi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersDraftsDelete(requestParameters: V1VouchersDraftsDeleteRequest, options?: any): AxiosPromise<void> {
            return VouchersApiFp(configuration).v1VouchersDraftsDelete(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * E-Müstahsil Makbuzu taslaklarınıza bu uç ile ulaşabilirsiniz.
         * @summary Taslak makbuzları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersDraftsGet(requestParameters: V1VouchersDraftsGetRequest, options?: any): AxiosPromise<VoucherPaginationResult> {
            return VouchersApiFp(configuration).v1VouchersDraftsGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgelerinizi mail olarak iletmek için bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **50** farklı belge, **10** farklı mail adresi gönderilebilir.
         * @summary Belgeyi mail olarak iletir
         * @param {EmailSendDto} emailSendDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersEmailSendPost(requestParameters: V1VouchersEmailSendPostRequest, options?: any): AxiosPromise<void> {
            return VouchersApiFp(configuration).v1VouchersEmailSendPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgelerinizi toplu olarak dışarı aktarmak isterseniz bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **100** kayıt dışarı aktarılabilir.    **fileType** Parametre detayları    > **Xml** : Belgenizin XML halini dışarı aktarır    > **Envelope** : Belgenizin Zarf XML halini dışarı aktarır    > **Pdf** : Belgenize ait PDF görüntüsünü dışarı aktarır    > **UnifiedPdf** : ***uuids*** parametresinde gönderilen belgeleri tek PDF dosyası halinde dışarı aktarır    > **Excel** : ***uuids*** parametresinde gönderilen belgeleri Excel\'e aktarır
         * @summary Toplu aktar
         * @param {FileExportTypeParameter} fileType 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersExportFileTypePost(requestParameters: V1VouchersExportFileTypePostRequest, options?: any): AxiosPromise<void> {
            return VouchersApiFp(configuration).v1VouchersExportFileTypePost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Düzenlenmiş olan e-Müstahsil Makbuzlarınıza bu uç ile ulaşabilirsiniz.
         * @summary Makbuzları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {Array<string>} [tags] Makbuza atanmış etiketlere göre filtreleme yapabilirsiniz
         * @param {string} [userNote] Kullanıcı notuna göre filtreleme yapabilirsiniz
         * @param {string} [documentNote] Belge içinde geçen nota göre filtreleme yapabilirsiniz
         * @param {string} [orderNumber] Sipariş numarasına göre filtreleme yapabilirsiniz
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {string} [startCreateDate] Oluşturma tarihi başlangıcı
         * @param {string} [endCreateDate] Oluşturma tarihi sonu
         * @param {ArchiveDocumentStatusParameter} [voucherStatus] Makbuz durumuna göre filtreleme yapabilirsiniz
         * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
         * @param {boolean} [includeCanceledDocuments] İptal edilmiş olan belgeleri de listelemek isterseniz **true** göndermelisiniz
         * @param {MailStatusFilterParameter} [mailStatus] Mail durumuna göre filtreleme yapabilirsiniz
         * @param {string} [startDate] Başlangıç tarihi
         * @param {string} [endDate] Bitiş tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersGet(requestParameters: V1VouchersGetRequest, options?: any): AxiosPromise<VoucherPaginationResult> {
            return VouchersApiFp(configuration).v1VouchersGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Düzenlenmiş olan son 10 e-Müstahsil Makbuzlarınıza bu uç ile ulaşabilirsiniz.
         * @summary Son 10 makbuzları listeler
         * @param {ArchiveDocumentStatusParameter} [voucherStatus] Makbuz durumuna göre filtreleme yapabilirsiniz
         * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
         * @param {boolean} [includeCanceledDocuments] İptal edilmiş olan belgeleri de listelemek isterseniz **true** göndermelisiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersLastGet(requestParameters: V1VouchersLastGetRequest, options?: any): AxiosPromise<Array<Voucher>> {
            return VouchersApiFp(configuration).v1VouchersLastGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye etiket eklemek veya çıkarmak için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **100** belgeye tag eklenebilir/çıkarılabilir.
         * @summary Etiket ekler/çıkarır
         * @param {TaggingDto} taggingDto #### Etiket eklemek veya çıkarmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersTagsPut(requestParameters: V1VouchersTagsPutRequest, options?: any): AxiosPromise<void> {
            return VouchersApiFp(configuration).v1VouchersTagsPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Sorgulanan belgeye ait işlem geçmişine bu uç ile ulaşabilirsiniz.
         * @summary İşlem geçmişini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersUuidHistoriesGet(requestParameters: V1VouchersUuidHistoriesGetRequest, options?: any): AxiosPromise<HistoryPaginationResult> {
            return VouchersApiFp(configuration).v1VouchersUuidHistoriesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye ait HTML görüntüye bu uç ile ulaşabilirsiniz.
         * @summary Belgeyi görüntüler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersUuidHtmlGet(requestParameters: V1VouchersUuidHtmlGetRequest, options?: any): AxiosPromise<void> {
            return VouchersApiFp(configuration).v1VouchersUuidHtmlGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Sorgulanan belge içerisindeki mail adreslerinin listesine bu uç ile ulaşabilirsiniz.
         * @summary Mail adreslerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersUuidMailaddressesGet(requestParameters: V1VouchersUuidMailaddressesGetRequest, options?: any): AxiosPromise<Array<string>> {
            return VouchersApiFp(configuration).v1VouchersUuidMailaddressesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Sorgulanan belgeye ait mail gönderim geçmişine bu uç ile ulaşabilirsiniz.
         * @summary Mail geçmişini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersUuidMailhistoriesGet(requestParameters: V1VouchersUuidMailhistoriesGetRequest, options?: any): AxiosPromise<MailHistoryPaginationResult> {
            return VouchersApiFp(configuration).v1VouchersUuidMailhistoriesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye ait PDF dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary PDF İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersUuidPdfGet(requestParameters: V1VouchersUuidPdfGetRequest, options?: any): AxiosPromise<void> {
            return VouchersApiFp(configuration).v1VouchersUuidPdfGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Gelen belgeler için göndericiyi, giden belgeler içinse alıcıyı **Firma Listesi**\'ne kaydetmek için bu uç kullanılabilir.
         * @summary Firma olarak kaydet
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersUuidSavecompanyindocumentPost(requestParameters: V1VouchersUuidSavecompanyindocumentPostRequest, options?: any): AxiosPromise<Company> {
            return VouchersApiFp(configuration).v1VouchersUuidSavecompanyindocumentPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belge içerisinde belirtilen vergi bilgilerine bu uç ile ulaşabilirsiniz.
         * @summary Vergi bilgilerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersUuidTaxesGet(requestParameters: V1VouchersUuidTaxesGetRequest, options?: any): AxiosPromise<TaxInfoPaginationResult> {
            return VouchersApiFp(configuration).v1VouchersUuidTaxesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Sorgulanan belgeye ait kullanıcı tarafından eklenmiş notlara bu uç ile ulaşabilirsiniz.
         * @summary Kullanıcı notu listeler
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersUuidUsernotesGet(requestParameters: V1VouchersUuidUsernotesGetRequest, options?: any): AxiosPromise<NotePaginationResult> {
            return VouchersApiFp(configuration).v1VouchersUuidUsernotesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Kullanıcı tarafından oluşturulmuş olan notu silmek için bu uç kullanılabilir.
         * @summary Kullanıcı notunu siler
         * @param {string} uuid **Belge uuid**
         * @param {string} id **Not id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersUuidUsernotesIdDelete(requestParameters: V1VouchersUuidUsernotesIdDeleteRequest, options?: any): AxiosPromise<void> {
            return VouchersApiFp(configuration).v1VouchersUuidUsernotesIdDelete(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye daha önce eklenmiş olan kullanıcı notunu güncellemek için bu uç kullanılabilir.
         * @summary Kullanıcı notunu günceller
         * @param {string} uuid #### Belgeye ait uuid
         * @param {string} id #### Not id
         * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersUuidUsernotesIdPut(requestParameters: V1VouchersUuidUsernotesIdPutRequest, options?: any): AxiosPromise<void> {
            return VouchersApiFp(configuration).v1VouchersUuidUsernotesIdPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye kullanıcı notu eklemek için bu uç kullanılabilir.
         * @summary Kullanıcı notu ekler
         * @param {string} uuid #### Belge uuid
         * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersUuidUsernotesPost(requestParameters: V1VouchersUuidUsernotesPostRequest, options?: any): AxiosPromise<Note> {
            return VouchersApiFp(configuration).v1VouchersUuidUsernotesPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye ait XML dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary XML İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VouchersUuidXmlGet(requestParameters: V1VouchersUuidXmlGetRequest, options?: any): AxiosPromise<void> {
            return VouchersApiFp(configuration).v1VouchersUuidXmlGet(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VouchersApi - object-oriented interface
 * @export
 * @class VouchersApi
 * @extends {BaseAPI}
 */
export class VouchersApi extends BaseAPI {
    /**
     * Belgelere toplu olarak yeni durum atamak için bu uç kullanılabilir.
     * @summary Yeni durum atar
     * @param {VoucherSetParameter} operation **İşlem**
     * @param {Array<string>} requestBody **Belge uuid listesi**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public v1VouchersBulkOperationPut(requestParameters: V1VouchersBulkOperationPutRequest, options?: any) {
        return VouchersApiFp(this.configuration).v1VouchersBulkOperationPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeyi iptal etmek için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **50** kayıt iptal edilebilir.
     * @summary Belgeyi iptal eder
     * @param {DocumentCancellationDto} [documentCancellationDto] #### İptal için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public v1VouchersCancelPost(requestParameters: V1VouchersCancelPostRequest, options?: any) {
        return VouchersApiFp(this.configuration).v1VouchersCancelPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * İptal edilmiş olan e-Müstahsil makbuzlarınıza bu uç ile ulaşabilirsiniz.
     * @summary İptal edilen makbuzlar
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {string} [documentNote] Belge içinde geçen nota göre filtreleme yapabilirsiniz
     * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
     * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
     * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
     * @param {string} [startCreateDate] Oluşturma tarihi başlangıcı
     * @param {string} [endCreateDate] Oluşturma tarihi sonu
     * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
     * @param {string} [startDate] Başlangıç tarihi
     * @param {string} [endDate] Bitiş tarihi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public v1VouchersCanceledGet(requestParameters: V1VouchersCanceledGetRequest, options?: any) {
        return VouchersApiFp(this.configuration).v1VouchersCanceledGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * İptal edilmiş olan son 10 e-Müstahsil makbuzlarınıza bu uç ile ulaşabilirsiniz.
     * @summary İptal edilen son 10 makbuzlar
     * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public v1VouchersCanceledLastGet(requestParameters: V1VouchersCanceledLastGetRequest, options?: any) {
        return VouchersApiFp(this.configuration).v1VouchersCanceledLastGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * İptal edilmiş ve henüz raporlanmamış olan belgeye ait iptal işlemini geri almak için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **50** iptal kaydı geri alınabilir.
     * @summary İptal işlemini geri alır
     * @param {DocumentCancellationDto} documentCancellationDto **İptal işlemini geri almak için kullanılacak model**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public v1VouchersCanceledWithdrawPost(requestParameters: V1VouchersCanceledWithdrawPostRequest, options?: any) {
        return VouchersApiFp(this.configuration).v1VouchersCanceledWithdrawPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Taslak belgeleri silmek için bu uç kullanılablir
     * @param {Array<string>} requestBody #### Taslak belge UUID listesi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public v1VouchersDraftsDelete(requestParameters: V1VouchersDraftsDeleteRequest, options?: any) {
        return VouchersApiFp(this.configuration).v1VouchersDraftsDelete(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * E-Müstahsil Makbuzu taslaklarınıza bu uç ile ulaşabilirsiniz.
     * @summary Taslak makbuzları listeler
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
     * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
     * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public v1VouchersDraftsGet(requestParameters: V1VouchersDraftsGetRequest, options?: any) {
        return VouchersApiFp(this.configuration).v1VouchersDraftsGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgelerinizi mail olarak iletmek için bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **50** farklı belge, **10** farklı mail adresi gönderilebilir.
     * @summary Belgeyi mail olarak iletir
     * @param {EmailSendDto} emailSendDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public v1VouchersEmailSendPost(requestParameters: V1VouchersEmailSendPostRequest, options?: any) {
        return VouchersApiFp(this.configuration).v1VouchersEmailSendPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgelerinizi toplu olarak dışarı aktarmak isterseniz bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **100** kayıt dışarı aktarılabilir.    **fileType** Parametre detayları    > **Xml** : Belgenizin XML halini dışarı aktarır    > **Envelope** : Belgenizin Zarf XML halini dışarı aktarır    > **Pdf** : Belgenize ait PDF görüntüsünü dışarı aktarır    > **UnifiedPdf** : ***uuids*** parametresinde gönderilen belgeleri tek PDF dosyası halinde dışarı aktarır    > **Excel** : ***uuids*** parametresinde gönderilen belgeleri Excel\'e aktarır
     * @summary Toplu aktar
     * @param {FileExportTypeParameter} fileType 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public v1VouchersExportFileTypePost(requestParameters: V1VouchersExportFileTypePostRequest, options?: any) {
        return VouchersApiFp(this.configuration).v1VouchersExportFileTypePost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Düzenlenmiş olan e-Müstahsil Makbuzlarınıza bu uç ile ulaşabilirsiniz.
     * @summary Makbuzları listeler
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {Array<string>} [tags] Makbuza atanmış etiketlere göre filtreleme yapabilirsiniz
     * @param {string} [userNote] Kullanıcı notuna göre filtreleme yapabilirsiniz
     * @param {string} [documentNote] Belge içinde geçen nota göre filtreleme yapabilirsiniz
     * @param {string} [orderNumber] Sipariş numarasına göre filtreleme yapabilirsiniz
     * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
     * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
     * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
     * @param {string} [startCreateDate] Oluşturma tarihi başlangıcı
     * @param {string} [endCreateDate] Oluşturma tarihi sonu
     * @param {ArchiveDocumentStatusParameter} [voucherStatus] Makbuz durumuna göre filtreleme yapabilirsiniz
     * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
     * @param {boolean} [includeCanceledDocuments] İptal edilmiş olan belgeleri de listelemek isterseniz **true** göndermelisiniz
     * @param {MailStatusFilterParameter} [mailStatus] Mail durumuna göre filtreleme yapabilirsiniz
     * @param {string} [startDate] Başlangıç tarihi
     * @param {string} [endDate] Bitiş tarihi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public v1VouchersGet(requestParameters: V1VouchersGetRequest, options?: any) {
        return VouchersApiFp(this.configuration).v1VouchersGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Düzenlenmiş olan son 10 e-Müstahsil Makbuzlarınıza bu uç ile ulaşabilirsiniz.
     * @summary Son 10 makbuzları listeler
     * @param {ArchiveDocumentStatusParameter} [voucherStatus] Makbuz durumuna göre filtreleme yapabilirsiniz
     * @param {BaseReportDivisionStatusParameter} [reportDivisionStatus] Rapor durumuna göre filtreleme yapabilirsiniz
     * @param {boolean} [includeCanceledDocuments] İptal edilmiş olan belgeleri de listelemek isterseniz **true** göndermelisiniz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public v1VouchersLastGet(requestParameters: V1VouchersLastGetRequest, options?: any) {
        return VouchersApiFp(this.configuration).v1VouchersLastGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye etiket eklemek veya çıkarmak için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **100** belgeye tag eklenebilir/çıkarılabilir.
     * @summary Etiket ekler/çıkarır
     * @param {TaggingDto} taggingDto #### Etiket eklemek veya çıkarmak için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public v1VouchersTagsPut(requestParameters: V1VouchersTagsPutRequest, options?: any) {
        return VouchersApiFp(this.configuration).v1VouchersTagsPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sorgulanan belgeye ait işlem geçmişine bu uç ile ulaşabilirsiniz.
     * @summary İşlem geçmişini getirir
     * @param {string} uuid **Belge uuid**
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public v1VouchersUuidHistoriesGet(requestParameters: V1VouchersUuidHistoriesGetRequest, options?: any) {
        return VouchersApiFp(this.configuration).v1VouchersUuidHistoriesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye ait HTML görüntüye bu uç ile ulaşabilirsiniz.
     * @summary Belgeyi görüntüler
     * @param {string} uuid **Belge uuid**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public v1VouchersUuidHtmlGet(requestParameters: V1VouchersUuidHtmlGetRequest, options?: any) {
        return VouchersApiFp(this.configuration).v1VouchersUuidHtmlGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sorgulanan belge içerisindeki mail adreslerinin listesine bu uç ile ulaşabilirsiniz.
     * @summary Mail adreslerini getirir
     * @param {string} uuid **Belge uuid**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public v1VouchersUuidMailaddressesGet(requestParameters: V1VouchersUuidMailaddressesGetRequest, options?: any) {
        return VouchersApiFp(this.configuration).v1VouchersUuidMailaddressesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sorgulanan belgeye ait mail gönderim geçmişine bu uç ile ulaşabilirsiniz.
     * @summary Mail geçmişini getirir
     * @param {string} uuid **Belge uuid**
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public v1VouchersUuidMailhistoriesGet(requestParameters: V1VouchersUuidMailhistoriesGetRequest, options?: any) {
        return VouchersApiFp(this.configuration).v1VouchersUuidMailhistoriesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye ait PDF dosyasını indirmek için bu ucu kullanabilirsiniz.
     * @summary PDF İndir
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public v1VouchersUuidPdfGet(requestParameters: V1VouchersUuidPdfGetRequest, options?: any) {
        return VouchersApiFp(this.configuration).v1VouchersUuidPdfGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gelen belgeler için göndericiyi, giden belgeler içinse alıcıyı **Firma Listesi**\'ne kaydetmek için bu uç kullanılabilir.
     * @summary Firma olarak kaydet
     * @param {string} uuid **Belge uuid**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public v1VouchersUuidSavecompanyindocumentPost(requestParameters: V1VouchersUuidSavecompanyindocumentPostRequest, options?: any) {
        return VouchersApiFp(this.configuration).v1VouchersUuidSavecompanyindocumentPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belge içerisinde belirtilen vergi bilgilerine bu uç ile ulaşabilirsiniz.
     * @summary Vergi bilgilerini getirir
     * @param {string} uuid **Belge uuid**
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public v1VouchersUuidTaxesGet(requestParameters: V1VouchersUuidTaxesGetRequest, options?: any) {
        return VouchersApiFp(this.configuration).v1VouchersUuidTaxesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sorgulanan belgeye ait kullanıcı tarafından eklenmiş notlara bu uç ile ulaşabilirsiniz.
     * @summary Kullanıcı notu listeler
     * @param {string} uuid **Belge uuid**
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public v1VouchersUuidUsernotesGet(requestParameters: V1VouchersUuidUsernotesGetRequest, options?: any) {
        return VouchersApiFp(this.configuration).v1VouchersUuidUsernotesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Kullanıcı tarafından oluşturulmuş olan notu silmek için bu uç kullanılabilir.
     * @summary Kullanıcı notunu siler
     * @param {string} uuid **Belge uuid**
     * @param {string} id **Not id**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public v1VouchersUuidUsernotesIdDelete(requestParameters: V1VouchersUuidUsernotesIdDeleteRequest, options?: any) {
        return VouchersApiFp(this.configuration).v1VouchersUuidUsernotesIdDelete(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye daha önce eklenmiş olan kullanıcı notunu güncellemek için bu uç kullanılabilir.
     * @summary Kullanıcı notunu günceller
     * @param {string} uuid #### Belgeye ait uuid
     * @param {string} id #### Not id
     * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public v1VouchersUuidUsernotesIdPut(requestParameters: V1VouchersUuidUsernotesIdPutRequest, options?: any) {
        return VouchersApiFp(this.configuration).v1VouchersUuidUsernotesIdPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye kullanıcı notu eklemek için bu uç kullanılabilir.
     * @summary Kullanıcı notu ekler
     * @param {string} uuid #### Belge uuid
     * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public v1VouchersUuidUsernotesPost(requestParameters: V1VouchersUuidUsernotesPostRequest, options?: any) {
        return VouchersApiFp(this.configuration).v1VouchersUuidUsernotesPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye ait XML dosyasını indirmek için bu ucu kullanabilirsiniz.
     * @summary XML İndir
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public v1VouchersUuidXmlGet(requestParameters: V1VouchersUuidXmlGetRequest, options?: any) {
        return VouchersApiFp(this.configuration).v1VouchersUuidXmlGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1VouchersBulkOperationPutRequest {
    /**
     * **İşlem**
     */
    operation: VoucherSetParameter;
    /**
     * **Belge uuid listesi**
     */
    requestBody: Array<string>;
    }
    export interface V1VouchersCancelPostRequest {
    /**
     * #### İptal için gönderilmesi gereken model
     */
    documentCancellationDto?: DocumentCancellationDto;
    }
    export interface V1VouchersCanceledGetRequest {
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    /**
     * Belge içinde geçen nota göre filtreleme yapabilirsiniz
     */
    documentNote?: string;
    /**
     * Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
     */
    company?: string;
    /**
     * UUID numarasına göre filtreleme yapabilirsiniz
     */
    uuid?: string;
    /**
     * Belge numarasına göre filtreleme yapabilirsiniz
     */
    documentNumber?: string;
    /**
     * Oluşturma tarihi başlangıcı
     */
    startCreateDate?: string;
    /**
     * Oluşturma tarihi sonu
     */
    endCreateDate?: string;
    /**
     * Rapor durumuna göre filtreleme yapabilirsiniz
     */
    reportDivisionStatus?: BaseReportDivisionStatusParameter;
    /**
     * Başlangıç tarihi
     */
    startDate?: string;
    /**
     * Bitiş tarihi
     */
    endDate?: string;
    }
    export interface V1VouchersCanceledLastGetRequest {
    /**
     * Rapor durumuna göre filtreleme yapabilirsiniz
     */
    reportDivisionStatus?: BaseReportDivisionStatusParameter;
    }
    export interface V1VouchersCanceledWithdrawPostRequest {
    /**
     * **İptal işlemini geri almak için kullanılacak model**
     */
    documentCancellationDto: DocumentCancellationDto;
    }
    export interface V1VouchersDraftsDeleteRequest {
    /**
     * #### Taslak belge UUID listesi
     */
    requestBody: Array<string>;
    }
    export interface V1VouchersDraftsGetRequest {
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    /**
     * Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
     */
    company?: string;
    /**
     * UUID numarasına göre filtreleme yapabilirsiniz
     */
    uuid?: string;
    /**
     * Belge numarasına göre filtreleme yapabilirsiniz
     */
    documentNumber?: string;
    }
    export interface V1VouchersEmailSendPostRequest {
    /**
     * 
     */
    emailSendDto: EmailSendDto;
    }
    export interface V1VouchersExportFileTypePostRequest {
    /**
     * 
     */
    fileType: FileExportTypeParameter;
    /**
     * 
     */
    requestBody: Array<string>;
    }
    export interface V1VouchersGetRequest {
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    /**
     * Makbuza atanmış etiketlere göre filtreleme yapabilirsiniz
     */
    tags?: Array<string>;
    /**
     * Kullanıcı notuna göre filtreleme yapabilirsiniz
     */
    userNote?: string;
    /**
     * Belge içinde geçen nota göre filtreleme yapabilirsiniz
     */
    documentNote?: string;
    /**
     * Sipariş numarasına göre filtreleme yapabilirsiniz
     */
    orderNumber?: string;
    /**
     * Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
     */
    company?: string;
    /**
     * UUID numarasına göre filtreleme yapabilirsiniz
     */
    uuid?: string;
    /**
     * Belge numarasına göre filtreleme yapabilirsiniz
     */
    documentNumber?: string;
    /**
     * Oluşturma tarihi başlangıcı
     */
    startCreateDate?: string;
    /**
     * Oluşturma tarihi sonu
     */
    endCreateDate?: string;
    /**
     * Makbuz durumuna göre filtreleme yapabilirsiniz
     */
    voucherStatus?: ArchiveDocumentStatusParameter;
    /**
     * Rapor durumuna göre filtreleme yapabilirsiniz
     */
    reportDivisionStatus?: BaseReportDivisionStatusParameter;
    /**
     * İptal edilmiş olan belgeleri de listelemek isterseniz **true** göndermelisiniz
     */
    includeCanceledDocuments?: boolean;
    /**
     * Mail durumuna göre filtreleme yapabilirsiniz
     */
    mailStatus?: MailStatusFilterParameter;
    /**
     * Başlangıç tarihi
     */
    startDate?: string;
    /**
     * Bitiş tarihi
     */
    endDate?: string;
    }
    export interface V1VouchersLastGetRequest {
    /**
     * Makbuz durumuna göre filtreleme yapabilirsiniz
     */
    voucherStatus?: ArchiveDocumentStatusParameter;
    /**
     * Rapor durumuna göre filtreleme yapabilirsiniz
     */
    reportDivisionStatus?: BaseReportDivisionStatusParameter;
    /**
     * İptal edilmiş olan belgeleri de listelemek isterseniz **true** göndermelisiniz
     */
    includeCanceledDocuments?: boolean;
    }
    export interface V1VouchersTagsPutRequest {
    /**
     * #### Etiket eklemek veya çıkarmak için gönderilmesi gereken model
     */
    taggingDto: TaggingDto;
    }
    export interface V1VouchersUuidHistoriesGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    }
    export interface V1VouchersUuidHtmlGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    }
    export interface V1VouchersUuidMailaddressesGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    }
    export interface V1VouchersUuidMailhistoriesGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    }
    export interface V1VouchersUuidPdfGetRequest {
    /**
     * 
     */
    uuid: string;
    }
    export interface V1VouchersUuidSavecompanyindocumentPostRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    }
    export interface V1VouchersUuidTaxesGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    }
    export interface V1VouchersUuidUsernotesGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    }
    export interface V1VouchersUuidUsernotesIdDeleteRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    /**
     * **Not id**
     */
    id: string;
    }
    export interface V1VouchersUuidUsernotesIdPutRequest {
    /**
     * #### Belgeye ait uuid
     */
    uuid: string;
    /**
     * #### Not id
     */
    id: string;
    /**
     * #### Not eklemek için gönderilmesi gereken model
     */
    noteDto: NoteDto;
    }
    export interface V1VouchersUuidUsernotesPostRequest {
    /**
     * #### Belge uuid
     */
    uuid: string;
    /**
     * #### Not eklemek için gönderilmesi gereken model
     */
    noteDto: NoteDto;
    }
    export interface V1VouchersUuidXmlGetRequest {
    /**
     * 
     */
    uuid: string;
    }
