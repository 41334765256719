import { TaxInfo, TaxInfoPaginationResult } from '@/apis/einvoice/interfaces';
import { DocumentInfo, ErrorWrapper } from '@/components';
import { Close } from '@/components/Buttons';
import LocalPagination from '@/components/Pagination/LocalPagination';
import { TableDefaultLoading } from '@/components/TableBody';
import { TaxesWithKdv } from '@/constants/gib';
import { DocumentModal, Pagination } from '@/interfaces';
import { faHistory } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import _ from 'lodash';
import React, { memo, useState } from 'react';
import intl from 'react-intl-universal';

export interface DocumentTaxesModalAdditionalProps {
  documentCurrencyCode: string;
  refetch: (uuid?: string, documentNumber?: string, pagination?: Pagination, additionalProps?: DocumentTaxesModalAdditionalProps) => void;
}

export const DocumentTaxesModal = memo(
  ({
    closeModal,
    visible,
    documentNumber,
    uuid,
    data,
    loading,
    error,
    additionalProps
  }: DocumentModal<TaxInfoPaginationResult, DocumentTaxesModalAdditionalProps>) => {
    const [pagination, setPagination] = useState({ page: 1, pageSize: 10, sort: 'CreatedAt desc' });

    return (
      <Modal
        centered
        title={
          <span>
            <FontAwesomeIcon icon={faHistory} /> {intl.get('VERGI_DETAYLARI')}
          </span>
        }
        onCancel={closeModal}
        width={1024}
        visible={visible}
        footer={[<Close key="close-button" onClick={closeModal} />]}
      >
        <DocumentInfo documentNumber={documentNumber || ''} uuid={uuid || ''} />
        <Table
          showHeader={data !== undefined}
          components={ErrorWrapper({ error })}
          style={{ marginTop: '10px' }}
          loading={TableDefaultLoading(loading)}
          scroll={{ x: true, y: 512 }}
          bordered
          pagination={false}
          dataSource={(data && data.data) || []}
          rowKey={(record) => record.taxTypeCode}
        >
          <Table.Column
            key="TaxTypeCode"
            title={intl.get('VERGI_KODU')}
            dataIndex="TaxTypeCode"
            render={(text, record: TaxInfo) => {
              return <span>{record.taxTypeCode}</span>;
            }}
          />
          <Table.Column
            key="taxDescription"
            title={intl.get('VERGI_ADI')}
            dataIndex="TaxTypeCode"
            render={(text, record: TaxInfo) => {
              return TaxesWithKdv.filter((x) => x.taxTypeCode === record.taxTypeCode)[0].description;
            }}
          />
          <Table.Column
            key="percent"
            title={intl.get('ORAN')}
            width={150}
            align="right"
            render={(text, record: TaxInfo) => {
              return <span>{`${record.percent} %`}</span>;
            }}
          />
          <Table.Column
            key="taxableAmount"
            title={intl.get('VERGI_MATRAHI')}
            width={150}
            align="right"
            render={(_text, record: TaxInfo) => {
              return (
                <span>{`${record.taxableAmount === 0 ? ((record.taxAmount * 100) / record.percent).toFixed(2) : record.taxableAmount} ${
                  additionalProps?.documentCurrencyCode
                }`}</span>
              );
            }}
          />
          <Table.Column
            key="taxAmount"
            title={intl.get('VERGI_TUTARI')}
            width={150}
            align="right"
            render={(text, record: TaxInfo) => {
              return <span>{`${record.taxAmount} ${additionalProps?.documentCurrencyCode}`}</span>;
            }}
          />
        </Table>

        {data && data.data && data.data.length > 0 && (
          <LocalPagination
            data={data}
            selectedCount={0}
            execute={() => additionalProps && additionalProps.refetch(uuid, documentNumber, pagination, additionalProps)}
            page={pagination.page}
            pageSize={pagination.pageSize}
            onPageChange={(page, pageSize) => {
              setPagination({ ...pagination, page, pageSize });
            }}
          />
        )}
      </Modal>
    );
  },
  (prev, next) => _.isEqual(prev.data, next.data) && prev.visible === next.visible && prev.loading === next.loading
);

export default DocumentTaxesModal;
