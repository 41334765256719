import { Option } from '@/interfaces';
import { Select } from 'antd';
import React from 'react';
export const Year = ({ defaultValue, onChange }: { defaultValue?: string; onChange?: (value: string) => void }) => {
  const years: Array<Option> = [];
  const activeYear = new Date().getFullYear();
  for (let year = 2016; year <= activeYear; year++) {
    years.push({ value: year, text: year.toString() });
  }

  return (
    <Select className="w-100" onChange={onChange} defaultValue={defaultValue === undefined ? activeYear.toString() : defaultValue}>
      {years.map((option) => (
        <Select.Option key={option.value} value={option.value}>
          {option.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default Year;
