import { CheckCircleTwoTone, StopTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { memo } from 'react';
import intl from 'react-intl-universal';

export const BooleanComponent = memo(({ value, tooltip }: { value: boolean; tooltip?: string }) => {
  return (
    <Tooltip trigger="hover" placement="bottom" title={tooltip}>
      <div>
        {value === true ? <CheckCircleTwoTone twoToneColor={'#52c41a'} /> : <StopTwoTone twoToneColor={'#ff0000'} />}
        <span> {value === true ? intl.get('EVET') : intl.get('HAYIR')} </span>
      </div>
    </Tooltip>
  );
});

export default BooleanComponent;
