import { ErrorViewer, Loading } from '@/components/TableBody';
import PageFilterContext from '@/contexts/PageFilterContext';
import { AnyType } from '@/type';
import { Checkbox, Empty } from 'antd';
import { AxiosError } from 'axios';
import _ from 'lodash';
import React, { PropsWithChildren, ReactNode, memo, useContext, useEffect, useState } from 'react';
import BaseTable, { AutoResizer } from 'react-base-table';
import { v4 as uuidv4 } from 'uuid';
import ConnectorErrorViewer from '../TableBody/ConnectorErrorViewer';
import { JsxElement } from 'typescript';
const callOrReturn = (funcOrValue, ...args) => {
  return typeof funcOrValue === 'function' ? funcOrValue(...args) : funcOrValue;
};

interface ContainerProps<TPageData> {
  /**
   * cellRenderer içerisinde local state e erişim olmadığından, local state erişim gereksinimleri için state bu propa da geçilir.
   * https://github.com/Autodesk/react-base-table/issues/77
   */
  pageData: TPageData;
}

interface Container<TPageData> {
  props: ContainerProps<TPageData>;
}

export interface CellRendererProps<T, TPageData> {
  rowData: T;
  container: Container<TPageData>;
}

const normalizeColumns = (elements: AnyType) => {
  const columns: AnyType = [];
  React.Children.forEach(elements, (element) => {
    if (React.isValidElement(element) && element.key) {
      const column = { ...(element as AnyType).props, key: element.key };
      columns.push(column);
    }
  });
  return columns;
};

const SelectionCell = (params) => {
  const handleChange = (e) => {
    const { rowData, rowIndex, column } = params;
    const { onChange } = column;
    onChange({ selected: e.target.checked, rowData, rowIndex });
  };

  const { rowData, column } = params;
  const { selectedRowKeys, rowKey } = column;
  const checked = selectedRowKeys.includes(rowData[rowKey]);
  return <Checkbox checked={checked} onChange={handleChange} />;
};

type State = {
  selectedRowKeys: string[];
  expandedRowKeys: string[];
};

type Props = {
  selectedRowKeys?: string[];
  onSelectedRowsChange?: (rowKeys: string[]) => void;
  expandedRowKeys?: string[];
  defaultSelectedRowKeys?: string[];
  defaultExpandedRowKeys?: string[];
  columns: AnyType[];
  rowKey: string;
  rowClassName?: string;
  data: AnyType[];
  fixed: boolean;
  notSelectable?: boolean;
  useIsScrolling?: boolean;
  error?: AxiosError;
  loading?: boolean;
  pageData?: AnyType;
  stateKey?: string;
  checkColumnVisibility?: boolean;
  requestParameters?: any;
  connectorTable?: boolean;
  emptyComponent?: ReactNode;
};

export const SelectableBaseTable = memo(
  (props: PropsWithChildren<Props>) => {
    const [key, setKey] = useState<string>(uuidv4());

    const [pageState] = useContext(PageFilterContext);

    const { columns, checkColumnVisibility, stateKey, emptyComponent, children, ...rest } = props;

    const selectedColumn = (stateKey && (pageState[stateKey].selectedColumns as string[])) || [];

    const handleSelectChange = ({ selected, rowData }) => {
      const selectedRowKeys = [...(props.selectedRowKeys || [])];
      const key = rowData[props.rowKey];
      if (selected) {
        if (!selectedRowKeys.includes(key)) selectedRowKeys.push(key);
      } else {
        const index = selectedRowKeys.indexOf(key);
        if (index > -1) {
          selectedRowKeys.splice(index, 1);
        }
      }

      props.onSelectedRowsChange && props.onSelectedRowsChange(selectedRowKeys);
    };

    useEffect(() => {
      if (props.requestParameters) {
        setKey(uuidv4());
      }
    }, [props.requestParameters]);

    const checkVisibility = (column: any[]) => {
      if (selectedColumn.length === 0) {
        return column;
      } else {
        return column.filter((x) => x.excludeSelector || selectedColumn.some((c) => x.key === c));
      }
    };

    let normalizedColumns = checkVisibility(columns.filter((x) => x !== null) || normalizeColumns(children));

    const selectAll = (e, data) => {
      if (e.target.checked) {
        props.onSelectedRowsChange && props.onSelectedRowsChange(data.map((x) => x.id));
      } else {
        props.onSelectedRowsChange && props.onSelectedRowsChange([]);
      }
    };

    const selectionColumn = {
      minWidth: 40,
      width: 40,
      align: 'center',
      cellRenderer: SelectionCell,
      headerRenderer: (event) => {
        return (
          <Checkbox
            // eslint-disable-next-line react/prop-types
            checked={props.data.length === (props.selectedRowKeys || []).length && props.data.length !== 0}
            // eslint-disable-next-line react/prop-types
            indeterminate={(props.selectedRowKeys || []).length !== 0 ? (props.selectedRowKeys || []).length !== props.data.length : false}
            onChange={(e) => selectAll(e, event.container.props.data)}
          />
        );
      },
      key: '__selection__',
      rowKey: props.rowKey,
      selectedRowKeys: props.selectedRowKeys,
      onChange: handleSelectChange
    };

    if (props.notSelectable === true) {
      normalizedColumns = [...normalizedColumns];
    } else {
      normalizedColumns = [selectionColumn, ...normalizedColumns];
    }

    const rowClassName = ({ rowData, rowIndex }) => {
      const { rowClassName, rowKey } = props;
      const rowClass = rowClassName ? callOrReturn(rowClassName, { rowData, rowIndex }) : '';
      const key = rowData[rowKey];
      return [rowClass, props.selectedRowKeys && props.selectedRowKeys.includes(key) && 'row-selected'].filter(Boolean).concat(' ');
    };

    const rowRenderer = ({ isScrolling, cells }) => {
      if (isScrolling) return <div style={{ paddingLeft: 50 }}>Scrolling</div>;
      return cells;
    };

    return (
      <AutoResizer>
        {({ width, height }) => (
          <BaseTable
            {...rest}
            width={width}
            height={height}
            // useIsScrolling={props.data.length > 50}
            useIsScrolling={false}
            rowRenderer={rowRenderer}
            emptyRenderer={
              props.loading === true ? (
                <Loading />
              ) : props.error !== undefined ? (
                props.connectorTable === true ? (
                  <ConnectorErrorViewer error={props.error} />
                ) : (
                  <ErrorViewer error={props.error} />
                )
              ) : (
                <>
                  {emptyComponent !== undefined && <div className="table__body__center">{emptyComponent}</div>}
                  {emptyComponent === undefined && <Empty className="table__body__center" />}
                </>
              )
            }
            columns={normalizedColumns}
            key={key}
            rowClassName={rowClassName}
          />
        )}
      </AutoResizer>
    );
  },
  (prevProps, nextProps) => {
    return (
      _.isEqual(prevProps.data, nextProps.data) &&
      _.isEqual(prevProps.requestParameters, nextProps.requestParameters) &&
      prevProps.selectedRowKeys === nextProps.selectedRowKeys &&
      prevProps.loading === nextProps.loading &&
      prevProps.error === nextProps.error &&
      prevProps.columns === nextProps.columns
    );
  }
);

export default SelectableBaseTable;
