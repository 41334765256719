// tslint:disable
// eslint:disable
/**
 * General API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DailyStatistics } from '../interfaces';
/**
 * StatisticsApi - axios parameter creator
 * @export
 */
export const StatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Detay : Günlük kullanım istatistiklerine bu uç ile ulaşabilirsiniz
         * @summary Belge listeler
         * @param {string} startDate Başlangıç tarihi
         * @param {string} endDate Bitiş tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StatisticsDailyGet: async (requestParameters: V1StatisticsDailyGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
                throw new RequiredError('startDate','Required parameter startDate was null or undefined when calling v1StatisticsDailyGet.');
            }
            // verify required parameter 'endDate' is not null or undefined
            if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
                throw new RequiredError('endDate','Required parameter endDate was null or undefined when calling v1StatisticsDailyGet.');
            }
            const localVarPath = `/v1/statistics/daily`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.startDate !== undefined) {
                localVarQueryParameter['startDate'] = (requestParameters.startDate as any instanceof Date) ?
                    (requestParameters.startDate as any).toISOString() : requestParameters.startDate;
            }
            if (requestParameters.endDate !== undefined) {
                localVarQueryParameter['endDate'] = (requestParameters.endDate as any instanceof Date) ?
                    (requestParameters.endDate as any).toISOString() : requestParameters.endDate;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detay : Genel kullanım istatistiklerine bu uç ile ulaşabilirsiniz
         * @summary Belge listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StatisticsGeneralGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/statistics/general`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatisticsApi - functional programming interface
 * @export
 */
export const StatisticsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Detay : Günlük kullanım istatistiklerine bu uç ile ulaşabilirsiniz
         * @summary Belge listeler
         * @param {string} startDate Başlangıç tarihi
         * @param {string} endDate Bitiş tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1StatisticsDailyGet(requestParameters: V1StatisticsDailyGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DailyStatistics>>> {
            const localVarAxiosArgs = await StatisticsApiAxiosParamCreator(configuration).v1StatisticsDailyGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Detay : Genel kullanım istatistiklerine bu uç ile ulaşabilirsiniz
         * @summary Belge listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1StatisticsGeneralGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await StatisticsApiAxiosParamCreator(configuration).v1StatisticsGeneralGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StatisticsApi - factory interface
 * @export
 */
export const StatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Detay : Günlük kullanım istatistiklerine bu uç ile ulaşabilirsiniz
         * @summary Belge listeler
         * @param {string} startDate Başlangıç tarihi
         * @param {string} endDate Bitiş tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StatisticsDailyGet(requestParameters: V1StatisticsDailyGetRequest, options?: any): AxiosPromise<Array<DailyStatistics>> {
            return StatisticsApiFp(configuration).v1StatisticsDailyGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Detay : Genel kullanım istatistiklerine bu uç ile ulaşabilirsiniz
         * @summary Belge listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StatisticsGeneralGet(options?: any): AxiosPromise<void> {
            return StatisticsApiFp(configuration).v1StatisticsGeneralGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatisticsApi - object-oriented interface
 * @export
 * @class StatisticsApi
 * @extends {BaseAPI}
 */
export class StatisticsApi extends BaseAPI {
    /**
     * Detay : Günlük kullanım istatistiklerine bu uç ile ulaşabilirsiniz
     * @summary Belge listeler
     * @param {string} startDate Başlangıç tarihi
     * @param {string} endDate Bitiş tarihi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public v1StatisticsDailyGet(requestParameters: V1StatisticsDailyGetRequest, options?: any) {
        return StatisticsApiFp(this.configuration).v1StatisticsDailyGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Detay : Genel kullanım istatistiklerine bu uç ile ulaşabilirsiniz
     * @summary Belge listeler
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public v1StatisticsGeneralGet(options?: any) {
        return StatisticsApiFp(this.configuration).v1StatisticsGeneralGet(options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1StatisticsDailyGetRequest {
    /**
     * Başlangıç tarihi
     */
    startDate: string;
    /**
     * Bitiş tarihi
     */
    endDate: string;
    }
