import { AnyType } from '@/type';
import React, { Dispatch, Reducer } from 'react';

const createStore = <TState extends AnyType, TAction extends AnyType>(reducer: Reducer<TState, TAction>, initialState: TState) => {
  const contextValues: [TState, Dispatch<TAction>] = [initialState, () => null];

  const Context = React.createContext<[TState, Dispatch<TAction>]>(contextValues);

  const Provider: React.FC = (props: AnyType) => {
    const store = React.useReducer(reducer, initialState);

    return <Context.Provider value={store}>{props.children}</Context.Provider>;
  };

  return { Context, Provider, Consumer: Context.Consumer };
};

export default createStore;
