/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { BarcodeInformation, DocumentTemplateCustomizationSettingDto } from '@/apis/einvoice/interfaces';
import { faBook, faCode } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tabs } from 'antd';
import { Select } from 'formik-antd';
import React from 'react';
import AddDocRefTab from './AddDocRefTab';
import NotesTab from './NotesTab';
const { TabPane } = Tabs;
export interface BarcodesModalProps {
  visible: boolean;
  barcodeInformation?: BarcodeInformation;
}

const { Option } = Select;

export interface BarcodesModalProps {
  visible: boolean;
  barcodeInformation?: BarcodeInformation;
}

export const BarcodesInformation = ({
  documentTemplateCustomizationSetting,
  onChange
}: {
  documentTemplateCustomizationSetting?: DocumentTemplateCustomizationSettingDto;
  onChange: (data: BarcodeInformation[]) => void;
}) => {
  return (
    <div className="card-container p-0" style={{ marginTop: 10 }}>
      <Tabs defaultActiveKey="note" size={'middle'} type="card">
        <TabPane
          tab={
            <>
              <FontAwesomeIcon icon={faCode} className="mr-2" />
              Not Alanından Oku
            </>
          }
          key="note"
        >
          <NotesTab documentTemplateCustomizationSetting={documentTemplateCustomizationSetting} onChange={(data) => onChange(data)} />
        </TabPane>

        <TabPane
          tab={
            <>
              <FontAwesomeIcon icon={faBook} className="mr-2" />
              Ek Belgelerden Oku
            </>
          }
          key="addDocRef"
        >
          <AddDocRefTab documentTemplateCustomizationSetting={documentTemplateCustomizationSetting} onChange={(data) => onChange(data)} />
        </TabPane>
      </Tabs>
    </div>
  );
};
