import { ArchiveDocumentStatus } from '@/apis/earchive/interfaces';
import { BagdeStatus } from '@/constants/enums';
import { Badge, Tooltip } from 'antd';
import React, { memo } from 'react';
import intl from 'react-intl-universal';

export const ArchiveStatusInfo = memo(({ archiveDocumentStatus }: { archiveDocumentStatus: ArchiveDocumentStatus }) => {
  const text = (archiveStatus: ArchiveDocumentStatus) => {
    switch (archiveStatus) {
      case 'Error':
        return 'HATA_ALDI';
      case 'Signed':
        return 'IMZALANDI';
      case 'WaitingSign':
        return 'IMZALANMAYI_BEKLIYOR';
      default:
        return 'DURUM_BILINMIYOR';
    }
  };

  const color = (archiveStatus: ArchiveDocumentStatus): BagdeStatus => {
    switch (archiveStatus) {
      case 'Error':
        return BagdeStatus.error;
      case 'Signed':
        return BagdeStatus.success;
      case 'WaitingSign':
        return BagdeStatus.processing;
      default:
        return BagdeStatus.default;
    }
  };
  const variant = color(archiveDocumentStatus);
  const title = text(archiveDocumentStatus);
  return (
    <>
      {variant !== 'error' && (
        <div>
          <Badge status={variant} text={intl.get(title)} />
        </div>
      )}

      {variant === 'error' && (
        <div style={{ cursor: 'pointer' }}>
          <Tooltip trigger="hover" overlayClassName="antd-tooltip-center" title={intl.get('HATA_DETAYI_ICIN_TIKLAYINIZ')} placement="bottom">
            <Badge status={variant} text={intl.get(title)} />
          </Tooltip>
        </div>
      )}
    </>
  );
});

export default ArchiveStatusInfo;
