/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLText } from '@/xmldom-decorators';

/*
  27.04.2021 : Fully completed
*/
export class TextType {
  @XMLText()
  value?: string;
}

export default TextType;
