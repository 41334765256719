import React from 'react';
import intl from 'react-intl-universal';

export const DocumentInfo = ({ documentNumber, uuid }: { documentNumber: string; uuid: string }) => {
  return (
    <div>
      {documentNumber !== null && documentNumber !== '' && (
        <>
          <strong>{intl.get('BELGE_NO')} : </strong> <span>{documentNumber}</span>
          <br />
        </>
      )}

      {uuid !== null && uuid !== '' && (
        <>
          <strong>{intl.get('UUID')} : </strong> <span>{uuid}</span>
        </>
      )}
    </div>
  );
};

export default DocumentInfo;
