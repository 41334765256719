import { AnyType } from '@/type';
import moment from 'moment';

export const upsertItemByField = <T>(array: Array<T>, obj: T, field: string) => {
  const index = array.findIndex((x) => x[field] === obj[field]);
  if (index >= 0) {
    return array.splice(index, 1, obj);
  } else {
    return [...array, obj];
  }
};

export const updateItemById = <T>(array: Array<T>, id, newObject: AnyType, idSelector: (item: T) => AnyType) => {
  const newArray = [...array];

  const index = newArray.findIndex((x) => idSelector(x) === id);
  if (index >= 0) {
    newArray.splice(index, 1, Object.assign(newArray[index], newObject));
  }
  return newArray;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDays = (startDate: string, endDate: string) => {
  const data: moment.Moment[] = [];
  for (let index = 0; index < moment.duration(moment(endDate).diff(startDate)).asDays(); index++) {
    const date = moment(startDate).clone().add(index, 'days');
    data.push(date);
  }

  return data;
};

export const flattenItems = (items, key) =>
  items.reduce((flattenedItems, item) => {
    flattenedItems.push(item);
    if (Array.isArray(item[key])) {
      return flattenedItems.concat(flattenItems(item[key], key));
    }
    return flattenedItems;
  }, []);
