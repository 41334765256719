import Localization from '@/components/Localization';
import ThemeLayout from '@/components/Template/Layout';
import Loader from '@/components/Template/Layout/Loader';
import { ThemeContextConsumer } from '@/contexts/ThemeContext';
import NotFoundPage from '@/pages/404';
import { LockOutlined } from '@ant-design/icons';
import { getUserManager } from '@axa-fr/react-oidc-context';
import { Alert, Result } from 'antd';
import axios from 'axios';
import NProgress from 'nprogress';
import React from 'react';
import Loadable from 'react-loadable';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import Switch from 'react-router-transition-switch';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { HasRole, HideMarketPlace } from './components/Auth';
import { WORKING_ENVIRONMENT } from './config';
import { ALLROLES, GetScopeOrRoleList } from './constants/auth';
import { MenuFlatten } from './constants/menu';
import DashBoard from './pages/DashBoard/DashBoard';
import { AnyType } from './type';

type Props = RouteComponentProps;

const loadable = (loader) =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />
  });
const routes = [
  {
    path: '/invoice/editor/general/:productType?/:uuid?',
    component: loadable(() => import('./pages/InvoiceEditor')),
    exact: true
  },
  {
    path: '/invoice/editor/export/:uuid?',
    component: loadable(() => import('./pages/InvoiceEditor')),
    exact: true
  },
  {
    path: '/invoice/editor/taxfree/:uuid?',
    component: loadable(() => import('./pages/InvoiceEditor')),
    exact: true
  },
  // {
  //   path: '/invoice/editor/taxfree',
  //   component: loadable(() => import('./pages/InvoiceEditor')),
  //   exact: true
  // },
  {
    path: '/invoice/draft',
    component: loadable(() => import('./pages/DraftInvoice')),
    exact: true
  },
  {
    path: '/einvoice/incoming/list',
    component: loadable(() => import('./pages/EInvoice/IncomingInvoices/List')),
    exact: true
  },
  {
    path: '/einvoice/incoming/excel/reports',
    component: loadable(() => import('./pages/EInvoice/IncomingInvoices/Excel/Reports')),
    exact: true
  },
  {
    path: '/einvoice/incoming/excel/templates',
    component: loadable(() => import('./pages/EInvoice/IncomingInvoices/Excel/Templates')),
    exact: true
  },
  {
    path: '/einvoice/outgoing/list',
    component: loadable(() => import('./pages/EInvoice/OutgoingInvoices/List')),
    exact: true
  },
  {
    path: '/einvoice/outgoing/excel/reports',
    component: loadable(() => import('./pages/EInvoice/OutgoingInvoices/Excel/Reports')),
    exact: true
  },
  {
    path: '/einvoice/outgoing/excel/templates',
    component: loadable(() => import('./pages/EInvoice/OutgoingInvoices/Excel/Templates')),
    exact: true
  },
  {
    path: '/einvoice/tags',
    component: loadable(() => import('./pages/EInvoice/Tags')),
    exact: true
  },
  {
    path: '/einvoice/definations/series',
    component: loadable(() => import('./pages/EInvoice/Definations/Series')),
    exact: true
  },
  {
    path: '/einvoice/definations/xslts',
    component: loadable(() => import('./pages/EInvoice/Definations/DocumentTemplates')),
    exact: true
  },
  {
    path: '/einvoice/definations/custom-templates',
    component: loadable(() => import('./pages/EInvoice/Definations/CustomizedTemplates')),
    exact: true
  },
  {
    path: '/einvoice/definations/custom-templates/new',
    component: loadable(() => import('./pages/EInvoice/Definations/CustomizedTemplates/UpsertPage')),
    exact: true
  },
  {
    path: '/einvoice/definations/custom-templates/:id?',
    component: loadable(() => import('./pages/EInvoice/Definations/CustomizedTemplates/UpsertPage')),
    exact: true
  },
  {
    path: '/einvoice/definations/filetitles',
    component: loadable(() => import('./pages/EInvoice/Definations/FileTitle')),
    exact: true
  },
  {
    path: '/einvoice/notifications/incomingnotification',
    component: loadable(() => import('./pages/EInvoice/Notifications/IncomingNotification')),
    exact: true
  },
  {
    path: '/einvoice/notifications/outgoingnotification',
    component: loadable(() => import('./pages/EInvoice/Notifications/OutgoingNotification')),
    exact: true
  },
  {
    path: '/einvoice/upload',
    component: loadable(() => import('./pages/EInvoice/Upload')),
    exact: true
  },
  {
    path: '/einvoice/ex/incoming',
    component: loadable(() => import('./pages/EInvoice/Exinvoices/IncomingInvoices')),
    exact: true
  },
  {
    path: '/einvoice/ex/outgoing',
    component: loadable(() => import('./pages/EInvoice/Exinvoices/OutgoingInvoices')),
    exact: true
  },
  {
    path: '/einvoice/ex/queue',
    component: loadable(() => import('./pages/EInvoice/Exinvoices/Queue')),
    exact: true
  },
  {
    path: '/einvoice/ex/upload',
    component: loadable(() => import('./pages/EInvoice/Exinvoices/Upload')),
    exact: true
  },

  {
    path: '/edespatch/editor/:uuid?',
    component: loadable(() => import('./pages/DepatchAdviceEditor')),
    exact: true
  },
  // {
  //   path: '/edespatch/editor/list',
  //   component: loadable(() => import('./pages/DepatchAdviceEditor')),
  //   exact: true
  // },
  {
    path: '/edespatch/draft',
    component: loadable(() => import('./pages/EDespatch/Draft')),
    exact: true
  },
  {
    path: '/edespatch/incoming/list',
    component: loadable(() => import('./pages/EDespatch/IncomingDespatches/List')),
    exact: true
  },
  {
    path: '/edespatch/incoming/excel/reports',
    component: loadable(() => import('./pages/EDespatch/IncomingDespatches/Excel/Reports')),
    exact: true
  },
  {
    path: '/edespatch/incoming/excel/templates',
    component: loadable(() => import('./pages/EDespatch/IncomingDespatches/Excel/Templates')),
    exact: true
  },
  {
    path: '/edespatch/outgoing/list',
    component: loadable(() => import('./pages/EDespatch/OutgoingDespatches/List')),
    exact: true
  },
  {
    path: '/edespatch/outgoing/excel/reports',
    component: loadable(() => import('./pages/EDespatch/OutgoingDespatches/Excel/Reports')),
    exact: true
  },
  {
    path: '/edespatch/outgoing/excel/templates',
    component: loadable(() => import('./pages/EDespatch/OutgoingDespatches/Excel/Templates')),
    exact: true
  },
  {
    path: '/edespatch/tags',
    component: loadable(() => import('./pages/EDespatch/Tags')),
    exact: true
  },
  {
    path: '/edespatch/definations/series',
    component: loadable(() => import('./pages/EDespatch/Definations/Series')),
    exact: true
  },
  {
    path: '/edespatch/definations/answerseries',
    component: loadable(() => import('./pages/EDespatch/Definations/AnswerSeries')),
    exact: true
  },
  {
    path: '/edespatch/definations/despatchadvicexslts',
    component: loadable(() => import('./pages/EDespatch/Definations/DocumentTemplates')),
    exact: true
  },
  {
    path: '/edespatch/definations/receiptadvicexslts',
    component: loadable(() => import('./pages/EDespatch/Definations/AnswerDocumentTemplates')),
    exact: true
  },

  {
    path: '/edespatch/definations/custom-templates',
    component: loadable(() => import('./pages/EDespatch/Definations/CustomizedTemplates')),
    exact: true
  },
  {
    path: '/edespatch/definations/custom-templates/new',
    component: loadable(() => import('./pages/EDespatch/Definations/CustomizedTemplates/UpsertPage')),
    exact: true
  },
  {
    path: '/edespatch/definations/custom-templates/:id?',
    component: loadable(() => import('./pages/EDespatch/Definations/CustomizedTemplates/UpsertPage')),
    exact: true
  },

  {
    path: '/edespatch/definations/filetitles',
    component: loadable(() => import('./pages/EDespatch/Definations/FileTitle')),
    exact: true
  },
  {
    path: '/edespatch/definations/mailing',
    component: loadable(() => import('./pages/EDespatch/Definations/MailAndSms')),
    exact: true
  },
  {
    path: '/edespatch/notifications/incomingnotification',
    component: loadable(() => import('./pages/EDespatch/Notifications/IncomingNotification')),
    exact: true
  },
  {
    path: '/edespatch/notifications/outgoingnotification',
    component: loadable(() => import('./pages/EDespatch/Notifications/OutgoingNotification')),
    exact: true
  },
  {
    path: '/edespatch/upload',
    component: loadable(() => import('./pages/EDespatch/Upload')),
    exact: true
  },

  {
    path: '/earchive/list/:canceled?',
    component: loadable(() => import('./pages/EArchive/Invoices/List')),
    exact: true
  },
  {
    path: '/earchive/incoming/list',
    component: loadable(() => import('./pages/EArchive/IncomingInvoices/List')),
    exact: true
  },
  {
    path: '/earchive/excel/reports',
    component: loadable(() => import('./pages/EArchive/Invoices/Excel/Reports')),
    exact: true
  },
  {
    path: '/earchive/excel/templates',
    component: loadable(() => import('./pages/EArchive/Invoices/Excel/Templates')),
    exact: true
  },
  {
    path: '/earchive/tags',
    component: loadable(() => import('./pages/EArchive/Tags')),
    exact: true
  },
  {
    path: '/earchive/definations/series',
    component: loadable(() => import('./pages/EArchive/Definations/Series')),
    exact: true
  },
  {
    path: '/earchive/definations/xslts',
    component: loadable(() => import('./pages/EArchive/Definations/DocumentTemplates')),
    exact: true
  },

  {
    path: '/earchive/definations/custom-templates',
    component: loadable(() => import('./pages/EArchive/Definations/CustomizedTemplates')),
    exact: true
  },
  {
    path: '/earchive/definations/custom-templates/new',
    component: loadable(() => import('./pages/EArchive/Definations/CustomizedTemplates/UpsertPage')),
    exact: true
  },
  {
    path: '/earchive/definations/custom-templates/:id?',
    component: loadable(() => import('./pages/EArchive/Definations/CustomizedTemplates/UpsertPage')),
    exact: true
  },

  {
    path: '/earchive/definations/filetitles',
    component: loadable(() => import('./pages/EArchive/Definations/FileTitle')),
    exact: true
  },
  {
    path: '/earchive/definations/mailing',
    component: loadable(() => import('./pages/EArchive/Definations/MailAndSms')),
    exact: true
  },
  {
    path: '/earchive/notifications',
    component: loadable(() => import('./pages/EArchive/Notifications/Invoicenotification')),
    exact: true
  },
  {
    path: '/earchive/upload',
    component: loadable(() => import('./pages/EArchive/Upload')),
    exact: true
  },
  {
    path: '/earchive/exinvoices',
    component: loadable(() => import('./pages/EArchive/ExInvoices/List')),
    exact: true
  },
  {
    path: '/earchive/ex/queue',
    component: loadable(() => import('./pages/EArchive/ExInvoices/Queue')),
    exact: true
  },
  {
    path: '/earchive/ex/upload',
    component: loadable(() => import('./pages/EArchive/ExInvoices/Upload')),
    exact: true
  },
  {
    path: '/esmm/editor/:uuid?',
    component: loadable(() => import('./pages/FreelancerVoucherEditor')),
    exact: true
  },
  // {
  //   path: '/esmm/draft',
  //   component: loadable(() => import('./pages/FreelangerVoucherEditor')),
  //   exact: true
  // },
  {
    path: '/esmm/draft',
    component: loadable(() => import('./pages/ESmm/Draft')),
    exact: true
  },
  {
    path: '/esmm/tags',
    component: loadable(() => import('./pages/ESmm/Tags')),
    exact: true
  },
  {
    path: '/esmm/list',
    component: loadable(() => import('./pages/ESmm/Vouchers/List')),
    exact: true
  },
  {
    path: '/esmm/list/:canceled?',
    component: loadable(() => import('./pages/ESmm/Vouchers/List')),
    exact: true
  },
  {
    path: '/esmm/definations/series',
    component: loadable(() => import('./pages/ESmm/Definations/Series')),
    exact: true
  },
  {
    path: '/esmm/definations/xslts',
    component: loadable(() => import('./pages/ESmm/Definations/DocumentTemplates')),
    exact: true
  },

  {
    path: '/esmm/definations/custom-templates',
    component: loadable(() => import('./pages/ESmm/Definations/CustomizedTemplates')),
    exact: true
  },
  {
    path: '/esmm/definations/custom-templates/new',
    component: loadable(() => import('./pages/ESmm/Definations/CustomizedTemplates/UpsertPage')),
    exact: true
  },
  {
    path: '/esmm/definations/custom-templates/:id?',
    component: loadable(() => import('./pages/ESmm/Definations/CustomizedTemplates/UpsertPage')),
    exact: true
  },

  {
    path: '/esmm/definations/filetitles',
    component: loadable(() => import('./pages/ESmm/Definations/FileTitle')),
    exact: true
  },
  {
    path: '/esmm/definations/mailing',
    component: loadable(() => import('./pages/ESmm/Definations/MailAndSms')),
    exact: true
  },
  {
    path: '/esmm/definations/mailing',
    component: loadable(() => import('./pages/ESmm/Definations/MailAndSms')),
    exact: true
  },
  {
    path: '/esmm/notifications',
    component: loadable(() => import('./pages/ESmm/Notifications/VoucherNotification')),
    exact: true
  },
  {
    path: '/esmm/excel/reports',
    component: loadable(() => import('./pages/ESmm/Vouchers/Excel/Reports')),
    exact: true
  },
  {
    path: '/esmm/excel/templates',
    component: loadable(() => import('./pages/ESmm/Vouchers/Excel/Templates')),
    exact: true
  },
  {
    path: '/esmm/upload',
    component: loadable(() => import('./pages/ESmm/Upload')),
    exact: true
  },

  {
    path: '/emm/editor/:uuid?',
    component: loadable(() => import('./pages/CreditNoteEditor')),
    exact: true
  },
  // {
  //   path: '/emm/draft',
  //   component: loadable(() => import('./pages/CreditNoteEditor')),
  //   exact: true
  // },
  {
    path: '/emm/draft',
    component: loadable(() => import('./pages/EMm/Draft')),
    exact: true
  },
  {
    path: '/emm/tags',
    component: loadable(() => import('./pages/EMm/Tags')),
    exact: true
  },
  {
    path: '/emm/list',
    component: loadable(() => import('./pages/EMm/Vouchers/List')),
    exact: true
  },
  {
    path: '/emm/list/:canceled?',
    component: loadable(() => import('./pages/EMm/Vouchers/List')),
    exact: true
  },
  {
    path: '/emm/definations/series',
    component: loadable(() => import('./pages/EMm/Definations/Series')),
    exact: true
  },
  {
    path: '/emm/definations/xslts',
    component: loadable(() => import('./pages/EMm/Definations/DocumentTemplates')),
    exact: true
  },

  {
    path: '/emm/definations/custom-templates',
    component: loadable(() => import('./pages/EMm/Definations/CustomizedTemplates')),
    exact: true
  },
  {
    path: '/emm/definations/custom-templates/new',
    component: loadable(() => import('./pages/EMm/Definations/CustomizedTemplates/UpsertPage')),
    exact: true
  },
  {
    path: '/emm/definations/custom-templates/:id?',
    component: loadable(() => import('./pages/EMm/Definations/CustomizedTemplates/UpsertPage')),
    exact: true
  },

  {
    path: '/emm/definations/filetitles',
    component: loadable(() => import('./pages/EMm/Definations/FileTitle')),
    exact: true
  },
  {
    path: '/emm/definations/mailing',
    component: loadable(() => import('./pages/EMm/Definations/MailAndSms')),
    exact: true
  },
  {
    path: '/emm/notifications',
    component: loadable(() => import('./pages/EMm/Notifications/VoucherNotification')),
    exact: true
  },
  {
    path: '/earchivedocuments/report/list',
    component: loadable(() => import('./pages/EArchiveDocuments/Report/List')),
    exact: true
  },
  {
    path: '/earchivedocuments/report/create',
    component: loadable(() => import('./pages/EArchiveDocuments/Report/Create')),
    exact: true
  },
  {
    path: '/emm/excel/reports',
    component: loadable(() => import('./pages/EMm/Vouchers/Excel/Reports')),
    exact: true
  },
  {
    path: '/emm/excel/templates',
    component: loadable(() => import('./pages/EMm/Vouchers/Excel/Templates')),
    exact: true
  },
  {
    path: '/emm/upload',
    component: loadable(() => import('./pages/EMm/Upload')),
    exact: true
  },

  {
    path: '/paymentrequired',
    component: loadable(() => import('./pages/PaymentRequired')),
    exact: true
  },

  { path: '/companies', component: loadable(() => import('./pages/Companies')), exact: true },
  { path: '/productservices', component: loadable(() => import('./pages/ProductServices')), exact: true },
  { path: '/management/company', component: loadable(() => import('./pages/Management/Companies')), exact: true },
  { path: '/management/apis', component: loadable(() => import('./pages/Management/Api')), exact: true },
  { path: '/management/apis/new', component: loadable(() => import('./pages/Management/Api/New')), exact: true },
  { path: '/connector/unsentinvoices', component: loadable(() => import('./pages/Connector/UnsentInvoices')), exact: true },
  { path: '/connector/unsentdespatches', component: loadable(() => import('./pages/Connector/UnsentDespatches')), exact: true },
  { path: '/management/users', component: loadable(() => import('./pages/Management/User')), exact: true },
  { path: '/management/accountants', component: loadable(() => import('./pages/Management/Accountant')), exact: true },
  { path: '/management/connector', component: loadable(() => import('./pages/Management/Connector')), exact: true },
  { path: '/management/connector/queryeditor', component: loadable(() => import('./pages/Management/Connector/QueryEditor')), exact: true },
  { path: '/management/documentarchives', component: loadable(() => import('./pages/Management/DocumentArchive')), exact: true }
];

if (WORKING_ENVIRONMENT === 'Production' && !HideMarketPlace()) {
  routes.push(
    { path: '/management/marketplaces', component: loadable(() => import('./pages/Management/MarketPlaces')), exact: true },
    { path: '/marketplace/orders', component: loadable(() => import('./pages/Marketplace/orders')), exact: true }
  );
}

axios.interceptors.request.use(
  async (config) => {
    NProgress.configure({ showSpinner: false, minimum: 0.35 }).start();
    await getUserManager()
      .getUser()
      .then((user) => {
        config.headers['Authorization'] = 'Bearer ' + user?.access_token;
        return config;
      });
    return config;
  },
  (error) => {
    NProgress.done();
    return error;
  }
);

axios.interceptors.response.use(
  (response) => {
    NProgress.done();
    return response;
  },
  (error) => {
    NProgress.done();

    if (error?.response?.status == 402) {
      window.location.href = `/paymentrequired?type=${error.response.data.paymentRequiredType}&key=${error.response.data.key}`;
    }

    return error;
  }
);

const menuData = MenuFlatten();

const Routes = () => {
  return (
    <ThemeContextConsumer>
      {([{ locale, routerAnimation }]) => (
        <Localization locale={locale}>
          <ThemeLayout>
            <Switch
              render={(props: AnyType) => {
                const {
                  children,
                  location: { pathname }
                } = props;
                const menu = menuData.find((x) => x.url === pathname);

                let hasRole = HasRole('ADMINISTRATOR');
                let isRequiredAdministrator = false;

                //adminse hiç kontrol etmiyoruz
                if (!hasRole) {
                  if (menu) {
                    hasRole = menu.role ? HasRole(menu.role) : true;
                  } else {
                    if ((pathname as string).startsWith('/management') && !HasRole('ADMINISTRATOR')) {
                      hasRole = false;
                      isRequiredAdministrator = true;
                    } else {
                      hasRole = true;
                    }
                  }
                }

                // const hasRole = true;

                return (
                  <SwitchTransition>
                    <CSSTransition key={pathname} classNames={routerAnimation} timeout={routerAnimation === 'none' ? 0 : 300}>
                      {hasRole ? (
                        children
                      ) : (
                        <Result
                          className="table__body__center"
                          style={{ padding: 0 }}
                          status="warning"
                          icon={<LockOutlined />}
                          title={
                            <div>
                              <span>Yetkisiz işlem. </span>
                              <Alert
                                style={{ marginTop: 20 }}
                                message={isRequiredAdministrator ? 'Yönetici' : GetScopeOrRoleList(ALLROLES).find((x) => x.value === menu.role)?.content}
                                type="warning"
                              />
                            </div>
                          }
                          extra={<div>İşlemi gerçekleştirebilmeniz için yukarıda belirtilen yetkinin tanımlanması gerekmektedir.</div>}
                        />
                      )}
                    </CSSTransition>
                  </SwitchTransition>
                );
              }}
            >
              <Route exact path="/dashboard" component={DashBoard} />
              <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
              {routes.map((props: any) => (
                <Route key={props.path} {...props} />
              ))}
              <Route component={NotFoundPage} />
            </Switch>
          </ThemeLayout>
        </Localization>
      )}
    </ThemeContextConsumer>
  );
};

export default Routes;
