// tslint:disable
// eslint:disable
/**
 * E-SMM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DynamicNotificationRuleDto } from '../interfaces';
// @ts-ignore
import { DynamicNotificationRuleListModelPaginationResult } from '../interfaces';
// @ts-ignore
import { DynamicNotificationRuleModel } from '../interfaces';
/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Kayıtlı bildirim kurallarınıza bu uç ile ulaşabilirsiniz.
         * @summary Kuralları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NotificationsDynamicrulesGet: async (requestParameters: V1NotificationsDynamicrulesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1NotificationsDynamicrulesGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1NotificationsDynamicrulesGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1NotificationsDynamicrulesGet.');
            }
            const localVarPath = `/v1/notifications/dynamicrules`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Kayıtlı bildirim kuralını silmek bu uç kullanılabilir.
         * @summary Kural siler
         * @param {string} id **Silinecek kural id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NotificationsDynamicrulesIdDelete: async (requestParameters: V1NotificationsDynamicrulesIdDeleteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1NotificationsDynamicrulesIdDelete.');
            }
            const localVarPath = `/v1/notifications/dynamicrules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Kayıtlı bildirim kuralınızın detaylarına bu uç ile ulaşabilirsiniz.
         * @summary Sorgulanan kuralı getirir
         * @param {string} id **Kural id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NotificationsDynamicrulesIdGet: async (requestParameters: V1NotificationsDynamicrulesIdGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1NotificationsDynamicrulesIdGet.');
            }
            const localVarPath = `/v1/notifications/dynamicrules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Daha önce tanımlanmış kuralı güncellemek için bu uç kullanılabilir.
         * @summary Kural günceller
         * @param {string} id #### Kural id
         * @param {DynamicNotificationRuleDto} dynamicNotificationRuleDto #### Kural güncellemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NotificationsDynamicrulesIdPut: async (requestParameters: V1NotificationsDynamicrulesIdPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1NotificationsDynamicrulesIdPut.');
            }
            // verify required parameter 'dynamicNotificationRuleDto' is not null or undefined
            if (requestParameters.dynamicNotificationRuleDto === null || requestParameters.dynamicNotificationRuleDto === undefined) {
                throw new RequiredError('dynamicNotificationRuleDto','Required parameter dynamicNotificationRuleDto was null or undefined when calling v1NotificationsDynamicrulesIdPut.');
            }
            const localVarPath = `/v1/notifications/dynamicrules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.dynamicNotificationRuleDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.dynamicNotificationRuleDto !== undefined ? requestParameters.dynamicNotificationRuleDto : {}) : (requestParameters.dynamicNotificationRuleDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Yeni bildirim kuralı oluşturmak için bu uç kullanılabilir.
         * @summary Kural oluşturur
         * @param {DynamicNotificationRuleDto} dynamicNotificationRuleDto #### Kural oluşturmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NotificationsDynamicrulesPost: async (requestParameters: V1NotificationsDynamicrulesPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dynamicNotificationRuleDto' is not null or undefined
            if (requestParameters.dynamicNotificationRuleDto === null || requestParameters.dynamicNotificationRuleDto === undefined) {
                throw new RequiredError('dynamicNotificationRuleDto','Required parameter dynamicNotificationRuleDto was null or undefined when calling v1NotificationsDynamicrulesPost.');
            }
            const localVarPath = `/v1/notifications/dynamicrules`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.dynamicNotificationRuleDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.dynamicNotificationRuleDto !== undefined ? requestParameters.dynamicNotificationRuleDto : {}) : (requestParameters.dynamicNotificationRuleDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Kayıtlı bildirim kurallarınıza bu uç ile ulaşabilirsiniz.
         * @summary Kuralları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NotificationsDynamicrulesGet(requestParameters: V1NotificationsDynamicrulesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DynamicNotificationRuleListModelPaginationResult>> {
            const localVarAxiosArgs = await NotificationsApiAxiosParamCreator(configuration).v1NotificationsDynamicrulesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Kayıtlı bildirim kuralını silmek bu uç kullanılabilir.
         * @summary Kural siler
         * @param {string} id **Silinecek kural id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NotificationsDynamicrulesIdDelete(requestParameters: V1NotificationsDynamicrulesIdDeleteRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await NotificationsApiAxiosParamCreator(configuration).v1NotificationsDynamicrulesIdDelete(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Kayıtlı bildirim kuralınızın detaylarına bu uç ile ulaşabilirsiniz.
         * @summary Sorgulanan kuralı getirir
         * @param {string} id **Kural id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NotificationsDynamicrulesIdGet(requestParameters: V1NotificationsDynamicrulesIdGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DynamicNotificationRuleModel>> {
            const localVarAxiosArgs = await NotificationsApiAxiosParamCreator(configuration).v1NotificationsDynamicrulesIdGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Daha önce tanımlanmış kuralı güncellemek için bu uç kullanılabilir.
         * @summary Kural günceller
         * @param {string} id #### Kural id
         * @param {DynamicNotificationRuleDto} dynamicNotificationRuleDto #### Kural güncellemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NotificationsDynamicrulesIdPut(requestParameters: V1NotificationsDynamicrulesIdPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await NotificationsApiAxiosParamCreator(configuration).v1NotificationsDynamicrulesIdPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Yeni bildirim kuralı oluşturmak için bu uç kullanılabilir.
         * @summary Kural oluşturur
         * @param {DynamicNotificationRuleDto} dynamicNotificationRuleDto #### Kural oluşturmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NotificationsDynamicrulesPost(requestParameters: V1NotificationsDynamicrulesPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DynamicNotificationRuleModel>> {
            const localVarAxiosArgs = await NotificationsApiAxiosParamCreator(configuration).v1NotificationsDynamicrulesPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Kayıtlı bildirim kurallarınıza bu uç ile ulaşabilirsiniz.
         * @summary Kuralları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NotificationsDynamicrulesGet(requestParameters: V1NotificationsDynamicrulesGetRequest, options?: any): AxiosPromise<DynamicNotificationRuleListModelPaginationResult> {
            return NotificationsApiFp(configuration).v1NotificationsDynamicrulesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Kayıtlı bildirim kuralını silmek bu uç kullanılabilir.
         * @summary Kural siler
         * @param {string} id **Silinecek kural id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NotificationsDynamicrulesIdDelete(requestParameters: V1NotificationsDynamicrulesIdDeleteRequest, options?: any): AxiosPromise<void> {
            return NotificationsApiFp(configuration).v1NotificationsDynamicrulesIdDelete(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Kayıtlı bildirim kuralınızın detaylarına bu uç ile ulaşabilirsiniz.
         * @summary Sorgulanan kuralı getirir
         * @param {string} id **Kural id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NotificationsDynamicrulesIdGet(requestParameters: V1NotificationsDynamicrulesIdGetRequest, options?: any): AxiosPromise<DynamicNotificationRuleModel> {
            return NotificationsApiFp(configuration).v1NotificationsDynamicrulesIdGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Daha önce tanımlanmış kuralı güncellemek için bu uç kullanılabilir.
         * @summary Kural günceller
         * @param {string} id #### Kural id
         * @param {DynamicNotificationRuleDto} dynamicNotificationRuleDto #### Kural güncellemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NotificationsDynamicrulesIdPut(requestParameters: V1NotificationsDynamicrulesIdPutRequest, options?: any): AxiosPromise<void> {
            return NotificationsApiFp(configuration).v1NotificationsDynamicrulesIdPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Yeni bildirim kuralı oluşturmak için bu uç kullanılabilir.
         * @summary Kural oluşturur
         * @param {DynamicNotificationRuleDto} dynamicNotificationRuleDto #### Kural oluşturmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NotificationsDynamicrulesPost(requestParameters: V1NotificationsDynamicrulesPostRequest, options?: any): AxiosPromise<DynamicNotificationRuleModel> {
            return NotificationsApiFp(configuration).v1NotificationsDynamicrulesPost(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * Kayıtlı bildirim kurallarınıza bu uç ile ulaşabilirsiniz.
     * @summary Kuralları listeler
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public v1NotificationsDynamicrulesGet(requestParameters: V1NotificationsDynamicrulesGetRequest, options?: any) {
        return NotificationsApiFp(this.configuration).v1NotificationsDynamicrulesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Kayıtlı bildirim kuralını silmek bu uç kullanılabilir.
     * @summary Kural siler
     * @param {string} id **Silinecek kural id**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public v1NotificationsDynamicrulesIdDelete(requestParameters: V1NotificationsDynamicrulesIdDeleteRequest, options?: any) {
        return NotificationsApiFp(this.configuration).v1NotificationsDynamicrulesIdDelete(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Kayıtlı bildirim kuralınızın detaylarına bu uç ile ulaşabilirsiniz.
     * @summary Sorgulanan kuralı getirir
     * @param {string} id **Kural id**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public v1NotificationsDynamicrulesIdGet(requestParameters: V1NotificationsDynamicrulesIdGetRequest, options?: any) {
        return NotificationsApiFp(this.configuration).v1NotificationsDynamicrulesIdGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Daha önce tanımlanmış kuralı güncellemek için bu uç kullanılabilir.
     * @summary Kural günceller
     * @param {string} id #### Kural id
     * @param {DynamicNotificationRuleDto} dynamicNotificationRuleDto #### Kural güncellemek için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public v1NotificationsDynamicrulesIdPut(requestParameters: V1NotificationsDynamicrulesIdPutRequest, options?: any) {
        return NotificationsApiFp(this.configuration).v1NotificationsDynamicrulesIdPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Yeni bildirim kuralı oluşturmak için bu uç kullanılabilir.
     * @summary Kural oluşturur
     * @param {DynamicNotificationRuleDto} dynamicNotificationRuleDto #### Kural oluşturmak için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public v1NotificationsDynamicrulesPost(requestParameters: V1NotificationsDynamicrulesPostRequest, options?: any) {
        return NotificationsApiFp(this.configuration).v1NotificationsDynamicrulesPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1NotificationsDynamicrulesGetRequest {
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    }
    export interface V1NotificationsDynamicrulesIdDeleteRequest {
    /**
     * **Silinecek kural id**
     */
    id: string;
    }
    export interface V1NotificationsDynamicrulesIdGetRequest {
    /**
     * **Kural id**
     */
    id: string;
    }
    export interface V1NotificationsDynamicrulesIdPutRequest {
    /**
     * #### Kural id
     */
    id: string;
    /**
     * #### Kural güncellemek için gönderilmesi gereken model
     */
    dynamicNotificationRuleDto: DynamicNotificationRuleDto;
    }
    export interface V1NotificationsDynamicrulesPostRequest {
    /**
     * #### Kural oluşturmak için gönderilmesi gereken model
     */
    dynamicNotificationRuleDto: DynamicNotificationRuleDto;
    }
