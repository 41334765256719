import { EnvelopesApi } from '@/apis/einvoice';
import { DocumentModalValue } from '@/interfaces';
import { AnyType } from '@/type';
import { useCallback, useRef, useState } from 'react';
import intl from 'react-intl-universal';
export * from './EnvelopeQueryModal';
export interface EnvelopeQueryResponse {
  type: string;
  instanceIdentifier: string;
  code?: string;
  message: string;
}

export const useEnvelopeQuery = () => {
  const api = new EnvelopesApi();
  /**
   * Henüz asyn operasyon devam ederken, sonuçlanmasını beklemeden modalı kapatması durumlarını handle etmek için kullanılıyor
   */
  const isClosed = useRef(false);

  const [value, setValue] = useState<DocumentModalValue<Array<EnvelopeQueryResponse>, null>>({ data: [] as AnyType, loading: false, visible: false });

  /**
   * Api isteğini başlatmak için bu metod kullanılır
   * @param uuid BELGE ETTN değeri
   * @param documentNumber 16 Haneli e-Belge Numarası
   * @param docuemntEnvelopeNumber Belgenin zarf numarası
   * @param answerEnvelopeNumber Belgeye istinaden oluşturulmuş olan yanıtı zarf numarası
   */
  const get = (uuid: string, documentNumber: string, docuemntEnvelopeNumber: string, answerEnvelopeNumber?: string) => {
    isClosed.current = false;
    setValue({ loading: true, visible: true, data: undefined, documentNumber, uuid });

    const documentQuery = api.v1EnvelopesInstanceIdentifierQueryGet({ instanceIdentifier: docuemntEnvelopeNumber }).then((response) => response);
    const answerQuery = answerEnvelopeNumber
      ? api.v1EnvelopesInstanceIdentifierQueryGet({ instanceIdentifier: answerEnvelopeNumber }).then((response) => response)
      : undefined;

    return Promise.all([documentQuery, answerQuery]).then((values) => {
      const data: EnvelopeQueryResponse[] = [
        {
          type: intl.get('BELGE_ZARFI'),
          instanceIdentifier: docuemntEnvelopeNumber,
          code: values[0].data.code || undefined,
          message: values[0].data.description || ''
        }
      ];

      if (answerQuery && values[1]) {
        data.push({
          type: intl.get('CEVAP_ZARFI'),
          instanceIdentifier: answerEnvelopeNumber || '',
          code: values[1].data.code || undefined,
          message: values[1].data.description || ''
        });
      }

      setValue({ loading: false, visible: true, data, documentNumber, uuid });
    });
  };

  const closeModal = useCallback(() => {
    isClosed.current = true;
    setValue({ loading: false, visible: false, data: [] });
  }, []);

  const props = { ...value, closeModal };

  return { props, get };
};

export default useEnvelopeQuery;
