/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLAttribute, XMLText } from '@/xmldom-decorators';

/*
  27.04.2021 : Fully completed
*/
export class EmbeddedDocumentBinaryObjectType {
  @XMLAttribute()
  format?: string;

  @XMLAttribute()
  mimeCode?: string;

  @XMLAttribute()
  encodingCode?: string;

  @XMLAttribute()
  characterSetCode?: string;

  @XMLAttribute()
  filename?: string;

  @XMLText()
  value?: string;
}

export default EmbeddedDocumentBinaryObjectType;
