import { StatisticsApiFactory, V1StatisticsDailyGetRequest } from '@/apis/general';
import { DailyStatistics } from '@/apis/general/interfaces';
import { ProductType } from '@/apis/identity/interfaces';
import { ButtonTabPane, ButtonTabs, DateRangeSelector, TabFeedback } from '@/components';
import { HasProduct } from '@/components/Auth';
import { Refresh } from '@/components/Buttons';
import { DateFormat, PageConst, PageStyle } from '@/constants/page';
import { useGetPageData } from '@/hooks';
import { faChartPie, faPlus, faShare, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import 'react-grid-layout/css/styles.css';
import { Helmet } from 'react-helmet';
import 'react-resizable/css/styles.css';
import { Button, Card, CardDeck, CardHeader } from 'reactstrap';
import Announcements from './Announcements';
import EArchive from './EArchive';
import EInvoice from './EInvoice';
import EMM from './EMM';
import ESMM from './ESMM';
import './style.scss';
import axios from 'axios';
import { DEALER_API_ENDPOINT, PAYMENT_PORTAL_ENVIRONMENT, WORKING_ENVIRONMENT } from '@/config';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Modal, Result } from 'antd';
import { CreditCardOutlined } from '@ant-design/icons';
import { faBan, faCreditCard } from '@fortawesome/pro-light-svg-icons';

const statisticsApiFactory = StatisticsApiFactory();

const DashBoard = () => {
  const einvoice = React.createRef() as any;
  const earchive = React.createRef() as any;
  const esmm = React.createRef() as any;
  const emm = React.createRef() as any;

  const [requestParameters, setRequestParameters] = useState<V1StatisticsDailyGetRequest>({
    startDate: moment(new Date().firstDate()).format(DateFormat.ymd),
    endDate: moment(new Date().lastDate()).format(DateFormat.ymd)
  });

  const { execute, loading, data } = useGetPageData<Array<DailyStatistics>>(statisticsApiFactory.v1StatisticsDailyGet, requestParameters, true);
  const { execute: refreshGeneral, loading: loadingGeneral, data: dateGeneral } = useGetPageData<any>(
    statisticsApiFactory.v1StatisticsGeneralGet,
    requestParameters,
    true
  );

  const { oidcUser } = useReactOidc();

  const flattenData = (data || []).reduce((flattenObject, currentDate) => {
    Object.keys(currentDate.statistics).map((key) => {
      if (flattenObject[key] === undefined) {
        flattenObject[key] = currentDate.statistics[key];
      } else {
        flattenObject[key] += currentDate.statistics[key];
      }
    });
    return flattenObject;
  }, {});

  useEffect(() => {
    if (WORKING_ENVIRONMENT === 'Production') {
      axios
        .get(
          `${DEALER_API_ENDPOINT}/onlinepayment?onlinePaymentType=CustomerDebit&partyIdentification=${oidcUser.profile.PartyIdentification}&userName=${oidcUser.profile.name}`
        )
        .then((e: any) => {
          if (e.isAxiosError !== true && e.data.balanceTotal > 50) {
            const info = Modal.info({
              width: PageConst.MediumModalWidth,
              title: 'Ödeme Hatırlatıcı',
              className: 'nes-dynamic-modal no-modal-footer',
              okText: 'Kapat',

              content: (
                <Result
                  style={{ padding: 0 }}
                  status="warning"
                  icon={<CreditCardOutlined />}
                  title={
                    <span>
                      Ödenmemiş <b>{e.data.balanceTotal.toLocaleString('tr-TR', { minimumFractionDigits: 2 })}</b> ₺ borcunuz bulunmaktadır.
                    </span>
                  }
                  className="document-editor-result"
                  extra={[
                    <Button size="sm" key="btn-preview" color={'dark'} className="btn-labeled mr-3" onClick={() => info.destroy()}>
                      <FontAwesomeIcon icon={faBan} className="btn-label" />
                      <span>Kapat</span>
                    </Button>,
                    <Button
                      size="sm"
                      key="btn-preview"
                      color={'success'}
                      className="btn-labeled mr-3"
                      onClick={() => {
                        window.open(
                          `${PAYMENT_PORTAL_ENVIRONMENT}?onlinePaymentType=CustomerDebit&partyIdentification=${oidcUser.profile.PartyIdentification}&userName=${oidcUser.profile.name}`
                        );
                        info.destroy();
                      }}
                    >
                      <FontAwesomeIcon icon={faCreditCard} className="btn-label" />
                      Ödeme Yap
                    </Button>
                  ]}
                />
              ),
              cancelButtonProps: { hidden: true },
              okButtonProps: { hidden: true }
            });
          }
        });
    }
  }, []);

  return (
    <div style={{ height: '100%' }} className="statistics">
      <Card style={{ margin: 10, marginBottom: '1rem' }}>
        <CardHeader className={PageStyle.card__header}>
          <div className="justify-content-center mr-auto utils_custom_title">
            <FontAwesomeIcon icon={faChartPie} color="#0ab1fc" style={{ marginRight: '10px' }} />
            Duyurular
          </div>
        </CardHeader>
      </Card>

      <div style={{ margin: '0px 10px' }}>
        <CardDeck style={{ minHeight: 300 }}>
          <Announcements isDevelopmentAnnouncement={false} title="Bizden Haberler" />
          <Announcements isDevelopmentAnnouncement={true} title="Sistemsel Güncellemeler" />
        </CardDeck>
      </div>

      <Card style={{ margin: 10, marginBottom: '1rem' }}>
        <CardHeader className={PageStyle.card__header}>
          <div className="justify-content-center mr-auto utils_custom_title">
            <FontAwesomeIcon icon={faChartPie} color="#0ab1fc" style={{ marginRight: '10px' }} />
            İstatistikler - (Seçilen tarih aralığında)
          </div>
          <DateRangeSelector
            onChange={(value) => setRequestParameters({ startDate: value.startDate.format(DateFormat.ymd), endDate: value.endDate.format(DateFormat.ymd) })}
            defaultValue={{ startDate: moment(requestParameters.startDate), endDate: moment(requestParameters.endDate) }}
          />
          <Refresh
            onClick={() => {
              einvoice && einvoice.current && einvoice.current.refreshData();
              earchive && earchive.current && earchive.current.refreshData();
              emm && emm.current && emm.current.refreshData();
              esmm && esmm.current && esmm.current.refreshData();

              execute();
              refreshGeneral();
            }}
            onlyIcon
          />
        </CardHeader>
      </Card>

      <ButtonTabs defaultActiveKey="einvoice" animated={true} className="button__tabs statistics">
        {HasProduct(ProductType.EInvoice) && (
          <ButtonTabPane forceRender={true} tab={<TabFeedback icon={faUser} title={'E-Fatura İstatistikleri'} isValid={undefined} />} key="einvoice">
            <EInvoice
              ref={einvoice}
              startDate={requestParameters.startDate}
              endDate={requestParameters.endDate}
              flattenData={flattenData}
              loading={loading || loadingGeneral}
              data={data}
              generalStats={dateGeneral}
            />
          </ButtonTabPane>
        )}

        {HasProduct(ProductType.EArchive) && (
          <ButtonTabPane forceRender={true} tab={<TabFeedback icon={faUser} title={'E-Arşiv İstatistikleri'} isValid={undefined} />} key="earchive">
            <EArchive
              ref={earchive}
              startDate={requestParameters.startDate}
              endDate={requestParameters.endDate}
              flattenData={flattenData}
              loading={loading || loadingGeneral}
              data={data}
              generalStats={dateGeneral}
            />
          </ButtonTabPane>
        )}

        {HasProduct(ProductType.ESmm) && (
          <ButtonTabPane forceRender={true} tab={<TabFeedback icon={faUser} title={'E-SMM İstatistikleri'} isValid={undefined} />} key="esmm">
            <ESMM
              ref={esmm}
              startDate={requestParameters.startDate}
              endDate={requestParameters.endDate}
              flattenData={flattenData}
              loading={loading || loadingGeneral}
              data={data}
              generalStats={dateGeneral}
            />
          </ButtonTabPane>
        )}

        {HasProduct(ProductType.EMm) && (
          <ButtonTabPane forceRender={true} tab={<TabFeedback icon={faUser} title={'E-MM İstatistikleri'} isValid={undefined} />} key="emm">
            <EMM
              ref={emm}
              startDate={requestParameters.startDate}
              endDate={requestParameters.endDate}
              flattenData={flattenData}
              loading={loading || loadingGeneral}
              data={data}
              generalStats={dateGeneral}
            />
          </ButtonTabPane>
        )}
      </ButtonTabs>

      <Helmet title="Ana Sayfa Hoşgeldiniz" />
    </div>
  );
};

export default DashBoard;
