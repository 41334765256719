/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { CountryType, IDType } from '.';
import { NS_CAC, NS_CBC } from '../constants';
import TextType from './TextType';

/*
  27.04.2021 : Fully completed
*/
export class AddressType {
  @XMLElement({ types: [{ name: 'ID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  id?: IDType;

  @XMLElement({ types: [{ name: 'Postbox', namespaceUri: NS_CBC, itemType: () => String }] })
  postbox?: string;

  @XMLElement({ types: [{ name: 'Room', namespaceUri: NS_CBC, itemType: () => String }] })
  room?: string;

  @XMLElement({ types: [{ name: 'StreetName', namespaceUri: NS_CBC, itemType: () => String }] })
  streetName?: string;

  @XMLElement({ types: [{ name: 'BlockName', namespaceUri: NS_CBC, itemType: () => String }] })
  blockName?: string;

  @XMLElement({ types: [{ name: 'BuildingName', namespaceUri: NS_CBC, itemType: () => String }] })
  buildingName?: string;

  @XMLElement({ types: [{ name: 'BuildingNumber', namespaceUri: NS_CBC, itemType: () => String }] })
  buildingNumber?: string;

  @XMLElement({ types: [{ name: 'CitySubdivisionName', namespaceUri: NS_CBC, itemType: () => TextType }] })
  citySubdivisionName?: TextType;

  @XMLElement({ types: [{ name: 'CityName', namespaceUri: NS_CBC, itemType: () => TextType }] })
  cityName?: TextType;

  @XMLElement({ types: [{ name: 'PostalZone', namespaceUri: NS_CBC, itemType: () => String }] })
  postalZone?: string;

  @XMLElement({ types: [{ name: 'Region', namespaceUri: NS_CBC, itemType: () => String }] })
  region?: string;

  @XMLElement({ types: [{ name: 'District', namespaceUri: NS_CBC, itemType: () => String }] })
  district?: string;

  @XMLElement({ types: [{ name: 'Country', namespaceUri: NS_CAC, itemType: () => CountryType }] })
  country?: CountryType;
}

export default AddressType;
