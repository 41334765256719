// tslint:disable
// eslint:disable
/**
 * E-Invoice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Attachment } from '../interfaces';
// @ts-ignore
import { BooleanState } from '../interfaces';
// @ts-ignore
import { Company } from '../interfaces';
// @ts-ignore
import { DespatchDocumentReferencePaginationResult } from '../interfaces';
// @ts-ignore
import { DocumentAnswer } from '../interfaces';
// @ts-ignore
import { DocumentAnswerDto } from '../interfaces';
// @ts-ignore
import { EmailSendDto } from '../interfaces';
// @ts-ignore
import { FileExportTypeParameter } from '../interfaces';
// @ts-ignore
import { HistoryPaginationResult } from '../interfaces';
// @ts-ignore
import { IncomingInvoice } from '../interfaces';
// @ts-ignore
import { IncomingInvoiceDetail } from '../interfaces';
// @ts-ignore
import { IncomingInvoicePaginationResult } from '../interfaces';
// @ts-ignore
import { IncomingInvoiceSetParameter } from '../interfaces';
// @ts-ignore
import { InvoiceProfileIdParameter } from '../interfaces';
// @ts-ignore
import { InvoiceTypeParameter } from '../interfaces';
// @ts-ignore
import { LucaTransferStatusParameter } from '../interfaces';
// @ts-ignore
import { Note } from '../interfaces';
// @ts-ignore
import { NoteDto } from '../interfaces';
// @ts-ignore
import { NotePaginationResult } from '../interfaces';
// @ts-ignore
import { OutgoingAnswer } from '../interfaces';
// @ts-ignore
import { TaggingDto } from '../interfaces';
// @ts-ignore
import { TaxInfoPaginationResult } from '../interfaces';
/**
 * IncomingInvoicesApi - axios parameter creator
 * @export
 */
export const IncomingInvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Belgelere toplu olarak yeni durum atamak için bu uç kullanılabilir.
         * @summary Yeni durum atar
         * @param {IncomingInvoiceSetParameter} operation **İşlem**
         * @param {Array<string>} requestBody **Belge uuid listesi**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesBulkOperationPut: async (requestParameters: V1IncomingInvoicesBulkOperationPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'operation' is not null or undefined
            if (requestParameters.operation === null || requestParameters.operation === undefined) {
                throw new RequiredError('operation','Required parameter operation was null or undefined when calling v1IncomingInvoicesBulkOperationPut.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling v1IncomingInvoicesBulkOperationPut.');
            }
            const localVarPath = `/v1/incoming/invoices/bulk/{operation}`.replace(`{${"operation"}}`, encodeURIComponent(String(requestParameters.operation)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.requestBody !== undefined ? requestParameters.requestBody : {}) : (requestParameters.requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgelerinizi mail olarak iletmek için bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **50** farklı belge, **10** farklı mail adresi gönderilebilir.
         * @summary Belgeyi mail olarak iletir
         * @param {EmailSendDto} emailSendDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesEmailSendPost: async (requestParameters: V1IncomingInvoicesEmailSendPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailSendDto' is not null or undefined
            if (requestParameters.emailSendDto === null || requestParameters.emailSendDto === undefined) {
                throw new RequiredError('emailSendDto','Required parameter emailSendDto was null or undefined when calling v1IncomingInvoicesEmailSendPost.');
            }
            const localVarPath = `/v1/incoming/invoices/email/send`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.emailSendDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.emailSendDto !== undefined ? requestParameters.emailSendDto : {}) : (requestParameters.emailSendDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgelerinizi toplu olarak dışarı aktarmak isterseniz bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **100** kayıt dışarı aktarılabilir.    **fileType** Parametre detayları    > **Xml** : Belgenizin XML halini dışarı aktarır    > **Envelope** : Belgenizin Zarf XML halini dışarı aktarır    > **Pdf** : Belgenize ait PDF görüntüsünü dışarı aktarır    > **UnifiedPdf** : ***uuids*** parametresinde gönderilen belgeleri tek PDF dosyası halinde dışarı aktarır    > **Excel** : ***uuids*** parametresinde gönderilen belgeleri Excel\'e aktarır
         * @summary Toplu aktar
         * @param {FileExportTypeParameter} fileType 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesExportFileTypePost: async (requestParameters: V1IncomingInvoicesExportFileTypePostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileType' is not null or undefined
            if (requestParameters.fileType === null || requestParameters.fileType === undefined) {
                throw new RequiredError('fileType','Required parameter fileType was null or undefined when calling v1IncomingInvoicesExportFileTypePost.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling v1IncomingInvoicesExportFileTypePost.');
            }
            const localVarPath = `/v1/incoming/invoices/export/{fileType}`.replace(`{${"fileType"}}`, encodeURIComponent(String(requestParameters.fileType)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.requestBody !== undefined ? requestParameters.requestBody : {}) : (requestParameters.requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gelen e-Faturalarınıza bu uç ile ulaşabilirsiniz.
         * @summary Faturaları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {Array<string>} [tags] Faturaya atanmış etiketlere göre filtreleme yapabilirsiniz
         * @param {string} [userNote] Kullanıcı notuna göre filtreleme yapabilirsiniz
         * @param {string} [documentNote] Belge içinde geçen nota göre filtreleme yapabilirsiniz
         * @param {string} [despatchNumber] İrsaliye numarasına göre filtreleme yapabilirsiniz
         * @param {string} [orderNumber] Sipariş numarasına göre filtreleme yapabilirsiniz
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {string} [receiverAlias] Gelen faturaları e-Dönüşüm etiketlerine (urn:mail:defaultpk@nes.com.tr vb.)göre filtrelemek için kullanabilirsiniz.
         * @param {string} [startCreateDate] Oluşturma tarihi başlangıcı
         * @param {string} [endCreateDate] Oluşturma tarihi sonu
         * @param {BooleanState} [erpTransferred] ERP\&#39;ye transfer durumuna göre filtreleme yapabilirsiniz
         * @param {BooleanState} [isRead] Okunma durumuna göre filtreleme yapabilirsiniz
         * @param {boolean} [archived] Arşivlenen belgeleri göster
         * @param {InvoiceProfileIdParameter} [profileId] Fatura profiline göre filtreleme yapabilirsiniz
         * @param {InvoiceTypeParameter} [invoiceTypeCode] Fatura tipine göre filtreleme yapabilirsiniz
         * @param {DocumentAnswer} [documentAnswer] Cevap durumuna göre filtreleme yapabilirsiniz
         * @param {LucaTransferStatusParameter} [lucaTransferStatus] 
         * @param {string} [startDate] Başlangıç tarihi
         * @param {string} [endDate] Bitiş tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesGet: async (requestParameters: V1IncomingInvoicesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1IncomingInvoicesGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1IncomingInvoicesGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1IncomingInvoicesGet.');
            }
            const localVarPath = `/v1/incoming/invoices`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.tags) {
                localVarQueryParameter['tags'] = requestParameters.tags;
            }
            if (requestParameters.userNote !== undefined) {
                localVarQueryParameter['userNote'] = requestParameters.userNote;
            }
            if (requestParameters.documentNote !== undefined) {
                localVarQueryParameter['documentNote'] = requestParameters.documentNote;
            }
            if (requestParameters.despatchNumber !== undefined) {
                localVarQueryParameter['despatchNumber'] = requestParameters.despatchNumber;
            }
            if (requestParameters.orderNumber !== undefined) {
                localVarQueryParameter['orderNumber'] = requestParameters.orderNumber;
            }
            if (requestParameters.company !== undefined) {
                localVarQueryParameter['company'] = requestParameters.company;
            }
            if (requestParameters.uuid !== undefined) {
                localVarQueryParameter['uuid'] = requestParameters.uuid;
            }
            if (requestParameters.documentNumber !== undefined) {
                localVarQueryParameter['documentNumber'] = requestParameters.documentNumber;
            }
            if (requestParameters.receiverAlias !== undefined) {
                localVarQueryParameter['receiverAlias'] = requestParameters.receiverAlias;
            }
            if (requestParameters.startCreateDate !== undefined) {
                localVarQueryParameter['startCreateDate'] = (requestParameters.startCreateDate as any instanceof Date) ?
                    (requestParameters.startCreateDate as any).toISOString() : requestParameters.startCreateDate;
            }
            if (requestParameters.endCreateDate !== undefined) {
                localVarQueryParameter['endCreateDate'] = (requestParameters.endCreateDate as any instanceof Date) ?
                    (requestParameters.endCreateDate as any).toISOString() : requestParameters.endCreateDate;
            }
            if (requestParameters.erpTransferred !== undefined) {
                localVarQueryParameter['erpTransferred'] = requestParameters.erpTransferred;
            }
            if (requestParameters.isRead !== undefined) {
                localVarQueryParameter['isRead'] = requestParameters.isRead;
            }
            if (requestParameters.archived !== undefined) {
                localVarQueryParameter['archived'] = requestParameters.archived;
            }
            if (requestParameters.profileId !== undefined) {
                localVarQueryParameter['profileId'] = requestParameters.profileId;
            }
            if (requestParameters.invoiceTypeCode !== undefined) {
                localVarQueryParameter['invoiceTypeCode'] = requestParameters.invoiceTypeCode;
            }
            if (requestParameters.documentAnswer !== undefined) {
                localVarQueryParameter['documentAnswer'] = requestParameters.documentAnswer;
            }
            if (requestParameters.lucaTransferStatus !== undefined) {
                localVarQueryParameter['lucaTransferStatus'] = requestParameters.lucaTransferStatus;
            }
            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }
            if (requestParameters.startDate !== undefined) {
                localVarQueryParameter['startDate'] = requestParameters.startDate;
            }
            if (requestParameters.endDate !== undefined) {
                localVarQueryParameter['endDate'] = requestParameters.endDate;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gelen son 10 faturaya bu uç ile ulaşabilirsiniz.
         * @summary Gelen son 10 faturayı listeler
         * @param {BooleanState} [erpTransferred] ERP\&#39;ye transfer durumuna göre filtreleme yapabilirsiniz
         * @param {BooleanState} [isRead] Okunma durumuna göre filtreleme yapabilirsiniz
         * @param {boolean} [archived] Arşivlenen belgeleri göster
         * @param {InvoiceProfileIdParameter} [profileId] Fatura profiline göre filtreleme yapabilirsiniz
         * @param {InvoiceTypeParameter} [invoiceTypeCode] Fatura tipine göre filtreleme yapabilirsiniz
         * @param {DocumentAnswer} [documentAnswer] Cevap durumuna göre filtreleme yapabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesLastGet: async (requestParameters: V1IncomingInvoicesLastGetRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/incoming/invoices/last`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.erpTransferred !== undefined) {
                localVarQueryParameter['erpTransferred'] = requestParameters.erpTransferred;
            }
            if (requestParameters.isRead !== undefined) {
                localVarQueryParameter['isRead'] = requestParameters.isRead;
            }
            if (requestParameters.archived !== undefined) {
                localVarQueryParameter['archived'] = requestParameters.archived;
            }
            if (requestParameters.profileId !== undefined) {
                localVarQueryParameter['profileId'] = requestParameters.profileId;
            }
            if (requestParameters.invoiceTypeCode !== undefined) {
                localVarQueryParameter['invoiceTypeCode'] = requestParameters.invoiceTypeCode;
            }
            if (requestParameters.documentAnswer !== undefined) {
                localVarQueryParameter['documentAnswer'] = requestParameters.documentAnswer;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye etiket eklemek veya çıkarmak için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **100** belgeye tag eklenebilir/çıkarılabilir.
         * @summary Etiket ekler/çıkarır
         * @param {TaggingDto} taggingDto #### Etiket eklemek veya çıkarmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesTagsPut: async (requestParameters: V1IncomingInvoicesTagsPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'taggingDto' is not null or undefined
            if (requestParameters.taggingDto === null || requestParameters.taggingDto === undefined) {
                throw new RequiredError('taggingDto','Required parameter taggingDto was null or undefined when calling v1IncomingInvoicesTagsPut.');
            }
            const localVarPath = `/v1/incoming/invoices/tags`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.taggingDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.taggingDto !== undefined ? requestParameters.taggingDto : {}) : (requestParameters.taggingDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye ait ek dokumanların listesine bu uç ile ulaşabilirsiniz.
         * @summary Belge eklerini listeler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidAttachmentsGet: async (requestParameters: V1IncomingInvoicesUuidAttachmentsGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1IncomingInvoicesUuidAttachmentsGet.');
            }
            const localVarPath = `/v1/incoming/invoices/{uuid}/attachments`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye ait ek dokumanların indirilmesi için bu uç kullanılabilir.
         * @summary Belge ekini indirir
         * @param {string} uuid **Belge uuid**
         * @param {number} index **Ek belge sırası**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidAttachmentsIndexGet: async (requestParameters: V1IncomingInvoicesUuidAttachmentsIndexGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1IncomingInvoicesUuidAttachmentsIndexGet.');
            }
            // verify required parameter 'index' is not null or undefined
            if (requestParameters.index === null || requestParameters.index === undefined) {
                throw new RequiredError('index','Required parameter index was null or undefined when calling v1IncomingInvoicesUuidAttachmentsIndexGet.');
            }
            const localVarPath = `/v1/incoming/invoices/{uuid}/attachments/{index}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))).replace(`{${"index"}}`, encodeURIComponent(String(requestParameters.index)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belge içerisinde belirtilen irsaliye bilgilerine bu uç ile ulaşabilirsiniz.
         * @summary İrsaliye bilgilerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidDespatchesGet: async (requestParameters: V1IncomingInvoicesUuidDespatchesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1IncomingInvoicesUuidDespatchesGet.');
            }
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1IncomingInvoicesUuidDespatchesGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1IncomingInvoicesUuidDespatchesGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1IncomingInvoicesUuidDespatchesGet.');
            }
            const localVarPath = `/v1/incoming/invoices/{uuid}/despatches`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tarafınıza gönderilen ticari e-faturaya cevap vermek için bu uç kullanılabilir.
         * @summary Belge\'ye cevap verir
         * @param {string} uuid **Belge uuid**
         * @param {DocumentAnswerDto} [documentAnswerDto] **Cevap vermek için gönderilmesi gereken model**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidDocumentAnswerPost: async (requestParameters: V1IncomingInvoicesUuidDocumentAnswerPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1IncomingInvoicesUuidDocumentAnswerPost.');
            }
            const localVarPath = `/v1/incoming/invoices/{uuid}/documentAnswer`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.documentAnswerDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.documentAnswerDto !== undefined ? requestParameters.documentAnswerDto : {}) : (requestParameters.documentAnswerDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye ait Zarf XML dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary Zarf XML İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidEnvelopeGet: async (requestParameters: V1IncomingInvoicesUuidEnvelopeGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1IncomingInvoicesUuidEnvelopeGet.');
            }
            const localVarPath = `/v1/incoming/invoices/{uuid}/envelope`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Belge detaylarını getirir
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidGet: async (requestParameters: V1IncomingInvoicesUuidGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1IncomingInvoicesUuidGet.');
            }
            const localVarPath = `/v1/incoming/invoices/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sorgulanan belgeye ait işlem geçmişine bu uç ile ulaşabilirsiniz.
         * @summary İşlem geçmişini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidHistoriesGet: async (requestParameters: V1IncomingInvoicesUuidHistoriesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1IncomingInvoicesUuidHistoriesGet.');
            }
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1IncomingInvoicesUuidHistoriesGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1IncomingInvoicesUuidHistoriesGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1IncomingInvoicesUuidHistoriesGet.');
            }
            const localVarPath = `/v1/incoming/invoices/{uuid}/histories`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye ait HTML görüntüye bu uç ile ulaşabilirsiniz.
         * @summary Belgeyi görüntüler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidHtmlGet: async (requestParameters: V1IncomingInvoicesUuidHtmlGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1IncomingInvoicesUuidHtmlGet.');
            }
            const localVarPath = `/v1/incoming/invoices/{uuid}/html`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye ait PDF dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary PDF İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidPdfGet: async (requestParameters: V1IncomingInvoicesUuidPdfGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1IncomingInvoicesUuidPdfGet.');
            }
            const localVarPath = `/v1/incoming/invoices/{uuid}/pdf`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gelen belgeler için göndericiyi, giden belgeler içinse alıcıyı **Firma Listesi**\'ne kaydetmek için bu uç kullanılabilir.
         * @summary Firma olarak kaydet
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidSavecompanyindocumentPost: async (requestParameters: V1IncomingInvoicesUuidSavecompanyindocumentPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1IncomingInvoicesUuidSavecompanyindocumentPost.');
            }
            const localVarPath = `/v1/incoming/invoices/{uuid}/savecompanyindocument`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belge içerisinde belirtilen vergi bilgilerine bu uç ile ulaşabilirsiniz.
         * @summary Vergi bilgilerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidTaxesGet: async (requestParameters: V1IncomingInvoicesUuidTaxesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1IncomingInvoicesUuidTaxesGet.');
            }
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1IncomingInvoicesUuidTaxesGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1IncomingInvoicesUuidTaxesGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1IncomingInvoicesUuidTaxesGet.');
            }
            const localVarPath = `/v1/incoming/invoices/{uuid}/taxes`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sorgulanan belgeye ait kullanıcı tarafından eklenmiş notlara bu uç ile ulaşabilirsiniz.
         * @summary Kullanıcı notu listeler
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidUsernotesGet: async (requestParameters: V1IncomingInvoicesUuidUsernotesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1IncomingInvoicesUuidUsernotesGet.');
            }
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1IncomingInvoicesUuidUsernotesGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1IncomingInvoicesUuidUsernotesGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1IncomingInvoicesUuidUsernotesGet.');
            }
            const localVarPath = `/v1/incoming/invoices/{uuid}/usernotes`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Kullanıcı tarafından oluşturulmuş olan notu silmek için bu uç kullanılabilir.
         * @summary Kullanıcı notunu siler
         * @param {string} uuid **Belge uuid**
         * @param {string} id **Not id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidUsernotesIdDelete: async (requestParameters: V1IncomingInvoicesUuidUsernotesIdDeleteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1IncomingInvoicesUuidUsernotesIdDelete.');
            }
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1IncomingInvoicesUuidUsernotesIdDelete.');
            }
            const localVarPath = `/v1/incoming/invoices/{uuid}/usernotes/{id}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye daha önce eklenmiş olan kullanıcı notunu güncellemek için bu uç kullanılabilir.
         * @summary Kullanıcı notunu günceller
         * @param {string} uuid #### Belgeye ait uuid
         * @param {string} id #### Not id
         * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidUsernotesIdPut: async (requestParameters: V1IncomingInvoicesUuidUsernotesIdPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1IncomingInvoicesUuidUsernotesIdPut.');
            }
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1IncomingInvoicesUuidUsernotesIdPut.');
            }
            // verify required parameter 'noteDto' is not null or undefined
            if (requestParameters.noteDto === null || requestParameters.noteDto === undefined) {
                throw new RequiredError('noteDto','Required parameter noteDto was null or undefined when calling v1IncomingInvoicesUuidUsernotesIdPut.');
            }
            const localVarPath = `/v1/incoming/invoices/{uuid}/usernotes/{id}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.noteDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.noteDto !== undefined ? requestParameters.noteDto : {}) : (requestParameters.noteDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye kullanıcı notu eklemek için bu uç kullanılabilir.
         * @summary Kullanıcı notu ekler
         * @param {string} uuid #### Belge uuid
         * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidUsernotesPost: async (requestParameters: V1IncomingInvoicesUuidUsernotesPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1IncomingInvoicesUuidUsernotesPost.');
            }
            // verify required parameter 'noteDto' is not null or undefined
            if (requestParameters.noteDto === null || requestParameters.noteDto === undefined) {
                throw new RequiredError('noteDto','Required parameter noteDto was null or undefined when calling v1IncomingInvoicesUuidUsernotesPost.');
            }
            const localVarPath = `/v1/incoming/invoices/{uuid}/usernotes`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.noteDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.noteDto !== undefined ? requestParameters.noteDto : {}) : (requestParameters.noteDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye ait XML dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary XML İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidXmlGet: async (requestParameters: V1IncomingInvoicesUuidXmlGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1IncomingInvoicesUuidXmlGet.');
            }
            const localVarPath = `/v1/incoming/invoices/{uuid}/xml`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IncomingInvoicesApi - functional programming interface
 * @export
 */
export const IncomingInvoicesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Belgelere toplu olarak yeni durum atamak için bu uç kullanılabilir.
         * @summary Yeni durum atar
         * @param {IncomingInvoiceSetParameter} operation **İşlem**
         * @param {Array<string>} requestBody **Belge uuid listesi**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1IncomingInvoicesBulkOperationPut(requestParameters: V1IncomingInvoicesBulkOperationPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await IncomingInvoicesApiAxiosParamCreator(configuration).v1IncomingInvoicesBulkOperationPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgelerinizi mail olarak iletmek için bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **50** farklı belge, **10** farklı mail adresi gönderilebilir.
         * @summary Belgeyi mail olarak iletir
         * @param {EmailSendDto} emailSendDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1IncomingInvoicesEmailSendPost(requestParameters: V1IncomingInvoicesEmailSendPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await IncomingInvoicesApiAxiosParamCreator(configuration).v1IncomingInvoicesEmailSendPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgelerinizi toplu olarak dışarı aktarmak isterseniz bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **100** kayıt dışarı aktarılabilir.    **fileType** Parametre detayları    > **Xml** : Belgenizin XML halini dışarı aktarır    > **Envelope** : Belgenizin Zarf XML halini dışarı aktarır    > **Pdf** : Belgenize ait PDF görüntüsünü dışarı aktarır    > **UnifiedPdf** : ***uuids*** parametresinde gönderilen belgeleri tek PDF dosyası halinde dışarı aktarır    > **Excel** : ***uuids*** parametresinde gönderilen belgeleri Excel\'e aktarır
         * @summary Toplu aktar
         * @param {FileExportTypeParameter} fileType 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1IncomingInvoicesExportFileTypePost(requestParameters: V1IncomingInvoicesExportFileTypePostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await IncomingInvoicesApiAxiosParamCreator(configuration).v1IncomingInvoicesExportFileTypePost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Gelen e-Faturalarınıza bu uç ile ulaşabilirsiniz.
         * @summary Faturaları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {Array<string>} [tags] Faturaya atanmış etiketlere göre filtreleme yapabilirsiniz
         * @param {string} [userNote] Kullanıcı notuna göre filtreleme yapabilirsiniz
         * @param {string} [documentNote] Belge içinde geçen nota göre filtreleme yapabilirsiniz
         * @param {string} [despatchNumber] İrsaliye numarasına göre filtreleme yapabilirsiniz
         * @param {string} [orderNumber] Sipariş numarasına göre filtreleme yapabilirsiniz
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {string} [receiverAlias] Gelen faturaları e-Dönüşüm etiketlerine (urn:mail:defaultpk@nes.com.tr vb.)göre filtrelemek için kullanabilirsiniz.
         * @param {string} [startCreateDate] Oluşturma tarihi başlangıcı
         * @param {string} [endCreateDate] Oluşturma tarihi sonu
         * @param {BooleanState} [erpTransferred] ERP\&#39;ye transfer durumuna göre filtreleme yapabilirsiniz
         * @param {BooleanState} [isRead] Okunma durumuna göre filtreleme yapabilirsiniz
         * @param {boolean} [archived] Arşivlenen belgeleri göster
         * @param {InvoiceProfileIdParameter} [profileId] Fatura profiline göre filtreleme yapabilirsiniz
         * @param {InvoiceTypeParameter} [invoiceTypeCode] Fatura tipine göre filtreleme yapabilirsiniz
         * @param {DocumentAnswer} [documentAnswer] Cevap durumuna göre filtreleme yapabilirsiniz
         * @param {LucaTransferStatusParameter} [lucaTransferStatus] 
         * @param {string} [startDate] Başlangıç tarihi
         * @param {string} [endDate] Bitiş tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1IncomingInvoicesGet(requestParameters: V1IncomingInvoicesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncomingInvoicePaginationResult>> {
            const localVarAxiosArgs = await IncomingInvoicesApiAxiosParamCreator(configuration).v1IncomingInvoicesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Gelen son 10 faturaya bu uç ile ulaşabilirsiniz.
         * @summary Gelen son 10 faturayı listeler
         * @param {BooleanState} [erpTransferred] ERP\&#39;ye transfer durumuna göre filtreleme yapabilirsiniz
         * @param {BooleanState} [isRead] Okunma durumuna göre filtreleme yapabilirsiniz
         * @param {boolean} [archived] Arşivlenen belgeleri göster
         * @param {InvoiceProfileIdParameter} [profileId] Fatura profiline göre filtreleme yapabilirsiniz
         * @param {InvoiceTypeParameter} [invoiceTypeCode] Fatura tipine göre filtreleme yapabilirsiniz
         * @param {DocumentAnswer} [documentAnswer] Cevap durumuna göre filtreleme yapabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1IncomingInvoicesLastGet(requestParameters: V1IncomingInvoicesLastGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IncomingInvoice>>> {
            const localVarAxiosArgs = await IncomingInvoicesApiAxiosParamCreator(configuration).v1IncomingInvoicesLastGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye etiket eklemek veya çıkarmak için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **100** belgeye tag eklenebilir/çıkarılabilir.
         * @summary Etiket ekler/çıkarır
         * @param {TaggingDto} taggingDto #### Etiket eklemek veya çıkarmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1IncomingInvoicesTagsPut(requestParameters: V1IncomingInvoicesTagsPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await IncomingInvoicesApiAxiosParamCreator(configuration).v1IncomingInvoicesTagsPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye ait ek dokumanların listesine bu uç ile ulaşabilirsiniz.
         * @summary Belge eklerini listeler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1IncomingInvoicesUuidAttachmentsGet(requestParameters: V1IncomingInvoicesUuidAttachmentsGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Attachment>>> {
            const localVarAxiosArgs = await IncomingInvoicesApiAxiosParamCreator(configuration).v1IncomingInvoicesUuidAttachmentsGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye ait ek dokumanların indirilmesi için bu uç kullanılabilir.
         * @summary Belge ekini indirir
         * @param {string} uuid **Belge uuid**
         * @param {number} index **Ek belge sırası**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1IncomingInvoicesUuidAttachmentsIndexGet(requestParameters: V1IncomingInvoicesUuidAttachmentsIndexGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Attachment>>> {
            const localVarAxiosArgs = await IncomingInvoicesApiAxiosParamCreator(configuration).v1IncomingInvoicesUuidAttachmentsIndexGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belge içerisinde belirtilen irsaliye bilgilerine bu uç ile ulaşabilirsiniz.
         * @summary İrsaliye bilgilerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1IncomingInvoicesUuidDespatchesGet(requestParameters: V1IncomingInvoicesUuidDespatchesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DespatchDocumentReferencePaginationResult>> {
            const localVarAxiosArgs = await IncomingInvoicesApiAxiosParamCreator(configuration).v1IncomingInvoicesUuidDespatchesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Tarafınıza gönderilen ticari e-faturaya cevap vermek için bu uç kullanılabilir.
         * @summary Belge\'ye cevap verir
         * @param {string} uuid **Belge uuid**
         * @param {DocumentAnswerDto} [documentAnswerDto] **Cevap vermek için gönderilmesi gereken model**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1IncomingInvoicesUuidDocumentAnswerPost(requestParameters: V1IncomingInvoicesUuidDocumentAnswerPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutgoingAnswer>> {
            const localVarAxiosArgs = await IncomingInvoicesApiAxiosParamCreator(configuration).v1IncomingInvoicesUuidDocumentAnswerPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye ait Zarf XML dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary Zarf XML İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1IncomingInvoicesUuidEnvelopeGet(requestParameters: V1IncomingInvoicesUuidEnvelopeGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await IncomingInvoicesApiAxiosParamCreator(configuration).v1IncomingInvoicesUuidEnvelopeGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Belge detaylarını getirir
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1IncomingInvoicesUuidGet(requestParameters: V1IncomingInvoicesUuidGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncomingInvoiceDetail>> {
            const localVarAxiosArgs = await IncomingInvoicesApiAxiosParamCreator(configuration).v1IncomingInvoicesUuidGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Sorgulanan belgeye ait işlem geçmişine bu uç ile ulaşabilirsiniz.
         * @summary İşlem geçmişini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1IncomingInvoicesUuidHistoriesGet(requestParameters: V1IncomingInvoicesUuidHistoriesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoryPaginationResult>> {
            const localVarAxiosArgs = await IncomingInvoicesApiAxiosParamCreator(configuration).v1IncomingInvoicesUuidHistoriesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye ait HTML görüntüye bu uç ile ulaşabilirsiniz.
         * @summary Belgeyi görüntüler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1IncomingInvoicesUuidHtmlGet(requestParameters: V1IncomingInvoicesUuidHtmlGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await IncomingInvoicesApiAxiosParamCreator(configuration).v1IncomingInvoicesUuidHtmlGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye ait PDF dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary PDF İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1IncomingInvoicesUuidPdfGet(requestParameters: V1IncomingInvoicesUuidPdfGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await IncomingInvoicesApiAxiosParamCreator(configuration).v1IncomingInvoicesUuidPdfGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Gelen belgeler için göndericiyi, giden belgeler içinse alıcıyı **Firma Listesi**\'ne kaydetmek için bu uç kullanılabilir.
         * @summary Firma olarak kaydet
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1IncomingInvoicesUuidSavecompanyindocumentPost(requestParameters: V1IncomingInvoicesUuidSavecompanyindocumentPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await IncomingInvoicesApiAxiosParamCreator(configuration).v1IncomingInvoicesUuidSavecompanyindocumentPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belge içerisinde belirtilen vergi bilgilerine bu uç ile ulaşabilirsiniz.
         * @summary Vergi bilgilerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1IncomingInvoicesUuidTaxesGet(requestParameters: V1IncomingInvoicesUuidTaxesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxInfoPaginationResult>> {
            const localVarAxiosArgs = await IncomingInvoicesApiAxiosParamCreator(configuration).v1IncomingInvoicesUuidTaxesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Sorgulanan belgeye ait kullanıcı tarafından eklenmiş notlara bu uç ile ulaşabilirsiniz.
         * @summary Kullanıcı notu listeler
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1IncomingInvoicesUuidUsernotesGet(requestParameters: V1IncomingInvoicesUuidUsernotesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotePaginationResult>> {
            const localVarAxiosArgs = await IncomingInvoicesApiAxiosParamCreator(configuration).v1IncomingInvoicesUuidUsernotesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Kullanıcı tarafından oluşturulmuş olan notu silmek için bu uç kullanılabilir.
         * @summary Kullanıcı notunu siler
         * @param {string} uuid **Belge uuid**
         * @param {string} id **Not id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1IncomingInvoicesUuidUsernotesIdDelete(requestParameters: V1IncomingInvoicesUuidUsernotesIdDeleteRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await IncomingInvoicesApiAxiosParamCreator(configuration).v1IncomingInvoicesUuidUsernotesIdDelete(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye daha önce eklenmiş olan kullanıcı notunu güncellemek için bu uç kullanılabilir.
         * @summary Kullanıcı notunu günceller
         * @param {string} uuid #### Belgeye ait uuid
         * @param {string} id #### Not id
         * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1IncomingInvoicesUuidUsernotesIdPut(requestParameters: V1IncomingInvoicesUuidUsernotesIdPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await IncomingInvoicesApiAxiosParamCreator(configuration).v1IncomingInvoicesUuidUsernotesIdPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye kullanıcı notu eklemek için bu uç kullanılabilir.
         * @summary Kullanıcı notu ekler
         * @param {string} uuid #### Belge uuid
         * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1IncomingInvoicesUuidUsernotesPost(requestParameters: V1IncomingInvoicesUuidUsernotesPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Note>> {
            const localVarAxiosArgs = await IncomingInvoicesApiAxiosParamCreator(configuration).v1IncomingInvoicesUuidUsernotesPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye ait XML dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary XML İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1IncomingInvoicesUuidXmlGet(requestParameters: V1IncomingInvoicesUuidXmlGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await IncomingInvoicesApiAxiosParamCreator(configuration).v1IncomingInvoicesUuidXmlGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * IncomingInvoicesApi - factory interface
 * @export
 */
export const IncomingInvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Belgelere toplu olarak yeni durum atamak için bu uç kullanılabilir.
         * @summary Yeni durum atar
         * @param {IncomingInvoiceSetParameter} operation **İşlem**
         * @param {Array<string>} requestBody **Belge uuid listesi**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesBulkOperationPut(requestParameters: V1IncomingInvoicesBulkOperationPutRequest, options?: any): AxiosPromise<void> {
            return IncomingInvoicesApiFp(configuration).v1IncomingInvoicesBulkOperationPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgelerinizi mail olarak iletmek için bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **50** farklı belge, **10** farklı mail adresi gönderilebilir.
         * @summary Belgeyi mail olarak iletir
         * @param {EmailSendDto} emailSendDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesEmailSendPost(requestParameters: V1IncomingInvoicesEmailSendPostRequest, options?: any): AxiosPromise<void> {
            return IncomingInvoicesApiFp(configuration).v1IncomingInvoicesEmailSendPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgelerinizi toplu olarak dışarı aktarmak isterseniz bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **100** kayıt dışarı aktarılabilir.    **fileType** Parametre detayları    > **Xml** : Belgenizin XML halini dışarı aktarır    > **Envelope** : Belgenizin Zarf XML halini dışarı aktarır    > **Pdf** : Belgenize ait PDF görüntüsünü dışarı aktarır    > **UnifiedPdf** : ***uuids*** parametresinde gönderilen belgeleri tek PDF dosyası halinde dışarı aktarır    > **Excel** : ***uuids*** parametresinde gönderilen belgeleri Excel\'e aktarır
         * @summary Toplu aktar
         * @param {FileExportTypeParameter} fileType 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesExportFileTypePost(requestParameters: V1IncomingInvoicesExportFileTypePostRequest, options?: any): AxiosPromise<void> {
            return IncomingInvoicesApiFp(configuration).v1IncomingInvoicesExportFileTypePost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Gelen e-Faturalarınıza bu uç ile ulaşabilirsiniz.
         * @summary Faturaları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {Array<string>} [tags] Faturaya atanmış etiketlere göre filtreleme yapabilirsiniz
         * @param {string} [userNote] Kullanıcı notuna göre filtreleme yapabilirsiniz
         * @param {string} [documentNote] Belge içinde geçen nota göre filtreleme yapabilirsiniz
         * @param {string} [despatchNumber] İrsaliye numarasına göre filtreleme yapabilirsiniz
         * @param {string} [orderNumber] Sipariş numarasına göre filtreleme yapabilirsiniz
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {string} [receiverAlias] Gelen faturaları e-Dönüşüm etiketlerine (urn:mail:defaultpk@nes.com.tr vb.)göre filtrelemek için kullanabilirsiniz.
         * @param {string} [startCreateDate] Oluşturma tarihi başlangıcı
         * @param {string} [endCreateDate] Oluşturma tarihi sonu
         * @param {BooleanState} [erpTransferred] ERP\&#39;ye transfer durumuna göre filtreleme yapabilirsiniz
         * @param {BooleanState} [isRead] Okunma durumuna göre filtreleme yapabilirsiniz
         * @param {boolean} [archived] Arşivlenen belgeleri göster
         * @param {InvoiceProfileIdParameter} [profileId] Fatura profiline göre filtreleme yapabilirsiniz
         * @param {InvoiceTypeParameter} [invoiceTypeCode] Fatura tipine göre filtreleme yapabilirsiniz
         * @param {DocumentAnswer} [documentAnswer] Cevap durumuna göre filtreleme yapabilirsiniz
         * @param {LucaTransferStatusParameter} [lucaTransferStatus] 
         * @param {string} [startDate] Başlangıç tarihi
         * @param {string} [endDate] Bitiş tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesGet(requestParameters: V1IncomingInvoicesGetRequest, options?: any): AxiosPromise<IncomingInvoicePaginationResult> {
            return IncomingInvoicesApiFp(configuration).v1IncomingInvoicesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Gelen son 10 faturaya bu uç ile ulaşabilirsiniz.
         * @summary Gelen son 10 faturayı listeler
         * @param {BooleanState} [erpTransferred] ERP\&#39;ye transfer durumuna göre filtreleme yapabilirsiniz
         * @param {BooleanState} [isRead] Okunma durumuna göre filtreleme yapabilirsiniz
         * @param {boolean} [archived] Arşivlenen belgeleri göster
         * @param {InvoiceProfileIdParameter} [profileId] Fatura profiline göre filtreleme yapabilirsiniz
         * @param {InvoiceTypeParameter} [invoiceTypeCode] Fatura tipine göre filtreleme yapabilirsiniz
         * @param {DocumentAnswer} [documentAnswer] Cevap durumuna göre filtreleme yapabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesLastGet(requestParameters: V1IncomingInvoicesLastGetRequest, options?: any): AxiosPromise<Array<IncomingInvoice>> {
            return IncomingInvoicesApiFp(configuration).v1IncomingInvoicesLastGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye etiket eklemek veya çıkarmak için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **100** belgeye tag eklenebilir/çıkarılabilir.
         * @summary Etiket ekler/çıkarır
         * @param {TaggingDto} taggingDto #### Etiket eklemek veya çıkarmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesTagsPut(requestParameters: V1IncomingInvoicesTagsPutRequest, options?: any): AxiosPromise<void> {
            return IncomingInvoicesApiFp(configuration).v1IncomingInvoicesTagsPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye ait ek dokumanların listesine bu uç ile ulaşabilirsiniz.
         * @summary Belge eklerini listeler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidAttachmentsGet(requestParameters: V1IncomingInvoicesUuidAttachmentsGetRequest, options?: any): AxiosPromise<Array<Attachment>> {
            return IncomingInvoicesApiFp(configuration).v1IncomingInvoicesUuidAttachmentsGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye ait ek dokumanların indirilmesi için bu uç kullanılabilir.
         * @summary Belge ekini indirir
         * @param {string} uuid **Belge uuid**
         * @param {number} index **Ek belge sırası**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidAttachmentsIndexGet(requestParameters: V1IncomingInvoicesUuidAttachmentsIndexGetRequest, options?: any): AxiosPromise<Array<Attachment>> {
            return IncomingInvoicesApiFp(configuration).v1IncomingInvoicesUuidAttachmentsIndexGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belge içerisinde belirtilen irsaliye bilgilerine bu uç ile ulaşabilirsiniz.
         * @summary İrsaliye bilgilerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidDespatchesGet(requestParameters: V1IncomingInvoicesUuidDespatchesGetRequest, options?: any): AxiosPromise<DespatchDocumentReferencePaginationResult> {
            return IncomingInvoicesApiFp(configuration).v1IncomingInvoicesUuidDespatchesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Tarafınıza gönderilen ticari e-faturaya cevap vermek için bu uç kullanılabilir.
         * @summary Belge\'ye cevap verir
         * @param {string} uuid **Belge uuid**
         * @param {DocumentAnswerDto} [documentAnswerDto] **Cevap vermek için gönderilmesi gereken model**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidDocumentAnswerPost(requestParameters: V1IncomingInvoicesUuidDocumentAnswerPostRequest, options?: any): AxiosPromise<OutgoingAnswer> {
            return IncomingInvoicesApiFp(configuration).v1IncomingInvoicesUuidDocumentAnswerPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye ait Zarf XML dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary Zarf XML İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidEnvelopeGet(requestParameters: V1IncomingInvoicesUuidEnvelopeGetRequest, options?: any): AxiosPromise<void> {
            return IncomingInvoicesApiFp(configuration).v1IncomingInvoicesUuidEnvelopeGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Belge detaylarını getirir
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidGet(requestParameters: V1IncomingInvoicesUuidGetRequest, options?: any): AxiosPromise<IncomingInvoiceDetail> {
            return IncomingInvoicesApiFp(configuration).v1IncomingInvoicesUuidGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Sorgulanan belgeye ait işlem geçmişine bu uç ile ulaşabilirsiniz.
         * @summary İşlem geçmişini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidHistoriesGet(requestParameters: V1IncomingInvoicesUuidHistoriesGetRequest, options?: any): AxiosPromise<HistoryPaginationResult> {
            return IncomingInvoicesApiFp(configuration).v1IncomingInvoicesUuidHistoriesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye ait HTML görüntüye bu uç ile ulaşabilirsiniz.
         * @summary Belgeyi görüntüler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidHtmlGet(requestParameters: V1IncomingInvoicesUuidHtmlGetRequest, options?: any): AxiosPromise<void> {
            return IncomingInvoicesApiFp(configuration).v1IncomingInvoicesUuidHtmlGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye ait PDF dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary PDF İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidPdfGet(requestParameters: V1IncomingInvoicesUuidPdfGetRequest, options?: any): AxiosPromise<void> {
            return IncomingInvoicesApiFp(configuration).v1IncomingInvoicesUuidPdfGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Gelen belgeler için göndericiyi, giden belgeler içinse alıcıyı **Firma Listesi**\'ne kaydetmek için bu uç kullanılabilir.
         * @summary Firma olarak kaydet
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidSavecompanyindocumentPost(requestParameters: V1IncomingInvoicesUuidSavecompanyindocumentPostRequest, options?: any): AxiosPromise<Company> {
            return IncomingInvoicesApiFp(configuration).v1IncomingInvoicesUuidSavecompanyindocumentPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belge içerisinde belirtilen vergi bilgilerine bu uç ile ulaşabilirsiniz.
         * @summary Vergi bilgilerini getirir
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidTaxesGet(requestParameters: V1IncomingInvoicesUuidTaxesGetRequest, options?: any): AxiosPromise<TaxInfoPaginationResult> {
            return IncomingInvoicesApiFp(configuration).v1IncomingInvoicesUuidTaxesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Sorgulanan belgeye ait kullanıcı tarafından eklenmiş notlara bu uç ile ulaşabilirsiniz.
         * @summary Kullanıcı notu listeler
         * @param {string} uuid **Belge uuid**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidUsernotesGet(requestParameters: V1IncomingInvoicesUuidUsernotesGetRequest, options?: any): AxiosPromise<NotePaginationResult> {
            return IncomingInvoicesApiFp(configuration).v1IncomingInvoicesUuidUsernotesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Kullanıcı tarafından oluşturulmuş olan notu silmek için bu uç kullanılabilir.
         * @summary Kullanıcı notunu siler
         * @param {string} uuid **Belge uuid**
         * @param {string} id **Not id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidUsernotesIdDelete(requestParameters: V1IncomingInvoicesUuidUsernotesIdDeleteRequest, options?: any): AxiosPromise<void> {
            return IncomingInvoicesApiFp(configuration).v1IncomingInvoicesUuidUsernotesIdDelete(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye daha önce eklenmiş olan kullanıcı notunu güncellemek için bu uç kullanılabilir.
         * @summary Kullanıcı notunu günceller
         * @param {string} uuid #### Belgeye ait uuid
         * @param {string} id #### Not id
         * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidUsernotesIdPut(requestParameters: V1IncomingInvoicesUuidUsernotesIdPutRequest, options?: any): AxiosPromise<void> {
            return IncomingInvoicesApiFp(configuration).v1IncomingInvoicesUuidUsernotesIdPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye kullanıcı notu eklemek için bu uç kullanılabilir.
         * @summary Kullanıcı notu ekler
         * @param {string} uuid #### Belge uuid
         * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidUsernotesPost(requestParameters: V1IncomingInvoicesUuidUsernotesPostRequest, options?: any): AxiosPromise<Note> {
            return IncomingInvoicesApiFp(configuration).v1IncomingInvoicesUuidUsernotesPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye ait XML dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary XML İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IncomingInvoicesUuidXmlGet(requestParameters: V1IncomingInvoicesUuidXmlGetRequest, options?: any): AxiosPromise<void> {
            return IncomingInvoicesApiFp(configuration).v1IncomingInvoicesUuidXmlGet(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IncomingInvoicesApi - object-oriented interface
 * @export
 * @class IncomingInvoicesApi
 * @extends {BaseAPI}
 */
export class IncomingInvoicesApi extends BaseAPI {
    /**
     * Belgelere toplu olarak yeni durum atamak için bu uç kullanılabilir.
     * @summary Yeni durum atar
     * @param {IncomingInvoiceSetParameter} operation **İşlem**
     * @param {Array<string>} requestBody **Belge uuid listesi**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingInvoicesApi
     */
    public v1IncomingInvoicesBulkOperationPut(requestParameters: V1IncomingInvoicesBulkOperationPutRequest, options?: any) {
        return IncomingInvoicesApiFp(this.configuration).v1IncomingInvoicesBulkOperationPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgelerinizi mail olarak iletmek için bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **50** farklı belge, **10** farklı mail adresi gönderilebilir.
     * @summary Belgeyi mail olarak iletir
     * @param {EmailSendDto} emailSendDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingInvoicesApi
     */
    public v1IncomingInvoicesEmailSendPost(requestParameters: V1IncomingInvoicesEmailSendPostRequest, options?: any) {
        return IncomingInvoicesApiFp(this.configuration).v1IncomingInvoicesEmailSendPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgelerinizi toplu olarak dışarı aktarmak isterseniz bu ucu kullanabilirsiniz.    **Limit** : Tek bir istek içerisinde maks **100** kayıt dışarı aktarılabilir.    **fileType** Parametre detayları    > **Xml** : Belgenizin XML halini dışarı aktarır    > **Envelope** : Belgenizin Zarf XML halini dışarı aktarır    > **Pdf** : Belgenize ait PDF görüntüsünü dışarı aktarır    > **UnifiedPdf** : ***uuids*** parametresinde gönderilen belgeleri tek PDF dosyası halinde dışarı aktarır    > **Excel** : ***uuids*** parametresinde gönderilen belgeleri Excel\'e aktarır
     * @summary Toplu aktar
     * @param {FileExportTypeParameter} fileType 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingInvoicesApi
     */
    public v1IncomingInvoicesExportFileTypePost(requestParameters: V1IncomingInvoicesExportFileTypePostRequest, options?: any) {
        return IncomingInvoicesApiFp(this.configuration).v1IncomingInvoicesExportFileTypePost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gelen e-Faturalarınıza bu uç ile ulaşabilirsiniz.
     * @summary Faturaları listeler
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {Array<string>} [tags] Faturaya atanmış etiketlere göre filtreleme yapabilirsiniz
     * @param {string} [userNote] Kullanıcı notuna göre filtreleme yapabilirsiniz
     * @param {string} [documentNote] Belge içinde geçen nota göre filtreleme yapabilirsiniz
     * @param {string} [despatchNumber] İrsaliye numarasına göre filtreleme yapabilirsiniz
     * @param {string} [orderNumber] Sipariş numarasına göre filtreleme yapabilirsiniz
     * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
     * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
     * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
     * @param {string} [receiverAlias] Gelen faturaları e-Dönüşüm etiketlerine (urn:mail:defaultpk@nes.com.tr vb.)göre filtrelemek için kullanabilirsiniz.
     * @param {string} [startCreateDate] Oluşturma tarihi başlangıcı
     * @param {string} [endCreateDate] Oluşturma tarihi sonu
     * @param {BooleanState} [erpTransferred] ERP\&#39;ye transfer durumuna göre filtreleme yapabilirsiniz
     * @param {BooleanState} [isRead] Okunma durumuna göre filtreleme yapabilirsiniz
     * @param {boolean} [archived] Arşivlenen belgeleri göster
     * @param {InvoiceProfileIdParameter} [profileId] Fatura profiline göre filtreleme yapabilirsiniz
     * @param {InvoiceTypeParameter} [invoiceTypeCode] Fatura tipine göre filtreleme yapabilirsiniz
     * @param {DocumentAnswer} [documentAnswer] Cevap durumuna göre filtreleme yapabilirsiniz
     * @param {LucaTransferStatusParameter} [lucaTransferStatus] 
     * @param {string} [startDate] Başlangıç tarihi
     * @param {string} [endDate] Bitiş tarihi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingInvoicesApi
     */
    public v1IncomingInvoicesGet(requestParameters: V1IncomingInvoicesGetRequest, options?: any) {
        return IncomingInvoicesApiFp(this.configuration).v1IncomingInvoicesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gelen son 10 faturaya bu uç ile ulaşabilirsiniz.
     * @summary Gelen son 10 faturayı listeler
     * @param {BooleanState} [erpTransferred] ERP\&#39;ye transfer durumuna göre filtreleme yapabilirsiniz
     * @param {BooleanState} [isRead] Okunma durumuna göre filtreleme yapabilirsiniz
     * @param {boolean} [archived] Arşivlenen belgeleri göster
     * @param {InvoiceProfileIdParameter} [profileId] Fatura profiline göre filtreleme yapabilirsiniz
     * @param {InvoiceTypeParameter} [invoiceTypeCode] Fatura tipine göre filtreleme yapabilirsiniz
     * @param {DocumentAnswer} [documentAnswer] Cevap durumuna göre filtreleme yapabilirsiniz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingInvoicesApi
     */
    public v1IncomingInvoicesLastGet(requestParameters: V1IncomingInvoicesLastGetRequest, options?: any) {
        return IncomingInvoicesApiFp(this.configuration).v1IncomingInvoicesLastGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye etiket eklemek veya çıkarmak için bu uç kullanılabilir.    **Limit** : Tek bir istek içerisinde maks **100** belgeye tag eklenebilir/çıkarılabilir.
     * @summary Etiket ekler/çıkarır
     * @param {TaggingDto} taggingDto #### Etiket eklemek veya çıkarmak için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingInvoicesApi
     */
    public v1IncomingInvoicesTagsPut(requestParameters: V1IncomingInvoicesTagsPutRequest, options?: any) {
        return IncomingInvoicesApiFp(this.configuration).v1IncomingInvoicesTagsPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye ait ek dokumanların listesine bu uç ile ulaşabilirsiniz.
     * @summary Belge eklerini listeler
     * @param {string} uuid **Belge uuid**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingInvoicesApi
     */
    public v1IncomingInvoicesUuidAttachmentsGet(requestParameters: V1IncomingInvoicesUuidAttachmentsGetRequest, options?: any) {
        return IncomingInvoicesApiFp(this.configuration).v1IncomingInvoicesUuidAttachmentsGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye ait ek dokumanların indirilmesi için bu uç kullanılabilir.
     * @summary Belge ekini indirir
     * @param {string} uuid **Belge uuid**
     * @param {number} index **Ek belge sırası**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingInvoicesApi
     */
    public v1IncomingInvoicesUuidAttachmentsIndexGet(requestParameters: V1IncomingInvoicesUuidAttachmentsIndexGetRequest, options?: any) {
        return IncomingInvoicesApiFp(this.configuration).v1IncomingInvoicesUuidAttachmentsIndexGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belge içerisinde belirtilen irsaliye bilgilerine bu uç ile ulaşabilirsiniz.
     * @summary İrsaliye bilgilerini getirir
     * @param {string} uuid **Belge uuid**
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingInvoicesApi
     */
    public v1IncomingInvoicesUuidDespatchesGet(requestParameters: V1IncomingInvoicesUuidDespatchesGetRequest, options?: any) {
        return IncomingInvoicesApiFp(this.configuration).v1IncomingInvoicesUuidDespatchesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tarafınıza gönderilen ticari e-faturaya cevap vermek için bu uç kullanılabilir.
     * @summary Belge\'ye cevap verir
     * @param {string} uuid **Belge uuid**
     * @param {DocumentAnswerDto} [documentAnswerDto] **Cevap vermek için gönderilmesi gereken model**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingInvoicesApi
     */
    public v1IncomingInvoicesUuidDocumentAnswerPost(requestParameters: V1IncomingInvoicesUuidDocumentAnswerPostRequest, options?: any) {
        return IncomingInvoicesApiFp(this.configuration).v1IncomingInvoicesUuidDocumentAnswerPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye ait Zarf XML dosyasını indirmek için bu ucu kullanabilirsiniz.
     * @summary Zarf XML İndir
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingInvoicesApi
     */
    public v1IncomingInvoicesUuidEnvelopeGet(requestParameters: V1IncomingInvoicesUuidEnvelopeGetRequest, options?: any) {
        return IncomingInvoicesApiFp(this.configuration).v1IncomingInvoicesUuidEnvelopeGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Belge detaylarını getirir
     * @param {string} uuid **Belge uuid**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingInvoicesApi
     */
    public v1IncomingInvoicesUuidGet(requestParameters: V1IncomingInvoicesUuidGetRequest, options?: any) {
        return IncomingInvoicesApiFp(this.configuration).v1IncomingInvoicesUuidGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sorgulanan belgeye ait işlem geçmişine bu uç ile ulaşabilirsiniz.
     * @summary İşlem geçmişini getirir
     * @param {string} uuid **Belge uuid**
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingInvoicesApi
     */
    public v1IncomingInvoicesUuidHistoriesGet(requestParameters: V1IncomingInvoicesUuidHistoriesGetRequest, options?: any) {
        return IncomingInvoicesApiFp(this.configuration).v1IncomingInvoicesUuidHistoriesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye ait HTML görüntüye bu uç ile ulaşabilirsiniz.
     * @summary Belgeyi görüntüler
     * @param {string} uuid **Belge uuid**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingInvoicesApi
     */
    public v1IncomingInvoicesUuidHtmlGet(requestParameters: V1IncomingInvoicesUuidHtmlGetRequest, options?: any) {
        return IncomingInvoicesApiFp(this.configuration).v1IncomingInvoicesUuidHtmlGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye ait PDF dosyasını indirmek için bu ucu kullanabilirsiniz.
     * @summary PDF İndir
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingInvoicesApi
     */
    public v1IncomingInvoicesUuidPdfGet(requestParameters: V1IncomingInvoicesUuidPdfGetRequest, options?: any) {
        return IncomingInvoicesApiFp(this.configuration).v1IncomingInvoicesUuidPdfGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gelen belgeler için göndericiyi, giden belgeler içinse alıcıyı **Firma Listesi**\'ne kaydetmek için bu uç kullanılabilir.
     * @summary Firma olarak kaydet
     * @param {string} uuid **Belge uuid**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingInvoicesApi
     */
    public v1IncomingInvoicesUuidSavecompanyindocumentPost(requestParameters: V1IncomingInvoicesUuidSavecompanyindocumentPostRequest, options?: any) {
        return IncomingInvoicesApiFp(this.configuration).v1IncomingInvoicesUuidSavecompanyindocumentPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belge içerisinde belirtilen vergi bilgilerine bu uç ile ulaşabilirsiniz.
     * @summary Vergi bilgilerini getirir
     * @param {string} uuid **Belge uuid**
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingInvoicesApi
     */
    public v1IncomingInvoicesUuidTaxesGet(requestParameters: V1IncomingInvoicesUuidTaxesGetRequest, options?: any) {
        return IncomingInvoicesApiFp(this.configuration).v1IncomingInvoicesUuidTaxesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sorgulanan belgeye ait kullanıcı tarafından eklenmiş notlara bu uç ile ulaşabilirsiniz.
     * @summary Kullanıcı notu listeler
     * @param {string} uuid **Belge uuid**
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingInvoicesApi
     */
    public v1IncomingInvoicesUuidUsernotesGet(requestParameters: V1IncomingInvoicesUuidUsernotesGetRequest, options?: any) {
        return IncomingInvoicesApiFp(this.configuration).v1IncomingInvoicesUuidUsernotesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Kullanıcı tarafından oluşturulmuş olan notu silmek için bu uç kullanılabilir.
     * @summary Kullanıcı notunu siler
     * @param {string} uuid **Belge uuid**
     * @param {string} id **Not id**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingInvoicesApi
     */
    public v1IncomingInvoicesUuidUsernotesIdDelete(requestParameters: V1IncomingInvoicesUuidUsernotesIdDeleteRequest, options?: any) {
        return IncomingInvoicesApiFp(this.configuration).v1IncomingInvoicesUuidUsernotesIdDelete(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye daha önce eklenmiş olan kullanıcı notunu güncellemek için bu uç kullanılabilir.
     * @summary Kullanıcı notunu günceller
     * @param {string} uuid #### Belgeye ait uuid
     * @param {string} id #### Not id
     * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingInvoicesApi
     */
    public v1IncomingInvoicesUuidUsernotesIdPut(requestParameters: V1IncomingInvoicesUuidUsernotesIdPutRequest, options?: any) {
        return IncomingInvoicesApiFp(this.configuration).v1IncomingInvoicesUuidUsernotesIdPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye kullanıcı notu eklemek için bu uç kullanılabilir.
     * @summary Kullanıcı notu ekler
     * @param {string} uuid #### Belge uuid
     * @param {NoteDto} noteDto #### Not eklemek için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingInvoicesApi
     */
    public v1IncomingInvoicesUuidUsernotesPost(requestParameters: V1IncomingInvoicesUuidUsernotesPostRequest, options?: any) {
        return IncomingInvoicesApiFp(this.configuration).v1IncomingInvoicesUuidUsernotesPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye ait XML dosyasını indirmek için bu ucu kullanabilirsiniz.
     * @summary XML İndir
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomingInvoicesApi
     */
    public v1IncomingInvoicesUuidXmlGet(requestParameters: V1IncomingInvoicesUuidXmlGetRequest, options?: any) {
        return IncomingInvoicesApiFp(this.configuration).v1IncomingInvoicesUuidXmlGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1IncomingInvoicesBulkOperationPutRequest {
    /**
     * **İşlem**
     */
    operation: IncomingInvoiceSetParameter;
    /**
     * **Belge uuid listesi**
     */
    requestBody: Array<string>;
    }
    export interface V1IncomingInvoicesEmailSendPostRequest {
    /**
     * 
     */
    emailSendDto: EmailSendDto;
    }
    export interface V1IncomingInvoicesExportFileTypePostRequest {
    /**
     * 
     */
    fileType: FileExportTypeParameter;
    /**
     * 
     */
    requestBody: Array<string>;
    }
    export interface V1IncomingInvoicesGetRequest {
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    /**
     * Faturaya atanmış etiketlere göre filtreleme yapabilirsiniz
     */
    tags?: Array<string>;
    /**
     * Kullanıcı notuna göre filtreleme yapabilirsiniz
     */
    userNote?: string;
    /**
     * Belge içinde geçen nota göre filtreleme yapabilirsiniz
     */
    documentNote?: string;
    /**
     * İrsaliye numarasına göre filtreleme yapabilirsiniz
     */
    despatchNumber?: string;
    /**
     * Sipariş numarasına göre filtreleme yapabilirsiniz
     */
    orderNumber?: string;
    /**
     * Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
     */
    company?: string;
    /**
     * UUID numarasına göre filtreleme yapabilirsiniz
     */
    uuid?: string;
    /**
     * Belge numarasına göre filtreleme yapabilirsiniz
     */
    documentNumber?: string;
    /**
     * Gelen faturaları e-Dönüşüm etiketlerine (urn:mail:defaultpk@nes.com.tr vb.)göre filtrelemek için kullanabilirsiniz.
     */
    receiverAlias?: string;
    /**
     * Oluşturma tarihi başlangıcı
     */
    startCreateDate?: string;
    /**
     * Oluşturma tarihi sonu
     */
    endCreateDate?: string;
    /**
     * ERP\'ye transfer durumuna göre filtreleme yapabilirsiniz
     */
    erpTransferred?: BooleanState;
    /**
     * Okunma durumuna göre filtreleme yapabilirsiniz
     */
    isRead?: BooleanState;
    /**
     * Arşivlenen belgeleri göster
     */
    archived?: boolean;
    /**
     * Fatura profiline göre filtreleme yapabilirsiniz
     */
    profileId?: InvoiceProfileIdParameter;
    /**
     * Fatura tipine göre filtreleme yapabilirsiniz
     */
    invoiceTypeCode?: InvoiceTypeParameter;
    /**
     * Cevap durumuna göre filtreleme yapabilirsiniz
     */
    documentAnswer?: DocumentAnswer;
    /**
     * 
     */
    lucaTransferStatus?: LucaTransferStatusParameter;
    /**
     * Başlangıç tarihi
     */
    startDate?: string;
    /**
     * Bitiş tarihi
     */
    endDate?: string;
    }
    export interface V1IncomingInvoicesLastGetRequest {
    /**
     * ERP\'ye transfer durumuna göre filtreleme yapabilirsiniz
     */
    erpTransferred?: BooleanState;
    /**
     * Okunma durumuna göre filtreleme yapabilirsiniz
     */
    isRead?: BooleanState;
    /**
     * Arşivlenen belgeleri göster
     */
    archived?: boolean;
    /**
     * Fatura profiline göre filtreleme yapabilirsiniz
     */
    profileId?: InvoiceProfileIdParameter;
    /**
     * Fatura tipine göre filtreleme yapabilirsiniz
     */
    invoiceTypeCode?: InvoiceTypeParameter;
    /**
     * Cevap durumuna göre filtreleme yapabilirsiniz
     */
    documentAnswer?: DocumentAnswer;
    }
    export interface V1IncomingInvoicesTagsPutRequest {
    /**
     * #### Etiket eklemek veya çıkarmak için gönderilmesi gereken model
     */
    taggingDto: TaggingDto;
    }
    export interface V1IncomingInvoicesUuidAttachmentsGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    }
    export interface V1IncomingInvoicesUuidAttachmentsIndexGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    /**
     * **Ek belge sırası**
     */
    index: number;
    }
    export interface V1IncomingInvoicesUuidDespatchesGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    }
    export interface V1IncomingInvoicesUuidDocumentAnswerPostRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    /**
     * **Cevap vermek için gönderilmesi gereken model**
     */
    documentAnswerDto?: DocumentAnswerDto;
    }
    export interface V1IncomingInvoicesUuidEnvelopeGetRequest {
    /**
     * 
     */
    uuid: string;
    }
    export interface V1IncomingInvoicesUuidGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    }
    export interface V1IncomingInvoicesUuidHistoriesGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    }
    export interface V1IncomingInvoicesUuidHtmlGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    }
    export interface V1IncomingInvoicesUuidPdfGetRequest {
    /**
     * 
     */
    uuid: string;
    }
    export interface V1IncomingInvoicesUuidSavecompanyindocumentPostRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    }
    export interface V1IncomingInvoicesUuidTaxesGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    }
    export interface V1IncomingInvoicesUuidUsernotesGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    }
    export interface V1IncomingInvoicesUuidUsernotesIdDeleteRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    /**
     * **Not id**
     */
    id: string;
    }
    export interface V1IncomingInvoicesUuidUsernotesIdPutRequest {
    /**
     * #### Belgeye ait uuid
     */
    uuid: string;
    /**
     * #### Not id
     */
    id: string;
    /**
     * #### Not eklemek için gönderilmesi gereken model
     */
    noteDto: NoteDto;
    }
    export interface V1IncomingInvoicesUuidUsernotesPostRequest {
    /**
     * #### Belge uuid
     */
    uuid: string;
    /**
     * #### Not eklemek için gönderilmesi gereken model
     */
    noteDto: NoteDto;
    }
    export interface V1IncomingInvoicesUuidXmlGetRequest {
    /**
     * 
     */
    uuid: string;
    }
