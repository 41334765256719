/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { AmountType, IDType, PartyType, TextType } from '.';
import { NS_CAC, NS_CBC } from '../constants';

/*
  27.04.2021 : Partially completed
  cac:PartyLegalEntity
*/
export class PartyLegalEntityType {
  @XMLElement({ types: [{ name: 'RegistrationName', namespaceUri: NS_CBC, itemType: () => TextType }] })
  registrationName?: TextType;

  @XMLElement({ types: [{ name: 'CompanyID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  companyID?: IDType;

  @XMLElement({ types: [{ name: 'RegistrationDate', namespaceUri: NS_CBC, itemType: () => String }] })
  registrationDate?: string;

  //TODO : SoleProprietorshipIndicator

  @XMLElement({ types: [{ name: 'CorporateStockAmount', namespaceUri: NS_CBC, itemType: () => AmountType }] })
  corporateStockAmount?: AmountType;

  // TODO : FullyPaidSharesIndicator

  // TODO: CorporateRegistrationScheme

  @XMLElement({ types: [{ name: 'HeadOfficeParty', namespaceUri: NS_CAC, itemType: () => PartyType }] })
  headOfficeParty?: PartyType;
}

export default PartyLegalEntityType;
