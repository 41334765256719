/* eslint-disable @typescript-eslint/no-inferrable-types */

import { XMLElement } from '@/xmldom-decorators';
import { ContactType, PartyType } from '.';
import { DefaultValue, NS_CAC } from '../constants';

/*
  27.04.2021 : Fully completed
*/
export class SupplierPartyType {
  @XMLElement({ types: [{ name: 'Party', namespaceUri: NS_CAC, itemType: () => PartyType }] })
  party: PartyType = DefaultValue;

  @XMLElement({ types: [{ name: 'DespatchContact', namespaceUri: NS_CAC, itemType: () => ContactType }] })
  despatchContact?: ContactType;
}

export default SupplierPartyType;
