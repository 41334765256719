import { BankInformation as BankInformationModel, DocumentTemplateCustomizationSettingDto } from '@/apis/einvoice/interfaces';
import { WarningToastify } from '@/components';
import { Delete, New } from '@/components/Buttons';
import SortableTable from '@/components/SortableTable';
import { PageConst } from '@/constants/page';
import { EditOutlined } from '@ant-design/icons';
import { faArrowLeft, faArrowRight, faTrash } from '@fortawesome/pro-light-svg-icons';
import { faArrowAltToLeft, faArrowAltToRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Input, Menu, Popover, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { addItemsInIndex, repositionItem } from 'redux-toolbelt-immutable-helpers';
import { v4 as uuidv4 } from 'uuid';
import './style.scss';

const BankInformation = ({
  documentTemplateCustomizationSetting,
  onChange
}: {
  documentTemplateCustomizationSetting?: DocumentTemplateCustomizationSettingDto;
  onChange: (data: BankInformationModel) => void;
}) => {
  const [bankInfo, setBankInfo] = useState<BankInformationModel>({
    columns: (documentTemplateCustomizationSetting &&
      documentTemplateCustomizationSetting.bankInformation &&
      documentTemplateCustomizationSetting.bankInformation.columns) || ['Banka Adı', 'Şube', 'Hesap No', 'IBAN'],
    rows:
      (documentTemplateCustomizationSetting &&
        documentTemplateCustomizationSetting.bankInformation &&
        documentTemplateCustomizationSetting.bankInformation.rows) ||
      []
  });

  const dataSource = bankInfo.rows.reduce((data: any[], item, rowIndex) => {
    const dataRow = item.reduce((row: any, rowData, columnIndex) => {
      row = { ...row, [bankInfo.columns[columnIndex]]: rowData, index: uuidv4() };
      return row;
    }, {});

    data.push(dataRow);

    return data;
  }, []);

  useEffect(() => {
    onChange(bankInfo);
  }, [bankInfo]);

  const onDataChange = (newValue, column, rowIndex) => {
    const newData = { ...bankInfo };

    const columnIndex = bankInfo.columns.indexOf(column);

    newData.rows[rowIndex][columnIndex] = newValue;

    setBankInfo(newData);
  };

  const onColumnDelete = (column) => {
    const columnIndex = bankInfo.columns.indexOf(column.dataIndex);

    const newDbRecords = { ...bankInfo };

    newDbRecords.columns.splice(columnIndex, 1);

    newDbRecords.rows.map((x) => x.splice(columnIndex, 1));

    setBankInfo(newDbRecords);
  };

  const onAddRow = () => {
    const newDbRecords = { ...bankInfo };

    newDbRecords.rows.push(Array(bankInfo.columns.length).fill(''));
    setBankInfo(newDbRecords);
  };

  const onDeleteRow = (index) => {
    const newRecords = { ...bankInfo };

    newRecords.rows.splice(index, 1);

    setBankInfo(newRecords);
  };

  const makeColumns = () => {
    const columnList = bankInfo.columns.reduce((row: any[], item) => {
      row.push({
        dataIndex: item,
        title: item,
        render: (value, rowData, index) => <Input defaultValue={value} onBlur={(e) => e.target.value !== value && onDataChange(e.target.value, item, index)} />
      });

      return row;
    }, []);
    columnList.push({
      dataIndex: 'remove',
      align: 'center',
      width: 50,
      title: 'Sil',
      render: (value, rowData, index) => <Delete onlyIcon onClick={() => onDeleteRow(index)} />
    });

    return columnList;
  };

  const columns = makeColumns();

  const onAddColumn = (column, position: 'left' | 'right') => {
    const newDbRecords = { ...bankInfo };

    if (columns.some((x) => x.title === 'Yeni kolon')) {
      WarningToastify(intl.get('YENI_COLON_DAHA_ONCE_EKLENMIS'));
      return;
    }

    const activeIndex = columns.findIndex((x) => x.dataIndex === column.dataIndex);

    const positionIndex = position === 'left' ? activeIndex : activeIndex + 1;

    newDbRecords.columns = addItemsInIndex(newDbRecords.columns, positionIndex, 'Yeni kolon');
    newDbRecords.rows = newDbRecords.rows.map((row) => addItemsInIndex(row, positionIndex, ''));

    setBankInfo(newDbRecords);
  };

  const onDragColumn = (column, position: 'left' | 'right') => {
    const newDbRecords = { ...bankInfo };

    const activeIndex = columns.findIndex((x) => x.dataIndex === column.dataIndex);

    const positionIndex = position === 'left' ? activeIndex - 1 : activeIndex + 1;

    newDbRecords.columns = repositionItem(newDbRecords.columns, activeIndex, positionIndex);
    newDbRecords.rows = newDbRecords.rows.map((row) => repositionItem(row, activeIndex, positionIndex));
    setBankInfo(newDbRecords);
  };

  const HeaderCell = (props) => {
    // eslint-disable-next-line react/prop-types
    const { children } = props;

    const defaultValue = (children as any)[1] as string;

    const column = columns.find((x) => x.dataIndex === defaultValue);
    const columntIndex = columns.findIndex((x) => x.dataIndex === defaultValue);

    if (!column) {
      return <th {...props} />;
    }

    const columnIsFirst = columntIndex === 0;
    const columnIsLast = columntIndex === columns.length - 1;

    const changeTitle = (e) => {
      if (e.target.value !== defaultValue) {
        if (columns.some((x) => x.title === e.target.value)) {
          WarningToastify(intl.get('AYNI_KOLON_DAHA_ONCE_EKLENMIS'));
          return;
        }

        const newDbRecords = { ...bankInfo };
        const columnIndex = bankInfo.columns.indexOf(column.dataIndex);

        newDbRecords.columns[columnIndex] = e.target.value;
        setBankInfo(newDbRecords);
      }
    };

    return (
      <Popover
        key={column?.dataIndex}
        placement="bottom"
        content={
          <div>
            <div style={{ padding: 10 }}>
              <Input autoFocus defaultValue={defaultValue} onBlur={changeTitle} onKeyDown={(e) => e.keyCode === 13 && changeTitle(e)} />
            </div>
            <Menu>
              <Menu.Divider />
              {!columnIsFirst && (
                <Menu.Item onClick={() => onDragColumn(column, 'left')}>
                  <FontAwesomeIcon icon={faArrowAltToLeft} />
                  {intl.get('SUTUNU_SOLA_KAYDIR')}
                </Menu.Item>
              )}
              {!columnIsLast && (
                <Menu.Item onClick={() => onDragColumn(column, 'right')}>
                  <FontAwesomeIcon icon={faArrowAltToRight} />
                  {intl.get('SUTUNU_SAGA_KAYDIR')}
                </Menu.Item>
              )}
              <Menu.Divider />
              <Menu.Item onClick={() => onAddColumn(column, 'left')}>
                <FontAwesomeIcon icon={faArrowLeft} />
                {intl.get('SOLA_SUTUN_EKLE')}
              </Menu.Item>
              <Menu.Item onClick={() => onAddColumn(column, 'right')}>
                <FontAwesomeIcon icon={faArrowRight} />
                {intl.get('SAGA_SUTUN_EKLE')}
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item onClick={() => onColumnDelete(column)}>
                <FontAwesomeIcon icon={faTrash} />
                {intl.get('SUTUN_SIL')}
              </Menu.Item>
            </Menu>
          </div>
        }
        trigger={'click'}
        overlayClassName={'header_cell_editor'}
        style={{ borderRadius: 5 }}
        overlayStyle={{ padding: 0 }}
        destroyTooltipOnHide
      >
        <th {...props} style={{ cursor: 'pointer' }}>
          <EditOutlined /> {children}
        </th>
      </Popover>
    );
  };

  const components = {
    header: {
      cell: HeaderCell
    }
  };

  return (
    <Row gutter={PageConst.RowGutter}>
      <Col sm={24}>
        <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '7.5px' }}>
          <New onClick={onAddRow} title="Yeni Satır" />
        </div>

        <SortableTable
          data={dataSource}
          columns={columns}
          components={components}
          onChangeDataIndex={(oldIndex, newIndex) => {
            const newData = { ...bankInfo };
            newData.rows = repositionItem(newData.rows, oldIndex, newIndex);
            setBankInfo(newData);
          }}
        />
      </Col>
    </Row>
  );
};

export default BankInformation;
