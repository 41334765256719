import { V1TagsIdPutRequest, V1TagsPostRequest } from '@/apis/earchive';
import { Tag, TagDto } from '@/apis/earchive/interfaces';
import { InfoToastify, RootSpinner } from '@/components';
import { Close, Save } from '@/components/Buttons';
import { PageConst } from '@/constants/page';
import { useApi } from '@/hooks';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Modal, Row } from 'antd';
import { AxiosPromise } from 'axios';
import { Formik, FormikProps } from 'formik';
import { Form, FormItem, Input } from 'formik-antd';
import React, { useRef } from 'react';
import intl from 'react-intl-universal';
import * as Yup from 'yup';
import { ErrorViewer } from '../TableBody';

const { TextArea } = Input;

export const NewTag = (values, setFieldValue) => {
  const colorPaletsArray = [
    '0033CC',
    '428BCA',
    '44AD8E',
    '5CB85C',
    '69D100',
    '004E00',
    '34495E',
    '7F8C8D',
    'A295D6',
    '5843AD',
    '8E44AD',
    'AD4363',
    'D10069',
    'CC0033',
    'FF0000',
    'D9534F',
    'D1D100',
    'F0AD4E',
    'AD8D43'
  ];
  return (
    <Form layout="vertical">
      <Row gutter={PageConst.RowGutter}>
        <Col md={24}>
          <FormItem name="name" label={intl.get('ETIKET_ISMI')} required>
            <Input name="name" placeholder={intl.get('ETIKET_ISMI')} tabIndex={0} />
          </FormItem>
        </Col>

        <Col md={24}>
          <FormItem name="description" label={intl.get('ACIKLAMA')} required>
            <TextArea name="description" placeholder={intl.get('ACIKLAMA')} tabIndex={0} />
          </FormItem>
        </Col>
        <Col md={24}>
          <FormItem name="color" label={'Önizleme'}>
            <Input
              name="name"
              placeholder={values.name}
              tabIndex={0}
              disabled={true}
              addonBefore={
                <a
                  style={{
                    background: `#${values.color}`,
                    padding: '5px 18px',
                    margin: '0 -11px',
                    borderBottomLeftRadius: '2px',
                    borderStartStartRadius: '2px'
                  }}
                ></a>
              }
            />
          </FormItem>
        </Col>
        <Col md={24}>
          <div className="suggest-colors" style={{ width: '100%', height: 'auto' }}>
            <p>Lütfen renk seçiniz.</p>
            {colorPaletsArray.map((color) => (
              <a
                className="has-tooltip"
                key={color}
                onClick={(e) => setFieldValue('color', e.currentTarget.getAttribute('data-color'))}
                style={{ backgroundColor: `#${color}`, cursor: 'pointer' }}
                data-color={color}
              ></a>
            ))}
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export interface TagModalProps {
  visible: boolean;
  tag?: Tag;
}

export const NewTagScheme = Yup.object().shape<TagDto>({
  name: Yup.string().required().max(20),
  color: Yup.string().required().max(6),
  description: Yup.string().required().max(50)
});
const TagModal = ({
  closeModal,
  modalState: { visible, tag },
  post,
  put,
  refresh
}: {
  closeModal: () => void;
  modalState: TagModalProps;
  post: (requestParameters: V1TagsPostRequest) => AxiosPromise<Tag>;
  put: (requestParameters: V1TagsIdPutRequest) => AxiosPromise<void>;
  refresh: () => Promise<void>;
}) => {
  let form = (useRef(null) as unknown) as FormikProps<V1TagsPostRequest>;
  const callBack = (title: string, isEdit: boolean) => {
    closeModal();
    refresh();
    InfoToastify(intl.getHTML(isEdit ? 'ETIKET_GUNCELLENDI' : 'ETIKET_EKLENDI', { title }));
  };

  /**
   * Insert işlemi için kullanılan fonksiypn
   */
  const postRequest = useApi<void, V1TagsPostRequest>({
    asyncFunction: post,
    successCallback: (_, request) => {
      if (request) {
        callBack(request.tagDto.name, false);
      }
    }
  });

  /**
   * Update işlemi için kullanılan fonksiyon
   */
  const putRequest = useApi<void, V1TagsIdPutRequest>({
    asyncFunction: put,
    successCallback: (_, request) => {
      if (request) {
        callBack(request.tagDto.name, true);
      }
    }
  });

  return (
    <Modal
      title={
        <span>
          <FontAwesomeIcon icon={faPlus} className="mr-2" /> {intl.get('YENI_X_EKLE', { x: intl.get('ETIKET') })}
        </span>
      }
      onCancel={closeModal}
      width={PageConst.MediumModalWidth}
      centered
      transitionName="fade"
      maskClosable={false}
      visible={visible}
      afterClose={() => {
        putRequest.clear();
        postRequest.clear();
      }}
      footer={[
        <React.Fragment key="tag-modal-footer">
          <Close onClick={closeModal} />
          <Save onClick={() => form.handleSubmit()} />
        </React.Fragment>
      ]}
      destroyOnClose
    >
      <RootSpinner loading={postRequest.loading || putRequest.loading}>
        <Formik<TagDto>
          validateOnBlur={false}
          innerRef={(instance) => (form = instance)}
          onSubmit={(values) => {
            if(form.isValid) tag ? putRequest.call({ id: tag.id, tagDto: values }) : postRequest.call({ tagDto: values });
          }}
          validationSchema={NewTagScheme}
          initialValues={tag ? tag : { color: '0033CC', description: '', name: '' }}
        >
          {({ setFieldValue, values }) => {
            return NewTag(values, setFieldValue);
          }}
        </Formik>
        {(postRequest.error || putRequest.error) && <ErrorViewer error={postRequest.error || putRequest.error} />}
      </RootSpinner>
    </Modal>
  );
};

export default TagModal;
