/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { v4 as uuidv4 } from 'uuid';
import { AmountType } from '.';
import { NS_CBC } from '../constants';

/*
  27.04.2021 : Fully completed
*/
export class AllowanceChargeType {
  @XMLElement({ types: [{ name: 'ChargeIndicator', namespaceUri: NS_CBC, itemType: () => Boolean }] })
  chargeIndicator: boolean = false;

  @XMLElement({ types: [{ name: 'AllowanceChargeReason', namespaceUri: NS_CBC, itemType: () => String }] })
  allowanceChargeReason?: string;

  @XMLElement({ types: [{ name: 'MultiplierFactorNumeric', namespaceUri: NS_CBC, itemType: () => Number }] })
  multiplierFactorNumeric?: number;

  @XMLElement({ types: [{ name: 'SequenceNumeric', namespaceUri: NS_CBC, itemType: () => Number }] })
  sequenceNumeric?: number;

  @XMLElement({ types: [{ name: 'Amount', namespaceUri: NS_CBC, itemType: () => AmountType }] })
  amount: AmountType = { value: 0 };

  @XMLElement({ types: [{ name: 'BaseAmount', namespaceUri: NS_CBC, itemType: () => AmountType }] })
  baseAmount?: AmountType;

  @XMLElement({ types: [{ name: 'PerUnitAmount', namespaceUri: NS_CBC, itemType: () => AmountType }] })
  perUnitAmount?: AmountType;

  //Belge oluştur ekranlarında, orandan mı tutardan mı hesaplanacağı bu alanda belirtilir
  calculationField: 'Amount' | 'Percent' = 'Percent';

  percent: number = 0;

  uniqueId: string = uuidv4();
}

export default AllowanceChargeType;
