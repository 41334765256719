// tslint:disable
// eslint:disable
/**
 * General API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum DocumentType {
    OutgoingInvoice = 'OutgoingInvoice',
    IncomingInvoice = 'IncomingInvoice',
    ExOutgoingInvoice = 'ExOutgoingInvoice',
    ExIncomingInvoice = 'ExIncomingInvoice',
    EArchiveInvoice = 'EArchiveInvoice',
    ExEArchiveInvoice = 'ExEArchiveInvoice',
    IncomingDespatch = 'IncomingDespatch',
    OutgoingDespatch = 'OutgoingDespatch',
    ExIncomingDespatch = 'ExIncomingDespatch',
    ExOutgoingDespatch = 'ExOutgoingDespatch',
    EMm = 'EMm',
    ESmm = 'ESmm',
    EBook = 'EBook',
    ECheck = 'ECheck',
    Unknown = 'Unknown'
}



