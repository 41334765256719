/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/camelcase */

import * as crypto from 'crypto';
import React from 'react';

const signKey = (clientKey: string, msg: string) => {
  const key = new Buffer(clientKey, 'utf8');
  return crypto.createHmac('sha256', key).update(msg).digest('hex');
};

class ChatwootWidget extends React.Component {
  componentDidMount() {
    // window.addEventListener('chatwoot:ready', function () {
    const user = JSON.parse(
      window.sessionStorage.getItem('oidc.user:https://idtest.nes.com.tr:etransformation-front-end') ||
        window.sessionStorage.getItem('oidc.user:https://id.nes.com.tr:etransformation-front-end') ||
        '{}'
    );
    if (user != undefined && (window as any).$chatwoot != undefined) {
      debugger;
      const profile = user.profile;

      (window as any).$chatwoot.setUser(profile.sub, {
        name: profile.FullName, // Name of the user
        company_name: profile.AccountName,
        email: profile.email, // Email of the user
        identifier_hash: signKey('dPUbcTCwvqTc5cFNLUCKMXgH', profile.sub) // Identifier Hash generated in the previous step
      });

      (window as any).$chatwoot.setCustomAttributes({
        account_id: profile.AccountId,
        account_name: profile.AccountName,
        fullname: profile.FullName,
        party_identification: profile.PartyIdentification,
        username: profile.name
      });
    }
    // });
  }

  render() {
    return null;
  }
}

export default ChatwootWidget;
