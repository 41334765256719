import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { faEnvelopeOpen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import React, { memo } from 'react';
import intl from 'react-intl-universal';

export const IsRead = memo(({ isRead }: { isRead: boolean }) => {
  return (
    <Tooltip placement="bottom" title={isRead ? intl.get('OKUNDU') : intl.get('OKUNMADI')} className="c-p">
      <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={isRead ? faEnvelopeOpen : faEnvelope} className="fa-lg" />
    </Tooltip>
  );
});

export default IsRead;
