/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { IDType } from '.';
import { NS_CBC } from '../constants';

/*
  27.04.2021 : Partially completed
  cac:CommodityClassification
  cac:ItemInstance
*/
export class ItemInstanceType {
  @XMLElement({ types: [{ name: 'ProductTraceID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  productTraceID?: IDType;

  @XMLElement({ types: [{ name: 'ManufactureDate', namespaceUri: NS_CBC, itemType: () => String }] })
  manufactureDate?: string;

  @XMLElement({ types: [{ name: 'ManufactureTime', namespaceUri: NS_CBC, itemType: () => String }] })
  manufactureTime?: string;

  @XMLElement({ types: [{ name: 'BestBeforeDate', namespaceUri: NS_CBC, itemType: () => String }] })
  bestBeforeDate?: string;

  @XMLElement({ types: [{ name: 'RegistrationID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  registrationID?: IDType;

  @XMLElement({ types: [{ name: 'SerialID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  serialID?: IDType;

  // TODO : AdditionalItemProperty
}

export default ItemInstanceType;
