import { usePreviousValue } from '@/hooks';
import { AnyType } from '@/type';
import { Pagination as AntdPagination } from 'antd';
import React, { memo, useEffect } from 'react';
import intl from 'react-intl-universal';
import { CardFooter } from 'reactstrap';
import SelectedCount from './SelectedCount';
import styles from './style.module.scss';
interface Pagination {
  data: AnyType;
  execute: () => void;
  page: number;
  pageSize: number;
  onPageChange: (page: number, pageSize: number) => void;

  /**
   * seçilen kayıt sayısı
   */
  selectedCount: number;
}

export const LocalPagination = memo(
  ({ data, execute, page, pageSize, selectedCount, onPageChange }: Pagination) => {
    const prevPage = usePreviousValue<number | undefined>(page);
    const prevPageSize = usePreviousValue<number | undefined>(pageSize);

    useEffect(() => {
      if ((prevPage && page !== prevPage) || (prevPageSize && pageSize !== prevPageSize)) {
        execute();
      }
    }, [execute, page, pageSize, prevPage, prevPageSize]);

    return (
      <CardFooter className={styles.pagination__footer}>
        <SelectedCount count={selectedCount} />
        <AntdPagination
          className={styles.pagination}
          defaultPageSize={50}
          showSizeChanger
          onChange={(page, pageSize) => pageSize && onPageChange(page, pageSize)}
          current={data.page}
          pageSize={data.pageSize}
          total={data.totalCount}
          hideOnSinglePage={false}
          pageSizeOptions={['50', '100']}
          showQuickJumper
          showTotal={(total: number, range: [number, number]) => `${intl.get('TOPLAM')} : ${total} | ${intl.get('GOSTERILEN')} : ${range[0]} - ${range[1]}`}
        />
      </CardFooter>
    );
  },
  (prev, next) => {
    return (
      prev.page === next.page &&
      prev.pageSize === next.pageSize &&
      prev.data.page === next.data.page &&
      prev.data.pageSize === next.data.pageSize &&
      prev.data.totalCount === next.data.totalCount &&
      prev.selectedCount === next.selectedCount
    );
  }
);

export default LocalPagination;
