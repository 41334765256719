import { ProductType } from '@/apis/identity/interfaces';
import { ALLROLES, GetScopeOrRoleList } from '@/constants/auth';
import { LockOutlined } from '@ant-design/icons';
import { Alert, Modal, Result } from 'antd';
import React from 'react';

export * from './Authenticating';
export * from './Callback';
export * from './NotAuthenticated';
export * from './NotAuthorized';
export * from './SessionLost';

export const HasRoleAndNotifiy = (roleKey: string): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const role = GetScopeOrRoleList(ALLROLES).find((x) => x.value === roleKey)!;

  const user = JSON.parse(
    window.sessionStorage.getItem('oidc.user:https://idtest.nes.com.tr:etransformation-front-end') ||
      window.sessionStorage.getItem('oidc.user:https://id.nes.com.tr:etransformation-front-end') ||
      '{}'
  );

  if (user.profile === undefined) {
    return false;
  }

  const userRoles = Array.isArray(user.profile['role'] || []) ? user.profile['role'] || [] : [user.profile['role']];

  const isAccountant = JSON.parse(user.profile.IsAccountant);
  const allowAccountantUploadDocument = JSON.parse(user.profile.AccountantAllowDocumentUpload ?? 'false');

  if (isAccountant) {
    if (role === undefined) {
      return true;
    }

    if (!(role.value.indexOf('UPLOADS') >= 0 && !allowAccountantUploadDocument)) {
      return true;
    }

    if (!(role.value.indexOf('ADMINISTRATOR') >= 0)) {
      return true;
    }
  } else {
    if (userRoles.some((x) => x === 'ADMINISTRATOR') || userRoles.some((x) => x === role.value)) {
      return true;
    }
  }

  Modal.warning({
    // title: `Yetkisiz İşlem`,
    icon: undefined,
    width: 720,
    content: (
      <Result
        style={{ padding: 0 }}
        status="warning"
        icon={<LockOutlined />}
        title={
          <div>
            <span>Yetkisiz işlem. </span>
            <Alert style={{ marginTop: 20 }} message={role.content} type="warning" />
          </div>
        }
        extra={<div>İşlemi gerçekleştirebilmeniz için yukarıda belirtilen yetkinin tanımlanması gerekmektedir.</div>}
      />
    )
  });

  return false;
};

export const HasRole = (role: string): boolean => {
  const user = JSON.parse(
    window.sessionStorage.getItem('oidc.user:https://idtest.nes.com.tr:etransformation-front-end') ||
      window.sessionStorage.getItem('oidc.user:https://id.nes.com.tr:etransformation-front-end') ||
      '{}'
  );

  if (user.profile === undefined) {
    return false;
  }

  const userRoles = Array.isArray(user.profile['role'] || []) ? user.profile['role'] || [] : [user.profile['role']];

  const isAccountant = JSON.parse(user.profile.IsAccountant);
  const allowAccountantUploadDocument = JSON.parse(user.profile.AccountantAllowDocumentUpload ?? 'false');

  if (isAccountant) {
    if (role.indexOf('UPLOADS') >= 0 && !allowAccountantUploadDocument) {
      return false;
    }

    if (role.indexOf('ADMINISTRATOR') >= 0) {
      return false;
    }

    return true;
  } else {
    if (userRoles.some((x) => x === 'ADMINISTRATOR') || userRoles.some((x) => x === role)) {
      return true;
    }
    return false;
  }
};

export const HasProduct = (productType: ProductType): boolean => {
  const user = JSON.parse(
    window.sessionStorage.getItem('oidc.user:https://idtest.nes.com.tr:etransformation-front-end') ||
      window.sessionStorage.getItem('oidc.user:https://id.nes.com.tr:etransformation-front-end') ||
      '{}'
  );

  if (user.profile === undefined) {
    return false;
  }

  switch (productType) {
    case ProductType.EInvoice:
      return user.profile['EInvoiceIncomingModuleStatus'] !== '-1' || user.profile['EInvoiceOutgoingModuleStatus'] !== '-1';
    case ProductType.EDespatch:
      return user.profile['EDespatchIncomingModuleStatus'] !== '-1' || user.profile['EDespatchOutgoingModuleStatus'] !== '-1';
    case ProductType.EArchive:
      return user.profile['EArchiveInvoiceModuleStatus'] !== '-1';
    case ProductType.ESmm:
      return user.profile['ESmmVoucherModuleStatus'] !== '-1';
    case ProductType.EMm:
      return user.profile['EMmVoucherModuleStatus'] !== '-1';
    default:
      return false;
  }
};

export const HideMarketPlace = (): boolean => {
  const user = JSON.parse(
    window.sessionStorage.getItem('oidc.user:https://idtest.nes.com.tr:etransformation-front-end') ||
      window.sessionStorage.getItem('oidc.user:https://id.nes.com.tr:etransformation-front-end') ||
      '{}'
  );

  if (user.profile === undefined) {
    return false;
  }

  return JSON.parse(user.profile.HideMarketPlace || 'false');
};

export const HasAccountant = (): boolean => {
  const user = JSON.parse(
    window.sessionStorage.getItem('oidc.user:https://idtest.nes.com.tr:etransformation-front-end') ||
      window.sessionStorage.getItem('oidc.user:https://id.nes.com.tr:etransformation-front-end') ||
      '{}'
  );

  if (user.profile === undefined) {
    return false;
  }

  return JSON.parse(user.profile.IsAccountant);
};

export const HasPayAsYouGo = (): boolean => {
  const user = JSON.parse(
    window.sessionStorage.getItem('oidc.user:https://idtest.nes.com.tr:etransformation-front-end') ||
      window.sessionStorage.getItem('oidc.user:https://id.nes.com.tr:etransformation-front-end') ||
      '{}'
  );

  if (user.profile === undefined) {
    return false;
  }

  return JSON.parse(user.profile.UsingPayAsYouGo);
};

export const HasGroupCreditPool = (): boolean => {
  const user = JSON.parse(
    window.sessionStorage.getItem('oidc.user:https://idtest.nes.com.tr:etransformation-front-end') ||
      window.sessionStorage.getItem('oidc.user:https://id.nes.com.tr:etransformation-front-end') ||
      '{}'
  );

  if (user.profile === undefined) {
    return false;
  }

  return JSON.parse(user.profile.UsingGroupCreditPool);
};

export const HassAccountantAllowUploadDocument = (): boolean => {
  const user = JSON.parse(
    window.sessionStorage.getItem('oidc.user:https://idtest.nes.com.tr:etransformation-front-end') ||
      window.sessionStorage.getItem('oidc.user:https://id.nes.com.tr:etransformation-front-end') ||
      '{}'
  );

  if (user.profile === undefined) {
    return false;
  }

  return JSON.parse(user.profile.AccountantAllowDocumentUpload ?? 'false');
};

export const GetAccountProducts = (): ProductType[] => {
  const user = JSON.parse(
    window.sessionStorage.getItem('oidc.user:https://idtest.nes.com.tr:etransformation-front-end') ||
      window.sessionStorage.getItem('oidc.user:https://id.nes.com.tr:etransformation-front-end') ||
      '{}'
  );

  if (user.profile === undefined) {
    return [];
  }

  const products: ProductType[] = [];

  (user.profile['EInvoiceIncomingModuleStatus'] !== '-1' || user.profile['EInvoiceOutgoingModuleStatus'] !== '-1') && products.push(ProductType.EInvoice);
  (user.profile['EDespatchIncomingModuleStatus'] !== '-1' || user.profile['EDespatchOutgoingModuleStatus'] !== '-1') && products.push(ProductType.EDespatch);
  user.profile['EArchiveInvoiceModuleStatus'] !== '-1' && products.push(ProductType.EArchive);
  user.profile['ESmmVoucherModuleStatus'] !== '-1' && products.push(ProductType.ESmm);
  user.profile['EMmVoucherModuleStatus'] !== '-1' && products.push(ProductType.EMm);

  return products;
};
