import { AnnouncementsApiFactory, V1AnnouncementsLastGetRequest } from '@/apis/general';
import { AnnouncementDashboardDto } from '@/apis/general/interfaces';
import { Preview } from '@/components/Buttons';
import { PageStyle } from '@/constants/page';
import { useGetPageData } from '@/hooks';
import { MailOutlined } from '@ant-design/icons';
import { faNewspaper } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Tag, Tooltip } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { updateItemsByFilter } from 'redux-toolbelt-immutable-helpers';
import AnnouncementModal from './announcementModal';

const announcementApiFactory = AnnouncementsApiFactory();

const Announcements = ({ isDevelopmentAnnouncement, title }: { title: string; isDevelopmentAnnouncement: boolean }) => {
  const [announcementState, setAnnouncementState] = useState<{ visible: boolean; announcement?: AnnouncementDashboardDto }>({
    visible: false,
    announcement: undefined
  });

  const announcements = useGetPageData<Array<AnnouncementDashboardDto>>(
    announcementApiFactory.v1AnnouncementsLastGet,
    {
      isDevelopmentAnnouncement
    } as V1AnnouncementsLastGetRequest,
    true,
    (data) => {
      const announcement = data.filter((x) => x.isRead === false && x.showModal === true)[0];

      if (announcement) {
        setAnnouncementState({ announcement, visible: true });
      }
    }
  );

  return (
    <>
      <AnnouncementModal
        setVisible={(v, id) => {
          setAnnouncementState({ ...announcementState, visible: v });

          debugger;
          if (v === false && announcements.data?.some((x) => x.id === id && x.isRead === false)) {
            announcements.setData(
              updateItemsByFilter(
                announcements.data!,
                (item: AnnouncementDashboardDto) => item.id === id,
                (item) => ({ ...item, isRead: true })
              )
            );
          }
        }}
        announcement={announcementState.announcement}
        visible={announcementState.visible}
      />

      <Card>
        <CardHeader className={PageStyle.card__header}>
          <div className="justify-content-center mr-auto utils_custom_title">
            <FontAwesomeIcon icon={faNewspaper} color="#0ab1fc" style={{ marginRight: '10px' }} />
            {title}
          </div>
        </CardHeader>
        <CardBody>
          <Table
            bordered
            columns={[
              {
                title: '',
                width: 40,
                align: 'center',
                render(value, record: AnnouncementDashboardDto, index) {
                  return (
                    <Preview
                      onClick={() => {
                        setAnnouncementState({ visible: true, announcement: record });
                      }}
                    />
                  );
                }
              },
              {
                title: '',
                width: 40,
                align: 'center',
                render(value, record: AnnouncementDashboardDto, index) {
                  if (record.isRead === true) {
                    return null;
                  }

                  return (
                    <Tooltip title="Okunmamış duyuru" placement="bottomRight">
                      <Tag color="blue" icon={<MailOutlined />} className="mb-0 new-announcement" />
                    </Tooltip>
                  );
                }
              },
              {
                title: 'Duyuru Tarihi',
                dataIndex: 'createdAt',
                key: 'createdAt',
                width: 120,
                render(value, record: AnnouncementDashboardDto, index) {
                  return moment(record.createdAt).format('DD.MM.YYYY');
                }
              },
              {
                title: 'Başlık',
                dataIndex: 'title',
                key: 'title',
                render(value, record: AnnouncementDashboardDto, index) {
                  return (
                    <Tooltip title={record.title} placement="left">
                      {_.truncate(record.title!, { length: 60 })}
                    </Tooltip>
                  );
                }
              }
            ]}
            scroll={{ x: true }}
            dataSource={announcements?.data || []}
            pagination={false}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default Announcements;
