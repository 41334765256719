import { DailyStatistics } from '@/apis/general/interfaces';
import { getDays } from '@/utils/array';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { Skeleton } from 'antd';
import moment from 'moment';
import React, { useImperativeHandle } from 'react';
import { Responsive } from 'react-grid-layout';
import { SizeMe } from 'react-sizeme';
import DailyLineChart from '../Components/DailyLineChart';
import Doughnut from '../Components/Doughnut';
import InvoiceTypeCodeStats from './InvoiceTypeCodeStats';
import LastCanceledInvoices from './LastCanceledInvoices';
import LastInvoices from './LastInvoices';
import { EArchiveLayouts } from './Layouts';

const EArchive = React.forwardRef(
  (
    {
      startDate,
      endDate,
      flattenData,
      data,
      loading,
      generalStats
    }: {
      startDate: string;
      endDate: string;
      flattenData: any;
      loading: boolean;
      data?: DailyStatistics[];
      generalStats: any;
    },
    ref
  ) => {
    const lastInvoicesRef = React.createRef() as any;
    const lastCanceledInvoicesRef = React.createRef() as any;

    useImperativeHandle(ref, () => ({
      refreshData() {
        lastInvoicesRef.current.refresh();
        lastCanceledInvoicesRef.current.refresh();
      }
    }));

    return (
      <SizeMe>
        {({ size }) => {
          const divWidth = window.document.getElementsByClassName('air__utils__content')[0]?.clientWidth;
          return (
            <Responsive
              width={size !== null ? size.width || divWidth : divWidth}
              draggableHandle=".dashboard_drag_handle"
              layouts={
                JSON.parse(localStorage.getItem('apps.dashboard.earchive-f4975ba9-072c-439e-8089-d0d5facfc7e5') || '[]').length != 0
                  ? JSON.parse(localStorage.getItem('apps.dashboard.earchive-f4975ba9-072c-439e-8089-d0d5facfc7e5') || '[]')
                  : EArchiveLayouts
              }
              // layouts={EInvoiceLayouts}
              breakpoints={{ xxl: 1600, xl: 1200, lg: 1000, md: 800, sm: 600, xs: 400, xxs: 10 }}
              cols={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 24, xxs: 24 }}
              margin={[10, 10]}
              rowHeight={40}
              onLayoutChange={(_, layouts) => {
                localStorage.setItem('apps.dashboard.earchive-f4975ba9-072c-439e-8089-d0d5facfc7e5', JSON.stringify(layouts));
              }}
            >
              <div key="earchive-last-invoices" className="drag-item">
                <span className="dashboard_drag_handle" />
                <LastInvoices ref={lastInvoicesRef} />
              </div>

              <div key="earchive-last-canceled-invoices" className="drag-item">
                <span className="dashboard_drag_handle" />
                <LastCanceledInvoices ref={lastCanceledInvoicesRef} />
              </div>

              <div key="earchive-type-stats" className="drag-item">
                <span className="dashboard_drag_handle" />
                <InvoiceTypeCodeStats startDate={startDate} endDate={endDate} flattenData={flattenData} loading={loading} data={data} />
              </div>

              <div key="earchive-mailing-mail" className="drag-item">
                <span className="dashboard_drag_handle" />
                <Doughnut
                  icon={faPencil}
                  loading={loading}
                  title="Mail Durumları"
                  data={
                    flattenData['EA:M:S'] || flattenData['EA:M:F']
                      ? [
                          {
                            color: '#41b883',
                            label: 'Başarılı',
                            value: flattenData['EA:M:S'] || 0,
                            url: `/earchive/list?startDate=${startDate}&endDate=${endDate}&mailStatus=Succeed`
                          },
                          {
                            color: '#dc3545',
                            label: 'Başarısız',
                            value: flattenData['EA:M:F'] || 0,
                            url: `/earchive/list?startDate=${startDate}&endDate=${endDate}&mailStatus=Failed`
                          }
                        ]
                      : []
                  }
                />
              </div>

              <div key="earchive-mailing-sms" className="drag-item">
                <span className="dashboard_drag_handle" />
                <Doughnut
                  icon={faPencil}
                  loading={loading}
                  title="Sms Durumları"
                  data={
                    flattenData['EA:S:S'] || flattenData['EA:S:F']
                      ? [
                          {
                            color: '#41b883',
                            label: 'Başarılı',
                            value: flattenData['EA:S:S'] || 0,
                            url: `/earchive/list?startDate=${startDate}&endDate=${endDate}&smsStatus=Succeed`
                          },
                          {
                            color: '#dc3545',
                            label: 'Başarısız',
                            value: flattenData['EA:S:F'] || 0,
                            url: `/earchive/list?startDate=${startDate}&endDate=${endDate}&smsStatus=Failed`
                          }
                        ]
                      : []
                  }
                />
              </div>

              <div key="earchive-invoice-total" className="drag-item">
                <span className="dashboard_drag_handle" />
                {loading && <Skeleton active loading={loading} />}
                {!loading && (
                  <div className="card" style={{ border: '1px solid #bfc2c5' }}>
                    <div className="card-body">
                      <div className="d-flex flex-wrap align-items-center">
                        <div className="mr-auto">
                          <p className="text-uppercase font-weight-bold mb-1">Fatura</p>
                          <p className="mb-0">Tüm Zamanlar</p>
                        </div>
                        <p className="text-success font-weight-bold font-size-24 mb-0">{(generalStats && generalStats['EA:I']) || 0}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div key="earchive-invoice-canceled-total" className="drag-item">
                <span className="dashboard_drag_handle" />
                {loading && <Skeleton active loading={loading} />}
                {!loading && (
                  <div className="card" style={{ border: '1px solid #bfc2c5' }}>
                    <div className="card-body">
                      <div className="d-flex flex-wrap align-items-center">
                        <div className="mr-auto">
                          <p className="text-uppercase font-weight-bold mb-1">E-Arşiv İptal</p>
                          <p className="mb-0">Tüm Zamanlar</p>
                        </div>
                        <p className="text-danger font-weight-bold font-size-24 mb-0">{(generalStats && generalStats['EA:C']) || 0}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div key="earchive-invoice-mail-total" className="drag-item">
                <span className="dashboard_drag_handle" />
                {loading && <Skeleton active loading={loading} />}
                {!loading && (
                  <div className="card" style={{ border: '1px solid #bfc2c5' }}>
                    <div className="card-body">
                      <div className="d-flex flex-wrap align-items-center">
                        <div className="mr-auto">
                          <p className="text-uppercase font-weight-bold mb-1">Mail</p>
                          <p className="mb-0">Tüm Zamanlar</p>
                        </div>
                        <p className="font-weight-bold font-size-24 mb-0" style={{ color: '#007bff' }}>
                          {(generalStats && generalStats['EA:M']) || 0}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div key="earchive-invoice-sms-total" className="drag-item">
                <span className="dashboard_drag_handle" />
                {loading && <Skeleton active loading={loading} />}
                {!loading && (
                  <div className="card" style={{ border: '1px solid #bfc2c5' }}>
                    <div className="card-body">
                      <div className="d-flex flex-wrap align-items-center">
                        <div className="mr-auto">
                          <p className="text-uppercase font-weight-bold mb-1">Sms</p>
                          <p className="mb-0">Tüm Zamanlar</p>
                        </div>
                        <p className="font-weight-bold font-size-24 mb-0" style={{ color: '#17a2b8' }}>
                          {(generalStats && generalStats['EA:S']) || 0}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div key="earchive-daily" className="drag-item">
                <span className="dashboard_drag_handle" />
                <DailyLineChart
                  title="E-Arşiv İstatistikleri"
                  icon={faPencil}
                  loading={loading}
                  data={
                    data === undefined ||
                    data.length === 0 ||
                    (data.length !== 0 && !data.some((x) => x.statistics['EA:I'] !== undefined) && !data.some((x) => x.statistics['EA:C'] !== undefined))
                      ? getDays(startDate, endDate).map((item) => {
                          return {
                            date: item,
                            data: [
                              {
                                color: '#007bff',
                                label: 'e-Arşiv Fatura',
                                value: 0
                              },
                              {
                                color: '#dc3545',
                                label: 'e-Arşiv İptal',
                                value: 0
                              }
                            ]
                          };
                        })
                      : data.map((item) => {
                          return {
                            date: moment(item.date),
                            data: [
                              {
                                color: '#007bff',
                                label: 'e-Arşiv Fatura',
                                value: item.statistics['EA:I'],
                                url: `/earchive/list?&startDate=${startDate}&endDate=${endDate}`
                              },
                              {
                                color: '#dc3545',
                                label: 'e-Arşiv İptal',
                                value: item.statistics['EA:C'],
                                url: `/earchive/list/canceled?&startDate=${startDate}&endDate=${endDate}`
                              }
                            ]
                          };
                        })
                  }
                />
              </div>
            </Responsive>
          );
        }}
      </SizeMe>
    );
  }
);

export default EArchive;
