/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/camelcase */

import moment from 'moment';

export const PageStyle = {
  card__header: 'card__header card-header-flex align-items-center',
  card__header__title: 'justify-content-center mr-auto utils_custom_title',
  virtualized__card__body: 'd-flex flex-column h-100',
  table__body__spinner: 'table__body__spinner'
};

export const DateFormat = {
  /**
   * YYYY-MM-DD - Yıl-Ay-Gün şeklinde formatlar
   */
  ymd: 'YYYY-MM-DD',
  dmy: 'DD.MM.YYYY',
  dmyHMS: 'DD.MM.YYYY HH:mm:SS',
  HMS: 'HH:mm:ss'
};

export const IssueDateToday = moment(new Date()).format(DateFormat.ymd);

export const DateConst = {
  START_DATE: moment().add(-1, 'week').format(DateFormat.ymd),
  END_DATE: moment().format(DateFormat.ymd),
  TODAY: moment().format(DateFormat.ymd),
  YESTERDAY: moment().add(-1, 'day').format(DateFormat.ymd)
};
export const PageConst = {
  RowGutter: 5,
  EditorColGutter: 7.5,
  EditorRowGutter: 10,
  ColGutter: 8,
  LargeModalWidth: 900,
  XLargeModalWidth: 1200,
  XXLargeModalWidth: 1400,
  MediumModalWidth: 600,
  SmallModalWidth: 450
};
