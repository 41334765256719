/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { EmbeddedDocumentBinaryObjectType, ExternalReferenceType } from '.';
import { NS_CAC, NS_CBC } from '../constants';

/*
  27.04.2021 : Fully completed
*/
export class AttachmentType {
  @XMLElement({ types: [{ name: 'EmbeddedDocumentBinaryObject', namespaceUri: NS_CBC, itemType: () => EmbeddedDocumentBinaryObjectType }] })
  embeddedDocumentBinaryObject?: EmbeddedDocumentBinaryObjectType;

  // TODO : cac:ExternalReference

  @XMLElement({ types: [{ name: 'ExternalReference', namespaceUri: NS_CAC, itemType: () => ExternalReferenceType }] })
  externalReference?: ExternalReferenceType;
}

export default AttachmentType;
