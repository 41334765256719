// tslint:disable
// eslint:disable
/**
 * E-Archive API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum EDocumentTemplateType {
    _1 = 'Template_1',
    _2 = 'Template_2',
    _3 = 'Template_3',
    _4 = 'Template_4',
    _5 = 'Template_5'
}



