export enum BootstrapVariant {
  primary = 'primary',
  info = 'info',
  success = 'success',
  mint = 'mint',
  warning = 'warning',
  danger = 'danger',
  pink = 'pink',
  purple = 'purple',
  dark = 'dark'
}

export default BootstrapVariant;
