// tslint:disable
// eslint:disable
/**
 * E-MM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ExcelReportCreateDto } from '../interfaces';
// @ts-ignore
import { ExcelReportQueue } from '../interfaces';
// @ts-ignore
import { ExcelReportQueuePaginationResult } from '../interfaces';
// @ts-ignore
import { ExcelReportTemplate } from '../interfaces';
// @ts-ignore
import { ExcelReportTemplateDto } from '../interfaces';
// @ts-ignore
import { ExcelReportTemplatePaginationResult } from '../interfaces';
/**
 * ReportModuleApi - axios parameter creator
 * @export
 */
export const ReportModuleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Şablonlarda kullanılabilecek kolonların listesine bu uç ile ulaşabilirsiniz.
         * @summary Kolonları listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingReportmoduleColumnsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/outgoing/reportmodule/columns`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Daha önce oluşturulmuş raporlarınıza bu uç ile ulaşabilirsiniz.
         * @summary Rapor listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [query] *query* parametresi ile arama yapabilirsiniz.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingReportmoduleReportsGet: async (requestParameters: V1OutgoingReportmoduleReportsGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1OutgoingReportmoduleReportsGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1OutgoingReportmoduleReportsGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1OutgoingReportmoduleReportsGet.');
            }
            const localVarPath = `/v1/outgoing/reportmodule/reports`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.query !== undefined) {
                localVarQueryParameter['query'] = requestParameters.query;
            }
            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Daha önce oluşturulmuş raporlarınızı bu uç ile indirebilirsiniz.
         * @summary Rapor indirir
         * @param {string} id **Rapor id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingReportmoduleReportsIdDownloadGet: async (requestParameters: V1OutgoingReportmoduleReportsIdDownloadGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1OutgoingReportmoduleReportsIdDownloadGet.');
            }
            const localVarPath = `/v1/outgoing/reportmodule/reports/{id}/download`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Rapor oluşturmak için bu uç kullanılabilir.
         * @summary Rapor oluşturur
         * @param {ExcelReportCreateDto} [excelReportCreateDto] #### Rapor oluşturmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingReportmoduleReportsPost: async (requestParameters: V1OutgoingReportmoduleReportsPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/outgoing/reportmodule/reports`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.excelReportCreateDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.excelReportCreateDto !== undefined ? requestParameters.excelReportCreateDto : {}) : (requestParameters.excelReportCreateDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Excel rapor modülünde kullanmak için oluşturduğunuz şablonlara bu uç ulaşabilirsiniz.
         * @summary Şablonları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [query] *query* parametresi ile arama yapabilirsiniz.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingReportmoduleTemplatesGet: async (requestParameters: V1OutgoingReportmoduleTemplatesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1OutgoingReportmoduleTemplatesGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1OutgoingReportmoduleTemplatesGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1OutgoingReportmoduleTemplatesGet.');
            }
            const localVarPath = `/v1/outgoing/reportmodule/templates`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.query !== undefined) {
                localVarQueryParameter['query'] = requestParameters.query;
            }
            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Kayıtlı rapor şablonunuzu silmek bu uç kullanılabilir.
         * @summary Rapor Şablonunu siler
         * @param {string} id **Silinecek şablon id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingReportmoduleTemplatesIdDelete: async (requestParameters: V1OutgoingReportmoduleTemplatesIdDeleteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1OutgoingReportmoduleTemplatesIdDelete.');
            }
            const localVarPath = `/v1/outgoing/reportmodule/templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sorgulanan şablonu getirir
         * @param {string} id **Şablon id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingReportmoduleTemplatesIdGet: async (requestParameters: V1OutgoingReportmoduleTemplatesIdGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1OutgoingReportmoduleTemplatesIdGet.');
            }
            const localVarPath = `/v1/outgoing/reportmodule/templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Daha önce oluşturulmuş olan rapor şablonunu güncellemek için bu uç kullanılabilir.
         * @summary Rapor şablonunu günceller
         * @param {string} id **Düzenlenecek şablon id**
         * @param {ExcelReportTemplateDto} excelReportTemplateDto #### Rapor şablonu güncellemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingReportmoduleTemplatesIdPut: async (requestParameters: V1OutgoingReportmoduleTemplatesIdPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1OutgoingReportmoduleTemplatesIdPut.');
            }
            // verify required parameter 'excelReportTemplateDto' is not null or undefined
            if (requestParameters.excelReportTemplateDto === null || requestParameters.excelReportTemplateDto === undefined) {
                throw new RequiredError('excelReportTemplateDto','Required parameter excelReportTemplateDto was null or undefined when calling v1OutgoingReportmoduleTemplatesIdPut.');
            }
            const localVarPath = `/v1/outgoing/reportmodule/templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.excelReportTemplateDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.excelReportTemplateDto !== undefined ? requestParameters.excelReportTemplateDto : {}) : (requestParameters.excelReportTemplateDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Yeni rapor şablonu oluşturmak için bu uç kullanılabilir.
         * @summary Rapor şablonu oluşturur
         * @param {ExcelReportTemplateDto} excelReportTemplateDto #### Rapor şablonu oluşturmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingReportmoduleTemplatesPost: async (requestParameters: V1OutgoingReportmoduleTemplatesPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'excelReportTemplateDto' is not null or undefined
            if (requestParameters.excelReportTemplateDto === null || requestParameters.excelReportTemplateDto === undefined) {
                throw new RequiredError('excelReportTemplateDto','Required parameter excelReportTemplateDto was null or undefined when calling v1OutgoingReportmoduleTemplatesPost.');
            }
            const localVarPath = `/v1/outgoing/reportmodule/templates`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.excelReportTemplateDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.excelReportTemplateDto !== undefined ? requestParameters.excelReportTemplateDto : {}) : (requestParameters.excelReportTemplateDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportModuleApi - functional programming interface
 * @export
 */
export const ReportModuleApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Şablonlarda kullanılabilecek kolonların listesine bu uç ile ulaşabilirsiniz.
         * @summary Kolonları listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingReportmoduleColumnsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await ReportModuleApiAxiosParamCreator(configuration).v1OutgoingReportmoduleColumnsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Daha önce oluşturulmuş raporlarınıza bu uç ile ulaşabilirsiniz.
         * @summary Rapor listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [query] *query* parametresi ile arama yapabilirsiniz.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingReportmoduleReportsGet(requestParameters: V1OutgoingReportmoduleReportsGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExcelReportQueuePaginationResult>> {
            const localVarAxiosArgs = await ReportModuleApiAxiosParamCreator(configuration).v1OutgoingReportmoduleReportsGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Daha önce oluşturulmuş raporlarınızı bu uç ile indirebilirsiniz.
         * @summary Rapor indirir
         * @param {string} id **Rapor id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingReportmoduleReportsIdDownloadGet(requestParameters: V1OutgoingReportmoduleReportsIdDownloadGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ReportModuleApiAxiosParamCreator(configuration).v1OutgoingReportmoduleReportsIdDownloadGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Rapor oluşturmak için bu uç kullanılabilir.
         * @summary Rapor oluşturur
         * @param {ExcelReportCreateDto} [excelReportCreateDto] #### Rapor oluşturmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingReportmoduleReportsPost(requestParameters: V1OutgoingReportmoduleReportsPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExcelReportQueue>> {
            const localVarAxiosArgs = await ReportModuleApiAxiosParamCreator(configuration).v1OutgoingReportmoduleReportsPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Excel rapor modülünde kullanmak için oluşturduğunuz şablonlara bu uç ulaşabilirsiniz.
         * @summary Şablonları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [query] *query* parametresi ile arama yapabilirsiniz.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingReportmoduleTemplatesGet(requestParameters: V1OutgoingReportmoduleTemplatesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExcelReportTemplatePaginationResult>> {
            const localVarAxiosArgs = await ReportModuleApiAxiosParamCreator(configuration).v1OutgoingReportmoduleTemplatesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Kayıtlı rapor şablonunuzu silmek bu uç kullanılabilir.
         * @summary Rapor Şablonunu siler
         * @param {string} id **Silinecek şablon id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingReportmoduleTemplatesIdDelete(requestParameters: V1OutgoingReportmoduleTemplatesIdDeleteRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ReportModuleApiAxiosParamCreator(configuration).v1OutgoingReportmoduleTemplatesIdDelete(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sorgulanan şablonu getirir
         * @param {string} id **Şablon id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingReportmoduleTemplatesIdGet(requestParameters: V1OutgoingReportmoduleTemplatesIdGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExcelReportTemplate>> {
            const localVarAxiosArgs = await ReportModuleApiAxiosParamCreator(configuration).v1OutgoingReportmoduleTemplatesIdGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Daha önce oluşturulmuş olan rapor şablonunu güncellemek için bu uç kullanılabilir.
         * @summary Rapor şablonunu günceller
         * @param {string} id **Düzenlenecek şablon id**
         * @param {ExcelReportTemplateDto} excelReportTemplateDto #### Rapor şablonu güncellemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingReportmoduleTemplatesIdPut(requestParameters: V1OutgoingReportmoduleTemplatesIdPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExcelReportTemplate>> {
            const localVarAxiosArgs = await ReportModuleApiAxiosParamCreator(configuration).v1OutgoingReportmoduleTemplatesIdPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Yeni rapor şablonu oluşturmak için bu uç kullanılabilir.
         * @summary Rapor şablonu oluşturur
         * @param {ExcelReportTemplateDto} excelReportTemplateDto #### Rapor şablonu oluşturmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OutgoingReportmoduleTemplatesPost(requestParameters: V1OutgoingReportmoduleTemplatesPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExcelReportTemplate>> {
            const localVarAxiosArgs = await ReportModuleApiAxiosParamCreator(configuration).v1OutgoingReportmoduleTemplatesPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ReportModuleApi - factory interface
 * @export
 */
export const ReportModuleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Şablonlarda kullanılabilecek kolonların listesine bu uç ile ulaşabilirsiniz.
         * @summary Kolonları listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingReportmoduleColumnsGet(options?: any): AxiosPromise<Array<string>> {
            return ReportModuleApiFp(configuration).v1OutgoingReportmoduleColumnsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Daha önce oluşturulmuş raporlarınıza bu uç ile ulaşabilirsiniz.
         * @summary Rapor listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [query] *query* parametresi ile arama yapabilirsiniz.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingReportmoduleReportsGet(requestParameters: V1OutgoingReportmoduleReportsGetRequest, options?: any): AxiosPromise<ExcelReportQueuePaginationResult> {
            return ReportModuleApiFp(configuration).v1OutgoingReportmoduleReportsGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Daha önce oluşturulmuş raporlarınızı bu uç ile indirebilirsiniz.
         * @summary Rapor indirir
         * @param {string} id **Rapor id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingReportmoduleReportsIdDownloadGet(requestParameters: V1OutgoingReportmoduleReportsIdDownloadGetRequest, options?: any): AxiosPromise<void> {
            return ReportModuleApiFp(configuration).v1OutgoingReportmoduleReportsIdDownloadGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Rapor oluşturmak için bu uç kullanılabilir.
         * @summary Rapor oluşturur
         * @param {ExcelReportCreateDto} [excelReportCreateDto] #### Rapor oluşturmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingReportmoduleReportsPost(requestParameters: V1OutgoingReportmoduleReportsPostRequest, options?: any): AxiosPromise<ExcelReportQueue> {
            return ReportModuleApiFp(configuration).v1OutgoingReportmoduleReportsPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Excel rapor modülünde kullanmak için oluşturduğunuz şablonlara bu uç ulaşabilirsiniz.
         * @summary Şablonları listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [query] *query* parametresi ile arama yapabilirsiniz.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingReportmoduleTemplatesGet(requestParameters: V1OutgoingReportmoduleTemplatesGetRequest, options?: any): AxiosPromise<ExcelReportTemplatePaginationResult> {
            return ReportModuleApiFp(configuration).v1OutgoingReportmoduleTemplatesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Kayıtlı rapor şablonunuzu silmek bu uç kullanılabilir.
         * @summary Rapor Şablonunu siler
         * @param {string} id **Silinecek şablon id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingReportmoduleTemplatesIdDelete(requestParameters: V1OutgoingReportmoduleTemplatesIdDeleteRequest, options?: any): AxiosPromise<void> {
            return ReportModuleApiFp(configuration).v1OutgoingReportmoduleTemplatesIdDelete(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sorgulanan şablonu getirir
         * @param {string} id **Şablon id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingReportmoduleTemplatesIdGet(requestParameters: V1OutgoingReportmoduleTemplatesIdGetRequest, options?: any): AxiosPromise<ExcelReportTemplate> {
            return ReportModuleApiFp(configuration).v1OutgoingReportmoduleTemplatesIdGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Daha önce oluşturulmuş olan rapor şablonunu güncellemek için bu uç kullanılabilir.
         * @summary Rapor şablonunu günceller
         * @param {string} id **Düzenlenecek şablon id**
         * @param {ExcelReportTemplateDto} excelReportTemplateDto #### Rapor şablonu güncellemek için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingReportmoduleTemplatesIdPut(requestParameters: V1OutgoingReportmoduleTemplatesIdPutRequest, options?: any): AxiosPromise<ExcelReportTemplate> {
            return ReportModuleApiFp(configuration).v1OutgoingReportmoduleTemplatesIdPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Yeni rapor şablonu oluşturmak için bu uç kullanılabilir.
         * @summary Rapor şablonu oluşturur
         * @param {ExcelReportTemplateDto} excelReportTemplateDto #### Rapor şablonu oluşturmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OutgoingReportmoduleTemplatesPost(requestParameters: V1OutgoingReportmoduleTemplatesPostRequest, options?: any): AxiosPromise<ExcelReportTemplate> {
            return ReportModuleApiFp(configuration).v1OutgoingReportmoduleTemplatesPost(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportModuleApi - object-oriented interface
 * @export
 * @class ReportModuleApi
 * @extends {BaseAPI}
 */
export class ReportModuleApi extends BaseAPI {
    /**
     * Şablonlarda kullanılabilecek kolonların listesine bu uç ile ulaşabilirsiniz.
     * @summary Kolonları listeler
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportModuleApi
     */
    public v1OutgoingReportmoduleColumnsGet(options?: any) {
        return ReportModuleApiFp(this.configuration).v1OutgoingReportmoduleColumnsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Daha önce oluşturulmuş raporlarınıza bu uç ile ulaşabilirsiniz.
     * @summary Rapor listeler
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {string} [query] *query* parametresi ile arama yapabilirsiniz.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportModuleApi
     */
    public v1OutgoingReportmoduleReportsGet(requestParameters: V1OutgoingReportmoduleReportsGetRequest, options?: any) {
        return ReportModuleApiFp(this.configuration).v1OutgoingReportmoduleReportsGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Daha önce oluşturulmuş raporlarınızı bu uç ile indirebilirsiniz.
     * @summary Rapor indirir
     * @param {string} id **Rapor id**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportModuleApi
     */
    public v1OutgoingReportmoduleReportsIdDownloadGet(requestParameters: V1OutgoingReportmoduleReportsIdDownloadGetRequest, options?: any) {
        return ReportModuleApiFp(this.configuration).v1OutgoingReportmoduleReportsIdDownloadGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Rapor oluşturmak için bu uç kullanılabilir.
     * @summary Rapor oluşturur
     * @param {ExcelReportCreateDto} [excelReportCreateDto] #### Rapor oluşturmak için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportModuleApi
     */
    public v1OutgoingReportmoduleReportsPost(requestParameters: V1OutgoingReportmoduleReportsPostRequest, options?: any) {
        return ReportModuleApiFp(this.configuration).v1OutgoingReportmoduleReportsPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Excel rapor modülünde kullanmak için oluşturduğunuz şablonlara bu uç ulaşabilirsiniz.
     * @summary Şablonları listeler
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {string} [query] *query* parametresi ile arama yapabilirsiniz.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportModuleApi
     */
    public v1OutgoingReportmoduleTemplatesGet(requestParameters: V1OutgoingReportmoduleTemplatesGetRequest, options?: any) {
        return ReportModuleApiFp(this.configuration).v1OutgoingReportmoduleTemplatesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Kayıtlı rapor şablonunuzu silmek bu uç kullanılabilir.
     * @summary Rapor Şablonunu siler
     * @param {string} id **Silinecek şablon id**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportModuleApi
     */
    public v1OutgoingReportmoduleTemplatesIdDelete(requestParameters: V1OutgoingReportmoduleTemplatesIdDeleteRequest, options?: any) {
        return ReportModuleApiFp(this.configuration).v1OutgoingReportmoduleTemplatesIdDelete(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sorgulanan şablonu getirir
     * @param {string} id **Şablon id**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportModuleApi
     */
    public v1OutgoingReportmoduleTemplatesIdGet(requestParameters: V1OutgoingReportmoduleTemplatesIdGetRequest, options?: any) {
        return ReportModuleApiFp(this.configuration).v1OutgoingReportmoduleTemplatesIdGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Daha önce oluşturulmuş olan rapor şablonunu güncellemek için bu uç kullanılabilir.
     * @summary Rapor şablonunu günceller
     * @param {string} id **Düzenlenecek şablon id**
     * @param {ExcelReportTemplateDto} excelReportTemplateDto #### Rapor şablonu güncellemek için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportModuleApi
     */
    public v1OutgoingReportmoduleTemplatesIdPut(requestParameters: V1OutgoingReportmoduleTemplatesIdPutRequest, options?: any) {
        return ReportModuleApiFp(this.configuration).v1OutgoingReportmoduleTemplatesIdPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Yeni rapor şablonu oluşturmak için bu uç kullanılabilir.
     * @summary Rapor şablonu oluşturur
     * @param {ExcelReportTemplateDto} excelReportTemplateDto #### Rapor şablonu oluşturmak için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportModuleApi
     */
    public v1OutgoingReportmoduleTemplatesPost(requestParameters: V1OutgoingReportmoduleTemplatesPostRequest, options?: any) {
        return ReportModuleApiFp(this.configuration).v1OutgoingReportmoduleTemplatesPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1OutgoingReportmoduleReportsGetRequest {
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    /**
     * *query* parametresi ile arama yapabilirsiniz.
     */
    query?: string;
    }
    export interface V1OutgoingReportmoduleReportsIdDownloadGetRequest {
    /**
     * **Rapor id**
     */
    id: string;
    }
    export interface V1OutgoingReportmoduleReportsPostRequest {
    /**
     * #### Rapor oluşturmak için gönderilmesi gereken model
     */
    excelReportCreateDto?: ExcelReportCreateDto;
    }
    export interface V1OutgoingReportmoduleTemplatesGetRequest {
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    /**
     * *query* parametresi ile arama yapabilirsiniz.
     */
    query?: string;
    }
    export interface V1OutgoingReportmoduleTemplatesIdDeleteRequest {
    /**
     * **Silinecek şablon id**
     */
    id: string;
    }
    export interface V1OutgoingReportmoduleTemplatesIdGetRequest {
    /**
     * **Şablon id**
     */
    id: string;
    }
    export interface V1OutgoingReportmoduleTemplatesIdPutRequest {
    /**
     * **Düzenlenecek şablon id**
     */
    id: string;
    /**
     * #### Rapor şablonu güncellemek için gönderilmesi gereken model
     */
    excelReportTemplateDto: ExcelReportTemplateDto;
    }
    export interface V1OutgoingReportmoduleTemplatesPostRequest {
    /**
     * #### Rapor şablonu oluşturmak için gönderilmesi gereken model
     */
    excelReportTemplateDto: ExcelReportTemplateDto;
    }
