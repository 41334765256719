// tslint:disable
// eslint:disable
/**
 * NES Identity Server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ActionResultModelBase } from '../interfaces';
// @ts-ignore
import { PatDeleteRequest } from '../interfaces';
// @ts-ignore
import { PatDto } from '../interfaces';
// @ts-ignore
import { PersistedGrant } from '../interfaces';
// @ts-ignore
import { ValidationActionResultModel } from '../interfaces';
/**
 * PersistedGrantApi - axios parameter creator
 * @export
 */
export const PersistedGrantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Erişim anahtarını siler
         * @param {PatDeleteRequest} patDeleteRequest model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatDelete: async (requestParameters: V1PatDeleteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'patDeleteRequest' is not null or undefined
            if (requestParameters.patDeleteRequest === null || requestParameters.patDeleteRequest === undefined) {
                throw new RequiredError('patDeleteRequest','Required parameter patDeleteRequest was null or undefined when calling v1PatDelete.');
            }
            const localVarPath = `/v1/pat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.patDeleteRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.patDeleteRequest !== undefined ? requestParameters.patDeleteRequest : {}) : (requestParameters.patDeleteRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tüm erişim anahtarlarını siler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatDeleteallGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/pat/deleteall`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Erişim anahtarları dönülür
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/pat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Erişim anahtarı oluşturur
         * @param {PatDto} [patDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatPost: async (requestParameters: V1PatPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/pat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.patDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.patDto !== undefined ? requestParameters.patDto : {}) : (requestParameters.patDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersistedGrantApi - functional programming interface
 * @export
 */
export const PersistedGrantApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Erişim anahtarını siler
         * @param {PatDeleteRequest} patDeleteRequest model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatDelete(requestParameters: V1PatDeleteRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PersistedGrantApiAxiosParamCreator(configuration).v1PatDelete(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Tüm erişim anahtarlarını siler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatDeleteallGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PersistedGrantApiAxiosParamCreator(configuration).v1PatDeleteallGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Erişim anahtarları dönülür
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PersistedGrant>>> {
            const localVarAxiosArgs = await PersistedGrantApiAxiosParamCreator(configuration).v1PatGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Erişim anahtarı oluşturur
         * @param {PatDto} [patDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatPost(requestParameters: V1PatPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await PersistedGrantApiAxiosParamCreator(configuration).v1PatPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PersistedGrantApi - factory interface
 * @export
 */
export const PersistedGrantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Erişim anahtarını siler
         * @param {PatDeleteRequest} patDeleteRequest model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatDelete(requestParameters: V1PatDeleteRequest, options?: any): AxiosPromise<void> {
            return PersistedGrantApiFp(configuration).v1PatDelete(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tüm erişim anahtarlarını siler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatDeleteallGet(options?: any): AxiosPromise<void> {
            return PersistedGrantApiFp(configuration).v1PatDeleteallGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Erişim anahtarları dönülür
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatGet(options?: any): AxiosPromise<Array<PersistedGrant>> {
            return PersistedGrantApiFp(configuration).v1PatGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Erişim anahtarı oluşturur
         * @param {PatDto} [patDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatPost(requestParameters: V1PatPostRequest, options?: any): AxiosPromise<string> {
            return PersistedGrantApiFp(configuration).v1PatPost(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PersistedGrantApi - object-oriented interface
 * @export
 * @class PersistedGrantApi
 * @extends {BaseAPI}
 */
export class PersistedGrantApi extends BaseAPI {
    /**
     * 
     * @summary Erişim anahtarını siler
     * @param {PatDeleteRequest} patDeleteRequest model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersistedGrantApi
     */
    public v1PatDelete(requestParameters: V1PatDeleteRequest, options?: any) {
        return PersistedGrantApiFp(this.configuration).v1PatDelete(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tüm erişim anahtarlarını siler
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersistedGrantApi
     */
    public v1PatDeleteallGet(options?: any) {
        return PersistedGrantApiFp(this.configuration).v1PatDeleteallGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Erişim anahtarları dönülür
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersistedGrantApi
     */
    public v1PatGet(options?: any) {
        return PersistedGrantApiFp(this.configuration).v1PatGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Erişim anahtarı oluşturur
     * @param {PatDto} [patDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersistedGrantApi
     */
    public v1PatPost(requestParameters: V1PatPostRequest, options?: any) {
        return PersistedGrantApiFp(this.configuration).v1PatPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1PatDeleteRequest {
    /**
     * model
     */
    patDeleteRequest: PatDeleteRequest;
    }
    export interface V1PatPostRequest {
    /**
     * 
     */
    patDto?: PatDto;
    }
