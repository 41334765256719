//TODO sourceAppRecordId zorunlu bir alan değil undefined geçilebilir olmalı
import { UploadDocumentPreviewType } from '@/apis/earchive/interfaces';
import { V1UploadsDocumentPostRequest } from '@/apis/einvoice';
import { UploadDocumentResponse } from '@/apis/einvoice/interfaces';
import { AccountManagementApiFactory, AliasesApiFactory, V1EinvoiceAliasesAliasTypeGetRequest } from '@/apis/general';
import { AccountModuleAlias, AliasTypeParameter } from '@/apis/general/interfaces';
import { Breadcrumbs, DangerToastify } from '@/components';
import SelectableBaseTable, { CellRendererProps } from '@/components/SelectableBaseTable';
import { PageConst, PageStyle } from '@/constants/page';
import { useApi } from '@/hooks';
import { AnyType } from '@/type';
import { updateItemById } from '@/utils/array';
import { CheckCircleTwoTone, ClockCircleTwoTone } from '@ant-design/icons';
import { faBan, faFile, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Modal, Progress, Row, Upload } from 'antd';
import { UploadFileStatus } from 'antd/lib/upload/interface';
import { AxiosPromise } from 'axios';
import { Formik, FormikProps } from 'formik';
import { Form, FormItem, Radio, Select } from 'formik-antd';
import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import intl from 'react-intl-universal';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { Delete, UploadFile } from '../Buttons';
import { ErrorViewer } from '../TableBody';

type TableRowProps = CellRendererProps<UploadFileObject, Array<UploadFileObject>>;

interface UploadFileObject {
  uid: string;
  size: number;
  name: string;
  fileName?: string;
  lastModified?: number;
  lastModifiedDate?: Date;
  url?: string;
  status?: UploadFileStatus;
  percent?: number;
  thumbUrl?: string;
  originFileObj?: File | Blob;
  error?: AnyType;
  type: string;
  isDirectSend: boolean;
  sourceApp: string;
  sourceAppRecordId: string;
  senderAlias: string;
}
const MultipleDocumentUploaderModalScheme = Yup.object().shape<{ senderAlias: string; isDirectSend: boolean }>({
  senderAlias: Yup.string().nullable().required(),
  isDirectSend: Yup.boolean().nullable().required()
});
export const EinvoiceDocuments = ({
  onLoad,
  readType = 'asBinaryString',
  post,
  title,
  extensions,
  isEDespatch
}: {
  onLoad?: (file: AnyType, content: string | ArrayBuffer | null) => void;
  extensions?: string;
  readType?: 'asArrayBuffer' | 'asBinaryString' | 'asBase64';
  title?: string;
  post: (requestParameters: V1UploadsDocumentPostRequest, options: any) => AxiosPromise<UploadDocumentResponse>;
  isEDespatch: boolean;
}) => {
  const [files, setFiles] = useState<UploadFileObject[]>([]);
  let form = (useRef(null) as unknown) as FormikProps<{ senderAlias: string; isDirectSend: boolean }>;

  // get Aliases
  const { call, data, loading, error } = useApi<Array<AccountModuleAlias>, V1EinvoiceAliasesAliasTypeGetRequest>({
    asyncFunction: isEDespatch ? AliasesApiFactory().v1EdespatchAliasesAliasTypeGet : AliasesApiFactory().v1EinvoiceAliasesAliasTypeGet
  });

  const handleOnChange = (info) => {
    if (extensions) {
      const extension = (info.file.name as string).substr(info.file.name.lastIndexOf('.'));
      if (extensions.indexOf(extension) === -1) {
        DangerToastify(`Yalnızca ${extensions} tipinde dosyalar yüklenebilir,(${extension}) tipi yüklenemez!`);
        return false;
      }
    }
    if (info.fileList.length === 0 || !form.isValid) {
      onLoad && onLoad(null, null);
      setFiles([]);
      return false;
    }
    let fileList: UploadFileObject[] = [];
    fileList = info.fileList.map(
      (file, index): UploadFileObject => {
        if (index >= files.length)
          return {
            ...file,
            isDirectSend: form.values.isDirectSend,
            senderAlias: form.values.senderAlias,
            sourceApp: 'NES_PORTAL',
            sourceAppRecordId: uuidv4()
          };
        else return file;
      }
    );
    setFiles([...fileList]);

    const reader = new FileReader();
    reader.onload = (e) => {
      onLoad && onLoad(info.file, (e.target as AnyType).result);
    };
    switch (readType) {
      case 'asArrayBuffer':
        reader.readAsArrayBuffer(info.file);
        break;
      case 'asBase64':
        reader.readAsDataURL(info.file);
        break;
      case 'asBinaryString':
        break;
    }
    return false;
  };
  async function uploadFiles() {
    for (const file of files.filter((x) => x.status === undefined)) {
      await post(
        {
          senderAlias: file.senderAlias,
          file: file.originFileObj,
          isDirectSend: file.isDirectSend,
          sourceApp: file.sourceApp,
          sourceAppRecordId: file.sourceAppRecordId,
          previewType: UploadDocumentPreviewType.None,
          autoSaveCompany: false
        },
        {
          onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setFiles(updateItemById<UploadFileObject>(files, file.uid, { percent: percentCompleted, status: 'uploading' }, (item) => item.uid));
          }
        }
      ).then((response: AnyType) => {
        if (response.isAxiosError) {
          setFiles(updateItemById<UploadFileObject>(files, file.uid, { percent: 0, status: 'error', error: response }, (item) => item.uid));
        } else {
          setFiles(updateItemById<UploadFileObject>(files, file.uid, { percent: 100, status: 'done' }, (item) => item.uid));
        }
      });
    }
  }

  return (
    <>
      <Helmet title={title} />
      <Card className="h-100">
        <CardHeader className={PageStyle.card__header}>
          <Breadcrumbs />
          <div>
            <Upload
              accept={extensions}
              beforeUpload={() => false}
              onChange={(info) => {
                form.submitForm();
                handleOnChange(info);
              }}
              fileList={files}
              multiple={true}
              showUploadList={false}
            >
              <Button size="md" color={'info'} className="btn-labeled">
                <FontAwesomeIcon icon={faTimes} className={'btn-label'} />
                <span>{files.length !== 0 ? `${files.length} dosya seçildi` : 'Dosya Ekle'}</span>
              </Button>
            </Upload>{' '}
            <UploadFile onClick={() => uploadFiles()} disabled={files.length === 0 ? true : false} />
          </div>
        </CardHeader>
        <CardBody className={PageStyle.virtualized__card__body}>
          <Formik<{ senderAlias: string; isDirectSend: boolean }>
            isInitialValid={false}
            validateOnMount={true}
            innerRef={(instance) => (form = instance)}
            validationSchema={MultipleDocumentUploaderModalScheme}
            onSubmit={async () => Promise.resolve()}
            initialValues={{
              isDirectSend: true,
              senderAlias: ''
            }}
          >
            <Form layout="vertical">
              <Row gutter={[15, PageConst.ColGutter]}>
                <Col md={8}>
                  <FormItem name="senderAlias" label={intl.get('GONDERIM_ETIKETI')} required>
                    <Select
                      name="senderAlias"
                      placeholder={'Seçiniz.'}
                      loading={loading}
                      onDropdownVisibleChange={(value) => {
                        if (value) {
                          call({ aliasType: AliasTypeParameter.Gb });
                        }
                      }}
                    >
                      {data &&
                        data.map((item) => (
                          <Select.Option key={item.id} value={item.alias as string}>
                            {item.alias}
                          </Select.Option>
                        ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col md={10}>
                  <FormItem name="isDirectSend" label={intl.get('GONDERIM_TERCIHI')} required>
                    <Radio.Group name="isDirectSend">
                      <Radio name="isDirectSend" value={true}>
                        {intl.get('HEMEN_GONDER')}
                      </Radio>
                      <Radio name="isDirectSend" value={false}>
                        {intl.get('TASLAK_OLARAK_GONDER')}
                      </Radio>
                    </Radio.Group>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Formik>
          <Row className="flex-fill">
            <SelectableBaseTable
              key="uploadfiles-table"
              fixed
              pageData={files}
              data={files.map(({ name, uid, status, percent, error, isDirectSend, sourceApp, senderAlias }: UploadFileObject) => {
                return {
                  name,
                  error,
                  status,
                  percent,
                  uid,
                  isDirectSend,
                  senderAlias,
                  sourceApp
                };
              })}
              rowKey="uid"
              notSelectable
              columns={[
                {
                  key: `name`,
                  title: intl.get('BELGE_BILGILERI'),
                  width: 300,
                  minWidth: 300,
                  className: 'base-table-flex',
                  headerClassName: 'base-table-flex',
                  cellRenderer: ({ rowData }: TableRowProps) => {
                    return (
                      <div>
                        <FontAwesomeIcon icon={faFile} className={'mr-2'} />
                        <b>{rowData.name}</b>
                      </div>
                    );
                  }
                },
                {
                  key: `isDirectSend`,
                  title: intl.get('GONDERICI_TERCIHI'),
                  width: 150,
                  minWidth: 150,
                  cellRenderer: ({ rowData }: TableRowProps) => {
                    return (
                      <div>
                        {rowData.isDirectSend === true ? <CheckCircleTwoTone twoToneColor={'#52c41a'} /> : <ClockCircleTwoTone twoToneColor={'#ff0000'} />}
                        <span> {rowData.isDirectSend === true ? intl.get('HEMEN_GONDER') : intl.get('HEMEN_GONDERME')} </span>
                      </div>
                    );
                  }
                },
                {
                  key: `progress`,
                  title: intl.get('YUKLEME_DURUMU'),
                  width: 350,
                  minWidth: 350,
                  cellRenderer: ({ rowData }: AnyType) => {
                    if (rowData.error) {
                      return (
                        <div
                          onClick={() =>
                            Modal.error({
                              centered: true,
                              content: <ErrorViewer error={rowData.error} />,
                              icon: false,
                              width: 720,
                              okText: (
                                <>
                                  <FontAwesomeIcon icon={faTimes} className="btn-label" />
                                  <span>{intl.get('KAPAT')}</span>
                                </>
                              ),
                              okButtonProps: {
                                className: 'btn-danger btn-labeled btn-sm',
                                prefix: 'btn',
                                prefixCls: 'btn'
                              }
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faBan} color="red" className="mr-2" />
                          <span style={{ color: 'red', cursor: 'pointer' }}> Hata detayı için tıklayınız</span>
                        </div>
                      );
                    } else {
                      return <Progress percent={rowData.percent} status={rowData.status === 'error' ? 'exception' : undefined} style={{ padding: '10px' }} />;
                    }
                  }
                },
                {
                  key: `delete`,
                  title: intl.get('SIL'),
                  width: 60,
                  minWidth: 60,
                  align: 'center',
                  cellRenderer: ({
                    rowData,
                    container: {
                      props: { pageData }
                    }
                  }: TableRowProps) => {
                    return <Delete onlyIcon onClick={() => setFiles((pageData as AnyType).filter((item) => item.uid !== rowData.uid))} />;
                  }
                }
              ]}
            />
          </Row>
          {error && <ErrorViewer error={error} />}
        </CardBody>
      </Card>
    </>
  );
};

export default EinvoiceDocuments;
