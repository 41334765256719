import { BootstrapVariant } from '@/constants/enums';
import React, { memo } from 'react';

//TODO : Api model DespatchType Enum
export const DespatchTypeLabel = memo(({ despatchTypeParameter }: { despatchTypeParameter: string }) => {
  let color: BootstrapVariant = BootstrapVariant.primary;

  switch (despatchTypeParameter) {
    case 'All':
      color = BootstrapVariant.success;
      break;
    case 'SEVK':
      color = BootstrapVariant.danger;
      break;
    case 'MATBUDAN':
      color = BootstrapVariant.mint;
      break;
  }

  return <label className={`label label-${color}`}>{despatchTypeParameter}</label>;
});

export default DespatchTypeLabel;
