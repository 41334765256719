// tslint:disable
// eslint:disable
/**
 * E-Invoice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum InvoiceTypeCode {
    Unknown = 'Unknown',
    SATIS = 'SATIS',
    IADE = 'IADE',
    TEVKIFAT = 'TEVKIFAT',
    TEVKIFATIADE = 'TEVKIFATIADE',
    ISTISNA = 'ISTISNA',
    IHRACKAYITLI = 'IHRACKAYITLI',
    OZELMATRAH = 'OZELMATRAH',
    KOMISYONCU = 'KOMISYONCU',
    SGK = 'SGK',
    KONAKLAMAVERGISI = 'KONAKLAMAVERGISI',
    HKSKOMISYONCU = 'HKSKOMISYONCU'
}



