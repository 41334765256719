import { V1NotificationsIncomingDynamicrulesIdDeleteRequest } from '@/apis/edespatch';
import {
  V1NotificationsIncomingDynamicrulesIdGetRequest,
  V1NotificationsIncomingDynamicrulesIdPutRequest,
  V1NotificationsIncomingDynamicrulesPostRequest
} from '@/apis/einvoice';
import { DynamicNotificationRuleDto, DynamicNotificationRuleListModelPaginationResult, DynamicNotificationRuleModel } from '@/apis/einvoice/interfaces';
import { Breadcrumbs, InfoToastify, RootSpinner } from '@/components';
import { New, Preview, ProcessDropdownButton, Search } from '@/components/Buttons';
import { Delete, Edit } from '@/components/DropdownItems';
import SelectableBaseTable, { CellRendererProps } from '@/components/SelectableBaseTable';
import { PageStyle } from '@/constants/page';
import { useApi, useApiInConfirmation, useGetPageData } from '@/hooks';
import { DynamicNotification } from '@/interfaces';
import { AnyType } from '@/type';
import { Menu, Row } from 'antd';
import { AxiosPromise } from 'axios';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import intl from 'react-intl-universal';
import { Card, CardBody, CardHeader } from 'reactstrap';
import LocalPagination from '../Pagination/LocalPagination';
import PreviewRuleDetailModal from './PreviewRuleDetailModal';
import UpsertRuleModal, { RuleDetailsModalProps } from './UpsertRuleModal';

type TableRowProps = CellRendererProps<DynamicNotificationRuleModel, Array<DynamicNotificationRuleModel>>;

interface DynamicNotificationRuleDetailGetAdditionalProps {
  type: 'Edit' | 'Preview';
}

export const Notification = ({
  put,
  post,
  deleteFn,
  title,
  getRules,
  getRulesById,
  properties
}: {
  title?: string;
  getRules: (options: AnyType) => AxiosPromise<DynamicNotificationRuleListModelPaginationResult>;
  getRulesById: (requestParameters: V1NotificationsIncomingDynamicrulesIdGetRequest) => AxiosPromise<DynamicNotificationRuleModel>;
  deleteFn: (requestParameters: V1NotificationsIncomingDynamicrulesIdDeleteRequest) => AxiosPromise<void>;
  post: (requestParameters: V1NotificationsIncomingDynamicrulesPostRequest) => AxiosPromise<DynamicNotificationRuleDto>;
  put: (requestParameters: V1NotificationsIncomingDynamicrulesIdPutRequest) => AxiosPromise<void>;
  properties: Array<DynamicNotification>;
}) => {
  const [upsertRuleModalState, setUpsertRuleModalState] = useState<RuleDetailsModalProps>({
    visible: false,
    rules: undefined
  });

  const [previewRuleDetailModalState, setPreviewRuleDetailModalState] = useState<RuleDetailsModalProps>({
    visible: false,
    rules: undefined
  });

  /*
   *  Geçerli kural silinir.
   */
  const deleteRule = useApiInConfirmation<V1NotificationsIncomingDynamicrulesIdDeleteRequest>({
    asyncFunction: deleteFn
  });

  /*
   * Kualın detayına detayına bu uç ile ulaşabilirsiniz.
   */
  const getRulesByIdRequest = useApi<
    DynamicNotificationRuleModel,
    V1NotificationsIncomingDynamicrulesIdGetRequest,
    DynamicNotificationRuleDetailGetAdditionalProps
  >({
    asyncFunction: getRulesById,
    successCallback: (response, _, addtionalProps) => {
      addtionalProps?.type === 'Edit'
        ? setUpsertRuleModalState({ visible: true, rules: response.data })
        : setPreviewRuleDetailModalState({ visible: true, rules: response.data });
    }
  });

  const [requestParameter, setRequestParameter] = useState({ page: 1, pageSize: 10, sort: 'CreatedAt desc' });

  /*
   *  Kural listesini getirir
   */
  const { execute, loading, data, error } = useGetPageData<DynamicNotificationRuleListModelPaginationResult>(getRules, requestParameter, true);

  return (
    <RootSpinner loading={getRulesByIdRequest.loading || loading}>
      <UpsertRuleModal
        closeModal={() => setUpsertRuleModalState({ visible: false, rules: undefined })}
        refresh={execute}
        post={post}
        put={put}
        modalState={upsertRuleModalState}
        properties={properties}
      />

      <PreviewRuleDetailModal
        closeModal={() => setPreviewRuleDetailModalState({ visible: false, rules: undefined })}
        modalState={previewRuleDetailModalState}
        properties={properties}
      />

      <Helmet title={title} />
      <Card className="h-100">
        <CardHeader className={PageStyle.card__header}>
          <Breadcrumbs />
          <div>
            <New onClick={() => setUpsertRuleModalState({ visible: true, rules: undefined })} /> <Search onClick={() => execute()} />
          </div>
        </CardHeader>
        <CardBody className={PageStyle.virtualized__card__body}>
          <Row className="flex-fill">
            <SelectableBaseTable
              key="uploadfiles-table"
              fixed
              pageData={data}
              data={(data && data.data) || []}
              error={error || getRulesByIdRequest.error}
              rowKey={'id'}
              notSelectable
              columns={[
                {
                  minWidth: 40,
                  width: 40,
                  align: 'center',
                  cellRenderer: ({ rowData }: TableRowProps) => {
                    return <Preview title={'Kural Detayları'} onClick={() => getRulesByIdRequest.call({ id: rowData.id }, { type: 'Preview' })} />;
                  }
                },
                {
                  key: `ruleName`,
                  title: intl.get('KURAL_ADI'),
                  width: 400,
                  minWidth: 400,
                  className: 'base-table-flex',
                  headerClassName: 'base-table-flex',
                  cellRenderer: ({ rowData }: TableRowProps) => {
                    return <div>{rowData.ruleName}</div>;
                  }
                },
                {
                  key: `ruleReceiver`,
                  title: intl.get('ALICI_BILGILERI'),
                  width: 350,
                  minWidth: 350,
                  cellRenderer: ({ rowData }: AnyType) => {
                    if (rowData.receivers.length > 0)
                      return (
                        <div>
                          <div>
                            <strong>Birincil Alıcı : </strong>
                            {rowData.receivers[0].receiverValue}
                          </div>
                          <div>
                            <strong>Alıcı Sayısı : </strong>
                            {rowData.receivers.length}
                          </div>
                        </div>
                      );
                  }
                },
                {
                  key: `process`,
                  title: intl.get('ISLEMLER'),
                  minWidth: 75,
                  width: 75,
                  align: 'center',
                  cellRenderer: ({
                    rowData,
                    container: {
                      props: { pageData }
                    }
                  }: TableRowProps) => {
                    return (
                      <ProcessDropdownButton
                        isRow
                        menu={
                          <Menu>
                            <Edit
                              onClick={() => {
                                getRulesByIdRequest.call({ id: rowData.id }, { type: 'Edit' });
                              }}
                            />
                            <Delete
                              onClick={() =>
                                deleteRule.confirm(
                                  { id: rowData.id },
                                  {
                                    content: intl.getHTML('SERI_SILINDI_OLARAK_ISARETLENECEKTIR_DEVAM_EDILSIN_MI', { serie: rowData.ruleName }),
                                    title: `Seri Pasif Et - ${rowData.ruleName} - ${rowData.id}`,
                                    successCallback: () => {
                                      execute();
                                      InfoToastify(intl.getHTML('SERI_SILINDI_OLARAK_ISARETLENDI', { serie: rowData.ruleName }));
                                    }
                                  }
                                )
                              }
                            />
                          </Menu>
                        }
                      />
                    );
                  }
                }
              ]}
            />
          </Row>
        </CardBody>
        {data && data.data && data.data.length > 0 && (
          <LocalPagination
            data={data}
            selectedCount={0}
            execute={execute}
            page={requestParameter.page}
            pageSize={requestParameter.pageSize}
            onPageChange={(page, pageSize) => {
              setRequestParameter({ ...requestParameter, page, pageSize });
            }}
          />
        )}
      </Card>
    </RootSpinner>
  );
};

export default Notification;
