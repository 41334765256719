import { Option } from '@/interfaces';
import { Select } from 'antd';
import React from 'react';

export const FormSelect = (props: {
  showSearch?: boolean;
  options: Option[];
  name?: string;
  onChange: (value: any) => void;
  onInputKeyDown?: any;
  disabled?: boolean;
}) => {
  const { options, ...ownProps } = props;

  return (
    <Select {...ownProps} optionFilterProp={'children'} className="w-100">
      {options.map(({ text, value }) => (
        <Select.Option key={value} value={value}>
          {text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default FormSelect;
