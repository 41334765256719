// tslint:disable
// eslint:disable
/**
 * E-MM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DocumentTemplateCustomizationSetting } from '../interfaces';
// @ts-ignore
import { DocumentTemplateCustomizationSettingDto } from '../interfaces';
/**
 * DocumentTemplateCustomizationSettingsApi - axios parameter creator
 * @export
 */
export const DocumentTemplateCustomizationSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tasarım ayarları dönülür
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsGet: async (requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsGetRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/definitions/documenttemplates/customizationsettings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.query !== undefined) {
                localVarQueryParameter['query'] = requestParameters.query;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarımını silmek için kullanılır.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdDelete: async (requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdDeleteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DefinitionsDocumenttemplatesCustomizationsettingsIdDelete.');
            }
            const localVarPath = `/v1/definitions/documenttemplates/customizationsettings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sorgulanan ayarı getirir
         * @param {string} id **Kayıt kimliği (Id)**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdGet: async (requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DefinitionsDocumenttemplatesCustomizationsettingsIdGet.');
            }
            const localVarPath = `/v1/definitions/documenttemplates/customizationsettings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma eklenmiş olan logoyu silmek için bu uç kullanılabilir.
         * @param {string} id **Tasarım kimliği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoDelete: async (requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoDeleteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoDelete.');
            }
            const localVarPath = `/v1/definitions/documenttemplates/customizationsettings/{id}/logo`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma eklenmiş olan logoya bu uç ile ulaşılabilir.
         * @param {string} id **Tasarım kimliği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoGet: async (requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoGet.');
            }
            const localVarPath = `/v1/definitions/documenttemplates/customizationsettings/{id}/logo`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma logo eklemek için bu uç kullanılabilir.
         * @param {string} id 
         * @param {any} file **Dosya içeriği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoPost: async (requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoPost.');
            }
            // verify required parameter 'file' is not null or undefined
            if (requestParameters.file === null || requestParameters.file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoPost.');
            }
            const localVarPath = `/v1/definitions/documenttemplates/customizationsettings/{id}/logo`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


                if (requestParameters.file !== undefined) { 
                    localVarFormParams.append('File', requestParameters.file as any);
                }

    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tasarımı örnek bir belgeyle önizlemek için uç kullanılabilir.
         * @summary Tasarımı önizler
         * @param {string} id **Tasarım id**
         * @param {any} jsonContent 
         * @param {any} [ublXmlFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdPreviewPost: async (requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdPreviewPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DefinitionsDocumenttemplatesCustomizationsettingsIdPreviewPost.');
            }
            // verify required parameter 'jsonContent' is not null or undefined
            if (requestParameters.jsonContent === null || requestParameters.jsonContent === undefined) {
                throw new RequiredError('jsonContent','Required parameter jsonContent was null or undefined when calling v1DefinitionsDocumenttemplatesCustomizationsettingsIdPreviewPost.');
            }
            const localVarPath = `/v1/definitions/documenttemplates/customizationsettings/{id}/preview`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


                if (requestParameters.ublXmlFile !== undefined) { 
                    localVarFormParams.append('ublXmlFile', requestParameters.ublXmlFile as any);
                }

                if (requestParameters.jsonContent !== undefined) { 
                    localVarFormParams.append('jsonContent', requestParameters.jsonContent as any);
                }

    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarımını güncellemek için kullanılır.
         * @param {string} id 
         * @param {DocumentTemplateCustomizationSettingDto} documentTemplateCustomizationSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdPut: async (requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DefinitionsDocumenttemplatesCustomizationsettingsIdPut.');
            }
            // verify required parameter 'documentTemplateCustomizationSettingDto' is not null or undefined
            if (requestParameters.documentTemplateCustomizationSettingDto === null || requestParameters.documentTemplateCustomizationSettingDto === undefined) {
                throw new RequiredError('documentTemplateCustomizationSettingDto','Required parameter documentTemplateCustomizationSettingDto was null or undefined when calling v1DefinitionsDocumenttemplatesCustomizationsettingsIdPut.');
            }
            const localVarPath = `/v1/definitions/documenttemplates/customizationsettings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.documentTemplateCustomizationSettingDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.documentTemplateCustomizationSettingDto !== undefined ? requestParameters.documentTemplateCustomizationSettingDto : {}) : (requestParameters.documentTemplateCustomizationSettingDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdSetdefaultGet: async (requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdSetdefaultGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DefinitionsDocumenttemplatesCustomizationsettingsIdSetdefaultGet.');
            }
            const localVarPath = `/v1/definitions/documenttemplates/customizationsettings/{id}/setdefault`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma eklenmiş olan imzayı silmek için bu uç kullanılabilir.
         * @param {string} id **Tasarım kimliği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureDelete: async (requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureDeleteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureDelete.');
            }
            const localVarPath = `/v1/definitions/documenttemplates/customizationsettings/{id}/signature`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma eklenmiş olan imzaya bu uç ile ulaşılabilir.
         * @param {string} id **Tasarım kimliği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureGet: async (requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureGet.');
            }
            const localVarPath = `/v1/definitions/documenttemplates/customizationsettings/{id}/signature`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma imza eklemek için bu uç kullanılabilir.
         * @param {string} id 
         * @param {any} file **Dosya içeriği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignaturePost: async (requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdSignaturePostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignaturePost.');
            }
            // verify required parameter 'file' is not null or undefined
            if (requestParameters.file === null || requestParameters.file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignaturePost.');
            }
            const localVarPath = `/v1/definitions/documenttemplates/customizationsettings/{id}/signature`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


                if (requestParameters.file !== undefined) { 
                    localVarFormParams.append('File', requestParameters.file as any);
                }

    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma eklenmiş olan kaşeyi silmek için bu uç kullanılabilir.
         * @param {string} id **Tasarım kimliği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampDelete: async (requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdStampDeleteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampDelete.');
            }
            const localVarPath = `/v1/definitions/documenttemplates/customizationsettings/{id}/stamp`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma eklenmiş olan kaşeye bu uç ile ulaşılabilir.
         * @param {string} id **Tasarım kimliği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampGet: async (requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdStampGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampGet.');
            }
            const localVarPath = `/v1/definitions/documenttemplates/customizationsettings/{id}/stamp`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma kaşe eklemek için bu uç kullanılabilir.
         * @param {string} id 
         * @param {any} file **Dosya içeriği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampPost: async (requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdStampPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampPost.');
            }
            // verify required parameter 'file' is not null or undefined
            if (requestParameters.file === null || requestParameters.file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampPost.');
            }
            const localVarPath = `/v1/definitions/documenttemplates/customizationsettings/{id}/stamp`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


                if (requestParameters.file !== undefined) { 
                    localVarFormParams.append('File', requestParameters.file as any);
                }

    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarım eklemek için kullanılır.
         * @param {DocumentTemplateCustomizationSettingDto} documentTemplateCustomizationSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsPost: async (requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentTemplateCustomizationSettingDto' is not null or undefined
            if (requestParameters.documentTemplateCustomizationSettingDto === null || requestParameters.documentTemplateCustomizationSettingDto === undefined) {
                throw new RequiredError('documentTemplateCustomizationSettingDto','Required parameter documentTemplateCustomizationSettingDto was null or undefined when calling v1DefinitionsDocumenttemplatesCustomizationsettingsPost.');
            }
            const localVarPath = `/v1/definitions/documenttemplates/customizationsettings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.documentTemplateCustomizationSettingDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.documentTemplateCustomizationSettingDto !== undefined ? requestParameters.documentTemplateCustomizationSettingDto : {}) : (requestParameters.documentTemplateCustomizationSettingDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentTemplateCustomizationSettingsApi - functional programming interface
 * @export
 */
export const DocumentTemplateCustomizationSettingsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tasarım ayarları dönülür
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsDocumenttemplatesCustomizationsettingsGet(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentTemplateCustomizationSetting>>> {
            const localVarAxiosArgs = await DocumentTemplateCustomizationSettingsApiAxiosParamCreator(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarımını silmek için kullanılır.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsDocumenttemplatesCustomizationsettingsIdDelete(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdDeleteRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DocumentTemplateCustomizationSettingsApiAxiosParamCreator(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdDelete(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sorgulanan ayarı getirir
         * @param {string} id **Kayıt kimliği (Id)**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsDocumenttemplatesCustomizationsettingsIdGet(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTemplateCustomizationSetting>> {
            const localVarAxiosArgs = await DocumentTemplateCustomizationSettingsApiAxiosParamCreator(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma eklenmiş olan logoyu silmek için bu uç kullanılabilir.
         * @param {string} id **Tasarım kimliği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoDelete(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoDeleteRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DocumentTemplateCustomizationSettingsApiAxiosParamCreator(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoDelete(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma eklenmiş olan logoya bu uç ile ulaşılabilir.
         * @param {string} id **Tasarım kimliği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoGet(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DocumentTemplateCustomizationSettingsApiAxiosParamCreator(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma logo eklemek için bu uç kullanılabilir.
         * @param {string} id 
         * @param {any} file **Dosya içeriği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoPost(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DocumentTemplateCustomizationSettingsApiAxiosParamCreator(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Tasarımı örnek bir belgeyle önizlemek için uç kullanılabilir.
         * @summary Tasarımı önizler
         * @param {string} id **Tasarım id**
         * @param {any} jsonContent 
         * @param {any} [ublXmlFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsDocumenttemplatesCustomizationsettingsIdPreviewPost(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdPreviewPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DocumentTemplateCustomizationSettingsApiAxiosParamCreator(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdPreviewPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarımını güncellemek için kullanılır.
         * @param {string} id 
         * @param {DocumentTemplateCustomizationSettingDto} documentTemplateCustomizationSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsDocumenttemplatesCustomizationsettingsIdPut(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTemplateCustomizationSetting>> {
            const localVarAxiosArgs = await DocumentTemplateCustomizationSettingsApiAxiosParamCreator(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsDocumenttemplatesCustomizationsettingsIdSetdefaultGet(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdSetdefaultGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DocumentTemplateCustomizationSettingsApiAxiosParamCreator(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdSetdefaultGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma eklenmiş olan imzayı silmek için bu uç kullanılabilir.
         * @param {string} id **Tasarım kimliği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureDelete(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureDeleteRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DocumentTemplateCustomizationSettingsApiAxiosParamCreator(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureDelete(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma eklenmiş olan imzaya bu uç ile ulaşılabilir.
         * @param {string} id **Tasarım kimliği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureGet(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DocumentTemplateCustomizationSettingsApiAxiosParamCreator(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma imza eklemek için bu uç kullanılabilir.
         * @param {string} id 
         * @param {any} file **Dosya içeriği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignaturePost(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdSignaturePostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DocumentTemplateCustomizationSettingsApiAxiosParamCreator(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignaturePost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma eklenmiş olan kaşeyi silmek için bu uç kullanılabilir.
         * @param {string} id **Tasarım kimliği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampDelete(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdStampDeleteRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DocumentTemplateCustomizationSettingsApiAxiosParamCreator(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampDelete(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma eklenmiş olan kaşeye bu uç ile ulaşılabilir.
         * @param {string} id **Tasarım kimliği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampGet(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdStampGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DocumentTemplateCustomizationSettingsApiAxiosParamCreator(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma kaşe eklemek için bu uç kullanılabilir.
         * @param {string} id 
         * @param {any} file **Dosya içeriği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampPost(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdStampPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DocumentTemplateCustomizationSettingsApiAxiosParamCreator(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarım eklemek için kullanılır.
         * @param {DocumentTemplateCustomizationSettingDto} documentTemplateCustomizationSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsDocumenttemplatesCustomizationsettingsPost(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTemplateCustomizationSetting>> {
            const localVarAxiosArgs = await DocumentTemplateCustomizationSettingsApiAxiosParamCreator(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DocumentTemplateCustomizationSettingsApi - factory interface
 * @export
 */
export const DocumentTemplateCustomizationSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Tasarım ayarları dönülür
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsGet(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsGetRequest, options?: any): AxiosPromise<Array<DocumentTemplateCustomizationSetting>> {
            return DocumentTemplateCustomizationSettingsApiFp(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarımını silmek için kullanılır.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdDelete(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdDeleteRequest, options?: any): AxiosPromise<void> {
            return DocumentTemplateCustomizationSettingsApiFp(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdDelete(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sorgulanan ayarı getirir
         * @param {string} id **Kayıt kimliği (Id)**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdGet(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdGetRequest, options?: any): AxiosPromise<DocumentTemplateCustomizationSetting> {
            return DocumentTemplateCustomizationSettingsApiFp(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma eklenmiş olan logoyu silmek için bu uç kullanılabilir.
         * @param {string} id **Tasarım kimliği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoDelete(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoDeleteRequest, options?: any): AxiosPromise<void> {
            return DocumentTemplateCustomizationSettingsApiFp(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoDelete(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma eklenmiş olan logoya bu uç ile ulaşılabilir.
         * @param {string} id **Tasarım kimliği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoGet(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoGetRequest, options?: any): AxiosPromise<void> {
            return DocumentTemplateCustomizationSettingsApiFp(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma logo eklemek için bu uç kullanılabilir.
         * @param {string} id 
         * @param {any} file **Dosya içeriği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoPost(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoPostRequest, options?: any): AxiosPromise<void> {
            return DocumentTemplateCustomizationSettingsApiFp(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Tasarımı örnek bir belgeyle önizlemek için uç kullanılabilir.
         * @summary Tasarımı önizler
         * @param {string} id **Tasarım id**
         * @param {any} jsonContent 
         * @param {any} [ublXmlFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdPreviewPost(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdPreviewPostRequest, options?: any): AxiosPromise<void> {
            return DocumentTemplateCustomizationSettingsApiFp(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdPreviewPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarımını güncellemek için kullanılır.
         * @param {string} id 
         * @param {DocumentTemplateCustomizationSettingDto} documentTemplateCustomizationSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdPut(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdPutRequest, options?: any): AxiosPromise<DocumentTemplateCustomizationSetting> {
            return DocumentTemplateCustomizationSettingsApiFp(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdSetdefaultGet(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdSetdefaultGetRequest, options?: any): AxiosPromise<void> {
            return DocumentTemplateCustomizationSettingsApiFp(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdSetdefaultGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma eklenmiş olan imzayı silmek için bu uç kullanılabilir.
         * @param {string} id **Tasarım kimliği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureDelete(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureDeleteRequest, options?: any): AxiosPromise<void> {
            return DocumentTemplateCustomizationSettingsApiFp(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureDelete(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma eklenmiş olan imzaya bu uç ile ulaşılabilir.
         * @param {string} id **Tasarım kimliği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureGet(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureGetRequest, options?: any): AxiosPromise<void> {
            return DocumentTemplateCustomizationSettingsApiFp(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma imza eklemek için bu uç kullanılabilir.
         * @param {string} id 
         * @param {any} file **Dosya içeriği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignaturePost(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdSignaturePostRequest, options?: any): AxiosPromise<void> {
            return DocumentTemplateCustomizationSettingsApiFp(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignaturePost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma eklenmiş olan kaşeyi silmek için bu uç kullanılabilir.
         * @param {string} id **Tasarım kimliği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampDelete(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdStampDeleteRequest, options?: any): AxiosPromise<void> {
            return DocumentTemplateCustomizationSettingsApiFp(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampDelete(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma eklenmiş olan kaşeye bu uç ile ulaşılabilir.
         * @param {string} id **Tasarım kimliği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampGet(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdStampGetRequest, options?: any): AxiosPromise<void> {
            return DocumentTemplateCustomizationSettingsApiFp(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarıma kaşe eklemek için bu uç kullanılabilir.
         * @param {string} id 
         * @param {any} file **Dosya içeriği**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampPost(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdStampPostRequest, options?: any): AxiosPromise<void> {
            return DocumentTemplateCustomizationSettingsApiFp(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary e-Belge özelleştirilebilir tasarım eklemek için kullanılır.
         * @param {DocumentTemplateCustomizationSettingDto} documentTemplateCustomizationSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesCustomizationsettingsPost(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsPostRequest, options?: any): AxiosPromise<DocumentTemplateCustomizationSetting> {
            return DocumentTemplateCustomizationSettingsApiFp(configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsPost(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentTemplateCustomizationSettingsApi - object-oriented interface
 * @export
 * @class DocumentTemplateCustomizationSettingsApi
 * @extends {BaseAPI}
 */
export class DocumentTemplateCustomizationSettingsApi extends BaseAPI {
    /**
     * 
     * @summary Tasarım ayarları dönülür
     * @param {string} [query] Aranacak kelime
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateCustomizationSettingsApi
     */
    public v1DefinitionsDocumenttemplatesCustomizationsettingsGet(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsGetRequest, options?: any) {
        return DocumentTemplateCustomizationSettingsApiFp(this.configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary e-Belge özelleştirilebilir tasarımını silmek için kullanılır.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateCustomizationSettingsApi
     */
    public v1DefinitionsDocumenttemplatesCustomizationsettingsIdDelete(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdDeleteRequest, options?: any) {
        return DocumentTemplateCustomizationSettingsApiFp(this.configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdDelete(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sorgulanan ayarı getirir
     * @param {string} id **Kayıt kimliği (Id)**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateCustomizationSettingsApi
     */
    public v1DefinitionsDocumenttemplatesCustomizationsettingsIdGet(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdGetRequest, options?: any) {
        return DocumentTemplateCustomizationSettingsApiFp(this.configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary e-Belge özelleştirilebilir tasarıma eklenmiş olan logoyu silmek için bu uç kullanılabilir.
     * @param {string} id **Tasarım kimliği**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateCustomizationSettingsApi
     */
    public v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoDelete(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoDeleteRequest, options?: any) {
        return DocumentTemplateCustomizationSettingsApiFp(this.configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoDelete(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary e-Belge özelleştirilebilir tasarıma eklenmiş olan logoya bu uç ile ulaşılabilir.
     * @param {string} id **Tasarım kimliği**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateCustomizationSettingsApi
     */
    public v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoGet(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoGetRequest, options?: any) {
        return DocumentTemplateCustomizationSettingsApiFp(this.configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary e-Belge özelleştirilebilir tasarıma logo eklemek için bu uç kullanılabilir.
     * @param {string} id 
     * @param {any} file **Dosya içeriği**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateCustomizationSettingsApi
     */
    public v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoPost(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoPostRequest, options?: any) {
        return DocumentTemplateCustomizationSettingsApiFp(this.configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tasarımı örnek bir belgeyle önizlemek için uç kullanılabilir.
     * @summary Tasarımı önizler
     * @param {string} id **Tasarım id**
     * @param {any} jsonContent 
     * @param {any} [ublXmlFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateCustomizationSettingsApi
     */
    public v1DefinitionsDocumenttemplatesCustomizationsettingsIdPreviewPost(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdPreviewPostRequest, options?: any) {
        return DocumentTemplateCustomizationSettingsApiFp(this.configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdPreviewPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary e-Belge özelleştirilebilir tasarımını güncellemek için kullanılır.
     * @param {string} id 
     * @param {DocumentTemplateCustomizationSettingDto} documentTemplateCustomizationSettingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateCustomizationSettingsApi
     */
    public v1DefinitionsDocumenttemplatesCustomizationsettingsIdPut(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdPutRequest, options?: any) {
        return DocumentTemplateCustomizationSettingsApiFp(this.configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateCustomizationSettingsApi
     */
    public v1DefinitionsDocumenttemplatesCustomizationsettingsIdSetdefaultGet(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdSetdefaultGetRequest, options?: any) {
        return DocumentTemplateCustomizationSettingsApiFp(this.configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdSetdefaultGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary e-Belge özelleştirilebilir tasarıma eklenmiş olan imzayı silmek için bu uç kullanılabilir.
     * @param {string} id **Tasarım kimliği**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateCustomizationSettingsApi
     */
    public v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureDelete(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureDeleteRequest, options?: any) {
        return DocumentTemplateCustomizationSettingsApiFp(this.configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureDelete(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary e-Belge özelleştirilebilir tasarıma eklenmiş olan imzaya bu uç ile ulaşılabilir.
     * @param {string} id **Tasarım kimliği**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateCustomizationSettingsApi
     */
    public v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureGet(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureGetRequest, options?: any) {
        return DocumentTemplateCustomizationSettingsApiFp(this.configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary e-Belge özelleştirilebilir tasarıma imza eklemek için bu uç kullanılabilir.
     * @param {string} id 
     * @param {any} file **Dosya içeriği**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateCustomizationSettingsApi
     */
    public v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignaturePost(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdSignaturePostRequest, options?: any) {
        return DocumentTemplateCustomizationSettingsApiFp(this.configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignaturePost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary e-Belge özelleştirilebilir tasarıma eklenmiş olan kaşeyi silmek için bu uç kullanılabilir.
     * @param {string} id **Tasarım kimliği**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateCustomizationSettingsApi
     */
    public v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampDelete(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdStampDeleteRequest, options?: any) {
        return DocumentTemplateCustomizationSettingsApiFp(this.configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampDelete(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary e-Belge özelleştirilebilir tasarıma eklenmiş olan kaşeye bu uç ile ulaşılabilir.
     * @param {string} id **Tasarım kimliği**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateCustomizationSettingsApi
     */
    public v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampGet(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdStampGetRequest, options?: any) {
        return DocumentTemplateCustomizationSettingsApiFp(this.configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary e-Belge özelleştirilebilir tasarıma kaşe eklemek için bu uç kullanılabilir.
     * @param {string} id 
     * @param {any} file **Dosya içeriği**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateCustomizationSettingsApi
     */
    public v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampPost(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsIdStampPostRequest, options?: any) {
        return DocumentTemplateCustomizationSettingsApiFp(this.configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary e-Belge özelleştirilebilir tasarım eklemek için kullanılır.
     * @param {DocumentTemplateCustomizationSettingDto} documentTemplateCustomizationSettingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateCustomizationSettingsApi
     */
    public v1DefinitionsDocumenttemplatesCustomizationsettingsPost(requestParameters: V1DefinitionsDocumenttemplatesCustomizationsettingsPostRequest, options?: any) {
        return DocumentTemplateCustomizationSettingsApiFp(this.configuration).v1DefinitionsDocumenttemplatesCustomizationsettingsPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1DefinitionsDocumenttemplatesCustomizationsettingsGetRequest {
    /**
     * Aranacak kelime
     */
    query?: string;
    }
    export interface V1DefinitionsDocumenttemplatesCustomizationsettingsIdDeleteRequest {
    /**
     * 
     */
    id: string;
    }
    export interface V1DefinitionsDocumenttemplatesCustomizationsettingsIdGetRequest {
    /**
     * **Kayıt kimliği (Id)**
     */
    id: string;
    }
    export interface V1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoDeleteRequest {
    /**
     * **Tasarım kimliği**
     */
    id: string;
    }
    export interface V1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoGetRequest {
    /**
     * **Tasarım kimliği**
     */
    id: string;
    }
    export interface V1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoPostRequest {
    /**
     * 
     */
    id: string;
    /**
     * **Dosya içeriği**
     */
    file: any;
    }
    export interface V1DefinitionsDocumenttemplatesCustomizationsettingsIdPreviewPostRequest {
    /**
     * **Tasarım id**
     */
    id: string;
    /**
     * 
     */
    jsonContent: any;
    /**
     * 
     */
    ublXmlFile?: any;
    }
    export interface V1DefinitionsDocumenttemplatesCustomizationsettingsIdPutRequest {
    /**
     * 
     */
    id: string;
    /**
     * 
     */
    documentTemplateCustomizationSettingDto: DocumentTemplateCustomizationSettingDto;
    }
    export interface V1DefinitionsDocumenttemplatesCustomizationsettingsIdSetdefaultGetRequest {
    /**
     * 
     */
    id: string;
    }
    export interface V1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureDeleteRequest {
    /**
     * **Tasarım kimliği**
     */
    id: string;
    }
    export interface V1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureGetRequest {
    /**
     * **Tasarım kimliği**
     */
    id: string;
    }
    export interface V1DefinitionsDocumenttemplatesCustomizationsettingsIdSignaturePostRequest {
    /**
     * 
     */
    id: string;
    /**
     * **Dosya içeriği**
     */
    file: any;
    }
    export interface V1DefinitionsDocumenttemplatesCustomizationsettingsIdStampDeleteRequest {
    /**
     * **Tasarım kimliği**
     */
    id: string;
    }
    export interface V1DefinitionsDocumenttemplatesCustomizationsettingsIdStampGetRequest {
    /**
     * **Tasarım kimliği**
     */
    id: string;
    }
    export interface V1DefinitionsDocumenttemplatesCustomizationsettingsIdStampPostRequest {
    /**
     * 
     */
    id: string;
    /**
     * **Dosya içeriği**
     */
    file: any;
    }
    export interface V1DefinitionsDocumenttemplatesCustomizationsettingsPostRequest {
    /**
     * 
     */
    documentTemplateCustomizationSettingDto: DocumentTemplateCustomizationSettingDto;
    }
