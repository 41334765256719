import { EArchiveDocumentsReportDivisionStatus as IEArchiveDocumentsReportDivisionStatus } from '@/apis/general/interfaces';
import { BagdeStatus } from '@/constants/enums';
import { Badge, Tooltip } from 'antd';
import React, { memo } from 'react';
import intl from 'react-intl-universal';

export const EArchiveDocumentsReportDivisionStatus = memo(
  ({ reportDivisionStatus: eArchiveDocumentsReportDivisionStatus }: { reportDivisionStatus: IEArchiveDocumentsReportDivisionStatus }) => {
    const text = (eArchiveDocumentsReportDivisionStatus: IEArchiveDocumentsReportDivisionStatus) => {
      switch (eArchiveDocumentsReportDivisionStatus) {
        case 'None':
          return 'RAPOR_OLUSTURULMADI';
        case 'XmlCreateWait':
          return 'RAPOR_OLUSTURULUYOR';
        case 'XmlCreated':
          return 'RAPOR_OLUSTURULDU_GIB_E_GONDERILECEK';
        case 'XmlSigned':
          return 'RAPOR_IMZALANDI';
        case 'Query':
          return 'RAPOR_GONDERILDI_DURUM_SORGULANIYOR';
        case 'Completed':
          return 'RAPOR_GONDERIMI_BASARILI';
        case 'Error':
          return 'HATA_ALDI';
        default:
          return 'RAPOR_OLUSTURULMADI';
      }
    };

    const color = (eArchiveDocumentsReportDivisionStatus: IEArchiveDocumentsReportDivisionStatus): BagdeStatus => {
      switch (eArchiveDocumentsReportDivisionStatus) {
        case 'None':
          return BagdeStatus.processing;
        case 'XmlCreateWait':
        case 'XmlCreated':
        case 'XmlSigned':
        case 'Query':
          return BagdeStatus.warning;
        case 'Completed':
          return BagdeStatus.success;
        case 'Error':
          return BagdeStatus.error;
        default:
          return BagdeStatus.processing;
      }
    };
    const variant = color(eArchiveDocumentsReportDivisionStatus);
    const title = text(eArchiveDocumentsReportDivisionStatus);

    return (
      <>
        {variant !== 'error' && (
          <div>
            <Badge status={variant} text={intl.getHTML(title)} />
          </div>
        )}

        {variant === 'error' && (
          <div style={{ cursor: 'pointer' }}>
            <Tooltip trigger="hover" overlayClassName="antd-tooltip-center" title={intl.get('HATA_DETAYI_ICIN_TIKLAYINIZ')} placement="bottom">
              <Badge status={variant} text={intl.get(title)} />
            </Tooltip>
          </div>
        )}
      </>
    );
  }
);

export default EArchiveDocumentsReportDivisionStatus;
