import { OnClickProps } from '@/interfaces';
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuItem from 'antd/lib/menu/MenuItem';
import React from 'react';
import intl from 'react-intl-universal';

export const ConfirmSend = (props: OnClickProps) => {
    return (
        <MenuItem {...props}>
            <FontAwesomeIcon className="mr-2" icon={faPaperPlane} />
            {props.title || intl.get('ONAYLA_VE_GONDER')}
        </MenuItem>
    );
};

export default ConfirmSend;
