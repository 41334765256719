import { V1InvoicesDraftsGetRequest, V1InvoicesGetRequest } from '@/apis/earchive';
import { V1IncomingDespatchesGetRequest, V1OutgoingDespatchesDraftsGetRequest, V1OutgoingDespatchesGetRequest } from '@/apis/edespatch';
import { V1IncomingInvoicesGetRequest, V1OutgoingInvoicesDraftsGetRequest, V1OutgoingInvoicesGetRequest } from '@/apis/einvoice';
import { V1VouchersCanceledGetRequest, V1VouchersGetRequest, V1VouchersDraftsGetRequest } from '@/apis/esmm';
import { V1EarchiveincomingdocumentsGetRequest } from '@/apis/general';
import { DateCriteria } from '@/constants/enums';
import { DateConst } from '@/constants/page';
import createStore from '@/helpers/createStore';
import { Reducer } from 'react';

type DetailFilterDrawer = {
  visible: boolean;
  reExecute: boolean;
};

type GenericPageState<T> = {
  requestParameters: T;
  detailFilterDrawer: DetailFilterDrawer;
  dateCriteria: DateCriteria;
  selectedColumns: string[];
};

export type Action =
  | { type: 'SET_FILTER'; key: 'incomingInvoice'; value: GenericPageState<V1IncomingInvoicesGetRequest> }
  | { type: 'SET_FILTER'; key: 'incomingInvoiceArchive'; value: GenericPageState<V1IncomingInvoicesGetRequest> }
  | { type: 'SET_FILTER'; key: 'outgoingInvoice'; value: GenericPageState<V1OutgoingInvoicesGetRequest> }
  | { type: 'SET_FILTER'; key: 'outgoingInvoiceArchive'; value: GenericPageState<V1OutgoingInvoicesGetRequest> }
  | { type: 'SET_FILTER'; key: 'outgoingInvoicesDraft'; value: GenericPageState<V1OutgoingInvoicesDraftsGetRequest> }
  | { type: 'SET_FILTER'; key: 'incomingDespatch'; value: GenericPageState<V1IncomingDespatchesGetRequest> }
  | { type: 'SET_FILTER'; key: 'incomingDespatchArchive'; value: GenericPageState<V1IncomingDespatchesGetRequest> }
  | { type: 'SET_FILTER'; key: 'outgoingDespatch'; value: GenericPageState<V1OutgoingDespatchesGetRequest> }
  | { type: 'SET_FILTER'; key: 'outgoingDespatchArchive'; value: GenericPageState<V1OutgoingDespatchesGetRequest> }
  | { type: 'SET_FILTER'; key: 'outgoingDespatchDraft'; value: GenericPageState<V1OutgoingDespatchesDraftsGetRequest> }
  | { type: 'SET_FILTER'; key: 'earcihveInvoice'; value: GenericPageState<V1InvoicesGetRequest> }
  | { type: 'SET_FILTER'; key: 'earcihveIncomingDocuments'; value: GenericPageState<V1EarchiveincomingdocumentsGetRequest> }
  | { type: 'SET_FILTER'; key: 'earcihveInvoiceCanceled'; value: GenericPageState<V1InvoicesGetRequest> }
  | { type: 'SET_FILTER'; key: 'earchiveInvoicesDraft'; value: GenericPageState<V1InvoicesDraftsGetRequest> }
  | { type: 'SET_FILTER'; key: 'esmmVoucher'; value: GenericPageState<V1VouchersGetRequest> }
  | { type: 'SET_FILTER'; key: 'esmmVoucherCanceled'; value: GenericPageState<V1VouchersCanceledGetRequest> }
  | { type: 'SET_FILTER'; key: 'esmmVoucherDraft'; value: GenericPageState<V1VouchersDraftsGetRequest> }
  | { type: 'SET_FILTER'; key: 'emmVoucher'; value: GenericPageState<V1VouchersGetRequest> }
  | { type: 'SET_FILTER'; key: 'emmVoucherCanceled'; value: GenericPageState<V1VouchersCanceledGetRequest> }
  | { type: 'SET_FILTER'; key: 'emmVoucherDraft'; value: GenericPageState<V1VouchersDraftsGetRequest> }
  | { type: 'SET_COLUMN'; key: string; value: string[] };

type State = {
  incomingInvoice: GenericPageState<V1IncomingInvoicesGetRequest>;
  outgoingInvoice: GenericPageState<V1OutgoingInvoicesGetRequest>;
  outgoingInvoicesDraft: GenericPageState<V1OutgoingInvoicesDraftsGetRequest>;
  incomingDespatch: GenericPageState<V1IncomingDespatchesGetRequest>;
  incomingDespatchArchive: GenericPageState<V1IncomingDespatchesGetRequest>;
  outgoingDespatch: GenericPageState<V1OutgoingDespatchesGetRequest>;
  outgoingDespatchArchive: GenericPageState<V1OutgoingDespatchesGetRequest>;
  outgoingDespatchDraft: GenericPageState<V1OutgoingDespatchesDraftsGetRequest>;
  earcihveInvoice: GenericPageState<V1InvoicesGetRequest>;
  earcihveIncomingDocuments: GenericPageState<V1EarchiveincomingdocumentsGetRequest>;
  earcihveInvoiceCanceled: GenericPageState<V1InvoicesGetRequest>;
  earchiveInvoicesDraft: GenericPageState<V1InvoicesDraftsGetRequest>;
  esmmVoucher: GenericPageState<V1VouchersGetRequest>;
  esmmVoucherCanceled: GenericPageState<V1VouchersCanceledGetRequest>;
  esmmVoucherDraft: GenericPageState<V1VouchersDraftsGetRequest>;
  emmVoucher: GenericPageState<V1VouchersGetRequest>;
  emmVoucherCanceled: GenericPageState<V1VouchersCanceledGetRequest>;
  emmVoucherDraft: GenericPageState<V1VouchersDraftsGetRequest>;
};

const DetailFilterDrawerInitialState: DetailFilterDrawer = {
  reExecute: false,
  visible: false
};

const initialState: State = {
  incomingInvoice: {
    requestParameters: {
      page: 1,
      pageSize: 50,
      startDate: DateConst.START_DATE,
      endDate: DateConst.END_DATE,
      archived: false,
      sort: 'createdAt desc'
    },
    detailFilterDrawer: DetailFilterDrawerInitialState,
    dateCriteria: DateCriteria.DocumentDate,
    selectedColumns: JSON.parse(localStorage.getItem(`apps.columns.einvoice.incoming.list`) || '[]')
  },
  outgoingInvoice: {
    requestParameters: {
      page: 1,
      pageSize: 50,
      startDate: DateConst.START_DATE,
      endDate: DateConst.END_DATE,
      archived: false,
      sort: 'createdAt desc'
    },
    detailFilterDrawer: DetailFilterDrawerInitialState,
    dateCriteria: DateCriteria.DocumentDate,
    selectedColumns: JSON.parse(localStorage.getItem(`apps.columns.einvoice.outgoing.list`) || '[]')
  },
  outgoingInvoicesDraft: {
    requestParameters: {
      page: 1,
      pageSize: 50,
      sort: 'createdAt desc'
    },
    detailFilterDrawer: DetailFilterDrawerInitialState,
    dateCriteria: DateCriteria.DocumentDate,
    selectedColumns: JSON.parse(localStorage.getItem(`apps.columns.einvoice.outgoingdraft.list`) || '[]')
  },
  earchiveInvoicesDraft: {
    requestParameters: {
      page: 1,
      pageSize: 50,
      sort: 'createdAt desc'
    },
    detailFilterDrawer: DetailFilterDrawerInitialState,
    dateCriteria: DateCriteria.DocumentDate,
    selectedColumns: JSON.parse(localStorage.getItem(`apps.columns.einvoice.outgoingdraft.list`) || '[]')
  },
  incomingDespatch: {
    requestParameters: {
      page: 1,
      pageSize: 50,
      startDate: DateConst.START_DATE,
      endDate: DateConst.END_DATE,
      archived: false,
      sort: 'createdAt desc'
    },
    detailFilterDrawer: DetailFilterDrawerInitialState,
    dateCriteria: DateCriteria.DocumentDate,
    selectedColumns: JSON.parse(localStorage.getItem(`apps.columns.edespatch.incoming.list`) || '[]')
  },
  incomingDespatchArchive: {
    requestParameters: {
      page: 1,
      pageSize: 10,
      startDate: DateConst.START_DATE,
      endDate: DateConst.END_DATE,
      archived: true,
      sort: 'createdAt desc'
    },
    detailFilterDrawer: DetailFilterDrawerInitialState,
    dateCriteria: DateCriteria.DocumentDate,
    selectedColumns: JSON.parse(localStorage.getItem(`apps.columns.edespatch.incoming.list.archived`) || '[]')
  },
  outgoingDespatch: {
    requestParameters: {
      page: 1,
      pageSize: 50,
      startDate: DateConst.START_DATE,
      endDate: DateConst.END_DATE,
      archived: false,
      sort: 'createdAt desc'
    },
    detailFilterDrawer: DetailFilterDrawerInitialState,
    dateCriteria: DateCriteria.DocumentDate,
    selectedColumns: JSON.parse(localStorage.getItem(`apps.columns.edespatch.outgoing.list.archived`) || '[]')
  },
  outgoingDespatchArchive: {
    requestParameters: {
      page: 1,
      pageSize: 10,
      startDate: DateConst.START_DATE,
      endDate: DateConst.END_DATE,
      archived: true,
      sort: 'createdAt desc'
    },
    detailFilterDrawer: DetailFilterDrawerInitialState,
    dateCriteria: DateCriteria.DocumentDate,
    selectedColumns: JSON.parse(localStorage.getItem(`apps.columns.edespatch.outgoing.list.archived`) || '[]')
  },
  outgoingDespatchDraft: {
    requestParameters: {
      page: 1,
      pageSize: 50,
      sort: 'createdAt desc'
    },
    detailFilterDrawer: DetailFilterDrawerInitialState,
    dateCriteria: DateCriteria.DocumentDate,
    selectedColumns: JSON.parse(localStorage.getItem(`apps.columns.edespatch.outgoing.outgoingdraft.list`) || '[]')
  },
  earcihveInvoice: {
    requestParameters: {
      page: 1,
      pageSize: 50,
      startDate: DateConst.START_DATE,
      endDate: DateConst.END_DATE,
      sort: 'createdAt desc'
    },
    detailFilterDrawer: DetailFilterDrawerInitialState,
    dateCriteria: DateCriteria.DocumentDate,
    selectedColumns: JSON.parse(localStorage.getItem(`apps.columns.earchive.list`) || '[]')
  },
  earcihveIncomingDocuments: {
    requestParameters: {
      page: 1,
      pageSize: 50,
      startDate: DateConst.START_DATE,
      endDate: DateConst.END_DATE,
      sort: 'createdAt desc'
    },
    dateCriteria: DateCriteria.DocumentDate,
    detailFilterDrawer: DetailFilterDrawerInitialState,
    selectedColumns: []
  },
  earcihveInvoiceCanceled: {
    requestParameters: {
      page: 1,
      pageSize: 10,
      startDate: DateConst.START_DATE,
      endDate: DateConst.END_DATE,
      sort: 'createdAt desc'
    },
    detailFilterDrawer: DetailFilterDrawerInitialState,
    dateCriteria: DateCriteria.DocumentDate,
    selectedColumns: JSON.parse(localStorage.getItem(`apps.columns.earchive.list.canceled`) || '[]')
  },
  esmmVoucher: {
    requestParameters: {
      page: 1,
      pageSize: 50,
      startDate: DateConst.START_DATE,
      endDate: DateConst.END_DATE,
      sort: 'createdAt desc'
    },
    detailFilterDrawer: DetailFilterDrawerInitialState,
    dateCriteria: DateCriteria.DocumentDate,
    selectedColumns: JSON.parse(localStorage.getItem(`apps.columns.esmm.list`) || '[]')
  },
  esmmVoucherCanceled: {
    requestParameters: {
      page: 1,
      pageSize: 10,
      startDate: DateConst.START_DATE,
      endDate: DateConst.END_DATE,
      sort: 'createdAt desc'
    },
    detailFilterDrawer: DetailFilterDrawerInitialState,
    dateCriteria: DateCriteria.DocumentDate,
    selectedColumns: JSON.parse(localStorage.getItem(`apps.columns.esmm.list.canceled`) || '[]')
  },
  esmmVoucherDraft: {
    requestParameters: {
      page: 1,
      pageSize: 50,
      sort: 'createdAt desc'
    },
    detailFilterDrawer: DetailFilterDrawerInitialState,
    dateCriteria: DateCriteria.DocumentDate,
    selectedColumns: JSON.parse(localStorage.getItem(`apps.columns.evoucher.outgoingdraft.list`) || '[]')
  },
  emmVoucher: {
    requestParameters: {
      page: 1,
      pageSize: 50,
      startDate: DateConst.START_DATE,
      endDate: DateConst.END_DATE,
      sort: 'createdAt desc'
    },
    detailFilterDrawer: DetailFilterDrawerInitialState,
    dateCriteria: DateCriteria.DocumentDate,
    selectedColumns: JSON.parse(localStorage.getItem(`apps.columns.emm.list`) || '[]')
  },
  emmVoucherCanceled: {
    requestParameters: {
      page: 1,
      pageSize: 10,
      startDate: DateConst.START_DATE,
      endDate: DateConst.END_DATE,
      sort: 'createdAt desc'
    },
    detailFilterDrawer: DetailFilterDrawerInitialState,
    dateCriteria: DateCriteria.DocumentDate,
    selectedColumns: JSON.parse(localStorage.getItem(`apps.columns.emm.list.canceled`) || '[]')
  },
  emmVoucherDraft: {
    requestParameters: {
      page: 1,
      pageSize: 50,
      sort: 'createdAt desc'
    },
    detailFilterDrawer: DetailFilterDrawerInitialState,
    dateCriteria: DateCriteria.DocumentDate,
    selectedColumns: JSON.parse(localStorage.getItem(`apps.columns.evoucher.outgoingdraft.list`) || '[]')
  }
};

const reducer: Reducer<State, Action> = (state: State, action) => {
  switch (action.type) {
    case 'SET_FILTER': {
      return { ...state, [action.key]: action.value };
    }
    case 'SET_COLUMN': {
      return { ...state, [action.key]: { ...state[action.key], selectedColumns: action.value } };
    }
    default:
      return state;
  }
};
const { Context, Provider, Consumer } = createStore(reducer, initialState);

export { Provider as PageFilterProvider, Consumer as PageFilterContextConsumer };

export default Context;
