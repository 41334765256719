import { OnClickProps } from '@/interfaces';
import { faArchive } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuItem from 'antd/lib/menu/MenuItem';
import React from 'react';
import intl from 'react-intl-universal';

export const SetArchived = (props: OnClickProps) => {
  return (
    <MenuItem {...props}>
      <FontAwesomeIcon className={'mr-2'} icon={faArchive} />
      {intl.get('ARSIVE_KALDIR')}
    </MenuItem>
  );
};

export default SetArchived;
