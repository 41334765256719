import { EnvelopeInfo as IEDespatchEnvelopeInfo } from '@/apis/edespatch/interfaces';
import { EnvelopeInfo as IEInvoiceEnvelopeInfo, OutgoingStatus } from '@/apis/einvoice/interfaces';
import { Badge, Button, Modal, Table, Tooltip } from 'antd';
import { PresetStatusColorType } from 'antd/lib/_util/colors';
import React, { useState } from 'react';
import intl from 'react-intl-universal';

interface Props {
  outgoinsStatus: OutgoingStatus;
  documentNumber: string | null;
  envelopeInfo: IEInvoiceEnvelopeInfo | IEDespatchEnvelopeInfo | null;
  errorDescription?: string | null;
  id: string;
}

const text = (outgoingStatus: OutgoingStatus): string => {
  switch (outgoingStatus) {
    case OutgoingStatus.WaitingToBeSigned:
    case OutgoingStatus.WaitingToBeEnvelopeCreate:
    case OutgoingStatus.WaitingToBeSend:
    case OutgoingStatus.EnvelopeIsWaitingToBeProcessedByGib:
    case OutgoingStatus.ErrorHasBeenDetectedInEnvelopeByGib:
    case OutgoingStatus.EnvelopeIsWaitingToBeSendedToReceiverByGib:
    case OutgoingStatus.EnvelopeCouldNotTransferredToReceiverByGib:
    case OutgoingStatus.EnvelopeIsBeenWaitingToBeTransferredToReceiverByGib:
    case OutgoingStatus.EnvelopeSystemResponseIsBeingWaitedFromReceiver:
    case OutgoingStatus.ErrorHasBeenDetectedInEnvelopeByReceiver:
    case OutgoingStatus.EnvelopeHasBeenTransferredToReceiverSuccessfully:
      return outgoingStatus;
    default:
      return 'DURUM_BILINMIYOR';
  }
};

const color = (outgoingStatus: OutgoingStatus): PresetStatusColorType => {
  switch (outgoingStatus) {
    case OutgoingStatus.EnvelopeHasBeenTransferredToReceiverSuccessfully: //30
      return 'success';
    case OutgoingStatus.ErrorHasBeenDetectedInEnvelopeByGib: //11
    case OutgoingStatus.EnvelopeCouldNotTransferredToReceiverByGib: //20
    case OutgoingStatus.ErrorHasBeenDetectedInEnvelopeByReceiver: //23
      return 'error';
    case OutgoingStatus.WaitingToBeSigned: //2
    case OutgoingStatus.WaitingToBeEnvelopeCreate: //3
    case OutgoingStatus.WaitingToBeSend: //4
    case OutgoingStatus.EnvelopeIsWaitingToBeProcessedByGib: //10
    case OutgoingStatus.EnvelopeIsWaitingToBeSendedToReceiverByGib: //12
    case OutgoingStatus.EnvelopeIsBeenWaitingToBeTransferredToReceiverByGib: //21
    case OutgoingStatus.EnvelopeSystemResponseIsBeingWaitedFromReceiver: //22
      return 'warning';
    default:
      return 'default';
  }
};

export const OutgoingDocumentStatus: React.FC<Props> = ({ id, documentNumber, envelopeInfo, errorDescription, outgoinsStatus }: Props) => {
  const variant = color(outgoinsStatus);
  const title = text(outgoinsStatus);
  const [visibleError, setVisibleError] = useState<boolean>(false);

  return (
    <>
      <Modal
        style={{ paddingTop: '10px' }}
        width={720}
        onOk={() => setVisibleError(false)}
        onCancel={() => setVisibleError(false)}
        closable={true}
        footer={[
          <Button key="submit" type="primary" onClick={() => setVisibleError(false)}>
            {intl.get('KAPAT')}
          </Button>
        ]}
        title={
          <>
            <i className="ion ion-md-information-circle" /> {intl.get('HATA_DETAYI')}
          </>
        }
        visible={visibleError}
      >
        {documentNumber && (
          <>
            <strong>{intl.get('BELGE_NO')} : </strong> <span>{documentNumber}</span>
            <br />
          </>
        )}

        {envelopeInfo && (
          <>
            <strong>{intl.get('ZARF_NO')} : </strong> <span>{envelopeInfo.instanceIdentifier}</span>
            <Table
              style={{ marginTop: '10px' }}
              bordered
              pagination={false}
              dataSource={[
                {
                  code: envelopeInfo.code,
                  description: envelopeInfo.description
                }
              ]}
            >
              <Table.Column key="code" title={intl.get('HATA_KODU')} dataIndex="code" />
              <Table.Column key="description" title={intl.get('HATA_ACIKLAMASI')} dataIndex="description" />
            </Table>
          </>
        )}

        {envelopeInfo === null && (
          <>
            <hr />
            {errorDescription}
          </>
        )}
      </Modal>

      {variant !== 'error' && (
        <div>
          <Badge status={variant} text={intl.get(title)} key={id} />
        </div>
      )}

      {variant === 'error' && (
        <div style={{ cursor: 'pointer' }} onClick={() => setVisibleError(true)}>
          <Tooltip placement={'bottom'} trigger={'hover'} title={intl.get('HATA_DETAYI_ICIN_TIKLAYINIZ')}>
            <Badge status={variant} text={intl.get(title)} key={id} />
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default OutgoingDocumentStatus;
