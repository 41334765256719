import { OnClickProps } from '@/interfaces';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
export const Preview = ({ onClick, title = intl.get('GORUNTULE') }: OnClickProps & { title?: string }) => {
  return (
    <Tooltip trigger="hover" placement="bottom" title={title}>
      <FontAwesomeIcon icon={faSearch} className={`fa-lg`} style={{ cursor: 'pointer' }} onClick={onClick} />
    </Tooltip>
  );
};

export default Preview;
