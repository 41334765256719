import { AuthRole, AuthScope } from '@/interfaces';
import {
  faArchive,
  faArrowDown,
  faArrowUp,
  faBellOn,
  faBells,
  faCloudUpload,
  faCogs,
  faEdit,
  faFile,
  faFileCheck,
  faFileCode,
  faFileExcel,
  faFilePdf,
  faFileSignature,
  faListUl,
  faMailbox,
  faPencil,
  faPencilAlt,
  faPlus,
  faSearch,
  faShare,
  faTable,
  faTags,
  faTrash,
  faUsers
} from '@fortawesome/pro-regular-svg-icons';

export const EDESPATCH_EDITOR_ROLES: AuthRole = {
  value: 'ED:EDITOR',
  icon: faPencilAlt,
  checkable: false,
  children: [
    {
      value: 'ED:EDITOR:CREATE_EDIT',
      checkable: true,
      icon: faPlus
    }
  ]
};

export const EDESPATCH_OUTGOUNG_ROLES: AuthRole = {
  value: 'ED:OUT',
  icon: faArrowUp,
  checkable: false,
  children: [
    {
      value: 'ED:OUT:DES-ROOT',
      checkable: false,
      icon: faFile,
      children: [
        {
          value: 'ED:OUT:DES',
          checkable: true,
          icon: faFile
        },
        {
          value: 'ED:OUT:DES:XML',
          checkable: true,
          icon: faFileCode
        },
        {
          value: 'ED:OUT:DES:ENVELOPE',
          checkable: true,
          icon: faFileCode
        },
        {
          value: 'ED:OUT:DES:PDF',
          checkable: true,
          icon: faFilePdf
        },
        {
          value: 'ED:OUT:DES:EMAIL_SEND',
          checkable: true,
          icon: faShare
        },
        {
          value: 'ED:OUT:DES:HTML',
          checkable: true,
          icon: faSearch
        },
        {
          value: 'ED:OUT:DES:SET_ARCHIVE_STATUS',
          checkable: true,
          icon: faArchive
        }
      ]
    },
    {
      checkable: false,
      value: 'ED:OUT:RM',
      icon: faFileExcel,
      children: [
        {
          value: 'ED:OUT:RM:REPORTS',
          icon: faTable,
          checkable: true
        },
        {
          value: 'ED:OUT:RM:TEMPLATES',
          icon: faTags,
          checkable: true
        },
        {
          value: 'ED:OUT:RM:TEMPLATES_POST_PUT',
          icon: faEdit,
          checkable: true
        }
      ]
    }
  ]
};

export const EDESPATCH_INCOMING_ROLES: AuthRole = {
  value: 'ED:INC',
  icon: faArrowDown,
  checkable: false,
  children: [
    {
      value: 'ED:INC:DES-ROOT',
      checkable: false,
      icon: faFile,
      children: [
        {
          value: 'ED:INC:DES',
          checkable: true,
          icon: faFile
        },
        {
          value: 'ED:INC:DES:XML',
          checkable: true,
          icon: faFileCode
        },
        {
          value: 'ED:INC:DES:ENVELOPE',
          checkable: true,
          icon: faFileCode
        },
        {
          value: 'ED:INC:DES:PDF',
          checkable: true,
          icon: faFilePdf
        },
        {
          value: 'ED:INC:DES:EMAIL_SEND',
          checkable: true,
          icon: faShare
        },
        {
          value: 'ED:INC:DES:HTML',
          checkable: true,
          icon: faSearch
        },
        {
          value: 'ED:INC:DES:SET_ARCHIVE_STATUS',
          checkable: true,
          icon: faArchive
        },
        {
          value: 'ED:INC:DES:DOCUMENT_ANSWER',
          checkable: true,
          icon: faShare
        },
        {
          value: 'ED:INC:DES:SET_TRANSFERRED_STATUS',
          checkable: true,
          icon: faFileCheck
        }
      ]
    },
    {
      checkable: false,
      value: 'ED:INC:RM',
      icon: faFileExcel,
      children: [
        {
          value: 'ED:INC:RM:REPORTS',
          icon: faTable,
          checkable: true
        },
        {
          value: 'ED:INC:RM:TEMPLATES',
          icon: faTags,
          checkable: true
        },
        {
          value: 'ED:INC:RM:TEMPLATES_POST_PUT',
          icon: faEdit,
          checkable: true
        }
      ]
    }
  ]
};

export const EDESPATCH_DEFINATIONS_ROLES: AuthRole = {
  value: 'ED:DEF',
  icon: faCogs,
  checkable: false,
  children: [
    {
      value: 'ED:DEF:SERIES-ROOT',
      checkable: false,
      icon: faListUl,
      children: [
        {
          value: 'ED:DEF:SERIES',
          checkable: true,
          icon: faListUl
        },
        {
          value: 'ED:DEF:SERIES_POST',
          icon: faEdit,
          checkable: true
        },
        {
          value: 'ED:DEF:SERIES_DELETE',
          icon: faTrash,
          checkable: true
        }
      ]
    },
    {
      value: 'ED:DEF:ANSWER_SERIES-ROOT',
      checkable: false,
      icon: faListUl,
      children: [
        {
          value: 'ED:DEF:ANSWER_SERIES',
          checkable: true,
          icon: faListUl
        },
        {
          value: 'ED:DEF:ANSWER_SERIES_POST',
          icon: faEdit,
          checkable: true
        },
        {
          value: 'ED:DEF:ANSWER_SERIES_DELETE',
          icon: faTrash,
          checkable: true
        }
      ]
    },
    {
      value: 'ED:DEF:DOCUMENT_TEMPLATES-ROOT',
      checkable: false,
      icon: faTags,
      children: [
        {
          value: 'ED:DEF:DOCUMENT_TEMPLATES',
          checkable: true,
          icon: faTags
        },
        {
          value: 'ED:DEF:DOCUMENT_TEMPLATES_POST',
          icon: faEdit,
          checkable: true
        },
        {
          value: 'ED:DEF:DOCUMENT_TEMPLATES_PUT',
          icon: faPencil,
          checkable: true
        },
        {
          value: 'ED:DEF:DOCUMENT_TEMPLATES_DELETE',
          icon: faTrash,
          checkable: true
        }
      ]
    },
    {
      value: 'ED:DEF:ANSWER_DOCUMENT_TEMPLATES-ROOT',
      checkable: false,
      icon: faTags,
      children: [
        {
          value: 'ED:DEF:ANSWER_DOCUMENT_TEMPLATES',
          checkable: true,
          icon: faTags
        },
        {
          value: 'ED:DEF:ANSWER_DOCUMENT_TEMPLATES_POST',
          icon: faEdit,
          checkable: true
        },
        {
          value: 'ED:DEF:ANSWER_DOCUMENT_TEMPLATES_PUT',
          icon: faPencil,
          checkable: true
        },
        {
          value: 'ED:DEF:ANSWER_DOCUMENT_TEMPLATES_DELETE',
          icon: faTrash,
          checkable: true
        }
      ]
    },
    {
      value: 'ED:DEF:MAIL_SMS',
      icon: faMailbox,
      checkable: true
    },
    {
      value: 'ED:DEF:FILE_EXPORT_TITLES',
      icon: faFileSignature,
      checkable: true
    }
  ]
};

export const EDESPATCH_TAGS_ROLES: AuthRole = {
  value: 'ED:TAGS',
  icon: faTags,
  checkable: true
};

export const EDESPATCH_UPLOADS_ROLES: AuthRole = {
  value: 'ED:UPLOADS_DOCUMENT',
  icon: faCloudUpload,
  checkable: true
};

export const EDESPATCH_NOTIFICATIONS_ROLES: AuthRole = {
  value: 'ED:NOTIFY',
  icon: faBellOn,
  checkable: false,
  children: [
    {
      value: 'ED:NOTIFY:INC:DYNAMIC_RULES',
      checkable: true,
      icon: faBells
    },
    {
      value: 'ED:NOTIFY:OUT:DYNAMIC_RULES',
      checkable: true,
      icon: faBells
    }
  ]
};

export const EDESPATCH_ROLES = [
  EDESPATCH_EDITOR_ROLES,
  EDESPATCH_OUTGOUNG_ROLES,
  EDESPATCH_INCOMING_ROLES,
  EDESPATCH_DEFINATIONS_ROLES,
  EDESPATCH_NOTIFICATIONS_ROLES,
  EDESPATCH_TAGS_ROLES,
  EDESPATCH_UPLOADS_ROLES
];

export const EDESPATCH_OUTGOING_SCOPES: AuthScope = {
  value: 'edespatch:outgoing',
  checkable: false,
  children: [
    {
      value: 'edespatch:outgoing:list:read'
    },
    {
      value: 'edespatch:outgoing:list:write'
    },
    {
      value: 'edespatch:outgoing:excelreport:read'
    },
    {
      value: 'edespatch:outgoing:excelreport:write'
    }
  ]
};

export const EDESPATCH_INCOMING_SCOPES: AuthScope = {
  value: 'edespatch:incoming',
  checkable: false,
  children: [
    {
      value: 'edespatch:incoming:list:read'
    },
    {
      value: 'edespatch:incoming:list:write'
    },
    {
      value: 'edespatch:incoming:excelreport:read'
    },
    {
      value: 'edespatch:incoming:excelreport:write'
    }
  ]
};

export const EDESPATCH_TAG_SCOPES: AuthScope = {
  value: 'edespatch:tags',
  checkable: false,
  children: [
    {
      value: 'edespatch:tags:read'
    },
    {
      value: 'edespatch:tags:write'
    }
  ]
};

export const EDESPATCH_DEFINATIONS_SCOPES: AuthScope = {
  value: 'edespatch:definations',
  checkable: false,
  children: [
    {
      value: 'edespatch:definations:documentserie:read'
    },
    {
      value: 'edespatch:definations:documentserie:write'
    },
    {
      value: 'edespatch:definations:answerserie:read'
    },
    {
      value: 'edespatch:definations:answerserie:write'
    },
    {
      value: 'edespatch:definations:documenttemplate:read'
    },
    {
      value: 'edespatch:definations:documenttemplate:write'
    },
    {
      value: 'edespatch:definations:answertemplate:read'
    },
    {
      value: 'edespatch:definations:answertemplate:write'
    },
    {
      value: 'edespatch:definations:mailsms:read'
    },
    {
      value: 'edespatch:definations:mailsms:write'
    },
    {
      value: 'edespatch:definations:filetitle:read'
    },
    {
      value: 'edespatch:definations:filetitle:write'
    }
  ]
};

export const EDESPATCH_NOTIFICATIONS_SCOPES: AuthScope = {
  value: 'edespatch:notifications',
  checkable: false,
  children: [
    {
      value: 'edespatch:notifications:dynamic:incoming:read'
    },
    {
      value: 'edespatch:notifications:dynamic:incoming:write'
    },
    {
      value: 'edespatch:notifications:dynamic:outgoing:read'
    },
    {
      value: 'edespatch:notifications:dynamic:outgoing:write'
    }
  ]
};

export const EDESPATCH_UPLOAD_SCOPES: AuthScope = {
  value: 'edespatch:upload',
  checkable: false,
  children: [
    {
      value: 'edespatch:upload:write'
    }
  ]
};

export const EDESPATCH_SCOPES = [
  EDESPATCH_OUTGOING_SCOPES,
  EDESPATCH_INCOMING_SCOPES,
  EDESPATCH_TAG_SCOPES,
  EDESPATCH_DEFINATIONS_SCOPES,
  EDESPATCH_NOTIFICATIONS_SCOPES,
  EDESPATCH_UPLOAD_SCOPES
];
