/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { CodeType, IDType } from '.';
import { NS_CBC } from '../constants';

/*
  19.04.2022 : Partially completed
*/
export class TransportEquipmentType {
  @XMLElement({ types: [{ name: 'ID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  id?: IDType;

  @XMLElement({ types: [{ name: 'TransportEquipmentTypeCode', namespaceUri: NS_CBC, itemType: () => CodeType }] })
  transportEquipmentTypeCode?: CodeType;

  @XMLElement({ types: [{ name: 'Description', namespaceUri: NS_CBC, itemType: () => CodeType }] })
  description?: CodeType;
}

export default TransportEquipmentType;
