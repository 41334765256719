import {
  V1OutgoingReportmoduleTemplatesIdGetRequest,
  V1OutgoingReportmoduleTemplatesIdPutRequest,
  V1OutgoingReportmoduleTemplatesPostRequest
} from '@/apis/earchive';
import { ExcelReportTemplate, ExcelReportTemplateDto } from '@/apis/earchive/interfaces';
import { DragAndDrop, DragAndDropState, InfoToastify, RootSpinner } from '@/components';
import { Close, Save } from '@/components/Buttons';
import { ErrorViewer } from '@/components/TableBody';
import { DangerToastify } from '@/components/Toastify';
import { useApi } from '@/hooks';
import { KeyValue } from '@/interfaces';
import { AnyType } from '@/type';
import { faHeading } from '@fortawesome/pro-light-svg-icons';
import { faTags } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Modal, Row } from 'antd';
import { AxiosPromise } from 'axios';
import { Formik, FormikProps } from 'formik';
import { Form, FormItem, Input } from 'formik-antd';
import React, { memo, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import intl from 'react-intl-universal';
import * as Yup from 'yup';

export const UpsertModal = memo(
  ({
    getTemplatesById,
    getExcelColumns,
    post,
    closeModal,
    put,
    modalState,
    reportKey,
    refresh
  }: {
    modalState: { visible: boolean; templateId?: string };
    closeModal: () => void;
    post: (requestParameters: V1OutgoingReportmoduleTemplatesPostRequest) => AxiosPromise<ExcelReportTemplate>;
    put: (requestParameters: V1OutgoingReportmoduleTemplatesIdPutRequest) => AxiosPromise<ExcelReportTemplate>;
    getExcelColumns: () => AxiosPromise<Array<string>>;
    getTemplatesById: (requestParameters: V1OutgoingReportmoduleTemplatesIdGetRequest) => AxiosPromise<ExcelReportTemplate>;
    reportKey: string;
    refresh: () => void;
  }) => {
    let form = (useRef(null) as unknown) as FormikProps<V1OutgoingReportmoduleTemplatesPostRequest>;

    const [data, setData] = useState<DragAndDropState>({
      items: [],
      selected: []
    });

    /*
     *  Yeni Excel template oluşturur.
     */
    const postRequest = useApi<ExcelReportTemplate, V1OutgoingReportmoduleTemplatesPostRequest>({
      asyncFunction: post,
      successCallback: ({ data: { title } }) => {
        InfoToastify(intl.getHTML('SABLON_EKLENDI', { title }));
        closeModal();
        refresh();
      }
    });

    /*
     * Excel Template günceller.
     */
    const putRequest = useApi<ExcelReportTemplate, V1OutgoingReportmoduleTemplatesIdPutRequest>({
      asyncFunction: put,
      successCallback: ({ data: { title } }) => {
        InfoToastify(intl.getHTML('SABLON_GUNCELLENDI', { title }));
        closeModal();
      }
    });

    /*
     * Template detayına bu uç ile ulaşabilirsiniz.
     */
    const getExcelTemplatesIdRequest = useApi<ExcelReportTemplateDto, V1OutgoingReportmoduleTemplatesIdGetRequest>({
      asyncFunction: getTemplatesById
    });

    /*
     *  Şablon oluşturmak için sutunları getirir
     */
    const getExcelColumnsRequest = useApi<Array<string>, Array<AnyType>>({
      asyncFunction: getExcelColumns
    });

    /*
     * Sayfa onload oldugunda templateId varsa template güncellemek için api ucunu ve Kullanılabilir sutunları getirir.
     */
    useEffect(() => {
      if (modalState.templateId) getExcelTemplatesIdRequest.call({ id: modalState.templateId });
      getExcelColumnsRequest.call([]);
    }, []);

    /*
     * api uçlarından gelen verileri DragandDrop için state de tutma işlemi
     */
    let selectedColumns: KeyValue[] = [];
    useEffect(() => {
      if (getExcelColumnsRequest.data) {
        if (getExcelTemplatesIdRequest.data?.columns) {
          selectedColumns = [
            ...getExcelTemplatesIdRequest.data?.columns.map((item) => {
              const key = getExcelColumnsRequest.data?.find((x) => x === item) as string;
              return { key, value: intl.get(`excel.report.column.${reportKey}.${key}`) };
            })
          ];
          setData({
            selected: selectedColumns,
            items: [
              ...getExcelColumnsRequest.data
                .filter((item) => !selectedColumns.some((selected) => selected.key === item))
                .map((selected) => {
                  return { key: selected, value: intl.get(`excel.report.column.${reportKey}.${selected}`) };
                })
            ]
          });
        } else {
          setData({
            ...data,
            items: getExcelColumnsRequest.data.map((item) => {
              return { key: item, value: intl.get(`excel.report.column.${reportKey}.${item}`) };
            })
          });
        }
      }
    }, [getExcelColumnsRequest.data, getExcelTemplatesIdRequest.data]);

    /*
     * Sürükle bırak ile state günceller.
     */
    const onChange = (event: DragAndDropState) => {
      setData(event);
    };

    /*
     * Submit
     */
    const submitForm = () => {
      if (form && form.isValid) {
        if (data.selected.length < 2) DangerToastify(intl.getHTML('RAPOR_SUTUNU_SECMEK_ZORUNLU', { columnLength: 2 }));
        else form.handleSubmit();
      }
    };

    /*
     * Yup validation Şeması
     */
    const ExcelReportsScheme = Yup.object().shape<any>({
      title: Yup.string().nullable().min(3).required()
    });

    const loading = postRequest.loading || putRequest.loading || getExcelColumnsRequest.loading || getExcelTemplatesIdRequest.loading;
    return (
      <Modal
        title={
          <span>
            <FontAwesomeIcon icon={faTags} className="mr-2" /> Rapor Şablonu Tasarla
          </span>
        }
        onCancel={closeModal}
        centered
        width={920}
        transitionName="fade"
        maskClosable={false}
        visible={modalState.visible}
        footer={[
          <React.Fragment key="addresses-modal-footer">
            <Close onClick={() => closeModal()} />
            <Save onClick={submitForm} />
          </React.Fragment>
        ]}
        destroyOnClose
      >
        <RootSpinner loading={loading}>
          <Helmet title={'Şablon oluştur'} />

          {(getExcelColumnsRequest.error || getExcelTemplatesIdRequest.error || postRequest.error || putRequest.error) && (
            <ErrorViewer error={postRequest.error || putRequest.error || getExcelColumnsRequest.error || getExcelTemplatesIdRequest.error} />
          )}

          <Formik<ExcelReportTemplateDto>
            validateOnBlur={false}
            innerRef={(instance) => (form = instance)}
            onSubmit={(values) => {
              const columns = [...data.selected.map((item) => item.key)];
              modalState.templateId
                ? putRequest.call({ id: modalState.templateId, excelReportTemplateDto: { title: values.title, columns: columns } })
                : postRequest.call({ excelReportTemplateDto: { title: values.title, columns: columns } });
            }}
            validationSchema={ExcelReportsScheme}
            enableReinitialize={true}
            initialValues={
              getExcelTemplatesIdRequest.data
                ? { title: getExcelTemplatesIdRequest.data.title, columns: [] }
                : {
                    title: '',
                    columns: []
                  }
            }
          >
            <Form layout="vertical">
              <Row>
                <Col sm={24} xs={24}>
                  <FormItem label={intl.get('BASLIK')} hasFeedback name="title" required>
                    <Input prefix={<FontAwesomeIcon icon={faHeading} />} name="title" placeholder={intl.get('BASLIK')} tabIndex={0} />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col sm={24} xs={24}>
                  <FormItem name="columns" hasFeedback>
                    <DragAndDrop
                      isSearch={true}
                      isLoading={loading}
                      items={data.items}
                      selected={data.selected}
                      itemsTitle={intl.get('KULLANILABILIR_RAPOR_SUTUNLARI')}
                      selectedTitle={intl.get('SECILEN_RAPOR_SUTUNLARI')}
                      onChangeSelectedColumns={onChange}
                      key={'columns'}
                      minSelectedCount={2}
                    />
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Formik>
        </RootSpinner>
      </Modal>
    );
  }
);

export default UpsertModal;
