import {
  V1DefinitionsDocumenttemplatesGetRequest,
  V1DefinitionsDocumenttemplatesIdDeleteRequest,
  V1DefinitionsDocumenttemplatesIdGetRequest,
  V1DefinitionsDocumenttemplatesIdPreviewPostRequest,
  V1DefinitionsDocumenttemplatesIdPutRequest,
  V1DefinitionsDocumenttemplatesIdSetdefaultGetRequest,
  V1DefinitionsDocumenttemplatesPostRequest,
  V1DefinitionsSeriesGetRequest
} from '@/apis/einvoice';
import { DocumentTemplate } from '@/apis/einvoice/interfaces';
import { BooleanComponent, Breadcrumbs, InfoToastify, RootSpinner } from '@/components';
import { New, Preview, ProcessDropdownButton, Search } from '@/components/Buttons';
import SelectableBaseTable, { CellRendererProps } from '@/components/SelectableBaseTable';
import { PageStyle } from '@/constants/page';
import { useApiInConfirmation, useFileDownload, useGetPageData } from '@/hooks';
import { Col, Form, Input, Menu, Row } from 'antd';
import { AxiosPromise } from 'axios';
import React, { memo, useState } from 'react';
import { Helmet } from 'react-helmet';
import intl from 'react-intl-universal';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { updateItemsByFilter } from 'redux-toolbelt-immutable-helpers';
import { HasRoleAndNotifiy } from '../Auth';
import { Delete, DropdownDownload, Edit, SetDefault } from '../DropdownItems';
import DocumenttemplatesPreview, { DocumenttemplatesPreviewProps } from './DocumentTemplatesPreview';
import UpsertModal, { UpsertModalProps } from './UpsertModal';

type TableRowProps = CellRendererProps<DocumentTemplate, Array<DocumentTemplate>>;

export const DocumentTemplates = memo(
  ({
    get,
    post,
    put,
    setDefault,
    postXlstsPreview,
    download,
    deleteFn,
    title,
    postRole,
    putRole,
    deleteRole
  }: {
    get: (requestParameters: V1DefinitionsDocumenttemplatesGetRequest) => AxiosPromise<DocumentTemplate[]>;
    post: (requestParameters: V1DefinitionsDocumenttemplatesPostRequest) => AxiosPromise<DocumentTemplate>;
    put: (requestParameters: V1DefinitionsDocumenttemplatesIdPutRequest) => AxiosPromise<DocumentTemplate>;
    postXlstsPreview: (requestParameter: V1DefinitionsDocumenttemplatesIdPreviewPostRequest) => AxiosPromise<void>;
    setDefault: (requestParameters: V1DefinitionsDocumenttemplatesIdSetdefaultGetRequest) => AxiosPromise<void>;
    download: (requestParameters: V1DefinitionsDocumenttemplatesIdGetRequest) => AxiosPromise<void>;
    deleteFn: (requestParameters: V1DefinitionsDocumenttemplatesIdDeleteRequest) => AxiosPromise<void>;
    title: string;
    postRole: string;
    putRole: string;
    deleteRole: string;
  }) => {
    const [requestParameter, setRequestParameter] = useState<V1DefinitionsSeriesGetRequest>({ query: undefined });

    const { execute, loading, data, setData, error } = useGetPageData<Array<DocumentTemplate>>(get, requestParameter, true);

    const useXsltDownload = useFileDownload<V1DefinitionsDocumenttemplatesIdGetRequest>(download);

    const setDefaultConfirm = useApiInConfirmation<{ id: string }>({
      asyncFunction: setDefault
    });

    const xsltDelete = useApiInConfirmation<V1DefinitionsDocumenttemplatesIdDeleteRequest>({
      asyncFunction: deleteFn
    });

    const [upsertModalState, setUpsertModalState] = useState<UpsertModalProps>({
      visible: false,
      documentTemplate: undefined
    });
    const [previewModalState, setPreviewModalState] = useState<DocumenttemplatesPreviewProps>({
      visible: false,
      documentId: ''
    });
    return (
      <RootSpinner loading={loading || useXsltDownload.loading} tip={useXsltDownload.loading ? 'Önizleme hazırlanıyor, lütfen bekleyiniz' : undefined}>
        <DocumenttemplatesPreview
          closeModal={() => setPreviewModalState({ visible: false, documentId: '' })}
          postXlstsPreview={postXlstsPreview}
          modalState={previewModalState}
        />
        <UpsertModal
          closeModal={() => setUpsertModalState({ visible: false, documentTemplate: undefined })}
          refresh={execute}
          post={post}
          put={put}
          modalState={upsertModalState}
        />
        <Helmet title={title} />
        <Card className="h-100">
          <CardHeader className={PageStyle.card__header}>
            <Breadcrumbs />
            <div>
              <New onClick={() => HasRoleAndNotifiy(postRole) && setUpsertModalState({ visible: true, documentTemplate: undefined })} />{' '}
              <Search onClick={() => execute()} />
            </div>
          </CardHeader>
          <CardBody className={PageStyle.virtualized__card__body}>
            <Form layout="vertical">
              <Row>
                <Col sm={24} xs={24}>
                  <Form.Item label={intl.get('ARANACAK_KELIME')}>
                    <Input
                      defaultValue={requestParameter?.query}
                      onChange={(input) => setRequestParameter({ ...requestParameter, query: input.target.value })}
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                          execute();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Row className="flex-fill">
              <SelectableBaseTable
                fixed
                pageData={data}
                error={error}
                data={data || []}
                rowKey="id"
                notSelectable
                requestParameters={requestParameter}
                columns={[
                  {
                    key: `preview`,
                    minWidth: 40,
                    width: 40,
                    align: 'center',
                    cellRenderer: ({ rowData }: TableRowProps) => {
                      return <Preview onClick={() => setPreviewModalState({ visible: true, documentId: rowData.id })} />;
                    }
                  },
                  {
                    key: `title`,
                    dataKey: `title`,
                    title: intl.get('BASLIK'),
                    width: 150,
                    minWidth: 150,
                    className: 'base-table-flex',
                    headerClassName: 'base-table-flex'
                  },

                  {
                    key: `isDefault`,
                    dataKey: `isDefault`,
                    title: intl.get('VARSAYILAN'),
                    width: 145,
                    minWidth: 145,
                    cellRenderer: ({ rowData }: TableRowProps) => {
                      return <BooleanComponent value={rowData.isDefault} />;
                    }
                  },
                  {
                    key: `process`,
                    title: intl.get('ISLEMLER'),
                    minWidth: 75,
                    width: 75,
                    align: 'center',
                    cellRenderer: ({
                      rowData,
                      container: {
                        props: { pageData }
                      }
                    }: TableRowProps) => {
                      return (
                        <ProcessDropdownButton
                          isRow
                          menu={
                            <Menu>
                              <DropdownDownload onClick={() => useXsltDownload.download({ id: rowData.id })} />

                              {!rowData.isDefault && <Menu.Divider />}
                              {!rowData.isDefault && (
                                <Delete
                                  onClick={() => {
                                    HasRoleAndNotifiy(deleteRole) &&
                                      xsltDelete.confirm(
                                        { id: rowData.id },
                                        {
                                          content: intl.getHTML('SABLON_SILINECEKTIR_DEVAM_EDILSIN_MI', {
                                            title: rowData.title
                                          }),
                                          title: `Şablon Sil - ${rowData.id}`,
                                          successCallback: () => {
                                            setData(pageData.filter((x) => x.id !== rowData.id));
                                            InfoToastify(intl.getHTML('SABLON_SILINDI', { title: rowData.title }));
                                          }
                                        }
                                      );
                                  }}
                                />
                              )}
                              {!rowData.isDefault && (
                                <SetDefault
                                  onClick={() =>
                                    HasRoleAndNotifiy(putRole) &&
                                    setDefaultConfirm.confirm(
                                      { id: rowData.id },
                                      {
                                        content: intl.getHTML('SABLON_VARSAYILAN_AYARLANACAKTIR_DEVAM_EDILSIN_MI', { title: rowData.title }),
                                        title: `Şablon Varsayılan Ayarla - ${rowData.title} - ${rowData.id}`,
                                        successCallback: () => {
                                          const newData = updateItemsByFilter(
                                            pageData,
                                            (item: DocumentTemplate) => item.isDefault,
                                            (item) => ({ ...item, isDefault: false })
                                          );

                                          setData(
                                            updateItemsByFilter(
                                              newData,
                                              (item: DocumentTemplate) => item.id === rowData.id,
                                              (item) => ({ ...item, isDefault: true })
                                            )
                                          );

                                          InfoToastify(intl.getHTML('SABLON_VARSAYILAN_AYARLANDI', { title: rowData.title }));
                                        }
                                      }
                                    )
                                  }
                                />
                              )}

                              <Menu.Divider />
                              <Edit onClick={() => HasRoleAndNotifiy(putRole) && setUpsertModalState({ visible: true, documentTemplate: rowData })} />
                            </Menu>
                          }
                        />
                      );
                    }
                  }
                ]}
              />
            </Row>
          </CardBody>
        </Card>
      </RootSpinner>
    );
  }
);

export default DocumentTemplates;
