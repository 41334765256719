import { Invoice } from '@/apis/earchive/interfaces';
import { getFullName } from '@/components';
import { DateFormat } from '@/constants/page';
import moment from 'moment';
import React from 'react';
import style from '../style.module.scss';

const InvoiceListItem = ({ item, onClickRow, key }: { item: Invoice; onClickRow: (invoice: Invoice) => void; key: string }) => {
  const fullName = getFullName({
    familyName: item.accountingCustomerParty.familyName,
    firstName: item.accountingCustomerParty.firstName,
    partyName: item.accountingCustomerParty.partyName,
    partyIdentification: item.accountingCustomerParty.partyIdentification,
    truncate: 20
  });

  const s = fullName.split(' ');
  const avatar = s.length === 1 ? s[0].charAt(0).toUpperCase() + s[0].charAt(1).toUpperCase() : s[0].charAt(0).toUpperCase() + s[1].charAt(0).toUpperCase();

  return (
    <li className={style.item} key={key}>
      <a href="#" className={style.itemLink} onClick={() => onClickRow(item)}>
        <div className={`${style.itemPic}`}>
          <b>
            {item.accountingCustomerParty.partyIdentification.length === 11
              ? item.accountingCustomerParty.firstName.charAt(0) + item.accountingCustomerParty.familyName.charAt(0)
              : avatar}
          </b>
        </div>
        <div className="mr-1">
          <b>{item.documentNumber}</b>
          <div className="text-muted">{fullName}</div>
        </div>
        <div className="ml-auto text-right text-nowrap mr-2">
          <div>{moment(item.issueDate).format(DateFormat.ymd)}</div>
          <div className="text-success">
            {item.payableAmount} {item.documentCurrencyCode}
          </div>
        </div>
      </a>
    </li>
  );
};

export default InvoiceListItem;
