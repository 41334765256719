/* eslint-disable @typescript-eslint/no-inferrable-types */

import { XMLElement } from '@/xmldom-decorators';
import { TaxSchemeType } from '.';
import { DefaultValue, NS_CAC, NS_CBC } from '../constants';

/*
  27.04.2021 : Fully completed
*/
export class TaxCategoryType {
  @XMLElement({ types: [{ name: 'Name', namespaceUri: NS_CBC, itemType: () => String }] })
  name?: string;

  @XMLElement({ types: [{ name: 'TaxExemptionReasonCode', namespaceUri: NS_CBC, itemType: () => String }] })
  taxExemptionReasonCode?: string;

  @XMLElement({ types: [{ name: 'TaxExemptionReason', namespaceUri: NS_CBC, itemType: () => String }] })
  taxExemptionReason?: string;

  @XMLElement({ types: [{ name: 'TaxScheme', namespaceUri: NS_CAC, itemType: () => TaxSchemeType }] })
  taxScheme: TaxSchemeType = DefaultValue;
}

export default TaxCategoryType;
