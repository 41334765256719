import { ALLROLES, GetScopeOrRoleList } from '@/constants/auth';
import { AnyType } from '@/type';
import { BugTwoTone, LockOutlined } from '@ant-design/icons';
import { Alert, Empty, Result } from 'antd';
import React, { memo } from 'react';
import intl from 'react-intl-universal';
import styles from './style.module.scss';
export const ErrorViewer = memo(({ error }: { error: AnyType }) => {
  const httpStatusCode = error.response.status;
  if (httpStatusCode >= 500) {
    return (
      <div className="table__body__center">
        <div className={styles.error__title}>
          <BugTwoTone style={{ fontSize: '48px' }} twoToneColor={'#ffa34f'} />
          {error.response && <h1>{error.response.status}</h1>}
        </div>

        <h2 className={styles.error__message}>OOPS! Beklenmeyen Hata!</h2>

        {error.response &&
          error.response.headers &&
          error.response.headers['x-exception-handler-request-id'] &&
          `${error.response.headers['x-exception-handler-request-id']}`}
      </div>
    );
  }

  if (httpStatusCode == 422) {
    const errors = error.response && error.response.data && error.response.data.errors;
    return (
      <div>
        <p className={styles.error__description}>
          <>
            {errors.map(({ code, description, detail }, index) => {
              //INVALID_VALUE
              if (code == '422-01') {
                return (
                  <Alert
                    key={`error-${index}`}
                    type="warning"
                    description={detail}
                    message={intl.getHTML(code)}
                    showIcon
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  />
                );
              }

              //MUST_HAVE_AT_LEAST_ONE_DEFAULT_RECORD
              if (code == '422-05') {
                return <Alert key={`error-${index}`} type="warning" message={intl.get(code)} showIcon style={{ paddingLeft: 0, paddingRight: 0 }} />;
              }

              //RECORD_HAS_TRANSACTION
              if (code == '422-10') {
                return (
                  <Alert
                    key={`error-${index}`}
                    type="warning"
                    description={detail}
                    message={intl.getHTML(code)}
                    showIcon
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  />
                );
              }

              //RECORD_ALREADY_CANCELED
              if (code == '422-11') {
                return (
                  <Alert
                    key={`error-${index}`}
                    type="warning"
                    description={detail}
                    message={intl.getHTML(code)}
                    showIcon
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  />
                );
              }

              //RECORD_EXISTS
              if (code == '422-15') {
                return (
                  <Alert
                    key={`error-${index}`}
                    type="warning"
                    description={detail}
                    message={intl.getHTML(code)}
                    showIcon
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  />
                );
              }

              //RECORD_NOT_EXISTS
              if (code == '422-16') {
                return (
                  <Alert
                    key={`error-${index}`}
                    type="warning"
                    description={detail}
                    message={intl.getHTML(code)}
                    showIcon
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  />
                );
              }

              //SPECIAL_XSLT_NOT_EXIST
              if (code == '422-20') {
                return (
                  <Alert
                    key={`error-${index}`}
                    type="warning"
                    description={detail}
                    message={intl.getHTML(code)}
                    showIcon
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  />
                );
              }

              //DuplicateUserName
              if (code == 'DuplicateUserName') {
                return (
                  <Alert
                    key={`error-${index}`}
                    type="warning"
                    description={description}
                    message={intl.getHTML(code)}
                    showIcon
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  />
                );
              }

              //TODO : Dil kullanımına geçildiğinde detail yerine description kullanılıp localize edilebilir.
              return <Alert key={`error-${index}`} type="warning" message={`${code}:${detail}`} showIcon style={{ paddingLeft: 0, paddingRight: 0 }} />;
            })}
          </>
        </p>
      </div>
    );
  }

  if (httpStatusCode == 409) {
    return (
      <div>
        <p className={styles.error__description}>
          <Alert key={`error-409`} type="error" message={intl.get('409')} showIcon style={{ paddingLeft: 0, paddingRight: 0 }} />
        </p>
      </div>
    );
  }

  if (httpStatusCode == 400) {
    const errors = error.response && error.response.data && error.response.data.invalidFields;

    return (
      <div>
        <p className={styles.error__description}>
          {errors.map(({ field, description, detail }, index) => (
            <Alert key={`error-400-${field}`} type="error" message={description} showIcon style={{ paddingLeft: 0, paddingRight: 0 }} />
          ))}
        </p>
      </div>
    );
  }

  if (httpStatusCode == 403) {
    return (
      <Result
        className="table__body__center"
        style={{ padding: 0 }}
        status="warning"
        icon={<LockOutlined />}
        title={
          <div>
            <span>Yetkisiz işlem. </span>
            <Alert style={{ marginTop: 20 }} message={GetScopeOrRoleList(ALLROLES).find((x) => x.value === error.response.data.role)?.content} type="warning" />
          </div>
        }
        extra={<div>İşlemi gerçekleştirebilmeniz için yukarıda belirtilen yetkinin tanımlanması gerekmektedir.</div>}
      />
    );
  }

  return <Empty className="table__body__center" />;
});

export default ErrorViewer;
