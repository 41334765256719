import { faMailbox, faSms } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { memo } from 'react';
export const MailInfo = memo(
  ({
    sendType,
    succedMailCount,
    readedMailCount,
    failedMailCount,
    isSms
  }: {
    sendType: string;
    succedMailCount: number;
    readedMailCount: number;
    failedMailCount: number;
    isSms: boolean;
  }) => {
    if (sendType === 'KAGIT') {
      return <span />;
    } else {
      return (
        <div>
          <FontAwesomeIcon icon={isSms ? faSms : faMailbox} className="mr-2" />
          <b style={{ color: '#00a509' }}>B :</b> <span>{succedMailCount}</span>
          {' | '}
          <b style={{ color: '#0ab1fc' }}>O :</b> <span>{readedMailCount}</span>
          {' | '}
          <b style={{ color: '#fb0000' }}>H :</b> <span>{failedMailCount}</span>
        </div>
      );
    }
  }
);

export default MailInfo;
