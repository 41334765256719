/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLArray, XMLElement } from '@/xmldom-decorators';
import { v4 as uuidv4 } from 'uuid';
import { IDType, ItemType, OrderLineReferenceType, QuantityType, ShipmentType, TextType } from '.';
import { NS_CAC, NS_CBC } from '../constants';
import DocumentReferenceType from './DocumentReferenceType';

/*
  10.05.2021 : Partially completed
  cac:DocumentReference 
  cbc:OutstandingQuantity
  cbc:OutstandingReason 
  cbc:OversupplyQuantity 
  cac:Shipment
*/
export class DespatchLineType {
  @XMLElement({ types: [{ name: 'ID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  id?: IDType;

  @XMLArray({ nested: false, itemTypes: [{ name: 'Note', namespaceUri: NS_CBC, itemType: () => String }] })
  note?: string[];

  @XMLElement({ types: [{ name: 'DeliveredQuantity', namespaceUri: NS_CBC, itemType: () => QuantityType }] })
  deliveredQuantity: QuantityType = { unitCode: 'C62', value: 1 };

  @XMLElement({ types: [{ name: 'OutstandingQuantity', namespaceUri: NS_CBC, itemType: () => QuantityType }] })
  outstandingQuantity?: QuantityType;

  @XMLArray({ nested: false, itemTypes: [{ name: 'OutstandingReason', namespaceUri: NS_CAC, itemType: () => TextType }] })
  outstandingReason: TextType[] = [];

  @XMLElement({ types: [{ name: 'OversupplyQuantity', namespaceUri: NS_CBC, itemType: () => QuantityType }] })
  oversupplyQuantity?: QuantityType;

  @XMLElement({ types: [{ name: 'OrderLineReference', namespaceUri: NS_CAC, itemType: () => OrderLineReferenceType }] })
  orderLineReference?: OrderLineReferenceType;

  @XMLArray({ nested: false, itemTypes: [{ name: 'DocumentReference', namespaceUri: NS_CAC, itemType: () => DocumentReferenceType }] })
  documentReference: DocumentReferenceType[] = [];

  @XMLElement({ types: [{ name: 'Item', namespaceUri: NS_CAC, itemType: () => ItemType }] })
  item?: ItemType;

  @XMLArray({ nested: false, itemTypes: [{ name: 'Shipment', namespaceUri: NS_CAC, itemType: () => ShipmentType }] })
  shipment: ShipmentType[] = [];

  uniqueId: string = uuidv4();
}

export default DespatchLineType;
