export * from './decorators';
export * from './deserializer';
export * from './serializer';

export const formatXml = (xml, tab) => {
  // tab = optional indent value, default is tab (\t)
  let formatted = '',
    indent = '';
  tab = tab || '\t';
  xml.split(/>\s*</).forEach(function (node) {
    if (node.match(/^\/\w/)) indent = indent.substring(tab.length); // decrease indent by one 'tab'
    formatted += indent + '<' + node + '>\r\n';
    if (node.match(/^<?\w[^>]*[^\/]$/)) indent += tab; // increase indent
  });
  return formatted.substring(1, formatted.length - 3);
};
