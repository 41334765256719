/* eslint-disable @typescript-eslint/naming-convention */

import { AnnouncementsApiFactory, V1AnnouncementsIdAttachmentAttachmentIdGetRequest, V1AnnouncementsIdCoverimageGetRequest } from '@/apis/general';
import { AnnouncementDashboardDto, AnnouncementModalSize } from '@/apis/general/interfaces';
import { useFileDownload, useGetPageData } from '@/hooks';
import { BlobResponse } from '@/services/api/constants';
import { CloudDownloadOutlined, CommentOutlined } from '@ant-design/icons';
import { faNewspaper } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Tooltip } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import React, { useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import CreateAnnouncementRatingModal from './createAnnouncementRatingModal';

const announcementApiFactory = AnnouncementsApiFactory();

const AnnouncementModal = ({
  announcement,
  visible,
  setVisible
}: {
  setVisible: (visible: boolean, id: string) => void;
  visible: boolean;
  announcement?: AnnouncementDashboardDto;
}) => {
  if (announcement == undefined) {
    return null;
  }

  const coverImage = useGetPageData<string>(
    announcementApiFactory.v1AnnouncementsIdCoverimageGet,
    {
      id: announcement.id
    } as V1AnnouncementsIdCoverimageGetRequest,
    true
  );

  const useAttachmentDownload = useFileDownload<V1AnnouncementsIdAttachmentAttachmentIdGetRequest>(
    AnnouncementsApiFactory(BlobResponse).v1AnnouncementsIdAttachmentAttachmentIdGet
  );

  const onCancel = () => {
    setVisible(false, announcement.id!);
    if (announcement.isRead === false) {
      announcementApiFactory.v1AnnouncementsAnnouncementIdSetreadedPost({ announcementId: announcement.id! });
    }
  };

  const [feedBackVisible, setFeedBackVisible] = useState(false);

  const [isRated, setIsRated] = useState(false);

  let extraButtons = [
    <div key={'attachments'} style={{ float: 'left' }}>
      {announcement.attachments?.map((attachment, index) => {
        return (
          <Tooltip title={`${attachment.fileName} -  ${attachment.description}`} placement="bottom">
            <Button
              loading={useAttachmentDownload.loading}
              key={`attachment-${attachment.id}`}
              onClick={() => useAttachmentDownload.download({ attachmentId: attachment.id!, id: announcement.id! })}
            >
              <CloudDownloadOutlined />
            </Button>
          </Tooltip>
        );
      })}
    </div>
  ];

  if (announcement.isRatedAnnouncement !== true && isRated !== true) {
    extraButtons = [
      ...extraButtons,
      <Button key="back" onClick={() => setFeedBackVisible(true)}>
        <CommentOutlined />
        Duyuruyu Değerlendir
      </Button>
    ];
  }

  extraButtons = [
    ...extraButtons,
    <Button key="submit" type="primary" onClick={onCancel}>
      Kapat
    </Button>
  ];

  return (
    <Modal
      visible={visible}
      className="announcement-modal"
      width={announcement.announcementModalSize == AnnouncementModalSize.Medium ? 720 : 720}
      title={
        <>
          <FontAwesomeIcon icon={faNewspaper} /> {announcement.title}
        </>
      }
      onCancel={onCancel}
      footer={extraButtons}
    >
      {feedBackVisible && (
        <CreateAnnouncementRatingModal
          announcement={announcement}
          visible={feedBackVisible}
          setVisible={(v, i, isRated) => {
            setFeedBackVisible(v);

            if (isRated === true) {
              setIsRated(true);
            }
          }}
        />
      )}

      <Sider
        width={720}
        style={{
          margin: '0px',
          padding: '0px',
          overflowY: 'auto',
          height: announcement.modalHeight || 600,
          zIndex: 998
        }}
        collapsed={false}
        theme={'light'}
      >
        <Scrollbars className="custom-scrollbar" autoHide autoHideTimeout={500} autoHideDuration={200}>
          {coverImage.data && <img src={coverImage.data!} style={{ marginBottom: 32, marginLeft: '-1px' }} />}

          <div className="announcement-content" dangerouslySetInnerHTML={{ __html: announcement.content! }} />
        </Scrollbars>
      </Sider>
    </Modal>
  );
};

export default AnnouncementModal;
