/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { DocumentReferenceType, FinancialAccountType, IDType } from '.';
import { NS_CAC, NS_CBC } from '../constants';

/*
  27.04.2021 : Partially completed
  cac:FinancialAccount
*/
export class PersonType {
  @XMLElement({ types: [{ name: 'FirstName', namespaceUri: NS_CBC, itemType: () => String }] })
  firstName?: string;

  @XMLElement({ types: [{ name: 'FamilyName', namespaceUri: NS_CBC, itemType: () => String }] })
  familyName?: string;

  @XMLElement({ types: [{ name: 'Title', namespaceUri: NS_CBC, itemType: () => String }] })
  title?: string;

  @XMLElement({ types: [{ name: 'MiddleName', namespaceUri: NS_CBC, itemType: () => String }] })
  middleName?: string;

  @XMLElement({ types: [{ name: 'NameSuffix', namespaceUri: NS_CBC, itemType: () => String }] })
  nameSuffix?: string;

  @XMLElement({ types: [{ name: 'NationalityID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  nationalityID?: IDType;

  @XMLElement({ types: [{ name: 'FinancialAccount', namespaceUri: NS_CAC, itemType: () => FinancialAccountType }] })
  financialAccount?: FinancialAccountType;

  @XMLElement({ types: [{ name: 'IdentityDocumentReference', namespaceUri: NS_CAC, itemType: () => DocumentReferenceType }] })
  identityDocumentReference?: DocumentReferenceType;
}

export default PersonType;
