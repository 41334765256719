// tslint:disable
// eslint:disable
/**
 * General API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CodeInfo } from '../interfaces';
// @ts-ignore
import { GibTaxType } from '../interfaces';
/**
 * StaticCodesApi - axios parameter creator
 * @export
 */
export const StaticCodesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Detay : Kullanılabilir para birimilerine bu uç ile ulaşabilirsiniz
         * @summary Para birimlerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StaticcodesCurrencyGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/staticcodes/currency`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detay : Kullanılabilir vergi muafiyetlerine bu uç ile ulaşabilirsiniz
         * @summary Vergi muafiyet kodlarını listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StaticcodesTaxexemptionreasonGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/staticcodes/taxexemptionreason`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detay : Kullanılabilir vergi tiplerine bu uç ile ulaşabilirsiniz
         * @summary Vergi tiplerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StaticcodesTaxtypeGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/staticcodes/taxtype`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detay : Kullanılabilir tevkifat tiplerine bu uç ile ulaşabilirsiniz
         * @summary Tevkifat tiplerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StaticcodesWithholdingtaxtypeGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/staticcodes/withholdingtaxtype`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StaticCodesApi - functional programming interface
 * @export
 */
export const StaticCodesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Detay : Kullanılabilir para birimilerine bu uç ile ulaşabilirsiniz
         * @summary Para birimlerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1StaticcodesCurrencyGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CodeInfo>>> {
            const localVarAxiosArgs = await StaticCodesApiAxiosParamCreator(configuration).v1StaticcodesCurrencyGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Detay : Kullanılabilir vergi muafiyetlerine bu uç ile ulaşabilirsiniz
         * @summary Vergi muafiyet kodlarını listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1StaticcodesTaxexemptionreasonGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CodeInfo>>> {
            const localVarAxiosArgs = await StaticCodesApiAxiosParamCreator(configuration).v1StaticcodesTaxexemptionreasonGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Detay : Kullanılabilir vergi tiplerine bu uç ile ulaşabilirsiniz
         * @summary Vergi tiplerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1StaticcodesTaxtypeGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GibTaxType>>> {
            const localVarAxiosArgs = await StaticCodesApiAxiosParamCreator(configuration).v1StaticcodesTaxtypeGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Detay : Kullanılabilir tevkifat tiplerine bu uç ile ulaşabilirsiniz
         * @summary Tevkifat tiplerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1StaticcodesWithholdingtaxtypeGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GibTaxType>>> {
            const localVarAxiosArgs = await StaticCodesApiAxiosParamCreator(configuration).v1StaticcodesWithholdingtaxtypeGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StaticCodesApi - factory interface
 * @export
 */
export const StaticCodesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Detay : Kullanılabilir para birimilerine bu uç ile ulaşabilirsiniz
         * @summary Para birimlerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StaticcodesCurrencyGet(options?: any): AxiosPromise<Array<CodeInfo>> {
            return StaticCodesApiFp(configuration).v1StaticcodesCurrencyGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Detay : Kullanılabilir vergi muafiyetlerine bu uç ile ulaşabilirsiniz
         * @summary Vergi muafiyet kodlarını listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StaticcodesTaxexemptionreasonGet(options?: any): AxiosPromise<Array<CodeInfo>> {
            return StaticCodesApiFp(configuration).v1StaticcodesTaxexemptionreasonGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Detay : Kullanılabilir vergi tiplerine bu uç ile ulaşabilirsiniz
         * @summary Vergi tiplerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StaticcodesTaxtypeGet(options?: any): AxiosPromise<Array<GibTaxType>> {
            return StaticCodesApiFp(configuration).v1StaticcodesTaxtypeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Detay : Kullanılabilir tevkifat tiplerine bu uç ile ulaşabilirsiniz
         * @summary Tevkifat tiplerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StaticcodesWithholdingtaxtypeGet(options?: any): AxiosPromise<Array<GibTaxType>> {
            return StaticCodesApiFp(configuration).v1StaticcodesWithholdingtaxtypeGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StaticCodesApi - object-oriented interface
 * @export
 * @class StaticCodesApi
 * @extends {BaseAPI}
 */
export class StaticCodesApi extends BaseAPI {
    /**
     * Detay : Kullanılabilir para birimilerine bu uç ile ulaşabilirsiniz
     * @summary Para birimlerini listeler
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaticCodesApi
     */
    public v1StaticcodesCurrencyGet(options?: any) {
        return StaticCodesApiFp(this.configuration).v1StaticcodesCurrencyGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Detay : Kullanılabilir vergi muafiyetlerine bu uç ile ulaşabilirsiniz
     * @summary Vergi muafiyet kodlarını listeler
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaticCodesApi
     */
    public v1StaticcodesTaxexemptionreasonGet(options?: any) {
        return StaticCodesApiFp(this.configuration).v1StaticcodesTaxexemptionreasonGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Detay : Kullanılabilir vergi tiplerine bu uç ile ulaşabilirsiniz
     * @summary Vergi tiplerini listeler
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaticCodesApi
     */
    public v1StaticcodesTaxtypeGet(options?: any) {
        return StaticCodesApiFp(this.configuration).v1StaticcodesTaxtypeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Detay : Kullanılabilir tevkifat tiplerine bu uç ile ulaşabilirsiniz
     * @summary Tevkifat tiplerini listeler
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaticCodesApi
     */
    public v1StaticcodesWithholdingtaxtypeGet(options?: any) {
        return StaticCodesApiFp(this.configuration).v1StaticcodesWithholdingtaxtypeGet(options).then((request) => request(this.axios, this.basePath));
    }

}
