import { OnClickProps } from '@/interfaces';
import { AnyType } from '@/type';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';

/**
 * Inputlarda kullanmak üzere oluşturulan butonlar. afterAddon / beforeAddon olarak geçiyor antd içerisinde.
 */
export const SearchInAddon = ({ onClick }: OnClickProps) => {
  const input = useRef<AnyType>(null);

  useEffect(() => {
    if (input.current) {
      const element = (input.current as HTMLDivElement).parentElement;
      if (element) {
        element.style.padding = `0px`;
        element.style['background-color'] = `#92c755`;
        element.style['border-color'] = `#8bc34a`;
        element.style['color'] = `white`;
      }
    }
  }, []);

  return (
    <div style={{ padding: '0 13px', cursor: 'pointer' }} onClick={onClick} ref={input}>
      <FontAwesomeIcon icon={faSearch} />
    </div>
  );
};

export default SearchInAddon;
