/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { AmountType, TextType } from '.';
import { NS_CBC } from '../constants';
import IDType from './IDType';

/*
  27.04.2021 : Fully completed
*/
export class DeliveryTermsType {
  @XMLElement({ types: [{ name: 'ID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  id?: IDType;

  @XMLElement({ types: [{ name: 'SpecialTerms', namespaceUri: NS_CBC, itemType: () => TextType }] })
  specialTerms?: TextType;

  @XMLElement({ types: [{ name: 'Amount', namespaceUri: NS_CBC, itemType: () => AmountType }] })
  amount?: AmountType;
}

export default DeliveryTermsType;
