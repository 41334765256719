import { ActiveStatus } from '@/apis/einvoice/interfaces';
import { CheckCircleTwoTone, DeleteTwoTone, StopTwoTone } from '@ant-design/icons';
import { Select } from 'antd';
import React from 'react';

const { Option } = Select;

export const ActiveStatusFilter = ({ status = ActiveStatus.Active, onChange }: { status?: ActiveStatus; onChange?: (value) => void }) => {
  return (
    <Select defaultValue={status} style={{ width: '100%' }} onChange={onChange}>
      <Option value={ActiveStatus.Active}>
        <CheckCircleTwoTone twoToneColor="#52c41a" className="mr-2" />
        Aktif
      </Option>
      <Option value={ActiveStatus.Passive}>
        <StopTwoTone twoToneColor="#bfa722" className="mr-2" />
        Pasif
      </Option>
      <Option value={ActiveStatus.Deleted}>
        <DeleteTwoTone twoToneColor="red" className="mr-2" />
        Silindi
      </Option>
    </Select>
  );
};

export default ActiveStatusFilter;
