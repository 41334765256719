/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLArray, XMLElement } from '@/xmldom-decorators';
import { CodeType, IDType, PeriodType, TextType } from '.';
import { NS_CAC, NS_CBC } from '../constants';
import PersonType from './PersonType';
import TransportMeansType from './TransportMeansType';

/*
  19.04.2022 : Partially completed
*/
export class ShipmentStageType {
  @XMLElement({ types: [{ name: 'ID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  id?: IDType;

  @XMLElement({ types: [{ name: 'TransportModeCode', namespaceUri: NS_CBC, itemType: () => CodeType }] })
  transportModeCode?: CodeType;

  @XMLElement({ types: [{ name: 'TransportMeansTypeCode', namespaceUri: NS_CBC, itemType: () => CodeType }] })
  transportMeansTypeCode?: CodeType;

  @XMLElement({ types: [{ name: 'TransitDirectionCode', namespaceUri: NS_CBC, itemType: () => CodeType }] })
  transitDirectionCode?: CodeType;

  @XMLArray({ nested: false, itemTypes: [{ name: 'Instructions', namespaceUri: NS_CBC, itemType: () => TextType }] })
  instructions?: TextType[];

  @XMLElement({ types: [{ name: 'TransitPeriod', namespaceUri: NS_CAC, itemType: () => PeriodType }] })
  transitPeriod?: PeriodType;

  @XMLElement({ types: [{ name: 'TransportMeans', namespaceUri: NS_CAC, itemType: () => TransportMeansType }] })
  transportMeans?: TransportMeansType;

  @XMLArray({ nested: false, itemTypes: [{ name: 'DriverPerson', namespaceUri: NS_CAC, itemType: () => PersonType }] })
  driverPerson?: PersonType[];
}

export default ShipmentStageType;
