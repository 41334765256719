import { ExcelReportQueue, ExcelReportQueuePaginationResult, RecordStatus } from '@/apis/earchive/interfaces';
import { V1OutgoingReportmoduleReportsGetRequest, V1OutgoingReportmoduleReportsIdDownloadGetRequest } from '@/apis/einvoice';
import { Breadcrumbs, DateRange, RecordStatusComp, RootSpinner } from '@/components';
import { } from '@/components/';
import { Download, Search } from '@/components/Buttons';
import LocalPagination from '@/components/Pagination/LocalPagination';
import SelectableBaseTable, { CellRendererProps } from '@/components/SelectableBaseTable';
import { DateFormat, PageStyle } from '@/constants/page';
import { useFileDownload, useGetPageData } from '@/hooks';
import { Col, Form, Input, Row } from 'antd';
import { AxiosPromise } from 'axios';
import moment from 'moment';
import React, { memo, useState } from 'react';
import { Helmet } from 'react-helmet';
import intl from 'react-intl-universal';
import { Card, CardBody, CardHeader } from 'reactstrap';

type TableRowProps = CellRendererProps<ExcelReportQueue, Array<ExcelReportQueue>>;
export const ExcelReports = memo(
  ({
    title,
    download,
    getReports
  }: {
    title: string;
    getReports: (requestParameters: V1OutgoingReportmoduleReportsGetRequest) => AxiosPromise<ExcelReportQueuePaginationResult>;
    download: (requestParameters: V1OutgoingReportmoduleReportsIdDownloadGetRequest) => AxiosPromise<void>;
  }) => {
    const [state, setState] = useState<V1OutgoingReportmoduleReportsGetRequest>({
      page: 1,
      pageSize: 50,
      query: undefined,
      sort: 'CreatedAt desc'
    });

    const useExcelDownload = useFileDownload<V1OutgoingReportmoduleReportsIdDownloadGetRequest>(download);
    const { execute, loading, data, setData, error } = useGetPageData<ExcelReportQueuePaginationResult>(getReports, state, true);
    return (
      <RootSpinner loading={loading || useExcelDownload.loading}>
        <Helmet title={title} />
        <Card className="h-100">
          <CardHeader className={PageStyle.card__header}>
            <Breadcrumbs />
            <Search onClick={execute} />
          </CardHeader>
          <CardBody className={PageStyle.virtualized__card__body}>
            <Form layout="vertical">
              <Row>
                <Col sm={24} xs={24}>
                  <Form.Item label={intl.get('ARANACAK_KELIME')}>
                    <Input
                      defaultValue={state?.query || ''}
                      onChange={(input) => setState({ ...state, query: input.target.value })}
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                          execute();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Row className="flex-fill">
              <SelectableBaseTable
                fixed
                pageData={data}
                error={error}
                data={(data && data.data) || []}
                rowKey="id"
                notSelectable
                requestParameters={state}
                columns={[
                  {
                    key: `title`,
                    dataKey: `title`,
                    title: intl.get('BASLIK'),
                    width: 150,
                    minWidth: 150,
                    className: 'base-table-flex',
                    headerClassName: 'base-table-flex'
                  },
                  {
                    key: `createdAt`,
                    minWidth: 150,
                    title: 'Talep Zamanı',
                    width: 150,
                    cellRenderer: ({ rowData }: TableRowProps) => {
                      return <b>{moment(rowData.createdAt).format(DateFormat.dmyHMS)}</b>;
                    }
                  },

                  {
                    key: `dateRange`,
                    minWidth: 200,
                    title: intl.get('TARIH_ARALIGI'),
                    width: 200,
                    cellRenderer: ({ rowData }: TableRowProps) => {
                      return <DateRange startDate={rowData.startDate} endDate={rowData.endDate} format={DateFormat.dmy} />;
                    }
                  },
                  {
                    key: `electronicMail`,
                    title: intl.get('MAIL'),
                    minWidth: 230,
                    width: 230,
                    cellRenderer: ({ rowData }: TableRowProps) => {
                      if (!rowData.electronicMail) return '';

                      return <div>{rowData.electronicMail}</div>;
                    }
                  },
                  {
                    key: `recordStatus`,
                    title: intl.get('DURUM'),
                    minWidth: 230,
                    width: 230,
                    cellRenderer: ({ rowData }: TableRowProps) => {
                      return <RecordStatusComp recordStatus={rowData.recordStatus || RecordStatus.None} />;
                    }
                  },
                  {
                    key: `process`,
                    title: intl.get('INDIR'),
                    minWidth: 60,
                    width: 60,
                    align: 'center',
                    cellRenderer: ({ rowData }: TableRowProps) => {
                      if (rowData.recordStatus === RecordStatus.Succeed) {
                        return <Download onClick={() => useExcelDownload.download({ id: rowData.id })} onlyIcon />;
                      }

                      return '';
                    }
                  }
                ]}
              />
            </Row>
          </CardBody>
          {data && data.data && data.data.length > 0 && (
            <LocalPagination
              data={data}
              selectedCount={0}
              execute={execute}
              page={state.page}
              pageSize={state.pageSize}
              onPageChange={(page, pageSize) => {
                setState({ ...state, page, pageSize });
              }}
            />
          )}
        </Card>
      </RootSpinner>
    );
  }
);

export default ExcelReports;
