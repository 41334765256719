import { XMLElement } from '@/xmldom-decorators';
import { NS_CAC, NS_CBC } from '../constants';
import { FinancialInstitutionType } from './FinancialInstitutionType';

/*
  27.04.2021 : Fully completed
*/
export class BranchType {
  @XMLElement({ types: [{ name: 'Name', namespaceUri: NS_CBC, itemType: () => String }] })
  name?: string;

  @XMLElement({ types: [{ name: 'FinancialInstitution', namespaceUri: NS_CAC, itemType: () => FinancialInstitutionType }] })
  financialInstitution?: FinancialInstitutionType;
}
