/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { AttachmentType, IDType, PartyType, PeriodType } from '.';
import { NS_CAC, NS_CBC } from '../constants';

/*
  27.04.2021 : Fully completed
*/
export class DocumentReferenceType {
  @XMLElement({ types: [{ name: 'ID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  id?: IDType;

  @XMLElement({ types: [{ name: 'IssueDate', namespaceUri: NS_CBC, itemType: () => String }] })
  issueDate?: string;

  @XMLElement({ types: [{ name: 'DocumentTypeCode', namespaceUri: NS_CBC, itemType: () => String }] })
  documentTypeCode?: string;

  @XMLElement({ types: [{ name: 'DocumentType', namespaceUri: NS_CBC, itemType: () => String }] })
  documentType?: string;

  @XMLElement({ types: [{ name: 'DocumentDescription', namespaceUri: NS_CBC, itemType: () => String }] })
  documentDescription?: string;

  @XMLElement({ types: [{ name: 'Attachment', namespaceUri: NS_CAC, itemType: () => AttachmentType }] })
  attachment?: AttachmentType;

  @XMLElement({ types: [{ name: 'ValidityPeriod', namespaceUri: NS_CAC, itemType: () => PeriodType }] })
  validityPeriod?: PeriodType;

  @XMLElement({ types: [{ name: 'IssuerParty', namespaceUri: NS_CAC, itemType: () => PartyType }] })
  issuerParty?: PartyType;
}

export default DocumentReferenceType;
