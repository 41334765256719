import { AuthRole, AuthScope } from '@/interfaces';
import {
  faArchive,
  faArrowDown,
  faArrowUp,
  faBellOn,
  faBells,
  faCloudUpload,
  faCogs,
  faDownload,
  faEdit,
  faFile,
  faFileCheck,
  faFileCode,
  faFileExcel,
  faFilePdf,
  faFileSignature,
  faHistory,
  faList,
  faListUl,
  faPencil,
  faPencilAlt,
  faPlus,
  faSearch,
  faShare,
  faTable,
  faTags,
  faTrash,
  faUpload,
  faUsers
} from '@fortawesome/pro-regular-svg-icons';

export const EINVOICE_EDITOR_ROLES: AuthRole = {
  value: 'EI:EDITOR',
  icon: faPencilAlt,
  checkable: false,
  children: [
    {
      value: 'EI:EDITOR:CREATE_EDIT',
      checkable: true,
      icon: faPlus
    }
  ]
};

export const EINVOICE_OUTGOUNG_ROLES: AuthRole = {
  value: 'EI:OUT',
  icon: faArrowUp,
  checkable: false,
  children: [
    {
      value: 'EI:OUT:INV-ROOT',
      checkable: false,
      icon: faFile,
      children: [
        {
          value: 'EI:OUT:INV',
          checkable: true,
          icon: faFile
        },
        {
          value: 'EI:OUT:INV:XML',
          checkable: true,
          icon: faFileCode
        },
        {
          value: 'EI:OUT:INV:ENVELOPE',
          checkable: true,
          icon: faFileCode
        },
        {
          value: 'EI:OUT:INV:PDF',
          checkable: true,
          icon: faFilePdf
        },
        {
          value: 'EI:OUT:INV:EMAIL_SEND',
          checkable: true,
          icon: faShare
        },
        {
          value: 'EI:OUT:INV:HTML',
          checkable: true,
          icon: faSearch
        },
        {
          value: 'EI:OUT:INV:SET_ARCHIVE_STATUS',
          checkable: true,
          icon: faArchive
        }
      ]
    },
    {
      checkable: false,
      value: 'EI:OUT:RM',
      icon: faFileExcel,
      children: [
        {
          value: 'EI:OUT:RM:REPORTS',
          icon: faTable,
          checkable: true
        },
        {
          value: 'EI:OUT:RM:TEMPLATES',
          icon: faTags,
          checkable: true
        },
        {
          value: 'EI:OUT:RM:TEMPLATES_POST_PUT',
          icon: faEdit,
          checkable: true
        }
      ]
    }
  ]
};

export const EINVOICE_INCOMING_ROLES: AuthRole = {
  value: 'EI:INC',
  icon: faArrowDown,
  checkable: false,
  children: [
    {
      value: 'EI:INC:INV-ROOT',
      checkable: false,
      icon: faFile,
      children: [
        {
          value: 'EI:INC:INV',
          checkable: true,
          icon: faFile
        },
        {
          value: 'EI:INC:INV:XML',
          checkable: true,
          icon: faFileCode
        },
        {
          value: 'EI:INC:INV:ENVELOPE',
          checkable: true,
          icon: faFileCode
        },
        {
          value: 'EI:INC:INV:PDF',
          checkable: true,
          icon: faFilePdf
        },
        {
          value: 'EI:INC:INV:EMAIL_SEND',
          checkable: true,
          icon: faShare
        },
        {
          value: 'EI:INC:INV:HTML',
          checkable: true,
          icon: faSearch
        },
        {
          value: 'EI:INC:INV:SET_ARCHIVE_STATUS',
          checkable: true,
          icon: faArchive
        },
        {
          value: 'EI:INC:INV:DOCUMENT_ANSWER',
          checkable: true,
          icon: faShare
        },
        {
          value: 'EI:INC:INV:SET_TRANSFERRED_STATUS',
          checkable: true,
          icon: faFileCheck
        }
      ]
    },
    {
      checkable: false,
      value: 'EI:INC:RM',
      icon: faFileExcel,
      children: [
        {
          value: 'EI:INC:RM:REPORTS',
          icon: faTable,
          checkable: true
        },
        {
          value: 'EI:INC:RM:TEMPLATES',
          icon: faTags,
          checkable: true
        },
        {
          value: 'EI:INC:RM:TEMPLATES_POST_PUT',
          icon: faEdit,
          checkable: true
        }
      ]
    }
  ]
};

export const EINVOICE_DEFINATIONS_ROLES: AuthRole = {
  value: 'EI:DEF',
  icon: faCogs,
  checkable: false,
  children: [
    {
      value: 'EI:DEF:SERIES-ROOT',
      checkable: false,
      icon: faListUl,
      children: [
        {
          value: 'EI:DEF:SERIES',
          checkable: true,
          icon: faListUl
        },
        {
          value: 'EI:DEF:SERIES_POST',
          icon: faEdit,
          checkable: true
        },
        {
          value: 'EI:DEF:SERIES_DELETE',
          icon: faTrash,
          checkable: true
        }
      ]
    },
    {
      value: 'EI:DEF:DOCUMENT_TEMPLATES-ROOT',
      checkable: false,
      icon: faTags,
      children: [
        {
          value: 'EI:DEF:DOCUMENT_TEMPLATES',
          checkable: true,
          icon: faTags
        },
        {
          value: 'EI:DEF:DOCUMENT_TEMPLATES_POST',
          icon: faEdit,
          checkable: true
        },
        {
          value: 'EI:DEF:DOCUMENT_TEMPLATES_PUT',
          icon: faPencil,
          checkable: true
        },
        {
          value: 'EI:DEF:DOCUMENT_TEMPLATES_DELETE',
          icon: faTrash,
          checkable: true
        }
      ]
    },
    {
      value: 'EI:DEF:FILE_EXPORT_TITLES',
      icon: faFileSignature,
      checkable: true
    }
  ]
};

export const EINVOICE_NOTIFICATIONS_ROLES: AuthRole = {
  value: 'EI:NOTIFY',
  icon: faBellOn,
  checkable: false,
  children: [
    {
      value: 'EI:NOTIFY:INC:DYNAMIC_RULES',
      checkable: true,
      icon: faBells
    },
    {
      value: 'EI:NOTIFY:OUT:DYNAMIC_RULES',
      checkable: true,
      icon: faBells
    }
  ]
};

export const EINVOICE_EX_INVOICE_ROLES: AuthRole = {
  value: 'EI:EX',
  icon: faHistory,
  checkable: false,
  children: [
    {
      value: 'EI:EX:INC',
      checkable: true,
      icon: faDownload
    },
    {
      value: 'EI:EX:OUT',
      checkable: true,
      icon: faUpload
    },
    {
      value: 'EI:EX:QUEUE',
      checkable: true,
      icon: faList
    },
    {
      value: 'EI:EX:POST',
      checkable: true,
      icon: faCloudUpload
    }
  ]
};

export const EINVOICE_TAGS_ROLES: AuthRole = {
  value: 'EI:TAGS',
  icon: faTags,
  checkable: true
};

export const EINVOICE_UPLOADS_ROLES: AuthRole = {
  value: 'EI:UPLOADS_DOCUMENT',
  icon: faCloudUpload,
  checkable: true
};

export const EINVOICES_ROLES = [
  EINVOICE_EDITOR_ROLES,
  EINVOICE_OUTGOUNG_ROLES,
  EINVOICE_INCOMING_ROLES,
  EINVOICE_DEFINATIONS_ROLES,
  EINVOICE_NOTIFICATIONS_ROLES,
  EINVOICE_EX_INVOICE_ROLES,
  EINVOICE_TAGS_ROLES,
  EINVOICE_UPLOADS_ROLES
];

export const EINVOICE_OUTGOING_SCOPES: AuthScope = {
  value: 'einvoice:outgoing',
  checkable: false,
  children: [
    {
      checkable: true,
      value: 'einvoice:outgoing:list:read'
    },
    {
      checkable: true,
      value: 'einvoice:outgoing:list:write'
    },
    {
      checkable: true,
      value: 'einvoice:outgoing:excelreport:read'
    },
    {
      checkable: true,
      value: 'einvoice:outgoing:excelreport:write'
    }
  ]
};

export const EINVOICE_INCOMING_SCOPES: AuthScope = {
  value: 'einvoice:incoming',
  checkable: false,
  children: [
    {
      checkable: true,
      value: 'einvoice:incoming:list:read'
    },
    {
      checkable: true,
      value: 'einvoice:incoming:list:write'
    },
    {
      checkable: true,
      value: 'einvoice:incoming:excelreport:read'
    },
    {
      checkable: true,
      value: 'einvoice:incoming:excelreport:write'
    }
  ]
};

export const EINVOICE_TAGS_SCOPES: AuthScope = {
  value: 'einvoice:tags',
  checkable: false,
  children: [
    {
      value: 'einvoice:tags:read'
    },
    {
      value: 'einvoice:tags:write'
    }
  ]
};

export const EINVOICE_DEFINATIONS_SCOPES: AuthScope = {
  value: 'einvoice:definations',
  checkable: false,
  children: [
    {
      value: 'einvoice:definations:documentserie:read'
    },
    {
      value: 'einvoice:definations:documentserie:write'
    },
    {
      value: 'einvoice:definations:documenttemplate:read'
    },
    {
      value: 'einvoice:definations:documenttemplate:write'
    },
    {
      value: 'einvoice:definations:filetitle:read'
    },
    {
      value: 'einvoice:definations:filetitle:write'
    }
  ]
};

export const EINVOICE_NOTIFICATION_SCOPES: AuthScope = {
  value: 'einvoice:notifications',
  checkable: false,
  children: [
    {
      value: 'einvoice:notifications:dynamic:incoming:read'
    },
    {
      value: 'einvoice:notifications:dynamic:incoming:write'
    },
    {
      value: 'einvoice:notifications:dynamic:outgoing:read'
    },
    {
      value: 'einvoice:notifications:dynamic:outgoing:write'
    }
  ]
};

export const EINVOICE_UPLOAD_SCOPES: AuthScope = {
  value: 'einvoice:upload',
  checkable: false,
  children: [
    {
      value: 'einvoice:upload:write'
    }
  ]
};

export const EINVOICE_EXINVOICE_SCOPES: AuthScope = {
  value: 'einvoice:ex_invoice',
  checkable: false,
  children: [
    {
      value: 'einvoice:exinvoice:incoming:read'
    },
    {
      value: 'einvoice:exinvoice:outgoing:read'
    },
    {
      value: 'einvoice:exinvoice:queue:write'
    },
    {
      value: 'einvoice:exinvoice:queue:read'
    }
  ]
};

export const EINVOICE_SCOPES = [
  EINVOICE_OUTGOING_SCOPES,
  EINVOICE_INCOMING_SCOPES,
  EINVOICE_TAGS_SCOPES,
  EINVOICE_DEFINATIONS_SCOPES,
  EINVOICE_NOTIFICATION_SCOPES,
  EINVOICE_UPLOAD_SCOPES,
  EINVOICE_EXINVOICE_SCOPES
];
