import {
  V1DefinitionsSeriesIdGetRequest,
  V1DefinitionsSeriesIdYearSetnumberNextNumberGetRequest,
  V1DefinitionsSeriesSerieIdYearHistoriesGetRequest
} from '@/apis/earchive';
import { ActiveStatus, DocumentSerie, DocumentSerieNumber, DocumentSerieNumberHistoryPaginationResult } from '@/apis/einvoice/interfaces';
import { Close, ProcessDropdownButton } from '@/components/Buttons';
import { Edit, Histories } from '@/components/DropdownItems';
import { useApiModal } from '@/hooks';
import { ApiModal } from '@/interfaces';
import { faCalculator } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Modal, Table } from 'antd';
import { AxiosPromise } from 'axios';
import _ from 'lodash';
import React, { memo, useState } from 'react';
import intl from 'react-intl-universal';
import { ErrorWrapper, NoProcess } from '..';
import { TableDefaultLoading } from '../TableBody';
import { SerieHistoriesModal } from './SerieHistoriesModal';
import UpsetSerieUpdateNumberModal, { UpsetSerieUpdateNumberModalProps } from './UpsetSerieUpdateNumberModal';

const CountersModal = memo(
  ({
    closeModal,
    visible,
    loading,
    error,
    data,
    refresh,
    getHistories,
    getSeriesIdYearSetnumberNextNumber
  }: ApiModal<DocumentSerie, V1DefinitionsSeriesIdGetRequest> & {
    refresh: () => void;
    getHistories: (requestParameters: V1DefinitionsSeriesSerieIdYearHistoriesGetRequest) => AxiosPromise<DocumentSerieNumberHistoryPaginationResult>;
    getSeriesIdYearSetnumberNextNumber: (requestParameters: V1DefinitionsSeriesIdYearSetnumberNextNumberGetRequest) => AxiosPromise<void>;
  }) => {
    const serieHistories = useApiModal<DocumentSerieNumberHistoryPaginationResult, V1DefinitionsSeriesSerieIdYearHistoriesGetRequest>(getHistories);

    const [upsertSerieNumberModalState, setUpsertSerieNumberModalState] = useState<UpsetSerieUpdateNumberModalProps>({
      visible: false,
      currentNumber: '',
      year: new Date().getFullYear(),
      serieId: ''
    });

    return (
      <Modal
        centered
        title={
          <span>
            <FontAwesomeIcon icon={faCalculator} className="mr-2" /> {intl.getHTML('SERISINE_AIT_SAYAC_BILGILERI', { serie: data?.serie })}
          </span>
        }
        onCancel={closeModal}
        width={1024}
        visible={visible}
        footer={[<Close key="close-button" onClick={closeModal} />]}
      >
        <UpsetSerieUpdateNumberModal
          closeModal={() => setUpsertSerieNumberModalState({ visible: false, year: new Date().getFullYear(), serieId: '', currentNumber: '' })}
          refresh={refresh}
          getSeriesIdYearSetnumberNextNumber={getSeriesIdYearSetnumberNextNumber}
          modalState={upsertSerieNumberModalState}
        />
        <SerieHistoriesModal
          {...serieHistories.props}
          additionalProps={{
            getHistories: serieHistories.get,
            serieId: data?.id || '',
            year: new Date().getFullYear()
          }}
        />

        <Table
          showHeader={data !== undefined}
          components={ErrorWrapper({ error })}
          style={{ marginTop: '10px' }}
          bordered
          loading={TableDefaultLoading(loading)}
          rowKey={(item) => item.id}
          pagination={false}
          dataSource={data?.counters}
        >
          <Table.Column key="year" title={intl.get('SAYAC_YILI')} dataIndex="year" />
          <Table.Column
            className={'base-table-flex'}
            title={intl.get('SIRADAKI_NUMARA')}
            render={(text, item: DocumentSerieNumber) => `${data?.serie}${item.year}${item.nextNumber.padStart(9, '0')}`}
          />
          <Table.Column key="lastIssueDate" title={intl.get('SON_BELGE_TARIHI')} dataIndex="lastIssueDate" />
          <Table.Column
            key="process"
            title={intl.get('ISLEMLER')}
            dataIndex="process"
            width={70}
            render={(text, item: DocumentSerieNumber) => {
              return !data?.isPortal ? (
                <NoProcess title={intl.get('PORTAL_SERISI_OLMAYAN_KAYIT_UZERINDE_HERHANGINI_BIR_ISLEM_YAPILAMAZ')} />
              ) : (
                <ProcessDropdownButton
                  isRow
                  menu={
                    <Menu>
                      {data.activeStatus !== ActiveStatus.Deleted && (
                        <Edit
                          onClick={() =>
                            setUpsertSerieNumberModalState({ visible: true, year: item.year, serieId: data?.id || '', currentNumber: item.nextNumber })
                          }
                        />
                      )}
                      <Histories
                        onClick={() => serieHistories.get({ serieId: data?.id || '', year: item.year, page: 1, pageSize: 10, sort: 'CreatedAt desc' })}
                      />
                    </Menu>
                  }
                />
              );
            }}
          />
        </Table>
      </Modal>
    );
  },
  (prev, next) => _.isEqual(prev.data, next.data) && prev.visible === next.visible && prev.loading === next.loading
);

export default CountersModal;
