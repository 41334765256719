// tslint:disable
// eslint:disable
/**
 * NES Identity Server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Accountant } from '../interfaces';
// @ts-ignore
import { ActionResultModelBase } from '../interfaces';
// @ts-ignore
import { ActiveStatus } from '../interfaces';
// @ts-ignore
import { AuthorizeAccountantUserDto } from '../interfaces';
// @ts-ignore
import { ChangePasswordDto } from '../interfaces';
// @ts-ignore
import { CreateAccountantUserDto } from '../interfaces';
// @ts-ignore
import { ProductType } from '../interfaces';
// @ts-ignore
import { UnAuthorizeAccountantUserDto } from '../interfaces';
// @ts-ignore
import { UnProcessableActionResultModel } from '../interfaces';
// @ts-ignore
import { User } from '../interfaces';
// @ts-ignore
import { UserDto } from '../interfaces';
// @ts-ignore
import { UserEditDto } from '../interfaces';
// @ts-ignore
import { ValidationActionResultModel } from '../interfaces';
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AuthorizeAccountantUserDto} [authorizeAccountantUserDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersAccountantAuthorizePost: async (requestParameters: V1UsersAccountantAuthorizePostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/accountant/authorize`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.authorizeAccountantUserDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.authorizeAccountantUserDto !== undefined ? requestParameters.authorizeAccountantUserDto : {}) : (requestParameters.authorizeAccountantUserDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAccountantUserDto} [createAccountantUserDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersAccountantPost: async (requestParameters: V1UsersAccountantPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/accountant`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.createAccountantUserDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.createAccountantUserDto !== undefined ? requestParameters.createAccountantUserDto : {}) : (requestParameters.createAccountantUserDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UnAuthorizeAccountantUserDto} [unAuthorizeAccountantUserDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersAccountantUnauthorizePost: async (requestParameters: V1UsersAccountantUnauthorizePostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/accountant/unauthorize`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.unAuthorizeAccountantUserDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.unAuthorizeAccountantUserDto !== undefined ? requestParameters.unAuthorizeAccountantUserDto : {}) : (requestParameters.unAuthorizeAccountantUserDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kullanıcılar listelenir
         * @param {boolean} [onlyIsActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersAccountantsGet: async (requestParameters: V1UsersAccountantsGetRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/accountants`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (requestParameters.onlyIsActive !== undefined) {
                localVarQueryParameter['onlyIsActive'] = requestParameters.onlyIsActive;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} file **XML Dosya**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersCheckadminuserisexistPost: async (requestParameters: V1UsersCheckadminuserisexistPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            if (requestParameters.file === null || requestParameters.file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling v1UsersCheckadminuserisexistPost.');
            }
            const localVarPath = `/v1/users/checkadminuserisexist`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();


                if (requestParameters.file !== undefined) { 
                    localVarFormParams.append('File', requestParameters.file as any);
                }

    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} file **XML Dosya**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersCreateadminuserPost: async (requestParameters: V1UsersCreateadminuserPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            if (requestParameters.file === null || requestParameters.file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling v1UsersCreateadminuserPost.');
            }
            const localVarPath = `/v1/users/createadminuser`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();


                if (requestParameters.file !== undefined) { 
                    localVarFormParams.append('File', requestParameters.file as any);
                }

    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kullanıcılar listelenir
         * @param {ActiveStatus} [activeStatus] durum
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersGet: async (requestParameters: V1UsersGetRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (requestParameters.activeStatus !== undefined) {
                localVarQueryParameter['activeStatus'] = requestParameters.activeStatus;
            }
            if (requestParameters.query !== undefined) {
                localVarQueryParameter['query'] = requestParameters.query;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kullanıcıya modül erişim yetkisi verir
         * @param {string} id id
         * @param {ProductType} productType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdActivatemoduleProductTypePost: async (requestParameters: V1UsersIdActivatemoduleProductTypePostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1UsersIdActivatemoduleProductTypePost.');
            }
            // verify required parameter 'productType' is not null or undefined
            if (requestParameters.productType === null || requestParameters.productType === undefined) {
                throw new RequiredError('productType','Required parameter productType was null or undefined when calling v1UsersIdActivatemoduleProductTypePost.');
            }
            const localVarPath = `/v1/users/{id}/activatemodule/{productType}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"productType"}}`, encodeURIComponent(String(requestParameters.productType)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kullanıcının modül erişim yetkisini kaldırır
         * @param {string} id 
         * @param {ChangePasswordDto} [changePasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdChangepasswordPost: async (requestParameters: V1UsersIdChangepasswordPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1UsersIdChangepasswordPost.');
            }
            const localVarPath = `/v1/users/{id}/changepassword`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.changePasswordDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.changePasswordDto !== undefined ? requestParameters.changePasswordDto : {}) : (requestParameters.changePasswordDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kullanıcının modül erişim yetkisini kaldırır
         * @param {string} id id
         * @param {ProductType} productType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdDeactivatemoduleProductTypePost: async (requestParameters: V1UsersIdDeactivatemoduleProductTypePostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1UsersIdDeactivatemoduleProductTypePost.');
            }
            // verify required parameter 'productType' is not null or undefined
            if (requestParameters.productType === null || requestParameters.productType === undefined) {
                throw new RequiredError('productType','Required parameter productType was null or undefined when calling v1UsersIdDeactivatemoduleProductTypePost.');
            }
            const localVarPath = `/v1/users/{id}/deactivatemodule/{productType}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"productType"}}`, encodeURIComponent(String(requestParameters.productType)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kullanıcının modül erişim yetkisini kaldırır
         * @param {string} id 
         * @param {UserEditDto} [userEditDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdPut: async (requestParameters: V1UsersIdPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1UsersIdPut.');
            }
            const localVarPath = `/v1/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.userEditDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.userEditDto !== undefined ? requestParameters.userEditDto : {}) : (requestParameters.userEditDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detay : Kaydın durumunu değiştirmek için bu uç kullanılabilir
         * @summary Kullanıcının durumunu değiştirir
         * @param {string} id id
         * @param {ActiveStatus} activeStatus Güncellencek yeni durum. &lt;br/&gt; active : &lt;b&gt;Kaydı aktif eder&lt;/b&gt; | passive : &lt;b&gt;Kaydı pasif eder&lt;/b&gt; | delete : &lt;b&gt;Kaydı silindi olarak işaretler&lt;/b&gt;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdSetActiveStatusGet: async (requestParameters: V1UsersIdSetActiveStatusGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1UsersIdSetActiveStatusGet.');
            }
            // verify required parameter 'activeStatus' is not null or undefined
            if (requestParameters.activeStatus === null || requestParameters.activeStatus === undefined) {
                throw new RequiredError('activeStatus','Required parameter activeStatus was null or undefined when calling v1UsersIdSetActiveStatusGet.');
            }
            const localVarPath = `/v1/users/{id}/set/{activeStatus}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"activeStatus"}}`, encodeURIComponent(String(requestParameters.activeStatus)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kullanıcının modül erişim yetkisini kaldırır
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersPost: async (requestParameters: V1UsersPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.userDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.userDto !== undefined ? requestParameters.userDto : {}) : (requestParameters.userDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AuthorizeAccountantUserDto} [authorizeAccountantUserDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UsersAccountantAuthorizePost(requestParameters: V1UsersAccountantAuthorizePostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v1UsersAccountantAuthorizePost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateAccountantUserDto} [createAccountantUserDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UsersAccountantPost(requestParameters: V1UsersAccountantPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v1UsersAccountantPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UnAuthorizeAccountantUserDto} [unAuthorizeAccountantUserDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UsersAccountantUnauthorizePost(requestParameters: V1UsersAccountantUnauthorizePostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v1UsersAccountantUnauthorizePost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Kullanıcılar listelenir
         * @param {boolean} [onlyIsActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UsersAccountantsGet(requestParameters: V1UsersAccountantsGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Accountant>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v1UsersAccountantsGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {any} file **XML Dosya**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UsersCheckadminuserisexistPost(requestParameters: V1UsersCheckadminuserisexistPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v1UsersCheckadminuserisexistPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {any} file **XML Dosya**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UsersCreateadminuserPost(requestParameters: V1UsersCreateadminuserPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v1UsersCreateadminuserPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Kullanıcılar listelenir
         * @param {ActiveStatus} [activeStatus] durum
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UsersGet(requestParameters: V1UsersGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v1UsersGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Kullanıcıya modül erişim yetkisi verir
         * @param {string} id id
         * @param {ProductType} productType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UsersIdActivatemoduleProductTypePost(requestParameters: V1UsersIdActivatemoduleProductTypePostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v1UsersIdActivatemoduleProductTypePost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Kullanıcının modül erişim yetkisini kaldırır
         * @param {string} id 
         * @param {ChangePasswordDto} [changePasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UsersIdChangepasswordPost(requestParameters: V1UsersIdChangepasswordPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v1UsersIdChangepasswordPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Kullanıcının modül erişim yetkisini kaldırır
         * @param {string} id id
         * @param {ProductType} productType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UsersIdDeactivatemoduleProductTypePost(requestParameters: V1UsersIdDeactivatemoduleProductTypePostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v1UsersIdDeactivatemoduleProductTypePost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Kullanıcının modül erişim yetkisini kaldırır
         * @param {string} id 
         * @param {UserEditDto} [userEditDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UsersIdPut(requestParameters: V1UsersIdPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v1UsersIdPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Detay : Kaydın durumunu değiştirmek için bu uç kullanılabilir
         * @summary Kullanıcının durumunu değiştirir
         * @param {string} id id
         * @param {ActiveStatus} activeStatus Güncellencek yeni durum. &lt;br/&gt; active : &lt;b&gt;Kaydı aktif eder&lt;/b&gt; | passive : &lt;b&gt;Kaydı pasif eder&lt;/b&gt; | delete : &lt;b&gt;Kaydı silindi olarak işaretler&lt;/b&gt;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UsersIdSetActiveStatusGet(requestParameters: V1UsersIdSetActiveStatusGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v1UsersIdSetActiveStatusGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Kullanıcının modül erişim yetkisini kaldırır
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UsersPost(requestParameters: V1UsersPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v1UsersPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {AuthorizeAccountantUserDto} [authorizeAccountantUserDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersAccountantAuthorizePost(requestParameters: V1UsersAccountantAuthorizePostRequest, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).v1UsersAccountantAuthorizePost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAccountantUserDto} [createAccountantUserDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersAccountantPost(requestParameters: V1UsersAccountantPostRequest, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).v1UsersAccountantPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UnAuthorizeAccountantUserDto} [unAuthorizeAccountantUserDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersAccountantUnauthorizePost(requestParameters: V1UsersAccountantUnauthorizePostRequest, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).v1UsersAccountantUnauthorizePost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kullanıcılar listelenir
         * @param {boolean} [onlyIsActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersAccountantsGet(requestParameters: V1UsersAccountantsGetRequest, options?: any): AxiosPromise<Array<Accountant>> {
            return UsersApiFp(configuration).v1UsersAccountantsGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} file **XML Dosya**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersCheckadminuserisexistPost(requestParameters: V1UsersCheckadminuserisexistPostRequest, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).v1UsersCheckadminuserisexistPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} file **XML Dosya**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersCreateadminuserPost(requestParameters: V1UsersCreateadminuserPostRequest, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).v1UsersCreateadminuserPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kullanıcılar listelenir
         * @param {ActiveStatus} [activeStatus] durum
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersGet(requestParameters: V1UsersGetRequest, options?: any): AxiosPromise<Array<User>> {
            return UsersApiFp(configuration).v1UsersGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kullanıcıya modül erişim yetkisi verir
         * @param {string} id id
         * @param {ProductType} productType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdActivatemoduleProductTypePost(requestParameters: V1UsersIdActivatemoduleProductTypePostRequest, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).v1UsersIdActivatemoduleProductTypePost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kullanıcının modül erişim yetkisini kaldırır
         * @param {string} id 
         * @param {ChangePasswordDto} [changePasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdChangepasswordPost(requestParameters: V1UsersIdChangepasswordPostRequest, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).v1UsersIdChangepasswordPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kullanıcının modül erişim yetkisini kaldırır
         * @param {string} id id
         * @param {ProductType} productType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdDeactivatemoduleProductTypePost(requestParameters: V1UsersIdDeactivatemoduleProductTypePostRequest, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).v1UsersIdDeactivatemoduleProductTypePost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kullanıcının modül erişim yetkisini kaldırır
         * @param {string} id 
         * @param {UserEditDto} [userEditDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdPut(requestParameters: V1UsersIdPutRequest, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).v1UsersIdPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Detay : Kaydın durumunu değiştirmek için bu uç kullanılabilir
         * @summary Kullanıcının durumunu değiştirir
         * @param {string} id id
         * @param {ActiveStatus} activeStatus Güncellencek yeni durum. &lt;br/&gt; active : &lt;b&gt;Kaydı aktif eder&lt;/b&gt; | passive : &lt;b&gt;Kaydı pasif eder&lt;/b&gt; | delete : &lt;b&gt;Kaydı silindi olarak işaretler&lt;/b&gt;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdSetActiveStatusGet(requestParameters: V1UsersIdSetActiveStatusGetRequest, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).v1UsersIdSetActiveStatusGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kullanıcının modül erişim yetkisini kaldırır
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersPost(requestParameters: V1UsersPostRequest, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).v1UsersPost(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {AuthorizeAccountantUserDto} [authorizeAccountantUserDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersAccountantAuthorizePost(requestParameters: V1UsersAccountantAuthorizePostRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersAccountantAuthorizePost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAccountantUserDto} [createAccountantUserDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersAccountantPost(requestParameters: V1UsersAccountantPostRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersAccountantPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnAuthorizeAccountantUserDto} [unAuthorizeAccountantUserDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersAccountantUnauthorizePost(requestParameters: V1UsersAccountantUnauthorizePostRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersAccountantUnauthorizePost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kullanıcılar listelenir
     * @param {boolean} [onlyIsActive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersAccountantsGet(requestParameters: V1UsersAccountantsGetRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersAccountantsGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} file **XML Dosya**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersCheckadminuserisexistPost(requestParameters: V1UsersCheckadminuserisexistPostRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersCheckadminuserisexistPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} file **XML Dosya**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersCreateadminuserPost(requestParameters: V1UsersCreateadminuserPostRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersCreateadminuserPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kullanıcılar listelenir
     * @param {ActiveStatus} [activeStatus] durum
     * @param {string} [query] Aranacak kelime
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersGet(requestParameters: V1UsersGetRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kullanıcıya modül erişim yetkisi verir
     * @param {string} id id
     * @param {ProductType} productType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersIdActivatemoduleProductTypePost(requestParameters: V1UsersIdActivatemoduleProductTypePostRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersIdActivatemoduleProductTypePost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kullanıcının modül erişim yetkisini kaldırır
     * @param {string} id 
     * @param {ChangePasswordDto} [changePasswordDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersIdChangepasswordPost(requestParameters: V1UsersIdChangepasswordPostRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersIdChangepasswordPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kullanıcının modül erişim yetkisini kaldırır
     * @param {string} id id
     * @param {ProductType} productType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersIdDeactivatemoduleProductTypePost(requestParameters: V1UsersIdDeactivatemoduleProductTypePostRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersIdDeactivatemoduleProductTypePost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kullanıcının modül erişim yetkisini kaldırır
     * @param {string} id 
     * @param {UserEditDto} [userEditDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersIdPut(requestParameters: V1UsersIdPutRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersIdPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Detay : Kaydın durumunu değiştirmek için bu uç kullanılabilir
     * @summary Kullanıcının durumunu değiştirir
     * @param {string} id id
     * @param {ActiveStatus} activeStatus Güncellencek yeni durum. &lt;br/&gt; active : &lt;b&gt;Kaydı aktif eder&lt;/b&gt; | passive : &lt;b&gt;Kaydı pasif eder&lt;/b&gt; | delete : &lt;b&gt;Kaydı silindi olarak işaretler&lt;/b&gt;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersIdSetActiveStatusGet(requestParameters: V1UsersIdSetActiveStatusGetRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersIdSetActiveStatusGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kullanıcının modül erişim yetkisini kaldırır
     * @param {UserDto} [userDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersPost(requestParameters: V1UsersPostRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1UsersAccountantAuthorizePostRequest {
    /**
     * 
     */
    authorizeAccountantUserDto?: AuthorizeAccountantUserDto;
    }
    export interface V1UsersAccountantPostRequest {
    /**
     * 
     */
    createAccountantUserDto?: CreateAccountantUserDto;
    }
    export interface V1UsersAccountantUnauthorizePostRequest {
    /**
     * 
     */
    unAuthorizeAccountantUserDto?: UnAuthorizeAccountantUserDto;
    }
    export interface V1UsersAccountantsGetRequest {
    /**
     * 
     */
    onlyIsActive?: boolean;
    }
    export interface V1UsersCheckadminuserisexistPostRequest {
    /**
     * **XML Dosya**
     */
    file: any;
    }
    export interface V1UsersCreateadminuserPostRequest {
    /**
     * **XML Dosya**
     */
    file: any;
    }
    export interface V1UsersGetRequest {
    /**
     * durum
     */
    activeStatus?: ActiveStatus;
    /**
     * Aranacak kelime
     */
    query?: string;
    }
    export interface V1UsersIdActivatemoduleProductTypePostRequest {
    /**
     * id
     */
    id: string;
    /**
     * 
     */
    productType: ProductType;
    }
    export interface V1UsersIdChangepasswordPostRequest {
    /**
     * 
     */
    id: string;
    /**
     * 
     */
    changePasswordDto?: ChangePasswordDto;
    }
    export interface V1UsersIdDeactivatemoduleProductTypePostRequest {
    /**
     * id
     */
    id: string;
    /**
     * 
     */
    productType: ProductType;
    }
    export interface V1UsersIdPutRequest {
    /**
     * 
     */
    id: string;
    /**
     * 
     */
    userEditDto?: UserEditDto;
    }
    export interface V1UsersIdSetActiveStatusGetRequest {
    /**
     * id
     */
    id: string;
    /**
     * Güncellencek yeni durum. <br/> active : <b>Kaydı aktif eder</b> | passive : <b>Kaydı pasif eder</b> | delete : <b>Kaydı silindi olarak işaretler</b>.
     */
    activeStatus: ActiveStatus;
    }
    export interface V1UsersPostRequest {
    /**
     * 
     */
    userDto?: UserDto;
    }
