import {
  AddDocRefConditionField,
  AddDocRefValueField,
  CustomizationTableAdditionalInformation,
  DocumentTemplateCustomizationSettingDto,
  TemplateAdditionalValueFieldType
} from '@/apis/einvoice/interfaces';
import { NewInAddon, SaveInAddon } from '@/components/AddonButtons';
import { Delete, Edit } from '@/components/Buttons';
import { PageConst } from '@/constants/page';
import { XML_ESCAPE_CHARS } from '@/constants/regex';
import { EditedState } from '@/interfaces';
import { faEquals } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Col, Row, Table } from 'antd';
import { Formik, FormikProps } from 'formik';
import { Form, FormItem, Input, Select } from 'formik-antd';
import React, { useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { removeItem, replaceItem } from 'redux-toolbelt-immutable-helpers';
import * as Yup from 'yup';

const { Option } = Select;

const AddDocRefTab = ({
  documentTemplateCustomizationSetting,
  onChange
}: {
  documentTemplateCustomizationSetting?: DocumentTemplateCustomizationSettingDto;
  onChange: (data: CustomizationTableAdditionalInformation[]) => void;
}) => {
  const [edited, setEdited] = useState<EditedState>({ isEditing: false });

  let form = (useRef(null) as unknown) as FormikProps<CustomizationTableAdditionalInformation>;

  const validatiorAddDocRefScheme = Yup.object().shape<CustomizationTableAdditionalInformation>({
    title: Yup.string().nullable().required().max(500).matches(XML_ESCAPE_CHARS),
    valueField: Yup.mixed<AddDocRefValueField>().oneOf(Object.values(AddDocRefValueField)).required(),
    conditionField: Yup.mixed<AddDocRefConditionField>().oneOf(Object.values(AddDocRefConditionField)).required(),
    conditionValue: Yup.string().nullable().required().max(500).matches(XML_ESCAPE_CHARS),
    templateAdditionalValueFieldType: Yup.mixed<TemplateAdditionalValueFieldType>().oneOf(Object.values(TemplateAdditionalValueFieldType))
  });

  const onDelete = (record: CustomizationTableAdditionalInformation) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const index = documentTemplateCustomizationSetting!.customizationTableAdditionalInformations!.indexOf(record);

    setEdited({ isEditing: false, index: undefined });

    form.resetForm();

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    onChange(removeItem(documentTemplateCustomizationSetting!.customizationTableAdditionalInformations || [], index));
  };

  const onEdit = (record: CustomizationTableAdditionalInformation) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const index = documentTemplateCustomizationSetting!.customizationTableAdditionalInformations!.indexOf(record);
    setEdited({ isEditing: true, index: index });
    form.setValues(record);
  };

  return (
    <>
      <Row gutter={PageConst.RowGutter}>
        <Col sm={24}>
          <Alert
            style={{ marginBottom: 10 }}
            description={
              <div>
                <br />
                Örn: <b>cbc:DocumentTypeCode = &apos;KARGO_TAKIP_NO&apos;</b> şartına uygun bir veri olması durumunda seçtiğiniz alandaki (ID, IssueDate) veri
                belirttiğiniz başlıkla birlikte özelleştirme tablosuna eklenir.
              </div>
            }
            message={
              <div>
                Ek Belgeler (<b>AdditionalDocumentReference</b>) alanında; belirtilen şarta uygun bir değer varsa özelleştirme tablosunda belirttiğiniz başlıkla
                birlikte eklenir.
              </div>
            }
            type="info"
            closable
          />
        </Col>
      </Row>

      <Formik<CustomizationTableAdditionalInformation>
        validateOnBlur={false}
        innerRef={(instance) => (form = instance)}
        onSubmit={(values, { resetForm }) => {
          if (form.isValid) {
            if (edited.isEditing) {
              onChange(replaceItem(documentTemplateCustomizationSetting?.customizationTableAdditionalInformations || [], edited.index!, values));
              setEdited({ isEditing: false, index: undefined });
              resetForm();
            } else {
              onChange([...(documentTemplateCustomizationSetting?.customizationTableAdditionalInformations || []), { ...values }]);
              resetForm();
            }
          }
        }}
        validationSchema={validatiorAddDocRefScheme}
        initialValues={{
          title: '',
          valueField: AddDocRefValueField.ID,
          conditionField: AddDocRefConditionField.DocumentType,
          conditionValue: '',
          templateAdditionalValueFieldType: TemplateAdditionalValueFieldType.AdditionalDocumentReference
        }}
      >
        <Form layout="vertical">
          <Row gutter={PageConst.RowGutter}>
            <Col sm={4}>
              <FormItem name="title" label={intl.get('TemplateSettings.Title')} required>
                <Input name="title" placeholder={intl.get('TemplateSettings.Title')} tabIndex={0} />
              </FormItem>
            </Col>
            <Col sm={4}>
              <FormItem name="valueField" label={'Gösterilecek Değer'} required>
                <Select name="valueField" defaultValue={AddDocRefValueField.ID}>
                  <Option value={AddDocRefValueField.ID}>{AddDocRefValueField.ID.toString()}</Option>
                  <Option value={AddDocRefValueField.IssueDate}>{AddDocRefValueField.IssueDate.toString()}</Option>
                </Select>
              </FormItem>
            </Col>
            <Col sm={6}>
              <FormItem name="conditionField" label={intl.get('TemplateSettings.ConditionField')} required>
                <Select name="conditionField" defaultValue={AddDocRefConditionField.DocumentType}>
                  <Option value={AddDocRefConditionField.DocumentType}>{AddDocRefConditionField.DocumentType.toString()}</Option>
                  <Option value={AddDocRefConditionField.DocumentTypeCode}>{AddDocRefConditionField.DocumentTypeCode.toString()}</Option>
                </Select>
              </FormItem>
            </Col>
            <Col sm={10}>
              <FormItem name="conditionValue" label={'Koşul (* Eşittir)'} required>
                <Input
                  name="conditionValue"
                  placeholder={'KARGO_TAKIP_NO'}
                  tabIndex={0}
                  addonAfter={edited.isEditing ? <SaveInAddon onClick={() => form.handleSubmit()} /> : <NewInAddon onClick={() => form.handleSubmit()} />}
                />
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Formik>

      <Row gutter={PageConst.RowGutter}>
        <Col sm={24}>
          <Table
            bordered
            className="mt-2"
            pagination={false}
            dataSource={
              (documentTemplateCustomizationSetting &&
                documentTemplateCustomizationSetting.customizationTableAdditionalInformations &&
                documentTemplateCustomizationSetting.customizationTableAdditionalInformations.filter(
                  (x) => x.templateAdditionalValueFieldType === TemplateAdditionalValueFieldType.AdditionalDocumentReference
                )) ||
              []
            }
            columns={[
              {
                title: intl.get('TemplateSettings.Title'),
                dataIndex: 'title',
                key: 'title'
              },
              {
                title: 'Gösterilecek Değer',
                dataIndex: 'valueField',
                key: 'valueField',
                width: 130,
                render: (value) => `cbc:${value}`
              },
              {
                title: 'Koşul',
                dataIndex: 'conditionField',
                key: 'conditionField',
                render: (value, record) => {
                  return (
                    <div>
                      {`cbc:${record.conditionField}`} <FontAwesomeIcon icon={faEquals} className="ml-2 mr-2" /> {`'${record.conditionValue}'`}
                    </div>
                  );
                }
              },
              {
                width: 95,
                align: 'center',
                key: 'proseses',
                title: intl.get('ISLEMLER'),
                render: (text, record) => {
                  return (
                    <div>
                      <Edit onlyIcon onClick={() => onEdit(record)} /> <Delete onlyIcon onClick={() => onDelete(record)} />
                    </div>
                  );
                }
              }
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

export default AddDocRefTab;
