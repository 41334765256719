import { faPrintSlash } from '@fortawesome/pro-light-svg-icons';
import { faPrint } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import React, { memo } from 'react';
import intl from 'react-intl-universal';

export const IsPrint = memo(({ isPrint }: { isPrint: boolean }) => {
  return (
    <Tooltip trigger="hover" placement="bottom" title={isPrint ? intl.get('YAZDIRILDI') : intl.get('YAZDIRILMADI')}>
      <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={isPrint ? faPrint : faPrintSlash} className="fa-lg" />
    </Tooltip>
  );
});

export default IsPrint;
