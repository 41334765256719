import { V1DefinitionsSeriesSerieIdYearHistoriesGetRequest } from '@/apis/earchive';
import { DocumentSerieNumberHistory, DocumentSerieNumberHistoryPaginationResult } from '@/apis/einvoice/interfaces';
import { ErrorWrapper } from '@/components';
import { Close } from '@/components/Buttons';
import { TableDefaultLoading } from '@/components/TableBody';
import { ApiModal } from '@/interfaces';
import { faHistory } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import _ from 'lodash';
import moment from 'moment';
import React, { memo, useState } from 'react';
import intl from 'react-intl-universal';
import LocalPagination from '../Pagination/LocalPagination';
export const SerieHistoriesModal = memo(
  ({
    closeModal,
    visible,
    data,
    loading,
    error,
    additionalProps
  }: ApiModal<
    DocumentSerieNumberHistoryPaginationResult,
    V1DefinitionsSeriesSerieIdYearHistoriesGetRequest,
    { getHistories: (params: V1DefinitionsSeriesSerieIdYearHistoriesGetRequest) => void; year: number; serieId: string }
  >) => {
    const [requestParameter, setRequestParameter] = useState({
      page: 1,
      pageSize: 10,
      sort: 'CreatedAt desc'
    });

    return (
      <Modal
        centered
        title={
          <span>
            <FontAwesomeIcon icon={faHistory} /> {intl.get('ISLEM_GECMISI')}
          </span>
        }
        onCancel={closeModal}
        width={1024}
        visible={visible}
        footer={[<Close key="close-button" onClick={closeModal} />]}
      >
        <Table
          showHeader={data !== undefined}
          components={ErrorWrapper({ error })}
          style={{ marginTop: '10px' }}
          scroll={{ x: true, y: 512 }}
          loading={TableDefaultLoading(loading)}
          bordered
          pagination={false}
          dataSource={data && data.data}
          rowKey={(record) => record.createdAt}
        >
          <Table.Column
            key="createdAt"
            title={intl.get('TARIH')}
            dataIndex="createdAt"
            render={(text, record: DocumentSerieNumberHistory) => <b>{moment(record.createdAt).format('DD.MM.YYYY HH:mm')}</b>}
          />
          <Table.Column key="userName" title={intl.get('KULLANICI_ADI')} dataIndex="userName" />
          <Table.Column key="clientIp" title={intl.get('IP')} dataIndex="clientIp" />
          <Table.Column key="previousNumber" title={intl.get('ONCEKI_DEGER')} dataIndex="previousNumber" />
          <Table.Column key="updatedNumber" title={intl.get('GUNCELLENEN_DEGER')} dataIndex="updatedNumber" width={120} />
        </Table>
        {data && data.data && data.data.length > 0 && (
          <LocalPagination
            data={data}
            selectedCount={0}
            execute={() => {
              additionalProps?.getHistories({ ...requestParameter, serieId: additionalProps.serieId, year: additionalProps.year });
            }}
            page={requestParameter.page}
            pageSize={requestParameter.pageSize}
            onPageChange={(page, pageSize) => {
              setRequestParameter({ ...requestParameter, page, pageSize });
            }}
          />
        )}
      </Modal>
    );
  },
  (prev, next) => _.isEqual(prev.data, next.data) && prev.visible === next.visible && prev.loading === next.loading
);
