import { PayAsYouGoApiFactory } from '@/apis/general';
import { PayAsYouGoUsageInformation } from '@/apis/general/interfaces';
import { DateFormat } from '@/constants/page';
import { useGetPageData } from '@/hooks';
import { CalendarTwoTone, CreditCardTwoTone, MinusCircleTwoTone } from '@ant-design/icons';
import { faCreditCard } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Popover } from 'antd';
import moment from 'moment';
import React, { memo } from 'react';

export const MailSendHeaderPopover = memo(({ data }: { data: any }) => {
  const content = (
    <div>
      <div>
        <div style={{ marginRight: 10 }}>
          <CalendarTwoTone twoToneColor="#52c41a" />
          <b style={{ marginLeft: 10 }}>Dönem : </b>
          <span>
            {moment(data?.beginDate).format(DateFormat.dmy) ?? 0} / {moment(data?.endDate).format(DateFormat.dmy) ?? 0}
          </span>
        </div>
        <div style={{ marginRight: 10 }}>
          <MinusCircleTwoTone twoToneColor="#eb2f96" />
          <b style={{ marginLeft: 10 }}>Kullanılan Kontör : </b>
          <span>{data?.totalUsed ?? 0}</span>
        </div>
      </div>

      <div style={{ marginTop: 10 }}>
        <div style={{ marginRight: 10 }}>
          <CalendarTwoTone twoToneColor="#52c41a" />
          <b style={{ marginLeft: 10 }}>Son Hesaplama Zamanı : </b>
          <span>{moment(data?.lastCalculatedAt).format(DateFormat.dmyHMS) ?? 0}</span>
        </div>
        <div style={{ marginRight: 10 }}>
          <CreditCardTwoTone twoToneColor="#f50" />
          <b style={{ marginLeft: 10 }}>Kontör Birim Fiyat : </b>
          <span>{data?.activeRange?.unitPrice ?? 0}</span>
        </div>
      </div>

      <div style={{ marginTop: 10 }}>
        <div style={{ marginRight: 10 }}>
          <CalendarTwoTone twoToneColor="#52c41a" />
          <b style={{ marginLeft: 10 }}>Şuanki Kullanıma Göre Ödenecek Tutar : </b>
          <span>{((data?.activeRange?.unitPrice ?? 0) * (data?.totalUsed ?? 0) * 1.2).toFixed(2) ?? 0} ₺</span>
        </div>
      </div>
    </div>
  );
  return (
    <Popover content={content} title={'Detaylı Bilgi İçin Butona Tıklayınız'}>
      <div style={{ cursor: 'pointer', float: 'left', margin: '5px 12px' }}>
        <Button color="outlined">
          <FontAwesomeIcon icon={faCreditCard} style={{ marginRight: 10 }} />
          <span className={'d-none d-xs-none d-sm-inline'}>Kullandığın Kadar Öde Detayları</span>
        </Button>
      </div>
    </Popover>
  );
});

const PayAsYouGoComponent = () => {
  const api = PayAsYouGoApiFactory();

  const { execute, loading, data, error, setData } = useGetPageData<PayAsYouGoUsageInformation>(api.v1UsageGet, {}, true);

  if (!data) {
    return null;
  }

  return (
    <div style={{ display: 'flex' }}>
      <div>
        <MailSendHeaderPopover data={data} />

        {/* <div style={{ marginRight: 10 }}>
          <CalendarTwoTone twoToneColor="#52c41a" />
          <b style={{ marginLeft: 10 }}>Dönem : </b>
          <span>
            {moment(data?.beginDate).format(DateFormat.dmy) ?? 0} / {moment(data?.endDate).format(DateFormat.dmy) ?? 0}
          </span>
        </div>
        <div style={{ marginRight: 10 }}>
          <MinusCircleTwoTone twoToneColor="#eb2f96" />
          <b style={{ marginLeft: 10 }}>Kullanılan Kontör : </b>
          <span>{data?.totalUsed ?? 0}</span>
        </div> */}
      </div>

      {/* <div>
        <div style={{ marginRight: 10 }}>
          <CalendarTwoTone twoToneColor="#52c41a" />
          <b style={{ marginLeft: 10 }}>Son Hesaplama Zamanı : </b>
          <span>{moment(data?.lastCalculatedAt).format(DateFormat.dmyHMS) ?? 0}</span>
        </div>
        <div style={{ marginRight: 10 }}>
          <CreditCardTwoTone twoToneColor="#f50" />
          <b style={{ marginLeft: 10 }}> Kontör Birim Fiyat : </b>
          <span>{data?.activeRange?.unitPrice ?? 0}</span>
        </div>
      </div>

      <div>
        <div style={{ marginRight: 10 }}>
          <CalendarTwoTone twoToneColor="#52c41a" />
          <b style={{ marginLeft: 10 }}>Şuanki Kullanıma Göre Ödenecek Tutar : </b>
          <span>{((data?.activeRange?.unitPrice ?? 0) * (data?.totalUsed ?? 0) * 1.2).toFixed(2) ?? 0} ₺</span>
        </div>
      </div> */}

      {/* <div>
        <RightCircleTwoTone />
        <b style={{ marginLeft: 10 }}>Kalan Kontör : </b>
        <span>{(data?.totalDefinedCount ?? 0) - (data?.totalUsedCount ?? 0)}</span>
      </div> */}
    </div>
  );
};

export default PayAsYouGoComponent;
