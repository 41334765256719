/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLArray, XMLElement } from '@/xmldom-decorators';
import { CodeType, IDType, QuantityType } from '.';
import { NS_CAC, NS_CBC } from '../constants';
import PackageType from './PackageType';
import TextType from './TextType';
import TransportEquipmentType from './TransportEquipmentType';
import TransportMeansType from './TransportMeansType';

/*
  19.04.2022 : Partially completed
*/
export class TransportHandlingUnitType {
  @XMLElement({ types: [{ name: 'ID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  id?: IDType;

  @XMLElement({ types: [{ name: 'TransportHandlingUnitTypeCode', namespaceUri: NS_CBC, itemType: () => CodeType }] })
  transportHandlingUnitTypeCode?: CodeType;

  @XMLElement({ types: [{ name: 'HandlingCode', namespaceUri: NS_CBC, itemType: () => CodeType }] })
  handlingCode?: CodeType;

  @XMLElement({ types: [{ name: 'HandlingInstructions', namespaceUri: NS_CBC, itemType: () => TextType }] })
  handlingInstructions?: TextType;

  // TODO : public HazardousRiskIndicatorType HazardousRiskIndicator

  @XMLElement({ types: [{ name: 'TotalGoodsItemQuantity', namespaceUri: NS_CBC, itemType: () => QuantityType }] })
  totalGoodsItemQuantity?: QuantityType;

  @XMLElement({ types: [{ name: 'TotalPackageQuantity', namespaceUri: NS_CBC, itemType: () => QuantityType }] })
  totalPackageQuantity?: QuantityType;

  @XMLArray({ nested: false, itemTypes: [{ name: 'DamageRemarks', namespaceUri: NS_CBC, itemType: () => TextType }] })
  damageRemarks?: TextType[];

  @XMLElement({ types: [{ name: 'TraceID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  traceId?: IDType;

  @XMLArray({ nested: false, itemTypes: [{ name: 'ActualPackage', namespaceUri: NS_CAC, itemType: () => PackageType }] })
  actualPackage?: PackageType[];

  @XMLArray({ nested: false, itemTypes: [{ name: 'TransportEquipment', namespaceUri: NS_CAC, itemType: () => TransportEquipmentType }] })
  transportEquipment?: TransportEquipmentType[];

  @XMLArray({ nested: false, itemTypes: [{ name: 'TransportMeans', namespaceUri: NS_CBC, itemType: () => TransportMeansType }] })
  transportMeans?: TransportMeansType[];

  // TODO : public System.Collections.Generic.List<HazardousGoodsTransitType> HazardousGoodsTransit
  // TODO : public System.Collections.Generic.List<DimensionType> MeasurementDimension
  // TODO : public TemperatureType MinimumTemperature
  // TODO : public TemperatureType MaximumTemperature
  // TODO : public DimensionType FloorSpaceMeasurementDimension
  // TODO: public DimensionType PalletSpaceMeasurementDimension
  // TODO : public System.Collections.Generic.List<DocumentReferenceType> ShipmentDocumentReference
  // TODO : public System.Collections.Generic.List<CustomsDeclarationType> CustomsDeclaration
}

export default TransportHandlingUnitType;
