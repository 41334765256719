import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';

export const TabFeedback = ({ icon, title, isValid }: { icon: IconProp; title: ReactNode; isValid?: boolean }) => {
  return (
    <span style={isValid !== undefined ? (isValid === true ? {} : { color: 'red' }) : {}}>
      <FontAwesomeIcon icon={icon} className="mr-2" />
      {title}

      {isValid === true && <FontAwesomeIcon icon={faCheckCircle} className="ml-2" color="#52c41a" />}
      {isValid === false && <FontAwesomeIcon icon={faTimesCircle} className="ml-2" color="red" />}
    </span>
  );
};

export default TabFeedback;
