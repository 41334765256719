/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { NS_CBC } from '../constants';

/*
  27.04.2021 : Fully completed
*/
export class ExchangeRateType {
  @XMLElement({ types: [{ name: 'SourceCurrencyCode', namespaceUri: NS_CBC, itemType: () => String }] })
  sourceCurrencyCode: string = '';

  @XMLElement({ types: [{ name: 'TargetCurrencyCode', namespaceUri: NS_CBC, itemType: () => String }] })
  targetCurrencyCode: string = '';

  @XMLElement({ types: [{ name: 'CalculationRate', namespaceUri: NS_CBC, itemType: () => Number }] })
  calculationRate: number = 1;

  @XMLElement({ types: [{ name: 'Date', namespaceUri: NS_CBC, itemType: () => String }] })
  date?: string;
}

export default ExchangeRateType;
