import { ActiveStatusParameter } from '@/apis/earchive/interfaces';
import {
  V1DefinitionsSeriesGetRequest,
  V1DefinitionsSeriesIdDeleteRequest,
  V1DefinitionsSeriesIdGetRequest,
  V1DefinitionsSeriesIdSetdefaultGetRequest,
  V1DefinitionsSeriesIdSetStatusGetRequest,
  V1DefinitionsSeriesIdYearSetnumberNextNumberGetRequest,
  V1DefinitionsSeriesPostRequest,
  V1DefinitionsSeriesSerieIdYearHistoriesGetRequest
} from '@/apis/einvoice';
import { DocumentSerie, DocumentSerieNumberHistoryPaginationResult, ActiveStatus as IActiveStatus } from '@/apis/einvoice/interfaces';
import { ActiveStatus, BooleanComponent, Breadcrumbs, InfoToastify, RootSpinner } from '@/components';
import { New, Preview, ProcessDropdownButton, Search } from '@/components/Buttons';
import { Delete, PermanentDelete, SetActive, SetDefault, SetPassive } from '@/components/DropdownItems';
import SelectableBaseTable, { CellRendererProps } from '@/components/SelectableBaseTable';
import { PageConst, PageStyle } from '@/constants/page';
import { useApiInConfirmation, useApiModal, useGetPageData } from '@/hooks';
import { Col, Form, Input, Menu, Row } from 'antd';
import { AxiosPromise } from 'axios';
import React, { memo, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import intl from 'react-intl-universal';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { updateItemsByFilter } from 'redux-toolbelt-immutable-helpers';
import ActiveStatusFilter from '../ActiveStatusFilter';
import { HasRoleAndNotifiy } from '../Auth';
import NoProcess from '../NoProcess';
import CountersModal from './CountersModal';
import NewSerieModal from './NewSerieModal';

type TableRowProps = CellRendererProps<DocumentSerie, Array<DocumentSerie>>;

interface Counter {
  data: DocumentSerie;
  visible: boolean;
}

export const Series = memo(
  ({
    get,
    getSerieById,
    post,
    setActiveStatus,
    setDefault,
    getHistories,
    getSeriesIdYearSetnumberNextNumber,
    deleteFn,
    title,
    postRole,
    deleteRole,
    newSerie
  }: {
    get: (requestParameters: V1DefinitionsSeriesGetRequest) => AxiosPromise<DocumentSerie[]>;
    getSerieById: (requestParameters: V1DefinitionsSeriesIdGetRequest) => AxiosPromise<DocumentSerie>;
    post: (requestParameters: V1DefinitionsSeriesPostRequest) => AxiosPromise<DocumentSerie>;
    getHistories: (requestParameters: V1DefinitionsSeriesSerieIdYearHistoriesGetRequest) => AxiosPromise<DocumentSerieNumberHistoryPaginationResult>;
    getSeriesIdYearSetnumberNextNumber: (requestParameters: V1DefinitionsSeriesIdYearSetnumberNextNumberGetRequest) => AxiosPromise<void>;
    setActiveStatus: (requestParameters: V1DefinitionsSeriesIdSetStatusGetRequest) => AxiosPromise<void>;
    setDefault: (requestParameters: V1DefinitionsSeriesIdSetdefaultGetRequest) => AxiosPromise<void>;
    deleteFn: (requestParameters: V1DefinitionsSeriesIdDeleteRequest) => AxiosPromise<void>;
    title: string;
    postRole: string;
    deleteRole: string;
    newSerie: boolean;
  }) => {
    /**
     * Sayfa filtreleri root state de tutulmadığı için local state üzerinde tutuluyor.
     * requestParameter state i apiye atılan isteğin ta kendisi!
     */
    const [requestParameter, setRequestParameter] = useState<V1DefinitionsSeriesGetRequest>({ query: undefined, status: ActiveStatusParameter.Active });

    const setStatus = useApiInConfirmation<V1DefinitionsSeriesIdSetStatusGetRequest>({
      asyncFunction: setActiveStatus
    });

    const setDefaultConfirm = useApiInConfirmation<{ id: string }>({
      asyncFunction: setDefault
    });

    const deleteSerie = useApiInConfirmation<{ id: string }>({
      asyncFunction: deleteFn
    });

    const { execute, loading, data, setData, error } = useGetPageData<Array<DocumentSerie>>(get, requestParameter, true);

    const counters = useApiModal<DocumentSerie, V1DefinitionsSeriesIdGetRequest>(getSerieById);

    const [newSerieModalVisible, setNewSerieModalVisible] = useState(false);

    useEffect(() => {
      if (newSerie) {
        setNewSerieModalVisible(true);
      }
    }, [newSerie]);

    return (
      <RootSpinner loading={loading}>
        <NewSerieModal closeModal={() => setNewSerieModalVisible(false)} visible={newSerieModalVisible} refresh={execute} post={post} />
        <CountersModal
          {...counters.props}
          refresh={() => counters.get({ id: counters.props.data?.id || '' })}
          getHistories={getHistories}
          getSeriesIdYearSetnumberNextNumber={getSeriesIdYearSetnumberNextNumber}
        />

        <Helmet title={title} />
        <Card className="h-100">
          <CardHeader className={PageStyle.card__header}>
            <Breadcrumbs />
            <div>
              <New onClick={() => HasRoleAndNotifiy(postRole) && setNewSerieModalVisible(true)} /> <Search onClick={() => execute()} />
            </div>
          </CardHeader>
          <CardBody className={PageStyle.virtualized__card__body}>
            <Form layout="vertical">
              <Row gutter={PageConst.RowGutter}>
                <Col sm={18}>
                  <Form.Item label={intl.get('ARANACAK_KELIME')}>
                    <Input
                      defaultValue={requestParameter?.query}
                      onChange={(input) => setRequestParameter({ ...requestParameter, query: input.target.value })}
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                          execute();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col sm={6}>
                  <Form.Item label={intl.get('DURUM')}>
                    <ActiveStatusFilter onChange={(value) => setRequestParameter({ ...requestParameter, status: value })} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Row className="flex-fill">
              <SelectableBaseTable
                fixed
                pageData={data}
                error={error}
                data={data || []}
                rowKey="id"
                notSelectable
                requestParameters={requestParameter}
                columns={[
                  {
                    minWidth: 40,
                    width: 40,
                    align: 'center',
                    cellRenderer: ({ rowData }: TableRowProps) => {
                      return <Preview onClick={() => counters.get({ id: rowData.id })} />;
                    }
                  },
                  {
                    key: `serie`,
                    dataKey: `serie`,
                    title: intl.get('SERI'),
                    width: 150,
                    minWidth: 150,
                    className: 'base-table-flex',
                    headerClassName: 'base-table-flex',
                    cellRenderer: ({ rowData }: TableRowProps) => {
                      return <b>{rowData.serie}</b>;
                    }
                  },
                  {
                    key: `isPortal`,
                    dataKey: `isPortal`,
                    title: intl.get('PORTAL_SERISI_MI'),
                    width: 145,
                    minWidth: 145,
                    cellRenderer: ({ rowData }: TableRowProps) => {
                      return <BooleanComponent value={rowData.isPortal} />;
                    }
                  },
                  {
                    key: `activeStatus`,
                    dataKey: `activeStatus`,
                    title: intl.get('DURUM'),
                    width: 145,
                    minWidth: 145,
                    cellRenderer: ({ rowData }: TableRowProps) => {
                      return <ActiveStatus activeStatus={rowData.activeStatus} />;
                    }
                  },
                  {
                    key: `isDefault`,
                    dataKey: `isDefault`,
                    title: intl.get('VARSAYILAN'),
                    width: 145,
                    minWidth: 145,
                    cellRenderer: ({ rowData }: TableRowProps) => {
                      return <BooleanComponent value={rowData.isDefault} />;
                    }
                  },
                  {
                    key: `process`,
                    title: intl.get('ISLEMLER'),
                    minWidth: 75,
                    width: 75,
                    align: 'center',
                    cellRenderer: ({
                      rowData,
                      container: {
                        props: { pageData }
                      }
                    }: TableRowProps) => {
                      return !rowData.isPortal ? (
                        <NoProcess title={intl.get('PORTAL_SERISI_OLMAYAN_KAYIT_UZERINDE_HERHANGINI_BIR_ISLEM_YAPILAMAZ')} />
                      ) : rowData.isDefault ? (
                        <NoProcess />
                      ) : (
                        <ProcessDropdownButton
                          isRow
                          menu={
                            <Menu>
                              {rowData.activeStatus === 'Active' && rowData.isPortal && (
                                <SetDefault
                                  onClick={() =>
                                    setDefaultConfirm.confirm(
                                      { id: rowData.id },
                                      {
                                        content: intl.getHTML('SERI_VARSAYILAN_AYARLANACAKTIR_DEVAM_EDILSIN_MI', { serie: rowData.serie }),
                                        title: `Seri Varsayılan Ayarla - ${rowData.serie} - ${rowData.id}`,
                                        successCallback: () => {
                                          const newData = updateItemsByFilter(
                                            pageData,
                                            (item: DocumentSerie) => item.isDefault,
                                            (item) => ({ ...item, isDefault: false })
                                          );

                                          setData(
                                            updateItemsByFilter(
                                              newData,
                                              (item: DocumentSerie) => item.id === rowData.id,
                                              (item) => ({ ...item, isDefault: true })
                                            )
                                          );

                                          InfoToastify(intl.getHTML('SERI_VARSAYILAN_AYARLANDI', { serie: rowData.serie }));
                                        }
                                      }
                                    )
                                  }
                                />
                              )}
                              {rowData.activeStatus === 'Active' && rowData.isPortal && <Menu.Divider />}

                              {rowData.activeStatus === 'Active' && (
                                <SetPassive
                                  onClick={() =>
                                    setStatus.confirm(
                                      { id: rowData.id, status: IActiveStatus.Passive },
                                      {
                                        content: intl.getHTML('SERI_PASIF_EDILECEKTIR_DEVAM_EDILSIN_MI', { serie: rowData.serie }),
                                        title: `Seri Pasif Et - ${rowData.serie} - ${rowData.id}`,
                                        successCallback: () => {
                                          setData(pageData.filter((x) => x.id !== rowData.id));

                                          InfoToastify(intl.getHTML('SERI_PASIF_EDILDI', { serie: rowData.serie }));
                                        }
                                      }
                                    )
                                  }
                                />
                              )}
                              {rowData.activeStatus === 'Passive' && (
                                <SetActive
                                  onClick={() =>
                                    setStatus.confirm(
                                      { id: rowData.id, status: IActiveStatus.Active },
                                      {
                                        content: intl.getHTML('SERI_AKTIF_EDILECEKTIR_DEVAM_EDILSIN_MI', { serie: rowData.serie }),
                                        title: `Seri Pasif Et - ${rowData.serie} - ${rowData.id}`,
                                        successCallback: () => {
                                          setData(pageData.filter((x) => x.id !== rowData.id));

                                          InfoToastify(intl.getHTML('SERI_AKTIF_EDILDI', { serie: rowData.serie }));
                                        }
                                      }
                                    )
                                  }
                                />
                              )}
                              {rowData.activeStatus !== IActiveStatus.Deleted && <Menu.Divider />}
                              {rowData.activeStatus !== IActiveStatus.Deleted && (
                                <Delete
                                  onClick={() =>
                                    HasRoleAndNotifiy(deleteRole) &&
                                    setStatus.confirm(
                                      { id: rowData.id, status: IActiveStatus.Deleted },
                                      {
                                        content: intl.getHTML('SERI_SILINDI_OLARAK_ISARETLENECEKTIR_DEVAM_EDILSIN_MI', { serie: rowData.serie }),
                                        title: `Seri Pasif Et - ${rowData.serie} - ${rowData.id}`,
                                        successCallback: () => {
                                          setData(pageData.filter((x) => x.id !== rowData.id));

                                          InfoToastify(intl.getHTML('SERI_SILINDI_OLARAK_ISARETLENDI', { serie: rowData.serie }));
                                        }
                                      }
                                    )
                                  }
                                />
                              )}

                              {rowData.activeStatus === IActiveStatus.Deleted && (
                                <>
                                  <SetActive
                                    onClick={() =>
                                      setStatus.confirm(
                                        { id: rowData.id, status: IActiveStatus.Active },
                                        {
                                          content: intl.getHTML('SERI_AKTIF_EDILECEKTIR_DEVAM_EDILSIN_MI', { serie: rowData.serie }),
                                          title: `Seri Pasif Et - ${rowData.serie} - ${rowData.id}`,
                                          successCallback: () => {
                                            setData(pageData.filter((x) => x.id !== rowData.id));

                                            InfoToastify(intl.getHTML('SERI_AKTIF_EDILDI', { serie: rowData.serie }));
                                          }
                                        }
                                      )
                                    }
                                  />

                                  <PermanentDelete
                                    onClick={() =>
                                      HasRoleAndNotifiy(deleteRole) &&
                                      deleteSerie.confirm(
                                        { id: rowData.id },
                                        {
                                          content: intl.getHTML('SERI_KALICI_OLARAK_SILINECEKTIR_DEVAM_EDILSIN_MI', { serie: rowData.serie }),
                                          title: `Seri Kalıcı Olarak Sil - ${rowData.serie} - ${rowData.id}`,
                                          successCallback: () => {
                                            setData(pageData.filter((x) => x.id !== rowData.id));
                                            InfoToastify(intl.getHTML('SERI_KALICI_OLARAK_SILINDI', { serie: rowData.serie }));
                                          }
                                        }
                                      )
                                    }
                                  />
                                </>
                              )}
                            </Menu>
                          }
                        />
                      );
                    }
                  }
                ]}
              />
            </Row>
          </CardBody>
        </Card>
      </RootSpinner>
    );
  }
);

export default Series;
