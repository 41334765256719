import { TaxesWithoutKdv } from '@/constants/gib';
import InvoiceEditorContext from '@/contexts/InvoiceEditorContext';
import { TaxSubTotalType } from '@/models/xmlDocuments/cac';
import { Modal, Select } from 'antd';
import React, { memo, useContext, useEffect, useState } from 'react';
import { WarningToastify } from '.';

const confirm = Modal.confirm;

interface Props {
  taxSubTotalType: TaxSubTotalType;
  selectedTaxes: string[];
  value?: number | string;
  onValueChange?: (value: string) => void;
  id?: string;
  onChange?: (value: string) => void;
}

export const InvoiceTaxSelect = memo(
  ({ selectedTaxes, onValueChange, taxSubTotalType, id, ...rest }: Props) => {
    const [{ invoice }, dispatch] = useContext(InvoiceEditorContext);

    const { value, onChange } = rest as any;

    const [state, setState] = useState<string>(value || '');

    const onSelect = (selectedValue) => {
      if (value === selectedValue) {
        return false;
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const gibTax = TaxesWithoutKdv.find((x) => x.taxTypeCode === selectedValue)!;

      const element = document.getElementById(id!) as HTMLDivElement;
      element.blur();

      // ÖTV 1.Liste değiştirilirker ÖTV Tevkifatı var ise işlem engelleniyor
      if (value === '0071' && selectedTaxes.some((x) => x === '4171')) {
        WarningToastify(
          <div>
            <b>Ö.T.V Tevkifatı</b>, eklenmiş olduğu durumda <b>ÖTV 1. Liste</b> zorunludur!
          </div>
        );

        return;
      }

      if (selectedValue === '4171' && invoice.invoiceTypeCode !== 'TEVKIFAT') {
        WarningToastify(
          <div>
            <b>{gibTax.description}</b> vergisi ekleyebilmek için fatura tipi <b>TEVKIFAT</b> olmalıdır!
          </div>
        );

        setTimeout(() => {
          element.focus();
        }, 250);

        return;
      }

      if (selectedValue === '4171' && !selectedTaxes.some((x) => x === '0071')) {
        WarningToastify(
          <div>
            <b>{gibTax.description}</b> ekleyebilmek için <b>ÖTV 1. Liste</b> eklenmiş olmalıdır!
          </div>
        );

        setTimeout(() => {
          element.focus();
        }, 250);
        return;
      }

      if ((taxSubTotalType.taxAmount?.value || 0) > 0 || (taxSubTotalType.percent || 0) > 0) {
        confirm({
          title: 'Devam edilsin mi?',
          content: 'Vergi seçilmiş ve oran/tutar girilmiş. Seçili vergi değiştirildiğinde bilgiler sıfırlanacaktır.',
          okText: 'Evet',
          okType: 'danger',
          cancelText: 'Hayır',
          onOk() {
            setState(selectedValue);
          },
          onCancel() {
            return false;
          }
        });
      } else {
        setState(selectedValue);
      }
    };

    useEffect(() => {
      onChange && onChange(state);
      onValueChange && onValueChange(state);
    }, [state]);

    useEffect(() => {
      if (value === '') {
        const element = document.getElementById(id!) as HTMLDivElement;
        element.focus();
      }
    }, []);

    return (
      <Select id={id} showAction={['focus', 'click']} showSearch optionFilterProp={'children'} className="w-100" value={state} onSelect={onSelect}>
        {TaxesWithoutKdv.map(({ description, taxTypeCode }) => (
          <Select.Option disabled={selectedTaxes.some((x) => x === taxTypeCode)} key={taxTypeCode} value={taxTypeCode}>
            {description}
          </Select.Option>
        ))}
      </Select>
    );
  },
  (prev: Props, next: Props) => {
    return prev.value === next.value && prev.selectedTaxes === next.selectedTaxes;
  }
);

export default InvoiceTaxSelect;
