// tslint:disable
// eslint:disable
/**
 * General API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CompaniesType } from '../interfaces';
// @ts-ignore
import { Company } from '../interfaces';
// @ts-ignore
import { CompanyAddress } from '../interfaces';
// @ts-ignore
import { CompanyAddressDto } from '../interfaces';
// @ts-ignore
import { CompanyDto } from '../interfaces';
// @ts-ignore
import { CompanyPaginationResult } from '../interfaces';
/**
 * CompaniesApi - axios parameter creator
 * @export
 */
export const CompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Kayıtlı firma listenize bu uç ile ulaşabilirsiniz.
         * @summary Firma listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {CompaniesType} [companiesType] **Yurt içi firmaları ya da ihracat firmalarını sorgulamak için kullanılabilir**
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesGet: async (requestParameters: V1CompaniesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1CompaniesGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1CompaniesGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1CompaniesGet.');
            }
            const localVarPath = `/v1/companies`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.companiesType !== undefined) {
                localVarQueryParameter['companiesType'] = requestParameters.companiesType;
            }
            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }
            if (requestParameters.query !== undefined) {
                localVarQueryParameter['query'] = requestParameters.query;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Firmaya ait adresi silmek bu uç kullanılabilir.
         * @summary Firma adresini siler
         * @param {string} id **Firma id**
         * @param {string} addressId **Adres id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesIdAddressesAddressIdDelete: async (requestParameters: V1CompaniesIdAddressesAddressIdDeleteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1CompaniesIdAddressesAddressIdDelete.');
            }
            // verify required parameter 'addressId' is not null or undefined
            if (requestParameters.addressId === null || requestParameters.addressId === undefined) {
                throw new RequiredError('addressId','Required parameter addressId was null or undefined when calling v1CompaniesIdAddressesAddressIdDelete.');
            }
            const localVarPath = `/v1/companies/{id}/addresses/{addressId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"addressId"}}`, encodeURIComponent(String(requestParameters.addressId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Firmada kayıtlı olan adres bilgilerini düzenlemek için bu uç kullanılabilir.
         * @summary Firma adresini günceller
         * @param {string} id **Firma id**
         * @param {string} addressId **Adres id**
         * @param {CompanyAddressDto} companyAddressDto #### Adres düzenleme için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesIdAddressesAddressIdPut: async (requestParameters: V1CompaniesIdAddressesAddressIdPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1CompaniesIdAddressesAddressIdPut.');
            }
            // verify required parameter 'addressId' is not null or undefined
            if (requestParameters.addressId === null || requestParameters.addressId === undefined) {
                throw new RequiredError('addressId','Required parameter addressId was null or undefined when calling v1CompaniesIdAddressesAddressIdPut.');
            }
            // verify required parameter 'companyAddressDto' is not null or undefined
            if (requestParameters.companyAddressDto === null || requestParameters.companyAddressDto === undefined) {
                throw new RequiredError('companyAddressDto','Required parameter companyAddressDto was null or undefined when calling v1CompaniesIdAddressesAddressIdPut.');
            }
            const localVarPath = `/v1/companies/{id}/addresses/{addressId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"addressId"}}`, encodeURIComponent(String(requestParameters.addressId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.companyAddressDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.companyAddressDto !== undefined ? requestParameters.companyAddressDto : {}) : (requestParameters.companyAddressDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Firmanın adresini varsayılan olarak atamak için bu uç kullanılabilir.
         * @summary Adresi varsayılan ayarlar
         * @param {string} id **Firma id**
         * @param {string} addressId **Adres id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesIdAddressesAddressIdSetdefaultGet: async (requestParameters: V1CompaniesIdAddressesAddressIdSetdefaultGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1CompaniesIdAddressesAddressIdSetdefaultGet.');
            }
            // verify required parameter 'addressId' is not null or undefined
            if (requestParameters.addressId === null || requestParameters.addressId === undefined) {
                throw new RequiredError('addressId','Required parameter addressId was null or undefined when calling v1CompaniesIdAddressesAddressIdSetdefaultGet.');
            }
            const localVarPath = `/v1/companies/{id}/addresses/{addressId}/setdefault`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"addressId"}}`, encodeURIComponent(String(requestParameters.addressId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Kayıtlı firmanın adreslerine bu uç ile ulaşabilirsiniz.
         * @summary Firma adreslerini listeler
         * @param {string} id **Adresleri sorgulanan firma id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesIdAddressesGet: async (requestParameters: V1CompaniesIdAddressesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1CompaniesIdAddressesGet.');
            }
            const localVarPath = `/v1/companies/{id}/addresses`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Firmaya yeni adres oluşturmak için bu uç kullanılabilir.
         * @summary Firma adresi oluşturur
         * @param {string} id **Adres eklenecek firma id**
         * @param {CompanyAddressDto} companyAddressDto #### Adres oluşturmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesIdAddressesPost: async (requestParameters: V1CompaniesIdAddressesPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1CompaniesIdAddressesPost.');
            }
            // verify required parameter 'companyAddressDto' is not null or undefined
            if (requestParameters.companyAddressDto === null || requestParameters.companyAddressDto === undefined) {
                throw new RequiredError('companyAddressDto','Required parameter companyAddressDto was null or undefined when calling v1CompaniesIdAddressesPost.');
            }
            const localVarPath = `/v1/companies/{id}/addresses`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.companyAddressDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.companyAddressDto !== undefined ? requestParameters.companyAddressDto : {}) : (requestParameters.companyAddressDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Kayıtlı firmayı silmek bu uç kullanılabilir.
         * @summary Firma siler
         * @param {string} id **Silinecek firma id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesIdDelete: async (requestParameters: V1CompaniesIdDeleteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1CompaniesIdDelete.');
            }
            const localVarPath = `/v1/companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sorgulanan firmayı getirir
         * @param {string} id **Firma id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesIdGet: async (requestParameters: V1CompaniesIdGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1CompaniesIdGet.');
            }
            const localVarPath = `/v1/companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Kayıtlı firma bilgilerini düzenlemek için bu uç kullanılabilir.
         * @summary Firma bilgilerini günceller
         * @param {string} id **Düzenlenecek firma id**
         * @param {CompanyDto} companyDto #### Güncelleme için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesIdPut: async (requestParameters: V1CompaniesIdPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1CompaniesIdPut.');
            }
            // verify required parameter 'companyDto' is not null or undefined
            if (requestParameters.companyDto === null || requestParameters.companyDto === undefined) {
                throw new RequiredError('companyDto','Required parameter companyDto was null or undefined when calling v1CompaniesIdPut.');
            }
            const localVarPath = `/v1/companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.companyDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.companyDto !== undefined ? requestParameters.companyDto : {}) : (requestParameters.companyDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesImportPost: async (requestParameters: V1CompaniesImportPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/companies/import`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


                if (requestParameters.file !== undefined) { 
                    localVarFormParams.append('file', requestParameters.file as any);
                }

    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Yeni firma oluşturmak için bu uç kullanılabilir.
         * @summary Firma oluşturur
         * @param {CompanyDto} companyDto #### Firma oluşturmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesPost: async (requestParameters: V1CompaniesPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyDto' is not null or undefined
            if (requestParameters.companyDto === null || requestParameters.companyDto === undefined) {
                throw new RequiredError('companyDto','Required parameter companyDto was null or undefined when calling v1CompaniesPost.');
            }
            const localVarPath = `/v1/companies`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.companyDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.companyDto !== undefined ? requestParameters.companyDto : {}) : (requestParameters.companyDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompaniesApi - functional programming interface
 * @export
 */
export const CompaniesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Kayıtlı firma listenize bu uç ile ulaşabilirsiniz.
         * @summary Firma listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {CompaniesType} [companiesType] **Yurt içi firmaları ya da ihracat firmalarını sorgulamak için kullanılabilir**
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CompaniesGet(requestParameters: V1CompaniesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyPaginationResult>> {
            const localVarAxiosArgs = await CompaniesApiAxiosParamCreator(configuration).v1CompaniesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Firmaya ait adresi silmek bu uç kullanılabilir.
         * @summary Firma adresini siler
         * @param {string} id **Firma id**
         * @param {string} addressId **Adres id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CompaniesIdAddressesAddressIdDelete(requestParameters: V1CompaniesIdAddressesAddressIdDeleteRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CompaniesApiAxiosParamCreator(configuration).v1CompaniesIdAddressesAddressIdDelete(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Firmada kayıtlı olan adres bilgilerini düzenlemek için bu uç kullanılabilir.
         * @summary Firma adresini günceller
         * @param {string} id **Firma id**
         * @param {string} addressId **Adres id**
         * @param {CompanyAddressDto} companyAddressDto #### Adres düzenleme için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CompaniesIdAddressesAddressIdPut(requestParameters: V1CompaniesIdAddressesAddressIdPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CompaniesApiAxiosParamCreator(configuration).v1CompaniesIdAddressesAddressIdPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Firmanın adresini varsayılan olarak atamak için bu uç kullanılabilir.
         * @summary Adresi varsayılan ayarlar
         * @param {string} id **Firma id**
         * @param {string} addressId **Adres id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CompaniesIdAddressesAddressIdSetdefaultGet(requestParameters: V1CompaniesIdAddressesAddressIdSetdefaultGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CompaniesApiAxiosParamCreator(configuration).v1CompaniesIdAddressesAddressIdSetdefaultGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Kayıtlı firmanın adreslerine bu uç ile ulaşabilirsiniz.
         * @summary Firma adreslerini listeler
         * @param {string} id **Adresleri sorgulanan firma id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CompaniesIdAddressesGet(requestParameters: V1CompaniesIdAddressesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyAddress>>> {
            const localVarAxiosArgs = await CompaniesApiAxiosParamCreator(configuration).v1CompaniesIdAddressesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Firmaya yeni adres oluşturmak için bu uç kullanılabilir.
         * @summary Firma adresi oluşturur
         * @param {string} id **Adres eklenecek firma id**
         * @param {CompanyAddressDto} companyAddressDto #### Adres oluşturmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CompaniesIdAddressesPost(requestParameters: V1CompaniesIdAddressesPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyAddress>> {
            const localVarAxiosArgs = await CompaniesApiAxiosParamCreator(configuration).v1CompaniesIdAddressesPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Kayıtlı firmayı silmek bu uç kullanılabilir.
         * @summary Firma siler
         * @param {string} id **Silinecek firma id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CompaniesIdDelete(requestParameters: V1CompaniesIdDeleteRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CompaniesApiAxiosParamCreator(configuration).v1CompaniesIdDelete(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sorgulanan firmayı getirir
         * @param {string} id **Firma id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CompaniesIdGet(requestParameters: V1CompaniesIdGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await CompaniesApiAxiosParamCreator(configuration).v1CompaniesIdGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Kayıtlı firma bilgilerini düzenlemek için bu uç kullanılabilir.
         * @summary Firma bilgilerini günceller
         * @param {string} id **Düzenlenecek firma id**
         * @param {CompanyDto} companyDto #### Güncelleme için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CompaniesIdPut(requestParameters: V1CompaniesIdPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await CompaniesApiAxiosParamCreator(configuration).v1CompaniesIdPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CompaniesImportPost(requestParameters: V1CompaniesImportPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CompaniesApiAxiosParamCreator(configuration).v1CompaniesImportPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Yeni firma oluşturmak için bu uç kullanılabilir.
         * @summary Firma oluşturur
         * @param {CompanyDto} companyDto #### Firma oluşturmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CompaniesPost(requestParameters: V1CompaniesPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await CompaniesApiAxiosParamCreator(configuration).v1CompaniesPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CompaniesApi - factory interface
 * @export
 */
export const CompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Kayıtlı firma listenize bu uç ile ulaşabilirsiniz.
         * @summary Firma listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {CompaniesType} [companiesType] **Yurt içi firmaları ya da ihracat firmalarını sorgulamak için kullanılabilir**
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesGet(requestParameters: V1CompaniesGetRequest, options?: any): AxiosPromise<CompanyPaginationResult> {
            return CompaniesApiFp(configuration).v1CompaniesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Firmaya ait adresi silmek bu uç kullanılabilir.
         * @summary Firma adresini siler
         * @param {string} id **Firma id**
         * @param {string} addressId **Adres id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesIdAddressesAddressIdDelete(requestParameters: V1CompaniesIdAddressesAddressIdDeleteRequest, options?: any): AxiosPromise<void> {
            return CompaniesApiFp(configuration).v1CompaniesIdAddressesAddressIdDelete(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Firmada kayıtlı olan adres bilgilerini düzenlemek için bu uç kullanılabilir.
         * @summary Firma adresini günceller
         * @param {string} id **Firma id**
         * @param {string} addressId **Adres id**
         * @param {CompanyAddressDto} companyAddressDto #### Adres düzenleme için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesIdAddressesAddressIdPut(requestParameters: V1CompaniesIdAddressesAddressIdPutRequest, options?: any): AxiosPromise<void> {
            return CompaniesApiFp(configuration).v1CompaniesIdAddressesAddressIdPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Firmanın adresini varsayılan olarak atamak için bu uç kullanılabilir.
         * @summary Adresi varsayılan ayarlar
         * @param {string} id **Firma id**
         * @param {string} addressId **Adres id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesIdAddressesAddressIdSetdefaultGet(requestParameters: V1CompaniesIdAddressesAddressIdSetdefaultGetRequest, options?: any): AxiosPromise<void> {
            return CompaniesApiFp(configuration).v1CompaniesIdAddressesAddressIdSetdefaultGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Kayıtlı firmanın adreslerine bu uç ile ulaşabilirsiniz.
         * @summary Firma adreslerini listeler
         * @param {string} id **Adresleri sorgulanan firma id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesIdAddressesGet(requestParameters: V1CompaniesIdAddressesGetRequest, options?: any): AxiosPromise<Array<CompanyAddress>> {
            return CompaniesApiFp(configuration).v1CompaniesIdAddressesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Firmaya yeni adres oluşturmak için bu uç kullanılabilir.
         * @summary Firma adresi oluşturur
         * @param {string} id **Adres eklenecek firma id**
         * @param {CompanyAddressDto} companyAddressDto #### Adres oluşturmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesIdAddressesPost(requestParameters: V1CompaniesIdAddressesPostRequest, options?: any): AxiosPromise<CompanyAddress> {
            return CompaniesApiFp(configuration).v1CompaniesIdAddressesPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Kayıtlı firmayı silmek bu uç kullanılabilir.
         * @summary Firma siler
         * @param {string} id **Silinecek firma id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesIdDelete(requestParameters: V1CompaniesIdDeleteRequest, options?: any): AxiosPromise<void> {
            return CompaniesApiFp(configuration).v1CompaniesIdDelete(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sorgulanan firmayı getirir
         * @param {string} id **Firma id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesIdGet(requestParameters: V1CompaniesIdGetRequest, options?: any): AxiosPromise<Company> {
            return CompaniesApiFp(configuration).v1CompaniesIdGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Kayıtlı firma bilgilerini düzenlemek için bu uç kullanılabilir.
         * @summary Firma bilgilerini günceller
         * @param {string} id **Düzenlenecek firma id**
         * @param {CompanyDto} companyDto #### Güncelleme için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesIdPut(requestParameters: V1CompaniesIdPutRequest, options?: any): AxiosPromise<Company> {
            return CompaniesApiFp(configuration).v1CompaniesIdPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesImportPost(requestParameters: V1CompaniesImportPostRequest, options?: any): AxiosPromise<void> {
            return CompaniesApiFp(configuration).v1CompaniesImportPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Yeni firma oluşturmak için bu uç kullanılabilir.
         * @summary Firma oluşturur
         * @param {CompanyDto} companyDto #### Firma oluşturmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CompaniesPost(requestParameters: V1CompaniesPostRequest, options?: any): AxiosPromise<Company> {
            return CompaniesApiFp(configuration).v1CompaniesPost(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompaniesApi - object-oriented interface
 * @export
 * @class CompaniesApi
 * @extends {BaseAPI}
 */
export class CompaniesApi extends BaseAPI {
    /**
     * Kayıtlı firma listenize bu uç ile ulaşabilirsiniz.
     * @summary Firma listeler
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {CompaniesType} [companiesType] **Yurt içi firmaları ya da ihracat firmalarını sorgulamak için kullanılabilir**
     * @param {string} [query] Aranacak kelime
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public v1CompaniesGet(requestParameters: V1CompaniesGetRequest, options?: any) {
        return CompaniesApiFp(this.configuration).v1CompaniesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Firmaya ait adresi silmek bu uç kullanılabilir.
     * @summary Firma adresini siler
     * @param {string} id **Firma id**
     * @param {string} addressId **Adres id**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public v1CompaniesIdAddressesAddressIdDelete(requestParameters: V1CompaniesIdAddressesAddressIdDeleteRequest, options?: any) {
        return CompaniesApiFp(this.configuration).v1CompaniesIdAddressesAddressIdDelete(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Firmada kayıtlı olan adres bilgilerini düzenlemek için bu uç kullanılabilir.
     * @summary Firma adresini günceller
     * @param {string} id **Firma id**
     * @param {string} addressId **Adres id**
     * @param {CompanyAddressDto} companyAddressDto #### Adres düzenleme için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public v1CompaniesIdAddressesAddressIdPut(requestParameters: V1CompaniesIdAddressesAddressIdPutRequest, options?: any) {
        return CompaniesApiFp(this.configuration).v1CompaniesIdAddressesAddressIdPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Firmanın adresini varsayılan olarak atamak için bu uç kullanılabilir.
     * @summary Adresi varsayılan ayarlar
     * @param {string} id **Firma id**
     * @param {string} addressId **Adres id**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public v1CompaniesIdAddressesAddressIdSetdefaultGet(requestParameters: V1CompaniesIdAddressesAddressIdSetdefaultGetRequest, options?: any) {
        return CompaniesApiFp(this.configuration).v1CompaniesIdAddressesAddressIdSetdefaultGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Kayıtlı firmanın adreslerine bu uç ile ulaşabilirsiniz.
     * @summary Firma adreslerini listeler
     * @param {string} id **Adresleri sorgulanan firma id**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public v1CompaniesIdAddressesGet(requestParameters: V1CompaniesIdAddressesGetRequest, options?: any) {
        return CompaniesApiFp(this.configuration).v1CompaniesIdAddressesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Firmaya yeni adres oluşturmak için bu uç kullanılabilir.
     * @summary Firma adresi oluşturur
     * @param {string} id **Adres eklenecek firma id**
     * @param {CompanyAddressDto} companyAddressDto #### Adres oluşturmak için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public v1CompaniesIdAddressesPost(requestParameters: V1CompaniesIdAddressesPostRequest, options?: any) {
        return CompaniesApiFp(this.configuration).v1CompaniesIdAddressesPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Kayıtlı firmayı silmek bu uç kullanılabilir.
     * @summary Firma siler
     * @param {string} id **Silinecek firma id**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public v1CompaniesIdDelete(requestParameters: V1CompaniesIdDeleteRequest, options?: any) {
        return CompaniesApiFp(this.configuration).v1CompaniesIdDelete(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sorgulanan firmayı getirir
     * @param {string} id **Firma id**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public v1CompaniesIdGet(requestParameters: V1CompaniesIdGetRequest, options?: any) {
        return CompaniesApiFp(this.configuration).v1CompaniesIdGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Kayıtlı firma bilgilerini düzenlemek için bu uç kullanılabilir.
     * @summary Firma bilgilerini günceller
     * @param {string} id **Düzenlenecek firma id**
     * @param {CompanyDto} companyDto #### Güncelleme için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public v1CompaniesIdPut(requestParameters: V1CompaniesIdPutRequest, options?: any) {
        return CompaniesApiFp(this.configuration).v1CompaniesIdPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public v1CompaniesImportPost(requestParameters: V1CompaniesImportPostRequest, options?: any) {
        return CompaniesApiFp(this.configuration).v1CompaniesImportPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Yeni firma oluşturmak için bu uç kullanılabilir.
     * @summary Firma oluşturur
     * @param {CompanyDto} companyDto #### Firma oluşturmak için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public v1CompaniesPost(requestParameters: V1CompaniesPostRequest, options?: any) {
        return CompaniesApiFp(this.configuration).v1CompaniesPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1CompaniesGetRequest {
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    /**
     * **Yurt içi firmaları ya da ihracat firmalarını sorgulamak için kullanılabilir**
     */
    companiesType?: CompaniesType;
    /**
     * Aranacak kelime
     */
    query?: string;
    }
    export interface V1CompaniesIdAddressesAddressIdDeleteRequest {
    /**
     * **Firma id**
     */
    id: string;
    /**
     * **Adres id**
     */
    addressId: string;
    }
    export interface V1CompaniesIdAddressesAddressIdPutRequest {
    /**
     * **Firma id**
     */
    id: string;
    /**
     * **Adres id**
     */
    addressId: string;
    /**
     * #### Adres düzenleme için gönderilmesi gereken model
     */
    companyAddressDto: CompanyAddressDto;
    }
    export interface V1CompaniesIdAddressesAddressIdSetdefaultGetRequest {
    /**
     * **Firma id**
     */
    id: string;
    /**
     * **Adres id**
     */
    addressId: string;
    }
    export interface V1CompaniesIdAddressesGetRequest {
    /**
     * **Adresleri sorgulanan firma id**
     */
    id: string;
    }
    export interface V1CompaniesIdAddressesPostRequest {
    /**
     * **Adres eklenecek firma id**
     */
    id: string;
    /**
     * #### Adres oluşturmak için gönderilmesi gereken model
     */
    companyAddressDto: CompanyAddressDto;
    }
    export interface V1CompaniesIdDeleteRequest {
    /**
     * **Silinecek firma id**
     */
    id: string;
    }
    export interface V1CompaniesIdGetRequest {
    /**
     * **Firma id**
     */
    id: string;
    }
    export interface V1CompaniesIdPutRequest {
    /**
     * **Düzenlenecek firma id**
     */
    id: string;
    /**
     * #### Güncelleme için gönderilmesi gereken model
     */
    companyDto: CompanyDto;
    }
    export interface V1CompaniesImportPostRequest {
    /**
     * 
     */
    file?: any;
    }
    export interface V1CompaniesPostRequest {
    /**
     * #### Firma oluşturmak için gönderilmesi gereken model
     */
    companyDto: CompanyDto;
    }
