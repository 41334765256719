// tslint:disable
// eslint:disable
/**
 * E-Invoice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AliasTypeParameter } from '../interfaces';
// @ts-ignore
import { UserInfo } from '../interfaces';
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Bir firmanın GIB\'de kayıtlı mükellef olup olmadığını ***V.K.N.*** veya ***T.C.K.N*** ile sorgulamak için bu uç kullanılabilir.
         * @summary Kimlik No ile sorgular
         * @param {AliasTypeParameter} aliasType **Etiket tipi**
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersAliasTypePost: async (requestParameters: V1UsersAliasTypePostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'aliasType' is not null or undefined
            if (requestParameters.aliasType === null || requestParameters.aliasType === undefined) {
                throw new RequiredError('aliasType','Required parameter aliasType was null or undefined when calling v1UsersAliasTypePost.');
            }
            const localVarPath = `/v1/users/{aliasType}`.replace(`{${"aliasType"}}`, encodeURIComponent(String(requestParameters.aliasType)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.requestBody !== undefined ? requestParameters.requestBody : {}) : (requestParameters.requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Bir firmanın GIB\'de kayıtlı mükellef olup olmadığını ***V.K.N.*** veya ***T.C.K.N*** ile sorgulamak için bu uç kullanılabilir.
         * @summary Kimlik No ile sorgular
         * @param {string} identifier **Vergi Kimlik / T.C. Kimlik No**
         * @param {AliasTypeParameter} aliasType **Etiket tipi**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdentifierAliasTypeGet: async (requestParameters: V1UsersIdentifierAliasTypeGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'identifier' is not null or undefined
            if (requestParameters.identifier === null || requestParameters.identifier === undefined) {
                throw new RequiredError('identifier','Required parameter identifier was null or undefined when calling v1UsersIdentifierAliasTypeGet.');
            }
            // verify required parameter 'aliasType' is not null or undefined
            if (requestParameters.aliasType === null || requestParameters.aliasType === undefined) {
                throw new RequiredError('aliasType','Required parameter aliasType was null or undefined when calling v1UsersIdentifierAliasTypeGet.');
            }
            const localVarPath = `/v1/users/{identifier}/{aliasType}`.replace(`{${"identifier"}}`, encodeURIComponent(String(requestParameters.identifier))).replace(`{${"aliasType"}}`, encodeURIComponent(String(requestParameters.aliasType)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Bir firmanın GIB\'de kayıtlı mükellef olup olmadığını ***Ünvan*** ile sorgulamak için bu uç kullanılabilir.
         * @summary Ünvan ile sorgular
         * @param {string} query **Ünvan**
         * @param {AliasTypeParameter} aliasType **Etiket tipi**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersSearchQueryAliasTypeGet: async (requestParameters: V1UsersSearchQueryAliasTypeGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            if (requestParameters.query === null || requestParameters.query === undefined) {
                throw new RequiredError('query','Required parameter query was null or undefined when calling v1UsersSearchQueryAliasTypeGet.');
            }
            // verify required parameter 'aliasType' is not null or undefined
            if (requestParameters.aliasType === null || requestParameters.aliasType === undefined) {
                throw new RequiredError('aliasType','Required parameter aliasType was null or undefined when calling v1UsersSearchQueryAliasTypeGet.');
            }
            const localVarPath = `/v1/users/search/{query}/{aliasType}`.replace(`{${"query"}}`, encodeURIComponent(String(requestParameters.query))).replace(`{${"aliasType"}}`, encodeURIComponent(String(requestParameters.aliasType)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GIB\'de kayıtlı mükelleflerin listesini ZIP olarak indirmek için bu uç kullanılabilir.
         * @summary Mükellef listesini indirir
         * @param {AliasTypeParameter} aliasType **Etiket tipi**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersZipAliasTypeGet: async (requestParameters: V1UsersZipAliasTypeGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'aliasType' is not null or undefined
            if (requestParameters.aliasType === null || requestParameters.aliasType === undefined) {
                throw new RequiredError('aliasType','Required parameter aliasType was null or undefined when calling v1UsersZipAliasTypeGet.');
            }
            const localVarPath = `/v1/users/zip/{aliasType}`.replace(`{${"aliasType"}}`, encodeURIComponent(String(requestParameters.aliasType)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Bir firmanın GIB\'de kayıtlı mükellef olup olmadığını ***V.K.N.*** veya ***T.C.K.N*** ile sorgulamak için bu uç kullanılabilir.
         * @summary Kimlik No ile sorgular
         * @param {AliasTypeParameter} aliasType **Etiket tipi**
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UsersAliasTypePost(requestParameters: V1UsersAliasTypePostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserInfo>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v1UsersAliasTypePost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Bir firmanın GIB\'de kayıtlı mükellef olup olmadığını ***V.K.N.*** veya ***T.C.K.N*** ile sorgulamak için bu uç kullanılabilir.
         * @summary Kimlik No ile sorgular
         * @param {string} identifier **Vergi Kimlik / T.C. Kimlik No**
         * @param {AliasTypeParameter} aliasType **Etiket tipi**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UsersIdentifierAliasTypeGet(requestParameters: V1UsersIdentifierAliasTypeGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfo>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v1UsersIdentifierAliasTypeGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Bir firmanın GIB\'de kayıtlı mükellef olup olmadığını ***Ünvan*** ile sorgulamak için bu uç kullanılabilir.
         * @summary Ünvan ile sorgular
         * @param {string} query **Ünvan**
         * @param {AliasTypeParameter} aliasType **Etiket tipi**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UsersSearchQueryAliasTypeGet(requestParameters: V1UsersSearchQueryAliasTypeGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfo>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v1UsersSearchQueryAliasTypeGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * GIB\'de kayıtlı mükelleflerin listesini ZIP olarak indirmek için bu uç kullanılabilir.
         * @summary Mükellef listesini indirir
         * @param {AliasTypeParameter} aliasType **Etiket tipi**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UsersZipAliasTypeGet(requestParameters: V1UsersZipAliasTypeGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v1UsersZipAliasTypeGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Bir firmanın GIB\'de kayıtlı mükellef olup olmadığını ***V.K.N.*** veya ***T.C.K.N*** ile sorgulamak için bu uç kullanılabilir.
         * @summary Kimlik No ile sorgular
         * @param {AliasTypeParameter} aliasType **Etiket tipi**
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersAliasTypePost(requestParameters: V1UsersAliasTypePostRequest, options?: any): AxiosPromise<Array<UserInfo>> {
            return UsersApiFp(configuration).v1UsersAliasTypePost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Bir firmanın GIB\'de kayıtlı mükellef olup olmadığını ***V.K.N.*** veya ***T.C.K.N*** ile sorgulamak için bu uç kullanılabilir.
         * @summary Kimlik No ile sorgular
         * @param {string} identifier **Vergi Kimlik / T.C. Kimlik No**
         * @param {AliasTypeParameter} aliasType **Etiket tipi**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdentifierAliasTypeGet(requestParameters: V1UsersIdentifierAliasTypeGetRequest, options?: any): AxiosPromise<UserInfo> {
            return UsersApiFp(configuration).v1UsersIdentifierAliasTypeGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Bir firmanın GIB\'de kayıtlı mükellef olup olmadığını ***Ünvan*** ile sorgulamak için bu uç kullanılabilir.
         * @summary Ünvan ile sorgular
         * @param {string} query **Ünvan**
         * @param {AliasTypeParameter} aliasType **Etiket tipi**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersSearchQueryAliasTypeGet(requestParameters: V1UsersSearchQueryAliasTypeGetRequest, options?: any): AxiosPromise<UserInfo> {
            return UsersApiFp(configuration).v1UsersSearchQueryAliasTypeGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * GIB\'de kayıtlı mükelleflerin listesini ZIP olarak indirmek için bu uç kullanılabilir.
         * @summary Mükellef listesini indirir
         * @param {AliasTypeParameter} aliasType **Etiket tipi**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersZipAliasTypeGet(requestParameters: V1UsersZipAliasTypeGetRequest, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).v1UsersZipAliasTypeGet(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Bir firmanın GIB\'de kayıtlı mükellef olup olmadığını ***V.K.N.*** veya ***T.C.K.N*** ile sorgulamak için bu uç kullanılabilir.
     * @summary Kimlik No ile sorgular
     * @param {AliasTypeParameter} aliasType **Etiket tipi**
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersAliasTypePost(requestParameters: V1UsersAliasTypePostRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersAliasTypePost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Bir firmanın GIB\'de kayıtlı mükellef olup olmadığını ***V.K.N.*** veya ***T.C.K.N*** ile sorgulamak için bu uç kullanılabilir.
     * @summary Kimlik No ile sorgular
     * @param {string} identifier **Vergi Kimlik / T.C. Kimlik No**
     * @param {AliasTypeParameter} aliasType **Etiket tipi**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersIdentifierAliasTypeGet(requestParameters: V1UsersIdentifierAliasTypeGetRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersIdentifierAliasTypeGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Bir firmanın GIB\'de kayıtlı mükellef olup olmadığını ***Ünvan*** ile sorgulamak için bu uç kullanılabilir.
     * @summary Ünvan ile sorgular
     * @param {string} query **Ünvan**
     * @param {AliasTypeParameter} aliasType **Etiket tipi**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersSearchQueryAliasTypeGet(requestParameters: V1UsersSearchQueryAliasTypeGetRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersSearchQueryAliasTypeGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GIB\'de kayıtlı mükelleflerin listesini ZIP olarak indirmek için bu uç kullanılabilir.
     * @summary Mükellef listesini indirir
     * @param {AliasTypeParameter} aliasType **Etiket tipi**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersZipAliasTypeGet(requestParameters: V1UsersZipAliasTypeGetRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersZipAliasTypeGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1UsersAliasTypePostRequest {
    /**
     * **Etiket tipi**
     */
    aliasType: AliasTypeParameter;
    /**
     * 
     */
    requestBody?: Array<string>;
    }
    export interface V1UsersIdentifierAliasTypeGetRequest {
    /**
     * **Vergi Kimlik / T.C. Kimlik No**
     */
    identifier: string;
    /**
     * **Etiket tipi**
     */
    aliasType: AliasTypeParameter;
    }
    export interface V1UsersSearchQueryAliasTypeGetRequest {
    /**
     * **Ünvan**
     */
    query: string;
    /**
     * **Etiket tipi**
     */
    aliasType: AliasTypeParameter;
    }
    export interface V1UsersZipAliasTypeGetRequest {
    /**
     * **Etiket tipi**
     */
    aliasType: AliasTypeParameter;
    }
