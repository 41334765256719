import {
  AddressType,
  AllowanceChargeType,
  CustomerPartyType,
  DocumentReferenceType,
  IDType,
  InvoiceLineType,
  ItemType,
  OrderReferenceType,
  PartyType,
  PaymentMeansType,
  PaymentTermsType,
  ReceiptLineType,
  SupplierPartyType
} from '@/models/xmlDocuments/cac';
import { Invoice } from '@/models/xmlDocuments/invoice';
import { createProxy } from 'ts-object-path';

export const InvoiceLineArrayProxy = createProxy<InvoiceLineType[]>();
export const AllowanceChageArrayProxy = createProxy<AllowanceChargeType[]>();
export const InvoiceProxy = createProxy<Invoice>();
export const SupplierPartyTypeProxy = createProxy<SupplierPartyType>();
export const CustomerPartyTypeProxy = createProxy<CustomerPartyType>();
export const TaxRepresentativePartyProxy = createProxy<PartyType>();
export const AddressTypeProxy = createProxy<AddressType>();
export const PartyTypeProxy = createProxy<PartyType>();
export const IDTypeProxy = createProxy<IDType>();
export const OrderReferenceProxy = createProxy<OrderReferenceType>();
export const PaymentTermsProxy = createProxy<PaymentTermsType>();
export const PaymentMeansProxy = createProxy<PaymentMeansType>();
export const DocumentReferenceProxy = createProxy<DocumentReferenceType>();
export const InvoiceLineProxy = createProxy<InvoiceLineType>();
export const ReceiptLineProxy = createProxy<ReceiptLineType>();
export const ItemProxy = createProxy<ItemType>();
