// tslint:disable
// eslint:disable
/**
 * General API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccountDefaultDocumentParameterDto } from '../interfaces';
/**
 * AccountDefaultDocumentParameterApi - axios parameter creator
 * @export
 */
export const AccountDefaultDocumentParameterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Detay : Fatura Senaryosu, Gönderim Tipi, Satış Kanalı ayarlarını döner
         * @summary Firmanın Varsayılan Doküman Parametresini döner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AccountDefaultDocumentParameterGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/accountDefaultDocumentParameter`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detay : Firmaya ait varsayılan doküman parametresini güncellemek için bu uç kullanılabilir.
         * @summary Firmanın Varsayılan Doküman Parametresini günceller
         * @param {AccountDefaultDocumentParameterDto} accountDefaultDocumentParameterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AccountDefaultDocumentParameterPut: async (requestParameters: V1AccountDefaultDocumentParameterPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountDefaultDocumentParameterDto' is not null or undefined
            if (requestParameters.accountDefaultDocumentParameterDto === null || requestParameters.accountDefaultDocumentParameterDto === undefined) {
                throw new RequiredError('accountDefaultDocumentParameterDto','Required parameter accountDefaultDocumentParameterDto was null or undefined when calling v1AccountDefaultDocumentParameterPut.');
            }
            const localVarPath = `/v1/accountDefaultDocumentParameter`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.accountDefaultDocumentParameterDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.accountDefaultDocumentParameterDto !== undefined ? requestParameters.accountDefaultDocumentParameterDto : {}) : (requestParameters.accountDefaultDocumentParameterDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountDefaultDocumentParameterApi - functional programming interface
 * @export
 */
export const AccountDefaultDocumentParameterApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Detay : Fatura Senaryosu, Gönderim Tipi, Satış Kanalı ayarlarını döner
         * @summary Firmanın Varsayılan Doküman Parametresini döner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AccountDefaultDocumentParameterGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountDefaultDocumentParameterDto>> {
            const localVarAxiosArgs = await AccountDefaultDocumentParameterApiAxiosParamCreator(configuration).v1AccountDefaultDocumentParameterGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Detay : Firmaya ait varsayılan doküman parametresini güncellemek için bu uç kullanılabilir.
         * @summary Firmanın Varsayılan Doküman Parametresini günceller
         * @param {AccountDefaultDocumentParameterDto} accountDefaultDocumentParameterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AccountDefaultDocumentParameterPut(requestParameters: V1AccountDefaultDocumentParameterPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountDefaultDocumentParameterDto>> {
            const localVarAxiosArgs = await AccountDefaultDocumentParameterApiAxiosParamCreator(configuration).v1AccountDefaultDocumentParameterPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AccountDefaultDocumentParameterApi - factory interface
 * @export
 */
export const AccountDefaultDocumentParameterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Detay : Fatura Senaryosu, Gönderim Tipi, Satış Kanalı ayarlarını döner
         * @summary Firmanın Varsayılan Doküman Parametresini döner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AccountDefaultDocumentParameterGet(options?: any): AxiosPromise<AccountDefaultDocumentParameterDto> {
            return AccountDefaultDocumentParameterApiFp(configuration).v1AccountDefaultDocumentParameterGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Detay : Firmaya ait varsayılan doküman parametresini güncellemek için bu uç kullanılabilir.
         * @summary Firmanın Varsayılan Doküman Parametresini günceller
         * @param {AccountDefaultDocumentParameterDto} accountDefaultDocumentParameterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AccountDefaultDocumentParameterPut(requestParameters: V1AccountDefaultDocumentParameterPutRequest, options?: any): AxiosPromise<AccountDefaultDocumentParameterDto> {
            return AccountDefaultDocumentParameterApiFp(configuration).v1AccountDefaultDocumentParameterPut(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountDefaultDocumentParameterApi - object-oriented interface
 * @export
 * @class AccountDefaultDocumentParameterApi
 * @extends {BaseAPI}
 */
export class AccountDefaultDocumentParameterApi extends BaseAPI {
    /**
     * Detay : Fatura Senaryosu, Gönderim Tipi, Satış Kanalı ayarlarını döner
     * @summary Firmanın Varsayılan Doküman Parametresini döner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountDefaultDocumentParameterApi
     */
    public v1AccountDefaultDocumentParameterGet(options?: any) {
        return AccountDefaultDocumentParameterApiFp(this.configuration).v1AccountDefaultDocumentParameterGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Detay : Firmaya ait varsayılan doküman parametresini güncellemek için bu uç kullanılabilir.
     * @summary Firmanın Varsayılan Doküman Parametresini günceller
     * @param {AccountDefaultDocumentParameterDto} accountDefaultDocumentParameterDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountDefaultDocumentParameterApi
     */
    public v1AccountDefaultDocumentParameterPut(requestParameters: V1AccountDefaultDocumentParameterPutRequest, options?: any) {
        return AccountDefaultDocumentParameterApiFp(this.configuration).v1AccountDefaultDocumentParameterPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1AccountDefaultDocumentParameterPutRequest {
    /**
     * 
     */
    accountDefaultDocumentParameterDto: AccountDefaultDocumentParameterDto;
    }
