import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import { ErrorViewer } from './TableBody';

export const ShowErrorModal = (error: any) => {
  return Modal.error({
    centered: true,
    content: <ErrorViewer error={error} />,
    icon: false,
    width: 720,
    okText: (
      <>
        <FontAwesomeIcon icon={faTimes} className="btn-label" />
        <span>{intl.get('KAPAT')}</span>
      </>
    ),
    okButtonProps: {
      className: 'btn-danger btn-labeled btn-sm',
      prefix: 'btn',
      prefixCls: 'btn'
    }
  });
};

export default ShowErrorModal;
