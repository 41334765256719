/* eslint-disable @typescript-eslint/no-inferrable-types */
import { TaxesWithKdv } from '@/constants/gib';
import { XMLArray, XMLElement } from '@/xmldom-decorators';
import { v4 as uuidv4 } from 'uuid';
import { AllowanceChargeType, AmountType, IDType, ItemType, PriceType, QuantityType, TaxTotalType } from '.';
import { NS_CAC, NS_CBC } from '../constants';
import { DeliveryType } from './Delivery';

const KDV = TaxesWithKdv.find((x) => x.taxTypeCode == '0015')!;

export const DefaultTaxTotal: TaxTotalType = {
  taxAmount: { value: 0 },
  taxSubtotal: [
    {
      taxAmount: { value: 0 },
      taxCategory: { taxScheme: { taxTypeCode: KDV.taxTypeCode, name: KDV.description } },
      percent: 0,
      calculationField: 'Percent',
      taxableAmount: { value: 0 },
      uniqueId: uuidv4()
    }
  ]
};

/*
  27.04.2021 : Partially completed
  cac:OrderLineReference
  cac:DespatchLineReference
  cac:ReceiptLineReference
  cac:Delivery
*/
export class InvoiceLineType {
  @XMLElement({ types: [{ name: 'ID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  id?: IDType;

  // @XMLArray({ nested: false, itemTypes: [{ name: 'Note', namespaceUri: NS_CBC, itemType: () => String }] })
  // note?: string[];

  @XMLElement({ types: [{ name: 'InvoicedQuantity', namespaceUri: NS_CBC, itemType: () => QuantityType }] })
  invoicedQuantity?: QuantityType = { value: 1, unitCode: 'C62' };

  @XMLElement({ types: [{ name: 'LineExtensionAmount', namespaceUri: NS_CBC, itemType: () => AmountType }] })
  lineExtensionAmount?: AmountType;

  //TODO : OrderLineReference
  //TODO : DespatchLineReference
  //TODO : ReceiptLineReference
  @XMLArray({ nested: false, itemTypes: [{ name: 'Delivery', namespaceUri: NS_CAC, itemType: () => DeliveryType }] })
  delivery?: DeliveryType[];

  @XMLArray({ nested: false, itemTypes: [{ name: 'AllowanceCharge', namespaceUri: NS_CAC, itemType: () => AllowanceChargeType }] })
  allowanceCharge?: AllowanceChargeType[] = [];

  @XMLElement({ types: [{ name: 'TaxTotal', namespaceUri: NS_CAC, itemType: () => TaxTotalType }] })
  taxTotal: TaxTotalType = DefaultTaxTotal;

  @XMLArray({ nested: false, itemTypes: [{ name: 'WithholdingTaxTotal', namespaceUri: NS_CAC, itemType: () => TaxTotalType }] })
  withholdingTaxTotal?: TaxTotalType[];

  @XMLElement({ types: [{ name: 'Item', namespaceUri: NS_CAC, itemType: () => ItemType }] })
  item?: ItemType;

  @XMLElement({ types: [{ name: 'Price', namespaceUri: NS_CAC, itemType: () => PriceType }] })
  price?: PriceType;

  @XMLArray({ nested: false, itemTypes: [{ name: 'SubInvoiceLine', namespaceUri: NS_CBC, itemType: () => InvoiceLineType }] })
  subInvoiceLine?: InvoiceLineType[];

  uniqueId: string = uuidv4();
}

export default InvoiceLineType;
