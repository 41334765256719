export const SORT_REGEX = new RegExp('(?<column>.*)\\s(?<type>asc|desc)$');
export const FILTER_REGEX = new RegExp('(?<key>.*)_(?<value>.*)');
export const MAIL_REGEX = new RegExp(
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
);
export const REGISTER_NUMBER = new RegExp(/^\d{10,11}$/, 'g');
export const URL_REGEX = new RegExp(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/);
export const PASSWORD_REGEX = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@_\-$.,!%*?&])[A-Za-z\d_\-@$.,!%*?&]{8,16}$/);

export const XML_ESCAPE_CHARS = new RegExp(/^(?!.*(<|>|""|'|&)).*/);
export const POSTAL_ZONE = new RegExp(/^((0[1-9])|([1-7][0-9])|(8[0-1]))[0-9]{3}$/);

export const IBAN_REGEX = new RegExp(/^TR\d{7}[A-Z0-9]{17}$/);
