import { PageStyle } from '@/constants/page';
import { Spin } from 'antd';
import React, { CSSProperties, PropsWithChildren } from 'react';
import Loader from 'react-loaders';

export const RootSpinner = ({
  loading,
  children,
  tip = 'Lütfen bekleyiniz...',
  style
}: PropsWithChildren<{ loading: boolean; tip?: string; style?: CSSProperties }>) => {
  return (
    <Spin
      style={style}
      indicator={<Loader active type="ball-clip-rotate-multiple" />}
      spinning={loading}
      tip={tip}
      wrapperClassName={PageStyle.table__body__spinner}
    >
      {children}
    </Spin>
  );
};

export default RootSpinner;
