import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'antd';
import classnames from 'classnames';
import React, { ReactElement } from 'react';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';

export const ProcessDropdownButton = ({ menu, isRow }: { menu: ReactElement; isRow?: boolean }) => {
  return (
    <Dropdown overlayStyle={{ top: '10px' }} placement="bottomRight" overlay={menu} trigger={['click']}>
      <Button color="info" size={'sm'} className={classnames('dropdown-toggle', { 'btn-sm': isRow }, { 'btn-labeled': !isRow })}>
        <FontAwesomeIcon icon={faCog} className={classnames({ 'btn-label': isRow !== true })} />
        {isRow !== true && <span className={'d-none d-xs-none d-sm-inline'}> {intl.get('ISLEMLER')}</span>}
      </Button>
    </Dropdown>
  );
};

export default ProcessDropdownButton;
