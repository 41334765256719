// tslint:disable
// eslint:disable
/**
 * E-SMM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DocumentTemplate } from '../interfaces';
/**
 * DocumentTemplatesApi - axios parameter creator
 * @export
 */
export const DocumentTemplatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Kayıtlı tasarım listenize bu uç ile ulaşabilirsiniz.
         * @summary Tasarımları listeler
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesGet: async (requestParameters: V1DefinitionsDocumenttemplatesGetRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/definitions/documenttemplates`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.query !== undefined) {
                localVarQueryParameter['query'] = requestParameters.query;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tasarımı silmek bu uç kullanılabilir.
         * @summary Tasarımı siler
         * @param {string} id **Tasarım id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesIdDelete: async (requestParameters: V1DefinitionsDocumenttemplatesIdDeleteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DefinitionsDocumenttemplatesIdDelete.');
            }
            const localVarPath = `/v1/definitions/documenttemplates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tasarım dosyasını indirir
         * @param {string} id **Tasarım id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesIdGet: async (requestParameters: V1DefinitionsDocumenttemplatesIdGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DefinitionsDocumenttemplatesIdGet.');
            }
            const localVarPath = `/v1/definitions/documenttemplates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tasarımı örnek bir belgeyle önizlemek için uç kullanılabilir.
         * @summary Tasarımı önizler
         * @param {string} id **Tasarım id**
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesIdPreviewPost: async (requestParameters: V1DefinitionsDocumenttemplatesIdPreviewPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DefinitionsDocumenttemplatesIdPreviewPost.');
            }
            const localVarPath = `/v1/definitions/documenttemplates/{id}/preview`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


                if (requestParameters.file !== undefined) { 
                    localVarFormParams.append('file', requestParameters.file as any);
                }

    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Kayıtlı tasarımlarını düzenlemek için bu uç kullanılabilir.
         * @summary Tasarımı günceller
         * @param {string} id **Tasarım id**
         * @param {string} title Başlık
         * @param {boolean} isDefault Varsayılan
         * @param {any} file XSLT Dosyası
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesIdPut: async (requestParameters: V1DefinitionsDocumenttemplatesIdPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DefinitionsDocumenttemplatesIdPut.');
            }
            // verify required parameter 'title' is not null or undefined
            if (requestParameters.title === null || requestParameters.title === undefined) {
                throw new RequiredError('title','Required parameter title was null or undefined when calling v1DefinitionsDocumenttemplatesIdPut.');
            }
            // verify required parameter 'isDefault' is not null or undefined
            if (requestParameters.isDefault === null || requestParameters.isDefault === undefined) {
                throw new RequiredError('isDefault','Required parameter isDefault was null or undefined when calling v1DefinitionsDocumenttemplatesIdPut.');
            }
            // verify required parameter 'file' is not null or undefined
            if (requestParameters.file === null || requestParameters.file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling v1DefinitionsDocumenttemplatesIdPut.');
            }
            const localVarPath = `/v1/definitions/documenttemplates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


                if (requestParameters.title !== undefined) { 
                    localVarFormParams.append('Title', requestParameters.title as any);
                }

                if (requestParameters.isDefault !== undefined) { 
                    localVarFormParams.append('IsDefault', requestParameters.isDefault as any);
                }

                if (requestParameters.file !== undefined) { 
                    localVarFormParams.append('File', requestParameters.file as any);
                }

    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tasarımı varsayılan olarak atamak için bu uç kullanılabilir.
         * @summary Tasarımı varsayılan ayarlar
         * @param {string} id **Tasarım id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesIdSetdefaultGet: async (requestParameters: V1DefinitionsDocumenttemplatesIdSetdefaultGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DefinitionsDocumenttemplatesIdSetdefaultGet.');
            }
            const localVarPath = `/v1/definitions/documenttemplates/{id}/setdefault`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Yeni tasarım eklemek için bu uç kullanılabilir.
         * @summary Tasarım ekler
         * @param {string} title Başlık
         * @param {boolean} isDefault Varsayılan
         * @param {any} file XSLT Dosyası
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesPost: async (requestParameters: V1DefinitionsDocumenttemplatesPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'title' is not null or undefined
            if (requestParameters.title === null || requestParameters.title === undefined) {
                throw new RequiredError('title','Required parameter title was null or undefined when calling v1DefinitionsDocumenttemplatesPost.');
            }
            // verify required parameter 'isDefault' is not null or undefined
            if (requestParameters.isDefault === null || requestParameters.isDefault === undefined) {
                throw new RequiredError('isDefault','Required parameter isDefault was null or undefined when calling v1DefinitionsDocumenttemplatesPost.');
            }
            // verify required parameter 'file' is not null or undefined
            if (requestParameters.file === null || requestParameters.file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling v1DefinitionsDocumenttemplatesPost.');
            }
            const localVarPath = `/v1/definitions/documenttemplates`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


                if (requestParameters.title !== undefined) { 
                    localVarFormParams.append('Title', requestParameters.title as any);
                }

                if (requestParameters.isDefault !== undefined) { 
                    localVarFormParams.append('IsDefault', requestParameters.isDefault as any);
                }

                if (requestParameters.file !== undefined) { 
                    localVarFormParams.append('File', requestParameters.file as any);
                }

    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentTemplatesApi - functional programming interface
 * @export
 */
export const DocumentTemplatesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Kayıtlı tasarım listenize bu uç ile ulaşabilirsiniz.
         * @summary Tasarımları listeler
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsDocumenttemplatesGet(requestParameters: V1DefinitionsDocumenttemplatesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentTemplate>>> {
            const localVarAxiosArgs = await DocumentTemplatesApiAxiosParamCreator(configuration).v1DefinitionsDocumenttemplatesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Tasarımı silmek bu uç kullanılabilir.
         * @summary Tasarımı siler
         * @param {string} id **Tasarım id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsDocumenttemplatesIdDelete(requestParameters: V1DefinitionsDocumenttemplatesIdDeleteRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DocumentTemplatesApiAxiosParamCreator(configuration).v1DefinitionsDocumenttemplatesIdDelete(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Tasarım dosyasını indirir
         * @param {string} id **Tasarım id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsDocumenttemplatesIdGet(requestParameters: V1DefinitionsDocumenttemplatesIdGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DocumentTemplatesApiAxiosParamCreator(configuration).v1DefinitionsDocumenttemplatesIdGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Tasarımı örnek bir belgeyle önizlemek için uç kullanılabilir.
         * @summary Tasarımı önizler
         * @param {string} id **Tasarım id**
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsDocumenttemplatesIdPreviewPost(requestParameters: V1DefinitionsDocumenttemplatesIdPreviewPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DocumentTemplatesApiAxiosParamCreator(configuration).v1DefinitionsDocumenttemplatesIdPreviewPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Kayıtlı tasarımlarını düzenlemek için bu uç kullanılabilir.
         * @summary Tasarımı günceller
         * @param {string} id **Tasarım id**
         * @param {string} title Başlık
         * @param {boolean} isDefault Varsayılan
         * @param {any} file XSLT Dosyası
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsDocumenttemplatesIdPut(requestParameters: V1DefinitionsDocumenttemplatesIdPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTemplate>> {
            const localVarAxiosArgs = await DocumentTemplatesApiAxiosParamCreator(configuration).v1DefinitionsDocumenttemplatesIdPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Tasarımı varsayılan olarak atamak için bu uç kullanılabilir.
         * @summary Tasarımı varsayılan ayarlar
         * @param {string} id **Tasarım id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsDocumenttemplatesIdSetdefaultGet(requestParameters: V1DefinitionsDocumenttemplatesIdSetdefaultGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DocumentTemplatesApiAxiosParamCreator(configuration).v1DefinitionsDocumenttemplatesIdSetdefaultGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Yeni tasarım eklemek için bu uç kullanılabilir.
         * @summary Tasarım ekler
         * @param {string} title Başlık
         * @param {boolean} isDefault Varsayılan
         * @param {any} file XSLT Dosyası
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsDocumenttemplatesPost(requestParameters: V1DefinitionsDocumenttemplatesPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTemplate>> {
            const localVarAxiosArgs = await DocumentTemplatesApiAxiosParamCreator(configuration).v1DefinitionsDocumenttemplatesPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DocumentTemplatesApi - factory interface
 * @export
 */
export const DocumentTemplatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Kayıtlı tasarım listenize bu uç ile ulaşabilirsiniz.
         * @summary Tasarımları listeler
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesGet(requestParameters: V1DefinitionsDocumenttemplatesGetRequest, options?: any): AxiosPromise<Array<DocumentTemplate>> {
            return DocumentTemplatesApiFp(configuration).v1DefinitionsDocumenttemplatesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Tasarımı silmek bu uç kullanılabilir.
         * @summary Tasarımı siler
         * @param {string} id **Tasarım id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesIdDelete(requestParameters: V1DefinitionsDocumenttemplatesIdDeleteRequest, options?: any): AxiosPromise<void> {
            return DocumentTemplatesApiFp(configuration).v1DefinitionsDocumenttemplatesIdDelete(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tasarım dosyasını indirir
         * @param {string} id **Tasarım id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesIdGet(requestParameters: V1DefinitionsDocumenttemplatesIdGetRequest, options?: any): AxiosPromise<void> {
            return DocumentTemplatesApiFp(configuration).v1DefinitionsDocumenttemplatesIdGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Tasarımı örnek bir belgeyle önizlemek için uç kullanılabilir.
         * @summary Tasarımı önizler
         * @param {string} id **Tasarım id**
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesIdPreviewPost(requestParameters: V1DefinitionsDocumenttemplatesIdPreviewPostRequest, options?: any): AxiosPromise<void> {
            return DocumentTemplatesApiFp(configuration).v1DefinitionsDocumenttemplatesIdPreviewPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Kayıtlı tasarımlarını düzenlemek için bu uç kullanılabilir.
         * @summary Tasarımı günceller
         * @param {string} id **Tasarım id**
         * @param {string} title Başlık
         * @param {boolean} isDefault Varsayılan
         * @param {any} file XSLT Dosyası
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesIdPut(requestParameters: V1DefinitionsDocumenttemplatesIdPutRequest, options?: any): AxiosPromise<DocumentTemplate> {
            return DocumentTemplatesApiFp(configuration).v1DefinitionsDocumenttemplatesIdPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Tasarımı varsayılan olarak atamak için bu uç kullanılabilir.
         * @summary Tasarımı varsayılan ayarlar
         * @param {string} id **Tasarım id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesIdSetdefaultGet(requestParameters: V1DefinitionsDocumenttemplatesIdSetdefaultGetRequest, options?: any): AxiosPromise<void> {
            return DocumentTemplatesApiFp(configuration).v1DefinitionsDocumenttemplatesIdSetdefaultGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Yeni tasarım eklemek için bu uç kullanılabilir.
         * @summary Tasarım ekler
         * @param {string} title Başlık
         * @param {boolean} isDefault Varsayılan
         * @param {any} file XSLT Dosyası
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsDocumenttemplatesPost(requestParameters: V1DefinitionsDocumenttemplatesPostRequest, options?: any): AxiosPromise<DocumentTemplate> {
            return DocumentTemplatesApiFp(configuration).v1DefinitionsDocumenttemplatesPost(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentTemplatesApi - object-oriented interface
 * @export
 * @class DocumentTemplatesApi
 * @extends {BaseAPI}
 */
export class DocumentTemplatesApi extends BaseAPI {
    /**
     * Kayıtlı tasarım listenize bu uç ile ulaşabilirsiniz.
     * @summary Tasarımları listeler
     * @param {string} [query] Aranacak kelime
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplatesApi
     */
    public v1DefinitionsDocumenttemplatesGet(requestParameters: V1DefinitionsDocumenttemplatesGetRequest, options?: any) {
        return DocumentTemplatesApiFp(this.configuration).v1DefinitionsDocumenttemplatesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tasarımı silmek bu uç kullanılabilir.
     * @summary Tasarımı siler
     * @param {string} id **Tasarım id**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplatesApi
     */
    public v1DefinitionsDocumenttemplatesIdDelete(requestParameters: V1DefinitionsDocumenttemplatesIdDeleteRequest, options?: any) {
        return DocumentTemplatesApiFp(this.configuration).v1DefinitionsDocumenttemplatesIdDelete(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tasarım dosyasını indirir
     * @param {string} id **Tasarım id**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplatesApi
     */
    public v1DefinitionsDocumenttemplatesIdGet(requestParameters: V1DefinitionsDocumenttemplatesIdGetRequest, options?: any) {
        return DocumentTemplatesApiFp(this.configuration).v1DefinitionsDocumenttemplatesIdGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tasarımı örnek bir belgeyle önizlemek için uç kullanılabilir.
     * @summary Tasarımı önizler
     * @param {string} id **Tasarım id**
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplatesApi
     */
    public v1DefinitionsDocumenttemplatesIdPreviewPost(requestParameters: V1DefinitionsDocumenttemplatesIdPreviewPostRequest, options?: any) {
        return DocumentTemplatesApiFp(this.configuration).v1DefinitionsDocumenttemplatesIdPreviewPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Kayıtlı tasarımlarını düzenlemek için bu uç kullanılabilir.
     * @summary Tasarımı günceller
     * @param {string} id **Tasarım id**
     * @param {string} title Başlık
     * @param {boolean} isDefault Varsayılan
     * @param {any} file XSLT Dosyası
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplatesApi
     */
    public v1DefinitionsDocumenttemplatesIdPut(requestParameters: V1DefinitionsDocumenttemplatesIdPutRequest, options?: any) {
        return DocumentTemplatesApiFp(this.configuration).v1DefinitionsDocumenttemplatesIdPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tasarımı varsayılan olarak atamak için bu uç kullanılabilir.
     * @summary Tasarımı varsayılan ayarlar
     * @param {string} id **Tasarım id**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplatesApi
     */
    public v1DefinitionsDocumenttemplatesIdSetdefaultGet(requestParameters: V1DefinitionsDocumenttemplatesIdSetdefaultGetRequest, options?: any) {
        return DocumentTemplatesApiFp(this.configuration).v1DefinitionsDocumenttemplatesIdSetdefaultGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Yeni tasarım eklemek için bu uç kullanılabilir.
     * @summary Tasarım ekler
     * @param {string} title Başlık
     * @param {boolean} isDefault Varsayılan
     * @param {any} file XSLT Dosyası
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplatesApi
     */
    public v1DefinitionsDocumenttemplatesPost(requestParameters: V1DefinitionsDocumenttemplatesPostRequest, options?: any) {
        return DocumentTemplatesApiFp(this.configuration).v1DefinitionsDocumenttemplatesPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1DefinitionsDocumenttemplatesGetRequest {
    /**
     * Aranacak kelime
     */
    query?: string;
    }
    export interface V1DefinitionsDocumenttemplatesIdDeleteRequest {
    /**
     * **Tasarım id**
     */
    id: string;
    }
    export interface V1DefinitionsDocumenttemplatesIdGetRequest {
    /**
     * **Tasarım id**
     */
    id: string;
    }
    export interface V1DefinitionsDocumenttemplatesIdPreviewPostRequest {
    /**
     * **Tasarım id**
     */
    id: string;
    /**
     * 
     */
    file?: any;
    }
    export interface V1DefinitionsDocumenttemplatesIdPutRequest {
    /**
     * **Tasarım id**
     */
    id: string;
    /**
     * Başlık
     */
    title: string;
    /**
     * Varsayılan
     */
    isDefault: boolean;
    /**
     * XSLT Dosyası
     */
    file: any;
    }
    export interface V1DefinitionsDocumenttemplatesIdSetdefaultGetRequest {
    /**
     * **Tasarım id**
     */
    id: string;
    }
    export interface V1DefinitionsDocumenttemplatesPostRequest {
    /**
     * Başlık
     */
    title: string;
    /**
     * Varsayılan
     */
    isDefault: boolean;
    /**
     * XSLT Dosyası
     */
    file: any;
    }
