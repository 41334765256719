// tslint:disable
// eslint:disable
/**
 * General API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EArchivePortalIncomingDocument } from '../interfaces';
/**
 * EArchiveIncomingDocumentApi - axios parameter creator
 * @export
 */
export const EArchiveIncomingDocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Tarafınıza düzenlenmiş e-Arşiv faturalarınıza bu uç ile ulaşabilirsiniz.
         * @summary Interaktif vergi dairesine veya GIB E-Arşiv portale düşmüş olan E-Arşiv gelen belgeleri listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [startDate] Başlangıç tarihi
         * @param {string} [endDate] Bitiş tarihi
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1EarchiveincomingdocumentsGet: async (requestParameters: V1EarchiveincomingdocumentsGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1EarchiveincomingdocumentsGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1EarchiveincomingdocumentsGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1EarchiveincomingdocumentsGet.');
            }
            const localVarPath = `/v1/earchiveincomingdocuments`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }
            if (requestParameters.startDate !== undefined) {
                localVarQueryParameter['startDate'] = requestParameters.startDate;
            }
            if (requestParameters.endDate !== undefined) {
                localVarQueryParameter['endDate'] = requestParameters.endDate;
            }
            if (requestParameters.query !== undefined) {
                localVarQueryParameter['query'] = requestParameters.query;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EArchiveIncomingDocumentApi - functional programming interface
 * @export
 */
export const EArchiveIncomingDocumentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Tarafınıza düzenlenmiş e-Arşiv faturalarınıza bu uç ile ulaşabilirsiniz.
         * @summary Interaktif vergi dairesine veya GIB E-Arşiv portale düşmüş olan E-Arşiv gelen belgeleri listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [startDate] Başlangıç tarihi
         * @param {string} [endDate] Bitiş tarihi
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1EarchiveincomingdocumentsGet(requestParameters: V1EarchiveincomingdocumentsGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EArchivePortalIncomingDocument>>> {
            const localVarAxiosArgs = await EArchiveIncomingDocumentApiAxiosParamCreator(configuration).v1EarchiveincomingdocumentsGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * EArchiveIncomingDocumentApi - factory interface
 * @export
 */
export const EArchiveIncomingDocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Tarafınıza düzenlenmiş e-Arşiv faturalarınıza bu uç ile ulaşabilirsiniz.
         * @summary Interaktif vergi dairesine veya GIB E-Arşiv portale düşmüş olan E-Arşiv gelen belgeleri listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [startDate] Başlangıç tarihi
         * @param {string} [endDate] Bitiş tarihi
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1EarchiveincomingdocumentsGet(requestParameters: V1EarchiveincomingdocumentsGetRequest, options?: any): AxiosPromise<Array<EArchivePortalIncomingDocument>> {
            return EArchiveIncomingDocumentApiFp(configuration).v1EarchiveincomingdocumentsGet(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EArchiveIncomingDocumentApi - object-oriented interface
 * @export
 * @class EArchiveIncomingDocumentApi
 * @extends {BaseAPI}
 */
export class EArchiveIncomingDocumentApi extends BaseAPI {
    /**
     * Tarafınıza düzenlenmiş e-Arşiv faturalarınıza bu uç ile ulaşabilirsiniz.
     * @summary Interaktif vergi dairesine veya GIB E-Arşiv portale düşmüş olan E-Arşiv gelen belgeleri listeler
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {string} [startDate] Başlangıç tarihi
     * @param {string} [endDate] Bitiş tarihi
     * @param {string} [query] Aranacak kelime
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EArchiveIncomingDocumentApi
     */
    public v1EarchiveincomingdocumentsGet(requestParameters: V1EarchiveincomingdocumentsGetRequest, options?: any) {
        return EArchiveIncomingDocumentApiFp(this.configuration).v1EarchiveincomingdocumentsGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1EarchiveincomingdocumentsGetRequest {
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    /**
     * Başlangıç tarihi
     */
    startDate?: string;
    /**
     * Bitiş tarihi
     */
    endDate?: string;
    /**
     * Aranacak kelime
     */
    query?: string;
    }
