/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { AmountType, PeriodType } from '.';
import { NS_CAC, NS_CBC } from '../constants';

/*
  27.04.2021 : Fully completed
*/
export class PaymentTermsType {
  @XMLElement({ types: [{ name: 'Note', namespaceUri: NS_CBC, itemType: () => String }] })
  note?: string;

  @XMLElement({ types: [{ name: 'PenaltySurchargePercent', namespaceUri: NS_CBC, itemType: () => Number }] })
  penaltySurchargePercent?: number;

  @XMLElement({ types: [{ name: 'Amount', namespaceUri: NS_CBC, itemType: () => AmountType }] })
  amount?: AmountType;

  @XMLElement({ types: [{ name: 'PenaltyAmount', namespaceUri: NS_CBC, itemType: () => AmountType }] })
  penaltyAmount?: AmountType;

  @XMLElement({ types: [{ name: 'PaymentDueDate', namespaceUri: NS_CBC, itemType: () => String }] })
  paymentDueDate?: string;

  @XMLElement({ types: [{ name: 'SettlementPeriod', namespaceUri: NS_CAC, itemType: () => PeriodType }] })
  settlementPeriod?: PeriodType;
}

export default PaymentTermsType;
