import { faFileInvoice } from '@fortawesome/pro-light-svg-icons';
import { faFileCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import React, { memo } from 'react';
import intl from 'react-intl-universal';

export const IsTransferred = memo(({ isTransferred }: { isTransferred: boolean }) => {
  return (
    <Tooltip trigger="hover" placement="bottom" title={isTransferred ? intl.get('ERP_YE_KAYIT_EDILDI') : intl.get('ERP_YE_KAYIT_EDILMEDI')}>
      <FontAwesomeIcon style={{ cursor: 'pointer', fontSize: '20px' }} icon={isTransferred ? faFileCheck : faFileInvoice} />
    </Tooltip>
  );
});

export default IsTransferred;
