/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLAttribute, XMLText } from '@/xmldom-decorators';

/*
  27.04.2021 : Fully completed
*/
export class AmountType {
  @XMLAttribute()
  currencyID?: string;

  @XMLText({ type: Number })
  value: number = 0;
}

export default AmountType;
