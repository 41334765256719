import { DocumentTemplateCustomizationSettingDto } from '@/apis/einvoice/interfaces';
import { Delete, New } from '@/components/Buttons';
import SortableTable from '@/components/SortableTable';
import { PageConst } from '@/constants/page';
import { Col, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { repositionItem } from 'redux-toolbelt-immutable-helpers';
import { v4 as uuidv4 } from 'uuid';
import './style.scss';

type NoteModel = {
  notes: string[];
};

const BankInformation = ({
  documentTemplateCustomizationSetting,
  onChange
}: {
  documentTemplateCustomizationSetting?: DocumentTemplateCustomizationSettingDto;
  onChange: (data: string[]) => void;
}) => {
  const [bankInfo, setBankInfo] = useState<NoteModel>({
    notes: documentTemplateCustomizationSetting?.notes || []
  });

  const dataSource = bankInfo.notes.map((data, item) => {
    return { note: data, index: uuidv4() };
  });

  useEffect(() => {
    onChange(bankInfo.notes);
  }, [bankInfo]);

  const onDataChange = (newValue, rowIndex) => {
    const newData = { ...bankInfo };

    newData.notes[rowIndex] = newValue;

    setBankInfo(newData);
  };

  const onAddRow = () => {
    const newDbRecords = { ...bankInfo };

    newDbRecords.notes.push('');
    setBankInfo(newDbRecords);
  };

  const onDeleteRow = (index) => {
    const newRecords = { ...bankInfo };

    newRecords.notes.splice(index, 1);

    setBankInfo(newRecords);
  };

  const makeColumns = () => {
    const columnList = [
      {
        dataIndex: 'note',
        title: 'Not',
        render: (value, rowData, index) => <Input defaultValue={value} onBlur={(e) => e.target.value !== value && onDataChange(e.target.value, index)} />
      },
      {
        dataIndex: 'remove',
        align: 'center',
        title: 'Sil',
        width: 50,
        render: (value, rowData, index) => <Delete onlyIcon onClick={() => onDeleteRow(index)} />
      }
    ];

    return columnList;
  };

  const columns = makeColumns();

  return (
    <Row gutter={PageConst.RowGutter}>
      <Col sm={24}>
        <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '7.5px' }}>
          <New onClick={onAddRow} title="Yeni Satır" />
        </div>

        <SortableTable
          data={dataSource}
          columns={columns}
          onChangeDataIndex={(oldIndex, newIndex) => {
            const newData = { ...bankInfo };
            newData.notes = repositionItem(newData.notes, oldIndex, newIndex);
            setBankInfo(newData);
          }}
        />
      </Col>
    </Row>
  );
};

export default BankInformation;
