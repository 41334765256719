import { ApiModal, ApiModalValue } from '@/interfaces';
import { AnyType } from '@/type';
import { AxiosPromise } from 'axios';
import { useCallback, useRef, useState } from 'react';
/**
 * Belge ye ait bilgilerin apiden alınır.
 * @param asyncFunction Api çağrısı yapacak olan async fonksiyon
 */
export const useApiModal = <TResponse, TParameter, TAdditionalProps = null>(asyncFunction) => {
  /**
   * Henüz asyn operasyon devam ederken, sonuçlanmasını beklemeden modalı kapatması durumlarını handle etmek için kullanılıyor
   */
  const isClosed = useRef(false);

  const [value, setValue] = useState<ApiModalValue<TResponse, TAdditionalProps>>({ data: [] as AnyType, loading: false, visible: false });

  /**
   * Api isteğini başlatmak için bu metod kullanılır
   * @param additionalProps Modal içerisinde kullanılmak için ekstra veri
   */
  const get = (params: TParameter, additionalProps?: TAdditionalProps) => {
    isClosed.current = false;
    setValue({ loading: true, visible: true, data: undefined });
    return (asyncFunction(params) as AxiosPromise<TResponse>)
      .then((response: AnyType) => {
        if (!isClosed.current) {
          if (response.isAxiosError) {
            setValue({ loading: false, data: undefined, error: response, visible: true, additionalProps });
          } else {
            setValue({ loading: false, data: response.data, visible: true, additionalProps });
          }
        }
      })
      .catch((error) => {
        if (!isClosed.current) {
          setValue({ loading: false, data: undefined, error: error, visible: true });
        }
      });
  };

  const closeModal = useCallback(() => {
    isClosed.current = true;
    setValue({ loading: false, visible: false, data: undefined });
  }, []);

  const props: ApiModal<TResponse, TParameter, TAdditionalProps> = { ...value, closeModal };

  return { props, get };
};

export default useApiModal;
