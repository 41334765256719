/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLArray, XMLElement } from '@/xmldom-decorators';
import { AddressType, IDType, LocationType, PartyType, PeriodType, QuantityType } from '.';
import { NS_CAC, NS_CBC } from '../constants';
import DeliveryTermsType from './DeliveryTermsType';
import { DespatchType } from './DespatchType';
import ShipmentType from './ShipmentType';

/*
  
*/
export class DeliveryType {
  @XMLElement({ types: [{ name: 'ID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  id?: IDType;

  @XMLElement({ types: [{ name: 'Quantity', namespaceUri: NS_CBC, itemType: () => QuantityType }] })
  quantity?: QuantityType;

  @XMLElement({ types: [{ name: 'ActualDeliveryDate', namespaceUri: NS_CBC, itemType: () => String }] })
  actualDeliveryDate?: string;

  @XMLElement({ types: [{ name: 'ActualDeliveryTime', namespaceUri: NS_CBC, itemType: () => String }] })
  actualDeliveryTime?: string;

  @XMLElement({ types: [{ name: 'LatestDeliveryDate', namespaceUri: NS_CBC, itemType: () => String }] })
  latestDeliveryDate?: string;

  @XMLElement({ types: [{ name: 'LatestDeliveryTime', namespaceUri: NS_CBC, itemType: () => String }] })
  latestDeliveryTime?: string;

  @XMLElement({ types: [{ name: 'TrackingID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  trackingID?: IDType;

  @XMLElement({ types: [{ name: 'DeliveryAddress', namespaceUri: NS_CAC, itemType: () => AddressType }] })
  deliveryAddress?: AddressType;

  @XMLElement({ types: [{ name: 'AlternativeDeliveryLocation', namespaceUri: NS_CAC, itemType: () => LocationType }] })
  alternativeDeliveryLocation?: LocationType;

  @XMLElement({ types: [{ name: 'EstimatedDeliveryPeriod', namespaceUri: NS_CAC, itemType: () => PeriodType }] })
  estimatedDeliveryPeriod?: PeriodType;

  @XMLElement({ types: [{ name: 'CarrierParty', namespaceUri: NS_CAC, itemType: () => PartyType }] })
  carrierParty?: PartyType;

  @XMLElement({ types: [{ name: 'DeliveryParty', namespaceUri: NS_CAC, itemType: () => PartyType }] })
  deliveryParty?: PartyType;

  @XMLElement({ types: [{ name: 'Despatch', namespaceUri: NS_CAC, itemType: () => DespatchType }] })
  despatch?: DespatchType;

  @XMLArray({ nested: false, itemTypes: [{ name: 'DeliveryTerms', namespaceUri: NS_CAC, itemType: () => DeliveryTermsType }] })
  deliveryTerms?: DeliveryTermsType[];

  @XMLElement({ types: [{ name: 'Shipment', namespaceUri: NS_CAC, itemType: () => ShipmentType }] })
  shipment?: ShipmentType;
}

export default AddressType;
