import { RootSpinner } from '@/components';
import { Close } from '@/components/Buttons';
import { ErrorViewer } from '@/components/TableBody';
import { PageConst } from '@/constants/page';
import { useApi } from '@/hooks';
import { faInfoCircle, faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { RcFile } from 'antd/lib/upload';
import { AxiosPromise } from 'axios';
import React, { useState } from 'react';
import { Button, UncontrolledAlert } from 'reactstrap';
import SingleUploader from '../SingleUploader';
import PreviewModal, { PreviewModalProps } from './PreviewModal';
export interface DocumenttemplatesPreviewProps {
  visible: boolean;
  documentId: string;
  jsonContent: string;
}
const DocumenttemplatesPreview = ({
  closeModal,
  postXlstsPreview,
  modalState: { documentId, visible, jsonContent }
}: {
  modalState: DocumenttemplatesPreviewProps;
  closeModal: () => void;
  postXlstsPreview: (requestParameter: any) => AxiosPromise<void>;
}) => {
  const [selectedFile, setSelectedFile] = useState<RcFile>();
  const [previewModal, setPreviewModal] = useState<PreviewModalProps>({
    visible: false,
    data: ''
  });
  const { call, loading, error, clear } = useApi<string, any>({
    asyncFunction: postXlstsPreview,
    successCallback: (res, req) => {
      req && setPreviewModal({ data: res.data, visible: true });
    }
  });

  return (
    <Modal
      centered
      title={
        <span>
          <FontAwesomeIcon icon={faSearch} /> {'Xlsts Önizleme'}
        </span>
      }
      onCancel={() => {
        closeModal();
        clear();
      }}
      width={PageConst.LargeModalWidth}
      destroyOnClose
      visible={visible}
      footer={
        <Row gutter={PageConst.RowGutter} style={{ float: 'right' }}>
          <Col>
            <Button
              size={'sm'}
              onClick={() =>
                call({
                  id: documentId,
                  ublXmlFile: selectedFile,
                  jsonContent: new File([new Blob([jsonContent], { type: 'application/json' })], `content.json`, {
                    type: 'application/json'
                  })
                })
              }
              color={'success'}
              className="btn-labeled"
            >
              <FontAwesomeIcon icon={faSearch} className={'btn-label'} />
              <span>{'Görüntüle'}</span>
            </Button>
          </Col>
          <Col>
            <Close
              onClick={() => {
                closeModal();
                clear();
              }}
            />
          </Col>
        </Row>
      }
    >
      <PreviewModal modalState={previewModal} closeModal={() => setPreviewModal({ data: '', visible: false })} />
      <RootSpinner loading={loading} tip={loading ? 'Dosya hazırlanıyor, lütfen bekleyiniz' : undefined}>
        {/* {!loading && !error && ( */}
        <>
          <Row>
            <Col md={24}>
              <UncontrolledAlert color="success" fade={false}>
                <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                Özel bir <b>.xml</b> ile önizleme almak isterniz aşğıdaki alandan <b>.xml</b> dosyası seçebilirsiniz.
              </UncontrolledAlert>
            </Col>
          </Row>
          <Row>
            <SingleUploader onLoad={(file) => setSelectedFile(file)} readType={'asBinaryString'} extensions={'.xml'} />
          </Row>
          <Row style={{ float: 'right' }}></Row>
        </>
        {/* )} */}
        {error && <ErrorViewer error={error} />}
      </RootSpinner>
    </Modal>
  );
};

export default DocumenttemplatesPreview;
