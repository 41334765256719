import { DateFormat, PageStyle } from '@/constants/page';
import { useApi } from '@/hooks';
import { KeyValue } from '@/interfaces';
import { AnyType } from '@/type';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle, faSave } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { message } from 'antd';
import { AxiosPromise } from 'axios';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Card, CardBody, CardHeader, UncontrolledAlert } from 'reactstrap';
import { DragAndDrop, DragAndDropState } from '..';

export const FileTitleDescription = () => (
  <>
    <UncontrolledAlert color="success" fade={false}>
      <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
      İndirilen dosyaların oluşturulacak olan isimlerini sürükle bırak yaparak belirleyebilirsiniz.
    </UncontrolledAlert>

    <UncontrolledAlert color="info" fade={false}>
      <FontAwesomeIcon icon={faSave} className="mr-2" />
      Sürükle bırak yaptıkça seçtiğiniz kolonlar otomatik olarak kayıt edilecektir.
    </UncontrolledAlert>
  </>
);
const exampleData = {
  DocumentNumber: `NES${new Date().getFullYear()}000000092`,
  IssueDate: moment(new Date()).format(DateFormat.dmy),
  FullName: 'NES BİLGİ A.Ş.',
  PartyIdentification: '6310694807',
  Uuid: '7e9c02fa-0747-bc78-76a7-2f8643e97aae'
};

const availableKeys: KeyValue[] = [
  { key: 'DocumentNumber', value: 'Belge Numarası' },
  { key: 'IssueDate', value: 'Belge Tarihi' },
  { key: 'FullName', value: 'Ünvan' },
  { key: 'PartyIdentification', value: 'Vergi / T.C. No' },
  { key: 'Uuid', value: 'Ettn' }
];

export const FileTitleSelector = ({
  icon,
  title,
  documentType,
  extension,
  put,
  getFileTitleKeys
}: {
  icon: IconProp;
  title: string;
  documentType;
  extension: string;
  getFileTitleKeys: (requestParameters: AnyType) => AxiosPromise<Array<string>>;
  put: (requestParameters: AnyType) => AxiosPromise<void>;
}) => {
  const [data, setData] = useState<DragAndDropState>({
    items: availableKeys,
    selected: []
  });

  const { loading, call, data: selectedValue } = useApi<Array<string>, AnyType>({
    asyncFunction: getFileTitleKeys
  });

  useEffect(() => {
    if (selectedValue) {
      const newSelected = selectedValue.map((key) => {
        const item = availableKeys.find((x) => x.key === key);
        if (item) {
          return item;
        } else {
          return availableKeys[0];
        }
      }) as KeyValue[];
      const difference = availableKeys.filter((x) => !newSelected.includes(x));
      setData({
        selected: newSelected,
        items: difference
      });
    }
  }, [selectedValue]);

  const { loading: saveLoading, call: saveCall } = useApi<void, AnyType>({
    asyncFunction: put,
    successCallback: () => {
      message.success(intl.get('TERCIHLER_KAYDEDILDI'));
    }
  });

  useEffect(() => {
    call({ documentType, extension });
  }, []);

  const example =
    data.selected.length === 0 ? (
      ''
    ) : (
      <div>
        <br />
        <strong>Örnek Dosya Adı: </strong>
        {`${_.trim(
          data.selected.reduce((fileName, item) => {
            return fileName + exampleData[item.key] + '-';
          }, ''),
          '-'
        )}.${extension}`}
      </div>
    );

  const onChange = (event: DragAndDropState) => {
    setData(event);
    saveCall({ fileExportTitleSettingDto: { fileExportTitleDocumentType: documentType, extension, titles: event.selected.map((x) => x.key) } });
  };

  return (
    <Card>
      <CardHeader className={PageStyle.card__header}>
        <FontAwesomeIcon icon={icon} color="#0ab1fc" className="mr-2" />
        <span className={PageStyle.card__header__title}>{title}</span>
      </CardHeader>
      <CardBody>
        <DragAndDrop
          isLoading={loading || saveLoading}
          items={data.items}
          selected={data.selected}
          itemsTitle={intl.get('SECENEKLER')}
          selectedTitle={intl.get('SECILENLER')}
          onChangeSelectedColumns={onChange}
          minSelectedCount={1}
        />
        {example}
      </CardBody>
    </Card>
  );
};

export default FileTitleSelector;
