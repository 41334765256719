import { V1DefinitionsMailingSmsSettingsPutRequest } from '@/apis/earchive';
import { SmsSetting } from '@/apis/earchive/interfaces';
import { InfoToastify } from '@/components';
import { Save } from '@/components/Buttons';
import { ErrorViewer } from '@/components/TableBody';
import { DateFormat, PageStyle } from '@/constants/page';
import { useApi } from '@/hooks';
import { faSms } from '@fortawesome/pro-light-svg-icons';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Col, Row, Skeleton } from 'antd';
import { AxiosPromise } from 'axios';
import { Formik, FormikProps } from 'formik';
import { Checkbox, Form, FormItem, Input } from 'formik-antd';
import moment from 'moment';
import React, { memo, useEffect, useRef } from 'react';
import intl from 'react-intl-universal';
import { Card, CardBody, CardHeader } from 'reactstrap';
const { TextArea } = Input;
export const SmsSettings = memo(
  ({ getSms, put }: { getSms: () => AxiosPromise<SmsSetting>; put: (requestParameters: V1DefinitionsMailingSmsSettingsPutRequest) => AxiosPromise<void> }) => {
    let form = (useRef(null) as unknown) as FormikProps<V1DefinitionsMailingSmsSettingsPutRequest>;

    /**
     * smsleri getirmek için kullanılan fonksiypn
     */
    const getSmsRequest = useApi<SmsSetting>({
      asyncFunction: getSms
    });

    /**
     * Update işlemi için kullanılan fonksiyon
     */
    const putRequest = useApi<void, V1DefinitionsMailingSmsSettingsPutRequest>({
      asyncFunction: put,
      successCallback: (_, request) => {
        if (request) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          getSmsRequest.setData({ ...getSmsRequest.data!, isAuto: request.smsSettingDto.isAuto! });
          InfoToastify(intl.get('SMS_AYARLARINIZ_BASARIYLA_GUNCELLENDI'));
        }
      }
    });

    useEffect(() => {
      getSmsRequest.call();
    }, []);

    return (
      <>
        <Card>
          <CardHeader className={PageStyle.card__header}>
            <div className="justify-content-center mr-auto utils_custom_title d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block">
              {<FontAwesomeIcon icon={faSms} color="#0ab1fc" style={{ marginRight: '10px' }} />}
              <b>{intl.get('SMS_AYARLARI')}</b>
            </div>
            {getSmsRequest.data && getSmsRequest.data.isNesApprove === true ? (
              <Badge count={intl.get('NES_BILGI_ONAYLADI')} style={{ backgroundColor: '#52c41a', marginRight: 10 }} />
            ) : (
              <Badge count={intl.get('NES_BILGI_ONAYI_BEKLENIYOR')} style={{ backgroundColor: '#ffa726', marginRight: 10 }} />
            )}
            <Save onClick={() => form.isValid && form.handleSubmit()} />
          </CardHeader>
          <CardBody className={PageStyle.virtualized__card__body}>
            <Skeleton active loading={getSmsRequest.loading || putRequest.loading}>
              <Formik<SmsSetting>
                validateOnBlur={false}
                innerRef={(instance) => (form = instance)}
                onSubmit={(values) => {
                  if(form.isValid)
                  putRequest.call({ smsSettingDto: values });
                }}
                enableReinitialize={true}
                initialValues={
                  getSmsRequest.data && getSmsRequest.data.messageBody
                    ? {
                        ...getSmsRequest.data,
                        messageBody: getSmsRequest.data.messageBody
                          .replace('{{IssueDate}}', moment(new Date()).format(DateFormat.dmy))
                          .replace('{{Total}}', '1.000')
                          .replace('{{CurrencyCode}}', '₺')
                          .replace('{{LinkCode}}', Math.random().toString(36).substring(7))
                      }
                    : {
                        allDocumentSendSms: false,
                        isAuto: false,
                        isNesApprove: false,
                        messageBody: '',
                        senderName: ''
                      }
                }
              >
                <Form layout="vertical">
                  <Row>
                    <Col sm={24} xs={24}>
                      <FormItem name="senderName" label={intl.get('SMS_BASLIGI')}>
                        <Input disabled={true} prefix={<FontAwesomeIcon icon={faUser} />} name="senderName" placeholder={'NES BILGI'} tabIndex={0} />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={24}>
                      <FormItem name="isAuto">
                        <Checkbox name="isAuto" tabIndex={0}>
                          {intl.get('OTOMATIK_SMS')}
                        </Checkbox>
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={24}>
                      <FormItem name="messageBody" label={intl.get('ORNEK_SMS')}>
                        <TextArea
                          disabled={true}
                          name="messageBody"
                          placeholder="01.01.2016 tarihindeki, 1234,56 TL alisverisinizin e-arsiv faturasi hazirlanmistir. http://e-fatura.link/"
                          tabIndex={0}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                </Form>
              </Formik>
            </Skeleton>
          </CardBody>
        </Card>
        {getSmsRequest.error || (putRequest.error && <ErrorViewer error={getSmsRequest.error || putRequest.error} />)}
      </>
    );
  }
);

export default SmsSettings;
