import { DocumentInfo, ErrorWrapper } from '@/components';
import { Close } from '@/components/Buttons';
import { TableDefaultLoading } from '@/components/TableBody';
import { DocumentModal } from '@/interfaces';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Tooltip } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import _ from 'lodash';
import React, { memo } from 'react';
import intl from 'react-intl-universal';
import { EnvelopeQueryResponse } from '.';

export const EnvelopeQueryModal = memo(
  ({ closeModal, visible, documentNumber, uuid, data, loading, error }: DocumentModal<Array<EnvelopeQueryResponse>, null>) => {
    return (
      <Modal
        key={`envelope-query-modal`}
        centered
        title={
          <span>
            <FontAwesomeIcon icon={faQuestionCircle} /> {intl.get('ZARF_SORGU_SONUCU')}
          </span>
        }
        onCancel={closeModal}
        width={1024}
        visible={visible}
        footer={[<Close key="btn-modal-close" onClick={closeModal} />]}
      >
        <DocumentInfo documentNumber={documentNumber || ''} uuid={uuid || ''} />
        <Table<EnvelopeQueryResponse>
          showHeader={data !== undefined}
          components={ErrorWrapper({ error })}
          style={{ marginTop: '10px' }}
          loading={TableDefaultLoading(loading)}
          scroll={{ x: true, y: 512 }}
          bordered
          pagination={false}
          dataSource={data}
          rowKey={(record) => record.instanceIdentifier}
        >
          <Table.Column key="type" title={intl.get('ZARF')} dataIndex="type" />
          <Table.Column key="instanceIdentifier" title={intl.get('ZARF_NO')} dataIndex="instanceIdentifier" />
          <Table.Column key="code" title={intl.get('DURUM_KODU')} dataIndex="code" width={100} />
          <Table.Column<EnvelopeQueryResponse>
            title={intl.get('DURUM_ACIKLAMASI')}
            render={(record) =>
              record.message.length >= 20 ? (
                <Tooltip trigger={'hover'} placement="bottom" title={record.message}>
                  <div>{_.truncate(record.message, { length: 20 })}</div>
                </Tooltip>
              ) : (
                record.message
              )
            }
          />
        </Table>
      </Modal>
    );
  },
  (prev, next) => _.isEqual(prev.data, next.data) && prev.visible === next.visible && prev.loading === next.loading
);

export default EnvelopeQueryModal;
