import { ErrorViewer } from '@/components/TableBody';
import React from 'react';
export const ErrorWrapper = ({ error }: { error }) => {
  return {
    table: error
      ? () => (
          <table>
            <tbody className="ant-table-tbody">
              <tr>
                <td>
                  <div className="table__body__center">{<ErrorViewer error={error} />}</div>
                </td>
              </tr>
            </tbody>
          </table>
        )
      : undefined
  };
};

export default ErrorWrapper;
