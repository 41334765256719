import { ButtonProps } from '@/interfaces';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React from 'react';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';

export const Search = ({ disabled, onClick, size = 'sm', onlyIcon, title }: ButtonProps) => {
  return (
    <Button disabled={disabled} size={size} key="btn-search" onClick={onClick} color={'primary'} className="btn-labeled">
      <FontAwesomeIcon icon={faSearch} className={classnames({ 'btn-label': !onlyIcon })} />
      {!onlyIcon && <span>{title ? title : intl.get('ARA')}</span>}
    </Button>
  );
};

export default Search;
