import { PageConst } from '@/constants/page';
import { OnClickProps } from '@/interfaces';
import { faHistory, faPlus, faShare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Result } from 'antd';
import MenuItem from 'antd/lib/menu/MenuItem';
import React from 'react';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { InfoToastify } from '../Toastify';

export const ShareDocumentLink = (props: OnClickProps) => {
  return (
    <MenuItem {...props}>
      <FontAwesomeIcon className={'mr-2'} icon={faShare} />
      Link İle Paylaş
    </MenuItem>
  );
};

export default ShareDocumentLink;
