import { AnyType } from '@/type';
import classNames from 'classnames';
import React from 'react';
import styles from './style.module.scss';

const Loader = ({ spinning = true, fullScreen }: AnyType) => (
  <div
    className={classNames(styles.loader, {
      [styles.hidden]: !spinning,
      [styles.fullScreen]: fullScreen
    })}
  />
);

export default Loader;
