import { EmailsModalProps, RootSpinner, SendMailModal } from '@/components';
import { Close, PdfDownload, Print, SendMail } from '@/components/Buttons';
import { ErrorViewer } from '@/components/TableBody';
import { useApi, useFileDownload } from '@/hooks';
import { DocumentModal } from '@/interfaces';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import _ from 'lodash';
import React, { memo, useEffect, useState } from 'react';

type SetPrintedParameter = { requestBody: string[]; operation: 'Printed' | 'Read' };

export const DocumentPreviewModal = memo(
  ({
    closeModal,
    visible,
    documentNumber,
    uuid,
    data,
    loading,
    error,
    sendMailApi,
    pdfDownloadApi,
    setPrintedApi,
    setPrintedCallback,
    setReadedCallback,
    setReadApi
  }: DocumentModal<string> & {
    pdfDownloadApi?;
    setPrintedApi;
    setPrintedCallback?: (params: { uuid: string }) => void;
    setReadedCallback?: (params: { uuid: string }) => void;
    sendMailApi;
    setReadApi?;
  }) => {
    const pdf = useFileDownload<{ uuid: string }>(pdfDownloadApi);
    const setPrinted = useApi<boolean, SetPrintedParameter>({
      asyncFunction: setPrintedApi,
      successCallback: (_, request) => request && setPrintedCallback && setPrintedCallback({ uuid: request.requestBody[0] })
    });

    const setReaded = useApi<boolean, SetPrintedParameter>({
      asyncFunction: setReadApi,
      successCallback: (_, request) => request && setReadedCallback && setReadedCallback({ uuid: request.requestBody[0] })
    });

    useEffect(() => {
      if (visible && !error) {
        setReadApi && setReaded.call({ requestBody: [uuid || ''], operation: 'Read' });
      }
    }, [visible]);

    const [emailsModalState, setEmailsModalState] = useState<EmailsModalProps>({
      visible: false,
      uuids: [],
      documentNumber: ''
    });
    const bodyLoading = pdf.loading;
    return (
      <>
        <SendMailModal closeModal={() => setEmailsModalState({ visible: false, uuids: [] })} modalState={emailsModalState} sendMailFn={sendMailApi} />
        <Modal
          centered
          title={
            <span>
              <FontAwesomeIcon icon={faSearch} /> {documentNumber} - {uuid}
            </span>
          }
          onCancel={closeModal}
          width={900}
          visible={visible}
          footer={[
            <div style={{ textAlign: 'left' }} key="preview-modal">
              {!loading && !error && (
                <>
                  <PdfDownload disabled={bodyLoading} onClick={() => pdf.download({ uuid: uuid || '' })} />
                  <SendMail
                    disabled={bodyLoading}
                    onClick={() => setEmailsModalState({ uuids: [uuid || ''], visible: true, documentNumber: documentNumber })}
                  />
                  <Print
                    disabled={bodyLoading}
                    onClick={() => {
                      setPrinted.call({ requestBody: [uuid || ''], operation: 'Printed' });
                      try {
                        window.frames['documentPreview'].contentWindow.print();
                      } catch (e) {
                        window.frames['documentPreview'].document.print();
                      }
                      // return false
                    }}
                  />
                </>
              )}

              <div className="float-right">
                <Close onClick={closeModal} />
              </div>
            </div>
          ]}
        >
          <RootSpinner loading={bodyLoading}>
            {data && (
              <iframe
                title={uuid}
                id="documentPreview"
                src="#"
                srcDoc={data}
                width="900"
                height="100%"
                frameBorder="0"
                style={{ width: '100%', minHeight: 600, paddingLeft: 10 }}
              />
            )}
            {loading && <Skeleton active />}
            {error && <ErrorViewer error={error} />}
          </RootSpinner>
        </Modal>
      </>
    );
  },
  (prev, next) => _.isEqual(prev.data, next.data) && prev.visible === next.visible && prev.loading === next.loading
);
