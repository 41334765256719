import { MailHistory, MailHistoryPaginationResult } from '@/apis/esmm/interfaces';
import { DocumentInfo, ErrorWrapper } from '@/components';
import { Close } from '@/components/Buttons';
import LocalPagination from '@/components/Pagination/LocalPagination';
import { TableDefaultLoading } from '@/components/TableBody';
import { DocumentModal, Pagination } from '@/interfaces';
import { faHistory } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Modal, Table } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { memo, useState } from 'react';
import intl from 'react-intl-universal';

export interface MailHistoriesModalAdditionalProps {
  refetch: (uuid?: string, documentNumber?: string, pagination?: Pagination, additionalProps?: MailHistoriesModalAdditionalProps) => void;
}

export const MailHistoriesModal = memo(
  ({
    closeModal,
    visible,
    documentNumber,
    uuid,
    data,
    loading,
    error,
    additionalProps
  }: DocumentModal<MailHistoryPaginationResult, MailHistoriesModalAdditionalProps>) => {
    function color(type?: boolean) {
      switch (type) {
        case true:
          return 'success';
        case false:
          return 'error';
        default:
          return 'default';
      }
    }

    const [pagination, setPagination] = useState({ page: 1, pageSize: 10, sort: 'CreatedAt desc' });

    return (
      <Modal
        centered
        title={
          <span>
            <FontAwesomeIcon icon={faHistory} /> {intl.get('MAIL_DETAYLARI')}
          </span>
        }
        onCancel={closeModal}
        width={1024}
        visible={visible}
        footer={[<Close key="close-button" onClick={closeModal} />]}
      >
        <DocumentInfo documentNumber={documentNumber || ''} uuid={uuid || ''} />
        <Table
          showHeader={data !== undefined}
          components={ErrorWrapper({ error })}
          style={{ marginTop: '10px' }}
          scroll={{ x: true, y: 512 }}
          loading={TableDefaultLoading(loading)}
          bordered
          pagination={false}
          dataSource={(data && data.data) || []}
          rowKey={(record) => record.id}
        >
          <Table.Column
            key="sendedDate"
            title={intl.get('GONDERME_TARIHI')}
            dataIndex="sendedDate"
            render={(text, record: MailHistory) => moment(record.sendedDate).format('YYYY-MM-DD, mm:mm:ss')}
          />
          <Table.Column key="receiverMail" title={intl.get('ALICI_MAIL')} dataIndex="receiverMail" />
          <Table.Column
            key="isRead"
            title={`${intl.get('OKUNMA')}?`}
            dataIndex="isRead"
            render={(text, record: MailHistory) =>
              record.isRead === true ? (
                <div>
                  <Badge status={color(record.isRead)} text={intl.get('MAIL_OKUNDU')} />
                  {' | ' + moment(record.sendedDate).format('YYYY-MM-DD, mm:mm:ss')}
                </div>
              ) : (
                <Badge status={color(record.isRead)} text={intl.get('MAIL_OKUNMADI')} />
              )
            }
          />
          <Table.Column
            key="isDownload"
            title={`${intl.get('INDIRILME')}?`}
            dataIndex="isDownload"
            render={(text, record: MailHistory) =>
              record.isDownload === true ? (
                <Badge status={color(record.isDownload)} text={intl.get('EVET')} />
              ) : (
                <Badge status={color(record.isDownload)} text={intl.get('HAYIR')} />
              )
            }
          />
          <Table.Column
            key="isView"
            title={`${intl.get('GORUNTULENME')}?`}
            dataIndex="isView"
            width={120}
            render={(text, record: MailHistory) =>
              record.isView === true ? (
                <Badge status={color(record.isView)} text={intl.get('EVET')} />
              ) : (
                <Badge status={color(record.isView)} text={intl.get('HAYIR')} />
              )
            }
          />
        </Table>

        {data && data.data && data.data.length > 0 && (
          <LocalPagination
            data={data}
            selectedCount={0}
            execute={() => additionalProps && additionalProps.refetch(uuid, documentNumber, pagination, additionalProps)}
            page={pagination.page}
            pageSize={pagination.pageSize}
            onPageChange={(page, pageSize) => {
              setPagination({ ...pagination, page, pageSize });
            }}
          />
        )}
      </Modal>
    );
  },
  (prev, next) => _.isEqual(prev.data, next.data) && prev.visible === next.visible && prev.loading === next.loading
);

export default MailHistoriesModal;
