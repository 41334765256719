/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { TextType } from '.';
import { NS_CBC } from '../constants';

/*
  27.04.2021 : Fully completed
*/
export class CountryType {
  @XMLElement({ types: [{ name: 'IdentificationCode', namespaceUri: NS_CBC, itemType: () => String }] })
  identificationCode?: string;

  @XMLElement({ types: [{ name: 'Name', namespaceUri: NS_CBC, itemType: () => TextType }] })
  name?: TextType;
}

export default CountryType;
