import { AuthRole, AuthScope } from '@/interfaces';
import { faListUl, faPencilAlt, faPlus } from '@fortawesome/pro-regular-svg-icons';

export const EA_REPORT_ROLES: AuthRole = {
  value: 'EA:REPORTS',
  icon: faPencilAlt,
  checkable: false,
  children: [
    {
      value: 'EA:REPORTS:CREATE',
      checkable: true,
      icon: faPlus
    },
    {
      value: 'EA:REPORTS:LIST',
      checkable: true,
      icon: faListUl
    }
  ]
};
export const EARCHIVE_DOCUMENT_REPORT_ROLES = [EA_REPORT_ROLES];

export const EA_REPORT_SCOPES: AuthScope = {
  value: 'earchive:reports',
  checkable: false,
  children: [
    {
      value: 'earchive:reports:read',
      checkable: true
    },
    {
      value: 'earchive:reports:write',
      checkable: true
    }
  ]
};
export const EARCHIVE_DOCUMENT_REPORT_SCOPES = [EA_REPORT_SCOPES];
