// tslint:disable
// eslint:disable
/**
 * E-Invoice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum MarketPlaceProvider {
    None = 'None',
    Trendyol = 'Trendyol',
    Hepsiburada = 'Hepsiburada',
    N11 = 'N11',
    CicekSepeti = 'CicekSepeti',
    Amazon = 'Amazon',
    Pazarama = 'Pazarama',
    PttAvm = 'PttAvm',
    Akakce = 'Akakce',
    TurkcellPasaj = 'TurkcellPasaj',
    Morhipo = 'Morhipo',
    Farmazon = 'Farmazon',
    Ikas = 'Ikas',
    WooCommerce = 'WooCommerce',
    Idefix = 'Idefix'
}



