import { AuthRole, AuthScope } from '@/interfaces';
import {
  faArchive,
  faArrowUp,
  faBan,
  faBellOn,
  faCloudUpload,
  faCogs,
  faEdit,
  faFile,
  faFileCode,
  faFileExcel,
  faFilePdf,
  faFileSignature,
  faListUl,
  faMailbox,
  faPencil,
  faPencilAlt,
  faPlus,
  faSearch,
  faShare,
  faTable,
  faTags,
  faTrash,
  faUndo,
  faUsers
} from '@fortawesome/pro-regular-svg-icons';

export const EMM_EDITOR_ROLES: AuthRole = {
  value: 'EMM:EDITOR',
  icon: faPencilAlt,
  checkable: false,
  children: [
    {
      value: 'EMM:EDITOR:CREATE_EDIT',
      checkable: true,
      icon: faPlus
    }
  ]
};

export const EMM_OUTGOUNG_ROLES: AuthRole = {
  value: 'EMM:OUT',
  icon: faArrowUp,
  checkable: false,
  children: [
    {
      value: 'EMM:OUT:CRE-ROOT',
      checkable: false,
      icon: faFile,
      children: [
        {
          value: 'EMM:OUT:V',
          checkable: true,
          icon: faFile
        },
        {
          value: 'EMM:OUT:V:XML',
          checkable: true,
          icon: faFileCode
        },
        {
          value: 'EMM:OUT:V:PDF',
          checkable: true,
          icon: faFilePdf
        },
        {
          value: 'EMM:OUT:V:EMAIL_SEND',
          checkable: true,
          icon: faShare
        },
        {
          value: 'EMM:OUT:V:HTML',
          checkable: true,
          icon: faSearch
        },
        {
          value: 'EMM:OUT:V:CANCEL',
          checkable: true,
          icon: faArchive
        }
      ]
    },
    {
      checkable: false,
      value: 'EMM:OUT:CANCELED-ROOT',
      icon: faBan,
      children: [
        {
          value: 'EMM:OUT:CANCELED',
          checkable: true,
          icon: faBan
        },
        {
          value: 'EMM:OUT:V:CANCEL:WITHDRAW',
          checkable: true,
          icon: faUndo
        }
      ]
    },
    {
      checkable: false,
      value: 'EMM:OUT:RM',
      icon: faFileExcel,
      children: [
        {
          value: 'EMM:OUT:RM:REPORTS',
          icon: faTable,
          checkable: true
        },
        {
          value: 'EMM:OUT:RM:TEMPLATES',
          icon: faTags,
          checkable: true
        },
        {
          value: 'EMM:OUT:RM:TEMPLATES_POST_PUT',
          icon: faEdit,
          checkable: true
        }
      ]
    }
  ]
};

export const EMM_DEFINATIONS_ROLES: AuthRole = {
  value: 'EMM:DEF',
  icon: faCogs,
  checkable: false,
  children: [
    {
      value: 'EMM:DEF:SERIES-ROOT',
      checkable: false,
      icon: faListUl,
      children: [
        {
          value: 'EMM:DEF:SERIES',
          checkable: true,
          icon: faListUl
        },
        {
          value: 'EMM:DEF:SERIES_POST',
          icon: faEdit,
          checkable: true
        },
        {
          value: 'EMM:DEF:SERIES_DELETE',
          icon: faTrash,
          checkable: true
        }
      ]
    },
    {
      value: 'EMM:DEF:DOCUMENT_TEMPLATES-ROOT',
      checkable: false,
      icon: faTags,
      children: [
        {
          value: 'EMM:DEF:DOCUMENT_TEMPLATES',
          checkable: true,
          icon: faTags
        },
        {
          value: 'EMM:DEF:DOCUMENT_TEMPLATES_POST',
          icon: faEdit,
          checkable: true
        },
        {
          value: 'EMM:DEF:DOCUMENT_TEMPLATES_PUT',
          icon: faPencil,
          checkable: true
        },
        {
          value: 'EMM:DEF:DOCUMENT_TEMPLATES_DELETE',
          icon: faTrash,
          checkable: true
        }
      ]
    },
    {
      value: 'EMM:DEF:MAIL_SMS',
      icon: faMailbox,
      checkable: true
    },
    {
      value: 'EMM:DEF:FILE_EXPORT_TITLES',
      icon: faFileSignature,
      checkable: true
    }
  ]
};

export const EMM_TAGS_ROLES: AuthRole = {
  value: 'EMM:TAGS',
  icon: faTags,
  checkable: true
};

export const EMM_UPLOADS_ROLES: AuthRole = {
  value: 'EMM:UPLOADS_DOCUMENT',
  icon: faCloudUpload,
  checkable: true
};

export const EMM_NOTIFICATIONS_ROLES: AuthRole = {
  value: 'EMM:NOTIFY:OUT:DYNAMIC_RULES',
  icon: faBellOn,
  checkable: true
};

export const EMM_ROLES = [EMM_EDITOR_ROLES, EMM_OUTGOUNG_ROLES, EMM_DEFINATIONS_ROLES, EMM_NOTIFICATIONS_ROLES, EMM_TAGS_ROLES, EMM_UPLOADS_ROLES];

export const EMM_OUTGOING_SCOPES: AuthScope = {
  value: 'emm:outgoing',
  checkable: false,
  children: [
    {
      value: 'emm:outgoing:list:read'
    },
    {
      value: 'emm:outgoing:list:write'
    },
    {
      value: 'emm:outgoing:canceled:read'
    },
    {
      value: 'emm:outgoing:canceled:write'
    },
    {
      value: 'emm:outgoing:excelreport:read'
    },
    {
      value: 'emm:outgoing:excelreport:write'
    }
  ]
};

export const EMM_TAGS_SCOPES: AuthScope = {
  value: 'emm:tags',
  checkable: false,
  children: [
    {
      value: 'emm:tags:read'
    },
    {
      value: 'emm:tags:write'
    }
  ]
};

export const EMM_DEFINATIONS_SCOPES: AuthScope = {
  value: 'emm:definations',
  checkable: false,
  children: [
    {
      value: 'emm:definations:documentserie:read'
    },
    {
      value: 'emm:definations:documentserie:write'
    },
    {
      value: 'emm:definations:documenttemplate:read'
    },
    {
      value: 'emm:definations:documenttemplate:write'
    },
    {
      value: 'emm:definations:mailsms:read'
    },
    {
      value: 'emm:definations:mailsms:write'
    },
    {
      value: 'emm:definations:filetitle:read'
    },
    {
      value: 'emm:definations:filetitle:write'
    }
  ]
};

export const EMM_NOTIFICATIONS_SCOPES: AuthScope = {
  value: 'emm:notifications',
  checkable: false,
  children: [
    {
      value: 'emm:notifications:dynamic:read'
    },
    {
      value: 'emm:notifications:dynamic:write'
    }
  ]
};

export const EMM_UPLOAD_SCOPES: AuthScope = {
  value: 'emm:upload',
  checkable: false,
  children: [
    {
      value: 'emm:upload:write'
    }
  ]
};

export const EMM_SCOPES = [EMM_OUTGOING_SCOPES, EMM_TAGS_SCOPES, EMM_DEFINATIONS_SCOPES, EMM_NOTIFICATIONS_SCOPES, EMM_UPLOAD_SCOPES];
