// tslint:disable
// eslint:disable
/**
 * General API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum EArchiveDocumentType {
    EArchiveInvoice = 'EArchiveInvoice',
    EArchiveInvoiceCancel = 'EArchiveInvoiceCancel',
    ESmmVoucher = 'ESmmVoucher',
    ESmmVoucherCancel = 'ESmmVoucherCancel',
    EMmVoucher = 'EMmVoucher',
    EMmVoucherCancel = 'EMmVoucherCancel'
}



