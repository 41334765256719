import { Company, ExcelReportQueue } from '@/apis/earchive/interfaces';
import { V1OutgoingReportmoduleReportsPostRequest } from '@/apis/einvoice';
import { DateRangeSelector, InfoToastify, RootSpinner } from '@/components';
import { Close, Save } from '@/components/Buttons';
import { DateCriteria } from '@/components/Selects';
import ErrorViewer from '@/components/TableBody/ErrorViewer';
import { DateFormat, PageConst } from '@/constants/page';
import { useApi } from '@/hooks';
import { faFileSpreadsheet, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Form, Input, Modal, Row } from 'antd';
import { AxiosPromise } from 'axios';
import moment from 'moment';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { UncontrolledAlert } from 'reactstrap';

export interface AddressesModalProps {
  visible: boolean;
  company?: Company;
}

const CreateExcelReportModal = ({
  closeModal,
  post,
  modalState
}: {
  closeModal: () => void;
  post: (requestParameters: V1OutgoingReportmoduleReportsPostRequest) => AxiosPromise<ExcelReportQueue>;
  modalState: { visible: boolean; templateId?: string };
}) => {
  const [form] = Form.useForm();

  const { pathname } = useLocation();
  const history = useHistory();

  const { loading, call, error } = useApi<any, V1OutgoingReportmoduleReportsPostRequest>({
    asyncFunction: post,
    successCallback: (response, request) => {
      if (request) {
        closeModal();
        history.push(pathname.replace('templates', 'reports'));
        InfoToastify('Rapor oluşturulmak üzere kuyruğa yazıldı.');
      }
    }
  });

  const submit = () => {
    form.validateFields().then((x) => {
      const values = form.getFieldsValue();
      call({ excelReportCreateDto: { ...values, templateId: modalState.templateId } });
    });
  };

  const previousMonth = moment(new Date()).add(-1, 'month');
  const startDate = moment(previousMonth.toDate().firstDate());
  const endDate = moment(previousMonth.toDate().lastDate());

  return (
    <Modal
      title={
        <span>
          <FontAwesomeIcon icon={faFileSpreadsheet} className="mr-2" /> Excel Raporu Hazırla
        </span>
      }
      onCancel={closeModal}
      centered
      width={600}
      transitionName="fade"
      maskClosable={false}
      visible={modalState.visible}
      footer={[
        <React.Fragment key="addresses-modal-footer">
          <Close onClick={() => closeModal()} />
          <Save onClick={submit} />
        </React.Fragment>
      ]}
      destroyOnClose
    >
      <RootSpinner loading={loading}>
        <Form
          form={form}
          layout="vertical"
          name="accountTransaction"
          initialValues={{ startDate: startDate.format(DateFormat.ymd), endDate: endDate.format(DateFormat.ymd), dateCriteria: 'DocumentDate' }}
        >
          <UncontrolledAlert color="success" fade={false}>
            <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
            Tarih kriteri seçerek <b>Belge Tarihi</b> ya da <b>Eklenme Tarihine</b> göre bir aralık seçerek rapor alabilirsiniz.
          </UncontrolledAlert>

          <Row gutter={[PageConst.EditorColGutter, 5]}>
            <Form.Item name="startDate" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="endDate" hidden>
              <Input />
            </Form.Item>

            <Col sm={24}>
              <Form.Item name="dateCriteria" label={'Tarih Kriteri'} rules={[{ required: true }]}>
                <DateCriteria />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item label={'Tarih Aralığı'} rules={[{ required: true }]}>
                <DateRangeSelector
                  defaultValue={{ startDate, endDate }}
                  onChange={(value) =>
                    form.setFieldsValue({ startDate: value.startDate.format(DateFormat.ymd), endDate: value.endDate.format(DateFormat.ymd) })
                  }
                />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item name="title" label={'Rapor Açıklaması'} rules={[{ required: true, max: 250 }]}>
                <Input placeholder="Rapor açıklaması" />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item name="electronicMail" label={'Email Adres'} rules={[{ required: false, max: 75, type: 'email' }]}>
                <Input placeholder="Email adres" />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <div>
                * <b>Email Adres</b> alanını doldurduğunuzda belirtilen mail adresine rapor gönderilecektir.
              </div>
              <div>
                * Hazırlanan raporları <b>Rapor Listesi</b> sayfasından da takip edebilirsiniz
              </div>
            </Col>
          </Row>
        </Form>
        {error && <ErrorViewer error={error} />}
      </RootSpinner>
    </Modal>
  );
};

export default CreateExcelReportModal;
