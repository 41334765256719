import { V1DefinitionsSeriesPostRequest } from '@/apis/einvoice';
import { DocumentSerie, DocumentSerieDto } from '@/apis/einvoice/interfaces';
import { Close, Save } from '@/components/Buttons';
import { useApi } from '@/hooks';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'antd';
import { AxiosPromise } from 'axios';
import { Formik, FormikProps } from 'formik';
import { Checkbox, Form, FormItem, Input } from 'formik-antd';
import React, { useRef } from 'react';
import intl from 'react-intl-universal';
import * as Yup from 'yup';
import { InfoToastify, RootSpinner } from '..';
import { ErrorViewer } from '../TableBody';

const NewSerieScheme = Yup.object().shape({
  serie: Yup.string().required()
});

const NewSerieModal = ({
  closeModal,
  visible,
  post,
  refresh
}: {
  closeModal: () => void;
  visible: boolean;
  post: (requestParameters: V1DefinitionsSeriesPostRequest) => AxiosPromise<DocumentSerie>;
  refresh: () => Promise<void>;
}) => {
  let form = (useRef(null) as unknown) as FormikProps<V1DefinitionsSeriesPostRequest>;

  const { call, loading, error, clear } = useApi<void, V1DefinitionsSeriesPostRequest>({
    asyncFunction: post,
    successCallback: (_, request) => {
      if (request) {
        closeModal();
        refresh();
        InfoToastify(intl.getHTML('SERI_EKLENDI', { serie: request.documentSerieDto.serie }));
      }
    }
  });

  const submitForm = () => {
    if (form && form.isValid) {
      form.handleSubmit();
    }
  };

  return (
    <Modal
      title={
        <span>
          <FontAwesomeIcon icon={faPlus} className="mr-2" /> {intl.get('YENI_X_EKLE', { x: intl.get('seri') })}
        </span>
      }
      onCancel={closeModal}
      centered
      transitionName="fade"
      maskClosable={false}
      visible={visible}
      afterClose={clear}
      footer={[
        <React.Fragment key="new-serie-modal-footer">
          <Close onClick={closeModal} />
          <Save onClick={submitForm} />
        </React.Fragment>
      ]}
      destroyOnClose
    >
      <RootSpinner loading={loading}>
        <Formik<DocumentSerieDto>
          innerRef={(instance) => (form = instance)}
          onSubmit={(documentSerieDto) => call({ documentSerieDto })}
          validationSchema={NewSerieScheme}
          initialValues={{ serie: '', isDefault: false }}
        >
          <Form layout="vertical">
            <FormItem name="serie" label={intl.get('SERI')} hasFeedback required>
              <Input name="serie" placeholder={intl.get('SERI')} tabIndex={0} autoFocus />
            </FormItem>

            <FormItem name="isDefault">
              <Checkbox name="isDefault">{intl.get('VARSAYILAN_OLARAK_AYARLA')}</Checkbox>
            </FormItem>
          </Form>
        </Formik>

        {error && <ErrorViewer error={error} />}
      </RootSpinner>
    </Modal>
  );
};

export default NewSerieModal;
