import { DailyStatistics } from '@/apis/general/interfaces';
import { PageStyle } from '@/constants/page';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton, Tabs } from 'antd';
import React, { useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Pie from '../Components/Pie';

export const ProfileIdStats = ({
  data,
  flattenData,
  loading,
  startDate,
  endDate
}: {
  data?: Array<DailyStatistics>;
  flattenData: any;
  loading: boolean;
  startDate: string;
  endDate: string;
}) => {
  if (loading) {
    return <Skeleton active loading={loading} />;
  }

  const [activeTab, setActiveTab] = useState<'incoming' | 'outgoing'>('incoming');

  const getColorFromProfile = (profile: string) => {
    switch (profile) {
      case 'TEMELFATURA':
        return '#007bff';
      case 'TICARIFATURA':
        return '#dc3545';
      case 'IHRACAT':
        return '#fd7e14';
      case 'YOLCUBERABERFATURA':
        return '#17a2b8';
      case 'HKS':
        return '#ffc107';
      case 'KAMU':
        return '#6c757d';
      default:
        return 'black';
    }
  };

  return (
    <Card className="h-100 kit__utils__cardMarked kit__utils__cardMarked--success">
      <CardHeader className={PageStyle.card__header}>
        <div className="d-flex align-items-center mr-auto">
          <FontAwesomeIcon icon={faPencil} style={{ marginRight: '10px' }} color="#0ab1fc" />
          <span className="font-weight-bold mr-3">Fatura Profili</span>
        </div>
        <Tabs defaultActiveKey={activeTab} onChange={(value: any) => setActiveTab(value)} className="air-tabs tabbed-card" style={{ marginBottom: '0px' }}>
          <Tabs.TabPane tab="Gelen" key="incoming" />
          <Tabs.TabPane tab="Giden" key="outgoing" />
        </Tabs>
      </CardHeader>
      <CardBody style={{ padding: '10px 20px' }} className={'overflow-auto align-items-center d-flex'}>
        {activeTab === 'incoming' && (
          <Pie
            data={
              data?.length !== 0
                ? Object.keys(flattenData)
                    .filter((x) => x.startsWith('EI:I:P'))
                    .map((x) => {
                      const profile = x.replace('EI:I:P:', '');
                      return {
                        label: profile.replace('YOLCUBERABERFATURA', 'TAXFREE'),
                        value: flattenData[x],
                        color: getColorFromProfile(profile),
                        url: `/einvoice/incoming/list?startDate=${startDate}&endDate=${endDate}&profileId=${profile}`
                      };
                    })
                : []
            }
          />
        )}
        {activeTab === 'outgoing' && (
          <Pie
            data={
              data?.length !== 0
                ? Object.keys(flattenData)
                    .filter((x) => x.startsWith('EI:O:P'))
                    .map((x) => {
                      const profile = x.replace('EI:O:P:', '');
                      return {
                        label: profile.replace('YOLCUBERABERFATURA', 'TAXFREE'),
                        value: flattenData[x],
                        color: getColorFromProfile(profile),
                        url: `/einvoice/outgoing/list?startDate=${startDate}&endDate=${endDate}&profileId=${profile}`
                      };
                    })
                : []
            }
          />
        )}
      </CardBody>
    </Card>
  );
};

export default ProfileIdStats;
