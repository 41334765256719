import { Toastify } from '@/interfaces';
import { faEngineWarning } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import intl from 'react-intl-universal';
import { toast, ToastPosition } from 'react-toastify';
export const Toast = ({ message, type, autoClose, containerId, hideProgressBar, icon, position }: Toastify) => {
  return toast(
    <div>
      {icon !== undefined && <div className="toastify-left">{icon}</div>}
      <div className="toastify-body">{message}</div>
    </div>,
    {
      position: position !== undefined ? position : 'top-right',
      closeOnClick: false,
      autoClose: autoClose === undefined ? 2500 : autoClose,
      type,
      hideProgressBar,
      containerId
    }
  );
};

export const NoRecordSelectedToastify = (text: string = intl.get('HERHANGI_BIR_KAYIT_SECILMEDI')) =>
  Toast({
    message: text,
    type: 'warning',
    icon: <FontAwesomeIcon icon={faExclamationTriangle} />,
    hideProgressBar: true
  });

export const SuccessToastify = (message) =>
  Toast({
    message,
    type: 'success',
    icon: <FontAwesomeIcon icon={faCheckCircle} />,
    hideProgressBar: true
  });

export const DangerToastify = (message, position: ToastPosition = 'top-right', hideProgressBar?: boolean, autoClose?: number | false) =>
  Toast({
    message,
    type: 'error',
    icon: <FontAwesomeIcon icon={faExclamationTriangle} />,
    hideProgressBar: hideProgressBar === undefined ? true : hideProgressBar,
    position: position,
    autoClose
  });

export const InfoToastify = (message) =>
  Toast({
    message,
    type: 'info',
    icon: <FontAwesomeIcon icon={faCheckCircle} />,
    autoClose: 4000,
    hideProgressBar: false
  });

export const WarningToastify = (message, time?: number) =>
  Toast({
    message,
    type: 'warning',
    icon: <FontAwesomeIcon icon={faEngineWarning} />,
    autoClose: time || 4000,
    hideProgressBar: false
  });

export default Toastify;
