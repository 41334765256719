import { PageStyle } from '@/constants/page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton } from 'antd';
import moment from 'moment';
import 'moment/locale/tr';
import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

interface LineData {
  value: number;
  label: string;
  color: string;
  url?: string;
}

interface LineChart {
  date: moment.Moment;
  data: LineData[];
}

export const DailyLineChart = ({ data, loading, title, icon }: { data: LineChart[]; loading: boolean; title: string; icon: any }) => {
  if (loading) {
    return <Skeleton active loading={loading} />;
  }

  const flattenData = (data || []).reduce((flattenObject, currentDate) => {
    currentDate.data.map((item) => {
      if (flattenObject[item.label] === undefined) {
        flattenObject[item.label] = item.value || 0;
      } else {
        flattenObject[item.label] += item.value || 0;
      }
    });
    return flattenObject;
  }, {});

  const renderLegend = (props: any) => {
    const { payload } = props;
    return (
      <ul className="recharts-default-legend" style={{ padding: 0, margin: 0, textAlign: 'center' }}>
        {payload.map((entry, index) => (
          <li
            className={`legend recharts-legend-item legend-item-${index}`}
            key={`item-${index}`}
            style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '10px' }}
          >
            <svg
              className="recharts-surface"
              width="14"
              height="14"
              viewBox="0 0 32 32"
              version="1.1"
              style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '4px' }}
            >
              <path
                strokeWidth={4}
                fill="none"
                stroke={entry.color}
                d="M0,16h10.666666666666666
            A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
            H32M21.333333333333332,16
            A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16"
                className="recharts-legend-icon"
              ></path>
            </svg>
            <Link to={entry.payload['data-url']} className="recharts-legend-item-text" style={{ color: entry.color }}>
              {entry.dataKey} - ( <b>{flattenData[entry.dataKey] || 0}</b> )
            </Link>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Card className="h-100">
      <CardHeader className={PageStyle.card__header}>
        <div className="justify-content-center mr-auto utils_custom_title">
          <FontAwesomeIcon icon={icon} color="#0ab1fc" style={{ marginRight: '10px' }} />
          {title}
        </div>
      </CardHeader>
      <CardBody>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data.map((x) => {
              const obj = {
                name: x.date.locale('tr').format('LL dddd'),
                short: x.date.locale('tr').format('DD MMM')
              };

              x.data.map((item) => {
                obj[item.label] = item.value;
              });

              return obj;
            })}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="short" />
            <YAxis />
            <Tooltip
              labelFormatter={(_, payload) => {
                return <b>{payload && payload[0]?.payload?.name}</b>;
              }}
            />
            <Legend content={renderLegend} />
            {data
              .reduce((array: { key: string; color: string; url?: string }[], item) => {
                item.data.map((x) => {
                  if (!array.some((a) => a.key === x.label)) {
                    array.push({ color: x.color, key: x.label, url: x.url });
                  }
                });

                return array;
              }, [])
              .map((x, index) => {
                return <Line strokeWidth={2} key={index} type="monotone" dataKey={x.key} stroke={x.color} data-url={x.url} />;
              })}
            asd asdas
          </LineChart>
        </ResponsiveContainer>
      </CardBody>
    </Card>
  );
};

export default DailyLineChart;
