import { V1NotificationsIncomingDynamicrulesIdPutRequest, V1NotificationsIncomingDynamicrulesPostRequest } from '@/apis/einvoice';
import {
  ActiveStatus,
  DynamicNotificationRuleDetailDto,
  DynamicNotificationRuleDto,
  DynamicNotificationRuleModel,
  DynamicNotificationRuleReceiverDto,
  NotificationType
} from '@/apis/einvoice/interfaces';
import { DangerToastify } from '@/components';
import { Close, Delete, New, Save } from '@/components/Buttons';
import { ErrorViewer, TableDefaultLoading } from '@/components/TableBody';
import { PageConst } from '@/constants/page';
import { MAIL_REGEX, REGISTER_NUMBER } from '@/constants/regex';
import { useApi } from '@/hooks';
import { DynamicNotification } from '@/interfaces';
import { faEnvelope, faFlagCheckered, faPencilAlt, faPlus } from '@fortawesome/pro-light-svg-icons';
import { faArrowsH, faMailBulk, faTriangle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Modal, Row, Tabs } from 'antd';
import { AxiosPromise } from 'axios';
import { Formik, FormikProps } from 'formik';
import { Form, FormItem, Input, InputNumber, Select, Table } from 'formik-antd';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import intl from 'react-intl-universal';
import * as Yup from 'yup';
import { InfoToastify, RootSpinner, TabFeedback } from '..';
import { NewInAddon } from '../AddonButtons';

export interface RuleDetailsModalProps {
  visible: boolean;
  rules?: DynamicNotificationRuleModel;
}

interface UpsertFormModel {
  ruleName: string;
  activeStatus?: ActiveStatus;
  detail?: RuleDetailModel;
  receiver?: string;
}

interface RuleDetailModel {
  value1?: string | number | null;
  value2?: string | number | null;
  property: string;
  operator: string;
}

interface DTO {
  details: Array<DynamicNotificationRuleDetailDto>;
  receivers: Array<DynamicNotificationRuleReceiverDto>;
}

const { Option } = Select;

export const UpsertRuleModal = ({
  modalState: { visible, rules },
  closeModal,
  post,
  put,
  refresh,
  properties
}: {
  modalState: RuleDetailsModalProps;
  closeModal: () => void;
  post: (requestParameters: V1NotificationsIncomingDynamicrulesPostRequest) => AxiosPromise<DynamicNotificationRuleDto>;
  put: (requestParameters: V1NotificationsIncomingDynamicrulesIdPutRequest) => AxiosPromise<void>;
  refresh: () => Promise<void>;
  properties: Array<DynamicNotification>;
}) => {
  let form = (useRef(null) as unknown) as FormikProps<UpsertFormModel>;

  const [ruleDetail, setRuleDetail] = useState<DTO>({
    details: [],
    receivers: []
  });

  /*
   *Düzenleme modalı açılınca state i günceller
   */
  useEffect(() => {
    if (rules) setRuleDetail(rules);
    else setRuleDetail({ details: [], receivers: [] });
  }, [rules]);

  const UpsertRuleDetailScheme = Yup.object().shape<UpsertFormModel>({
    ruleName: Yup.string().nullable().required(),
    activeStatus: Yup.mixed<keyof typeof ActiveStatus>().oneOf(Object.values(ActiveStatus)),
    receiver: Yup.string().email(),

    detail: Yup.object()
      .required()
      .shape<DynamicNotificationRuleDetailDto>({
        operator: Yup.string()
          .nullable()
          .required()
          .test('property-test', 'Lütfen bir koşul operatörü seçiniz.', () => {
            if (form.values.detail?.property !== 'None' && form.values.detail?.operator === 'None') {
              return false;
            }
            return true;
          }),
        property: Yup.string().nullable().required(),
        value1: Yup.string()
          .nullable()
          .test('property-test', 'Lütfen Değer giriniz.', () => {
            if (form.values.detail?.property !== 'None' && form.values.detail?.operator !== 'None') {
              if (form.values.detail?.value1 === 'None' || form.values.detail?.value1 === '' || form.values.detail?.value1 === 0) {
                return false;
              }
            }
            return true;
          })
          .test('value1-test-partyIdentification', 'Geçerli bir V.K.N / T.C.K.N giriniz', () => {
            if (
              form.values.detail?.property === 'partyIdentification' &&
              form.values.detail?.operator !== 'None' &&
              typeof form.values.detail?.value1 === 'string' &&
              !form.values.detail.value1.match(REGISTER_NUMBER)
            ) {
              return false;
            }
            return true;
          }),
        value2: Yup.string()
          .nullable()
          .test('property-operator-test', 'Lütfen Değer giriniz.', () => {
            if (
              form.values.detail?.property !== 'None' &&
              form.values.detail?.operator === 'Between' &&
              (form.values.detail.value2 === 0 || form.values.detail.value2 === '')
            ) {
              return false;
            }
            return true;
          })
      })
  });

  const generateInput = (values: UpsertFormModel) => {
    if (values && values.detail) {
      const { property, operator } = values.detail;
      const propertyDetail = properties.find((x) => x.property.value === property);
      if (property !== 'None' && operator !== '') {
        if (propertyDetail?.selectItems) {
          return (
            <Row gutter={PageConst.RowGutter}>
              <Col sm={24}>
                <FormItem name="detail.value1" label={'Değer'} hasFeedback required>
                  <Select className="w-100" name="detail.value1">
                    {propertyDetail?.selectItems.map(({ value, text, icon }) => (
                      <Option key={value} value={value}>
                        {icon && icon}
                        {text}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
            </Row>
          );
        }

        /**
         * Sayısal değer üzerinden yapılacak bir filtreleme ise
         */
        if (propertyDetail?.operators.some((x) => x.value === 'Between')) {
          if (operator === 'Between') {
            return (
              <Row gutter={PageConst.RowGutter}>
                <Col sm={24} md={12}>
                  <FormItem name="detail.value1" label={intl.get('BASLANGIC_DEGERI')} hasFeedback required>
                    <InputNumber
                      name="detail.value1"
                      style={{ width: '100%', textAlign: 'right' }}
                      defaultValue={1000}
                      formatter={(value) => `  ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    />
                  </FormItem>
                </Col>

                <Col sm={24} md={12}>
                  <FormItem name="detail.value2" label={intl.get('BITIS_DEGERI')} hasFeedback required>
                    <InputNumber
                      name="detail.value2"
                      style={{ width: '100%', textAlign: 'right' }}
                      defaultValue={1000}
                      formatter={(value) => `  ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    />
                  </FormItem>
                </Col>
              </Row>
            );
          }

          return (
            <Row gutter={PageConst.RowGutter}>
              <Col sm={24}>
                <FormItem name="detail.value1" label={intl.get('DEGER')} hasFeedback required>
                  <InputNumber
                    name="detail.value1"
                    style={{ width: '100%', textAlign: 'right' }}
                    defaultValue={1000}
                    formatter={(value) => `  ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  />
                </FormItem>
              </Col>
            </Row>
          );
        }

        return (
          <Row gutter={PageConst.RowGutter}>
            <Col sm={24}>
              <FormItem name="detail.value1" label={intl.get('DEGER')} hasFeedback required>
                <Input name="detail.value1" style={{ width: '100%', textAlign: 'left' }} />
              </FormItem>
            </Col>
          </Row>
        );
      }
    }
  };

  const callBack = (title: string, isEdit: boolean) => {
    closeModal();
    refresh();
    InfoToastify(intl.getHTML(isEdit ? 'SABLON_GUNCELLENDI' : 'SABLON_EKLENDI', { title }));
  };

  /**
   * Insert işlemi için kullanılan fonksiypn
   */
  const postRequest = useApi<DynamicNotificationRuleDto, V1NotificationsIncomingDynamicrulesPostRequest>({
    asyncFunction: post,
    successCallback: (_, request) => {
      if (request) {
        callBack(request.dynamicNotificationRuleDto.ruleName || '', false);
      }
    }
  });

  /**
   * Update işlemi için kullanılan fonksiyon
   */
  const putRequest = useApi<void, V1NotificationsIncomingDynamicrulesIdPutRequest>({
    asyncFunction: put,
    successCallback: (_, request) => {
      if (request) {
        callBack(request.dynamicNotificationRuleDto.ruleName, true);
      }
    }
  });

  const submitForm = () => {
    form.validateForm();

    if (ruleDetail.details.length === 0) {
      DangerToastify('Herhangi bir kural eklenmedi!');
      return;
    }
    if (ruleDetail.receivers.length === 0) {
      DangerToastify('Herhangi bir alıcı eklenmedi!');
      return;
    }

    if (ruleDetail.details.length > 0 && ruleDetail.receivers.length > 0) form.handleSubmit();
  };

  return (
    <Modal
      title={
        <span>
          {!rules && (
            <>
              <FontAwesomeIcon icon={faPlus} className="mr-2" /> {intl.get('YENI_X_EKLE', { x: intl.get('KURAL') })}
            </>
          )}
          {rules && (
            <>
              <FontAwesomeIcon icon={faPencilAlt} className="mr-2" /> {intl.get('X_DUZENLE', { x: intl.get('KURAL') })}
            </>
          )}
        </span>
      }
      onCancel={closeModal}
      centered
      width={800}
      transitionName="fade"
      maskClosable={false}
      visible={visible}
      afterClose={() => {
        putRequest.clear();
        postRequest.clear();
        setRuleDetail({ details: [], receivers: [] });
      }}
      footer={[
        <React.Fragment key="xslt-upsert-modal-footer">
          <Close onClick={closeModal} />
          <Save onClick={submitForm} />
        </React.Fragment>
      ]}
      destroyOnClose
    >
      <RootSpinner loading={postRequest.loading || putRequest.loading}>
        <Formik<UpsertFormModel>
          validateOnBlur={false}
          innerRef={(instance) => (form = instance)}
          onSubmit={() => {
            if (form.isValid)
              rules
                ? putRequest.call({
                    dynamicNotificationRuleDto: { ruleName: form.values.ruleName, ...ruleDetail },
                    id: rules.id
                  })
                : postRequest.call({ dynamicNotificationRuleDto: { ...ruleDetail, ruleName: form.values.ruleName } });
            form.resetForm();
          }}
          validationSchema={UpsertRuleDetailScheme}
          initialValues={
            rules
              ? {
                  detail: { property: 'None', operator: 'None' },
                  receiver: '',
                  ruleName: rules.ruleName
                }
              : {
                  detail: { property: 'None', operator: 'None' },
                  receiver: '',
                  ruleName: ''
                }
          }
        >
          {({ errors, touched, values }) => {
            return (
              <Form layout="vertical">
                <Row>
                  <Col sm={24} xs={24}>
                    <FormItem name="ruleName" label={intl.get('KURAL_ADI')} hasFeedback required>
                      <Input prefix={<FontAwesomeIcon icon={faFlagCheckered} />} name="ruleName" placeholder={intl.get('KURAL_ADI')} tabIndex={0} />
                    </FormItem>
                  </Col>
                </Row>
                <Tabs defaultActiveKey="1" animated={false} type="card">
                  <Tabs.TabPane
                    key="1"
                    tab={
                      <TabFeedback
                        icon={faTriangle}
                        title={intl.get('KURALLAR')}
                        isValid={
                          Object.keys(_.pick(touched, 'detail')).length !== 0 && ruleDetail.details.length > 0
                            ? Object.keys(_.pick(errors, 'detail')).length !== 0
                              ? false
                              : true
                            : undefined
                        }
                      />
                    }
                  >
                    <Row gutter={PageConst.RowGutter}>
                      <Col sm={24} md={values.detail?.property !== 'None' ? 12 : 24}>
                        <FormItem name="detail.property" label={'Özellik'} hasFeedback required>
                          <Select
                            className="w-100"
                            name="detail.property"
                            onChange={(value) => {
                              form.setValues({ ...form.values, detail: { property: value, operator: 'None', value1: '', value2: '' } }, false);
                            }}
                          >
                            {properties
                              .filter((x) => !ruleDetail.details.some((r) => r.property === x.property.value))
                              .map(({ property: { value, text, icon } }) => (
                                <Option key={value} value={value}>
                                  {icon && icon}
                                  {text}
                                </Option>
                              ))}
                          </Select>
                        </FormItem>
                      </Col>

                      {values.detail?.property !== 'None' && (
                        <Col sm={24} md={12}>
                          <FormItem name="detail.operator" label={'Özellik'} hasFeedback required>
                            <Select className="w-100" name="detail.operator">
                              {properties
                                .find((x) => x.property.value === values.detail?.property)
                                ?.operators.map(({ value, text, icon }) => (
                                  <Option key={value} value={value}>
                                    {icon && icon}
                                    {text}
                                  </Option>
                                ))}
                            </Select>
                          </FormItem>
                        </Col>
                      )}
                    </Row>
                    {values.detail?.operator !== 'None' && values.detail?.property !== '' && generateInput(values)}
                    {(values.detail?.value1 || values.detail?.value2) && (
                      <Row style={{ justifyContent: 'flex-end' }}>
                        <Col>
                          <New
                            onClick={() => {
                              if (values.detail && !Object.keys(form.errors).some((x) => x === 'detail')) {
                                setRuleDetail({
                                  ...ruleDetail,
                                  details: [
                                    ...ruleDetail.details,
                                    {
                                      operator: values.detail.operator,
                                      property: values.detail.property,
                                      value1: values.detail.value1?.toString(),
                                      value2: values.detail.value2?.toString()
                                    }
                                  ]
                                });
                                form.setValues({ ...form.values, detail: { property: 'None', operator: 'None' } });
                              } else {
                                form.validateForm();
                              }
                            }}
                          />
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col sm={24}>
                        <Table
                          style={{ marginTop: '10px' }}
                          loading={TableDefaultLoading(false)}
                          bordered
                          pagination={false}
                          dataSource={ruleDetail.details}
                          rowKey={(value) => value.operator}
                          name="rulesAll"
                          columns={[
                            {
                              title: 'Özellik',
                              dataIndex: 'property',
                              key: 'property',
                              render: (value) => {
                                // eslint-disable-next-line react/prop-types
                                const propertyDetail = properties.find((x) => x.property.value === value);

                                return (
                                  <div>
                                    {propertyDetail?.property.icon}
                                    <span>{propertyDetail?.property.text}</span>
                                  </div>
                                );
                              }
                            },
                            {
                              title: 'Koşul Operatörü',
                              dataIndex: 'operator',
                              key: 'operator',
                              render: (value) => {
                                const operatorDetail = properties
                                  // eslint-disable-next-line react/prop-types
                                  .find((x) => x.operators.find((item) => item.value === value))
                                  ?.operators.find((item) => item.value === value);

                                return (
                                  <div>
                                    {operatorDetail?.icon}
                                    <span>{operatorDetail?.text}</span>
                                  </div>
                                );
                              }
                            },
                            {
                              title: 'Değer',
                              key: 'values',
                              render: (value) => {
                                if (value.operator === 'Between')
                                  return (
                                    <b>
                                      {value.value1} <FontAwesomeIcon icon={faArrowsH} /> {value.value2}
                                    </b>
                                  );
                                if (typeof value.value1) return <b>{value.value1}</b>;
                              }
                            },
                            {
                              title: 'Sil',
                              width: 38,
                              render: (value) => {
                                return (
                                  <Delete
                                    onlyIcon
                                    onClick={() => {
                                      setRuleDetail({
                                        ...ruleDetail,
                                        details: ruleDetail.details.filter((detail, index) => ruleDetail.details[index] !== value)
                                      });
                                    }}
                                  />
                                );
                              }
                            }
                          ]}
                        />
                      </Col>
                    </Row>
                  </Tabs.TabPane>

                  <Tabs.TabPane
                    key="2"
                    tab={
                      <TabFeedback
                        icon={faMailBulk}
                        title={intl.get('ALICILAR')}
                        isValid={
                          Object.keys(_.pick(touched, 'receiver')).length !== 0 && ruleDetail.receivers.length > 0
                            ? Object.keys(_.pick(errors, 'receiver')).length !== 0
                              ? false
                              : true
                            : undefined
                        }
                      />
                    }
                  >
                    <Row gutter={PageConst.RowGutter}>
                      <Col sm={24}>
                        <FormItem name="receiver" label={'Mail'} hasFeedback={false}>
                          <Input
                            prefix={<FontAwesomeIcon icon={faEnvelope} />}
                            name="receiver"
                            placeholder={intl.get('MAIL')}
                            type="email"
                            addonAfter={
                              <NewInAddon
                                onClick={() => {
                                  if (MAIL_REGEX.test(values.receiver || '')) {
                                    setRuleDetail({
                                      ...ruleDetail,
                                      receivers: [...ruleDetail.receivers, { receiverValue: values.receiver || '', notificationType: NotificationType.Mail }]
                                    });

                                    const formValues = form.values;
                                    form.resetForm();
                                    form.setValues({ ...formValues, receiver: '' });
                                  }
                                }}
                              />
                            }
                            required
                          />
                        </FormItem>
                      </Col>
                    </Row>
                    <Table
                      style={{ marginTop: '10px', width: '100%' }}
                      loading={TableDefaultLoading(false)}
                      bordered
                      pagination={false}
                      dataSource={ruleDetail.receivers}
                      rowKey={(record) => record.receiverValue}
                      name="electronicMail"
                      columns={[
                        {
                          title: 'Mail',
                          dataIndex: 'receiverValue',
                          key: 'receiverValue',

                          render: (value) => {
                            return (
                              <div>
                                <FontAwesomeIcon className={'mr-2'} icon={faEnvelope} />
                                {value}
                              </div>
                            );
                          }
                        },
                        {
                          title: 'Sil',
                          width: 38,
                          render: (value) => {
                            return (
                              <Delete
                                onlyIcon
                                onClick={() =>
                                  setRuleDetail({
                                    ...ruleDetail,
                                    receivers: ruleDetail.receivers.filter((_, index) => ruleDetail.receivers[index] !== value)
                                  })
                                }
                              />
                            );
                          }
                        }
                      ]}
                    ></Table>
                  </Tabs.TabPane>
                </Tabs>
              </Form>
            );
          }}
        </Formik>

        {(postRequest.error || putRequest.error) && <ErrorViewer error={postRequest.error || putRequest.error} />}
      </RootSpinner>
    </Modal>
  );
};

export default UpsertRuleModal;
