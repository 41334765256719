import { XMLElement } from '@/xmldom-decorators';
import { DocumentReferenceType } from '.';
import { NS_CAC } from '../constants';

/*
  27.04.2021 : Partialy completed
  
*/
export class BillingReferenceType {
  @XMLElement({ types: [{ name: 'InvoiceDocumentReference', namespaceUri: NS_CAC, itemType: () => DocumentReferenceType }] })
  invoiceDocumentReference?: DocumentReferenceType;

  @XMLElement({ types: [{ name: 'SelfBilledInvoiceDocumentReference', namespaceUri: NS_CAC, itemType: () => DocumentReferenceType }] })
  selfBilledInvoiceDocumentReference?: DocumentReferenceType;

  @XMLElement({ types: [{ name: 'CreditNoteDocumentReference', namespaceUri: NS_CAC, itemType: () => DocumentReferenceType }] })
  creditNoteDocumentReference?: DocumentReferenceType;

  @XMLElement({ types: [{ name: 'SelfBilledCreditNoteDocumentReference', namespaceUri: NS_CAC, itemType: () => DocumentReferenceType }] })
  selfBilledCreditNoteDocumentReference?: DocumentReferenceType;

  @XMLElement({ types: [{ name: 'DebitNoteDocumentReference', namespaceUri: NS_CAC, itemType: () => DocumentReferenceType }] })
  debitNoteDocumentReference?: DocumentReferenceType;

  @XMLElement({ types: [{ name: 'ReminderDocumentReference', namespaceUri: NS_CAC, itemType: () => DocumentReferenceType }] })
  reminderDocumentReference?: DocumentReferenceType;

  @XMLElement({ types: [{ name: 'AdditionalDocumentReference', namespaceUri: NS_CAC, itemType: () => DocumentReferenceType }] })
  additionalDocumentReference?: DocumentReferenceType;

  // TODO : BillingReferenceLine
}
