/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { TaxesWithKdv, TaxesWithoutKdv } from '@/constants/gib';
import { IstisnaDigerOptions, IstisnaOptions, OzelMatrahOptions, TaxFreeOptions } from '@/constants/invoice-editor';
import { IhracKayitliTaxReason, WithouldingTaxReason } from '@/constants/option-items';
import { SetLineWithCalculate, setCurrencyCode } from '@/contexts/InvoiceEditorContext';
import { InvoiceLineType, TaxSubTotalType } from '@/models/xmlDocuments/cac';
import { Invoice } from '@/models/xmlDocuments/invoice';
import { nameOf } from '@/utils/objectUtils';
import update, { Context, extend } from 'immutability-helper';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { InvoiceLineProxy } from './Proxies';

const taxCode = (taxSubTotalType: TaxSubTotalType) => taxSubTotalType.taxCategory?.taxScheme?.taxTypeCode;
const taxReasonCode = (taxSubTotalType: TaxSubTotalType) => taxSubTotalType.taxCategory?.taxExemptionReasonCode;

export const invoiceEditorImmutableHelperContext = new Context();

extend('$auto', function (value, object) {
  return object ? update(object, value) : update({}, value);
});
extend('$autoArray', function (value, obj: any) {
  let s: any = null;
  if (obj) {
    s = update(obj, value);
  } else {
    s = update([], value);
  }

  return s;
});

invoiceEditorImmutableHelperContext.extend('$setLineWithCalculate', function (action: SetLineWithCalculate, invoiceLine: InvoiceLineType) {
  //State güncellenemesinin forma yansıması için (touched=true durumu) resetFields çalıştırılıyor.
  const { id, value, form } = action;

  let newLine = _.cloneDeep(invoiceLine);

  let removedTax: TaxSubTotalType | undefined = undefined;
  let updatedTax: TaxSubTotalType | undefined = undefined;

  //   //OnChange eventinden gelen değer state de bulunan line'a yazılıyor.
  switch (action.subActionType) {
    case 'UPDATE_TAX_CODE':
      updatedTax = newLine.taxTotal.taxSubtotal[action.subLineIndex!];

      newLine = invoiceEditorImmutableHelperContext.update(newLine, {
        taxTotal: {
          taxSubtotal: {
            [action.subLineIndex!]: {
              $set: {
                taxableAmount: { value: 0 },
                calculationField: 'Percent',
                percent: 0,
                taxAmount: { value: 0 },
                taxCategory: {
                  taxScheme: {
                    taxTypeCode: action.value,
                    name: TaxesWithoutKdv.find((x) => x.taxTypeCode === action.value)?.description
                  }
                },
                uniqueId: uuidv4()
              }
            }
          }
        }
      });
      break;
    case 'REMOVE_ALLOWANCE':
      newLine = invoiceEditorImmutableHelperContext.update(newLine, {
        allowanceCharge: { $splice: [[action.subLineIndex!, 1]] }
      });
      break;
    case 'REMOVE_TAX':
      removedTax = newLine.taxTotal.taxSubtotal[action.subLineIndex!];
      newLine = invoiceEditorImmutableHelperContext.update(newLine, {
        taxTotal: {
          taxSubtotal: { $splice: [[action.subLineIndex!, 1]] }
        }
      });
      break;
    case 'CHANGE_WITHHOLDING_ACTIVATE': {
      newLine = invoiceEditorImmutableHelperContext.update(newLine, {
        withholdingTaxTotal:
          value === true
            ? {
                $set: [
                  {
                    taxAmount: { value: 0 },
                    taxSubtotal: [
                      {
                        calculationField: 'Percent',
                        percent: 0,
                        taxAmount: { value: 0 },
                        taxCategory: { taxScheme: { taxTypeCode: '', name: '' } },
                        taxableAmount: { value: 0 },
                        uniqueId: uuidv4()
                      }
                    ]
                  }
                ]
              }
            : { $set: undefined }
      });

      break;
    }
    default: {
      if (id) {
        if (id !== 'taxIncludeAmount') {
          const withoutUniqueId = id!.substr(id!.indexOf('.') + 1);

          newLine = _.set(newLine, withoutUniqueId, value);

          if (action.productService) {
            const unitCodeId = `${nameOf(InvoiceLineProxy.invoicedQuantity?.unitCode)}`;
            const priceAmountId = `${nameOf(InvoiceLineProxy.price?.priceAmount?.value)}`;
            // const kdvPercentId = `${nameOf(InvoiceLineProxy.taxTotal?.taxSubtotal[0].percent)}`;
            const nameId = `${nameOf(InvoiceLineProxy.item?.name?.value)}`;

            newLine = _.set(newLine, nameId, action.productService.name);
            newLine = _.set(newLine, unitCodeId, action.productService.unitTypeCode);
            newLine = _.set(newLine, priceAmountId, action.productService.priceAmount);

            // newLine = _.set(newLine, kdvPercentId, action.productService.taxPercent);
          }

          if (action.subActionType === 'SET_WITHHOLDING_REASON') {
            const reason = WithouldingTaxReason.find((x) => x.reasonCode === action.value);
            if (reason) {
              newLine.withholdingTaxTotal![0].taxSubtotal[0]!.percent = reason.percent;
              newLine.withholdingTaxTotal![0].taxSubtotal[0]!.taxCategory.taxScheme.name = reason.text;
            }
          }

          if (action.subActionType === 'SET_OZELMATRAH_REASON') {
            const reason = OzelMatrahOptions.find((x) => x.value === action.value);
            if (reason) {
              newLine.taxTotal.taxSubtotal[0]!.taxCategory.taxExemptionReason = (reason.value === '0' ? undefined : reason?.text) as any;
              newLine.taxTotal.taxSubtotal[0]!.taxCategory.taxExemptionReasonCode = (reason.value === '0' ? undefined : reason?.value) as any;

              if (reason.value === '0') {
                const id = `${newLine.uniqueId}.${nameOf(InvoiceLineProxy.taxTotal?.taxSubtotal[0].taxableAmount.value)}`;
                action.form && action.form.setFieldsValue({ [id]: 0 });
              }
            }
          }

          if (action.subActionType === 'SET_KDV_REASON') {
            const reason = IstisnaOptions.concat(IhracKayitliTaxReason)
              .concat(IstisnaDigerOptions)
              .concat(TaxFreeOptions)
              .find((x) => x.value === action.value);

            if (reason) {
              newLine.taxTotal.taxSubtotal[0]!.taxCategory.taxExemptionReason = (reason.value === '0' ? undefined : reason?.text) as any;
              newLine.taxTotal.taxSubtotal[0]!.taxCategory.taxExemptionReasonCode = (reason.value === '0' ? undefined : reason?.value) as any;
            }
          }
        }

        if (id === 'taxIncludeAmount') {
          //Kdv Dahil Tutar
          const taxIncludedAmount = action.value;

          //KDV Oran
          const kdvPercent = newLine?.taxTotal?.taxSubtotal?.find((x) => x.taxCategory?.taxScheme?.taxTypeCode === '0015')?.percent || 0;

          const malHizmetTutar = taxIncludedAmount / (1 + kdvPercent / 100);

          const iskontoOran = newLine?.allowanceCharge?.[0]?.percent || 0;

          const quantity = newLine?.invoicedQuantity?.value || 0;
          const price = (malHizmetTutar * 100) / (100 - iskontoOran);

          if (!newLine.price) {
            newLine.price = { priceAmount: { value: 0, currencyID: action.invoice.documentCurrencyCode.value } };
          }

          newLine.price!.priceAmount = { value: (price / quantity).round(6), currencyID: action.invoice.documentCurrencyCode.value };

          const priceFormId = `${action.uniqueId}.${nameOf(InvoiceLineProxy.price?.priceAmount?.value)}`;

          action.form && action.form.setFieldsValue({ [priceFormId]: newLine.price!.priceAmount.value });
        }

        break;
      }
    }
  }

  //Hesaplama yöntemi set ediliyor
  if (action.calculationField) {
    switch (action.calculationField) {
      case 'AllowanceAmount':
      case 'AllowancePercent':
        newLine.allowanceCharge![action.subLineIndex!].calculationField = action.calculationField === 'AllowanceAmount' ? 'Amount' : 'Percent';
        break;

      case 'TaxAmount':
      case 'TaxPercent':
        newLine.taxTotal.taxSubtotal[action.subLineIndex!].calculationField = action.calculationField === 'TaxAmount' ? 'Amount' : 'Percent';
        break;
    }
  }

  let subTotal = (newLine.price?.priceAmount?.value || 0) * (newLine.invoicedQuantity?.value || 0);
  //   (newLine.lineExtensionAmount || { value: 0 }).value = subTotal;
  newLine.lineExtensionAmount = { value: subTotal };

  //İskonto/Artırımlar satıra uygulanıyor
  newLine.allowanceCharge!.forEach((allowanceCharge, subIndex) => {
    const paths = {
      percent: `${newLine.uniqueId}.${nameOf(InvoiceLineProxy.allowanceCharge![subIndex === 0 ? subIndex : allowanceCharge.uniqueId].percent)}`,
      amount: `${newLine.uniqueId}.${nameOf(InvoiceLineProxy.allowanceCharge![subIndex === 0 ? subIndex : allowanceCharge.uniqueId].amount?.value)}`
    };

    if (allowanceCharge.calculationField === 'Amount') {
      allowanceCharge.multiplierFactorNumeric = (allowanceCharge.amount.value! / subTotal).round(4);
      allowanceCharge.percent = (allowanceCharge.multiplierFactorNumeric * 100).round(4);

      if (isNaN(allowanceCharge.multiplierFactorNumeric)) {
        allowanceCharge.multiplierFactorNumeric = 0;
        allowanceCharge.percent = 0;
      }
    } else {
      allowanceCharge.multiplierFactorNumeric = allowanceCharge.percent / 100;
      allowanceCharge.amount = { value: (subTotal * allowanceCharge.multiplierFactorNumeric!).round(2) };
    }

    form && form.setFieldsValue({ [paths.amount]: allowanceCharge.amount.value });
    form && form.setFieldsValue({ [paths.percent]: allowanceCharge.percent });

    allowanceCharge.baseAmount = { value: subTotal };

    if (allowanceCharge.chargeIndicator) {
      subTotal += allowanceCharge.amount.value!;
    } else {
      subTotal -= allowanceCharge.amount.value!;
    }
  });

  const subTotalNotRounded = subTotal;
  subTotal = subTotal.round(2);

  newLine.lineExtensionAmount.value = subTotal;

  if (action.invoice.invoiceTypeCode === 'OZELMATRAH') {
    if (newLine.taxTotal.taxSubtotal[0].taxCategory.taxExemptionReasonCode === undefined) {
      newLine.taxTotal.taxSubtotal[0].taxableAmount = { value: subTotal };
    } else {
      if (action.subActionType === 'SET_OZELMATRAH_REASON') {
        newLine.taxTotal.taxSubtotal[0].taxableAmount = { value: 0 };
      } else {
        const removedGibTax = TaxesWithoutKdv.find((x) => x.taxTypeCode === removedTax?.taxCategory?.taxScheme?.taxTypeCode);
        if (action.subActionType === 'REMOVE_TAX' && removedGibTax && removedGibTax.isIncreaseKdv) {
          newLine.taxTotal.taxSubtotal[0].taxableAmount.value -= removedTax!.taxAmount.value;
        }

        const updatedGibTax = TaxesWithoutKdv.find((x) => x.taxTypeCode === updatedTax?.taxCategory?.taxScheme?.taxTypeCode);
        if (action.subActionType === 'UPDATE_TAX_CODE' && updatedGibTax && updatedGibTax.isIncreaseKdv) {
          newLine.taxTotal.taxSubtotal[0].taxableAmount.value -= updatedTax!.taxAmount.value;
        }
      }
    }
  } else {
    if (action.invoice.invoiceTypeCode !== 'TEVKIFATIADE') {
      //Tevkifatlı iade faturaların matrah kullanıcıdan alınıyor
      newLine.taxTotal.taxSubtotal[0].taxableAmount = { value: subTotal };
    }
  }

  if (action.invoice.invoiceTypeCode === 'TEVKIFATIADE') {
    const percent = newLine.taxTotal.taxSubtotal[0].percent;
    const yeniKdv = (newLine.taxTotal.taxSubtotal[0].taxableAmount?.value * percent) / 100;

    newLine.taxTotal.taxSubtotal[0].taxAmount.value = yeniKdv.round(2);
  } else {
    //KDV Dahil hesaplamada bu alan sorun çıkarıyordu, KDV tuvarlanmamış subTotal olan subTotalNotRounded üzerinden hesaplanıyor.
    // const taxableAmountValue = newLine.taxTotal.taxSubtotal[0].taxableAmount.value || 0;

    const taxableAmount = action.invoice.invoiceTypeCode === 'OZELMATRAH' ? newLine.taxTotal.taxSubtotal[0].taxableAmount.value : subTotalNotRounded;

    newLine.taxTotal.taxSubtotal[0].taxAmount.value = ((taxableAmount * newLine.taxTotal.taxSubtotal[0].percent) / 100).round(2);
  }

  form &&
    form.setFields([
      { name: `${newLine.uniqueId}.${nameOf(InvoiceLineProxy.lineExtensionAmount!.value)}`, value: subTotal },
      {
        name: `${newLine.uniqueId}.${nameOf(InvoiceLineProxy.taxTotal.taxSubtotal[0].taxAmount.value)}`,
        value: newLine.taxTotal.taxSubtotal[0].taxAmount.value
      }
    ]);

  for (let i = 1; i < newLine.taxTotal.taxSubtotal.length; i++) {
    const lineTax = newLine.taxTotal.taxSubtotal[i];
    const gibTax = TaxesWithoutKdv.find((x) => x.taxTypeCode === lineTax.taxCategory.taxScheme.taxTypeCode);

    if (gibTax) {
      let taxAmount = 0;
      let taxPercent = 0;

      const paths = {
        percent: `${newLine.uniqueId}.${nameOf(InvoiceLineProxy.taxTotal.taxSubtotal![lineTax.uniqueId].percent)}`,
        amount: `${newLine.uniqueId}.${nameOf(InvoiceLineProxy.taxTotal.taxSubtotal![lineTax.uniqueId].taxAmount.value)}`
      };

      if (lineTax.calculationField === 'Amount') {
        taxAmount = lineTax.taxAmount.value || 0;
        taxPercent = ((taxAmount * 100) / newLine.lineExtensionAmount.value).round(2);
        lineTax.percent = taxPercent;
      } else {
        taxAmount = ((newLine.lineExtensionAmount.value * lineTax.percent) / 100).round(2);
        taxPercent = lineTax.percent;
        lineTax.taxAmount.value = taxAmount;
      }

      if (gibTax.isIncreaseKdv && newLine.taxTotal.taxSubtotal[0].taxAmount.value > 0) {
        newLine.taxTotal.taxSubtotal[0].taxAmount.value += ((taxAmount * newLine.taxTotal.taxSubtotal[0].percent) / 100).round(2);
        newLine.taxTotal.taxSubtotal[0].taxableAmount.value! += taxAmount;

        form &&
          form.setFieldsValue({
            [`${newLine.uniqueId}.${nameOf(InvoiceLineProxy.taxTotal.taxSubtotal![0].taxAmount.value)}`]: newLine.taxTotal.taxSubtotal[0].taxAmount.value
          });
      }

      lineTax.taxableAmount.value = newLine.lineExtensionAmount.value;

      form && form.setFieldsValue({ [paths.amount]: taxAmount });
      form && form.setFieldsValue({ [paths.percent]: taxPercent });
    }
  }

  const paths = {
    reasonCode: `${newLine.uniqueId}.${nameOf(InvoiceLineProxy.withholdingTaxTotal![0].taxSubtotal![0].taxCategory.taxScheme.taxTypeCode)}`,
    percent: `${newLine.uniqueId}.${nameOf(InvoiceLineProxy.withholdingTaxTotal![0].taxSubtotal![0].percent)}`,
    amount: `${newLine.uniqueId}.${nameOf(InvoiceLineProxy.withholdingTaxTotal![0].taxSubtotal![0].taxAmount.value)}`
  };

  if (newLine.withholdingTaxTotal && newLine.withholdingTaxTotal[0]) {
    const lineTax = newLine.withholdingTaxTotal[0].taxSubtotal[0];
    const kdv = newLine.taxTotal.taxSubtotal[0];

    const taxAmount = (((kdv.taxAmount.value || 0) * lineTax.percent) / 100).round(2);

    newLine.withholdingTaxTotal[0].taxAmount = { value: taxAmount };

    lineTax.taxableAmount.value = kdv.taxAmount.value || 0;
    lineTax.taxAmount.value = taxAmount;

    form && form.setFieldsValue({ [paths.amount]: taxAmount });
    form && form.setFieldsValue({ [paths.percent]: lineTax.percent });
  } else {
    form && form.setFieldsValue({ [paths.reasonCode]: '0' });
    form && form.setFieldsValue({ [paths.percent]: 0 });
    form && form.setFieldsValue({ [paths.amount]: 0 });
  }

  newLine.taxTotal.taxAmount.value = newLine.taxTotal.taxSubtotal.reduce((total, active) => (total += active.taxAmount.value || 0), 0).round(2);

  if (newLine.withholdingTaxTotal && newLine.withholdingTaxTotal[0]) {
    newLine.taxTotal.taxAmount.value = (newLine.taxTotal.taxAmount.value - (newLine.withholdingTaxTotal[0].taxAmount.value || 0)).round(2);
  }

  if (id && id !== 'taxIncludeAmount') {
    const defaultValue =
      (newLine.lineExtensionAmount?.value || 0) +
      (newLine?.taxTotal?.taxSubtotal?.find((x) => x.taxCategory?.taxScheme?.taxTypeCode === '0015')?.taxAmount?.value || 0);

    const formName = `${id.substring(0, id.indexOf('.'))}.taxIncludeAmount`;

    action.form && action.form.setFieldsValue({ [formName]: defaultValue });
  }

  return newLine;
});

invoiceEditorImmutableHelperContext.extend('$calculateMonetaryTotals', function (invoice: Invoice) {
  let lineAllowanceTotal = 0;
  let lineChargeTotal = 0;

  invoice.taxTotal = [{ taxAmount: { value: 0 }, taxSubtotal: [] }];
  invoice.withholdingTaxTotal = [];

  if (invoice.allowanceCharge === undefined) {
    invoice.allowanceCharge = [];
  }

  invoice.legalMonetaryTotal = {
    lineExtensionAmount: { value: 0 },
    payableAmount: { value: 0 },
    taxExclusiveAmount: { value: 0 },
    taxInclusiveAmount: { value: 0 },
    allowanceTotalAmount: { value: 0 },
    chargeTotalAmount: { value: 0 },
    payableRoundingAmount: undefined
  };

  invoice.invoiceLine!.forEach((invoiceLine) => {
    //Vergiler
    invoiceLine.taxTotal.taxSubtotal?.forEach((subTotal) => {
      const generalSubTotal = invoice.taxTotal![0].taxSubtotal.find(
        (x) => taxCode(x) === taxCode(subTotal) && x.percent === subTotal.percent && taxReasonCode(x) === taxReasonCode(subTotal)
      );

      if (generalSubTotal) {
        generalSubTotal.taxAmount.value = (generalSubTotal.taxAmount.value + subTotal.taxAmount.value).round(2);
        generalSubTotal.taxableAmount.value = (generalSubTotal.taxableAmount.value + subTotal.taxableAmount.value).round(2);
      } else {
        invoice.taxTotal![0].taxSubtotal.push(_.cloneDeep(subTotal));
      }
    });

    //Tevkifatlar
    if (invoiceLine.withholdingTaxTotal && invoiceLine.withholdingTaxTotal[0]) {
      invoiceLine.withholdingTaxTotal[0].taxSubtotal.forEach((subTotal) => {
        const taxTypeCode = taxCode(subTotal);
        if (!(taxTypeCode === '0' || taxTypeCode === '')) {
          //Initiliaze
          if (invoice.withholdingTaxTotal === undefined || (invoice.withholdingTaxTotal?.length || 0) === 0) {
            invoice.withholdingTaxTotal = [{ taxAmount: { value: 0 }, taxSubtotal: [] }];
          }

          const generalSubTotal = invoice.withholdingTaxTotal![0].taxSubtotal.find(
            (x) => taxCode(x) === taxTypeCode && x.percent === subTotal.percent && taxReasonCode(x) === taxReasonCode(subTotal)
          );

          if (generalSubTotal) {
            generalSubTotal.taxAmount.value = (generalSubTotal.taxAmount.value + subTotal.taxAmount.value).round(2);
            generalSubTotal.taxableAmount.value = (generalSubTotal.taxableAmount.value + subTotal.taxableAmount.value).round(2);
          } else {
            invoice.withholdingTaxTotal![0].taxSubtotal.push(_.cloneDeep(subTotal));
          }

          invoice.withholdingTaxTotal![0].taxAmount.value = (
            invoice.withholdingTaxTotal![0].taxAmount.value + invoiceLine.withholdingTaxTotal![0].taxAmount.value
          ).round(2);
        }
      });
    }

    //İskonto/Artırım genel toplamlar
    (invoiceLine.allowanceCharge || []).forEach((allowanceCharge) => {
      if (allowanceCharge.amount.value > 0) {
        if (allowanceCharge.chargeIndicator) {
          lineChargeTotal += allowanceCharge.amount.value;
        } else {
          lineAllowanceTotal += allowanceCharge.amount.value;
        }
      }
    });

    invoice.taxTotal![0].taxAmount.value += invoiceLine.taxTotal.taxAmount.value;

    invoice.legalMonetaryTotal.lineExtensionAmount.value += ((invoiceLine.price?.priceAmount?.value || 0) * (invoiceLine.invoicedQuantity?.value || 0)).round(
      2
    );

    invoice.legalMonetaryTotal.taxExclusiveAmount.value += invoiceLine.lineExtensionAmount?.value || 0;

    //Satır Mal/Hizmet toplamı ödenecek tutara ekleniyor.
    invoice.legalMonetaryTotal.payableAmount.value += invoiceLine.lineExtensionAmount?.value || 0;

    (invoiceLine.taxTotal?.taxSubtotal || []).forEach((taxSubTotal) => {
      const taxTypeCode = taxSubTotal!.taxCategory!.taxScheme!.taxTypeCode;
      const gibTax = TaxesWithKdv.find((x) => x.taxTypeCode === taxTypeCode);

      if (gibTax) {
        if (gibTax.isDecrease) {
          invoice.legalMonetaryTotal.payableAmount.value -= taxSubTotal.taxAmount.value;
        } else {
          invoice.legalMonetaryTotal.payableAmount.value += taxSubTotal.taxAmount.value;
        }
      }
    });
  });

  invoice.legalMonetaryTotal.allowanceTotalAmount = { value: Math.abs(lineChargeTotal - lineAllowanceTotal) };

  invoice.allowanceCharge.forEach((allowanceCharge) => {
    if ((allowanceCharge.amount?.value || 0) > 0) {
      if (allowanceCharge.allowanceChargeReason?.startsWith('HKS')) {
        invoice.legalMonetaryTotal.chargeTotalAmount!.value += allowanceCharge.amount.value;
      }
    }
  });

  invoice.legalMonetaryTotal.taxInclusiveAmount.value = 0;

  //Mal/Hizmet toplam tutarları ekleniyor
  invoice.legalMonetaryTotal.taxInclusiveAmount.value += invoice.legalMonetaryTotal.lineExtensionAmount.value;

  //İskontolar düşülüyor
  invoice.legalMonetaryTotal.taxInclusiveAmount.value -= lineAllowanceTotal;

  //Artırımlar ekleniyor
  invoice.legalMonetaryTotal.taxInclusiveAmount.value += lineChargeTotal;

  //Vergiler ekleniyor
  if (invoice.taxTotal) {
    invoice.taxTotal[0].taxSubtotal.forEach((taxSubTotal) => {
      const taxTypeCode = taxSubTotal!.taxCategory!.taxScheme!.taxTypeCode;
      const gibTax = TaxesWithKdv.find((x) => x.taxTypeCode === taxTypeCode && x.isDecrease !== true);

      if (gibTax) {
        //İlgili indirilecek bir değerse, ödenecek tutardan eksiltiliyor ve else bloğuna girmediği için vergilere de eklenmemiş oluyor. Örnek Stopajlar
        invoice.legalMonetaryTotal.taxInclusiveAmount.value += taxSubTotal.taxAmount.value;
      }
    });
  }

  invoice.taxTotal![0].taxAmount.value = invoice.taxTotal![0].taxAmount.value.round(2);

  if (invoice.invoiceTypeCode === 'IHRACKAYITLI') {
    const kdvTotal =
      invoice.taxTotal &&
      invoice.taxTotal[0].taxSubtotal
        .filter((x) => x.taxCategory?.taxScheme?.taxTypeCode === '0015')
        .reduce((sum, subTotal) => {
          return sum + (subTotal?.taxAmount.value || 0);
        }, 0);

    invoice.legalMonetaryTotal.payableAmount.value = invoice.legalMonetaryTotal.payableAmount.value - kdvTotal;
  }

  if (
    (invoice.profileID === 'EARSIVFATURA' && invoice.invoiceTypeCode === 'HKSKOMISYONCU') ||
    (invoice.profileID === 'HKS' && invoice.invoiceTypeCode === 'KOMISYONCU')
  ) {
    invoice.legalMonetaryTotal.payableAmount.value =
      invoice.legalMonetaryTotal.payableAmount.value - (invoice.legalMonetaryTotal.chargeTotalAmount?.value || 0);
  }

  //KDV Tevkifatı ekleniyor
  if (invoice.withholdingTaxTotal && invoice.withholdingTaxTotal[0]) {
    invoice.legalMonetaryTotal.payableAmount.value -= invoice.withholdingTaxTotal[0]?.taxAmount?.value || 0;
  }

  invoice.legalMonetaryTotal.payableAmount.value = invoice.legalMonetaryTotal.payableAmount.value.round(2);
  invoice.legalMonetaryTotal.lineExtensionAmount.value = invoice.legalMonetaryTotal.lineExtensionAmount.value.round(2);
  invoice.legalMonetaryTotal.taxExclusiveAmount.value = invoice.legalMonetaryTotal.taxExclusiveAmount.value.round(2);
  invoice.legalMonetaryTotal.taxInclusiveAmount.value = invoice.legalMonetaryTotal.taxInclusiveAmount.value.round(2);

  if (invoice.legalMonetaryTotal?.allowanceTotalAmount?.value) {
    invoice.legalMonetaryTotal.allowanceTotalAmount.value = invoice.legalMonetaryTotal.allowanceTotalAmount.value.round(2);
  }

  invoice.allowanceCharge?.map((allowanceCharge) => {
    allowanceCharge.amount.value = allowanceCharge.amount.value.round(2);
  });

  if (invoice.withholdingTaxTotal && invoice.withholdingTaxTotal.length > 0) {
    invoice.withholdingTaxTotal[0].taxAmount.value = invoice.withholdingTaxTotal[0].taxAmount.value.round(2);
  }

  if (invoice.freeExportInvoice) {
    invoice.legalMonetaryTotal.payableAmount.value = 0;
  }

  return setCurrencyCode(invoice, invoice.documentCurrencyCode.value || 'TRY');
});

export default invoiceEditorImmutableHelperContext;
