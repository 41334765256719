import { Spin } from 'antd';
import React from 'react';
import Loader from 'react-loaders';
import './style.scss';

export const Callback = () => {
  return (
    <div className="spin-container">
      <Spin indicator={<Loader active type="ball-clip-rotate-multiple" />} spinning={true} tip={`NES Portal'e bağlanıyor, lütfen bekleyiniz..`} />;
    </div>
  );
};
