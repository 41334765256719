import { InvoicesApiFactory, V1InvoicesLastGetRequest } from '@/apis/earchive';
import { Invoice } from '@/apis/earchive/interfaces';
import { PageStyle } from '@/constants/page';
import { DocumentPreviewModal, useDocumentModal, useGetPageData } from '@/hooks';
import { BlobResponse } from '@/services/api/constants';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton } from 'antd';
import classNames from 'classnames';
import React, { useImperativeHandle } from 'react';
import { CardHeader, UncontrolledAlert } from 'reactstrap';
import InvoiceListItem from './InvoiceListItem';

const api = InvoicesApiFactory();
const apiWithBlobResponse = InvoicesApiFactory(BlobResponse);

const LastCanceledInvoices = React.forwardRef((_, ref) => {
  const invoicePreview = useDocumentModal<string>(api.v1InvoicesUuidHtmlGet);

  const requestParameters: V1InvoicesLastGetRequest = { includeCanceledDocuments: false };

  const { execute, loading, data } = useGetPageData<Array<Invoice>>(api.v1InvoicesCanceledLastGet, requestParameters, true);

  useImperativeHandle(ref, () => ({
    refresh() {
      execute();
    }
  }));

  if (loading) {
    return <Skeleton active loading={loading} />;
  }

  return (
    <div className="card h-100">
      <DocumentPreviewModal
        sendMailApi={api.v1InvoicesEmailSendPost}
        {...invoicePreview.props}
        pdfDownloadApi={apiWithBlobResponse.v1InvoicesUuidPdfGet}
        setPrintedApi={api.v1InvoicesBulkOperationPut}
      />

      <CardHeader className={PageStyle.card__header}>
        <div className="d-flex align-items-center mr-auto">
          <FontAwesomeIcon icon={faPencil} style={{ marginRight: '10px' }} color="#0ab1fc" />
          <span className="font-weight-bold mr-3">Son İptal Edilen Faturalar</span>
        </div>
      </CardHeader>
      <div className={classNames('card-body p-0 overflow-auto', { 'align-items-center d-flex': (data || []).length === 0 })}>
        {data && data.length > 0 && (
          <ul className="list-unstyled">
            {data.map((item) => {
              return <InvoiceListItem key={item.id} item={item} onClickRow={(item) => invoicePreview.get(item.id, item.documentNumber)} />;
            })}
          </ul>
        )}

        {(data || []).length === 0 && (
          <UncontrolledAlert color="warning" className="w-100" fade={false} style={{ textAlign: 'center', margin: '10px 20px' }}>
            Kayıt yok
          </UncontrolledAlert>
        )}
      </div>
    </div>
  );
});

export default LastCanceledInvoices;
