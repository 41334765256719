// tslint:disable
// eslint:disable
/**
 * General API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccountInfoResponse } from '../interfaces';
// @ts-ignore
import { ModuleEnvelope } from '../interfaces';
/**
 * AccountModulesApi - axios parameter creator
 * @export
 */
export const AccountModulesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Detay :
         * @summary Zarfı imzalar
         * @param {any} file **XML Dosya**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AccountmodulesGetenvelopecontentPost: async (requestParameters: V1AccountmodulesGetenvelopecontentPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            if (requestParameters.file === null || requestParameters.file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling v1AccountmodulesGetenvelopecontentPost.');
            }
            const localVarPath = `/v1/accountmodules/getenvelopecontent`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


                if (requestParameters.file !== undefined) { 
                    localVarFormParams.append('File', requestParameters.file as any);
                }

    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detay :
         * @summary Zarfın detaylarını getirir.
         * @param {any} file **XML Dosya**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AccountmodulesGetenvelopeinfoPost: async (requestParameters: V1AccountmodulesGetenvelopeinfoPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            if (requestParameters.file === null || requestParameters.file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling v1AccountmodulesGetenvelopeinfoPost.');
            }
            const localVarPath = `/v1/accountmodules/getenvelopeinfo`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


                if (requestParameters.file !== undefined) { 
                    localVarFormParams.append('File', requestParameters.file as any);
                }

    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detay :
         * @summary Hesap bilgilerini ve işlem bekleyen zarfları döner
         * @param {any} file **XML Dosya**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AccountmodulesInfoPost: async (requestParameters: V1AccountmodulesInfoPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            if (requestParameters.file === null || requestParameters.file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling v1AccountmodulesInfoPost.');
            }
            const localVarPath = `/v1/accountmodules/info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


                if (requestParameters.file !== undefined) { 
                    localVarFormParams.append('File', requestParameters.file as any);
                }

    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detay :
         * @summary Zarfı imzalar
         * @param {any} file **XML Dosya**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AccountmodulesSetsignedcontentPost: async (requestParameters: V1AccountmodulesSetsignedcontentPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            if (requestParameters.file === null || requestParameters.file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling v1AccountmodulesSetsignedcontentPost.');
            }
            const localVarPath = `/v1/accountmodules/setsignedcontent`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


                if (requestParameters.file !== undefined) { 
                    localVarFormParams.append('File', requestParameters.file as any);
                }

    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detay :
         * @summary Hesap bilgilerini günceller
         * @param {any} file **XML Dosya**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AccountmodulesUpdatePost: async (requestParameters: V1AccountmodulesUpdatePostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            if (requestParameters.file === null || requestParameters.file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling v1AccountmodulesUpdatePost.');
            }
            const localVarPath = `/v1/accountmodules/update`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


                if (requestParameters.file !== undefined) { 
                    localVarFormParams.append('File', requestParameters.file as any);
                }

    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountModulesApi - functional programming interface
 * @export
 */
export const AccountModulesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Detay :
         * @summary Zarfı imzalar
         * @param {any} file **XML Dosya**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AccountmodulesGetenvelopecontentPost(requestParameters: V1AccountmodulesGetenvelopecontentPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountModulesApiAxiosParamCreator(configuration).v1AccountmodulesGetenvelopecontentPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Detay :
         * @summary Zarfın detaylarını getirir.
         * @param {any} file **XML Dosya**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AccountmodulesGetenvelopeinfoPost(requestParameters: V1AccountmodulesGetenvelopeinfoPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModuleEnvelope>> {
            const localVarAxiosArgs = await AccountModulesApiAxiosParamCreator(configuration).v1AccountmodulesGetenvelopeinfoPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Detay :
         * @summary Hesap bilgilerini ve işlem bekleyen zarfları döner
         * @param {any} file **XML Dosya**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AccountmodulesInfoPost(requestParameters: V1AccountmodulesInfoPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountInfoResponse>> {
            const localVarAxiosArgs = await AccountModulesApiAxiosParamCreator(configuration).v1AccountmodulesInfoPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Detay :
         * @summary Zarfı imzalar
         * @param {any} file **XML Dosya**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AccountmodulesSetsignedcontentPost(requestParameters: V1AccountmodulesSetsignedcontentPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountModulesApiAxiosParamCreator(configuration).v1AccountmodulesSetsignedcontentPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Detay :
         * @summary Hesap bilgilerini günceller
         * @param {any} file **XML Dosya**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AccountmodulesUpdatePost(requestParameters: V1AccountmodulesUpdatePostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountModulesApiAxiosParamCreator(configuration).v1AccountmodulesUpdatePost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AccountModulesApi - factory interface
 * @export
 */
export const AccountModulesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Detay :
         * @summary Zarfı imzalar
         * @param {any} file **XML Dosya**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AccountmodulesGetenvelopecontentPost(requestParameters: V1AccountmodulesGetenvelopecontentPostRequest, options?: any): AxiosPromise<void> {
            return AccountModulesApiFp(configuration).v1AccountmodulesGetenvelopecontentPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Detay :
         * @summary Zarfın detaylarını getirir.
         * @param {any} file **XML Dosya**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AccountmodulesGetenvelopeinfoPost(requestParameters: V1AccountmodulesGetenvelopeinfoPostRequest, options?: any): AxiosPromise<ModuleEnvelope> {
            return AccountModulesApiFp(configuration).v1AccountmodulesGetenvelopeinfoPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Detay :
         * @summary Hesap bilgilerini ve işlem bekleyen zarfları döner
         * @param {any} file **XML Dosya**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AccountmodulesInfoPost(requestParameters: V1AccountmodulesInfoPostRequest, options?: any): AxiosPromise<AccountInfoResponse> {
            return AccountModulesApiFp(configuration).v1AccountmodulesInfoPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Detay :
         * @summary Zarfı imzalar
         * @param {any} file **XML Dosya**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AccountmodulesSetsignedcontentPost(requestParameters: V1AccountmodulesSetsignedcontentPostRequest, options?: any): AxiosPromise<void> {
            return AccountModulesApiFp(configuration).v1AccountmodulesSetsignedcontentPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Detay :
         * @summary Hesap bilgilerini günceller
         * @param {any} file **XML Dosya**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AccountmodulesUpdatePost(requestParameters: V1AccountmodulesUpdatePostRequest, options?: any): AxiosPromise<void> {
            return AccountModulesApiFp(configuration).v1AccountmodulesUpdatePost(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountModulesApi - object-oriented interface
 * @export
 * @class AccountModulesApi
 * @extends {BaseAPI}
 */
export class AccountModulesApi extends BaseAPI {
    /**
     * Detay :
     * @summary Zarfı imzalar
     * @param {any} file **XML Dosya**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountModulesApi
     */
    public v1AccountmodulesGetenvelopecontentPost(requestParameters: V1AccountmodulesGetenvelopecontentPostRequest, options?: any) {
        return AccountModulesApiFp(this.configuration).v1AccountmodulesGetenvelopecontentPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Detay :
     * @summary Zarfın detaylarını getirir.
     * @param {any} file **XML Dosya**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountModulesApi
     */
    public v1AccountmodulesGetenvelopeinfoPost(requestParameters: V1AccountmodulesGetenvelopeinfoPostRequest, options?: any) {
        return AccountModulesApiFp(this.configuration).v1AccountmodulesGetenvelopeinfoPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Detay :
     * @summary Hesap bilgilerini ve işlem bekleyen zarfları döner
     * @param {any} file **XML Dosya**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountModulesApi
     */
    public v1AccountmodulesInfoPost(requestParameters: V1AccountmodulesInfoPostRequest, options?: any) {
        return AccountModulesApiFp(this.configuration).v1AccountmodulesInfoPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Detay :
     * @summary Zarfı imzalar
     * @param {any} file **XML Dosya**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountModulesApi
     */
    public v1AccountmodulesSetsignedcontentPost(requestParameters: V1AccountmodulesSetsignedcontentPostRequest, options?: any) {
        return AccountModulesApiFp(this.configuration).v1AccountmodulesSetsignedcontentPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Detay :
     * @summary Hesap bilgilerini günceller
     * @param {any} file **XML Dosya**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountModulesApi
     */
    public v1AccountmodulesUpdatePost(requestParameters: V1AccountmodulesUpdatePostRequest, options?: any) {
        return AccountModulesApiFp(this.configuration).v1AccountmodulesUpdatePost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1AccountmodulesGetenvelopecontentPostRequest {
    /**
     * **XML Dosya**
     */
    file: any;
    }
    export interface V1AccountmodulesGetenvelopeinfoPostRequest {
    /**
     * **XML Dosya**
     */
    file: any;
    }
    export interface V1AccountmodulesInfoPostRequest {
    /**
     * **XML Dosya**
     */
    file: any;
    }
    export interface V1AccountmodulesSetsignedcontentPostRequest {
    /**
     * **XML Dosya**
     */
    file: any;
    }
    export interface V1AccountmodulesUpdatePostRequest {
    /**
     * **XML Dosya**
     */
    file: any;
    }
