import Amount from '@/components/Amount';
import { PageConst, PageStyle } from '@/constants/page';
import InvoiceEditorContext from '@/contexts/InvoiceEditorContext';
import { faSigma } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Col, Form, Row } from 'antd';
import update from 'immutability-helper';
import React, { useContext } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

export enum ExpenseType {
  HKSKOMISYON = 'HKSKOMISYON',
  HKSKOMISYONKDV = 'HKSKOMISYONKDV',
  HKSNAVLUN = 'HKSNAVLUN',
  HKSNAVLUNKDV = 'HKSNAVLUNKDV',
  HKSHAMMALIYE = 'HKSHAMMALIYE',
  HKSHAMMALIYEKDV = 'HKSHAMMALIYEKDV',
  HKSNAKLIYE = 'HKSNAKLIYE',
  HKSNAKLIYEKDV = 'HKSNAKLIYEKDV',
  HKSGVTEVKIFAT = 'HKSGVTEVKIFAT',
  HKSBAGKURTEVKIFAT = 'HKSBAGKURTEVKIFAT',
  HKSRUSUM = 'HKSRUSUM',
  HKSRUSUMKDV = 'HKSRUSUMKDV',
  HKSTICBORSASI = 'HKSTICBORSASI',
  HKSTICBORSASIKDV = 'HKSTICBORSASIKDV',
  HKSMILLISAVUNMAFON = 'HKSMILLISAVUNMAFON',
  HKSMSFONKDV = 'HKSMSFONKDV',
  HKSDIGERMASRAFLAR = 'HKSDIGERMASRAFLAR',
  HKSDIGERKDV = 'HKSDIGERKDV'
}

export const HKSExpenses = () => {
  const [{ invoice }, dispatch] = useContext(InvoiceEditorContext);

  const [form] = Form.useForm();

  const setExpense = (expenseType: ExpenseType, value: number, type: 'Amount' | 'Percent') => {
    const index = invoice.allowanceCharge && invoice.allowanceCharge.findIndex((x) => x.allowanceChargeReason === expenseType);

    if ((index === undefined || index === -1) && (value === undefined || value === 0)) {
      return false;
    }

    if (invoice.allowanceCharge === undefined) {
      invoice.allowanceCharge = [];
    }

    dispatch({
      type: 'SET_ALLOWANCE_CHARGES',
      value: {
        ...invoice,
        allowanceCharge: update(
          invoice.allowanceCharge,
          index === undefined || index === -1
            ? {
                $push: [
                  {
                    chargeIndicator: false,
                    allowanceChargeReason: expenseType,
                    multiplierFactorNumeric: type === 'Amount' ? 0 : value,
                    amount: { value: type === 'Amount' ? value : 0 },
                    calculationField: 'Amount',
                    percent: 0,
                    uniqueId: uuidv4()
                  }
                ]
              }
            : value === undefined || value === 0 //boş, undefined veya selectlerdeki defaultValue olan 0 ise
            ? { $splice: [[index, 1]] }
            : {
                [index]: {
                  $set: {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    ...invoice.allowanceCharge![index],
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    amount: { ...invoice.allowanceCharge![index].amount, value: type === 'Amount' ? value : invoice.allowanceCharge![index].amount.value },
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    multiplierFactorNumeric: type === 'Amount' ? invoice.allowanceCharge![index].multiplierFactorNumeric : value
                  }
                }
              }
        )
      }
    });
  };

  const getAllowanceCharge = (expenseType: ExpenseType) => {
    return invoice.allowanceCharge?.find((x) => x.allowanceChargeReason === expenseType);
  };

  const formId = (expenseType: ExpenseType, type: 'Amount' | 'Percent') => `${expenseType}.${type === 'Amount' ? 'amount' : 'multiplierFactorNumeric'}`;

  const ExpenseElement = ({ title, expenseType, type }: { title: string; expenseType: ExpenseType; type: 'Amount' | 'Percent' }) => {
    const formElementId = formId(expenseType, type);

    const $allowanceCharge = getAllowanceCharge(expenseType);

    const initialValue = (type === 'Amount' ? $allowanceCharge?.amount?.value : $allowanceCharge?.multiplierFactorNumeric) || 0;

    return (
      <Form.Item
        label={title}
        name={formElementId}
        initialValue={initialValue}
        shouldUpdate={(prevValues, curValues) => prevValues[formElementId] !== curValues[formElementId]}
      >
        <Amount
          precision={4}
          max={type === 'Percent' ? 100 : 999999999999}
          onBlur={(value) => value !== initialValue && setExpense(expenseType, value, type)}
        />
      </Form.Item>
    );
  };

  return (
    <Card>
      <CardHeader className={PageStyle.card__header}>
        <div>
          {<FontAwesomeIcon icon={faSigma} color="#0ab1fc" style={{ marginRight: '10px' }} />}
          <b>HKS Komisyoncu - Masraflar</b>
        </div>
      </CardHeader>
      <CardBody>
        <Alert
          message={
            <span>
              <b>Bilgilendirme : </b>
              Fatura tasarımınızın güncel olmamasından dolayı, fatura üzerinde bu bilgiler görünmezse bize ulaşarak (
              <a href="mailto:destek@nesbilgi.com.tr">
                <u>destek@nesbilgi.com.tr</u>
              </a>
              ) tasarımınızı güncellettirmeniz gerekmektedir
            </span>
          }
          type="info"
          className="mb-3"
          showIcon
          closable
        />

        <Form layout="vertical" form={form}>
          <Row gutter={[PageConst.EditorColGutter, PageConst.EditorColGutter]}>
            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Komisyon %" expenseType={ExpenseType.HKSKOMISYON} type="Percent" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Komisyon Tutar" expenseType={ExpenseType.HKSKOMISYON} type="Amount" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Komisyon KDV %" expenseType={ExpenseType.HKSKOMISYONKDV} type="Percent" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Komisyon KDV Tutar" expenseType={ExpenseType.HKSKOMISYONKDV} type="Amount" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Navlun %" expenseType={ExpenseType.HKSNAVLUN} type="Percent" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Navlun Tutar" expenseType={ExpenseType.HKSNAVLUN} type="Amount" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Navlun KDV %" expenseType={ExpenseType.HKSNAVLUNKDV} type="Percent" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Navlun KDV Tutar" expenseType={ExpenseType.HKSNAVLUNKDV} type="Amount" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Hammaliye %" expenseType={ExpenseType.HKSHAMMALIYE} type="Percent" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Hammaliye Tutar" expenseType={ExpenseType.HKSHAMMALIYE} type="Amount" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Hammaliye KDV %" expenseType={ExpenseType.HKSHAMMALIYEKDV} type="Percent" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Hammaliye KDV Tutar" expenseType={ExpenseType.HKSHAMMALIYEKDV} type="Amount" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Nakliye %" expenseType={ExpenseType.HKSNAKLIYE} type="Percent" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Nakliye Tutar" expenseType={ExpenseType.HKSNAKLIYE} type="Amount" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Nakliye KDV %" expenseType={ExpenseType.HKSNAKLIYEKDV} type="Percent" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Nakliye KDV Tutar" expenseType={ExpenseType.HKSNAKLIYEKDV} type="Amount" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Gelir V. Tevkifatı %" expenseType={ExpenseType.HKSGVTEVKIFAT} type="Percent" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Gelir V. Tevkifatı Tutar" expenseType={ExpenseType.HKSGVTEVKIFAT} type="Amount" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Bağkur Tevkifatı %" expenseType={ExpenseType.HKSBAGKURTEVKIFAT} type="Percent" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Bağkur Tevkifatı Tutar" expenseType={ExpenseType.HKSBAGKURTEVKIFAT} type="Amount" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Hal Rüsumu %" expenseType={ExpenseType.HKSRUSUM} type="Percent" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Hal Rüsumu Tutar" expenseType={ExpenseType.HKSRUSUM} type="Amount" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Hal Rüsumu KDV %" expenseType={ExpenseType.HKSRUSUMKDV} type="Percent" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Hal Rüsumu KDV Tutar" expenseType={ExpenseType.HKSRUSUMKDV} type="Amount" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Tic. Borsası %" expenseType={ExpenseType.HKSTICBORSASI} type="Percent" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Tic. Borsası Tutar" expenseType={ExpenseType.HKSTICBORSASI} type="Amount" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Tic. Borsası KDV %" expenseType={ExpenseType.HKSTICBORSASIKDV} type="Percent" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Tic. Borsası KDV Tutar" expenseType={ExpenseType.HKSTICBORSASIKDV} type="Amount" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Milli S. Fonu %" expenseType={ExpenseType.HKSMILLISAVUNMAFON} type="Percent" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Milli S. Fonu Tutar" expenseType={ExpenseType.HKSMILLISAVUNMAFON} type="Amount" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Milli S. Fonu KDV %" expenseType={ExpenseType.HKSMSFONKDV} type="Percent" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Milli S. Fonu KDV Tutar" expenseType={ExpenseType.HKSMSFONKDV} type="Amount" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Diğer Masraflar %" expenseType={ExpenseType.HKSDIGERMASRAFLAR} type="Percent" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Diğer Masraflar Tutar" expenseType={ExpenseType.HKSDIGERMASRAFLAR} type="Amount" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Diğer Masraflar KDV %" expenseType={ExpenseType.HKSDIGERKDV} type="Percent" />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ExpenseElement title="Diğer Masraflar KDV Tutar" expenseType={ExpenseType.HKSDIGERKDV} type="Amount" />
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

export default HKSExpenses;
