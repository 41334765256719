import { RootSpinner } from '@/components';
import React, { memo } from 'react';

export const Loading = memo(({ tip }: { tip?: string }) => (
  <div className="table__body__center">
    <RootSpinner tip={tip} loading />
  </div>
));

export const TableDefaultLoading = (loading: boolean) => {
  return {
    spinning: loading,
    indicator: <Loading />
  };
};

export default Loading;
