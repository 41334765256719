import { OnClickProps } from '@/interfaces';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuItem from 'antd/lib/menu/MenuItem';
import React from 'react';
import intl from 'react-intl-universal';

export const Delete = (props: OnClickProps) => {
  return (
    <MenuItem {...props}>
      <FontAwesomeIcon className="mr-2" icon={faTrashAlt} />
      {props.title || intl.get('SIL')}
    </MenuItem>
  );
};

export default Delete;
