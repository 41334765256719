// tslint:disable
// eslint:disable
/**
 * General API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AnnouncementDashboardDto } from '../interfaces';
// @ts-ignore
import { SetAnnouncementFeedbackCommand } from '../interfaces';
/**
 * AnnouncementsApi - axios parameter creator
 * @export
 */
export const AnnouncementsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} announcementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AnnouncementsAnnouncementIdSetreadedPost: async (requestParameters: V1AnnouncementsAnnouncementIdSetreadedPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'announcementId' is not null or undefined
            if (requestParameters.announcementId === null || requestParameters.announcementId === undefined) {
                throw new RequiredError('announcementId','Required parameter announcementId was null or undefined when calling v1AnnouncementsAnnouncementIdSetreadedPost.');
            }
            const localVarPath = `/v1/announcements/{announcementId}/setreaded`.replace(`{${"announcementId"}}`, encodeURIComponent(String(requestParameters.announcementId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetAnnouncementFeedbackCommand} [setAnnouncementFeedbackCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AnnouncementsFeedbackPost: async (requestParameters: V1AnnouncementsFeedbackPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/announcements/feedback`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.setAnnouncementFeedbackCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.setAnnouncementFeedbackCommand !== undefined ? requestParameters.setAnnouncementFeedbackCommand : {}) : (requestParameters.setAnnouncementFeedbackCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AnnouncementsIdAttachmentAttachmentIdGet: async (requestParameters: V1AnnouncementsIdAttachmentAttachmentIdGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1AnnouncementsIdAttachmentAttachmentIdGet.');
            }
            // verify required parameter 'attachmentId' is not null or undefined
            if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
                throw new RequiredError('attachmentId','Required parameter attachmentId was null or undefined when calling v1AnnouncementsIdAttachmentAttachmentIdGet.');
            }
            const localVarPath = `/v1/announcements/{id}/attachment/{attachmentId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AnnouncementsIdCoverimageGet: async (requestParameters: V1AnnouncementsIdCoverimageGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1AnnouncementsIdCoverimageGet.');
            }
            const localVarPath = `/v1/announcements/{id}/coverimage`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [isDevelopmentAnnouncement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AnnouncementsLastGet: async (requestParameters: V1AnnouncementsLastGetRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/announcements/last`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.isDevelopmentAnnouncement !== undefined) {
                localVarQueryParameter['isDevelopmentAnnouncement'] = requestParameters.isDevelopmentAnnouncement;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnouncementsApi - functional programming interface
 * @export
 */
export const AnnouncementsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} announcementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AnnouncementsAnnouncementIdSetreadedPost(requestParameters: V1AnnouncementsAnnouncementIdSetreadedPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AnnouncementsApiAxiosParamCreator(configuration).v1AnnouncementsAnnouncementIdSetreadedPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SetAnnouncementFeedbackCommand} [setAnnouncementFeedbackCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AnnouncementsFeedbackPost(requestParameters: V1AnnouncementsFeedbackPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AnnouncementsApiAxiosParamCreator(configuration).v1AnnouncementsFeedbackPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AnnouncementsIdAttachmentAttachmentIdGet(requestParameters: V1AnnouncementsIdAttachmentAttachmentIdGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await AnnouncementsApiAxiosParamCreator(configuration).v1AnnouncementsIdAttachmentAttachmentIdGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AnnouncementsIdCoverimageGet(requestParameters: V1AnnouncementsIdCoverimageGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await AnnouncementsApiAxiosParamCreator(configuration).v1AnnouncementsIdCoverimageGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {boolean} [isDevelopmentAnnouncement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AnnouncementsLastGet(requestParameters: V1AnnouncementsLastGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnnouncementDashboardDto>>> {
            const localVarAxiosArgs = await AnnouncementsApiAxiosParamCreator(configuration).v1AnnouncementsLastGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AnnouncementsApi - factory interface
 * @export
 */
export const AnnouncementsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} announcementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AnnouncementsAnnouncementIdSetreadedPost(requestParameters: V1AnnouncementsAnnouncementIdSetreadedPostRequest, options?: any): AxiosPromise<void> {
            return AnnouncementsApiFp(configuration).v1AnnouncementsAnnouncementIdSetreadedPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetAnnouncementFeedbackCommand} [setAnnouncementFeedbackCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AnnouncementsFeedbackPost(requestParameters: V1AnnouncementsFeedbackPostRequest, options?: any): AxiosPromise<void> {
            return AnnouncementsApiFp(configuration).v1AnnouncementsFeedbackPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AnnouncementsIdAttachmentAttachmentIdGet(requestParameters: V1AnnouncementsIdAttachmentAttachmentIdGetRequest, options?: any): AxiosPromise<string> {
            return AnnouncementsApiFp(configuration).v1AnnouncementsIdAttachmentAttachmentIdGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AnnouncementsIdCoverimageGet(requestParameters: V1AnnouncementsIdCoverimageGetRequest, options?: any): AxiosPromise<string> {
            return AnnouncementsApiFp(configuration).v1AnnouncementsIdCoverimageGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [isDevelopmentAnnouncement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AnnouncementsLastGet(requestParameters: V1AnnouncementsLastGetRequest, options?: any): AxiosPromise<Array<AnnouncementDashboardDto>> {
            return AnnouncementsApiFp(configuration).v1AnnouncementsLastGet(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnnouncementsApi - object-oriented interface
 * @export
 * @class AnnouncementsApi
 * @extends {BaseAPI}
 */
export class AnnouncementsApi extends BaseAPI {
    /**
     * 
     * @param {string} announcementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsApi
     */
    public v1AnnouncementsAnnouncementIdSetreadedPost(requestParameters: V1AnnouncementsAnnouncementIdSetreadedPostRequest, options?: any) {
        return AnnouncementsApiFp(this.configuration).v1AnnouncementsAnnouncementIdSetreadedPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetAnnouncementFeedbackCommand} [setAnnouncementFeedbackCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsApi
     */
    public v1AnnouncementsFeedbackPost(requestParameters: V1AnnouncementsFeedbackPostRequest, options?: any) {
        return AnnouncementsApiFp(this.configuration).v1AnnouncementsFeedbackPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} attachmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsApi
     */
    public v1AnnouncementsIdAttachmentAttachmentIdGet(requestParameters: V1AnnouncementsIdAttachmentAttachmentIdGetRequest, options?: any) {
        return AnnouncementsApiFp(this.configuration).v1AnnouncementsIdAttachmentAttachmentIdGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsApi
     */
    public v1AnnouncementsIdCoverimageGet(requestParameters: V1AnnouncementsIdCoverimageGetRequest, options?: any) {
        return AnnouncementsApiFp(this.configuration).v1AnnouncementsIdCoverimageGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [isDevelopmentAnnouncement] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsApi
     */
    public v1AnnouncementsLastGet(requestParameters: V1AnnouncementsLastGetRequest, options?: any) {
        return AnnouncementsApiFp(this.configuration).v1AnnouncementsLastGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1AnnouncementsAnnouncementIdSetreadedPostRequest {
    /**
     * 
     */
    announcementId: string;
    }
    export interface V1AnnouncementsFeedbackPostRequest {
    /**
     * 
     */
    setAnnouncementFeedbackCommand?: SetAnnouncementFeedbackCommand;
    }
    export interface V1AnnouncementsIdAttachmentAttachmentIdGetRequest {
    /**
     * 
     */
    id: string;
    /**
     * 
     */
    attachmentId: string;
    }
    export interface V1AnnouncementsIdCoverimageGetRequest {
    /**
     * 
     */
    id: string;
    }
    export interface V1AnnouncementsLastGetRequest {
    /**
     * 
     */
    isDevelopmentAnnouncement?: boolean;
    }
