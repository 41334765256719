import { DailyStatistics } from '@/apis/general/interfaces';
import { PageStyle } from '@/constants/page';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton, Tabs } from 'antd';
import React, { useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Pie from '../Components/Pie';

export const AnswerStats = ({
  data,
  flattenData,
  loading,
  startDate,
  endDate
}: {
  data?: Array<DailyStatistics>;
  flattenData: any;
  loading: boolean;
  startDate: string;
  endDate: string;
}) => {
  if (loading) {
    return <Skeleton active loading={loading} />;
  }

  const [activeTab, setActiveTab] = useState<'incoming' | 'outgoing'>('incoming');

  return (
    <Card className="h-100 kit__utils__cardMarked kit__utils__cardMarked--info">
      <CardHeader className={PageStyle.card__header}>
        <div className="d-flex align-items-center mr-auto">
          <FontAwesomeIcon icon={faPencil} style={{ marginRight: '10px' }} color="#0ab1fc" />
          <span className="font-weight-bold mr-3">Cevaplar</span>
        </div>
        <Tabs defaultActiveKey={activeTab} onChange={(value: any) => setActiveTab(value)} className="air-tabs tabbed-card" style={{ marginBottom: '0px' }}>
          <Tabs.TabPane tab="Gelen" key="incoming" />
          <Tabs.TabPane tab="Giden" key="outgoing" />
        </Tabs>
      </CardHeader>
      <CardBody style={{ padding: '10px 20px' }} className={'overflow-auto align-items-center d-flex'}>
        {activeTab === 'incoming' && (
          <Pie
            data={
              flattenData['EI:I:A:A'] || flattenData['EI:I:A:R'] || flattenData['EI:I:A:W']
                ? [
                    {
                      color: '#41b883',
                      label: 'Kabul Edilen',
                      value: flattenData['EI:I:A:A'] || 0,
                      url: `/einvoice/incoming/list?startDate=${startDate}&endDate=${endDate}&profileId=TICARIFATURA&documentAnswer=Accepted`
                    },
                    {
                      color: '#dc3545',
                      label: 'Reddedilen',
                      value: flattenData['EI:I:A:R'] || 0,
                      url: `/einvoice/incoming/list?startDate=${startDate}&endDate=${endDate}&profileId=TICARIFATURA&documentAnswer=Rejected`
                    },
                    {
                      color: '#ffc107',
                      label: 'Cevap Bekleyen',
                      value: flattenData['EI:I:A:W'] || 0,
                      url: `/einvoice/incoming/list?startDate=${startDate}&endDate=${endDate}&profileId=TICARIFATURA&documentAnswer=Waiting`
                    }
                  ]
                : []
            }
          />
        )}
        {activeTab === 'outgoing' && (
          <Pie
            data={
              flattenData['EI:O:A:A'] || flattenData['EI:O:A:R'] || flattenData['EI:O:A:W']
                ? [
                    {
                      color: '#41b883',
                      label: 'Kabul Edilen',
                      value: flattenData['EI:O:A:A'] || 0,
                      url: `/einvoice/outgoing/list?startDate=${startDate}&endDate=${endDate}&profileId=TICARIFATURA&documentAnswer=Accepted`
                    },
                    {
                      color: '#dc3545',
                      label: 'Reddedilen',
                      value: flattenData['EI:O:A:R'] || 0,
                      url: `/einvoice/outgoing/list?startDate=${startDate}&endDate=${endDate}&profileId=TICARIFATURA&documentAnswer=Rejected`
                    },
                    {
                      color: '#ffc107',
                      label: 'Cevap Bekleyen',
                      value: flattenData['EI:O:A:W'] || 0,
                      url: `/einvoice/outgoing/list?startDate=${startDate}&endDate=${endDate}&profileId=TICARIFATURA&documentAnswer=Waiting`
                    }
                  ]
                : []
            }
          />
        )}
      </CardBody>
    </Card>
  );
};

export default AnswerStats;
