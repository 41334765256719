import { ButtonProps } from '@/interfaces';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React, { CSSProperties } from 'react';
import { Button } from 'reactstrap';

export const IconButton = ({
  title,
  style,
  disabled,
  onClick,
  size = 'sm',
  onlyIcon,
  color,
  icon
}: ButtonProps & { icon: IconProp; style?: CSSProperties }) => {
  return (
    <Button disabled={disabled} size={size} style={style} key="btn-search" onClick={onClick} color={color} className="btn-labeled">
      <FontAwesomeIcon icon={icon} className={classnames({ 'btn-label': !onlyIcon })} />
      {!onlyIcon && <span>{title}</span>}
    </Button>
  );
};

export default IconButton;
