import { enUS, trTR } from '@/constants/locales';
import { ConfigProvider } from 'antd';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
const locales = {
  'tr-TR': trTR,
  'en-US': enUS
};

export class Localization extends Component<{ locale; children }> {
  loadLocales(locale) {
    intl.init({
      currentLocale: locale,
      locales,
      warningHandler: () => {
        console.log('missing_intl_key');
      }
    });
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps: { locale: string }) {
    const locale = this.props.locale;
    const localeNew = nextProps.locale;
    if (locale !== localeNew) {
      this.loadLocales(localeNew);
    }
  }

  componentDidMount = () => {
    const locale = this.props.locale;
    this.loadLocales(locale);
  };

  currentLocale = locales[this.props.locale];

  render() {
    return <ConfigProvider locale={this.currentLocale.antdData}>{this.props.children}</ConfigProvider>;
  }
}

export default Localization;
