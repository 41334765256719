const EINVOICE_API_ENDPOINT: string = (window as any).EINVOICE_API_ENDPOINT || '';
const EARCHIVE_API_ENDPOINT: string = (window as any).EARCHIVE_API_ENDPOINT || '';
const EDESPATCH_API_ENDPOINT: string = (window as any).EDESPATCH_API_ENDPOINT || '';
const ESMM_API_ENDPOINT: string = (window as any).ESMM_API_ENDPOINT || '';
const EMM_API_ENDPOINT: string = (window as any).EMM_API_ENDPOINT || '';
const GENERAL_API_ENDPOINT: string = (window as any).GENERAL_API_ENDPOINT || '';
const IDENTITY_SERVER_AUTHORITY_URL: string = (window as any).IDENTITY_SERVER_AUTHORITY_URL || '';
const IDENTITY_CLIENT_ID: string = (window as any).IDENTITY_CLIENT_ID || '';
const IDENTITY_ISSUER: string = (window as any).IDENTITY_ISSUER || '';
const APP_BASE_URL: string = (window as any).APP_BASE_URL || '';
const APP_REDIRECT_URL: string = (window as any).APP_REDIRECT_URL || '';
const IDENTITY_SCOPE: string = (window as any).IDENTITY_SCOPE || '';
const SILENT_REDIRECT_URL: string = (window as any).SILENT_REDIRECT_URL || '';
const WORKING_ENVIRONMENT: string = (window as any).WORKING_ENVIRONMENT || '';
const DOCUMENT_PREVIEW_BASE_URL: string = (window as any).DOCUMENT_PREVIEW_BASE_URL || '';
const DEALER_API_ENDPOINT: string = (window as any).DEALER_API_ENDPOINT || '';
const PAYMENT_PORTAL_ENVIRONMENT: string = (window as any).PAYMENT_PORTAL_ENVIRONMENT || '';

export {
  EINVOICE_API_ENDPOINT,
  EARCHIVE_API_ENDPOINT,
  EDESPATCH_API_ENDPOINT,
  ESMM_API_ENDPOINT,
  EMM_API_ENDPOINT,
  GENERAL_API_ENDPOINT,
  IDENTITY_SERVER_AUTHORITY_URL,
  IDENTITY_CLIENT_ID,
  IDENTITY_ISSUER,
  APP_BASE_URL,
  APP_REDIRECT_URL,
  IDENTITY_SCOPE,
  SILENT_REDIRECT_URL,
  WORKING_ENVIRONMENT,
  DOCUMENT_PREVIEW_BASE_URL,
  DEALER_API_ENDPOINT,
  PAYMENT_PORTAL_ENVIRONMENT
};
