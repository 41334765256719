// tslint:disable
// eslint:disable
/**
 * General API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EArchiveDocumentsReportDivision } from '../interfaces';
// @ts-ignore
import { EArchiveDocumentsReportDivisionContent } from '../interfaces';
// @ts-ignore
import { History } from '../interfaces';
// @ts-ignore
import { Month } from '../interfaces';
// @ts-ignore
import { UnReportedEArchiveDocument } from '../interfaces';
/**
 * EArchiveDocumentsReportApi - axios parameter creator
 * @export
 */
export const EArchiveDocumentsReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Detay : Rapor parçasının içeriğine bu api ucu ile ulaşabilirsiniz
         * @summary Rapor parçasının içeriğini listeler
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1EarchivedocumentsreportIdDetailsGet: async (requestParameters: V1EarchivedocumentsreportIdDetailsGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1EarchivedocumentsreportIdDetailsGet.');
            }
            const localVarPath = `/v1/earchivedocumentsreport/{id}/details`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detay : Rapor parçasının içeriğini excel olarak dışarı aktarmak için bu api ucu kullanılabilir
         * @summary Rapor parçasının içeriğini excel olarak dışarı aktarır
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1EarchivedocumentsreportIdExcelGet: async (requestParameters: V1EarchivedocumentsreportIdExcelGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1EarchivedocumentsreportIdExcelGet.');
            }
            const localVarPath = `/v1/earchivedocumentsreport/{id}/excel`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detay : Rapor parçası geçmişine bu api ucu ile ulaşabilirsiniz
         * @summary Rapor parçası geçmişini listeler
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1EarchivedocumentsreportIdHistoriesGet: async (requestParameters: V1EarchivedocumentsreportIdHistoriesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1EarchivedocumentsreportIdHistoriesGet.');
            }
            const localVarPath = `/v1/earchivedocumentsreport/{id}/histories`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detay : Rapor parçasının xml dosyasını indirmek için bu uç kullanılabilir
         * @summary Rapor parçasının xml dosyasını indirir
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1EarchivedocumentsreportIdXmlGet: async (requestParameters: V1EarchivedocumentsreportIdXmlGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1EarchivedocumentsreportIdXmlGet.');
            }
            const localVarPath = `/v1/earchivedocumentsreport/{id}/xml`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detay : Henüz raporlanmamış e-arşiv belgelerin listesini bu uç ile alabilirsiniz              Başlangıç ve bitiş tarihi
         * @summary Henüz raporlanmamış e-arşiv belgeleri listeler
         * @param {string} issueDate Raporlanmasını istediğiniz belgenin tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1EarchivedocumentsreportUnreportedGet: async (requestParameters: V1EarchivedocumentsreportUnreportedGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'issueDate' is not null or undefined
            if (requestParameters.issueDate === null || requestParameters.issueDate === undefined) {
                throw new RequiredError('issueDate','Required parameter issueDate was null or undefined when calling v1EarchivedocumentsreportUnreportedGet.');
            }
            const localVarPath = `/v1/earchivedocumentsreport/unreported`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.issueDate !== undefined) {
                localVarQueryParameter['issueDate'] = (requestParameters.issueDate as any instanceof Date) ?
                    (requestParameters.issueDate as any).toISOString() : requestParameters.issueDate;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Henüz raporlanmamış e-arşiv belgelerin raporlanmasını tetiklemek için bu uç kullanılabilir
         * @param {string} issueDate Raporlanmasını istediğiniz belgenin tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1EarchivedocumentsreportUnreportedPost: async (requestParameters: V1EarchivedocumentsreportUnreportedPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'issueDate' is not null or undefined
            if (requestParameters.issueDate === null || requestParameters.issueDate === undefined) {
                throw new RequiredError('issueDate','Required parameter issueDate was null or undefined when calling v1EarchivedocumentsreportUnreportedPost.');
            }
            const localVarPath = `/v1/earchivedocumentsreport/unreported`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.issueDate !== undefined) {
                localVarQueryParameter['issueDate'] = (requestParameters.issueDate as any instanceof Date) ?
                    (requestParameters.issueDate as any).toISOString() : requestParameters.issueDate;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detay : Raporlarınıza bu uç ile ulaşabilirsiniz
         * @summary Raporları listeler
         * @param {number} year yıl
         * @param {Month} month ay
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1EarchivedocumentsreportYearMonthGet: async (requestParameters: V1EarchivedocumentsreportYearMonthGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            if (requestParameters.year === null || requestParameters.year === undefined) {
                throw new RequiredError('year','Required parameter year was null or undefined when calling v1EarchivedocumentsreportYearMonthGet.');
            }
            // verify required parameter 'month' is not null or undefined
            if (requestParameters.month === null || requestParameters.month === undefined) {
                throw new RequiredError('month','Required parameter month was null or undefined when calling v1EarchivedocumentsreportYearMonthGet.');
            }
            const localVarPath = `/v1/earchivedocumentsreport/{year}/{month}`.replace(`{${"year"}}`, encodeURIComponent(String(requestParameters.year))).replace(`{${"month"}}`, encodeURIComponent(String(requestParameters.month)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EArchiveDocumentsReportApi - functional programming interface
 * @export
 */
export const EArchiveDocumentsReportApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Detay : Rapor parçasının içeriğine bu api ucu ile ulaşabilirsiniz
         * @summary Rapor parçasının içeriğini listeler
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1EarchivedocumentsreportIdDetailsGet(requestParameters: V1EarchivedocumentsreportIdDetailsGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EArchiveDocumentsReportDivisionContent>>> {
            const localVarAxiosArgs = await EArchiveDocumentsReportApiAxiosParamCreator(configuration).v1EarchivedocumentsreportIdDetailsGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Detay : Rapor parçasının içeriğini excel olarak dışarı aktarmak için bu api ucu kullanılabilir
         * @summary Rapor parçasının içeriğini excel olarak dışarı aktarır
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1EarchivedocumentsreportIdExcelGet(requestParameters: V1EarchivedocumentsreportIdExcelGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await EArchiveDocumentsReportApiAxiosParamCreator(configuration).v1EarchivedocumentsreportIdExcelGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Detay : Rapor parçası geçmişine bu api ucu ile ulaşabilirsiniz
         * @summary Rapor parçası geçmişini listeler
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1EarchivedocumentsreportIdHistoriesGet(requestParameters: V1EarchivedocumentsreportIdHistoriesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<History>>> {
            const localVarAxiosArgs = await EArchiveDocumentsReportApiAxiosParamCreator(configuration).v1EarchivedocumentsreportIdHistoriesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Detay : Rapor parçasının xml dosyasını indirmek için bu uç kullanılabilir
         * @summary Rapor parçasının xml dosyasını indirir
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1EarchivedocumentsreportIdXmlGet(requestParameters: V1EarchivedocumentsreportIdXmlGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await EArchiveDocumentsReportApiAxiosParamCreator(configuration).v1EarchivedocumentsreportIdXmlGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Detay : Henüz raporlanmamış e-arşiv belgelerin listesini bu uç ile alabilirsiniz              Başlangıç ve bitiş tarihi
         * @summary Henüz raporlanmamış e-arşiv belgeleri listeler
         * @param {string} issueDate Raporlanmasını istediğiniz belgenin tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1EarchivedocumentsreportUnreportedGet(requestParameters: V1EarchivedocumentsreportUnreportedGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnReportedEArchiveDocument>> {
            const localVarAxiosArgs = await EArchiveDocumentsReportApiAxiosParamCreator(configuration).v1EarchivedocumentsreportUnreportedGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Henüz raporlanmamış e-arşiv belgelerin raporlanmasını tetiklemek için bu uç kullanılabilir
         * @param {string} issueDate Raporlanmasını istediğiniz belgenin tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1EarchivedocumentsreportUnreportedPost(requestParameters: V1EarchivedocumentsreportUnreportedPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await EArchiveDocumentsReportApiAxiosParamCreator(configuration).v1EarchivedocumentsreportUnreportedPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Detay : Raporlarınıza bu uç ile ulaşabilirsiniz
         * @summary Raporları listeler
         * @param {number} year yıl
         * @param {Month} month ay
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1EarchivedocumentsreportYearMonthGet(requestParameters: V1EarchivedocumentsreportYearMonthGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EArchiveDocumentsReportDivision>>> {
            const localVarAxiosArgs = await EArchiveDocumentsReportApiAxiosParamCreator(configuration).v1EarchivedocumentsreportYearMonthGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * EArchiveDocumentsReportApi - factory interface
 * @export
 */
export const EArchiveDocumentsReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Detay : Rapor parçasının içeriğine bu api ucu ile ulaşabilirsiniz
         * @summary Rapor parçasının içeriğini listeler
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1EarchivedocumentsreportIdDetailsGet(requestParameters: V1EarchivedocumentsreportIdDetailsGetRequest, options?: any): AxiosPromise<Array<EArchiveDocumentsReportDivisionContent>> {
            return EArchiveDocumentsReportApiFp(configuration).v1EarchivedocumentsreportIdDetailsGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Detay : Rapor parçasının içeriğini excel olarak dışarı aktarmak için bu api ucu kullanılabilir
         * @summary Rapor parçasının içeriğini excel olarak dışarı aktarır
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1EarchivedocumentsreportIdExcelGet(requestParameters: V1EarchivedocumentsreportIdExcelGetRequest, options?: any): AxiosPromise<void> {
            return EArchiveDocumentsReportApiFp(configuration).v1EarchivedocumentsreportIdExcelGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Detay : Rapor parçası geçmişine bu api ucu ile ulaşabilirsiniz
         * @summary Rapor parçası geçmişini listeler
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1EarchivedocumentsreportIdHistoriesGet(requestParameters: V1EarchivedocumentsreportIdHistoriesGetRequest, options?: any): AxiosPromise<Array<History>> {
            return EArchiveDocumentsReportApiFp(configuration).v1EarchivedocumentsreportIdHistoriesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Detay : Rapor parçasının xml dosyasını indirmek için bu uç kullanılabilir
         * @summary Rapor parçasının xml dosyasını indirir
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1EarchivedocumentsreportIdXmlGet(requestParameters: V1EarchivedocumentsreportIdXmlGetRequest, options?: any): AxiosPromise<void> {
            return EArchiveDocumentsReportApiFp(configuration).v1EarchivedocumentsreportIdXmlGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Detay : Henüz raporlanmamış e-arşiv belgelerin listesini bu uç ile alabilirsiniz              Başlangıç ve bitiş tarihi
         * @summary Henüz raporlanmamış e-arşiv belgeleri listeler
         * @param {string} issueDate Raporlanmasını istediğiniz belgenin tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1EarchivedocumentsreportUnreportedGet(requestParameters: V1EarchivedocumentsreportUnreportedGetRequest, options?: any): AxiosPromise<UnReportedEArchiveDocument> {
            return EArchiveDocumentsReportApiFp(configuration).v1EarchivedocumentsreportUnreportedGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Henüz raporlanmamış e-arşiv belgelerin raporlanmasını tetiklemek için bu uç kullanılabilir
         * @param {string} issueDate Raporlanmasını istediğiniz belgenin tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1EarchivedocumentsreportUnreportedPost(requestParameters: V1EarchivedocumentsreportUnreportedPostRequest, options?: any): AxiosPromise<void> {
            return EArchiveDocumentsReportApiFp(configuration).v1EarchivedocumentsreportUnreportedPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Detay : Raporlarınıza bu uç ile ulaşabilirsiniz
         * @summary Raporları listeler
         * @param {number} year yıl
         * @param {Month} month ay
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1EarchivedocumentsreportYearMonthGet(requestParameters: V1EarchivedocumentsreportYearMonthGetRequest, options?: any): AxiosPromise<Array<EArchiveDocumentsReportDivision>> {
            return EArchiveDocumentsReportApiFp(configuration).v1EarchivedocumentsreportYearMonthGet(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EArchiveDocumentsReportApi - object-oriented interface
 * @export
 * @class EArchiveDocumentsReportApi
 * @extends {BaseAPI}
 */
export class EArchiveDocumentsReportApi extends BaseAPI {
    /**
     * Detay : Rapor parçasının içeriğine bu api ucu ile ulaşabilirsiniz
     * @summary Rapor parçasının içeriğini listeler
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EArchiveDocumentsReportApi
     */
    public v1EarchivedocumentsreportIdDetailsGet(requestParameters: V1EarchivedocumentsreportIdDetailsGetRequest, options?: any) {
        return EArchiveDocumentsReportApiFp(this.configuration).v1EarchivedocumentsreportIdDetailsGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Detay : Rapor parçasının içeriğini excel olarak dışarı aktarmak için bu api ucu kullanılabilir
     * @summary Rapor parçasının içeriğini excel olarak dışarı aktarır
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EArchiveDocumentsReportApi
     */
    public v1EarchivedocumentsreportIdExcelGet(requestParameters: V1EarchivedocumentsreportIdExcelGetRequest, options?: any) {
        return EArchiveDocumentsReportApiFp(this.configuration).v1EarchivedocumentsreportIdExcelGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Detay : Rapor parçası geçmişine bu api ucu ile ulaşabilirsiniz
     * @summary Rapor parçası geçmişini listeler
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EArchiveDocumentsReportApi
     */
    public v1EarchivedocumentsreportIdHistoriesGet(requestParameters: V1EarchivedocumentsreportIdHistoriesGetRequest, options?: any) {
        return EArchiveDocumentsReportApiFp(this.configuration).v1EarchivedocumentsreportIdHistoriesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Detay : Rapor parçasının xml dosyasını indirmek için bu uç kullanılabilir
     * @summary Rapor parçasının xml dosyasını indirir
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EArchiveDocumentsReportApi
     */
    public v1EarchivedocumentsreportIdXmlGet(requestParameters: V1EarchivedocumentsreportIdXmlGetRequest, options?: any) {
        return EArchiveDocumentsReportApiFp(this.configuration).v1EarchivedocumentsreportIdXmlGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Detay : Henüz raporlanmamış e-arşiv belgelerin listesini bu uç ile alabilirsiniz              Başlangıç ve bitiş tarihi
     * @summary Henüz raporlanmamış e-arşiv belgeleri listeler
     * @param {string} issueDate Raporlanmasını istediğiniz belgenin tarihi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EArchiveDocumentsReportApi
     */
    public v1EarchivedocumentsreportUnreportedGet(requestParameters: V1EarchivedocumentsreportUnreportedGetRequest, options?: any) {
        return EArchiveDocumentsReportApiFp(this.configuration).v1EarchivedocumentsreportUnreportedGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Henüz raporlanmamış e-arşiv belgelerin raporlanmasını tetiklemek için bu uç kullanılabilir
     * @param {string} issueDate Raporlanmasını istediğiniz belgenin tarihi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EArchiveDocumentsReportApi
     */
    public v1EarchivedocumentsreportUnreportedPost(requestParameters: V1EarchivedocumentsreportUnreportedPostRequest, options?: any) {
        return EArchiveDocumentsReportApiFp(this.configuration).v1EarchivedocumentsreportUnreportedPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Detay : Raporlarınıza bu uç ile ulaşabilirsiniz
     * @summary Raporları listeler
     * @param {number} year yıl
     * @param {Month} month ay
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EArchiveDocumentsReportApi
     */
    public v1EarchivedocumentsreportYearMonthGet(requestParameters: V1EarchivedocumentsreportYearMonthGetRequest, options?: any) {
        return EArchiveDocumentsReportApiFp(this.configuration).v1EarchivedocumentsreportYearMonthGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1EarchivedocumentsreportIdDetailsGetRequest {
    /**
     * id
     */
    id: string;
    }
    export interface V1EarchivedocumentsreportIdExcelGetRequest {
    /**
     * id
     */
    id: string;
    }
    export interface V1EarchivedocumentsreportIdHistoriesGetRequest {
    /**
     * id
     */
    id: string;
    }
    export interface V1EarchivedocumentsreportIdXmlGetRequest {
    /**
     * id
     */
    id: string;
    }
    export interface V1EarchivedocumentsreportUnreportedGetRequest {
    /**
     * Raporlanmasını istediğiniz belgenin tarihi
     */
    issueDate: string;
    }
    export interface V1EarchivedocumentsreportUnreportedPostRequest {
    /**
     * Raporlanmasını istediğiniz belgenin tarihi
     */
    issueDate: string;
    }
    export interface V1EarchivedocumentsreportYearMonthGetRequest {
    /**
     * yıl
     */
    year: number;
    /**
     * ay
     */
    month: Month;
    }
