import { AccountantApiFactory, V1AccountantsAccountIdSetactivePutRequest } from '@/apis/identity';
import { AccountantAccountInfo } from '@/apis/identity/interfaces';
import { getFullName } from '@/components/PartyInfo';
import { useApi, useGetPageData } from '@/hooks';
import { Select } from 'antd';
import React from 'react';
import { getUserManager } from '@axa-fr/react-oidc-context';
const AccountSelector = () => {
  const user = JSON.parse(
    window.sessionStorage.getItem('oidc.user:https://idtest.nes.com.tr:etransformation-front-end') ||
      window.sessionStorage.getItem('oidc.user:https://id.nes.com.tr:etransformation-front-end') ||
      '{}'
  );

  if (user.profile === undefined) {
    return null;
  }

  const isAccountant = JSON.parse(user.profile.IsAccountant);

  if (!isAccountant) {
    return null;
  }

  const { v1AccountantsGet, v1AccountantsAccountIdSetactivePut } = AccountantApiFactory();

  const importCompanies = useApi<any, any>({
    asyncFunction: v1AccountantsAccountIdSetactivePut,
    successCallback: (response, _, addtionalProps) => {
      getUserManager()
        .removeUser()
        .then((x) => {
          window.location.href = '/';
        });
    }
  });

  const { execute, loading, data, error } = useGetPageData<Array<AccountantAccountInfo>>(v1AccountantsGet, {}, false);

  const accountName = user.profile.AccountName;
  const partyIdentification = user.profile.PartyIdentification;

  return (
    <Select
      showSearch={false}
      optionFilterProp="children"
      style={{ width: '100%' }}
      onChange={(value) => {
        importCompanies.call({ accountId: value } as V1AccountantsAccountIdSetactivePutRequest);
      }}
      value={'-1'}
      onDropdownVisibleChange={(open) => {
        if (open) {
          execute();
        }
      }}
      loading={loading}
    >
      <Select.Option value={'-1'}>
        {partyIdentification} / {accountName}
      </Select.Option>

      {data &&
        data
          .filter((x) => x.partyIdentification != partyIdentification)
          .map((x) => {
            return (
              <Select.Option value={x.accountId!} key={x.accountId}>
                {x.partyIdentification} /{' '}
                {getFullName({
                  partyName: x.partyName,
                  firstName: x.firstName,
                  familyName: x.familyName,
                  partyIdentification: x.partyIdentification!,
                  isVirtual: false
                })}
              </Select.Option>
            );
          })}
    </Select>
  );
};

export default AccountSelector;
