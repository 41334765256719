import { BootstrapVariant } from '@/constants/enums';
import React, { memo } from 'react';

export const InvoiceTypeCodeLabel = memo(({ profileId, invoiceTypeCode }: { profileId: any; invoiceTypeCode: any }) => {
  let color: BootstrapVariant = BootstrapVariant.primary;

  if (profileId === 'IHRACAT') {
    color = BootstrapVariant.info;
  } else if (profileId === 'YOLCUBERABERFATURA') {
    color = BootstrapVariant.dark;
  }

  switch (invoiceTypeCode) {
    case 'SATIS':
      color = BootstrapVariant.success;
      break;
    case 'IADE':
      color = BootstrapVariant.danger;
      break;
    case 'TEVKIFAT':
      color = BootstrapVariant.mint;
      break;
    case 'ISTISNA':
      color = BootstrapVariant.warning;
      break;
    case 'OZELMATRAH':
      color = BootstrapVariant.primary;
      break;
    case 'IHRACKAYITLI':
      color = BootstrapVariant.purple;
      break;
    case 'SGK':
      color = BootstrapVariant.pink;
      break;
  }

  return <label className={`label label-${color}`}>{invoiceTypeCode}</label>;
});

export default InvoiceTypeCodeLabel;
