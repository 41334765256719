/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { CodeType } from '.';
import { NS_CAC, NS_CBC } from '../constants';
import { FinancialAccountType } from './FinancialAccountType';

/*
  27.04.2021 : Fully completed
*/
export class PaymentMeansType {
  @XMLElement({ types: [{ name: 'PaymentMeansCode', namespaceUri: NS_CBC, itemType: () => CodeType }] })
  paymentMeansCode?: CodeType;

  @XMLElement({ types: [{ name: 'PaymentDueDate', namespaceUri: NS_CBC, itemType: () => String }] })
  paymentDueDate?: string;

  @XMLElement({ types: [{ name: 'PaymentChannelCode', namespaceUri: NS_CBC, itemType: () => CodeType }] })
  paymentChannelCode?: CodeType;

  @XMLElement({ types: [{ name: 'InstructionNote', namespaceUri: NS_CBC, itemType: () => String }] })
  instructionNote?: string;

  @XMLElement({ types: [{ name: 'PayerFinancialAccount', namespaceUri: NS_CAC, itemType: () => FinancialAccountType }] })
  payerFinancialAccount?: FinancialAccountType;

  @XMLElement({ types: [{ name: 'PayeeFinancialAccount', namespaceUri: NS_CAC, itemType: () => FinancialAccountType }] })
  payeeFinancialAccount?: FinancialAccountType;
}

export default PaymentMeansType;
