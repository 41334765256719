/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { IDType } from '.';
import { NS_CBC } from '../constants';

/*
  19.04.2022 : Partially completed
*/
export class RoadTransportType {
  @XMLElement({ types: [{ name: 'LicensePlateID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  licensePlateID?: IDType;
}

export default RoadTransportType;
