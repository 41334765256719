import { DocumentModal, DocumentModalValue, Pagination } from '@/interfaces';
import { AnyType } from '@/type';
import { AxiosPromise } from 'axios';
import { useCallback, useRef, useState } from 'react';
export * from './DocumentAttachmentsModal';
export * from './DocumentDespatchesModal';
export * from './DocumentHistoriesModal';
export * from './DocumentPreviewModal';
export * from './DocumentTaxesModal';
export * from './IncomingInvoiceDetailModal';
export * from './MailHistoriesModal';
export * from './OutgoingInvoiceDetailModal';

/**
 * Belge ye ait bilgilerin apiden alınır.
 * @param asyncFunction Belge ile ilgili bilgiyi çekecek async api fonksiyonu
 */
export const useDocumentModal = <T, TAdditionalProps = null>(asyncFunction) => {
  /**
   * Henüz asyn operasyon devam ederken, sonuçlanmasını beklemeden modalı kapatması durumlarını handle etmek için kullanılıyor
   */
  const isClosed = useRef(false);

  const [value, setValue] = useState<DocumentModalValue<T, TAdditionalProps>>({ data: [] as AnyType, loading: false, visible: false });

  /**
   * Api isteğini başlatmak için bu metod kullanılır
   * @param uuid BELGE ETTN değeri
   * @param documentNumber 16 Haneli e-Belge Numarası
   * @param additionalProps Modal içerisinde kullanılmak için ekstra veri
   */
  const get = (uuid: string, documentNumber: string, pagination?: Pagination, additionalProps?: TAdditionalProps) => {
    isClosed.current = false;
    setValue({
      loading: true,
      visible: true,
      documentNumber,
      uuid,
      data: undefined,
      pagination: undefined
    });

    const params = {
      uuid,
      page: pagination?.page || undefined,
      pageSize: pagination?.pageSize || undefined,
      sort: pagination?.sort || undefined
    };

    return (asyncFunction(params) as AxiosPromise<T>)
      .then((response: AnyType) => {
        if (!isClosed.current) {
          if (response.isAxiosError) {
            setValue({ loading: false, data: undefined, error: response, visible: true, documentNumber, uuid, additionalProps });
          } else {
            setValue({ loading: false, data: response.data, visible: true, documentNumber, uuid, additionalProps });
          }
        }
      })
      .catch((error) => {
        if (!isClosed.current) {
          setValue({ loading: false, data: undefined, error: error, visible: true });
        }
      });
  };

  const closeModal = useCallback(() => {
    isClosed.current = true;
    setValue({ loading: false, visible: false, documentNumber: undefined, uuid: undefined, data: undefined });
  }, []);

  const props: DocumentModal<T, TAdditionalProps> = { ...value, closeModal };

  return { props, get };
};

export default useDocumentModal;
