/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { UserInfo } from '@/apis/einvoice/interfaces';
import { ProductServices } from '@/apis/general/interfaces';
import { CompanyInfo } from '@/components/InvoiceEditor/General';
import createStore from '@/helpers/createStore';
import { AllowanceChargeType, DefaultTaxTotal, DocumentReferenceType, InvoiceLineType, TaxSubTotalType } from '@/models/xmlDocuments/cac';
import { Invoice } from '@/models/xmlDocuments/invoice';
import { ExpenseType } from '@/pages/InvoiceEditor/HKSExpenses';
import { invoiceEditorImmutableHelperContext } from '@/pages/InvoiceEditor/ImmutableHelperContext';
import { InvoiceLineProxy } from '@/pages/InvoiceEditor/Proxies';
import { nameOf } from '@/utils/objectUtils';
import { FormInstance } from 'antd/es/form';
import update from 'immutability-helper';
import _ from 'lodash';
import { Reducer } from 'react';
import { v4 as uuidv4 } from 'uuid';

export type ExcelLine = {
  'Mal/Hizmet Adı': string;
  Miktar: number;
  Birim: string;
  'Birim Fiyat': number;
  'İskonto Oranı': number;
  'KDV Oranı': number;
  'Satıcı Kodu': string;
  'Alıcı Kodu': string;
  Marka: string;
  Model: string;
  Açıklama: string;
  'GTIP No': string;
  'Teslim Şartı': string;
  'Gönderim Şekli': string;
  'Gümrük Takip No': string;
  'Bulunduğu Kabın Numarası': string;
  'Bulunduğu Kabın Adeti': number;
  'Bulunduğu Kabın Cinsi': string;
};

export type SetLineWithCalculate = {
  type: 'SET_LINE_WITH_CALCULATE';
  lineIndex: number;
  id?: string;
  value?: any;
  calculationField?: 'TaxableAmount' | 'TaxAmount' | 'TaxPercent' | 'AllowanceAmount' | 'AllowancePercent';
  subLineIndex?: number;
  subActionType?:
    | 'UPDATE_TAX_CODE'
    | 'REMOVE_ALLOWANCE'
    | 'REMOVE_TAX'
    | 'SET_WITHHOLDING_REASON'
    | 'SET_OZELMATRAH_REASON'
    | 'SET_KDV_REASON'
    | 'CHANGE_WITHHOLDING_ACTIVATE';
  form?: FormInstance<any>;
  invoice: Invoice;
  productService?: ProductServices;
  uniqueId?: string;
};

export type SetLineFromProductServiceWithCalculate = {
  type: 'SET_LINE_FROM_PRODUCT_SERVICE_WITH_CALCULATE';
  lineIndex: number;
  id?: string;
  value?: any;
  calculationField?: 'TaxableAmount' | 'TaxAmount' | 'TaxPercent' | 'AllowanceAmount' | 'AllowancePercent';
  subLineIndex?: number;
  subActionType?:
    | 'UPDATE_TAX_CODE'
    | 'REMOVE_ALLOWANCE'
    | 'REMOVE_TAX'
    | 'SET_WITHHOLDING_REASON'
    | 'SET_OZELMATRAH_REASON'
    | 'SET_KDV_REASON'
    | 'CHANGE_WITHHOLDING_ACTIVATE';
  form?: FormInstance<any>;
  invoice: Invoice;
  uniqueId?: string;
  productService: ProductServices;
};

export type State = {
  company: CompanyInfo | undefined;
  userInfo: UserInfo | undefined;
  invoice: Invoice;
  isEarchiveInvoice: boolean;
  documentTemplate?: string;
  autoSaveCompany: boolean;
  senderAlias?: string;
  orderForm: { isValid: boolean; isSubmitting: boolean };
  isEdit: boolean;
  invoiceDetailLoaded: boolean;
  requiredExemptionReasonColumn: boolean;
  isExportInvoice: boolean;
  calculateTaxIncludePrice: boolean;
  eInvoiceDocumentSerieExist: boolean;
  eArchiveDocumentSerieExist: boolean;
  eArchiveDocumentTemplateExist: boolean;
  accountAddressExist: boolean;

  checkingRequirements: boolean;
};

export type Action =
  | { type: 'SET_STATE'; value: State }
  | { type: 'SET_FREE_EXPORT_INVOICE'; value: boolean }
  | { type: 'SET_ISSUE_DATE'; value: State }
  | { type: 'SET_CALCULATE_TAX_INCLUDE_PRICE'; value: boolean }
  | { type: 'SET_ALLOWANCE_CHARGES'; value: Invoice }
  | { type: 'SET_INVOICE'; value: Invoice }
  | { type: 'SET_INVOICE_TYPE'; value: string; form: FormInstance<any> }
  | { type: 'SET_PROFILE_ID'; value: string; form: FormInstance<any> }
  | { type: 'SET_DOCUMENT_CURRENCY_CODE'; value: string }
  | { type: 'ADD_LINE' }
  | { type: 'SET_LINE_WITH_PATH'; index: number; path: string; value: string }
  | { type: 'REMOVE_LINE'; value: number }
  | { type: 'ADD_TAX'; lineIndex: number }
  | { type: 'IMPORT_LINE'; value: ExcelLine[] }
  | { type: 'IMPORT_LINE_FROM_XML'; value: InvoiceLineType[] }
  | SetLineWithCalculate
  | SetLineFromProductServiceWithCalculate
  | { type: 'ADD_ALLOWANCE'; lineIndex: number };

const initialState: State = {
  company: undefined,
  userInfo: undefined,
  isEarchiveInvoice: true,
  autoSaveCompany: false,
  invoice: new Invoice(),
  orderForm: { isValid: true, isSubmitting: false },
  isEdit: false,
  invoiceDetailLoaded: false,
  requiredExemptionReasonColumn: false,
  isExportInvoice: false,
  calculateTaxIncludePrice: false,
  checkingRequirements: true,
  accountAddressExist: false,
  eArchiveDocumentSerieExist: false,
  eArchiveDocumentTemplateExist: false,
  eInvoiceDocumentSerieExist: false
};

export const setCurrencyCode = (invoice: Invoice, documentCurrencyCode: string) => {
  invoice.documentCurrencyCode = { value: documentCurrencyCode };

  if (documentCurrencyCode === 'TRY') {
    invoice.pricingExchangeRate = undefined;
  } else {
    if (invoice.pricingExchangeRate !== undefined) {
      invoice.pricingExchangeRate.sourceCurrencyCode = documentCurrencyCode;
    } else {
      invoice.pricingExchangeRate = {
        calculationRate: 1,
        sourceCurrencyCode: documentCurrencyCode,
        targetCurrencyCode: 'TRY'
      };
    }
  }

  invoice.invoiceLine?.forEach((invoiceLine) => {
    if (invoiceLine.price && invoiceLine.price.priceAmount) {
      invoiceLine.price!.priceAmount.currencyID = documentCurrencyCode;
    }

    (invoiceLine.allowanceCharge || []).forEach((allowanceCharge) => {
      if (allowanceCharge.baseAmount) allowanceCharge.baseAmount.currencyID = documentCurrencyCode;
      if (allowanceCharge.amount) allowanceCharge.amount.currencyID = documentCurrencyCode;
      if (allowanceCharge.perUnitAmount) allowanceCharge.perUnitAmount.currencyID = documentCurrencyCode;
    });

    if (invoiceLine.taxTotal.taxAmount) invoiceLine.taxTotal.taxAmount.currencyID = documentCurrencyCode;
    if (invoiceLine.withholdingTaxTotal && invoiceLine.withholdingTaxTotal[0] && invoiceLine.withholdingTaxTotal[0].taxAmount)
      invoiceLine.withholdingTaxTotal[0].taxAmount.currencyID = documentCurrencyCode;

    (invoiceLine.taxTotal.taxSubtotal || []).forEach((taxSubTotal) => {
      if (taxSubTotal.taxAmount) taxSubTotal.taxAmount.currencyID = documentCurrencyCode;
      if (taxSubTotal.taxableAmount) taxSubTotal.taxableAmount.currencyID = documentCurrencyCode;
      if (taxSubTotal.transactionCurrencyTaxAmount) taxSubTotal.transactionCurrencyTaxAmount.currencyID = documentCurrencyCode;
      if (taxSubTotal.perUnitAmount) taxSubTotal.perUnitAmount.currencyID = documentCurrencyCode;
    });

    ((invoiceLine.withholdingTaxTotal && invoiceLine.withholdingTaxTotal[0] && invoiceLine.withholdingTaxTotal[0].taxSubtotal) || []).forEach((taxSubTotal) => {
      if (taxSubTotal.taxAmount) taxSubTotal.taxAmount.currencyID = documentCurrencyCode;
      if (taxSubTotal.taxableAmount) taxSubTotal.taxableAmount.currencyID = documentCurrencyCode;
      if (taxSubTotal.transactionCurrencyTaxAmount) taxSubTotal.transactionCurrencyTaxAmount.currencyID = documentCurrencyCode;
      if (taxSubTotal.perUnitAmount) taxSubTotal.perUnitAmount.currencyID = documentCurrencyCode;
    });

    if (!_.isUndefined(invoiceLine.lineExtensionAmount)) {
      invoiceLine.lineExtensionAmount.currencyID = documentCurrencyCode;
    }
  });

  if (invoice.legalMonetaryTotal) {
    if (invoice.legalMonetaryTotal.lineExtensionAmount) invoice.legalMonetaryTotal.lineExtensionAmount.currencyID = documentCurrencyCode;
    if (invoice.legalMonetaryTotal.taxExclusiveAmount) invoice.legalMonetaryTotal.taxExclusiveAmount.currencyID = documentCurrencyCode;
    if (invoice.legalMonetaryTotal.taxInclusiveAmount) invoice.legalMonetaryTotal.taxInclusiveAmount.currencyID = documentCurrencyCode;
    if (invoice.legalMonetaryTotal.allowanceTotalAmount) invoice.legalMonetaryTotal.allowanceTotalAmount.currencyID = documentCurrencyCode;
    if (invoice.legalMonetaryTotal.chargeTotalAmount) invoice.legalMonetaryTotal.chargeTotalAmount.currencyID = documentCurrencyCode;
    if (invoice.legalMonetaryTotal.payableRoundingAmount) invoice.legalMonetaryTotal.payableRoundingAmount.currencyID = documentCurrencyCode;
    if (invoice.legalMonetaryTotal.payableAmount) invoice.legalMonetaryTotal.payableAmount.currencyID = documentCurrencyCode;
  }

  if (invoice.paymentTerms) {
    if (invoice.paymentTerms.amount) invoice.paymentTerms.amount.currencyID = documentCurrencyCode;
    if (invoice.paymentTerms.penaltyAmount) invoice.paymentTerms.penaltyAmount.currencyID = documentCurrencyCode;
  }

  (invoice.allowanceCharge || []).forEach((allowanceCharge) => {
    if (allowanceCharge.baseAmount) allowanceCharge.baseAmount.currencyID = documentCurrencyCode;
    if (allowanceCharge.amount) allowanceCharge.amount.currencyID = documentCurrencyCode;
    if (allowanceCharge.perUnitAmount) allowanceCharge.perUnitAmount.currencyID = documentCurrencyCode;
  });

  (invoice.taxTotal || []).forEach((taxTotal) => {
    taxTotal.taxAmount.currencyID = documentCurrencyCode;

    taxTotal.taxSubtotal.forEach((taxSubTotal) => {
      if (taxSubTotal.taxAmount) taxSubTotal.taxAmount.currencyID = documentCurrencyCode;
      if (taxSubTotal.taxableAmount) taxSubTotal.taxableAmount.currencyID = documentCurrencyCode;
      if (taxSubTotal.transactionCurrencyTaxAmount) taxSubTotal.transactionCurrencyTaxAmount.currencyID = documentCurrencyCode;
      if (taxSubTotal.perUnitAmount) taxSubTotal.perUnitAmount.currencyID = documentCurrencyCode;
    });
  });

  (invoice.withholdingTaxTotal || []).forEach((taxTotal) => {
    taxTotal.taxAmount.currencyID = documentCurrencyCode;

    taxTotal.taxSubtotal.forEach((taxSubTotal) => {
      if (taxSubTotal.taxAmount) taxSubTotal.taxAmount.currencyID = documentCurrencyCode;
      if (taxSubTotal.taxableAmount) taxSubTotal.taxableAmount.currencyID = documentCurrencyCode;
      if (taxSubTotal.transactionCurrencyTaxAmount) taxSubTotal.transactionCurrencyTaxAmount.currencyID = documentCurrencyCode;
      if (taxSubTotal.perUnitAmount) taxSubTotal.perUnitAmount.currencyID = documentCurrencyCode;
    });
  });

  return invoice;
};

const NAMES = {
  name: nameOf(InvoiceLineProxy.item?.name?.value),
  quantity: nameOf(InvoiceLineProxy.invoicedQuantity?.value),
  unitCode: nameOf(InvoiceLineProxy.invoicedQuantity?.unitCode),
  price: nameOf(InvoiceLineProxy.price?.priceAmount?.value),
  allowancePercent: nameOf(InvoiceLineProxy.allowanceCharge?.[0]?.percent),
  kdvPercent: nameOf(InvoiceLineProxy.taxTotal?.taxSubtotal?.[0]?.percent),
  sellersItemIdentification: nameOf(InvoiceLineProxy.item?.sellersItemIdentification?.id?.value),
  buyersItemIdentification: nameOf(InvoiceLineProxy.item?.buyersItemIdentification?.id?.value),
  brandName: nameOf(InvoiceLineProxy.item?.brandName),
  model: nameOf(InvoiceLineProxy.item?.modelName),
  description: nameOf(InvoiceLineProxy.item?.description),
  gtipNo: nameOf(InvoiceLineProxy.delivery?.[0]?.shipment?.goodsItem?.[0]?.requiredCustomsID?.value),
  deliveryTermsCode: nameOf(InvoiceLineProxy.delivery?.[0]?.deliveryTerms?.[0]?.id?.value),
  transportModeCode: nameOf(InvoiceLineProxy.delivery?.[0]?.shipment?.shipmentStage?.[0]?.transportModeCode?.value),
  productTraceId: nameOf(InvoiceLineProxy.item?.itemInstance?.[0]?.productTraceID?.value),
  actualPackageId: nameOf(InvoiceLineProxy.delivery?.[0]?.shipment?.transportHandlingUnit?.[0]?.actualPackage?.[0]?.id?.value),
  actualPackageQuantity: nameOf(InvoiceLineProxy.delivery?.[0]?.shipment?.transportHandlingUnit?.[0]?.actualPackage?.[0]?.quantity?.value),
  actualPackageTypeCode: nameOf(InvoiceLineProxy.delivery?.[0]?.shipment?.transportHandlingUnit?.[0]?.actualPackage?.[0]?.packagingTypeCode?.value)
};

const getValue = (val: string) => val.substring(0, val.indexOf(' '));

const removeInternetInfo = (key: string, references?: DocumentReferenceType[]) => {
  const index = references && references.findIndex((x) => x.documentTypeCode === key);

  if (index === undefined || index === -1) {
    return references;
  }

  return update(references || [], { $splice: [[index, 1]] });
};

const reducer: Reducer<State, Action> = (state: State, action) => {
  switch (action.type) {
    case 'SET_INVOICE': {
      return { ...state, invoice: action.value };
    }
    case 'SET_DOCUMENT_CURRENCY_CODE': {
      const invoice = _.cloneDeep(state.invoice);

      return { ...state, invoice: setCurrencyCode(invoice, action.value) };
    }
    case 'SET_LINE_WITH_PATH': {
      const { index, path, value } = action;

      const obj = update(state.invoice, {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        invoiceLine: { [index]: { $set: _.setWith(_.clone(state.invoice.invoiceLine![index]), path.substring(path.indexOf('.') + 1), value, _.clone) as any } }
      }) as any;

      if (path.indexOf('deliveryTerms') !== -1) {
        obj.invoiceLine[index].delivery[0].deliveryTerms[0].id.schemeID = 'INCOTERMS';
      }

      if (path.indexOf('shipment') !== -1) {
        obj.invoiceLine[index].delivery[0].shipment.id = { value: undefined };
      }

      return { ...state, invoice: obj };
    }

    case 'SET_CALCULATE_TAX_INCLUDE_PRICE': {
      return { ...state, calculateTaxIncludePrice: action.value };
    }

    case 'SET_FREE_EXPORT_INVOICE': {
      const newInvoiceState = { ...state.invoice };

      newInvoiceState.freeExportInvoice = action.value;

      //Genel hesaplama yapılarak state güncelleniyor
      return {
        ...state,

        invoice: invoiceEditorImmutableHelperContext.update(state.invoice, {
          $calculateMonetaryTotals: newInvoiceState
        } as any)
      };
    }

    case 'SET_INVOICE_TYPE': {
      const previousInvoiceType = state.invoice.invoiceTypeCode;

      const invoice = _.cloneDeep(state.invoice);

      invoice.invoiceTypeCode = action.value;

      if (previousInvoiceType === 'OZELMATRAH' || previousInvoiceType === 'ISTISNA' || invoice.invoiceTypeCode === 'ISTISNA') {
        invoice.invoiceLine?.forEach((invoiceLine) => {
          const id = `${invoiceLine.uniqueId}.${nameOf(InvoiceLineProxy.taxTotal?.taxSubtotal[0].taxCategory?.taxExemptionReasonCode)}`;
          action.form.setFieldsValue({ [id]: '0' });

          invoiceLine.taxTotal.taxSubtotal[0].taxCategory.taxExemptionReasonCode = undefined;
          invoiceLine.taxTotal.taxSubtotal[0].taxCategory.taxExemptionReason = undefined;
        });
      }

      if (previousInvoiceType === 'TEVKIFAT') {
        invoice.invoiceLine?.forEach((invoiceLine) => {
          const id = `${invoiceLine.uniqueId}.${nameOf(InvoiceLineProxy.withholdingTaxTotal![0].taxSubtotal[0].taxCategory.taxScheme.taxTypeCode)}`;

          action.form.setFieldsValue({ [id]: '0' });

          invoiceLine.withholdingTaxTotal = [];
        });
      }

      if (previousInvoiceType === 'IADE' || previousInvoiceType === 'TEVKIFATIADE') {
        invoice.billingReference = (invoice.billingReference || []).filter(
          (billingReference) => !(billingReference.invoiceDocumentReference !== undefined && billingReference.invoiceDocumentReference.documentType === 'İADE')
        );
      }

      if (previousInvoiceType === 'SGK') {
        invoice.accountingCost = undefined;
        invoice.additionalDocumentReference = (invoice.additionalDocumentReference || []).filter((x) => x.documentTypeCode !== 'MUKELLEF_KODU');
        invoice.additionalDocumentReference = (invoice.additionalDocumentReference || []).filter((x) => x.documentTypeCode !== 'MUKELLEF_ADI');
        invoice.additionalDocumentReference = (invoice.additionalDocumentReference || []).filter((x) => x.documentTypeCode !== 'DOSYA_NO');

        action.form.setFields([{ name: ['sgkInfoForm'], value: undefined }]);
      }

      if (previousInvoiceType === 'KOMISYONCU') {
        Object.keys(ExpenseType).forEach((expenseType) => {
          invoice.allowanceCharge = (invoice.allowanceCharge || []).filter((x) => x.allowanceChargeReason !== expenseType);
        });
      }

      if (previousInvoiceType === 'OZELMATRAH' || previousInvoiceType === 'TEVKIFAT') {
        (invoice.invoiceLine || []).forEach((invoiceLine, index) => {
          if (previousInvoiceType === 'OZELMATRAH') {
            const id = `${invoiceLine.uniqueId}.${nameOf(InvoiceLineProxy.taxTotal?.taxSubtotal[0].taxableAmount.value)}`;

            action.form.setFieldsValue({ [id]: 0 });

            invoice.invoiceLine = invoiceEditorImmutableHelperContext.update(invoice.invoiceLine, {
              [index]: { $setLineWithCalculate: { invoice, form: action.form } } as any
            });
          }
        });
      }

      if (
        previousInvoiceType === 'OZELMATRAH' ||
        previousInvoiceType === 'TEVKIFAT' ||
        previousInvoiceType === 'IHRACKAYITLI' ||
        invoice.invoiceTypeCode === 'IHRACKAYITLI' ||
        invoice.invoiceTypeCode === 'ISTISNA' ||
        previousInvoiceType === 'ISTISNA' ||
        previousInvoiceType === 'KOMISYONCU'
      ) {
        return {
          ...state,
          invoice: invoiceEditorImmutableHelperContext.update(invoice, {
            $calculateMonetaryTotals: invoice
          } as any)
        };
      } else {
        return { ...state, invoice };
      }
    }
    case 'SET_PROFILE_ID': {
      const previousProfileId = state.invoice.profileID;

      const invoice = _.cloneDeep(state.invoice);

      invoice.profileID = action.value;

      if (previousProfileId === 'HKS') {
        invoice.invoiceLine?.forEach((invoiceLine) => {
          // const id = `${invoiceLine.uniqueId}.${nameOf(InvoiceLineProxy.taxTotal?.taxSubtotal[0].taxCategory?.taxExemptionReasonCode)}`;
          // action.form.setFieldsValue({ [id]: '0' });

          const malSahibiVKNTCKNFormIndex = invoiceLine.item?.additionalItemIdentification?.findIndex((x) => x.id?.schemeID === 'MALSAHIBIVKNTCKN') ?? -1;
          const malSahibiAdSoyadFormIndex = invoiceLine.item?.additionalItemIdentification?.findIndex((x) => x.id?.schemeID === 'MALSAHIBIADSOYADUNVAN') ?? -1;
          const kunyeNoFormIndex = invoiceLine.item?.additionalItemIdentification?.findIndex((x) => x.id?.schemeID === 'KUNYENO') ?? -1;

          const malSahibiVKNTCKNIndex = invoiceLine.item?.additionalItemIdentification?.findIndex((x) => x.id?.schemeID === 'MALSAHIBIVKNTCKN') ?? -1;
          if (malSahibiVKNTCKNIndex !== -1) {
            const id = `${invoiceLine.uniqueId}.${nameOf(InvoiceLineProxy.item?.additionalItemIdentification![malSahibiVKNTCKNFormIndex!]?.id?.value)}`;
            action.form.setFieldsValue({ [id]: '' });

            invoiceLine.item?.additionalItemIdentification?.splice(malSahibiVKNTCKNIndex, 1);
          }

          const malSahibiAdSoyadIndex = invoiceLine.item?.additionalItemIdentification?.findIndex((x) => x.id?.schemeID === 'MALSAHIBIADSOYADUNVAN') ?? -1;
          if (malSahibiAdSoyadIndex !== -1) {
            const id = `${invoiceLine.uniqueId}.${nameOf(InvoiceLineProxy.item?.additionalItemIdentification![malSahibiAdSoyadFormIndex!]?.id?.value)}`;
            action.form.setFieldsValue({ [id]: '' });

            invoiceLine.item?.additionalItemIdentification?.splice(malSahibiAdSoyadIndex, 1);
          }

          const kunyeNoIndex = invoiceLine.item?.additionalItemIdentification?.findIndex((x) => x.id?.schemeID === 'KUNYENO') ?? -1;
          if (kunyeNoIndex !== -1) {
            const id = `${invoiceLine.uniqueId}.${nameOf(InvoiceLineProxy.item?.additionalItemIdentification![kunyeNoFormIndex!]?.id?.value)}`;
            action.form.setFieldsValue({ [id]: '' });

            invoiceLine.item?.additionalItemIdentification?.splice(kunyeNoIndex, 1);
          }
        });
      }

      if (invoice.profileID === 'KAMU') {
        invoice.buyerCustomerParty = _.cloneDeep(invoice.accountingCustomerParty);
      }

      return { ...state, invoice };
    }

    case 'SET_STATE': {
      return action.value;
    }
    case 'SET_ISSUE_DATE': {
      debugger;
      const newInvoiceState = { ...action.value.invoice };

      if (newInvoiceState.profileID !== 'EARSIVFATURA') {
        newInvoiceState.additionalDocumentReference = removeInternetInfo('INT_WEBSITE', newInvoiceState.additionalDocumentReference);
        newInvoiceState.additionalDocumentReference = removeInternetInfo('INT_PAYMENT_METHOD', newInvoiceState.additionalDocumentReference);
        newInvoiceState.additionalDocumentReference = removeInternetInfo('INT_PAYMENT_METHOD_NAME', newInvoiceState.additionalDocumentReference);
        newInvoiceState.additionalDocumentReference = removeInternetInfo('INT_PAYMENTDATE', newInvoiceState.additionalDocumentReference);
        newInvoiceState.additionalDocumentReference = removeInternetInfo('INT_TRANSPORTER', newInvoiceState.additionalDocumentReference);
        newInvoiceState.additionalDocumentReference = removeInternetInfo('INT_TRANSPORTER_REGISTER_NUMBER', newInvoiceState.additionalDocumentReference);
        newInvoiceState.additionalDocumentReference = removeInternetInfo('INT_TRANSPORT_DATE', newInvoiceState.additionalDocumentReference);
        newInvoiceState.additionalDocumentReference = removeInternetInfo('IS_DESPATCH', newInvoiceState.additionalDocumentReference);
        newInvoiceState.additionalDocumentReference = removeInternetInfo('IS_INTERNET', newInvoiceState.additionalDocumentReference);
        newInvoiceState.additionalDocumentReference = removeInternetInfo('SEND_TYPE', newInvoiceState.additionalDocumentReference);
      }

      return { ...action.value, invoice: newInvoiceState };
    }

    case 'ADD_LINE': {
      const newLine = new InvoiceLineType();
      newLine.allowanceCharge = [
        {
          amount: { value: 0 },
          multiplierFactorNumeric: 0,
          sequenceNumeric: 1,
          calculationField: 'Percent',
          chargeIndicator: false,
          percent: 0,
          uniqueId: uuidv4()
        }
      ];

      newLine.id = { value: (state.invoice.invoiceLine.length + 1).toString() };
      return {
        ...state,
        invoice: { ...state.invoice!, invoiceLine: [...state.invoice!.invoiceLine!, newLine], lineCountNumeric: state.invoice.invoiceLine.length + 1 }
      };
    }
    case 'IMPORT_LINE': {
      let newInvoice = { ...state.invoice, lineCountNumeric: state.invoice.invoiceLine.length + action.value.length };

      action.value.forEach((excelLine, index) => {
        let newLine = new InvoiceLineType();
        newLine.allowanceCharge = [
          {
            amount: { value: 0 },
            multiplierFactorNumeric: 0,
            sequenceNumeric: 1,
            calculationField: 'Percent',
            chargeIndicator: false,
            percent: 0,
            uniqueId: uuidv4()
          }
        ];

        newLine.id = { value: (newInvoice.invoiceLine.length + 1).toString() };

        //Mal Hizmet Adı
        newLine = _.set(newLine, NAMES.name, excelLine['Mal/Hizmet Adı']);

        //Miktar
        const quantity = Number(excelLine.Miktar?.toString().replace(',', ''));
        if (!isNaN(quantity)) {
          newLine = _.set(newLine, NAMES.quantity, quantity);
        } else {
          newLine = _.set(newLine, NAMES.quantity, 0);
        }

        //Birim
        newLine = _.set(newLine, NAMES.unitCode, getValue(excelLine.Birim));

        //Birim Fiyat
        const price = Number(excelLine['Birim Fiyat']);
        if (!isNaN(price)) newLine = _.set(newLine, NAMES.price, price);

        //İskonto Oranı
        const allowancePercent = Number(excelLine['İskonto Oranı']);
        if (!isNaN(allowancePercent)) {
          newLine = _.set(newLine, NAMES.allowancePercent, allowancePercent);
          newLine.allowanceCharge![0].calculationField = 'Percent';
        }

        //KDV Oranı
        const kdvPercent = Number(excelLine['KDV Oranı']);
        if (!isNaN(kdvPercent)) {
          newLine = _.set(newLine, NAMES.kdvPercent, kdvPercent);
          newLine.taxTotal.taxSubtotal![0].calculationField = 'Percent';
        }

        //Satıcı ürün kodu
        const sellersItemIdentification = excelLine['Satıcı Kodu'] || '';
        if (sellersItemIdentification !== '') newLine = _.set(newLine, NAMES.sellersItemIdentification, sellersItemIdentification);

        //Alıcı ürün kodu
        const buyersItemIdentification = excelLine['Alıcı Kodu'] || '';
        if (buyersItemIdentification !== '') newLine = _.set(newLine, NAMES.buyersItemIdentification, buyersItemIdentification);

        //Marka
        const brandName = excelLine.Marka || '';
        if (brandName !== '') newLine = _.set(newLine, NAMES.brandName, brandName);

        //Model
        const model = excelLine.Model || '';
        if (model !== '') newLine = _.set(newLine, NAMES.model, model);

        //Açıklama
        const description = excelLine.Açıklama || '';
        if (description !== '') newLine = _.set(newLine, NAMES.description, description);

        if (state.invoice.profileID === 'IHRACAT') {
          //GTIP No
          const gtipNo = excelLine['GTIP No'] || '';
          if (gtipNo !== '') {
            newLine = _.set(newLine, NAMES.gtipNo, gtipNo);
            newLine!.delivery![0].shipment!.id = { value: undefined };
          }

          //Teslim Şartı
          const deliveryTermsCode = excelLine['Teslim Şartı'] || '';
          if (deliveryTermsCode !== '') {
            newLine = _.set(newLine, NAMES.deliveryTermsCode, getValue(deliveryTermsCode));
            newLine.delivery![0]!.deliveryTerms![0]!.id!.schemeID = 'INCOTERMS';
          }

          //Gönderim Şekli
          const transportModeCode = excelLine['Gönderim Şekli'] || '';
          if (transportModeCode !== '') newLine = _.set(newLine, NAMES.transportModeCode, getValue(transportModeCode));

          //Gümrük Takip No
          const productTraceId = excelLine['Gümrük Takip No'] || '';
          if (productTraceId !== '') newLine = _.set(newLine, NAMES.productTraceId, productTraceId);

          //Bulunduğu kabın numarası
          const actualPackageId = excelLine['Bulunduğu Kabın Numarası'] || '';
          if (actualPackageId !== '') newLine = _.set(newLine, NAMES.actualPackageId, actualPackageId);

          //Bulunduğu kabın adedi
          const actualPackageQuantity = Number(excelLine['Bulunduğu Kabın Adeti']);
          if (!isNaN(actualPackageQuantity)) newLine = _.set(newLine, NAMES.actualPackageQuantity, actualPackageQuantity);

          //Bulunduğu kabın cinsi
          const actualPackageTypeCode = excelLine['Bulunduğu Kabın Cinsi'] || '';
          if (actualPackageTypeCode !== '') newLine = _.set(newLine, NAMES.actualPackageTypeCode, getValue(actualPackageTypeCode));
        }

        //Satır hesaplaması yapılıyor
        newLine = invoiceEditorImmutableHelperContext.update(newLine as any, {
          $setLineWithCalculate: {
            invoice: state.invoice,
            type: 'SET_LINE_WITH_CALCULATE',
            lineIndex: 0
          } as any
        });

        newInvoice = update(newInvoice, { invoiceLine: { $push: [newLine] } });
      });

      //Genel hesaplama yapılarak state güncelleniyor
      return {
        ...state,
        invoice: invoiceEditorImmutableHelperContext.update(newInvoice, {
          $calculateMonetaryTotals: newInvoice
        } as any)
      };
    }
    case 'IMPORT_LINE_FROM_XML': {
      let newInvoice: Invoice = { ...state.invoice, lineCountNumeric: state.invoice.invoiceLine.length + action.value.length };

      for (let i = 0; i < action.value.length; i++) {
        const element = action.value[i];

        element.id = { value: (newInvoice.invoiceLine.length + 1).toString() };

        if (element.allowanceCharge?.length === 0) {
          element.allowanceCharge = [
            {
              amount: { value: 0 },
              multiplierFactorNumeric: 0,
              sequenceNumeric: 1,
              calculationField: 'Percent',
              chargeIndicator: false,
              percent: 0,
              uniqueId: uuidv4()
            }
          ];

          if (element.taxTotal?.taxSubtotal?.length == 0) {
            element.taxTotal = DefaultTaxTotal;
          } else {
            const tax0015Index = element.taxTotal?.taxSubtotal.findIndex((x) => x.taxCategory?.taxScheme?.taxTypeCode === '0015') ?? -1;

            if (tax0015Index > 0) {
              const tax0015 = element.taxTotal?.taxSubtotal.splice(tax0015Index, 1);

              element.taxTotal?.taxSubtotal.splice(0, 0, tax0015[0]);
            }
          }
        }

        newInvoice = update(newInvoice, { invoiceLine: { $push: [element] } });
      }

      //Genel hesaplama yapılarak state güncelleniyor
      return {
        ...state,
        invoice: invoiceEditorImmutableHelperContext.update(newInvoice, {
          $calculateMonetaryTotals: newInvoice
        } as any)
      };
    }

    case 'REMOVE_LINE': {
      const lines = [...state.invoice.invoiceLine];

      lines.splice(action.value, 1);

      lines.forEach((line, index) => {
        line.id = { value: (index + 1).toString() };
      });

      const invoice = { ...state.invoice!, invoiceLine: lines, lineCountNumeric: lines.length };

      return {
        ...state,
        invoice: invoiceEditorImmutableHelperContext.update(invoice, {
          $calculateMonetaryTotals: invoice
        } as any)
      };
    }
    case 'ADD_ALLOWANCE':
      return update(state, {
        invoice: {
          invoiceLine: { [action.lineIndex]: { allowanceCharge: { $push: [new AllowanceChargeType()] } } }
        }
      });
    case 'ADD_TAX':
      return update(state, {
        invoice: {
          invoiceLine: { [action.lineIndex]: { taxTotal: { taxSubtotal: { $push: [new TaxSubTotalType()] } } } }
        }
      });
    case 'SET_LINE_WITH_CALCULATE': {
      const invoice: Invoice = {
        ...state.invoice,
        invoiceLine: invoiceEditorImmutableHelperContext.update(state.invoice.invoiceLine, {
          [action.lineIndex]: { $setLineWithCalculate: action } as any
        })
      };

      // let isRequiredTaxExemptionReason = false;
      // if (
      //   invoice.invoiceLine?.some((x) =>
      //     x.taxTotal?.taxSubtotal?.some((ts) => ts?.taxCategory?.taxScheme?.taxTypeCode === '0015' && ts.taxAmount?.value === 0)
      //   ) &&
      //   invoice.invoiceTypeCode !== 'ISTISNA'
      // ) {
      //   isRequiredTaxExemptionReason = true;
      // } else {
      //   isRequiredTaxExemptionReason = false;
      // }

      return {
        ...state,
        invoice: invoiceEditorImmutableHelperContext.update(invoice, {
          $calculateMonetaryTotals: invoice
        } as any)
      };
    }

    case 'SET_LINE_FROM_PRODUCT_SERVICE_WITH_CALCULATE': {
      const invoice: Invoice = {
        ...state.invoice,
        invoiceLine: invoiceEditorImmutableHelperContext.update(state.invoice.invoiceLine, {
          [action.lineIndex]: { $setLineWithCalculate: action } as any
        })
      };

      return {
        ...state,
        invoice: invoiceEditorImmutableHelperContext.update(invoice, {
          $calculateMonetaryTotals: invoice
        } as any)
      };
    }

    case 'SET_ALLOWANCE_CHARGES': {
      const invoice: Invoice = {
        ...action.value
      };
      return {
        ...state,
        invoice: invoiceEditorImmutableHelperContext.update(invoice, {
          $calculateMonetaryTotals: invoice
        } as any)
      };
    }

    default:
      return state;
  }
};
const { Context, Provider, Consumer } = createStore(reducer, initialState);

export { Consumer as InvoiceEditorContextConsumer, Provider as InvoiceEditorProvider };

export default Context;
