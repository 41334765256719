import { RecordStatus } from '@/apis/general/interfaces';
import { BagdeStatus } from '@/constants/enums';
import { Badge, Tooltip } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';

export const RecordStatusComp = ({ recordStatus }: { recordStatus: RecordStatus }) => {
  const text = (recordStatus: RecordStatus) => {
    switch (recordStatus) {
      case 'None':
        return 'DURUM_BILINMIYOR';
      case 'Error':
        return 'HATA_ALDI';
      case 'Succeed':
        return 'BASARILI';
      case 'Waiting':
        return 'BEKLENIYOR';
      default:
        return 'DURUM_BILINMIYOR';
    }
  };

  const color = (recordStatus: RecordStatus): BagdeStatus => {
    switch (recordStatus) {
      case 'None':
        return BagdeStatus.default;
      case 'Error':
        return BagdeStatus.error;
      case 'Succeed':
        return BagdeStatus.success;
      case 'Waiting':
        return BagdeStatus.processing;
      default:
        return BagdeStatus.default;
    }
  };
  const variant = color(recordStatus);
  const title = text(recordStatus);
  return (
    <>
      {variant !== 'error' && (
        <div>
          <Badge status={variant} text={intl.get(title)} />
        </div>
      )}

      {variant === 'error' && (
        <div style={{ cursor: 'pointer' }}>
          <Tooltip trigger="hover" overlayClassName="antd-tooltip-center" title={intl.get('HATA_DETAYI_ICIN_TIKLAYINIZ')} placement="bottom">
            <Badge status={variant} text={intl.get(title)} />
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default RecordStatusComp;
