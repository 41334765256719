import { OnClickProps } from '@/interfaces';
import { AnyType } from '@/type';
import { faSave } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';

/**
 * Inputlarda kullanmak üzere oluşturulan butonlar. afterAddon / beforeAddon olarak geçiyor antd içerisinde.
 */
export const SaveInAddon = ({ onClick }: OnClickProps) => {
  const input = useRef<AnyType>(null);

  useEffect(() => {
    if (input.current) {
      const element = (input.current as HTMLDivElement).parentElement;
      if (element) {
        element.style.padding = `0px`;
        element.style['background-color'] = `#1b7ee0`;
        element.style['border-color'] = `#1976d2`;
        element.style['color'] = `white`;
      }
    }
  }, []);

  return (
    <div style={{ padding: '0 13px', cursor: 'pointer' }} onClick={onClick} ref={input}>
      <FontAwesomeIcon icon={faSave} />
    </div>
  );
};

export default SaveInAddon;
