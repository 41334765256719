import React, { memo } from 'react';
import intl from 'react-intl-universal';
import { SmartTooltip } from '.';

export const CityCountryName = memo(({ cityName, countryName }: { cityName: string; countryName: string }) => {
  return (
    <SmartTooltip
      data={[
        { size: 10, title: intl.get('SEHIR'), value: cityName, makeTooltipTitle: false },
        { size: 10, title: intl.get('ULKE'), value: countryName, makeTooltipTitle: false }
      ]}
    />
  );
});

export default CityCountryName;
