import { SortType } from '@/constants/enums';

export class SortInfo {
  column: string;
  type: SortType;

  constructor(column: string, type: SortType) {
    this.column = column;
    this.type = type;
  }
}

export default SortInfo;
