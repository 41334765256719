import { Input } from 'antd';
import currencySymbol from 'currency-symbol-map';
import React, { useEffect, useState } from 'react';
import { WarningToastify } from './Toastify';

interface State {
  value: number;
  formattedValue: string;
  focused?: boolean;
  valueText?: string;
}

interface Props {
  precision: number;
  onBlur?: (value: number) => void;
  currency?: string;
  prefix?: string;
  disabled?: boolean;
  value?: number | string;
  onChange?: (value: string) => void;
  onValueChange?: (value: number) => void;
  noZero?: boolean;
  onKeyDown?: (event) => void;
  max?: number;
  min?: number;
  align?: 'right' | 'left';
  allowNegative?: boolean;
}

const Amount = ({ precision, allowNegative, onBlur, onValueChange, currency, prefix, align, disabled, noZero, onKeyDown, min, max, ...rest }: Props) => {
  const { value, onChange } = rest as any;

  const getFloatValue = (v: any): number => {
    return typeof v === 'string' ? parseFloat((v || '0').replace(',', '.')) : v || 0;
  };

  const [state, setState] = useState<State>({
    value: getFloatValue(value) || 0,
    formattedValue: noZero === true ? getFloatValue(value).toString().replace('.', ',') : getFloatValue(value).toFixed(precision).replace('.', ','),
    focused: false
  });
  const inputRef = React.useRef<any>(null);

  useEffect(() => {
    if (state.focused) {
      inputRef.current.input.select();
    }
  }, [state]);

  useEffect(() => {
    if (state.focused === false) {
      const formattedValue = (noZero === true ? getFloatValue(value).toString() : getFloatValue(value).toFixed(precision)).replace('.', ',');
      setState({ ...state, formattedValue });
    }
  }, [value]);

  useEffect(() => {
    if (state.focused === true) {
      const newValue = ((value !== state.value ? (typeof value === 'string' ? parseFloat(value.replace(',', '.')) : value) : state.value) || 0).round(
        precision
      );

      setState({ ...state, valueText: newValue.toString().replace('.', ','), value: newValue });
    }
  }, [state.focused]);

  return (
    <Input
      disabled={disabled}
      style={{ textAlign: align || 'right' }}
      ref={inputRef}
      value={state.focused === true ? state.valueText : state.formattedValue}
      onFocus={(e) => {
        setState({ ...state, focused: true, formattedValue: state.value.toString().replace('.', ',') });
      }}
      addonAfter={currency && <span style={{ fontSize: 14 }}>{currencySymbol(currency || 'TRY')}</span>}
      addonBefore={prefix && <span style={{ fontSize: 14 }}>{prefix}</span>}
      onBlur={({ target: { value } }) => {
        const newA = parseFloat(value.replace(',', '.') || '0').round(precision);

        setState({
          ...state,
          focused: false,
          value: newA,
          formattedValue: noZero === true ? newA.toString().replace('.', ',') : newA.toFixed(precision).replace('.', ',')
        });

        onBlur && onBlur(newA);
      }}
      onKeyDown={(e: any) => {
        onKeyDown && onKeyDown(e);

        const caretPosition = (e.target as any).selectionStart;

        //Virgül pozisyonu tespit ediliyor
        const decimalSeperatorPosition = state.formattedValue.indexOf(',');

        //Sadece sayısal değerlere veya sağ-sol ok gibi değerlere izin veririz, aksi durumda izin verilmez
        if (
          !(
            (e.keyCode >= 48 && e.keyCode <= 57) ||
            (e.keyCode >= 96 && e.keyCode <= 105) ||
            e.keyCode == 189 ||
            e.keyCode == 109 ||
            e.keyCode == 110 || // Eksi
            // CTRL_CMD+A
            ((e.metaKey || e.ctrlKey) && e.keyCode === 65) ||
            // CTRL_CMD+V
            ((e.metaKey || e.ctrlKey) && e.keyCode === 86)
          ) &&
          !(e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 188 || e.keyCode === 8)
        ) {
          e.preventDefault();
        }

        if (e.keyCode === 188 && decimalSeperatorPosition !== -1) {
          e.preventDefault();
        }

        if (
          decimalSeperatorPosition !== -1 &&
          precision - state.formattedValue.substr(decimalSeperatorPosition + 1).length === 0 &&
          caretPosition > decimalSeperatorPosition &&
          !(e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 8) &&
          !((e.metaKey || e.ctrlKey) && e.keyCode === 65)
        ) {
          e.preventDefault();
        }

        const newchar = String.fromCharCode(e.charCode || e.keyCode);
        const curValue = e.target.value.split('');
        curValue.splice(e.target.selectionStart, e.target.selectionEnd - e.target.selectionStart, newchar);
        const newval = curValue.join('');

        const inputValue = parseFloat(newval.replace(',', '.') || '0');
        if (max && inputValue > max) {
          e.preventDefault();
          WarningToastify(`Değer, maksimum ${max} olabilir.`);
        }

        if (min && inputValue < min) {
          e.preventDefault();
          WarningToastify(`Değer, minimum ${min} olabilir.`);
        }

        if (allowNegative !== true && inputValue < 0) {
          e.preventDefault();
          WarningToastify(`Değer negatif olamaz!`);
        }
      }}
      onChange={({ target: { value } }) => {
        const number = parseFloat(value.replace(',', '.') || '0');

        if (allowNegative !== true && number < 0) {
          WarningToastify(`Değer negatif olamaz!`);
          return false;
        }

        setState({ ...state, focused: undefined, formattedValue: value, value: number });
        onChange && onChange(value);
        onValueChange && onValueChange(number);
      }}
    />
  );
};

export default Amount;

// export default memo(Amount, (prev, next) => {
//   return (prev as any).value === (next as any).value;
// });
