import { faPortalExit, faSms } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import React, { memo } from 'react';
import intl from 'react-intl-universal';

export const SmsInfo = memo(({ isSmsSend }: { isSmsSend: boolean }) => {
  return (
    <Tooltip trigger="hover" placement="bottom" title={isSmsSend ? intl.get('SMS_DURUMU') : intl.get('Sms gönderilmedi.')}>
      <FontAwesomeIcon style={{ cursor: 'pointer', fontSize: '20px' }} icon={isSmsSend ? faSms : faPortalExit} />
    </Tooltip>
  );
});

export default SmsInfo;
