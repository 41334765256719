/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLArray, XMLElement } from '@/xmldom-decorators';
import { CodeType, IDType, TextType } from '.';
import { NS_CAC, NS_CBC } from '../constants';
import RoadTransportType from './RoadTransportType';

/*
  19.04.2022 : Partially completed
*/
export class TransportMeansType {
  @XMLElement({ types: [{ name: 'JourneyID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  journeyID?: IDType;

  @XMLElement({ types: [{ name: 'RegistrationNationalityID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  registrationNationalityID?: IDType;

  @XMLArray({ nested: false, itemTypes: [{ name: 'RegistrationNationality', namespaceUri: NS_CBC, itemType: () => TextType }] })
  registrationNationality?: TextType[];

  @XMLElement({ types: [{ name: 'DirectionCode', namespaceUri: NS_CBC, itemType: () => CodeType }] })
  directionCode?: CodeType;

  @XMLElement({ types: [{ name: 'TransportMeansTypeCode', namespaceUri: NS_CBC, itemType: () => CodeType }] })
  transportMeansTypeCode?: CodeType;

  @XMLElement({ types: [{ name: 'TradeServiceCode', namespaceUri: NS_CBC, itemType: () => CodeType }] })
  tradeServiceCode?: CodeType;

  // TODO : public StowageType Stowage
  // TODO : public AirTransportType AirTransport

  @XMLElement({ types: [{ name: 'RoadTransport', namespaceUri: NS_CAC, itemType: () => RoadTransportType }] })
  roadTransport?: RoadTransportType;

  // TODO : public RailTransportType RailTransport
  // TODO : public MaritimeTransportType MaritimeTransport
  // TODO : public PartyType OwnerParty
  // TODO : public System.Collections.Generic.List<DimensionType> MeasurementDimension
}

export default TransportMeansType;
