/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLArray, XMLElement } from '@/xmldom-decorators';
import { IDType } from '.';
import { NS_CAC, NS_CBC } from '../constants';
import CodeType from './CodeType';
import GoodsItemType from './GoodsItemType';
import { QuantityTypeNoRequiredUnitCode } from './QuantityType';

/*
  27.04.2021 : Partially completed
  cac:CommodityClassification
  cac:ItemInstance
*/
export class PackageType {
  @XMLElement({ types: [{ name: 'ID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  id?: IDType;

  @XMLElement({ types: [{ name: 'Quantity', namespaceUri: NS_CBC, itemType: () => QuantityTypeNoRequiredUnitCode }] })
  quantity?: QuantityTypeNoRequiredUnitCode;

  // TODO : ReturnableMaterialIndicator

  @XMLElement({ types: [{ name: 'PackageLevelCode', namespaceUri: NS_CBC, itemType: () => CodeType }] })
  packageLevelCode?: CodeType;

  @XMLElement({ types: [{ name: 'PackagingTypeCode', namespaceUri: NS_CBC, itemType: () => CodeType }] })
  packagingTypeCode?: CodeType;

  // TODO : PackingMaterial

  @XMLArray({ nested: false, itemTypes: [{ name: 'ContainedPackage', namespaceUri: NS_CAC, itemType: () => PackageType }] })
  containedPackage?: PackageType[];

  @XMLArray({ nested: false, itemTypes: [{ name: 'GoodsItem', namespaceUri: NS_CAC, itemType: () => GoodsItemType }] })
  goodsItem?: GoodsItemType[];

  // TODO : MeasurementDimension
}

export default PackageType;
