import { AuthRole, AuthScope } from '@/interfaces';
import {
  faArchive,
  faArrowUp,
  faBan,
  faBellOn,
  faCloudUpload,
  faCogs,
  faEdit,
  faFile,
  faFileCode,
  faFileExcel,
  faFilePdf,
  faFileSignature,
  faListUl,
  faMailbox,
  faPencil,
  faPencilAlt,
  faPlus,
  faSearch,
  faShare,
  faTable,
  faTags,
  faTrash,
  faUndo,
  faUsers
} from '@fortawesome/pro-regular-svg-icons';

export const ESMM_EDITOR_ROLES: AuthRole = {
  value: 'ESMM:EDITOR',
  icon: faPencilAlt,
  checkable: false,
  children: [
    {
      value: 'ESMM:EDITOR:CREATE_EDIT',
      checkable: true,
      icon: faPlus
    }
  ]
};

export const ESMM_OUTGOUNG_ROLES: AuthRole = {
  value: 'ESMM:OUT',
  icon: faArrowUp,
  checkable: false,
  children: [
    {
      value: 'ESMM:OUT:V-ROOT',
      checkable: false,
      icon: faFile,
      children: [
        {
          value: 'ESMM:OUT:V',
          checkable: true,
          icon: faFile
        },
        {
          value: 'ESMM:OUT:V:XML',
          checkable: true,
          icon: faFileCode
        },
        {
          value: 'ESMM:OUT:V:PDF',
          checkable: true,
          icon: faFilePdf
        },
        {
          value: 'ESMM:OUT:V:EMAIL_SEND',
          checkable: true,
          icon: faShare
        },
        {
          value: 'ESMM:OUT:V:HTML',
          checkable: true,
          icon: faSearch
        },
        {
          value: 'ESMM:OUT:V:CANCEL',
          checkable: true,
          icon: faArchive
        }
      ]
    },
    {
      checkable: false,
      value: 'ESMM:OUT:CANCELED-ROOT',
      icon: faBan,
      children: [
        {
          checkable: true,
          value: 'ESMM:OUT:CANCELED',
          icon: faBan
        },
        {
          value: 'ESMM:OUT:V:CANCEL:WITHDRAW',
          checkable: true,
          icon: faUndo
        }
      ]
    },
    {
      checkable: false,
      value: 'ESMM:OUT:RM',
      icon: faFileExcel,
      children: [
        {
          value: 'ESMM:OUT:RM:REPORTS',
          icon: faTable,
          checkable: true
        },
        {
          value: 'ESMM:OUT:RM:TEMPLATES',
          icon: faTags,
          checkable: true
        },
        {
          value: 'ESMM:OUT:RM:TEMPLATES_POST_PUT',
          icon: faEdit,
          checkable: true
        }
      ]
    }
  ]
};

export const ESMM_DEFINATIONS_ROLES: AuthRole = {
  value: 'ESMM:DEF',
  icon: faCogs,
  checkable: false,
  children: [
    {
      value: 'ESMM:DEF:SERIES-ROOT',
      checkable: false,
      icon: faListUl,
      children: [
        {
          value: 'ESMM:DEF:SERIES',
          checkable: true,
          icon: faListUl
        },
        {
          value: 'ESMM:DEF:SERIES_POST',
          icon: faEdit,
          checkable: true
        },
        {
          value: 'ESMM:DEF:SERIES_DELETE',
          icon: faTrash,
          checkable: true
        }
      ]
    },
    {
      value: 'ESMM:DEF:DOCUMENT_TEMPLATES-ROOT',
      checkable: false,
      icon: faTags,
      children: [
        {
          value: 'ESMM:DEF:DOCUMENT_TEMPLATES',
          checkable: true,
          icon: faTags
        },
        {
          value: 'ESMM:DEF:DOCUMENT_TEMPLATES_POST',
          icon: faEdit,
          checkable: true
        },
        {
          value: 'ESMM:DEF:DOCUMENT_TEMPLATES_PUT',
          icon: faPencil,
          checkable: true
        },
        {
          value: 'ESMM:DEF:DOCUMENT_TEMPLATES_DELETE',
          icon: faTrash,
          checkable: true
        }
      ]
    },
    {
      value: 'ESMM:DEF:MAIL_SMS',
      icon: faMailbox,
      checkable: true
    },
    {
      value: 'ESMM:DEF:FILE_EXPORT_TITLES',
      icon: faFileSignature,
      checkable: true
    }
  ]
};

export const ESMM_TAGS_ROLES: AuthRole = {
  value: 'ESMM:TAGS',
  icon: faTags,
  checkable: true
};

export const ESMM_UPLOADS_ROLES: AuthRole = {
  value: 'ESMM:UPLOADS_DOCUMENT',
  icon: faCloudUpload,
  checkable: true
};

export const ESMM_NOTIFICATIONS_ROLES: AuthRole = {
  value: 'ESMM:NOTIFY:OUT:DYNAMIC_RULES',
  icon: faBellOn,
  checkable: true
};

export const ESMM_ROLES = [ESMM_EDITOR_ROLES, ESMM_OUTGOUNG_ROLES, ESMM_DEFINATIONS_ROLES, ESMM_NOTIFICATIONS_ROLES, ESMM_TAGS_ROLES, ESMM_UPLOADS_ROLES];

export const ESMM_OUTGOING_SCOPES: AuthScope = {
  value: 'esmm:outgoing',
  checkable: false,
  children: [
    {
      value: 'esmm:outgoing:list:read'
    },
    {
      value: 'esmm:outgoing:list:write'
    },
    {
      value: 'esmm:outgoing:canceled:read'
    },
    {
      value: 'esmm:outgoing:canceled:write'
    },
    {
      value: 'esmm:outgoing:excelreport:read'
    },
    {
      value: 'esmm:outgoing:excelreport:write'
    }
  ]
};

export const ESMM_TAGS_SCOPES: AuthScope = {
  value: 'esmm:tags',
  checkable: false,
  children: [
    {
      value: 'esmm:tags:read'
    },
    {
      value: 'esmm:tags:write'
    }
  ]
};

export const ESMM_DEFINATIONS_SCOPES: AuthScope = {
  value: 'esmm:definations',
  checkable: false,
  children: [
    {
      value: 'esmm:definations:documentserie:read'
    },
    {
      value: 'esmm:definations:documentserie:write'
    },
    {
      value: 'esmm:definations:documenttemplate:read'
    },
    {
      value: 'esmm:definations:documenttemplate:write'
    },
    {
      value: 'esmm:definations:mailsms:read'
    },
    {
      value: 'esmm:definations:mailsms:write'
    },
    {
      value: 'esmm:definations:filetitle:read'
    },
    {
      value: 'esmm:definations:filetitle:write'
    }
  ]
};

export const ESMM_NOTIFICATIONS_SCOPES: AuthScope = {
  value: 'esmm:notifications',
  checkable: false,
  children: [
    {
      value: 'esmm:notifications:dynamic:read'
    },
    {
      value: 'esmm:notifications:dynamic:write'
    }
  ]
};

export const ESMM_UPLOAD_SCOPES: AuthScope = {
  value: 'esmm:upload',
  checkable: false,
  children: [
    {
      value: 'esmm:upload:write'
    }
  ]
};

export const ESMM_SCOPES = [ESMM_OUTGOING_SCOPES, ESMM_TAGS_SCOPES, ESMM_DEFINATIONS_SCOPES, ESMM_NOTIFICATIONS_SCOPES, ESMM_UPLOAD_SCOPES];
