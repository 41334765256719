import PageFilterContext from '@/contexts/PageFilterContext';
import { Option } from '@/interfaces';
import { AnyType } from '@/type';
import { faTable } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, Select, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';
import './style.scss';

export const ColumnSelect = ({ columns, url, stateKey }: { columns: Option[]; url: string; stateKey: string }) => {
  const [state, dispatch] = useContext(PageFilterContext);

  const selectedColumns = state[stateKey].selectedColumns as string[];

  const [visible, setVisible] = useState<boolean>(false);

  const defaultValue = columns.map((column) => {
    return column.value.toString();
  });

  useEffect(() => {
    const storageColumns: string[] = JSON.parse(localStorage.getItem(`apps.columns${url}`) || '[]');
    if (storageColumns.length === 0) {
      dispatch({
        type: 'SET_COLUMN',
        key: stateKey,
        value: defaultValue
      });
    }
  }, [visible]);

  return (
    <Popover
      autoAdjustOverflow={false}
      trigger="click"
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ marginTop: 4 }}>
            <FontAwesomeIcon icon={faTable} /> Sütun Seçiniz.
          </span>
        </div>
      }
      placement="bottomLeft"
      overlayClassName="column__select_popover"
      onVisibleChange={() => setVisible(!visible)}
      content={
        <Select
          style={{ position: 'inherit', display: 'block' }}
          dropdownStyle={{ position: 'inherit' }}
          value={selectedColumns}
          id="column-keys"
          className="column__select"
          open={visible}
          getPopupContainer={() => document.getElementsByClassName('column__select')?.[0] as AnyType}
          menuItemSelectedIcon={''}
          size="large"
          dropdownClassName={'column__select__dropdown'}
          mode="multiple"
          filterOption={(input, option) => {
            return option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
          onChange={(value: string[]) => {
            const newValue = value.length === 0 ? defaultValue : value;
            dispatch({
              type: 'SET_COLUMN',
              key: stateKey,
              value: newValue
            });

            localStorage.setItem(`apps.columns${url}`, JSON.stringify(newValue));
          }}
        >
          {columns.map((item) => (
            <Select.Option value={item.value} key={item.value} title={item.text}>
              <div className="column__info">
                {selectedColumns.some((x) => x === item.value) && <FontAwesomeIcon icon={faCheck} className="mr-2" />}
                {!selectedColumns.some((x) => x === item.value) && <FontAwesomeIcon icon={faCheck} className="mr-2" style={{ visibility: 'hidden' }} />}
                {item.text}
              </div>
            </Select.Option>
          ))}
        </Select>
      }
    >
      <Tooltip title={intl.get('SUTUN_SEC')} trigger="hover" placement="bottom">
        <Button size="sm" key="btn-edit-columns" color={'primary'} className="btn-labeled" onClick={() => setVisible(!visible)}>
          <FontAwesomeIcon icon={faTable} size={'1x'} />
        </Button>
      </Tooltip>
    </Popover>
  );
};

export default ColumnSelect;
