/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { v4 as uuidv4 } from 'uuid';
import { AmountType, TaxCategoryType } from '.';
import { DefaultValue, NS_CAC, NS_CBC } from '../constants';
import { MeasureType } from './QuantityType';

/*
  27.04.2021 : Fully completed
*/
export class TaxSubTotalType {
  @XMLElement({ types: [{ name: 'TaxableAmount', namespaceUri: NS_CBC, itemType: () => AmountType }] })
  taxableAmount: AmountType = { value: 0 };

  @XMLElement({ types: [{ name: 'TaxAmount', namespaceUri: NS_CBC, itemType: () => AmountType }] })
  taxAmount: AmountType = { value: 0 };

  @XMLElement({ types: [{ name: 'CalculationSequenceNumeric', namespaceUri: NS_CBC, itemType: () => Number }] })
  calculationSequenceNumeric?: number;

  @XMLElement({ types: [{ name: 'TransactionCurrencyTaxAmount', namespaceUri: NS_CBC, itemType: () => AmountType }] })
  transactionCurrencyTaxAmount?: AmountType;

  @XMLElement({ types: [{ name: 'Percent', namespaceUri: NS_CBC, itemType: () => Number }] })
  percent: number = 0;

  @XMLElement({ types: [{ name: 'BaseUnitMeasure', namespaceUri: NS_CBC, itemType: () => MeasureType }] })
  measureType?: MeasureType;

  @XMLElement({ types: [{ name: 'PerUnitAmount', namespaceUri: NS_CBC, itemType: () => AmountType }] })
  perUnitAmount?: AmountType;

  @XMLElement({ types: [{ name: 'TaxCategory', namespaceUri: NS_CAC, itemType: () => TaxCategoryType }] })
  taxCategory: TaxCategoryType = DefaultValue;

  //Belge oluştur ekranlarında, orandan mı tutardan mı hesaplanacağı bu alanda belirtilir
  calculationField: 'Amount' | 'Percent' = 'Percent';

  uniqueId: string = uuidv4();
}

export default TaxSubTotalType;
