import { ErrorViewer } from '@/components/TableBody';
import { AnyType } from '@/type';
import { getFileNameFromContentDisposition } from '@/utils/file';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'antd';
import { AxiosPromise } from 'axios';
import FileSaver from 'file-saver';
import React, { useRef, useState } from 'react';
import intl from 'react-intl-universal';

/**
 * Dosya indirmek için bu hook kullanılabilir
 * @param asyncFunction Dosyayı indirme isteğinde bulunacak api metodu
 */
export const useFileDownload = <T extends AnyType>(asyncFunction) => {
  const counter = useRef(0);
  const [loading, setLoading] = useState<boolean>(false);

  const download = (params: T) => {
    counter.current++;
    setLoading(true);
    return (asyncFunction(params) as AxiosPromise)
      .then(async (response: AnyType) => {
        if (response.isAxiosError) {
          const isJsonBlob = (data) => data instanceof Blob && data.type === 'application/json';

          if (isJsonBlob(response.response.data)) {
            debugger;
            response.response.data = JSON.parse(await response?.response?.data?.text());
          }

          setLoading(false);
          Modal.error({
            centered: true,
            content: <ErrorViewer error={response} />,
            icon: false,
            width: 720,
            okText: (
              <>
                <FontAwesomeIcon icon={faTimes} className="btn-label" />
                <span>{intl.get('KAPAT')}</span>
              </>
            ),
            okButtonProps: {
              className: 'btn-danger btn-labeled btn-sm',
              prefix: 'btn',
              prefixCls: 'btn'
            }
          });
        } else {
          FileSaver.saveAs(response.data, getFileNameFromContentDisposition(response));
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        debugger;
        Modal.error({
          centered: true,
          content: <ErrorViewer error={error} />,
          icon: false,
          width: 720,
          okText: (
            <>
              <FontAwesomeIcon icon={faTimes} className="btn-label" />
              <span>{intl.get('KAPAT')}</span>
            </>
          ),
          okButtonProps: {
            className: 'btn-danger btn-labeled btn-sm',
            prefix: 'btn',
            prefixCls: 'btn'
          }
        });
      });
  };

  return { loading, download };
};

export default useFileDownload;
