import { ButtonProps } from '@/interfaces';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React from 'react';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';

export const New = ({ disabled, title, onClick, size = 'sm', onlyIcon }: ButtonProps) => {
  return (
    <Button disabled={disabled} size={size} key="btn-search" onClick={onClick} color={'success'} className="btn-labeled">
      <FontAwesomeIcon icon={faPlus} className={classnames({ 'btn-label': !onlyIcon })} />
      {!onlyIcon && <span>{title ? title : intl.get('YENI')}</span>}
    </Button>
  );
};

export default New;
