import { SortType as SortTypeEnum } from '@/constants/enums';
import { faSortAmountDown, faSortAmountUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select } from 'antd';
import React, { memo } from 'react';
import intl from 'react-intl-universal';

export const SortType = memo(({ defaultValue, onChange }: { defaultValue?: SortTypeEnum; onChange?: (value: SortTypeEnum) => void }) => {
  return (
    <Select onChange={onChange} defaultValue={defaultValue === undefined ? SortTypeEnum.desc : defaultValue} className="w-100">
      <Select.Option value="desc">
        <FontAwesomeIcon icon={faSortAmountDown} /> {intl.get('AZALAN')}
      </Select.Option>
      <Select.Option value="asc">
        <FontAwesomeIcon icon={faSortAmountUp} /> {intl.get('ARTAN')}
      </Select.Option>
    </Select>
  );
});

export default SortType;
