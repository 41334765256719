import { DocumentTemplateCustomizationSetting, DocumentTemplateCustomizationSettingDto, EDocumentTemplateType } from '@/apis/einvoice/interfaces';
import {
  DocumentTemplateCustomizationSettingsApi,
  V1DefinitionsDocumenttemplatesCustomizationsettingsIdGetRequest,
  V1DefinitionsDocumenttemplatesCustomizationsettingsIdPutRequest,
  V1DefinitionsDocumenttemplatesCustomizationsettingsPostRequest
} from '@/apis/einvoice/services/document-template-customization-settings-api';
import { RootSpinner, ShowErrorModal, SuccessToastify, TabFeedback } from '@/components';
import { Save, Search } from '@/components/Buttons';
import { PageStyle } from '@/constants/page';
import { useApi } from '@/hooks';
import { faBarcode, faEdit, faInfo, faLandmark, faListAlt, faNotesMedical } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Skeleton, Tabs } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';
import BankInformation from './BankInformation';
import { BarcodesInformation } from './Borcodes';
import { CustomizationTableAdditionalInformations } from './CustomizationTableAdditionalInformations';
import DocumenttemplatesPreview, { DocumenttemplatesPreviewProps } from './DocumentTemplatesPreview';
import GeneralTab from './General';
import Notes from './Notes';

const { TabPane } = Tabs;

export const CustomizationTemplatesUpsert = ({
  api,
  apiWithBlobResponse,
  route,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  MainTab,
  editSignature
}: {
  api: DocumentTemplateCustomizationSettingsApi;
  apiWithBlobResponse: DocumentTemplateCustomizationSettingsApi;
  route: 'einvoice' | 'earchive' | 'edespatch' | 'esmm' | 'emm';
  MainTab: any;
  editSignature: boolean;
}) => {
  const history = useHistory();
  const { id } = useParams<any>();

  /**
   * Insert işlemi için kullanılan fonksiypn
   */
  const postRequest = useApi<DocumentTemplateCustomizationSetting, V1DefinitionsDocumenttemplatesCustomizationsettingsPostRequest>({
    asyncFunction: api.v1DefinitionsDocumenttemplatesCustomizationsettingsPost,
    successCallback: ({ data }) => {
      history.push(`/${route}/definations/custom-templates/${data.id}`);
    },
    errorCallback: (error) => {
      if (error?.response?.status === 409) {
        history.push(`/${route}/definations/custom-templates/${error?.response?.data}`);
      }
    }
  });

  useEffect(() => {
    if (!id) {
      postRequest.call({
        documentTemplateCustomizationSettingDto: {
          eDocumentTemplateType: EDocumentTemplateType._1,
          fontSize: 12,
          fontIsBold: false,
          title: 'Başlıksız Tasarım',
          isDefault: false
        }
      });
    }
  }, []);

  const [data, setData] = useState<DocumentTemplateCustomizationSettingDto>({
    eDocumentTemplateType: EDocumentTemplateType._1,
    fontSize: 12,
    title: 'Başlıksız Tablo',
    isDefault: false,
    fontIsBold: false
  });

  const [readyToUse, setReadyToUse] = useState(false);

  const getRequest = useApi<DocumentTemplateCustomizationSetting, V1DefinitionsDocumenttemplatesCustomizationsettingsIdGetRequest>({
    asyncFunction: api.v1DefinitionsDocumenttemplatesCustomizationsettingsIdGet,
    successCallback: (response) => {
      setReadyToUse(response.data.isReadyToUse);
      setData(response.data);
    },
    errorCallback: (error) => {
      if (error?.response?.status === 404) {
        history.push(`/${route}/definations/custom-templates`);
      }
    }
  });

  useEffect(() => {
    getRequest.call({ id: id });
  }, []);

  const putRequest = useApi<void, V1DefinitionsDocumenttemplatesCustomizationsettingsIdPutRequest>({
    asyncFunction: api.v1DefinitionsDocumenttemplatesCustomizationsettingsIdPut,
    successCallback: (_, request) => {
      if (request) {
        SuccessToastify('Değişiklikler kayıt edildi.');
        history.push(`/${route}/definations/custom-templates`);
      }
    },
    errorCallback: (error) => {
      ShowErrorModal(error);
    }
  });

  const [previewModalState, setPreviewModalState] = useState<DocumenttemplatesPreviewProps>({
    visible: false,
    documentId: '',
    jsonContent: ''
  });
  return (
    <RootSpinner loading={putRequest.loading}>
      <Helmet title={'API Tanımları'} />
      <DocumenttemplatesPreview
        closeModal={() => setPreviewModalState({ visible: false, documentId: '', jsonContent: '' })}
        postXlstsPreview={api.v1DefinitionsDocumenttemplatesCustomizationsettingsIdPreviewPost}
        modalState={previewModalState}
      />

      <Card>
        <CardHeader className={PageStyle.card__header}>
          <div className="justify-content-center mr-auto utils_custom_title d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block">
            {<FontAwesomeIcon icon={faEdit} color="#0ab1fc" style={{ marginRight: '10px' }} />}
            Tasarım Düzenle
          </div>
          {!getRequest.loading && (
            <div>
              <Search title="Önizle" onClick={() => setPreviewModalState({ visible: true, documentId: id, jsonContent: JSON.stringify(data) })} />{' '}
              <Save onClick={() => putRequest.call({ documentTemplateCustomizationSettingDto: data, id: id })} />
            </div>
          )}
        </CardHeader>

        <CardBody className={PageStyle.virtualized__card__body}>
          <Skeleton active loading={getRequest.loading}>
            {(route === 'earchive' || route === 'esmm') && !readyToUse && (
              <Alert
                description="Tasarımı kullanabilmek için yetkili imzası yüklenmeldir."
                message="Yetkili İmzası"
                style={{ marginTop: 10 }}
                type="error"
                showIcon
              />
            )}

            <div className="card-container p-0" style={{ marginTop: 10 }}>
              <Tabs defaultActiveKey="general" animated={false} type="card">
                <TabPane forceRender={true} tab={<TabFeedback icon={faListAlt} title={'Logo / İmza ve Genel Bilgiler'} isValid={undefined} />} key="general">
                  <GeneralTab
                    getLogo={apiWithBlobResponse.v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoGet}
                    getSignature={apiWithBlobResponse.v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureGet}
                    getStamp={apiWithBlobResponse.v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampGet}
                    postLogo={api.v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoPost}
                    postSignature={api.v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignaturePost}
                    postStamp={api.v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampPost}
                    deleteLogo={api.v1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoDelete}
                    deleteSignature={api.v1DefinitionsDocumenttemplatesCustomizationsettingsIdSignatureDelete}
                    deleteStamp={api.v1DefinitionsDocumenttemplatesCustomizationsettingsIdStampDelete}
                    onChange={(value) => {
                      if (
                        value.title !== data.title ||
                        value.fontSize !== data.fontSize ||
                        value.fontIsBold !== data.fontIsBold ||
                        value.isDefault !== data.isDefault ||
                        value.eDocumentTemplateType !== data.eDocumentTemplateType ||
                        (data.otherGeneralSettings !== undefined && !_.isEqual(value.otherGeneralSettings, data.otherGeneralSettings))
                      ) {
                        setData({ ...data, ...value });
                      }
                    }}
                    onRemoteImageChanged={(process) => setReadyToUse(process === 'delete' ? false : true)}
                    documentTemplateCustomizationSetting={data}
                    id={id}
                    MainTab={MainTab}
                    editSignature={editSignature}
                  />
                </TabPane>

                <TabPane forceRender={true} tab={<TabFeedback icon={faLandmark} title={'Banka Bilgileri'} isValid={undefined} />} key="bankInformation">
                  <BankInformation
                    onChange={(bankInformation) => setData({ ...data, bankInformation: bankInformation })}
                    documentTemplateCustomizationSetting={data}
                  />
                </TabPane>

                <TabPane forceRender={true} tab={<TabFeedback icon={faNotesMedical} title={'Sabit Notlar'} isValid={undefined} />} key="notes">
                  <Notes onChange={(notes) => setData({ ...data, notes })} documentTemplateCustomizationSetting={data} />
                </TabPane>

                <TabPane
                  forceRender={true}
                  tab={<TabFeedback icon={faInfo} title={'Özelleştirme Tablosu Ek Bilgiler'} isValid={undefined} />}
                  key="customizationTable"
                >
                  <CustomizationTableAdditionalInformations
                    documentTemplateCustomizationSetting={data}
                    onChange={(customizationTableAdditionalInformations) => setData({ ...data, customizationTableAdditionalInformations })}
                  />
                </TabPane>

                <TabPane forceRender={true} tab={<TabFeedback icon={faBarcode} title={'Barkodlar'} isValid={undefined} />} key="barcodes">
                  <BarcodesInformation documentTemplateCustomizationSetting={data} onChange={(barcodes) => setData({ ...data, barcodes })} />
                </TabPane>
              </Tabs>
            </div>
          </Skeleton>
        </CardBody>
      </Card>
    </RootSpinner>
  );
};
