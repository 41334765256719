/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { IDType, TaxSchemeType } from '.';
import { NS_CAC, NS_CBC } from '../constants';

/*
  27.04.2021 : Fully completed
*/
export class PartyTaxSchemeType {
  @XMLElement({ types: [{ name: 'RegistrationName', namespaceUri: NS_CBC, itemType: () => String }] })
  registrationName?: string;

  @XMLElement({ types: [{ name: 'CompanyID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  companyID?: IDType;

  @XMLElement({ types: [{ name: 'TaxScheme', namespaceUri: NS_CAC, itemType: () => TaxSchemeType }] })
  taxScheme?: TaxSchemeType;
}

export default PartyTaxSchemeType;
