/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { PartyType } from '.';
import { NS_CAC } from '../constants';

/*
  27.04.2021 : Partially completed
  cac:DeliveryContact
*/
export class CustomerPartyType {
  @XMLElement({ types: [{ name: 'Party', namespaceUri: NS_CAC, itemType: () => PartyType }] })
  party?: PartyType;

  // TODO : cac:DeliveryContact
}

export default CustomerPartyType;
