/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { NS_CBC } from '../constants';

/*
  27.04.2021 : Partially completed
  cac:OtherCommunication
*/
export class ContactType {
  @XMLElement({ types: [{ name: 'ID', namespaceUri: NS_CBC, itemType: () => String }] })
  id?: string;

  @XMLElement({ types: [{ name: 'Name', namespaceUri: NS_CBC, itemType: () => String }] })
  name?: string;

  @XMLElement({ types: [{ name: 'Telephone', namespaceUri: NS_CBC, itemType: () => String }] })
  telephone?: string;

  @XMLElement({ types: [{ name: 'Telefax', namespaceUri: NS_CBC, itemType: () => String }] })
  telefax?: string;

  @XMLElement({ types: [{ name: 'ElectronicMail', namespaceUri: NS_CBC, itemType: () => String }] })
  electronicMail?: string;

  @XMLElement({ types: [{ name: 'Note', namespaceUri: NS_CBC, itemType: () => String }] })
  note?: string;

  // TODO : cac:OtherCommunication
}

export default ContactType;
