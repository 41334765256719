import { Tooltip } from 'antd';
import _ from 'lodash';
import React, { memo } from 'react';

export interface TooltipInfo {
  value?: string | null;
  size: number;
  title?: string;
  makeTooltipTitle?: boolean;
}

export const SmartTooltip = memo(({ data }: { data: TooltipInfo[] }) => {
  if (!data.every((x) => x)) {
    return null;
  }

  const values = data.filter((x) => x.value !== null);

  let makeTooltip = false;
  for (let index = 0; index < values.length; index++) {
    const element = values[index];
    if (element.value && element.value.length > element.size) {
      makeTooltip = true;
      break;
    }
  }

  if (!makeTooltip) {
    return (
      <div>
        {values.map((item, index) => (
          <div key={index}>
            {(item.value || '').length > 0 && (
              <>
                {item.title !== undefined && <b>{item.title} : </b>}
                {item.value}
              </>
            )}
          </div>
        ))}
      </div>
    );
  }

  return (
    <Tooltip
      title={
        <React.Fragment>
          {values.map((item, index) => (
            <div key={index}>
              {item.makeTooltipTitle === true && <b>{item.title} : </b>}
              {item.value}
            </div>
          ))}
        </React.Fragment>
      }
      placement="bottom"
      trigger="hover"
    >
      <div>
        {values.map(
          (item, index) =>
            (item.value || '').length > 0 && (
              <div key={index}>
                {item.title && <b>{item.title} : </b>}
                {item.value && _.truncate(item.value, { length: item.size })}
              </div>
            )
        )}
      </div>
    </Tooltip>
  );
});

export default SmartTooltip;
