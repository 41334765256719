import { Months } from '@/constants/option-items';
import { Select } from 'antd';
import { Select as FormikSelect } from 'formik-antd';
import React from 'react';
export const Month = ({ defaultValue, onChange, name }: { defaultValue?: string; onChange?: (value: string) => void; name?: string }) => {
  const option = [{ value: 'None', text: 'Seçiniz...' }, ...Months].map((option) => (
    <Select.Option key={option.value} value={option.value}>
      {option.text}
    </Select.Option>
  ));

  return name ? (
    <FormikSelect name={name || 'month'} className="w-100" onChange={onChange} defaultValue={defaultValue === undefined ? 'None' : defaultValue}>
      {option}
    </FormikSelect>
  ) : (
    <Select className="w-100" onChange={onChange} defaultValue={defaultValue === undefined ? 'None' : defaultValue}>
      {option}
    </Select>
  );
};

export default Month;
