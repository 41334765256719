/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLAttribute, XMLText } from '@/xmldom-decorators';

/*
  27.04.2021 : Fully completed
*/
export class IDType {
  @XMLAttribute()
  schemeID?: string;

  @XMLText()
  value?: string;
}

export default IDType;
