// tslint:disable
// eslint:disable
/**
 * General API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum AnnouncementCategory {
    UnCategorized = 'UnCategorized',
    DevelopmentAnnouncement = 'DevelopmentAnnouncement',
    Announcement = 'Announcement',
    Reminder = 'Reminder'
}



