import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';
export const NoProcess = ({ title = intl.get('VARSAYILAN_KAYIT_UZERINDE_HERHANGINI_BIR_ISLEM_YAPILAMAZ') }: { title?: string }) => {
  return (
    <Tooltip trigger="hover" placement="bottomRight" title={title}>
      <Button style={{ width: '48px', textAlign: 'center' }} size={'sm'} key="btn-info" color={'primary'} className="btn-labeled">
        <FontAwesomeIcon size={'lg'} icon={faInfoCircle} />
      </Button>
    </Tooltip>
  );
};

export default NoProcess;
