import { Badge } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import styles from './style.module.scss';

export const SelectedCount = ({ count }: { count: number }) => {
  return (
    <div className={styles.selected__count} style={{ display: count === 0 ? 'none' : 'block' }}>
      <Badge count={count} overflowCount={500} />
      {'  '}
      <p>{intl.get('KAYIT_SECILI')}</p>
    </div>
  );
};

export default SelectedCount;
