import { DateFormat } from '@/constants/page';
import { TimePicker } from 'antd';
import moment from 'moment';
import React from 'react';

const GibTimePicker = (props: { placeholder?: string; value?: string; name?: string; onChange: (value?: string) => void }) => {
  const { placeholder, value, name, onChange, ...ownProps } = props;

  return (
    <TimePicker
      {...ownProps}
      value={value ? moment(value, DateFormat.HMS) : undefined}
      onChange={(value) => {
        onChange(value === null || value === undefined ? undefined : value.format(DateFormat.HMS));
      }}
      onBlur={(element) => {
        const value = element.target.value;
        onChange(value.isNullOrUndefinedOrEmpty() ? undefined : value);
      }}
      format={DateFormat.HMS}
      name={name}
      className="w-100"
      showSecond
      allowClear={true}
      placeholder={placeholder}
    />
  );
};

export default GibTimePicker;
