/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLArray, XMLElement } from '@/xmldom-decorators';
import { AddressType, CodeType, ContactType, IDType, LocationType, PartyIdentificationType, PartyNameType, PartyTaxSchemeType, PersonType } from '.';
import { NS_CAC, NS_CBC } from '../constants';
import PartyLegalEntityType from './PartyLegalEntityType';

/*
  27.04.2021 : Partially completed
  cac:PartyLegalEntity
*/
export class PartyType {
  @XMLElement({ types: [{ name: 'WebsiteURI', namespaceUri: NS_CBC, itemType: () => String }] })
  websiteURI?: string = '';

  @XMLElement({ types: [{ name: 'EndpointID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  endpointID?: IDType;

  @XMLElement({ types: [{ name: 'IndustryClassificationCode', namespaceUri: NS_CBC, itemType: () => CodeType }] })
  industryClassificationCode?: CodeType;

  @XMLArray({ nested: false, itemTypes: [{ name: 'PartyIdentification', namespaceUri: NS_CAC, itemType: () => PartyIdentificationType }] })
  partyIdentification: PartyIdentificationType[] = [];

  @XMLElement({ types: [{ name: 'PartyName', namespaceUri: NS_CAC, itemType: () => PartyNameType }] })
  partyName?: PartyNameType;

  @XMLElement({ types: [{ name: 'PostalAddress', namespaceUri: NS_CAC, itemType: () => AddressType }] })
  postalAddress?: AddressType;

  @XMLElement({ types: [{ name: 'PhysicalLocation', namespaceUri: NS_CAC, itemType: () => LocationType }] })
  physicalLocation?: LocationType;

  @XMLElement({ types: [{ name: 'PartyTaxScheme', namespaceUri: NS_CAC, itemType: () => PartyTaxSchemeType }] })
  partyTaxScheme?: PartyTaxSchemeType;

  @XMLArray({ nested: false, itemTypes: [{ name: 'PartyLegalEntity', namespaceUri: NS_CAC, itemType: () => PartyLegalEntityType }] })
  partyLegalEntity?: PartyLegalEntityType[] = [];

  @XMLElement({ types: [{ name: 'Contact', namespaceUri: NS_CAC, itemType: () => ContactType }] })
  contact?: ContactType;

  @XMLElement({ types: [{ name: 'Person', namespaceUri: NS_CAC, itemType: () => PersonType }] })
  person?: PersonType;

  @XMLElement({ types: [{ name: 'AgentParty', namespaceUri: NS_CAC, itemType: () => PartyType }] })
  agentParty?: PartyType;
}

export default PartyType;
