//TODO : if auth is fault, routing login page
import { Spin } from 'antd';
import React from 'react';
import Loader from 'react-loaders';
import './style.scss';

export const Authenticating = () => {
  return (
    <div className="spin-container">
      <Spin indicator={<Loader active type="ball-clip-rotate-multiple" />} spinning={true} tip={`Lütfen bekleyiniz...`} />
    </div>
  );
};
