import { EntryType, History, HistoryPaginationResult } from '@/apis/einvoice/interfaces';
import { DocumentInfo, ErrorWrapper } from '@/components';
import { Close } from '@/components/Buttons';
import LocalPagination from '@/components/Pagination/LocalPagination';
import { TableDefaultLoading } from '@/components/TableBody';
import { DocumentModal, Pagination } from '@/interfaces';
import { faHistory } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Table } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import _ from 'lodash';
import moment from 'moment';
import React, { memo, useState } from 'react';
import intl from 'react-intl-universal';
const Color = (type?: EntryType) => {
  switch (type) {
    case EntryType.Completed:
      return 'success';
    case EntryType.Error:
      return 'error';
    case EntryType.Warning:
      return 'warning';
    case EntryType.Info:
      return 'processing';
    case EntryType.None:
      return 'default';
    default:
      return 'default';
  }
};

export interface DocumentHistoriesModalAdditionalProps {
  refetch: (uuid?: string, documentNumber?: string, pagination?: Pagination, additionalProps?: DocumentHistoriesModalAdditionalProps) => void;
}

export const DocumentHistoriesModal = memo(
  ({
    closeModal,
    visible,
    documentNumber,
    uuid,
    data,
    loading,
    error,
    additionalProps
  }: DocumentModal<HistoryPaginationResult, DocumentHistoriesModalAdditionalProps>) => {
    const [pagination, setPagination] = useState({ page: 1, pageSize: 10, sort: 'CreatedAt desc' });

    return (
      <Modal
        centered
        title={
          <span>
            <FontAwesomeIcon icon={faHistory} /> {intl.get('ISLEM_GECMISI')}
          </span>
        }
        onCancel={closeModal}
        width={1024}
        visible={visible}
        footer={[<Close key="close-button" onClick={closeModal} />]}
      >
        <DocumentInfo documentNumber={documentNumber || ''} uuid={uuid || ''} />
        <Table
          showHeader={data !== undefined}
          components={ErrorWrapper({ error })}
          style={{ marginTop: '10px' }}
          scroll={{ x: true, y: 512 }}
          loading={TableDefaultLoading(loading)}
          bordered
          pagination={false}
          dataSource={(data && data.data) || []}
          rowKey={(record) => record.createdAt}
        >
          <Table.Column
            key="entryType"
            title={intl.get('ACIKLAMA')}
            dataIndex="entryType"
            render={(text, record: History) => <Badge status={Color(record.entryType)} text={record.message} />}
          />
          <Table.Column
            width={120}
            key="createdAt"
            title={intl.get('TARIH')}
            dataIndex="createdAt"
            render={(text) => <b>{moment(text).format('DD.MM.YYYY HH:mm')}</b>}
          />
          <Table.Column key="userName" width={160} title={intl.get('KULLANICI_ADI')} dataIndex="userName" />
          <Table.Column key="clientIp" width={120} title={intl.get('IP')} dataIndex="clientIp" />
        </Table>

        {data && data.data && data.data.length > 0 && (
          <LocalPagination
            data={data}
            selectedCount={0}
            execute={() => additionalProps && additionalProps.refetch(uuid, documentNumber, pagination, additionalProps)}
            page={pagination.page}
            pageSize={pagination.pageSize}
            onPageChange={(page, pageSize) => {
              setPagination({ ...pagination, page, pageSize });
            }}
          />
        )}
      </Modal>
    );
  },
  (prev, next) => _.isEqual(prev.data, next.data) && prev.visible === next.visible && prev.loading === next.loading
);
