import { V1DefinitionsSeriesIdYearSetnumberNextNumberGetRequest } from '@/apis/einvoice';
import { RootSpinner, SuccessToastify } from '@/components';
import { Close, Save } from '@/components/Buttons';
import { PageConst } from '@/constants/page';
import { useApi } from '@/hooks';
import { faPencilAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Modal, Row } from 'antd';
import { AxiosPromise } from 'axios';
import { Formik, FormikProps } from 'formik';
import { Form, FormItem, Input } from 'formik-antd';
import React, { useRef } from 'react';
import intl from 'react-intl-universal';
import * as Yup from 'yup';
import { ErrorViewer } from '../TableBody';
export interface UpsetSerieUpdateNumberModalProps {
  visible: boolean;
  serieId: string;
  currentNumber: string;
  year: number;
}
const UpsetSerieUpdateNumberModal = ({
  modalState: { visible, serieId, year, currentNumber },
  closeModal,
  refresh,
  getSeriesIdYearSetnumberNextNumber
}: {
  modalState: UpsetSerieUpdateNumberModalProps;
  closeModal: () => void;
  getSeriesIdYearSetnumberNextNumber: (requestParameters: V1DefinitionsSeriesIdYearSetnumberNextNumberGetRequest) => AxiosPromise<void>;
  refresh: () => void;
}) => {
  let form = (useRef(null) as unknown) as FormikProps<V1DefinitionsSeriesIdYearSetnumberNextNumberGetRequest>;

  const { clear, loading, call, error } = useApi<void, V1DefinitionsSeriesIdYearSetnumberNextNumberGetRequest>({
    asyncFunction: getSeriesIdYearSetnumberNextNumber,
    successCallback: (_, request) => {
      if (request) {
        SuccessToastify(intl.getHTML('BASARI_ILE_SERI_GUNCELLENDI', { nextNumber: request.nextNumber }));
        refresh();
        closeModal();
      }
    }
  });
  const UpsetSerieUpdateNumberModalScheme = Yup.object().shape<{ nextNumber: number }>({
    nextNumber: Yup.number()
      .nullable()
      .required()
      .test('test-nextNumber', 'Lütfen aktif değerden büyük değer giriniz.', (value) => {
        if (value && value !== 0 && value > Number(currentNumber)) return true;
        return false;
      })
  });

  return (
    <Modal
      onCancel={closeModal}
      centered
      width={PageConst.MediumModalWidth}
      transitionName="fade"
      maskClosable={false}
      visible={visible}
      closeIcon
      afterClose={() => {
        clear();
      }}
      footer={[
        <React.Fragment key="series-number-upsert-modal-footer">
          <Close onClick={closeModal} />
          <Save onClick={() => form.isValid && form.handleSubmit()} />
        </React.Fragment>
      ]}
      destroyOnClose
    >
      <RootSpinner loading={loading}>
        <Formik<{ nextNumber: number }>
          validateOnBlur={false}
          innerRef={(instance) => (form = instance)}
          onSubmit={(values) => {
            if(form.isValid)
            serieId && year && call({ id: serieId, year: year, nextNumber: values.nextNumber });
          }}
          validationSchema={UpsetSerieUpdateNumberModalScheme}
          initialValues={{
            nextNumber: Number(currentNumber)
          }}
        >
          <Form layout="vertical">
            <Row>
              <Col sm={24} xs={24}>
                <FormItem name="nextNumber" label={'Yeni Numara'} required>
                  <Input type="number" prefix={<FontAwesomeIcon icon={faPencilAlt} />} name="nextNumber" placeholder={'Lütfen sayı giriniz.'} tabIndex={0} />
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Formik>
        {error && <ErrorViewer error={error} />}
      </RootSpinner>
    </Modal>
  );
};

export default UpsetSerieUpdateNumberModal;
