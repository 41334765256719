/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { NS_CBC } from '../constants';
import IDType from './IDType';

/*
  27.04.2021 : Fully completed
*/
export class ExternalReferenceType {
  @XMLElement({ types: [{ name: 'URI', namespaceUri: NS_CBC, itemType: () => IDType }] })
  uri?: IDType;
}

export default ExternalReferenceType;
