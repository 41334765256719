import Icon from '@ant-design/icons';
import { Alert, Button, Modal, Slider, Upload } from 'antd';
import 'cropperjs/dist/cropper.css';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import ReactCropper, { ReactCropperElement } from 'react-cropper';
import Resizer from 'react-image-file-resizer';
import './style.scss';

const extensions = '.jpeg,.jpg,.png';

const ImageCropper = forwardRef(
  (
    {
      croppedImage,
      name,
      onChange,
      hideCropedResult,
      croppedImageWidth,
      croppedImageHeight
    }: { croppedImage?: string; name: string; croppedImageWidth?: number; croppedImageHeight?: number; hideCropedResult?: boolean; onChange?: (data) => void },
    ref
  ) => {
    const [state, setState] = useState<{ src?: string | ArrayBuffer | null; refresh: boolean }>({
      src: undefined,
      refresh: false
    });

    const [cropResult, setCropResult] = useState(croppedImage);

    const cropperRef = useRef<ReactCropperElement>(null);
    const uploadButton = useRef<HTMLButtonElement>(null);
    const preview = useRef<HTMLImageElement>(null);

    const props = {
      beforeUpload() {
        return false;
      },
      multiple: false,
      showUploadList: false,
      name: 'file'
    };

    useImperativeHandle(ref, () => ({
      cropImage: () => {
        const result = cropperRef.current?.cropper.getCroppedCanvas({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          width: preview.current!.offsetWidth,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          height: preview.current!.offsetHeight,
          imageSmoothingEnabled: true,
          imageSmoothingQuality: 'high',
          fillColor: 'white'
        });

        const dataUrl = result!.toDataURL('image/jpeg', 1);

        setCropResult(dataUrl);

        return dataUrl;
      },
      selectImage: () => {
        uploadButton.current?.click();
      },
      clear: () => {
        setState({ refresh: false, src: undefined });
      }
    }));

    return (
      <div>
        {(state.refresh === true || (state.src !== undefined && croppedImage === undefined)) && (
          <div>
            <div style={{ height: 300 }}>
              <div>
                <div style={{ width: '60%', height: '300px', float: 'left' }}>
                  <ReactCropper
                    name={name}
                    ref={cropperRef}
                    style={{
                      height: 250,
                      width: '100%',
                      marginBottom: '10px'
                    }}
                    rotatable={true}
                    aspectRatio={NaN}
                    background={false}
                    preview=".img-preview"
                    guides={false}
                    src={state.src as string}
                  />
                  <span>Resmi Döndür</span>
                  <Slider
                    className={'no-track'}
                    onChange={(value) => {
                      cropperRef.current?.cropper.rotateTo(value);
                    }}
                    defaultValue={0}
                    tooltipPlacement="bottom"
                    min={-180}
                    max={180}
                  />
                </div>

                <div style={{ width: '38%', float: 'right', height: 200 }}>
                  <p>
                    <b>Önizleme</b>
                  </p>

                  <div ref={preview} className="img-preview" style={{ width: croppedImageWidth || 250, float: 'left', height: croppedImageHeight || 160 }} />
                </div>
              </div>
            </div>

            <Alert
              message="Mavi kutunun resmi tam olarak kapladığından emin olunuz. Kenarlarda boşluk kalması evrak üzerinde imzanın daha uzak görünmesine sebep olacaktır."
              type="warning"
              showIcon
            />
          </div>
        )}

        {state.src === undefined && croppedImage === undefined && (
          <React.Fragment>
            <Alert
              message={
                <span>
                  Yalnızca <b>{extensions}</b> formatında dosyalar seçilebilir! <b>Dosya Seç</b> butonu ile seçim yapabilirsiniz.
                </span>
              }
              type="info"
            />
          </React.Fragment>
        )}

        {cropResult !== undefined && (hideCropedResult || false) === false && (
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <h2>Kırpılmış olan imza</h2>

            <img className="crop-result" src={cropResult} />
          </div>
        )}

        <Upload
          className="d-none"
          {...props}
          accept={extensions}
          onChange={(info) => {
            const extension = (info.file.name as string).substr(info.file.name.lastIndexOf('.')).toLowerCase();
            if (extensions.indexOf(extension) === -1) {
              Modal.error({
                title: <b>Geçersiz Dosya Formatı</b>,
                content: (
                  <div>
                    Yalnızca <b>{extensions}</b> tipinde resim dosyaları yüklenebilir! <br />
                    <br />
                    <b>{extension}</b> tipinde dosya yüklenemez!
                  </div>
                )
              });

              return false;
            }

            const file = info.fileList.filter((x) => x.uid === info.file.uid)[0].originFileObj!;

            Resizer.imageFileResizer(
              file, // Is the file of the image which will resized.
              1920, // Is the maxWidth of the resized new image.
              1080, // Is the maxHeight of the resized new image.
              file.type.indexOf('png') >= 0 ? 'PNG' : 'JPEG', // Is the compressFormat of the resized new image.
              100, // Is the quality of the resized new image.
              0, // Is the degree of clockwise rotation to apply to uploaded image.
              (uri) => {
                setState({ ...state, src: uri as string, refresh: true });
              }, // Is the callBack function of the resized new image URI.
              'base64'
            );

            onChange && onChange(info.fileList);
          }}
        >
          <Button type="primary" style={{ width: '100%' }} ref={uploadButton} className="d-none">
            <Icon type="upload" /> Dosya Seç
          </Button>
        </Upload>
      </div>
    );
  }
);

export default ImageCropper;
