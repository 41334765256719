import { AttachmentType } from '@/models/xmlDocuments/cac';
import { AnyType } from '@/type';
import { AxiosResponse } from 'axios';

export function getFileNameFromContentDisposition(response: AxiosResponse<AnyType>) {
  const header = response.headers['content-disposition'];
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(header);
  if (matches != null && matches[1]) {
    return matches[1].replace(/['"]/g, '');
  }
}

export function getFileExtension(fileName: string) {
  const extension = fileName.match(/\.[0-9a-z]+$/i);
  return extension ? extension[0] : '';
}

export function getFileSizeForKb(file: File) {
  return file.size / 1024;
}

export function base64toBlob(base64Data, options?: BlobPropertyBag) {
  const byteString = atob(base64Data);

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], options);
}


export function dataURLtoFile(dataurl, filename) {
 
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  
  return new File([u8arr], filename, {type:mime});
}


export function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
}

export const attachmentToFile = (attachment?: AttachmentType) => {
  const fileName = attachment && attachment.embeddedDocumentBinaryObject?.filename;
  const value = attachment && attachment.embeddedDocumentBinaryObject?.value;
  const mimeCode = attachment && attachment.embeddedDocumentBinaryObject?.mimeCode;

  if (fileName && value && mimeCode) {
    return new File([base64toBlob(value)], fileName, {
      type: mimeCode
    });
  }

  return null;
};
