import React, { memo } from 'react';
import intl from 'react-intl-universal';
import { SmartTooltip } from '.';

export const ElectronicmailWebSiteUri = memo(({ electronicMail, websiteUri }: { electronicMail?: string | null; websiteUri?: string | null }) => {
  return (
    <SmartTooltip
      data={[
        { size: 25, title: intl.get('MAIL'), value: electronicMail, makeTooltipTitle: false },
        { size: 25, title: intl.get('WEB_SITE'), value: websiteUri, makeTooltipTitle: false }
      ]}
    />
  );
});

export default ElectronicmailWebSiteUri;
