import { ErrorViewer, Loading } from '@/components/TableBody';
import { ApiError } from '@/interfaces';
import { AnyType } from '@/type';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { faBan, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Spin } from 'antd';
import { AxiosError } from 'axios';
import React, { ReactNode } from 'react';
import intl from 'react-intl-universal';
const confirmModal = Modal.confirm;

export interface ApiInConfirmationProps {
  title?: string | ReactNode;
  content: ReactNode;
  successCallback?: (response) => void;
  errorCallback?: (error: AxiosError<ApiError>) => void;
}

export const useApiInConfirmation = <T extends AnyType>({ asyncFunction }: { asyncFunction }) => {
  const confirm = (params: T, { title = intl.get('EMIN_MISINIZ'), content, successCallback, errorCallback }: ApiInConfirmationProps) => {
    const modal = confirmModal({
      icon: <QuestionCircleOutlined />,
      title,
      content,
      width: 720,
      visible: true,
      okType: 'danger',
      okText: (
        <>
          <FontAwesomeIcon icon={faCheck} className="btn-label" />
          <span>{intl.get('EVET')}</span>
        </>
      ),
      cancelText: (
        <>
          <FontAwesomeIcon icon={faBan} className="btn-label" />
          <span>{intl.get('HAYIR')}</span>
        </>
      ),
      okButtonProps: {
        onClick: () => {
          modal.update({
            okButtonProps: { className: 'btn-default btn-labeled btn-sm disabled', prefix: 'btn', prefixCls: 'btn', disabled: true },
            cancelButtonProps: { className: 'btn-default btn-labeled btn-sm disabled', prefix: 'btn', prefixCls: 'btn', disabled: true },
            content: (
              <Spin spinning={true} indicator={<Loading />}>
                {content}
              </Spin>
            )
          });

          return asyncFunction(params).then((response: AnyType) => {
            if (response.isAxiosError) {
              if (errorCallback) errorCallback(response);

              modal.update({
                okButtonProps: { style: { display: 'none' } },
                cancelButtonProps: { className: 'btn-danger btn-labeled btn-sm', prefix: 'btn', prefixCls: 'btn', disabled: false },
                cancelText: (
                  <>
                    <FontAwesomeIcon icon={faTimes} className="btn-label" />
                    <span>{intl.get('KAPAT')}</span>
                  </>
                ),
                content: <ErrorViewer error={response} />
              });
            } else {
              if (successCallback) successCallback(response);
              modal.destroy();
            }
          });
        },
        className: 'btn-warning btn-labeled btn-sm',
        prefix: 'btn',
        prefixCls: 'btn'
      },
      cancelButtonProps: {
        className: 'btn-outlined-primary btn-labeled btn-sm',
        prefix: 'btn',
        prefixCls: 'btn'
      }
    });
  };

  return { confirm };
};

export default useApiInConfirmation;
