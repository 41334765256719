export {};

declare global {
  interface String {
    isNullOrUndefinedOrEmpty(): boolean;
    getLineIndex(): string;
    isNumeric(): boolean;
  }
}

String.prototype.isNullOrUndefinedOrEmpty = function () {
  return this === null || this === undefined || this === '';
};

String.prototype.getLineIndex = function () {
  return this.substring(this.indexOf('.') + 1);
};

String.prototype.isNumeric = function () {
  if (typeof this != 'string') return false; // we only process strings!
  return !isNaN(parseFloat(this)); // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)... // ...and ensure strings of whitespace fail
};
