import { OnClickProps } from '@/interfaces';
import { faUndo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuItem from 'antd/lib/menu/MenuItem';
import React from 'react';
import intl from 'react-intl-universal';

export const SetUnCanceled = (props: OnClickProps) => {
  return (
    <MenuItem {...props}>
      <FontAwesomeIcon className={'mr-2'} icon={faUndo} />
      {intl.get('IPTALI_GERI_Al')}
    </MenuItem>
  );
};

export default SetUnCanceled;
