// tslint:disable
// eslint:disable
/**
 * E-MM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApproveAndSendResponse } from '../interfaces';
// @ts-ignore
import { UploadDocumentPreviewResponse } from '../interfaces';
// @ts-ignore
import { UploadDocumentPreviewType } from '../interfaces';
// @ts-ignore
import { UploadDocumentResponse } from '../interfaces';
/**
 * UploadsApi - axios parameter creator
 * @export
 */
export const UploadsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Belge yüklemek için bu uç kullanılabilir    **Limit** : File maks 1MB olabilir.
         * @summary Belge yükler
         * @param {any} file **UBL-TR Xml formatında zipli yada açık halde dosya**
         * @param {boolean} isDirectSend 
         * @param {UploadDocumentPreviewType} previewType 
         * @param {string} sourceApp Belgenin iletildiği kaynak uygulama adı  This filed is mandotary.  Set source application unique key to identify it
         * @param {boolean} autoSaveCompany 
         * @param {string} [documentTemplate] Özel olarak kullanılmak istenen, sistemde tanımlı XSLT başlığı
         * @param {string} [sourceAppRecordId] Belgenin iletildiği kaynak uygulamadaki benzersiz kaydın id\\\&#39;si  This filed is optional.  Set source application reference to keep track it
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UploadsDocumentPost: async (requestParameters: V1UploadsDocumentPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            if (requestParameters.file === null || requestParameters.file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling v1UploadsDocumentPost.');
            }
            // verify required parameter 'isDirectSend' is not null or undefined
            if (requestParameters.isDirectSend === null || requestParameters.isDirectSend === undefined) {
                throw new RequiredError('isDirectSend','Required parameter isDirectSend was null or undefined when calling v1UploadsDocumentPost.');
            }
            // verify required parameter 'previewType' is not null or undefined
            if (requestParameters.previewType === null || requestParameters.previewType === undefined) {
                throw new RequiredError('previewType','Required parameter previewType was null or undefined when calling v1UploadsDocumentPost.');
            }
            // verify required parameter 'sourceApp' is not null or undefined
            if (requestParameters.sourceApp === null || requestParameters.sourceApp === undefined) {
                throw new RequiredError('sourceApp','Required parameter sourceApp was null or undefined when calling v1UploadsDocumentPost.');
            }
            // verify required parameter 'autoSaveCompany' is not null or undefined
            if (requestParameters.autoSaveCompany === null || requestParameters.autoSaveCompany === undefined) {
                throw new RequiredError('autoSaveCompany','Required parameter autoSaveCompany was null or undefined when calling v1UploadsDocumentPost.');
            }
            const localVarPath = `/v1/uploads/document`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


                if (requestParameters.file !== undefined) { 
                    localVarFormParams.append('File', requestParameters.file as any);
                }

                if (requestParameters.isDirectSend !== undefined) { 
                    localVarFormParams.append('IsDirectSend', requestParameters.isDirectSend as any);
                }

                if (requestParameters.previewType !== undefined) { 
                    localVarFormParams.append('PreviewType', requestParameters.previewType as any);
                }

                if (requestParameters.documentTemplate !== undefined) { 
                    localVarFormParams.append('DocumentTemplate', requestParameters.documentTemplate as any);
                }

                if (requestParameters.sourceApp !== undefined) { 
                    localVarFormParams.append('SourceApp', requestParameters.sourceApp as any);
                }

                if (requestParameters.sourceAppRecordId !== undefined) { 
                    localVarFormParams.append('SourceAppRecordId', requestParameters.sourceAppRecordId as any);
                }

                if (requestParameters.autoSaveCompany !== undefined) { 
                    localVarFormParams.append('AutoSaveCompany', requestParameters.autoSaveCompany as any);
                }

    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeyi göndermeden önce görüntülemek için bu uç kullanılabilir    **Limit** : File maks 1MB olabilir.
         * @summary Belge önizleme
         * @param {any} file **UBL-TR Xml formatında dosya**
         * @param {string} [documentTemplateTitle] Sistem üzerinde tanımlı tasarım başlığı.                &gt; Burada bir değer gönderilirse, sistem üzerinde tanımlı tasarım dosyasıyla önizleme yapılır.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UploadsDocumentPreviewPost: async (requestParameters: V1UploadsDocumentPreviewPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            if (requestParameters.file === null || requestParameters.file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling v1UploadsDocumentPreviewPost.');
            }
            const localVarPath = `/v1/uploads/document/preview`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


                if (requestParameters.file !== undefined) { 
                    localVarFormParams.append('File', requestParameters.file as any);
                }

                if (requestParameters.documentTemplateTitle !== undefined) { 
                    localVarFormParams.append('DocumentTemplateTitle', requestParameters.documentTemplateTitle as any);
                }

    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Henüz onaylanmamış taslak belgeleri güncellemek için bu uç kullanılabilir
         * @summary Belge günceller
         * @param {string} uuid #### Belge uuid
         * @param {any} file **UBL-TR Xml formatında zipli yada açık halde dosya**
         * @param {boolean} isDirectSend 
         * @param {UploadDocumentPreviewType} previewType 
         * @param {string} sourceApp Belgenin iletildiği kaynak uygulama adı  This filed is mandotary.  Set source application unique key to identify it
         * @param {boolean} autoSaveCompany 
         * @param {string} [documentTemplate] Özel olarak kullanılmak istenen, sistemde tanımlı XSLT başlığı
         * @param {string} [sourceAppRecordId] Belgenin iletildiği kaynak uygulamadaki benzersiz kaydın id\\\&#39;si  This filed is optional.  Set source application reference to keep track it
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UploadsDocumentUuidPut: async (requestParameters: V1UploadsDocumentUuidPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1UploadsDocumentUuidPut.');
            }
            // verify required parameter 'file' is not null or undefined
            if (requestParameters.file === null || requestParameters.file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling v1UploadsDocumentUuidPut.');
            }
            // verify required parameter 'isDirectSend' is not null or undefined
            if (requestParameters.isDirectSend === null || requestParameters.isDirectSend === undefined) {
                throw new RequiredError('isDirectSend','Required parameter isDirectSend was null or undefined when calling v1UploadsDocumentUuidPut.');
            }
            // verify required parameter 'previewType' is not null or undefined
            if (requestParameters.previewType === null || requestParameters.previewType === undefined) {
                throw new RequiredError('previewType','Required parameter previewType was null or undefined when calling v1UploadsDocumentUuidPut.');
            }
            // verify required parameter 'sourceApp' is not null or undefined
            if (requestParameters.sourceApp === null || requestParameters.sourceApp === undefined) {
                throw new RequiredError('sourceApp','Required parameter sourceApp was null or undefined when calling v1UploadsDocumentUuidPut.');
            }
            // verify required parameter 'autoSaveCompany' is not null or undefined
            if (requestParameters.autoSaveCompany === null || requestParameters.autoSaveCompany === undefined) {
                throw new RequiredError('autoSaveCompany','Required parameter autoSaveCompany was null or undefined when calling v1UploadsDocumentUuidPut.');
            }
            const localVarPath = `/v1/uploads/document/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


                if (requestParameters.file !== undefined) { 
                    localVarFormParams.append('File', requestParameters.file as any);
                }

                if (requestParameters.isDirectSend !== undefined) { 
                    localVarFormParams.append('IsDirectSend', requestParameters.isDirectSend as any);
                }

                if (requestParameters.previewType !== undefined) { 
                    localVarFormParams.append('PreviewType', requestParameters.previewType as any);
                }

                if (requestParameters.documentTemplate !== undefined) { 
                    localVarFormParams.append('DocumentTemplate', requestParameters.documentTemplate as any);
                }

                if (requestParameters.sourceApp !== undefined) { 
                    localVarFormParams.append('SourceApp', requestParameters.sourceApp as any);
                }

                if (requestParameters.sourceAppRecordId !== undefined) { 
                    localVarFormParams.append('SourceAppRecordId', requestParameters.sourceAppRecordId as any);
                }

                if (requestParameters.autoSaveCompany !== undefined) { 
                    localVarFormParams.append('AutoSaveCompany', requestParameters.autoSaveCompany as any);
                }

    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * **Limit** : Tek istekte maksimum 25 belge işlenebilir.
         * @summary Daha önce gönderilmiş fakat taslak olan belgeleri, göndermek (resmileştirmek) için bu uç kullanılabilir.
         * @param {Array<string>} requestBody #### Taslak belge UUID listesi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UploadsDraftSendPost: async (requestParameters: V1UploadsDraftSendPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling v1UploadsDraftSendPost.');
            }
            const localVarPath = `/v1/uploads/draft/send`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.requestBody !== undefined ? requestParameters.requestBody : {}) : (requestParameters.requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UploadsApi - functional programming interface
 * @export
 */
export const UploadsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Belge yüklemek için bu uç kullanılabilir    **Limit** : File maks 1MB olabilir.
         * @summary Belge yükler
         * @param {any} file **UBL-TR Xml formatında zipli yada açık halde dosya**
         * @param {boolean} isDirectSend 
         * @param {UploadDocumentPreviewType} previewType 
         * @param {string} sourceApp Belgenin iletildiği kaynak uygulama adı  This filed is mandotary.  Set source application unique key to identify it
         * @param {boolean} autoSaveCompany 
         * @param {string} [documentTemplate] Özel olarak kullanılmak istenen, sistemde tanımlı XSLT başlığı
         * @param {string} [sourceAppRecordId] Belgenin iletildiği kaynak uygulamadaki benzersiz kaydın id\\\&#39;si  This filed is optional.  Set source application reference to keep track it
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UploadsDocumentPost(requestParameters: V1UploadsDocumentPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadDocumentResponse>> {
            const localVarAxiosArgs = await UploadsApiAxiosParamCreator(configuration).v1UploadsDocumentPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeyi göndermeden önce görüntülemek için bu uç kullanılabilir    **Limit** : File maks 1MB olabilir.
         * @summary Belge önizleme
         * @param {any} file **UBL-TR Xml formatında dosya**
         * @param {string} [documentTemplateTitle] Sistem üzerinde tanımlı tasarım başlığı.                &gt; Burada bir değer gönderilirse, sistem üzerinde tanımlı tasarım dosyasıyla önizleme yapılır.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UploadsDocumentPreviewPost(requestParameters: V1UploadsDocumentPreviewPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadDocumentPreviewResponse>> {
            const localVarAxiosArgs = await UploadsApiAxiosParamCreator(configuration).v1UploadsDocumentPreviewPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Henüz onaylanmamış taslak belgeleri güncellemek için bu uç kullanılabilir
         * @summary Belge günceller
         * @param {string} uuid #### Belge uuid
         * @param {any} file **UBL-TR Xml formatında zipli yada açık halde dosya**
         * @param {boolean} isDirectSend 
         * @param {UploadDocumentPreviewType} previewType 
         * @param {string} sourceApp Belgenin iletildiği kaynak uygulama adı  This filed is mandotary.  Set source application unique key to identify it
         * @param {boolean} autoSaveCompany 
         * @param {string} [documentTemplate] Özel olarak kullanılmak istenen, sistemde tanımlı XSLT başlığı
         * @param {string} [sourceAppRecordId] Belgenin iletildiği kaynak uygulamadaki benzersiz kaydın id\\\&#39;si  This filed is optional.  Set source application reference to keep track it
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UploadsDocumentUuidPut(requestParameters: V1UploadsDocumentUuidPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadDocumentResponse>> {
            const localVarAxiosArgs = await UploadsApiAxiosParamCreator(configuration).v1UploadsDocumentUuidPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * **Limit** : Tek istekte maksimum 25 belge işlenebilir.
         * @summary Daha önce gönderilmiş fakat taslak olan belgeleri, göndermek (resmileştirmek) için bu uç kullanılabilir.
         * @param {Array<string>} requestBody #### Taslak belge UUID listesi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UploadsDraftSendPost(requestParameters: V1UploadsDraftSendPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApproveAndSendResponse>>> {
            const localVarAxiosArgs = await UploadsApiAxiosParamCreator(configuration).v1UploadsDraftSendPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UploadsApi - factory interface
 * @export
 */
export const UploadsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Belge yüklemek için bu uç kullanılabilir    **Limit** : File maks 1MB olabilir.
         * @summary Belge yükler
         * @param {any} file **UBL-TR Xml formatında zipli yada açık halde dosya**
         * @param {boolean} isDirectSend 
         * @param {UploadDocumentPreviewType} previewType 
         * @param {string} sourceApp Belgenin iletildiği kaynak uygulama adı  This filed is mandotary.  Set source application unique key to identify it
         * @param {boolean} autoSaveCompany 
         * @param {string} [documentTemplate] Özel olarak kullanılmak istenen, sistemde tanımlı XSLT başlığı
         * @param {string} [sourceAppRecordId] Belgenin iletildiği kaynak uygulamadaki benzersiz kaydın id\\\&#39;si  This filed is optional.  Set source application reference to keep track it
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UploadsDocumentPost(requestParameters: V1UploadsDocumentPostRequest, options?: any): AxiosPromise<UploadDocumentResponse> {
            return UploadsApiFp(configuration).v1UploadsDocumentPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeyi göndermeden önce görüntülemek için bu uç kullanılabilir    **Limit** : File maks 1MB olabilir.
         * @summary Belge önizleme
         * @param {any} file **UBL-TR Xml formatında dosya**
         * @param {string} [documentTemplateTitle] Sistem üzerinde tanımlı tasarım başlığı.                &gt; Burada bir değer gönderilirse, sistem üzerinde tanımlı tasarım dosyasıyla önizleme yapılır.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UploadsDocumentPreviewPost(requestParameters: V1UploadsDocumentPreviewPostRequest, options?: any): AxiosPromise<UploadDocumentPreviewResponse> {
            return UploadsApiFp(configuration).v1UploadsDocumentPreviewPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Henüz onaylanmamış taslak belgeleri güncellemek için bu uç kullanılabilir
         * @summary Belge günceller
         * @param {string} uuid #### Belge uuid
         * @param {any} file **UBL-TR Xml formatında zipli yada açık halde dosya**
         * @param {boolean} isDirectSend 
         * @param {UploadDocumentPreviewType} previewType 
         * @param {string} sourceApp Belgenin iletildiği kaynak uygulama adı  This filed is mandotary.  Set source application unique key to identify it
         * @param {boolean} autoSaveCompany 
         * @param {string} [documentTemplate] Özel olarak kullanılmak istenen, sistemde tanımlı XSLT başlığı
         * @param {string} [sourceAppRecordId] Belgenin iletildiği kaynak uygulamadaki benzersiz kaydın id\\\&#39;si  This filed is optional.  Set source application reference to keep track it
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UploadsDocumentUuidPut(requestParameters: V1UploadsDocumentUuidPutRequest, options?: any): AxiosPromise<UploadDocumentResponse> {
            return UploadsApiFp(configuration).v1UploadsDocumentUuidPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * **Limit** : Tek istekte maksimum 25 belge işlenebilir.
         * @summary Daha önce gönderilmiş fakat taslak olan belgeleri, göndermek (resmileştirmek) için bu uç kullanılabilir.
         * @param {Array<string>} requestBody #### Taslak belge UUID listesi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UploadsDraftSendPost(requestParameters: V1UploadsDraftSendPostRequest, options?: any): AxiosPromise<Array<ApproveAndSendResponse>> {
            return UploadsApiFp(configuration).v1UploadsDraftSendPost(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UploadsApi - object-oriented interface
 * @export
 * @class UploadsApi
 * @extends {BaseAPI}
 */
export class UploadsApi extends BaseAPI {
    /**
     * Belge yüklemek için bu uç kullanılabilir    **Limit** : File maks 1MB olabilir.
     * @summary Belge yükler
     * @param {any} file **UBL-TR Xml formatında zipli yada açık halde dosya**
     * @param {boolean} isDirectSend 
     * @param {UploadDocumentPreviewType} previewType 
     * @param {string} sourceApp Belgenin iletildiği kaynak uygulama adı  This filed is mandotary.  Set source application unique key to identify it
     * @param {boolean} autoSaveCompany 
     * @param {string} [documentTemplate] Özel olarak kullanılmak istenen, sistemde tanımlı XSLT başlığı
     * @param {string} [sourceAppRecordId] Belgenin iletildiği kaynak uygulamadaki benzersiz kaydın id\\\&#39;si  This filed is optional.  Set source application reference to keep track it
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadsApi
     */
    public v1UploadsDocumentPost(requestParameters: V1UploadsDocumentPostRequest, options?: any) {
        return UploadsApiFp(this.configuration).v1UploadsDocumentPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeyi göndermeden önce görüntülemek için bu uç kullanılabilir    **Limit** : File maks 1MB olabilir.
     * @summary Belge önizleme
     * @param {any} file **UBL-TR Xml formatında dosya**
     * @param {string} [documentTemplateTitle] Sistem üzerinde tanımlı tasarım başlığı.                &gt; Burada bir değer gönderilirse, sistem üzerinde tanımlı tasarım dosyasıyla önizleme yapılır.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadsApi
     */
    public v1UploadsDocumentPreviewPost(requestParameters: V1UploadsDocumentPreviewPostRequest, options?: any) {
        return UploadsApiFp(this.configuration).v1UploadsDocumentPreviewPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Henüz onaylanmamış taslak belgeleri güncellemek için bu uç kullanılabilir
     * @summary Belge günceller
     * @param {string} uuid #### Belge uuid
     * @param {any} file **UBL-TR Xml formatında zipli yada açık halde dosya**
     * @param {boolean} isDirectSend 
     * @param {UploadDocumentPreviewType} previewType 
     * @param {string} sourceApp Belgenin iletildiği kaynak uygulama adı  This filed is mandotary.  Set source application unique key to identify it
     * @param {boolean} autoSaveCompany 
     * @param {string} [documentTemplate] Özel olarak kullanılmak istenen, sistemde tanımlı XSLT başlığı
     * @param {string} [sourceAppRecordId] Belgenin iletildiği kaynak uygulamadaki benzersiz kaydın id\\\&#39;si  This filed is optional.  Set source application reference to keep track it
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadsApi
     */
    public v1UploadsDocumentUuidPut(requestParameters: V1UploadsDocumentUuidPutRequest, options?: any) {
        return UploadsApiFp(this.configuration).v1UploadsDocumentUuidPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * **Limit** : Tek istekte maksimum 25 belge işlenebilir.
     * @summary Daha önce gönderilmiş fakat taslak olan belgeleri, göndermek (resmileştirmek) için bu uç kullanılabilir.
     * @param {Array<string>} requestBody #### Taslak belge UUID listesi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadsApi
     */
    public v1UploadsDraftSendPost(requestParameters: V1UploadsDraftSendPostRequest, options?: any) {
        return UploadsApiFp(this.configuration).v1UploadsDraftSendPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1UploadsDocumentPostRequest {
    /**
     * **UBL-TR Xml formatında zipli yada açık halde dosya**
     */
    file: any;
    /**
     * 
     */
    isDirectSend: boolean;
    /**
     * 
     */
    previewType: UploadDocumentPreviewType;
    /**
     * Belgenin iletildiği kaynak uygulama adı  This filed is mandotary.  Set source application unique key to identify it
     */
    sourceApp: string;
    /**
     * 
     */
    autoSaveCompany: boolean;
    /**
     * Özel olarak kullanılmak istenen, sistemde tanımlı XSLT başlığı
     */
    documentTemplate?: string;
    /**
     * Belgenin iletildiği kaynak uygulamadaki benzersiz kaydın id\\\'si  This filed is optional.  Set source application reference to keep track it
     */
    sourceAppRecordId?: string;
    }
    export interface V1UploadsDocumentPreviewPostRequest {
    /**
     * **UBL-TR Xml formatında dosya**
     */
    file: any;
    /**
     * Sistem üzerinde tanımlı tasarım başlığı.                > Burada bir değer gönderilirse, sistem üzerinde tanımlı tasarım dosyasıyla önizleme yapılır.
     */
    documentTemplateTitle?: string;
    }
    export interface V1UploadsDocumentUuidPutRequest {
    /**
     * #### Belge uuid
     */
    uuid: string;
    /**
     * **UBL-TR Xml formatında zipli yada açık halde dosya**
     */
    file: any;
    /**
     * 
     */
    isDirectSend: boolean;
    /**
     * 
     */
    previewType: UploadDocumentPreviewType;
    /**
     * Belgenin iletildiği kaynak uygulama adı  This filed is mandotary.  Set source application unique key to identify it
     */
    sourceApp: string;
    /**
     * 
     */
    autoSaveCompany: boolean;
    /**
     * Özel olarak kullanılmak istenen, sistemde tanımlı XSLT başlığı
     */
    documentTemplate?: string;
    /**
     * Belgenin iletildiği kaynak uygulamadaki benzersiz kaydın id\\\'si  This filed is optional.  Set source application reference to keep track it
     */
    sourceAppRecordId?: string;
    }
    export interface V1UploadsDraftSendPostRequest {
    /**
     * #### Taslak belge UUID listesi
     */
    requestBody: Array<string>;
    }
