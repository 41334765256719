// tslint:disable
// eslint:disable
/**
 * E-Invoice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * <remarks>              Belge seviyesinde henüz zarf oluşturulmadığı için None,Waiting gibi durumlar var. Zarf a eklendiği anda zarf ile aynı durumda devam etmesi gerekiyor.              </remarks>
 * @export
 * @enum {string}
 */
export enum OutgoingStatus {
    None = 'None',
    Waiting = 'Waiting',
    WaitingToBeSigned = 'WaitingToBeSigned',
    WaitingToBeEnvelopeCreate = 'WaitingToBeEnvelopeCreate',
    WaitingToBeSend = 'WaitingToBeSend',
    EnvelopeIsWaitingToBeProcessedByGib = 'EnvelopeIsWaitingToBeProcessedByGib',
    ErrorHasBeenDetectedInEnvelopeByGib = 'ErrorHasBeenDetectedInEnvelopeByGib',
    EnvelopeIsWaitingToBeSendedToReceiverByGib = 'EnvelopeIsWaitingToBeSendedToReceiverByGib',
    EnvelopeCouldNotTransferredToReceiverByGib = 'EnvelopeCouldNotTransferredToReceiverByGib',
    EnvelopeIsBeenWaitingToBeTransferredToReceiverByGib = 'EnvelopeIsBeenWaitingToBeTransferredToReceiverByGib',
    EnvelopeSystemResponseIsBeingWaitedFromReceiver = 'EnvelopeSystemResponseIsBeingWaitedFromReceiver',
    ErrorHasBeenDetectedInEnvelopeByReceiver = 'ErrorHasBeenDetectedInEnvelopeByReceiver',
    EnvelopeHasBeenTransferredToReceiverSuccessfully = 'EnvelopeHasBeenTransferredToReceiverSuccessfully',
    XmlParseError = 'XmlParseError',
    UnknownStatus = 'UnknownStatus'
}



