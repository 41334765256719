import { HasPayAsYouGo, HasGroupCreditPool } from '@/components/Auth';
import React from 'react';
import CreditSummaryComponent from './CreditSummaryComponent';
import PayAsYouGoComponent from './PayAsYouGoComponent';

const CreditSummary = () => {
  if (HasPayAsYouGo()) {
    return <PayAsYouGoComponent />;
  } else if (HasGroupCreditPool()) {
    return null;
  } else {
    return <CreditSummaryComponent />;
  }
};

export default CreditSummary;
