// tslint:disable
// eslint:disable
/**
 * General API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccountProductServicesDto } from '../interfaces';
// @ts-ignore
import { AccountProductServicesOperationParameter } from '../interfaces';
// @ts-ignore
import { ProductServices } from '../interfaces';
// @ts-ignore
import { ProductServicesPaginationResult } from '../interfaces';
/**
 * AccountProductServicesApi - axios parameter creator
 * @export
 */
export const AccountProductServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AccountProductServicesOperationParameter} operation 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductservicesBulkOperationPut: async (requestParameters: V1ProductservicesBulkOperationPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'operation' is not null or undefined
            if (requestParameters.operation === null || requestParameters.operation === undefined) {
                throw new RequiredError('operation','Required parameter operation was null or undefined when calling v1ProductservicesBulkOperationPut.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling v1ProductservicesBulkOperationPut.');
            }
            const localVarPath = `/v1/productservices/bulk/{operation}`.replace(`{${"operation"}}`, encodeURIComponent(String(requestParameters.operation)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.requestBody !== undefined ? requestParameters.requestBody : {}) : (requestParameters.requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductservicesGet: async (requestParameters: V1ProductservicesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1ProductservicesGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1ProductservicesGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1ProductservicesGet.');
            }
            const localVarPath = `/v1/productservices`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }
            if (requestParameters.query !== undefined) {
                localVarQueryParameter['query'] = requestParameters.query;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductservicesIdDelete: async (requestParameters: V1ProductservicesIdDeleteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ProductservicesIdDelete.');
            }
            const localVarPath = `/v1/productservices/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {AccountProductServicesDto} accountProductServicesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductservicesIdPut: async (requestParameters: V1ProductservicesIdPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ProductservicesIdPut.');
            }
            // verify required parameter 'accountProductServicesDto' is not null or undefined
            if (requestParameters.accountProductServicesDto === null || requestParameters.accountProductServicesDto === undefined) {
                throw new RequiredError('accountProductServicesDto','Required parameter accountProductServicesDto was null or undefined when calling v1ProductservicesIdPut.');
            }
            const localVarPath = `/v1/productservices/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.accountProductServicesDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.accountProductServicesDto !== undefined ? requestParameters.accountProductServicesDto : {}) : (requestParameters.accountProductServicesDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductservicesImportPost: async (requestParameters: V1ProductservicesImportPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/productservices/import`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


                if (requestParameters.file !== undefined) { 
                    localVarFormParams.append('file', requestParameters.file as any);
                }

    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccountProductServicesDto} [accountProductServicesDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductservicesPost: async (requestParameters: V1ProductservicesPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/productservices`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.accountProductServicesDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.accountProductServicesDto !== undefined ? requestParameters.accountProductServicesDto : {}) : (requestParameters.accountProductServicesDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountProductServicesApi - functional programming interface
 * @export
 */
export const AccountProductServicesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AccountProductServicesOperationParameter} operation 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductservicesBulkOperationPut(requestParameters: V1ProductservicesBulkOperationPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountProductServicesApiAxiosParamCreator(configuration).v1ProductservicesBulkOperationPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductservicesGet(requestParameters: V1ProductservicesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductServicesPaginationResult>> {
            const localVarAxiosArgs = await AccountProductServicesApiAxiosParamCreator(configuration).v1ProductservicesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductservicesIdDelete(requestParameters: V1ProductservicesIdDeleteRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountProductServicesApiAxiosParamCreator(configuration).v1ProductservicesIdDelete(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {AccountProductServicesDto} accountProductServicesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductservicesIdPut(requestParameters: V1ProductservicesIdPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountProductServicesApiAxiosParamCreator(configuration).v1ProductservicesIdPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductservicesImportPost(requestParameters: V1ProductservicesImportPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountProductServicesApiAxiosParamCreator(configuration).v1ProductservicesImportPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {AccountProductServicesDto} [accountProductServicesDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductservicesPost(requestParameters: V1ProductservicesPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductServices>> {
            const localVarAxiosArgs = await AccountProductServicesApiAxiosParamCreator(configuration).v1ProductservicesPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AccountProductServicesApi - factory interface
 * @export
 */
export const AccountProductServicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {AccountProductServicesOperationParameter} operation 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductservicesBulkOperationPut(requestParameters: V1ProductservicesBulkOperationPutRequest, options?: any): AxiosPromise<void> {
            return AccountProductServicesApiFp(configuration).v1ProductservicesBulkOperationPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductservicesGet(requestParameters: V1ProductservicesGetRequest, options?: any): AxiosPromise<ProductServicesPaginationResult> {
            return AccountProductServicesApiFp(configuration).v1ProductservicesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductservicesIdDelete(requestParameters: V1ProductservicesIdDeleteRequest, options?: any): AxiosPromise<void> {
            return AccountProductServicesApiFp(configuration).v1ProductservicesIdDelete(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {AccountProductServicesDto} accountProductServicesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductservicesIdPut(requestParameters: V1ProductservicesIdPutRequest, options?: any): AxiosPromise<void> {
            return AccountProductServicesApiFp(configuration).v1ProductservicesIdPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductservicesImportPost(requestParameters: V1ProductservicesImportPostRequest, options?: any): AxiosPromise<void> {
            return AccountProductServicesApiFp(configuration).v1ProductservicesImportPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountProductServicesDto} [accountProductServicesDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductservicesPost(requestParameters: V1ProductservicesPostRequest, options?: any): AxiosPromise<ProductServices> {
            return AccountProductServicesApiFp(configuration).v1ProductservicesPost(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountProductServicesApi - object-oriented interface
 * @export
 * @class AccountProductServicesApi
 * @extends {BaseAPI}
 */
export class AccountProductServicesApi extends BaseAPI {
    /**
     * 
     * @param {AccountProductServicesOperationParameter} operation 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountProductServicesApi
     */
    public v1ProductservicesBulkOperationPut(requestParameters: V1ProductservicesBulkOperationPutRequest, options?: any) {
        return AccountProductServicesApiFp(this.configuration).v1ProductservicesBulkOperationPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {string} [query] Aranacak kelime
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountProductServicesApi
     */
    public v1ProductservicesGet(requestParameters: V1ProductservicesGetRequest, options?: any) {
        return AccountProductServicesApiFp(this.configuration).v1ProductservicesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountProductServicesApi
     */
    public v1ProductservicesIdDelete(requestParameters: V1ProductservicesIdDeleteRequest, options?: any) {
        return AccountProductServicesApiFp(this.configuration).v1ProductservicesIdDelete(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {AccountProductServicesDto} accountProductServicesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountProductServicesApi
     */
    public v1ProductservicesIdPut(requestParameters: V1ProductservicesIdPutRequest, options?: any) {
        return AccountProductServicesApiFp(this.configuration).v1ProductservicesIdPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountProductServicesApi
     */
    public v1ProductservicesImportPost(requestParameters: V1ProductservicesImportPostRequest, options?: any) {
        return AccountProductServicesApiFp(this.configuration).v1ProductservicesImportPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountProductServicesDto} [accountProductServicesDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountProductServicesApi
     */
    public v1ProductservicesPost(requestParameters: V1ProductservicesPostRequest, options?: any) {
        return AccountProductServicesApiFp(this.configuration).v1ProductservicesPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1ProductservicesBulkOperationPutRequest {
    /**
     * 
     */
    operation: AccountProductServicesOperationParameter;
    /**
     * 
     */
    requestBody: Array<string>;
    }
    export interface V1ProductservicesGetRequest {
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    /**
     * Aranacak kelime
     */
    query?: string;
    }
    export interface V1ProductservicesIdDeleteRequest {
    /**
     * 
     */
    id: string;
    }
    export interface V1ProductservicesIdPutRequest {
    /**
     * 
     */
    id: string;
    /**
     * 
     */
    accountProductServicesDto: AccountProductServicesDto;
    }
    export interface V1ProductservicesImportPostRequest {
    /**
     * 
     */
    file?: any;
    }
    export interface V1ProductservicesPostRequest {
    /**
     * 
     */
    accountProductServicesDto?: AccountProductServicesDto;
    }
