/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { AmountType } from '.';
import { DefaultValue, NS_CBC } from '../constants';

/*
  27.04.2021 : Fully completed
*/
export class MonetaryTotalType {
  @XMLElement({ types: [{ name: 'LineExtensionAmount', namespaceUri: NS_CBC, itemType: () => AmountType }] })
  lineExtensionAmount: AmountType = DefaultValue;

  @XMLElement({ types: [{ name: 'TaxExclusiveAmount', namespaceUri: NS_CBC, itemType: () => AmountType }] })
  taxExclusiveAmount: AmountType = DefaultValue;

  @XMLElement({ types: [{ name: 'TaxInclusiveAmount', namespaceUri: NS_CBC, itemType: () => AmountType }] })
  taxInclusiveAmount: AmountType = DefaultValue;

  @XMLElement({ types: [{ name: 'AllowanceTotalAmount', namespaceUri: NS_CBC, itemType: () => AmountType }] })
  allowanceTotalAmount?: AmountType;

  @XMLElement({ types: [{ name: 'ChargeTotalAmount', namespaceUri: NS_CBC, itemType: () => AmountType }] })
  chargeTotalAmount?: AmountType;

  @XMLElement({ types: [{ name: 'PayableRoundingAmount', namespaceUri: NS_CBC, itemType: () => AmountType }] })
  payableRoundingAmount?: AmountType;

  @XMLElement({ types: [{ name: 'PayableAmount', namespaceUri: NS_CBC, itemType: () => AmountType }] })
  payableAmount: AmountType = DefaultValue;
}

export default MonetaryTotalType;
