import { GibTaxInfo } from '@/interfaces';

export const TaxesWithoutKdv: Array<GibTaxInfo> = [
  {
    description: 'Gelir Vergisi Stopajı',
    taxTypeCode: '0003',
    shortDescription: 'GV. Stpj.',
    disablePercent: false,
    disableTotal: false,
    isDecrease: true,
    isIncreaseKdv: false,
    exemptionCodeCheck: false,
    isOtv: false
  },
  {
    description: 'Kurumlar Vergisi Stopajı',
    taxTypeCode: '0011',
    shortDescription: 'KV. Stpj.',
    disablePercent: false,
    disableTotal: false,
    isDecrease: true,
    isIncreaseKdv: false,
    exemptionCodeCheck: false,
    isOtv: false
  },
  {
    description: 'ÖTV Tevkifatı',
    taxTypeCode: '4171',
    shortDescription: 'ÖTV Tevkifat',
    disablePercent: true,
    disableTotal: false,
    isDecrease: true,
    isIncreaseKdv: false,
    exemptionCodeCheck: false,
    isOtv: false
  },
  {
    description: 'Mera Fonu',
    taxTypeCode: '9040',
    shortDescription: 'Mera Fonu',
    disablePercent: false,
    disableTotal: false,
    isDecrease: true,
    isIncreaseKdv: false,
    exemptionCodeCheck: false,
    isOtv: false
  },

  {
    description: 'Kaynak Kullanımı Destekleme Fonu Kesintisi',
    taxTypeCode: '0061',
    shortDescription: 'KKDF. Kesinti',
    isIncreaseKdv: true,
    disablePercent: false,
    disableTotal: false,
    isDecrease: false,
    exemptionCodeCheck: false,
    isOtv: false
  },
  {
    description: 'Petrol ve Doğal Ürünlerine İliştv T.V. [ÖTV 1. Liste]',
    taxTypeCode: '0071',
    disablePercent: true,
    shortDescription: 'ÖTV 1. Liste',
    exemptionCodeCheck: true,
    isIncreaseKdv: true,
    disableTotal: false,
    isDecrease: false,
    isOtv: true
  },
  {
    description: 'Kolalı Gazoz, Alkollü İçe. ve Tütün Mamüllerine İlişkin Ö.T.V. [ÖTV 3. Liste]',
    taxTypeCode: '0073',
    isIncreaseKdv: true,
    shortDescription: 'ÖTV 3. Liste',
    exemptionCodeCheck: true,
    disablePercent: false,
    disableTotal: false,
    isDecrease: false,
    isOtv: true
  },
  {
    description: 'Dayanıklı Tüketim ve Diğer Mallara İlişkin Ö.T.V. [ÖTV 4. Liste]',
    taxTypeCode: '0074',
    isIncreaseKdv: true,
    shortDescription: 'ÖTV 4. Liste',
    exemptionCodeCheck: true,
    disablePercent: false,
    disableTotal: false,
    isDecrease: false,
    isOtv: true
  },
  {
    description: 'Alkollü İçeçeklere İlişkin Ö.T.V. [ÖTV 3A Liste]',
    taxTypeCode: '0075',
    isIncreaseKdv: true,
    shortDescription: 'ÖTV 3A Liste',
    exemptionCodeCheck: true,
    disablePercent: false,
    disableTotal: false,
    isDecrease: false,
    isOtv: true
  },
  {
    description: 'Tütün Mamüllerine İlişkin Ö.T.V. [ÖTV 3B Liste]',
    taxTypeCode: '0076',
    isIncreaseKdv: true,
    shortDescription: 'ÖTV 3B Liste',
    exemptionCodeCheck: true,
    disablePercent: false,
    disableTotal: false,
    isDecrease: false,
    isOtv: true
  },
  {
    description: 'Kolalı Gazozlara İlişkin Ö.T.V. [ÖTV 3C Liste]',
    taxTypeCode: '0077',
    isIncreaseKdv: true,
    disableTotal: true,
    shortDescription: 'ÖTV 3C Liste',
    exemptionCodeCheck: true,
    disablePercent: false,
    isDecrease: false,
    isOtv: true
  },
  {
    description: 'Damga Vergisi',
    taxTypeCode: '1047',
    shortDescription: 'Damga V.',
    disablePercent: false,
    disableTotal: false,
    isDecrease: false,
    isIncreaseKdv: false,
    exemptionCodeCheck: false,
    isOtv: false
  },
  {
    description: '5035 Sayılı Kanuna Göre Damga Vergisi',
    taxTypeCode: '1048',
    shortDescription: '5035SKDamgaV',
    disablePercent: false,
    disableTotal: false,
    isDecrease: false,
    isIncreaseKdv: false,
    exemptionCodeCheck: false,
    isOtv: false
  },
  {
    description: 'Elektrik ve Hava Gazı Tüketim Vergisi',
    taxTypeCode: '4071',
    shortDescription: 'Elk. HavaGaz. Tük. Ver.',
    isIncreaseKdv: true,
    disableTotal: true,
    disablePercent: false,
    isDecrease: false,
    exemptionCodeCheck: false,
    isOtv: false
  },
  {
    description: 'Özel İletişim Vergisi',
    taxTypeCode: '4080',
    shortDescription: 'Ö. İletişim V.',
    disablePercent: false,
    disableTotal: false,
    isDecrease: false,
    isIncreaseKdv: false,
    exemptionCodeCheck: false,
    isOtv: false
  },
  {
    description: '5035 Sayılı Kanuna Göre Özel İletişim Vergisi',
    taxTypeCode: '4081',
    shortDescription: '5035SKÖİletişimV',
    disablePercent: false,
    disableTotal: false,
    isDecrease: false,
    isIncreaseKdv: false,
    exemptionCodeCheck: false,
    isOtv: false
  },
  {
    description: 'Borsa Tescil Ücreti',
    taxTypeCode: '8001',
    shortDescription: 'Borsa Tes. Ücreti',
    disablePercent: false,
    disableTotal: false,
    isDecrease: false,
    isIncreaseKdv: false,
    exemptionCodeCheck: false,
    isOtv: false
  },
  {
    description: 'Enerji Fonu',
    taxTypeCode: '8002',
    isIncreaseKdv: true,
    shortDescription: 'Enerji Fonu',
    disablePercent: false,
    disableTotal: false,
    isDecrease: false,
    exemptionCodeCheck: false,
    isOtv: false
  },
  {
    description: 'TRT Payı',
    taxTypeCode: '8004',
    isIncreaseKdv: true,
    disableTotal: true,
    shortDescription: 'TRT Payı',
    disablePercent: false,
    isDecrease: false,
    exemptionCodeCheck: false,
    isOtv: false
  },
  {
    description: 'Elektrik Tüketim Vergisi',
    taxTypeCode: '8005',
    isIncreaseKdv: true,
    disableTotal: true,
    shortDescription: 'Elk. Tük. Vergisi',
    disablePercent: false,
    isDecrease: false,
    exemptionCodeCheck: false,
    isOtv: false
  },
  {
    description: 'Telsiz Kullanım Ücreti',
    taxTypeCode: '8006',
    shortDescription: 'Telsiz Kullanım',
    disablePercent: false,
    disableTotal: false,
    isDecrease: false,
    isIncreaseKdv: false,
    exemptionCodeCheck: false,
    isOtv: false
  },
  {
    description: 'Telsiz Ruhsat Ücreti',
    taxTypeCode: '8007',
    shortDescription: 'Telsiz Ruhsat',
    disablePercent: false,
    disableTotal: false,
    isDecrease: false,
    isIncreaseKdv: false,
    exemptionCodeCheck: false,
    isOtv: false
  },
  {
    description: 'Çevre Temizlik Vergisi',
    taxTypeCode: '8008',
    shortDescription: 'Çev. Tem. Vergisi',
    disablePercent: false,
    disableTotal: false,
    isDecrease: false,
    isIncreaseKdv: false,
    exemptionCodeCheck: false,
    isOtv: false
  },
  {
    description: 'Konaklama Vergisi',
    taxTypeCode: '0059',
    shortDescription: 'Konaklama Vergisi',
    disablePercent: false,
    disableTotal: false,
    isDecrease: false,
    isIncreaseKdv: false,
    exemptionCodeCheck: false,
    isOtv: false
  },
  // {
  //   description: 'KDV Tevkifatı',
  //   taxTypeCode: '9015',
  //   disablePercent: false,
  //   disableTotal: true,
  //   shortDescription: 'KDV Tevkifat',
  //   isDecrease: false,
  //   isIncreaseKdv: false,
  //   exemptionCodeCheck: false,
  //   isOtv: false
  // },
  {
    description: '4961 Banka Sigorta Muameleleri Vergisi',
    taxTypeCode: '9021',
    shortDescription: '4961BankaSMV',
    disablePercent: false,
    disableTotal: false,
    isDecrease: false,
    isIncreaseKdv: false,
    exemptionCodeCheck: false,
    isOtv: false
  },
  {
    description: 'Motorlu Taşıt Aralarına İlişkin Özel Tüketim Vergisi [ÖTV 2. Liste]',
    taxTypeCode: '9077',
    disableTotal: true,
    isIncreaseKdv: true,
    shortDescription: 'ÖTV 2. Liste',
    disablePercent: false,
    isDecrease: false,
    exemptionCodeCheck: false,
    isOtv: true
  }
];

export const TaxesWithKdv: Array<GibTaxInfo> = [
  {
    description: 'Katma Değer Vergisi',
    taxTypeCode: '0015',
    disableTotal: true,
    shortDescription: 'K.D.V',
    isDecrease: false,
    isIncreaseKdv: false,
    disablePercent: false,
    exemptionCodeCheck: true,
    isOtv: false
  },
  ...TaxesWithoutKdv
];

export const CreditNoteTaxes: Array<GibTaxInfo> = [
  { ...TaxesWithKdv.find((x) => x.taxTypeCode === '9040')!, disableTotal: true },
  { ...TaxesWithKdv.find((x) => x.taxTypeCode === '8001')!, isDecrease: true },
  {
    description: 'SGK Prim Kesintisi',
    taxTypeCode: 'SGK_PRIM',
    shortDescription: 'SGK Prim Kesintisi',
    disablePercent: false,
    disableTotal: true,
    isDecrease: true,
    isIncreaseKdv: false,
    exemptionCodeCheck: false,
    isOtv: false
  }
];

export const KDVTevkifat = {
  description: 'KDV Tevkifatı',
  taxTypeCode: '9015',
  disablePercent: false,
  disableTotal: true,
  shortDescription: 'KDV Tevkifat',
  isDecrease: false,
  isIncreaseKdv: false,
  exemptionCodeCheck: false,
  isOtv: false
};

export const CreditNoteTaxesWithGVStopaj: Array<GibTaxInfo> = [
  ...CreditNoteTaxes,
  {
    description: 'Gelir Vergisi Stopajı',
    taxTypeCode: '0003',
    shortDescription: 'GV. Stpj.',
    disablePercent: false,
    disableTotal: false,
    isDecrease: true,
    isIncreaseKdv: false,
    exemptionCodeCheck: false,
    isOtv: false
  }
];

export default TaxesWithoutKdv;
