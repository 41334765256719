import { EnvelopesApiFactory, V1EnvelopesInstanceIdentifierQueryGetRequest } from '@/apis/einvoice';
import { EInvoiceGetApplicationResponse } from '@/apis/einvoice/interfaces';
import { ErrorWrapper } from '@/components';
import { SearchInAddon } from '@/components/AddonButtons';
import { Close } from '@/components/Buttons';
import { TableDefaultLoading } from '@/components/TableBody';
import { PageConst } from '@/constants/page';
import { useApi } from '@/hooks';
import { faPencilAlt, faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Modal, Row, Table } from 'antd';
import { Formik, FormikProps } from 'formik';
import { Form, FormItem, Input } from 'formik-antd';
import React, { useRef } from 'react';
import intl from 'react-intl-universal';
import * as Yup from 'yup';
const EnvelopeSearchScheme = Yup.object().shape<V1EnvelopesInstanceIdentifierQueryGetRequest>({ instanceIdentifier: Yup.string().uuid().required() });
const EnvelopeSearchModal = ({ closeModal, visible }: { visible: boolean; closeModal: () => void }) => {
  const api = EnvelopesApiFactory();
  let form = (useRef(null) as unknown) as FormikProps<V1EnvelopesInstanceIdentifierQueryGetRequest>;
  const { call, loading, data, error } = useApi<Array<EInvoiceGetApplicationResponse>, V1EnvelopesInstanceIdentifierQueryGetRequest>({
    asyncFunction: api.v1EnvelopesInstanceIdentifierQueryGet
  });
  return (
    <Modal
      centered
      title={
        <span>
          <FontAwesomeIcon icon={faSearch} /> {'Zarf Durum Sorgulama'}
        </span>
      }
      onCancel={closeModal}
      width={PageConst.MediumModalWidth}
      visible={visible}
      footer={<Close onClick={closeModal} />}
    >
      <Formik<V1EnvelopesInstanceIdentifierQueryGetRequest>
        validateOnBlur={false}
        innerRef={(instance) => (form = instance)}
        onSubmit={(values) => {
          if (form.isValid) call(values);
        }}
        validationSchema={EnvelopeSearchScheme}
        initialValues={{ instanceIdentifier: '' }}
      >
        {({ handleSubmit }) => {
          return (
            <Form layout="vertical">
              <Row>
                <Col md={24}>
                  <FormItem name="instanceIdentifier" label={intl.get('ZARF_ETTN')} required>
                    <Input
                      prefix={<FontAwesomeIcon icon={faPencilAlt} />}
                      name="instanceIdentifier"
                      placeholder={'Zarf No giriniz..'}
                      tabIndex={0}
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                          handleSubmit();
                        }
                      }}
                      addonAfter={<SearchInAddon onClick={() => handleSubmit()} />}
                    />
                  </FormItem>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
      <Table
        columns={[
          {
            title: 'Kod',
            dataIndex: 'code',
            key: 'code',
            render: (value) => {
              return <b>{value}</b>;
            }
          },
          {
            title: 'Açıklama',
            dataIndex: 'description',
            key: 'description'
          }
        ]}
        showHeader={data !== undefined}
        components={ErrorWrapper({ error })}
        loading={TableDefaultLoading(loading)}
        dataSource={data && [data]}
        rowSelection={undefined}
        pagination={false}
        bordered
        rowKey={'code'}
      />
    </Modal>
  );
};

export default EnvelopeSearchModal;
