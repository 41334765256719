import { ButtonProps } from '@/interfaces';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React from 'react';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';

export const PdfDownload = ({ disabled, onClick, size = 'sm', onlyIcon }: ButtonProps) => {
  return (
    <Button disabled={disabled} size={size} key="btn-pdf-download" onClick={onClick} color={'primary'} className="btn-labeled">
      <FontAwesomeIcon icon={faFilePdf} className={classnames({ 'btn-label': !onlyIcon })} />
      {!onlyIcon && <span>{intl.get('PDF_INDIR')}</span>}
    </Button>
  );
};

export default PdfDownload;
