// tslint:disable
// eslint:disable
/**
 * General API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErpInformation } from '../interfaces';
// @ts-ignore
import { GenerateDespatchCommandDto } from '../interfaces';
// @ts-ignore
import { GenerateInvoiceCommandDto } from '../interfaces';
// @ts-ignore
import { OperationSystem } from '../interfaces';
// @ts-ignore
import { RemoteAccessSetting } from '../interfaces';
// @ts-ignore
import { RemoteAccessSettingPostDto } from '../interfaces';
// @ts-ignore
import { RemoteAccessSettingPutDto } from '../interfaces';
// @ts-ignore
import { RemoteQueryScript } from '../interfaces';
// @ts-ignore
import { RemoteQueryScriptDto } from '../interfaces';
// @ts-ignore
import { UnsentDespatchPaginatedListConnectorResult } from '../interfaces';
// @ts-ignore
import { UnsentInvoicePaginatedListConnectorResult } from '../interfaces';
// @ts-ignore
import { UploadDocumentResponseConnectorResult } from '../interfaces';
// @ts-ignore
import { VersionInfo } from '../interfaces';
/**
 * ConnectorApi - axios parameter creator
 * @export
 */
export const ConnectorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessErpinformationsGet: async (requestParameters: V1ConnectorRemoteaccessErpinformationsGetRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/connector/remoteaccess/erpinformations`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.query !== undefined) {
                localVarQueryParameter['query'] = requestParameters.query;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/connector/remoteaccess`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessIdCheckconnectionGet: async (requestParameters: V1ConnectorRemoteaccessIdCheckconnectionGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ConnectorRemoteaccessIdCheckconnectionGet.');
            }
            const localVarPath = `/v1/connector/remoteaccess/{id}/checkconnection`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessIdDelete: async (requestParameters: V1ConnectorRemoteaccessIdDeleteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ConnectorRemoteaccessIdDelete.');
            }
            const localVarPath = `/v1/connector/remoteaccess/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [query] 
         * @param {boolean} [onlyUnsentDocuments] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessIdDespatchesGet: async (requestParameters: V1ConnectorRemoteaccessIdDespatchesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ConnectorRemoteaccessIdDespatchesGet.');
            }
            const localVarPath = `/v1/connector/remoteaccess/{id}/despatches`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.query !== undefined) {
                localVarQueryParameter['query'] = requestParameters.query;
            }
            if (requestParameters.onlyUnsentDocuments !== undefined) {
                localVarQueryParameter['onlyUnsentDocuments'] = requestParameters.onlyUnsentDocuments;
            }
            if (requestParameters.beginDate !== undefined) {
                localVarQueryParameter['beginDate'] = (requestParameters.beginDate as any instanceof Date) ?
                    (requestParameters.beginDate as any).toISOString() : requestParameters.beginDate;
            }
            if (requestParameters.endDate !== undefined) {
                localVarQueryParameter['endDate'] = (requestParameters.endDate as any instanceof Date) ?
                    (requestParameters.endDate as any).toISOString() : requestParameters.endDate;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {GenerateDespatchCommandDto} [generateDespatchCommandDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessIdDespatchesPost: async (requestParameters: V1ConnectorRemoteaccessIdDespatchesPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ConnectorRemoteaccessIdDespatchesPost.');
            }
            const localVarPath = `/v1/connector/remoteaccess/{id}/despatches`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.generateDespatchCommandDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.generateDespatchCommandDto !== undefined ? requestParameters.generateDespatchCommandDto : {}) : (requestParameters.generateDespatchCommandDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [query] 
         * @param {boolean} [onlyUnsentDocuments] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessIdInvoicesGet: async (requestParameters: V1ConnectorRemoteaccessIdInvoicesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ConnectorRemoteaccessIdInvoicesGet.');
            }
            const localVarPath = `/v1/connector/remoteaccess/{id}/invoices`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.query !== undefined) {
                localVarQueryParameter['query'] = requestParameters.query;
            }
            if (requestParameters.onlyUnsentDocuments !== undefined) {
                localVarQueryParameter['onlyUnsentDocuments'] = requestParameters.onlyUnsentDocuments;
            }
            if (requestParameters.beginDate !== undefined) {
                localVarQueryParameter['beginDate'] = (requestParameters.beginDate as any instanceof Date) ?
                    (requestParameters.beginDate as any).toISOString() : requestParameters.beginDate;
            }
            if (requestParameters.endDate !== undefined) {
                localVarQueryParameter['endDate'] = (requestParameters.endDate as any instanceof Date) ?
                    (requestParameters.endDate as any).toISOString() : requestParameters.endDate;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {GenerateInvoiceCommandDto} [generateInvoiceCommandDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessIdInvoicesPost: async (requestParameters: V1ConnectorRemoteaccessIdInvoicesPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ConnectorRemoteaccessIdInvoicesPost.');
            }
            const localVarPath = `/v1/connector/remoteaccess/{id}/invoices`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.generateInvoiceCommandDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.generateInvoiceCommandDto !== undefined ? requestParameters.generateInvoiceCommandDto : {}) : (requestParameters.generateInvoiceCommandDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {RemoteAccessSettingPutDto} [remoteAccessSettingPutDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessIdPut: async (requestParameters: V1ConnectorRemoteaccessIdPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ConnectorRemoteaccessIdPut.');
            }
            const localVarPath = `/v1/connector/remoteaccess/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.remoteAccessSettingPutDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.remoteAccessSettingPutDto !== undefined ? requestParameters.remoteAccessSettingPutDto : {}) : (requestParameters.remoteAccessSettingPutDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessIdQueriesGet: async (requestParameters: V1ConnectorRemoteaccessIdQueriesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ConnectorRemoteaccessIdQueriesGet.');
            }
            const localVarPath = `/v1/connector/remoteaccess/{id}/queries`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {RemoteQueryScriptDto} [remoteQueryScriptDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessIdQueriesPut: async (requestParameters: V1ConnectorRemoteaccessIdQueriesPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ConnectorRemoteaccessIdQueriesPut.');
            }
            const localVarPath = `/v1/connector/remoteaccess/{id}/queries`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.remoteQueryScriptDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.remoteQueryScriptDto !== undefined ? requestParameters.remoteQueryScriptDto : {}) : (requestParameters.remoteQueryScriptDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OperationSystem} operationSystem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessLatestOperationSystemGet: async (requestParameters: V1ConnectorRemoteaccessLatestOperationSystemGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'operationSystem' is not null or undefined
            if (requestParameters.operationSystem === null || requestParameters.operationSystem === undefined) {
                throw new RequiredError('operationSystem','Required parameter operationSystem was null or undefined when calling v1ConnectorRemoteaccessLatestOperationSystemGet.');
            }
            const localVarPath = `/v1/connector/remoteaccess/latest/{operationSystem}`.replace(`{${"operationSystem"}}`, encodeURIComponent(String(requestParameters.operationSystem)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RemoteAccessSettingPostDto} [remoteAccessSettingPostDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessPost: async (requestParameters: V1ConnectorRemoteaccessPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/connector/remoteaccess`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.remoteAccessSettingPostDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.remoteAccessSettingPostDto !== undefined ? requestParameters.remoteAccessSettingPostDto : {}) : (requestParameters.remoteAccessSettingPostDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OperationSystem} operationSystem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessSetupOperationSystemGet: async (requestParameters: V1ConnectorRemoteaccessSetupOperationSystemGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'operationSystem' is not null or undefined
            if (requestParameters.operationSystem === null || requestParameters.operationSystem === undefined) {
                throw new RequiredError('operationSystem','Required parameter operationSystem was null or undefined when calling v1ConnectorRemoteaccessSetupOperationSystemGet.');
            }
            const localVarPath = `/v1/connector/remoteaccess/setup/{operationSystem}`.replace(`{${"operationSystem"}}`, encodeURIComponent(String(requestParameters.operationSystem)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConnectorApi - functional programming interface
 * @export
 */
export const ConnectorApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConnectorRemoteaccessErpinformationsGet(requestParameters: V1ConnectorRemoteaccessErpinformationsGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ErpInformation>>> {
            const localVarAxiosArgs = await ConnectorApiAxiosParamCreator(configuration).v1ConnectorRemoteaccessErpinformationsGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConnectorRemoteaccessGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RemoteAccessSetting>>> {
            const localVarAxiosArgs = await ConnectorApiAxiosParamCreator(configuration).v1ConnectorRemoteaccessGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConnectorRemoteaccessIdCheckconnectionGet(requestParameters: V1ConnectorRemoteaccessIdCheckconnectionGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ConnectorApiAxiosParamCreator(configuration).v1ConnectorRemoteaccessIdCheckconnectionGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConnectorRemoteaccessIdDelete(requestParameters: V1ConnectorRemoteaccessIdDeleteRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ConnectorApiAxiosParamCreator(configuration).v1ConnectorRemoteaccessIdDelete(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [query] 
         * @param {boolean} [onlyUnsentDocuments] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConnectorRemoteaccessIdDespatchesGet(requestParameters: V1ConnectorRemoteaccessIdDespatchesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnsentDespatchPaginatedListConnectorResult>> {
            const localVarAxiosArgs = await ConnectorApiAxiosParamCreator(configuration).v1ConnectorRemoteaccessIdDespatchesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {GenerateDespatchCommandDto} [generateDespatchCommandDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConnectorRemoteaccessIdDespatchesPost(requestParameters: V1ConnectorRemoteaccessIdDespatchesPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadDocumentResponseConnectorResult>> {
            const localVarAxiosArgs = await ConnectorApiAxiosParamCreator(configuration).v1ConnectorRemoteaccessIdDespatchesPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [query] 
         * @param {boolean} [onlyUnsentDocuments] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConnectorRemoteaccessIdInvoicesGet(requestParameters: V1ConnectorRemoteaccessIdInvoicesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnsentInvoicePaginatedListConnectorResult>> {
            const localVarAxiosArgs = await ConnectorApiAxiosParamCreator(configuration).v1ConnectorRemoteaccessIdInvoicesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {GenerateInvoiceCommandDto} [generateInvoiceCommandDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConnectorRemoteaccessIdInvoicesPost(requestParameters: V1ConnectorRemoteaccessIdInvoicesPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadDocumentResponseConnectorResult>> {
            const localVarAxiosArgs = await ConnectorApiAxiosParamCreator(configuration).v1ConnectorRemoteaccessIdInvoicesPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {RemoteAccessSettingPutDto} [remoteAccessSettingPutDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConnectorRemoteaccessIdPut(requestParameters: V1ConnectorRemoteaccessIdPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ConnectorApiAxiosParamCreator(configuration).v1ConnectorRemoteaccessIdPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConnectorRemoteaccessIdQueriesGet(requestParameters: V1ConnectorRemoteaccessIdQueriesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RemoteQueryScript>>> {
            const localVarAxiosArgs = await ConnectorApiAxiosParamCreator(configuration).v1ConnectorRemoteaccessIdQueriesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {RemoteQueryScriptDto} [remoteQueryScriptDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConnectorRemoteaccessIdQueriesPut(requestParameters: V1ConnectorRemoteaccessIdQueriesPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ConnectorApiAxiosParamCreator(configuration).v1ConnectorRemoteaccessIdQueriesPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {OperationSystem} operationSystem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConnectorRemoteaccessLatestOperationSystemGet(requestParameters: V1ConnectorRemoteaccessLatestOperationSystemGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VersionInfo>> {
            const localVarAxiosArgs = await ConnectorApiAxiosParamCreator(configuration).v1ConnectorRemoteaccessLatestOperationSystemGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {RemoteAccessSettingPostDto} [remoteAccessSettingPostDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConnectorRemoteaccessPost(requestParameters: V1ConnectorRemoteaccessPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RemoteAccessSetting>> {
            const localVarAxiosArgs = await ConnectorApiAxiosParamCreator(configuration).v1ConnectorRemoteaccessPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {OperationSystem} operationSystem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConnectorRemoteaccessSetupOperationSystemGet(requestParameters: V1ConnectorRemoteaccessSetupOperationSystemGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VersionInfo>> {
            const localVarAxiosArgs = await ConnectorApiAxiosParamCreator(configuration).v1ConnectorRemoteaccessSetupOperationSystemGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ConnectorApi - factory interface
 * @export
 */
export const ConnectorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessErpinformationsGet(requestParameters: V1ConnectorRemoteaccessErpinformationsGetRequest, options?: any): AxiosPromise<Array<ErpInformation>> {
            return ConnectorApiFp(configuration).v1ConnectorRemoteaccessErpinformationsGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessGet(options?: any): AxiosPromise<Array<RemoteAccessSetting>> {
            return ConnectorApiFp(configuration).v1ConnectorRemoteaccessGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessIdCheckconnectionGet(requestParameters: V1ConnectorRemoteaccessIdCheckconnectionGetRequest, options?: any): AxiosPromise<void> {
            return ConnectorApiFp(configuration).v1ConnectorRemoteaccessIdCheckconnectionGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessIdDelete(requestParameters: V1ConnectorRemoteaccessIdDeleteRequest, options?: any): AxiosPromise<void> {
            return ConnectorApiFp(configuration).v1ConnectorRemoteaccessIdDelete(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [query] 
         * @param {boolean} [onlyUnsentDocuments] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessIdDespatchesGet(requestParameters: V1ConnectorRemoteaccessIdDespatchesGetRequest, options?: any): AxiosPromise<UnsentDespatchPaginatedListConnectorResult> {
            return ConnectorApiFp(configuration).v1ConnectorRemoteaccessIdDespatchesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {GenerateDespatchCommandDto} [generateDespatchCommandDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessIdDespatchesPost(requestParameters: V1ConnectorRemoteaccessIdDespatchesPostRequest, options?: any): AxiosPromise<UploadDocumentResponseConnectorResult> {
            return ConnectorApiFp(configuration).v1ConnectorRemoteaccessIdDespatchesPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [query] 
         * @param {boolean} [onlyUnsentDocuments] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessIdInvoicesGet(requestParameters: V1ConnectorRemoteaccessIdInvoicesGetRequest, options?: any): AxiosPromise<UnsentInvoicePaginatedListConnectorResult> {
            return ConnectorApiFp(configuration).v1ConnectorRemoteaccessIdInvoicesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {GenerateInvoiceCommandDto} [generateInvoiceCommandDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessIdInvoicesPost(requestParameters: V1ConnectorRemoteaccessIdInvoicesPostRequest, options?: any): AxiosPromise<UploadDocumentResponseConnectorResult> {
            return ConnectorApiFp(configuration).v1ConnectorRemoteaccessIdInvoicesPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {RemoteAccessSettingPutDto} [remoteAccessSettingPutDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessIdPut(requestParameters: V1ConnectorRemoteaccessIdPutRequest, options?: any): AxiosPromise<void> {
            return ConnectorApiFp(configuration).v1ConnectorRemoteaccessIdPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessIdQueriesGet(requestParameters: V1ConnectorRemoteaccessIdQueriesGetRequest, options?: any): AxiosPromise<Array<RemoteQueryScript>> {
            return ConnectorApiFp(configuration).v1ConnectorRemoteaccessIdQueriesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {RemoteQueryScriptDto} [remoteQueryScriptDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessIdQueriesPut(requestParameters: V1ConnectorRemoteaccessIdQueriesPutRequest, options?: any): AxiosPromise<void> {
            return ConnectorApiFp(configuration).v1ConnectorRemoteaccessIdQueriesPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OperationSystem} operationSystem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessLatestOperationSystemGet(requestParameters: V1ConnectorRemoteaccessLatestOperationSystemGetRequest, options?: any): AxiosPromise<VersionInfo> {
            return ConnectorApiFp(configuration).v1ConnectorRemoteaccessLatestOperationSystemGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RemoteAccessSettingPostDto} [remoteAccessSettingPostDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessPost(requestParameters: V1ConnectorRemoteaccessPostRequest, options?: any): AxiosPromise<RemoteAccessSetting> {
            return ConnectorApiFp(configuration).v1ConnectorRemoteaccessPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OperationSystem} operationSystem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConnectorRemoteaccessSetupOperationSystemGet(requestParameters: V1ConnectorRemoteaccessSetupOperationSystemGetRequest, options?: any): AxiosPromise<VersionInfo> {
            return ConnectorApiFp(configuration).v1ConnectorRemoteaccessSetupOperationSystemGet(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConnectorApi - object-oriented interface
 * @export
 * @class ConnectorApi
 * @extends {BaseAPI}
 */
export class ConnectorApi extends BaseAPI {
    /**
     * 
     * @param {string} [query] Aranacak kelime
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorApi
     */
    public v1ConnectorRemoteaccessErpinformationsGet(requestParameters: V1ConnectorRemoteaccessErpinformationsGetRequest, options?: any) {
        return ConnectorApiFp(this.configuration).v1ConnectorRemoteaccessErpinformationsGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorApi
     */
    public v1ConnectorRemoteaccessGet(options?: any) {
        return ConnectorApiFp(this.configuration).v1ConnectorRemoteaccessGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorApi
     */
    public v1ConnectorRemoteaccessIdCheckconnectionGet(requestParameters: V1ConnectorRemoteaccessIdCheckconnectionGetRequest, options?: any) {
        return ConnectorApiFp(this.configuration).v1ConnectorRemoteaccessIdCheckconnectionGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorApi
     */
    public v1ConnectorRemoteaccessIdDelete(requestParameters: V1ConnectorRemoteaccessIdDeleteRequest, options?: any) {
        return ConnectorApiFp(this.configuration).v1ConnectorRemoteaccessIdDelete(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [query] 
     * @param {boolean} [onlyUnsentDocuments] 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorApi
     */
    public v1ConnectorRemoteaccessIdDespatchesGet(requestParameters: V1ConnectorRemoteaccessIdDespatchesGetRequest, options?: any) {
        return ConnectorApiFp(this.configuration).v1ConnectorRemoteaccessIdDespatchesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {GenerateDespatchCommandDto} [generateDespatchCommandDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorApi
     */
    public v1ConnectorRemoteaccessIdDespatchesPost(requestParameters: V1ConnectorRemoteaccessIdDespatchesPostRequest, options?: any) {
        return ConnectorApiFp(this.configuration).v1ConnectorRemoteaccessIdDespatchesPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [query] 
     * @param {boolean} [onlyUnsentDocuments] 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorApi
     */
    public v1ConnectorRemoteaccessIdInvoicesGet(requestParameters: V1ConnectorRemoteaccessIdInvoicesGetRequest, options?: any) {
        return ConnectorApiFp(this.configuration).v1ConnectorRemoteaccessIdInvoicesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {GenerateInvoiceCommandDto} [generateInvoiceCommandDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorApi
     */
    public v1ConnectorRemoteaccessIdInvoicesPost(requestParameters: V1ConnectorRemoteaccessIdInvoicesPostRequest, options?: any) {
        return ConnectorApiFp(this.configuration).v1ConnectorRemoteaccessIdInvoicesPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {RemoteAccessSettingPutDto} [remoteAccessSettingPutDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorApi
     */
    public v1ConnectorRemoteaccessIdPut(requestParameters: V1ConnectorRemoteaccessIdPutRequest, options?: any) {
        return ConnectorApiFp(this.configuration).v1ConnectorRemoteaccessIdPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorApi
     */
    public v1ConnectorRemoteaccessIdQueriesGet(requestParameters: V1ConnectorRemoteaccessIdQueriesGetRequest, options?: any) {
        return ConnectorApiFp(this.configuration).v1ConnectorRemoteaccessIdQueriesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {RemoteQueryScriptDto} [remoteQueryScriptDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorApi
     */
    public v1ConnectorRemoteaccessIdQueriesPut(requestParameters: V1ConnectorRemoteaccessIdQueriesPutRequest, options?: any) {
        return ConnectorApiFp(this.configuration).v1ConnectorRemoteaccessIdQueriesPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OperationSystem} operationSystem 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorApi
     */
    public v1ConnectorRemoteaccessLatestOperationSystemGet(requestParameters: V1ConnectorRemoteaccessLatestOperationSystemGetRequest, options?: any) {
        return ConnectorApiFp(this.configuration).v1ConnectorRemoteaccessLatestOperationSystemGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RemoteAccessSettingPostDto} [remoteAccessSettingPostDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorApi
     */
    public v1ConnectorRemoteaccessPost(requestParameters: V1ConnectorRemoteaccessPostRequest, options?: any) {
        return ConnectorApiFp(this.configuration).v1ConnectorRemoteaccessPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OperationSystem} operationSystem 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorApi
     */
    public v1ConnectorRemoteaccessSetupOperationSystemGet(requestParameters: V1ConnectorRemoteaccessSetupOperationSystemGetRequest, options?: any) {
        return ConnectorApiFp(this.configuration).v1ConnectorRemoteaccessSetupOperationSystemGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1ConnectorRemoteaccessErpinformationsGetRequest {
    /**
     * Aranacak kelime
     */
    query?: string;
    }
    export interface V1ConnectorRemoteaccessIdCheckconnectionGetRequest {
    /**
     * 
     */
    id: string;
    }
    export interface V1ConnectorRemoteaccessIdDeleteRequest {
    /**
     * 
     */
    id: string;
    }
    export interface V1ConnectorRemoteaccessIdDespatchesGetRequest {
    /**
     * 
     */
    id: string;
    /**
     * 
     */
    page?: number;
    /**
     * 
     */
    pageSize?: number;
    /**
     * 
     */
    query?: string;
    /**
     * 
     */
    onlyUnsentDocuments?: boolean;
    /**
     * 
     */
    beginDate?: string;
    /**
     * 
     */
    endDate?: string;
    }
    export interface V1ConnectorRemoteaccessIdDespatchesPostRequest {
    /**
     * 
     */
    id: string;
    /**
     * 
     */
    generateDespatchCommandDto?: GenerateDespatchCommandDto;
    }
    export interface V1ConnectorRemoteaccessIdInvoicesGetRequest {
    /**
     * 
     */
    id: string;
    /**
     * 
     */
    page?: number;
    /**
     * 
     */
    pageSize?: number;
    /**
     * 
     */
    query?: string;
    /**
     * 
     */
    onlyUnsentDocuments?: boolean;
    /**
     * 
     */
    beginDate?: string;
    /**
     * 
     */
    endDate?: string;
    }
    export interface V1ConnectorRemoteaccessIdInvoicesPostRequest {
    /**
     * 
     */
    id: string;
    /**
     * 
     */
    generateInvoiceCommandDto?: GenerateInvoiceCommandDto;
    }
    export interface V1ConnectorRemoteaccessIdPutRequest {
    /**
     * 
     */
    id: string;
    /**
     * 
     */
    remoteAccessSettingPutDto?: RemoteAccessSettingPutDto;
    }
    export interface V1ConnectorRemoteaccessIdQueriesGetRequest {
    /**
     * 
     */
    id: string;
    }
    export interface V1ConnectorRemoteaccessIdQueriesPutRequest {
    /**
     * 
     */
    id: string;
    /**
     * 
     */
    remoteQueryScriptDto?: RemoteQueryScriptDto;
    }
    export interface V1ConnectorRemoteaccessLatestOperationSystemGetRequest {
    /**
     * 
     */
    operationSystem: OperationSystem;
    }
    export interface V1ConnectorRemoteaccessPostRequest {
    /**
     * 
     */
    remoteAccessSettingPostDto?: RemoteAccessSettingPostDto;
    }
    export interface V1ConnectorRemoteaccessSetupOperationSystemGetRequest {
    /**
     * 
     */
    operationSystem: OperationSystem;
    }
