import { ButtonProps } from '@/interfaces';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React from 'react';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';

export const ConfirmSend = ({ disabled, onClick, size = 'sm', onlyIcon }: ButtonProps) => {
    return (
        <Button disabled={disabled} size={size} key="btn-confirmsend" onClick={onClick} color={'warning'} className="btn-labeled">
            <FontAwesomeIcon icon={faPaperPlane} className={classnames({ 'btn-label': !onlyIcon })} />
            {!onlyIcon && <span>{intl.get('ONAYLA_VE_GONDER')}</span>}
        </Button>
    );
};

export default ConfirmSend;
