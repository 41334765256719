/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { DocumentReferenceType, IDType } from '.';
import { NS_CAC, NS_CBC } from '../constants';

/*
  27.04.2021 : Fully completed
*/
export class OrderReferenceType {
  @XMLElement({ types: [{ name: 'ID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  id?: IDType;

  @XMLElement({ types: [{ name: 'SalesOrderID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  salesOrderID?: IDType;

  @XMLElement({ types: [{ name: 'IssueDate', namespaceUri: NS_CBC, itemType: () => String }] })
  issueDate?: string = undefined;

  @XMLElement({ types: [{ name: 'OrderTypeCode', namespaceUri: NS_CBC, itemType: () => String }] })
  orderTypeCode?: string;

  @XMLElement({ types: [{ name: 'DocumentReference', namespaceUri: NS_CAC, itemType: () => DocumentReferenceType }] })
  documentReference?: DocumentReferenceType;
}

export default OrderReferenceType;
