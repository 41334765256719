import { PageStyle } from '@/constants/page';
import { KeyValue } from '@/interfaces';
import { AnyType } from '@/type';
import { faArrows, faCheckDouble, faList } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, Skeleton } from 'antd';
import _ from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableLocation,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult
} from 'react-beautiful-dnd';
import { Card, CardBody, CardDeck, CardHeader } from 'reactstrap';
import { DangerToastify } from '../Toastify';
import style from './style.module.scss';

const { Search } = Input;
/** START */

const reorder = (list: KeyValue[], startIndex: number, endIndex: number): KeyValue[] => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (
  source: KeyValue[],
  destination: KeyValue[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation
): MoveResult | AnyType => {
  const sourceClone = [...source];
  const destClone = [...destination];
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 2;

const id2List = {
  droppable: 'items',
  droppable2: 'selected'
};

const getItemStyle = (draggableStyle: AnyType, isDragging: boolean) => ({
  userSelect: 'none',
  padding: 2 * grid,
  margin: `0px 0px 4px 0px`,
  background: isDragging ? '#e8fbd0' : '#F7F7F7',
  border: `0.50px solid #e7eaec`,
  borderRadius: 2,
  ...draggableStyle
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getListStyle = (_isDraggingOver: boolean) => ({
  // background: isDraggingOver ? '#F7F7F7' : '#f8f9fa',
  background: 'transparent',
  padding: grid,
  width: `100%`
  // minHeight: 300
});
/** END */

interface MoveResult {
  droppable: KeyValue[];
  droppable2: KeyValue[];
}

interface Props {
  isSearch?: boolean;
  isLoading: boolean;
  itemsTitle: string;
  selectedTitle: string;
  items: KeyValue[];
  selected: KeyValue[];
  onChangeSelectedColumns: (data: DragAndDropState) => void;
  minSelectedCount?: number;
  minSelectedProblem?: (lenght) => void;
}

export interface DragAndDropState {
  items: KeyValue[];
  selected: KeyValue[];
}

export const DragAndDrop = memo(
  ({ isSearch = false, isLoading, items, selected, minSelectedCount, minSelectedProblem, onChangeSelectedColumns, itemsTitle, selectedTitle }: Props) => {
    const [state, setState] = useState<DragAndDropState>({ items: [], selected: [] });
    useEffect(() => {
      setState({ ...state, items, selected });
    }, [items, selected]);

    const getList = (id: string): KeyValue[] => {
      return state[id2List[id]];
    };

    const onDragEnd = (result: DropResult): void => {
      const { source, destination } = result;

      if (!destination) {
        return;
      }

      const activeLenght = getList(source.droppableId).length;
      if (minSelectedCount && source.droppableId === 'droppable2' && activeLenght <= minSelectedCount) {
        if (minSelectedProblem) {
          minSelectedProblem(activeLenght);
        } else {
          DangerToastify(`En az ${minSelectedCount} kayıt seçili olmalıdır!`);
        }

        return;
      }

      if (source.droppableId === destination.droppableId) {
        const items = reorder(getList(source.droppableId), source.index, destination.index);

        let newState: DragAndDropState = { ...state };

        if (source.droppableId === 'droppable2') {
          newState = { ...state, selected: items };
        } else if (source.droppableId === 'droppable') {
          newState = { ...state, items };
        }

        setState(newState);
        onChangeSelectedColumns(newState);
      } else {
        const resultFromMove: MoveResult = move(getList(source.droppableId), getList(destination.droppableId), source, destination);

        const newState = {
          ...state,
          items: resultFromMove.droppable,
          selected: resultFromMove.droppable2
        };
        setState(newState);
        onChangeSelectedColumns(newState);
      }
    };

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <CardDeck style={{ minHeight: 300 }}>
          <Card>
            <CardHeader className={PageStyle.card__header}>
              <div className={PageStyle.card__header__title}>
                <FontAwesomeIcon icon={faList} className="mr-2" />
                <span>{itemsTitle}</span>
              </div>

              {isSearch && (
                <Search
                  placeholder="Sutün ara"
                  className="justify-content-end"
                  onChange={(e) => {
                    /**
                     * Client side arama için, react state ve render olmaması adına bu şekilde yapıldı.
                     */
                    const draggableItems = (window.document.getElementsByClassName('drag-item') as unknown) as Array<HTMLDivElement>;
                    for (let index = 0; index < draggableItems.length; index++) {
                      const element = draggableItems[index];
                      if (!new RegExp(`${e.target.value}`, 'i').test(element.getAttribute('data-text') || '')) {
                        element.style.display = 'none';
                      } else {
                        element.style.display = 'block';
                      }
                    }
                  }}
                  style={{ width: 200, height: 30, borderRadius: '5px' }}
                />
              )}
            </CardHeader>
            <CardBody className="drag-card-body" style={{ maxHeight: '720px', overflowY: 'auto' }}>
              <Skeleton active loading={isLoading}>
                <Droppable droppableId="droppable">
                  {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                      {state.items.map((item, index) => (
                        <Draggable key={item.key} draggableId={item.key} index={index}>
                          {(providedDraggable: DraggableProvided, snapshotDraggable: DraggableStateSnapshot) => (
                            <div data-key={item.key} data-text={item.value} className={`${style.draggable__item} drag-item`}>
                              <div
                                ref={providedDraggable.innerRef}
                                {...providedDraggable.draggableProps}
                                {...providedDraggable.dragHandleProps}
                                style={getItemStyle(providedDraggable.draggableProps.style, snapshotDraggable.isDragging)}
                              >
                                <FontAwesomeIcon icon={faArrows} className="mr-2" />
                                {item.value}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Skeleton>
            </CardBody>
          </Card>
          <Card>
            <CardHeader className={PageStyle.card__header}>
              <FontAwesomeIcon icon={faCheckDouble} className="mr-2" />
              <span className={PageStyle.card__header__title}>{selectedTitle}</span>
            </CardHeader>
            <CardBody className="drop-card-body">
              <Skeleton active loading={isLoading}>
                <Droppable droppableId="droppable2">
                  {(providedDroppable2: DroppableProvided, snapshotDroppable2: DroppableStateSnapshot) => (
                    <div
                      ref={providedDroppable2.innerRef}
                      style={{
                        background: '#f6f7f7',
                        padding: '2px',
                        width: '100%',
                        minHeight: `${selected.length * 40 < 200 ? 200 : (selected.length + 2) * 40}px`,
                        borderRadius: '4px',
                        border: '1px dashed #e4e8f0'
                      }}
                    >
                      {state.selected.map((item, index) => (
                        <Draggable key={item.key} draggableId={item.key} index={index}>
                          {(providedDraggable2: DraggableProvided, snapshotDraggable2: DraggableStateSnapshot) => (
                            <div className={style.droppable__item}>
                              <div
                                ref={providedDraggable2.innerRef}
                                {...providedDraggable2.draggableProps}
                                {...providedDraggable2.dragHandleProps}
                                style={getItemStyle(providedDraggable2.draggableProps.style, snapshotDraggable2.isDragging)}
                              >
                                <FontAwesomeIcon icon={faArrows} className="mr-2" />
                                {item.value}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {providedDroppable2.placeholder}
                    </div>
                  )}
                </Droppable>
              </Skeleton>
            </CardBody>
          </Card>
        </CardDeck>
      </DragDropContext>
    );
  },
  (prev, next) => _.isEqual(prev.items, next.items) && _.isEqual(prev.selected, next.selected) && prev.isLoading === next.isLoading
);

export default DragAndDrop;
