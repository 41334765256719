// tslint:disable
// eslint:disable
/**
 * General API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccountIVDSetting } from '../interfaces';
// @ts-ignore
import { AccountIVDSettingDto } from '../interfaces';
// @ts-ignore
import { CompanyAddress } from '../interfaces';
// @ts-ignore
import { CompanyAddressDto } from '../interfaces';
// @ts-ignore
import { CreditSummary } from '../interfaces';
// @ts-ignore
import { DealerInfo } from '../interfaces';
// @ts-ignore
import { LucaIntegrationSetting } from '../interfaces';
// @ts-ignore
import { PartyIdentification } from '../interfaces';
// @ts-ignore
import { PartyIdentificationDto } from '../interfaces';
/**
 * AccountManagementApi - axios parameter creator
 * @export
 */
export const AccountManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ManagementAddressIdDelete: async (requestParameters: V1ManagementAddressIdDeleteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ManagementAddressIdDelete.');
            }
            const localVarPath = `/v1/management/address/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CompanyAddressDto} companyAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ManagementAddressIdPut: async (requestParameters: V1ManagementAddressIdPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ManagementAddressIdPut.');
            }
            // verify required parameter 'companyAddressDto' is not null or undefined
            if (requestParameters.companyAddressDto === null || requestParameters.companyAddressDto === undefined) {
                throw new RequiredError('companyAddressDto','Required parameter companyAddressDto was null or undefined when calling v1ManagementAddressIdPut.');
            }
            const localVarPath = `/v1/management/address/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.companyAddressDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.companyAddressDto !== undefined ? requestParameters.companyAddressDto : {}) : (requestParameters.companyAddressDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ManagementAddressesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/management/addresses`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detay : Firmaya ait yeni adres bilgileri bu uç ile eklenebilir.
         * @summary Adres bilgileri ekler
         * @param {string} title Başlık
         * @param {string} streetName Cadde
         * @param {string} citySubDivisionName İlçe
         * @param {string} cityName İl
         * @param {string} countryName Ülke
         * @param {string} [postalZone] Posta kodu
         * @param {string} [telephone] Telefon
         * @param {string} [telefax] Faks
         * @param {string} [electronicMail] Mail
         * @param {string} [websiteUri] Web site
         * @param {string} [partyTaxSchemeName] Vergi dairesi
         * @param {boolean} [isDefault] Varsayılan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ManagementAddressesPost: async (requestParameters: V1ManagementAddressesPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'title' is not null or undefined
            if (requestParameters.title === null || requestParameters.title === undefined) {
                throw new RequiredError('title','Required parameter title was null or undefined when calling v1ManagementAddressesPost.');
            }
            // verify required parameter 'streetName' is not null or undefined
            if (requestParameters.streetName === null || requestParameters.streetName === undefined) {
                throw new RequiredError('streetName','Required parameter streetName was null or undefined when calling v1ManagementAddressesPost.');
            }
            // verify required parameter 'citySubDivisionName' is not null or undefined
            if (requestParameters.citySubDivisionName === null || requestParameters.citySubDivisionName === undefined) {
                throw new RequiredError('citySubDivisionName','Required parameter citySubDivisionName was null or undefined when calling v1ManagementAddressesPost.');
            }
            // verify required parameter 'cityName' is not null or undefined
            if (requestParameters.cityName === null || requestParameters.cityName === undefined) {
                throw new RequiredError('cityName','Required parameter cityName was null or undefined when calling v1ManagementAddressesPost.');
            }
            // verify required parameter 'countryName' is not null or undefined
            if (requestParameters.countryName === null || requestParameters.countryName === undefined) {
                throw new RequiredError('countryName','Required parameter countryName was null or undefined when calling v1ManagementAddressesPost.');
            }
            const localVarPath = `/v1/management/addresses`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.title !== undefined) {
                localVarQueryParameter['Title'] = requestParameters.title;
            }
            if (requestParameters.streetName !== undefined) {
                localVarQueryParameter['StreetName'] = requestParameters.streetName;
            }
            if (requestParameters.citySubDivisionName !== undefined) {
                localVarQueryParameter['CitySubDivisionName'] = requestParameters.citySubDivisionName;
            }
            if (requestParameters.cityName !== undefined) {
                localVarQueryParameter['CityName'] = requestParameters.cityName;
            }
            if (requestParameters.countryName !== undefined) {
                localVarQueryParameter['CountryName'] = requestParameters.countryName;
            }
            if (requestParameters.postalZone !== undefined) {
                localVarQueryParameter['PostalZone'] = requestParameters.postalZone;
            }
            if (requestParameters.telephone !== undefined) {
                localVarQueryParameter['Telephone'] = requestParameters.telephone;
            }
            if (requestParameters.telefax !== undefined) {
                localVarQueryParameter['Telefax'] = requestParameters.telefax;
            }
            if (requestParameters.electronicMail !== undefined) {
                localVarQueryParameter['ElectronicMail'] = requestParameters.electronicMail;
            }
            if (requestParameters.websiteUri !== undefined) {
                localVarQueryParameter['WebsiteUri'] = requestParameters.websiteUri;
            }
            if (requestParameters.partyTaxSchemeName !== undefined) {
                localVarQueryParameter['PartyTaxSchemeName'] = requestParameters.partyTaxSchemeName;
            }
            if (requestParameters.isDefault !== undefined) {
                localVarQueryParameter['IsDefault'] = requestParameters.isDefault;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Firmanın kontör tanımlama/kullanım özeti bu alanda dönülür.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ManagementCreditsummaryGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/management/creditsummary`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detay : Firmanın bağlı olduğu bayinin bilgileri bu uç ile alınabilir
         * @summary Bayi bilgilerine ulaşabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ManagementDealerinfoGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/management/dealerinfo`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detay : Firmaya ait kimlik bilgileri bu uç ile alınabilir
         * @summary Kimlik bilgilerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ManagementIdentificationsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/management/identifications`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ManagementIdentificationsIdDelete: async (requestParameters: V1ManagementIdentificationsIdDeleteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ManagementIdentificationsIdDelete.');
            }
            const localVarPath = `/v1/management/identifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detay : Firmaya ait yeni kimlik bilgileri eklemek için bu uç kullanılabilir.
         * @summary Kimlik bilgileri ekler
         * @param {PartyIdentificationDto} partyIdentificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ManagementIdentificationsPost: async (requestParameters: V1ManagementIdentificationsPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partyIdentificationDto' is not null or undefined
            if (requestParameters.partyIdentificationDto === null || requestParameters.partyIdentificationDto === undefined) {
                throw new RequiredError('partyIdentificationDto','Required parameter partyIdentificationDto was null or undefined when calling v1ManagementIdentificationsPost.');
            }
            const localVarPath = `/v1/management/identifications`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.partyIdentificationDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.partyIdentificationDto !== undefined ? requestParameters.partyIdentificationDto : {}) : (requestParameters.partyIdentificationDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ManagementIvdGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/management/ivd`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccountIVDSettingDto} accountIVDSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ManagementIvdPut: async (requestParameters: V1ManagementIvdPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountIVDSettingDto' is not null or undefined
            if (requestParameters.accountIVDSettingDto === null || requestParameters.accountIVDSettingDto === undefined) {
                throw new RequiredError('accountIVDSettingDto','Required parameter accountIVDSettingDto was null or undefined when calling v1ManagementIvdPut.');
            }
            const localVarPath = `/v1/management/ivd`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.accountIVDSettingDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.accountIVDSettingDto !== undefined ? requestParameters.accountIVDSettingDto : {}) : (requestParameters.accountIVDSettingDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ManagementLucaIntegrationSettingGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/management/luca/integration/setting`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LucaIntegrationSetting} lucaIntegrationSetting 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ManagementLucaIntegrationSettingPut: async (requestParameters: V1ManagementLucaIntegrationSettingPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'lucaIntegrationSetting' is not null or undefined
            if (requestParameters.lucaIntegrationSetting === null || requestParameters.lucaIntegrationSetting === undefined) {
                throw new RequiredError('lucaIntegrationSetting','Required parameter lucaIntegrationSetting was null or undefined when calling v1ManagementLucaIntegrationSettingPut.');
            }
            const localVarPath = `/v1/management/luca/integration/setting`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.lucaIntegrationSetting !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.lucaIntegrationSetting !== undefined ? requestParameters.lucaIntegrationSetting : {}) : (requestParameters.lucaIntegrationSetting || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountManagementApi - functional programming interface
 * @export
 */
export const AccountManagementApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ManagementAddressIdDelete(requestParameters: V1ManagementAddressIdDeleteRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountManagementApiAxiosParamCreator(configuration).v1ManagementAddressIdDelete(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CompanyAddressDto} companyAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ManagementAddressIdPut(requestParameters: V1ManagementAddressIdPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyAddress>> {
            const localVarAxiosArgs = await AccountManagementApiAxiosParamCreator(configuration).v1ManagementAddressIdPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ManagementAddressesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyAddress>>> {
            const localVarAxiosArgs = await AccountManagementApiAxiosParamCreator(configuration).v1ManagementAddressesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Detay : Firmaya ait yeni adres bilgileri bu uç ile eklenebilir.
         * @summary Adres bilgileri ekler
         * @param {string} title Başlık
         * @param {string} streetName Cadde
         * @param {string} citySubDivisionName İlçe
         * @param {string} cityName İl
         * @param {string} countryName Ülke
         * @param {string} [postalZone] Posta kodu
         * @param {string} [telephone] Telefon
         * @param {string} [telefax] Faks
         * @param {string} [electronicMail] Mail
         * @param {string} [websiteUri] Web site
         * @param {string} [partyTaxSchemeName] Vergi dairesi
         * @param {boolean} [isDefault] Varsayılan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ManagementAddressesPost(requestParameters: V1ManagementAddressesPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyAddress>> {
            const localVarAxiosArgs = await AccountManagementApiAxiosParamCreator(configuration).v1ManagementAddressesPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Firmanın kontör tanımlama/kullanım özeti bu alanda dönülür.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ManagementCreditsummaryGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreditSummary>> {
            const localVarAxiosArgs = await AccountManagementApiAxiosParamCreator(configuration).v1ManagementCreditsummaryGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Detay : Firmanın bağlı olduğu bayinin bilgileri bu uç ile alınabilir
         * @summary Bayi bilgilerine ulaşabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ManagementDealerinfoGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealerInfo>> {
            const localVarAxiosArgs = await AccountManagementApiAxiosParamCreator(configuration).v1ManagementDealerinfoGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Detay : Firmaya ait kimlik bilgileri bu uç ile alınabilir
         * @summary Kimlik bilgilerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ManagementIdentificationsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartyIdentification>>> {
            const localVarAxiosArgs = await AccountManagementApiAxiosParamCreator(configuration).v1ManagementIdentificationsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ManagementIdentificationsIdDelete(requestParameters: V1ManagementIdentificationsIdDeleteRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountManagementApiAxiosParamCreator(configuration).v1ManagementIdentificationsIdDelete(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Detay : Firmaya ait yeni kimlik bilgileri eklemek için bu uç kullanılabilir.
         * @summary Kimlik bilgileri ekler
         * @param {PartyIdentificationDto} partyIdentificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ManagementIdentificationsPost(requestParameters: V1ManagementIdentificationsPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartyIdentification>> {
            const localVarAxiosArgs = await AccountManagementApiAxiosParamCreator(configuration).v1ManagementIdentificationsPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ManagementIvdGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountIVDSetting>> {
            const localVarAxiosArgs = await AccountManagementApiAxiosParamCreator(configuration).v1ManagementIvdGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {AccountIVDSettingDto} accountIVDSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ManagementIvdPut(requestParameters: V1ManagementIvdPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountManagementApiAxiosParamCreator(configuration).v1ManagementIvdPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ManagementLucaIntegrationSettingGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LucaIntegrationSetting>> {
            const localVarAxiosArgs = await AccountManagementApiAxiosParamCreator(configuration).v1ManagementLucaIntegrationSettingGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {LucaIntegrationSetting} lucaIntegrationSetting 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ManagementLucaIntegrationSettingPut(requestParameters: V1ManagementLucaIntegrationSettingPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountManagementApiAxiosParamCreator(configuration).v1ManagementLucaIntegrationSettingPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AccountManagementApi - factory interface
 * @export
 */
export const AccountManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ManagementAddressIdDelete(requestParameters: V1ManagementAddressIdDeleteRequest, options?: any): AxiosPromise<void> {
            return AccountManagementApiFp(configuration).v1ManagementAddressIdDelete(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CompanyAddressDto} companyAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ManagementAddressIdPut(requestParameters: V1ManagementAddressIdPutRequest, options?: any): AxiosPromise<CompanyAddress> {
            return AccountManagementApiFp(configuration).v1ManagementAddressIdPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ManagementAddressesGet(options?: any): AxiosPromise<Array<CompanyAddress>> {
            return AccountManagementApiFp(configuration).v1ManagementAddressesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Detay : Firmaya ait yeni adres bilgileri bu uç ile eklenebilir.
         * @summary Adres bilgileri ekler
         * @param {string} title Başlık
         * @param {string} streetName Cadde
         * @param {string} citySubDivisionName İlçe
         * @param {string} cityName İl
         * @param {string} countryName Ülke
         * @param {string} [postalZone] Posta kodu
         * @param {string} [telephone] Telefon
         * @param {string} [telefax] Faks
         * @param {string} [electronicMail] Mail
         * @param {string} [websiteUri] Web site
         * @param {string} [partyTaxSchemeName] Vergi dairesi
         * @param {boolean} [isDefault] Varsayılan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ManagementAddressesPost(requestParameters: V1ManagementAddressesPostRequest, options?: any): AxiosPromise<CompanyAddress> {
            return AccountManagementApiFp(configuration).v1ManagementAddressesPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Firmanın kontör tanımlama/kullanım özeti bu alanda dönülür.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ManagementCreditsummaryGet(options?: any): AxiosPromise<CreditSummary> {
            return AccountManagementApiFp(configuration).v1ManagementCreditsummaryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Detay : Firmanın bağlı olduğu bayinin bilgileri bu uç ile alınabilir
         * @summary Bayi bilgilerine ulaşabilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ManagementDealerinfoGet(options?: any): AxiosPromise<DealerInfo> {
            return AccountManagementApiFp(configuration).v1ManagementDealerinfoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Detay : Firmaya ait kimlik bilgileri bu uç ile alınabilir
         * @summary Kimlik bilgilerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ManagementIdentificationsGet(options?: any): AxiosPromise<Array<PartyIdentification>> {
            return AccountManagementApiFp(configuration).v1ManagementIdentificationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ManagementIdentificationsIdDelete(requestParameters: V1ManagementIdentificationsIdDeleteRequest, options?: any): AxiosPromise<void> {
            return AccountManagementApiFp(configuration).v1ManagementIdentificationsIdDelete(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Detay : Firmaya ait yeni kimlik bilgileri eklemek için bu uç kullanılabilir.
         * @summary Kimlik bilgileri ekler
         * @param {PartyIdentificationDto} partyIdentificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ManagementIdentificationsPost(requestParameters: V1ManagementIdentificationsPostRequest, options?: any): AxiosPromise<PartyIdentification> {
            return AccountManagementApiFp(configuration).v1ManagementIdentificationsPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ManagementIvdGet(options?: any): AxiosPromise<AccountIVDSetting> {
            return AccountManagementApiFp(configuration).v1ManagementIvdGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountIVDSettingDto} accountIVDSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ManagementIvdPut(requestParameters: V1ManagementIvdPutRequest, options?: any): AxiosPromise<void> {
            return AccountManagementApiFp(configuration).v1ManagementIvdPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ManagementLucaIntegrationSettingGet(options?: any): AxiosPromise<LucaIntegrationSetting> {
            return AccountManagementApiFp(configuration).v1ManagementLucaIntegrationSettingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LucaIntegrationSetting} lucaIntegrationSetting 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ManagementLucaIntegrationSettingPut(requestParameters: V1ManagementLucaIntegrationSettingPutRequest, options?: any): AxiosPromise<void> {
            return AccountManagementApiFp(configuration).v1ManagementLucaIntegrationSettingPut(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountManagementApi - object-oriented interface
 * @export
 * @class AccountManagementApi
 * @extends {BaseAPI}
 */
export class AccountManagementApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public v1ManagementAddressIdDelete(requestParameters: V1ManagementAddressIdDeleteRequest, options?: any) {
        return AccountManagementApiFp(this.configuration).v1ManagementAddressIdDelete(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CompanyAddressDto} companyAddressDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public v1ManagementAddressIdPut(requestParameters: V1ManagementAddressIdPutRequest, options?: any) {
        return AccountManagementApiFp(this.configuration).v1ManagementAddressIdPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public v1ManagementAddressesGet(options?: any) {
        return AccountManagementApiFp(this.configuration).v1ManagementAddressesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Detay : Firmaya ait yeni adres bilgileri bu uç ile eklenebilir.
     * @summary Adres bilgileri ekler
     * @param {string} title Başlık
     * @param {string} streetName Cadde
     * @param {string} citySubDivisionName İlçe
     * @param {string} cityName İl
     * @param {string} countryName Ülke
     * @param {string} [postalZone] Posta kodu
     * @param {string} [telephone] Telefon
     * @param {string} [telefax] Faks
     * @param {string} [electronicMail] Mail
     * @param {string} [websiteUri] Web site
     * @param {string} [partyTaxSchemeName] Vergi dairesi
     * @param {boolean} [isDefault] Varsayılan
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public v1ManagementAddressesPost(requestParameters: V1ManagementAddressesPostRequest, options?: any) {
        return AccountManagementApiFp(this.configuration).v1ManagementAddressesPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Firmanın kontör tanımlama/kullanım özeti bu alanda dönülür.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public v1ManagementCreditsummaryGet(options?: any) {
        return AccountManagementApiFp(this.configuration).v1ManagementCreditsummaryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Detay : Firmanın bağlı olduğu bayinin bilgileri bu uç ile alınabilir
     * @summary Bayi bilgilerine ulaşabilirsiniz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public v1ManagementDealerinfoGet(options?: any) {
        return AccountManagementApiFp(this.configuration).v1ManagementDealerinfoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Detay : Firmaya ait kimlik bilgileri bu uç ile alınabilir
     * @summary Kimlik bilgilerini listeler
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public v1ManagementIdentificationsGet(options?: any) {
        return AccountManagementApiFp(this.configuration).v1ManagementIdentificationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public v1ManagementIdentificationsIdDelete(requestParameters: V1ManagementIdentificationsIdDeleteRequest, options?: any) {
        return AccountManagementApiFp(this.configuration).v1ManagementIdentificationsIdDelete(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Detay : Firmaya ait yeni kimlik bilgileri eklemek için bu uç kullanılabilir.
     * @summary Kimlik bilgileri ekler
     * @param {PartyIdentificationDto} partyIdentificationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public v1ManagementIdentificationsPost(requestParameters: V1ManagementIdentificationsPostRequest, options?: any) {
        return AccountManagementApiFp(this.configuration).v1ManagementIdentificationsPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public v1ManagementIvdGet(options?: any) {
        return AccountManagementApiFp(this.configuration).v1ManagementIvdGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountIVDSettingDto} accountIVDSettingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public v1ManagementIvdPut(requestParameters: V1ManagementIvdPutRequest, options?: any) {
        return AccountManagementApiFp(this.configuration).v1ManagementIvdPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public v1ManagementLucaIntegrationSettingGet(options?: any) {
        return AccountManagementApiFp(this.configuration).v1ManagementLucaIntegrationSettingGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LucaIntegrationSetting} lucaIntegrationSetting 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public v1ManagementLucaIntegrationSettingPut(requestParameters: V1ManagementLucaIntegrationSettingPutRequest, options?: any) {
        return AccountManagementApiFp(this.configuration).v1ManagementLucaIntegrationSettingPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1ManagementAddressIdDeleteRequest {
    /**
     * 
     */
    id: string;
    }
    export interface V1ManagementAddressIdPutRequest {
    /**
     * 
     */
    id: string;
    /**
     * 
     */
    companyAddressDto: CompanyAddressDto;
    }
    export interface V1ManagementAddressesPostRequest {
    /**
     * Başlık
     */
    title: string;
    /**
     * Cadde
     */
    streetName: string;
    /**
     * İlçe
     */
    citySubDivisionName: string;
    /**
     * İl
     */
    cityName: string;
    /**
     * Ülke
     */
    countryName: string;
    /**
     * Posta kodu
     */
    postalZone?: string | null;
    /**
     * Telefon
     */
    telephone?: string | null;
    /**
     * Faks
     */
    telefax?: string | null;
    /**
     * Mail
     */
    electronicMail?: string | null;
    /**
     * Web site
     */
    websiteUri?: string | null;
    /**
     * Vergi dairesi
     */
    partyTaxSchemeName?: string | null;
    /**
     * Varsayılan
     */
    isDefault?: boolean;
    }
    export interface V1ManagementIdentificationsIdDeleteRequest {
    /**
     * 
     */
    id: string;
    }
    export interface V1ManagementIdentificationsPostRequest {
    /**
     * 
     */
    partyIdentificationDto: PartyIdentificationDto;
    }
    export interface V1ManagementIvdPutRequest {
    /**
     * 
     */
    accountIVDSettingDto: AccountIVDSettingDto;
    }
    export interface V1ManagementLucaIntegrationSettingPutRequest {
    /**
     * 
     */
    lucaIntegrationSetting: LucaIntegrationSetting;
    }
