/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLArray, XMLElement } from '@/xmldom-decorators';
import { IDType, ItemType, OrderLineReferenceType, QuantityType } from '.';
import { NS_CAC, NS_CBC } from '../constants';

/*
  10.05.2021 : Partially completed
  cbc:ShortQuantity 
  cbc:RejectedQuantity 
  cbc:RejectReasonCode 
  cbc:RejectReason 
  cbc:OversupplyQuantity 
  cbc:ReceivedDate 
  cbc:TimingComplaintCode 
  cbc:TimingComplaint
  cac:DespatchLineReference
  cac:DocumentReference 
  cac:Shipment  
*/
export class ReceiptLineType {
  @XMLElement({ types: [{ name: 'ID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  id?: IDType;

  @XMLArray({ nested: false, itemTypes: [{ name: 'Note', namespaceUri: NS_CBC, itemType: () => String }] })
  note?: string[];

  @XMLElement({ types: [{ name: 'ReceivedQuantity', namespaceUri: NS_CBC, itemType: () => QuantityType }] })
  receivedQuantity?: QuantityType = { unitCode: 'C62', value: 1 };

  @XMLElement({ types: [{ name: 'ShortQuantity', namespaceUri: NS_CBC, itemType: () => QuantityType }] })
  shortQuantity?: QuantityType;

  @XMLElement({ types: [{ name: 'RejectedQuantity', namespaceUri: NS_CBC, itemType: () => QuantityType }] })
  rejectedQuantity?: QuantityType;

  //TODO : RejectReasonCode

  @XMLElement({ types: [{ name: 'RejectReason', namespaceUri: NS_CBC, itemType: () => String }] })
  rejectReason?: string;

  @XMLElement({ types: [{ name: 'OversupplyQuantity', namespaceUri: NS_CBC, itemType: () => QuantityType }] })
  oversupplyQuantity?: QuantityType;

  @XMLElement({ types: [{ name: 'OrderLineReference', namespaceUri: NS_CAC, itemType: () => OrderLineReferenceType }] })
  orderLineReference?: OrderLineReferenceType;

  //TODO : ReceivedDate
  //TODO : TimingComplaintCode
  //TODO : TimingComplaint
  //TODO : DespatchLineReference
  //TODO : DocumentReference
  //TODO : Shipment

  @XMLElement({ types: [{ name: 'Item', namespaceUri: NS_CAC, itemType: () => ItemType }] })
  item?: ItemType;
}

export default ReceiptLineType;
