export const ESmmLayouts = {
  xxl: [
    {
      w: 5,
      h: 15,
      x: 0,
      y: 0,
      i: 'esmm-last-invoices',
      moved: false,
      static: false
    },
    {
      w: 5,
      h: 15,
      x: 19,
      y: 0,
      i: 'esmm-last-canceled-invoices',
      moved: false,
      static: false
    },
    {
      w: 7,
      h: 6,
      x: 5,
      y: 9,
      i: 'esmm-mailing-mail',
      moved: false,
      static: false
    },
    {
      w: 7,
      h: 6,
      x: 12,
      y: 9,
      i: 'esmm-mailing-sms',
      moved: false,
      static: false
    },
    {
      w: 6,
      h: 2,
      x: 0,
      y: 15,
      i: 'esmm-invoice-total',
      moved: false,
      static: false
    },
    {
      w: 6,
      h: 2,
      x: 6,
      y: 15,
      i: 'esmm-invoice-canceled-total',
      moved: false,
      static: false
    },
    {
      w: 6,
      h: 2,
      x: 12,
      y: 15,
      i: 'esmm-invoice-mail-total',
      moved: false,
      static: false
    },
    {
      w: 6,
      h: 2,
      x: 18,
      y: 15,
      i: 'esmm-invoice-sms-total',
      moved: false,
      static: false
    },
    {
      w: 14,
      h: 9,
      x: 5,
      y: 0,
      i: 'esmm-daily',
      moved: false,
      static: false
    }
  ],
  xl: [
    {
      w: 6,
      h: 8,
      x: 0,
      y: 8,
      i: 'esmm-last-invoices',
      moved: false,
      static: false
    },
    {
      w: 6,
      h: 8,
      x: 6,
      y: 8,
      i: 'esmm-last-canceled-invoices',
      moved: false,
      static: false
    },
    {
      w: 6,
      h: 8,
      x: 12,
      y: 8,
      i: 'esmm-mailing-mail',
      moved: false,
      static: false
    },
    {
      w: 6,
      h: 8,
      x: 18,
      y: 8,
      i: 'esmm-mailing-sms',
      moved: false,
      static: false
    },
    {
      w: 6,
      h: 2,
      x: 0,
      y: 16,
      i: 'esmm-invoice-total',
      moved: false,
      static: false
    },
    {
      w: 6,
      h: 2,
      x: 6,
      y: 16,
      i: 'esmm-invoice-canceled-total',
      moved: false,
      static: false
    },
    {
      w: 6,
      h: 2,
      x: 12,
      y: 16,
      i: 'esmm-invoice-mail-total',
      moved: false,
      static: false
    },
    {
      w: 6,
      h: 2,
      x: 18,
      y: 16,
      i: 'esmm-invoice-sms-total',
      moved: false,
      static: false
    },
    {
      w: 24,
      h: 8,
      x: 0,
      y: 0,
      i: 'esmm-daily',
      moved: false,
      static: false
    }
  ],
  lg: [
    {
      w: 12,
      h: 9,
      x: 0,
      y: 8,
      i: 'esmm-last-invoices',
      moved: false,
      static: false
    },
    {
      w: 12,
      h: 9,
      x: 12,
      y: 8,
      i: 'esmm-last-canceled-invoices',
      moved: false,
      static: false
    },
    {
      w: 8,
      h: 8,
      x: 0,
      y: 17,
      i: 'esmm-mailing-mail',
      moved: false,
      static: false
    },
    {
      w: 8,
      h: 8,
      x: 8,
      y: 17,
      i: 'esmm-mailing-sms',
      moved: false,
      static: false
    },
    {
      w: 8,
      h: 2,
      x: 16,
      y: 17,
      i: 'esmm-invoice-total',
      moved: false,
      static: false
    },
    {
      w: 8,
      h: 2,
      x: 16,
      y: 19,
      i: 'esmm-invoice-canceled-total',
      moved: false,
      static: false
    },
    {
      w: 8,
      h: 2,
      x: 16,
      y: 21,
      i: 'esmm-invoice-mail-total',
      moved: false,
      static: false
    },
    {
      w: 8,
      h: 2,
      x: 16,
      y: 23,
      i: 'esmm-invoice-sms-total',
      moved: false,
      static: false
    },
    {
      w: 24,
      h: 8,
      x: 0,
      y: 0,
      i: 'esmm-daily',
      moved: false,
      static: false
    }
  ],
  md: [
    {
      w: 12,
      h: 8,
      x: 0,
      y: 7,
      i: 'esmm-last-invoices',
      moved: false,
      static: false
    },
    {
      w: 12,
      h: 8,
      x: 12,
      y: 7,
      i: 'esmm-last-canceled-invoices',
      moved: false,
      static: false
    },
    {
      w: 12,
      h: 5,
      x: 0,
      y: 15,
      i: 'esmm-mailing-mail',
      moved: false,
      static: false
    },
    {
      w: 12,
      h: 5,
      x: 12,
      y: 15,
      i: 'esmm-mailing-sms',
      moved: false,
      static: false
    },
    {
      w: 12,
      h: 2,
      x: 0,
      y: 20,
      i: 'esmm-invoice-total',
      moved: false,
      static: false
    },
    {
      w: 12,
      h: 2,
      x: 12,
      y: 20,
      i: 'esmm-invoice-canceled-total',
      moved: false,
      static: false
    },
    {
      w: 12,
      h: 2,
      x: 0,
      y: 22,
      i: 'esmm-invoice-mail-total',
      moved: false,
      static: false
    },
    {
      w: 12,
      h: 2,
      x: 12,
      y: 22,
      i: 'esmm-invoice-sms-total',
      moved: false,
      static: false
    },
    {
      w: 24,
      h: 7,
      x: 0,
      y: 0,
      i: 'esmm-daily',
      moved: false,
      static: false
    }
  ],
  sm: [
    {
      w: 12,
      h: 7,
      x: 0,
      y: 7,
      i: 'esmm-last-invoices',
      moved: false,
      static: false
    },
    {
      w: 12,
      h: 7,
      x: 12,
      y: 7,
      i: 'esmm-last-canceled-invoices',
      moved: false,
      static: false
    },
    {
      w: 12,
      h: 7,
      x: 0,
      y: 20,
      i: 'esmm-mailing-mail',
      moved: false,
      static: false
    },
    {
      w: 12,
      h: 7,
      x: 12,
      y: 20,
      i: 'esmm-mailing-sms',
      moved: false,
      static: false
    },
    {
      w: 12,
      h: 2,
      x: 0,
      y: 27,
      i: 'esmm-invoice-total',
      moved: false,
      static: false
    },
    {
      w: 12,
      h: 2,
      x: 12,
      y: 27,
      i: 'esmm-invoice-canceled-total',
      moved: false,
      static: false
    },
    {
      w: 12,
      h: 2,
      x: 0,
      y: 29,
      i: 'esmm-invoice-mail-total',
      moved: false,
      static: false
    },
    {
      w: 12,
      h: 2,
      x: 12,
      y: 29,
      i: 'esmm-invoice-sms-total',
      moved: false,
      static: false
    },
    {
      w: 24,
      h: 7,
      x: 0,
      y: 0,
      i: 'esmm-daily',
      moved: false,
      static: false
    }
  ],
  xs: [
    {
      w: 24,
      h: 7,
      x: 0,
      y: 6,
      i: 'esmm-last-invoices',
      moved: false,
      static: false
    },
    {
      w: 24,
      h: 7,
      x: 0,
      y: 13,
      i: 'esmm-last-canceled-invoices',
      moved: false,
      static: false
    },
    {
      w: 12,
      h: 7,
      x: 0,
      y: 26,
      i: 'esmm-mailing-mail',
      moved: false,
      static: false
    },
    {
      w: 12,
      h: 7,
      x: 12,
      y: 26,
      i: 'esmm-mailing-sms',
      moved: false,
      static: false
    },
    {
      w: 12,
      h: 2,
      x: 0,
      y: 33,
      i: 'esmm-invoice-total',
      moved: false,
      static: false
    },
    {
      w: 12,
      h: 2,
      x: 12,
      y: 33,
      i: 'esmm-invoice-canceled-total',
      moved: false,
      static: false
    },
    {
      w: 12,
      h: 2,
      x: 0,
      y: 35,
      i: 'esmm-invoice-mail-total',
      moved: false,
      static: false
    },
    {
      w: 12,
      h: 2,
      x: 12,
      y: 35,
      i: 'esmm-invoice-sms-total',
      moved: false,
      static: false
    },
    {
      w: 24,
      h: 6,
      x: 0,
      y: 0,
      i: 'esmm-daily',
      moved: false,
      static: false
    }
  ]
};
