import { AnyType } from '@/type';
import { Col, DatePicker, Radio, Row } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import moment from 'moment';
import { PickerRefConfig } from 'rc-picker/lib/Picker';
import React, { memo, useEffect, useRef, useState } from 'react';
import intl from 'react-intl-universal';
import './style.scss';
interface DateRangeSelect {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

const ExtraRenderFooter = ({ onChange }: { onChange: (e: RadioChangeEvent) => void }) => {
  const ranges = {
    today: [moment(), moment()],
    week: [moment().add(-1, 'week'), moment()],
    month: [moment().startOf('month'), moment()],
    thisMonth: [moment().add(-1, 'month'), moment()]
  };
  return (
    <Radio.Group value={4} onChange={onChange}>
      <Radio.Button value={ranges.today}>Bugün</Radio.Button>
      <Radio.Button value={ranges.week}>Bu Hafta</Radio.Button>
      <Radio.Button value={ranges.month}>Bu Ay</Radio.Button>
      <Radio.Button value={ranges.thisMonth}>Son 1 Ay</Radio.Button>
    </Radio.Group>
  );
};

export const DateRangeSelectorExternalState = memo(
  ({ values, onChange, maxDateUnit }: { values: DateRangeSelect; onChange: (values: DateRangeSelect) => void; maxDateUnit?: '1Month' | '1Week' }) => {
    const [isOpenEndDate, setIsOpenEndDate] = useState(false);
    const endDatePicker = useRef<PickerRefConfig>(null);

    const setSelectedDate = (selectedDate, type: 'StartDate' | 'EndDate') => {
      if (type === 'StartDate') {
        let unit = 'months';

        if (maxDateUnit == '1Week') {
          unit = 'weeks';
        }

        let maxDate = moment(selectedDate).add(1, unit as any);

        if (maxDate.toDate() > new Date()) {
          maxDate = moment(new Date());
        }

        onChange({
          startDate: selectedDate,
          endDate: !values.endDate.isBetween(selectedDate, maxDate) ? maxDate : values.endDate
        });

        setIsOpenEndDate(true);
        endDatePicker.current && endDatePicker.current.focus();
      } else {
        onChange({ ...values, endDate: selectedDate });
      }
    };

    return (
      <Row gutter={5}>
        <Col xs={12}>
          <DatePicker
            className="w-100"
            allowClear={false}
            showToday={false}
            value={values.startDate}
            placeholder={intl.get('BASLANGIC_TARIHI')}
            disabledDate={(current) => current > moment().endOf('day')}
            onChange={(value) => value && setSelectedDate(value, 'StartDate')}
            renderExtraFooter={() => <ExtraRenderFooter onChange={(e) => onChange({ startDate: e.target.value[0], endDate: e.target.value[1] })} />}
          />
        </Col>
        <Col xs={12}>
          <DatePicker
            ref={endDatePicker as AnyType}
            open={isOpenEndDate}
            onOpenChange={() => setIsOpenEndDate(!isOpenEndDate)}
            className="w-100"
            showToday={false}
            allowClear={false}
            value={values.endDate}
            placeholder={intl.get('BITIS_TARIHI')}
            onChange={(value) => value && setSelectedDate(value, 'EndDate')}
            disabledDate={(current) => current > moment(values.startDate).add(1, 'month') || current >= moment() || current < moment(values.startDate)}
            renderExtraFooter={() => <ExtraRenderFooter onChange={(e) => onChange({ ...values, startDate: e.target.value[0], endDate: e.target.value[1] })} />}
          />
        </Col>
      </Row>
    );
  }
);

export default DateRangeSelectorExternalState;
