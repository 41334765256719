// tslint:disable
// eslint:disable
/**
 * E-Invoice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ActiveStatus } from '../interfaces';
// @ts-ignore
import { ActiveStatusParameter } from '../interfaces';
// @ts-ignore
import { DocumentSerie } from '../interfaces';
// @ts-ignore
import { DocumentSerieDto } from '../interfaces';
// @ts-ignore
import { DocumentSerieNumberHistoryPaginationResult } from '../interfaces';
// @ts-ignore
import { SerieSourceParameter } from '../interfaces';
/**
 * SeriesApi - axios parameter creator
 * @export
 */
export const SeriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Kayıtlı seri listenize bu uç ile ulaşabilirsiniz.
         * @summary Serileri listeler
         * @param {ActiveStatusParameter} [status] **Durum**
         * @param {SerieSourceParameter} [source] **Seri kaynağı, Portal / ERP Serisi buradan sorgulanabilir**
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsSeriesGet: async (requestParameters: V1DefinitionsSeriesGetRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/definitions/series`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.status !== undefined) {
                localVarQueryParameter['status'] = requestParameters.status;
            }
            if (requestParameters.source !== undefined) {
                localVarQueryParameter['source'] = requestParameters.source;
            }
            if (requestParameters.query !== undefined) {
                localVarQueryParameter['query'] = requestParameters.query;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Kayıtlı seriyi silmek bu uç kullanılabilir.
         * @summary Seri siler
         * @param {string} id **Silinecek seri id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsSeriesIdDelete: async (requestParameters: V1DefinitionsSeriesIdDeleteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DefinitionsSeriesIdDelete.');
            }
            const localVarPath = `/v1/definitions/series/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sorgulanan seriyi getirir
         * @param {string} id **Seri id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsSeriesIdGet: async (requestParameters: V1DefinitionsSeriesIdGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DefinitionsSeriesIdGet.');
            }
            const localVarPath = `/v1/definitions/series/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Serinin aktiflik durumunu güncellemek için bu uç kullanılabilir.    **status** Parametre detayları    > **Active** : Kaydı aktif eder    > **Passive** : Kaydı pasif eder    > **Deleted** : Kaydı silindi olarak işaretler
         * @summary Seri durumunu günceller
         * @param {string} id **Seri id**
         * @param {ActiveStatus} status **Güncellenecek yeni durum**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsSeriesIdSetStatusGet: async (requestParameters: V1DefinitionsSeriesIdSetStatusGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DefinitionsSeriesIdSetStatusGet.');
            }
            // verify required parameter 'status' is not null or undefined
            if (requestParameters.status === null || requestParameters.status === undefined) {
                throw new RequiredError('status','Required parameter status was null or undefined when calling v1DefinitionsSeriesIdSetStatusGet.');
            }
            const localVarPath = `/v1/definitions/series/{id}/set/{status}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"status"}}`, encodeURIComponent(String(requestParameters.status)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Seriyi varsayılan olarak atamak için bu uç kullanılabilir.
         * @summary Seriyi varsayılan ayarlar
         * @param {string} id **Seri id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsSeriesIdSetdefaultGet: async (requestParameters: V1DefinitionsSeriesIdSetdefaultGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DefinitionsSeriesIdSetdefaultGet.');
            }
            const localVarPath = `/v1/definitions/series/{id}/setdefault`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Serinin belirtilen yıla ait sayacı (sıraki numarayı) güncellemek için bu uç kullanılabilir.
         * @summary Sayaç günceller
         * @param {string} id **Seri id**
         * @param {number} year **Sayaç yılı**
         * @param {number} nextNumber **Güncellenecek yeni numara**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsSeriesIdYearSetnumberNextNumberGet: async (requestParameters: V1DefinitionsSeriesIdYearSetnumberNextNumberGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DefinitionsSeriesIdYearSetnumberNextNumberGet.');
            }
            // verify required parameter 'year' is not null or undefined
            if (requestParameters.year === null || requestParameters.year === undefined) {
                throw new RequiredError('year','Required parameter year was null or undefined when calling v1DefinitionsSeriesIdYearSetnumberNextNumberGet.');
            }
            // verify required parameter 'nextNumber' is not null or undefined
            if (requestParameters.nextNumber === null || requestParameters.nextNumber === undefined) {
                throw new RequiredError('nextNumber','Required parameter nextNumber was null or undefined when calling v1DefinitionsSeriesIdYearSetnumberNextNumberGet.');
            }
            const localVarPath = `/v1/definitions/series/{id}/{year}/setnumber/{nextNumber}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"year"}}`, encodeURIComponent(String(requestParameters.year))).replace(`{${"nextNumber"}}`, encodeURIComponent(String(requestParameters.nextNumber)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Yeni seri eklemek için bu uç kullanılabilir.
         * @summary Seri ekler
         * @param {DocumentSerieDto} documentSerieDto #### Seri oluşturmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsSeriesPost: async (requestParameters: V1DefinitionsSeriesPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentSerieDto' is not null or undefined
            if (requestParameters.documentSerieDto === null || requestParameters.documentSerieDto === undefined) {
                throw new RequiredError('documentSerieDto','Required parameter documentSerieDto was null or undefined when calling v1DefinitionsSeriesPost.');
            }
            const localVarPath = `/v1/definitions/series`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.documentSerieDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.documentSerieDto !== undefined ? requestParameters.documentSerieDto : {}) : (requestParameters.documentSerieDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ön eke göre seriyi getirir
         * @param {string} serie **Seri (Ön ek)**
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsSeriesSerieGet: async (requestParameters: V1DefinitionsSeriesSerieGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serie' is not null or undefined
            if (requestParameters.serie === null || requestParameters.serie === undefined) {
                throw new RequiredError('serie','Required parameter serie was null or undefined when calling v1DefinitionsSeriesSerieGet.');
            }
            // verify required parameter 'year' is not null or undefined
            if (requestParameters.year === null || requestParameters.year === undefined) {
                throw new RequiredError('year','Required parameter year was null or undefined when calling v1DefinitionsSeriesSerieGet.');
            }
            const localVarPath = `/v1/definitions/series/{serie}`.replace(`{${"serie"}}`, encodeURIComponent(String(requestParameters.serie))).replace(`{${"year"}}`, encodeURIComponent(String(requestParameters.year)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sayaç geçmişi
         * @param {string} serieId **Seri id**
         * @param {number} year **Sayaç yıl**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsSeriesSerieIdYearHistoriesGet: async (requestParameters: V1DefinitionsSeriesSerieIdYearHistoriesGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serieId' is not null or undefined
            if (requestParameters.serieId === null || requestParameters.serieId === undefined) {
                throw new RequiredError('serieId','Required parameter serieId was null or undefined when calling v1DefinitionsSeriesSerieIdYearHistoriesGet.');
            }
            // verify required parameter 'year' is not null or undefined
            if (requestParameters.year === null || requestParameters.year === undefined) {
                throw new RequiredError('year','Required parameter year was null or undefined when calling v1DefinitionsSeriesSerieIdYearHistoriesGet.');
            }
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1DefinitionsSeriesSerieIdYearHistoriesGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1DefinitionsSeriesSerieIdYearHistoriesGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1DefinitionsSeriesSerieIdYearHistoriesGet.');
            }
            const localVarPath = `/v1/definitions/series/{serieId}/{year}/histories`.replace(`{${"serieId"}}`, encodeURIComponent(String(requestParameters.serieId))).replace(`{${"year"}}`, encodeURIComponent(String(requestParameters.year)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SeriesApi - functional programming interface
 * @export
 */
export const SeriesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Kayıtlı seri listenize bu uç ile ulaşabilirsiniz.
         * @summary Serileri listeler
         * @param {ActiveStatusParameter} [status] **Durum**
         * @param {SerieSourceParameter} [source] **Seri kaynağı, Portal / ERP Serisi buradan sorgulanabilir**
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsSeriesGet(requestParameters: V1DefinitionsSeriesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentSerie>>> {
            const localVarAxiosArgs = await SeriesApiAxiosParamCreator(configuration).v1DefinitionsSeriesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Kayıtlı seriyi silmek bu uç kullanılabilir.
         * @summary Seri siler
         * @param {string} id **Silinecek seri id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsSeriesIdDelete(requestParameters: V1DefinitionsSeriesIdDeleteRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SeriesApiAxiosParamCreator(configuration).v1DefinitionsSeriesIdDelete(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sorgulanan seriyi getirir
         * @param {string} id **Seri id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsSeriesIdGet(requestParameters: V1DefinitionsSeriesIdGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentSerie>> {
            const localVarAxiosArgs = await SeriesApiAxiosParamCreator(configuration).v1DefinitionsSeriesIdGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Serinin aktiflik durumunu güncellemek için bu uç kullanılabilir.    **status** Parametre detayları    > **Active** : Kaydı aktif eder    > **Passive** : Kaydı pasif eder    > **Deleted** : Kaydı silindi olarak işaretler
         * @summary Seri durumunu günceller
         * @param {string} id **Seri id**
         * @param {ActiveStatus} status **Güncellenecek yeni durum**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsSeriesIdSetStatusGet(requestParameters: V1DefinitionsSeriesIdSetStatusGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SeriesApiAxiosParamCreator(configuration).v1DefinitionsSeriesIdSetStatusGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Seriyi varsayılan olarak atamak için bu uç kullanılabilir.
         * @summary Seriyi varsayılan ayarlar
         * @param {string} id **Seri id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsSeriesIdSetdefaultGet(requestParameters: V1DefinitionsSeriesIdSetdefaultGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SeriesApiAxiosParamCreator(configuration).v1DefinitionsSeriesIdSetdefaultGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Serinin belirtilen yıla ait sayacı (sıraki numarayı) güncellemek için bu uç kullanılabilir.
         * @summary Sayaç günceller
         * @param {string} id **Seri id**
         * @param {number} year **Sayaç yılı**
         * @param {number} nextNumber **Güncellenecek yeni numara**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsSeriesIdYearSetnumberNextNumberGet(requestParameters: V1DefinitionsSeriesIdYearSetnumberNextNumberGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SeriesApiAxiosParamCreator(configuration).v1DefinitionsSeriesIdYearSetnumberNextNumberGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Yeni seri eklemek için bu uç kullanılabilir.
         * @summary Seri ekler
         * @param {DocumentSerieDto} documentSerieDto #### Seri oluşturmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsSeriesPost(requestParameters: V1DefinitionsSeriesPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentSerie>> {
            const localVarAxiosArgs = await SeriesApiAxiosParamCreator(configuration).v1DefinitionsSeriesPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Ön eke göre seriyi getirir
         * @param {string} serie **Seri (Ön ek)**
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsSeriesSerieGet(requestParameters: V1DefinitionsSeriesSerieGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentSerie>> {
            const localVarAxiosArgs = await SeriesApiAxiosParamCreator(configuration).v1DefinitionsSeriesSerieGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sayaç geçmişi
         * @param {string} serieId **Seri id**
         * @param {number} year **Sayaç yıl**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsSeriesSerieIdYearHistoriesGet(requestParameters: V1DefinitionsSeriesSerieIdYearHistoriesGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentSerieNumberHistoryPaginationResult>> {
            const localVarAxiosArgs = await SeriesApiAxiosParamCreator(configuration).v1DefinitionsSeriesSerieIdYearHistoriesGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SeriesApi - factory interface
 * @export
 */
export const SeriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Kayıtlı seri listenize bu uç ile ulaşabilirsiniz.
         * @summary Serileri listeler
         * @param {ActiveStatusParameter} [status] **Durum**
         * @param {SerieSourceParameter} [source] **Seri kaynağı, Portal / ERP Serisi buradan sorgulanabilir**
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsSeriesGet(requestParameters: V1DefinitionsSeriesGetRequest, options?: any): AxiosPromise<Array<DocumentSerie>> {
            return SeriesApiFp(configuration).v1DefinitionsSeriesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Kayıtlı seriyi silmek bu uç kullanılabilir.
         * @summary Seri siler
         * @param {string} id **Silinecek seri id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsSeriesIdDelete(requestParameters: V1DefinitionsSeriesIdDeleteRequest, options?: any): AxiosPromise<void> {
            return SeriesApiFp(configuration).v1DefinitionsSeriesIdDelete(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sorgulanan seriyi getirir
         * @param {string} id **Seri id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsSeriesIdGet(requestParameters: V1DefinitionsSeriesIdGetRequest, options?: any): AxiosPromise<DocumentSerie> {
            return SeriesApiFp(configuration).v1DefinitionsSeriesIdGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Serinin aktiflik durumunu güncellemek için bu uç kullanılabilir.    **status** Parametre detayları    > **Active** : Kaydı aktif eder    > **Passive** : Kaydı pasif eder    > **Deleted** : Kaydı silindi olarak işaretler
         * @summary Seri durumunu günceller
         * @param {string} id **Seri id**
         * @param {ActiveStatus} status **Güncellenecek yeni durum**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsSeriesIdSetStatusGet(requestParameters: V1DefinitionsSeriesIdSetStatusGetRequest, options?: any): AxiosPromise<void> {
            return SeriesApiFp(configuration).v1DefinitionsSeriesIdSetStatusGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Seriyi varsayılan olarak atamak için bu uç kullanılabilir.
         * @summary Seriyi varsayılan ayarlar
         * @param {string} id **Seri id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsSeriesIdSetdefaultGet(requestParameters: V1DefinitionsSeriesIdSetdefaultGetRequest, options?: any): AxiosPromise<void> {
            return SeriesApiFp(configuration).v1DefinitionsSeriesIdSetdefaultGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Serinin belirtilen yıla ait sayacı (sıraki numarayı) güncellemek için bu uç kullanılabilir.
         * @summary Sayaç günceller
         * @param {string} id **Seri id**
         * @param {number} year **Sayaç yılı**
         * @param {number} nextNumber **Güncellenecek yeni numara**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsSeriesIdYearSetnumberNextNumberGet(requestParameters: V1DefinitionsSeriesIdYearSetnumberNextNumberGetRequest, options?: any): AxiosPromise<void> {
            return SeriesApiFp(configuration).v1DefinitionsSeriesIdYearSetnumberNextNumberGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Yeni seri eklemek için bu uç kullanılabilir.
         * @summary Seri ekler
         * @param {DocumentSerieDto} documentSerieDto #### Seri oluşturmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsSeriesPost(requestParameters: V1DefinitionsSeriesPostRequest, options?: any): AxiosPromise<DocumentSerie> {
            return SeriesApiFp(configuration).v1DefinitionsSeriesPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ön eke göre seriyi getirir
         * @param {string} serie **Seri (Ön ek)**
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsSeriesSerieGet(requestParameters: V1DefinitionsSeriesSerieGetRequest, options?: any): AxiosPromise<DocumentSerie> {
            return SeriesApiFp(configuration).v1DefinitionsSeriesSerieGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sayaç geçmişi
         * @param {string} serieId **Seri id**
         * @param {number} year **Sayaç yıl**
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsSeriesSerieIdYearHistoriesGet(requestParameters: V1DefinitionsSeriesSerieIdYearHistoriesGetRequest, options?: any): AxiosPromise<DocumentSerieNumberHistoryPaginationResult> {
            return SeriesApiFp(configuration).v1DefinitionsSeriesSerieIdYearHistoriesGet(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SeriesApi - object-oriented interface
 * @export
 * @class SeriesApi
 * @extends {BaseAPI}
 */
export class SeriesApi extends BaseAPI {
    /**
     * Kayıtlı seri listenize bu uç ile ulaşabilirsiniz.
     * @summary Serileri listeler
     * @param {ActiveStatusParameter} [status] **Durum**
     * @param {SerieSourceParameter} [source] **Seri kaynağı, Portal / ERP Serisi buradan sorgulanabilir**
     * @param {string} [query] Aranacak kelime
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeriesApi
     */
    public v1DefinitionsSeriesGet(requestParameters: V1DefinitionsSeriesGetRequest, options?: any) {
        return SeriesApiFp(this.configuration).v1DefinitionsSeriesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Kayıtlı seriyi silmek bu uç kullanılabilir.
     * @summary Seri siler
     * @param {string} id **Silinecek seri id**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeriesApi
     */
    public v1DefinitionsSeriesIdDelete(requestParameters: V1DefinitionsSeriesIdDeleteRequest, options?: any) {
        return SeriesApiFp(this.configuration).v1DefinitionsSeriesIdDelete(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sorgulanan seriyi getirir
     * @param {string} id **Seri id**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeriesApi
     */
    public v1DefinitionsSeriesIdGet(requestParameters: V1DefinitionsSeriesIdGetRequest, options?: any) {
        return SeriesApiFp(this.configuration).v1DefinitionsSeriesIdGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Serinin aktiflik durumunu güncellemek için bu uç kullanılabilir.    **status** Parametre detayları    > **Active** : Kaydı aktif eder    > **Passive** : Kaydı pasif eder    > **Deleted** : Kaydı silindi olarak işaretler
     * @summary Seri durumunu günceller
     * @param {string} id **Seri id**
     * @param {ActiveStatus} status **Güncellenecek yeni durum**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeriesApi
     */
    public v1DefinitionsSeriesIdSetStatusGet(requestParameters: V1DefinitionsSeriesIdSetStatusGetRequest, options?: any) {
        return SeriesApiFp(this.configuration).v1DefinitionsSeriesIdSetStatusGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Seriyi varsayılan olarak atamak için bu uç kullanılabilir.
     * @summary Seriyi varsayılan ayarlar
     * @param {string} id **Seri id**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeriesApi
     */
    public v1DefinitionsSeriesIdSetdefaultGet(requestParameters: V1DefinitionsSeriesIdSetdefaultGetRequest, options?: any) {
        return SeriesApiFp(this.configuration).v1DefinitionsSeriesIdSetdefaultGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Serinin belirtilen yıla ait sayacı (sıraki numarayı) güncellemek için bu uç kullanılabilir.
     * @summary Sayaç günceller
     * @param {string} id **Seri id**
     * @param {number} year **Sayaç yılı**
     * @param {number} nextNumber **Güncellenecek yeni numara**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeriesApi
     */
    public v1DefinitionsSeriesIdYearSetnumberNextNumberGet(requestParameters: V1DefinitionsSeriesIdYearSetnumberNextNumberGetRequest, options?: any) {
        return SeriesApiFp(this.configuration).v1DefinitionsSeriesIdYearSetnumberNextNumberGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Yeni seri eklemek için bu uç kullanılabilir.
     * @summary Seri ekler
     * @param {DocumentSerieDto} documentSerieDto #### Seri oluşturmak için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeriesApi
     */
    public v1DefinitionsSeriesPost(requestParameters: V1DefinitionsSeriesPostRequest, options?: any) {
        return SeriesApiFp(this.configuration).v1DefinitionsSeriesPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ön eke göre seriyi getirir
     * @param {string} serie **Seri (Ön ek)**
     * @param {number} year 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeriesApi
     */
    public v1DefinitionsSeriesSerieGet(requestParameters: V1DefinitionsSeriesSerieGetRequest, options?: any) {
        return SeriesApiFp(this.configuration).v1DefinitionsSeriesSerieGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sayaç geçmişi
     * @param {string} serieId **Seri id**
     * @param {number} year **Sayaç yıl**
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeriesApi
     */
    public v1DefinitionsSeriesSerieIdYearHistoriesGet(requestParameters: V1DefinitionsSeriesSerieIdYearHistoriesGetRequest, options?: any) {
        return SeriesApiFp(this.configuration).v1DefinitionsSeriesSerieIdYearHistoriesGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1DefinitionsSeriesGetRequest {
    /**
     * **Durum**
     */
    status?: ActiveStatusParameter;
    /**
     * **Seri kaynağı, Portal / ERP Serisi buradan sorgulanabilir**
     */
    source?: SerieSourceParameter;
    /**
     * Aranacak kelime
     */
    query?: string;
    }
    export interface V1DefinitionsSeriesIdDeleteRequest {
    /**
     * **Silinecek seri id**
     */
    id: string;
    }
    export interface V1DefinitionsSeriesIdGetRequest {
    /**
     * **Seri id**
     */
    id: string;
    }
    export interface V1DefinitionsSeriesIdSetStatusGetRequest {
    /**
     * **Seri id**
     */
    id: string;
    /**
     * **Güncellenecek yeni durum**
     */
    status: ActiveStatus;
    }
    export interface V1DefinitionsSeriesIdSetdefaultGetRequest {
    /**
     * **Seri id**
     */
    id: string;
    }
    export interface V1DefinitionsSeriesIdYearSetnumberNextNumberGetRequest {
    /**
     * **Seri id**
     */
    id: string;
    /**
     * **Sayaç yılı**
     */
    year: number;
    /**
     * **Güncellenecek yeni numara**
     */
    nextNumber: number;
    }
    export interface V1DefinitionsSeriesPostRequest {
    /**
     * #### Seri oluşturmak için gönderilmesi gereken model
     */
    documentSerieDto: DocumentSerieDto;
    }
    export interface V1DefinitionsSeriesSerieGetRequest {
    /**
     * **Seri (Ön ek)**
     */
    serie: string;
    /**
     * 
     */
    year: number;
    }
    export interface V1DefinitionsSeriesSerieIdYearHistoriesGetRequest {
    /**
     * **Seri id**
     */
    serieId: string;
    /**
     * **Sayaç yıl**
     */
    year: number;
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    }
