// tslint:disable
// eslint:disable
/**
 * E-Invoice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ExInvoiceFileExportTypeParameter } from '../interfaces';
// @ts-ignore
import { ExInvoicePaginationResult } from '../interfaces';
// @ts-ignore
import { ExInvoiceQueue } from '../interfaces';
// @ts-ignore
import { ExInvoiceQueuePaginationResult } from '../interfaces';
// @ts-ignore
import { FileExportTypeParameter } from '../interfaces';
// @ts-ignore
import { InvoiceProfileIdParameter } from '../interfaces';
/**
 * ExInvoicesApi - axios parameter creator
 * @export
 */
export const ExInvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Eski belgelerinizi toplu olarak dışarı aktarmak isterseniz bu ucu kullanabilirsiniz.            **Limit** : Tek bir istek içerisinde maks **100** kayıt dışarı aktarılabilir.    **fileType** Parametre detayları    > **Xml** : Belgenizin XML halini dışarı aktarır    > **Pdf** : Belgenize ait PDF görüntüsünü dışarı aktarır    > **UnifiedPdf** : ***uuids*** parametresinde gönderilen belgeleri tek PDF dosyası halinde dışarı aktarır    > **Excel** : ***uuids*** parametresinde gönderilen belgeleri Excel\'e aktarır
         * @summary Dışarı Aktar
         * @param {ExInvoiceFileExportTypeParameter} fileType 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExinvoicesIncomingExportFileTypePost: async (requestParameters: V1ExinvoicesIncomingExportFileTypePostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileType' is not null or undefined
            if (requestParameters.fileType === null || requestParameters.fileType === undefined) {
                throw new RequiredError('fileType','Required parameter fileType was null or undefined when calling v1ExinvoicesIncomingExportFileTypePost.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling v1ExinvoicesIncomingExportFileTypePost.');
            }
            const localVarPath = `/v1/exinvoices/incoming/export/{fileType}`.replace(`{${"fileType"}}`, encodeURIComponent(String(requestParameters.fileType)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.requestBody !== undefined ? requestParameters.requestBody : {}) : (requestParameters.requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Daha önce yüklenmiş olan eski belgelerinize bu uç ile ulaşabilirsiniz.
         * @summary Belgeleri listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} startDate Başlangıç tarihi
         * @param {string} endDate Bitiş tarihi
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {InvoiceProfileIdParameter} [profileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExinvoicesIncomingGet: async (requestParameters: V1ExinvoicesIncomingGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1ExinvoicesIncomingGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1ExinvoicesIncomingGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1ExinvoicesIncomingGet.');
            }
            // verify required parameter 'startDate' is not null or undefined
            if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
                throw new RequiredError('startDate','Required parameter startDate was null or undefined when calling v1ExinvoicesIncomingGet.');
            }
            // verify required parameter 'endDate' is not null or undefined
            if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
                throw new RequiredError('endDate','Required parameter endDate was null or undefined when calling v1ExinvoicesIncomingGet.');
            }
            const localVarPath = `/v1/exinvoices/incoming`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.company !== undefined) {
                localVarQueryParameter['company'] = requestParameters.company;
            }
            if (requestParameters.uuid !== undefined) {
                localVarQueryParameter['uuid'] = requestParameters.uuid;
            }
            if (requestParameters.documentNumber !== undefined) {
                localVarQueryParameter['documentNumber'] = requestParameters.documentNumber;
            }
            if (requestParameters.profileId !== undefined) {
                localVarQueryParameter['profileId'] = requestParameters.profileId;
            }
            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }
            if (requestParameters.startDate !== undefined) {
                localVarQueryParameter['startDate'] = requestParameters.startDate;
            }
            if (requestParameters.endDate !== undefined) {
                localVarQueryParameter['endDate'] = requestParameters.endDate;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye ait HTML görüntüye bu uç ile ulaşabilirsiniz.
         * @summary Belgeyi görüntüler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExinvoicesIncomingUuidHtmlGet: async (requestParameters: V1ExinvoicesIncomingUuidHtmlGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1ExinvoicesIncomingUuidHtmlGet.');
            }
            const localVarPath = `/v1/exinvoices/incoming/{uuid}/html`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Eski belgeye ait PDF dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary PDF İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExinvoicesIncomingUuidPdfGet: async (requestParameters: V1ExinvoicesIncomingUuidPdfGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1ExinvoicesIncomingUuidPdfGet.');
            }
            const localVarPath = `/v1/exinvoices/incoming/{uuid}/pdf`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Eski belgeye ait XML dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary XML İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExinvoicesIncomingUuidXmlGet: async (requestParameters: V1ExinvoicesIncomingUuidXmlGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1ExinvoicesIncomingUuidXmlGet.');
            }
            const localVarPath = `/v1/exinvoices/incoming/{uuid}/xml`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Eski belgelerinizi toplu olarak dışarı aktarmak isterseniz bu ucu kullanabilirsiniz.            **Limit** : Tek bir istek içerisinde maks **100** kayıt dışarı aktarılabilir.    **fileType** Parametre detayları    > **Xml** : Belgenizin XML halini dışarı aktarır    > **Pdf** : Belgenize ait PDF görüntüsünü dışarı aktarır    > **UnifiedPdf** : ***uuids*** parametresinde gönderilen belgeleri tek PDF dosyası halinde dışarı aktarır    > **Excel** : ***uuids*** parametresinde gönderilen belgeleri Excel\'e aktarır
         * @summary Dışarı Aktar
         * @param {FileExportTypeParameter} fileType 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExinvoicesOutgoingExportFileTypePost: async (requestParameters: V1ExinvoicesOutgoingExportFileTypePostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileType' is not null or undefined
            if (requestParameters.fileType === null || requestParameters.fileType === undefined) {
                throw new RequiredError('fileType','Required parameter fileType was null or undefined when calling v1ExinvoicesOutgoingExportFileTypePost.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling v1ExinvoicesOutgoingExportFileTypePost.');
            }
            const localVarPath = `/v1/exinvoices/outgoing/export/{fileType}`.replace(`{${"fileType"}}`, encodeURIComponent(String(requestParameters.fileType)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.requestBody !== undefined ? requestParameters.requestBody : {}) : (requestParameters.requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Daha önce yüklenmiş olan eski belgelerinize bu uç ile ulaşabilirsiniz.
         * @summary Belgeleri listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} startDate Başlangıç tarihi
         * @param {string} endDate Bitiş tarihi
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {InvoiceProfileIdParameter} [profileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExinvoicesOutgoingGet: async (requestParameters: V1ExinvoicesOutgoingGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1ExinvoicesOutgoingGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1ExinvoicesOutgoingGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1ExinvoicesOutgoingGet.');
            }
            // verify required parameter 'startDate' is not null or undefined
            if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
                throw new RequiredError('startDate','Required parameter startDate was null or undefined when calling v1ExinvoicesOutgoingGet.');
            }
            // verify required parameter 'endDate' is not null or undefined
            if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
                throw new RequiredError('endDate','Required parameter endDate was null or undefined when calling v1ExinvoicesOutgoingGet.');
            }
            const localVarPath = `/v1/exinvoices/outgoing`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.company !== undefined) {
                localVarQueryParameter['company'] = requestParameters.company;
            }
            if (requestParameters.uuid !== undefined) {
                localVarQueryParameter['uuid'] = requestParameters.uuid;
            }
            if (requestParameters.documentNumber !== undefined) {
                localVarQueryParameter['documentNumber'] = requestParameters.documentNumber;
            }
            if (requestParameters.profileId !== undefined) {
                localVarQueryParameter['profileId'] = requestParameters.profileId;
            }
            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }
            if (requestParameters.startDate !== undefined) {
                localVarQueryParameter['startDate'] = requestParameters.startDate;
            }
            if (requestParameters.endDate !== undefined) {
                localVarQueryParameter['endDate'] = requestParameters.endDate;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Belgeye ait HTML görüntüye bu uç ile ulaşabilirsiniz.
         * @summary Belgeyi görüntüler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExinvoicesOutgoingUuidHtmlGet: async (requestParameters: V1ExinvoicesOutgoingUuidHtmlGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1ExinvoicesOutgoingUuidHtmlGet.');
            }
            const localVarPath = `/v1/exinvoices/outgoing/{uuid}/html`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Eski belgeye ait PDF dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary PDF İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExinvoicesOutgoingUuidPdfGet: async (requestParameters: V1ExinvoicesOutgoingUuidPdfGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1ExinvoicesOutgoingUuidPdfGet.');
            }
            const localVarPath = `/v1/exinvoices/outgoing/{uuid}/pdf`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Eski belgeye ait XML dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary XML İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExinvoicesOutgoingUuidXmlGet: async (requestParameters: V1ExinvoicesOutgoingUuidXmlGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling v1ExinvoicesOutgoingUuidXmlGet.');
            }
            const localVarPath = `/v1/exinvoices/outgoing/{uuid}/xml`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Eski belge yüklemek için bu uç kullanılabilir.            > Eski belgelerinizi **ZIP** dosyası içerisinde yüklemeniz gerekmektedir. İç içe klasörler, iç içe zipler sistem tarafından otomatik ayrıştırılarak yüklenecektir.     > Bundan dolayı bir ZIP içerisinde bir belge ***değil***, ZIP içerisinde yüzlerce belgeyi yükleyebilirsiniz.
         * @summary Eski belge yükler
         * @param {any} file Dosya
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExinvoicesPost: async (requestParameters: V1ExinvoicesPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            if (requestParameters.file === null || requestParameters.file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling v1ExinvoicesPost.');
            }
            const localVarPath = `/v1/exinvoices`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


                if (requestParameters.file !== undefined) { 
                    localVarFormParams.append('File', requestParameters.file as any);
                }

    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Eski belgeleri sisteme yükledikten sonra, işlenme (Kayıt edilip edilmediği) durumuna bu uç ile ulaşabilirsiniz.
         * @summary Yükleme kuyruğunu listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} startDate Başlangıç tarihi
         * @param {string} endDate Bitiş tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExinvoicesQueueGet: async (requestParameters: V1ExinvoicesQueueGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sort' is not null or undefined
            if (requestParameters.sort === null || requestParameters.sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling v1ExinvoicesQueueGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1ExinvoicesQueueGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1ExinvoicesQueueGet.');
            }
            // verify required parameter 'startDate' is not null or undefined
            if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
                throw new RequiredError('startDate','Required parameter startDate was null or undefined when calling v1ExinvoicesQueueGet.');
            }
            // verify required parameter 'endDate' is not null or undefined
            if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
                throw new RequiredError('endDate','Required parameter endDate was null or undefined when calling v1ExinvoicesQueueGet.');
            }
            const localVarPath = `/v1/exinvoices/queue`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.sort !== undefined) {
                localVarQueryParameter['sort'] = requestParameters.sort;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }
            if (requestParameters.startDate !== undefined) {
                localVarQueryParameter['startDate'] = requestParameters.startDate;
            }
            if (requestParameters.endDate !== undefined) {
                localVarQueryParameter['endDate'] = requestParameters.endDate;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Eski belgeleri sisteme yükledikten sonra yükleme sonuç raporuna bu uç ile ulaşabilirsiniz.
         * @summary Yükleme sonucunu indir
         * @param {string} id **Yükleme id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExinvoicesQueueIdGet: async (requestParameters: V1ExinvoicesQueueIdGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ExinvoicesQueueIdGet.');
            }
            const localVarPath = `/v1/exinvoices/queue/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExInvoicesApi - functional programming interface
 * @export
 */
export const ExInvoicesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Eski belgelerinizi toplu olarak dışarı aktarmak isterseniz bu ucu kullanabilirsiniz.            **Limit** : Tek bir istek içerisinde maks **100** kayıt dışarı aktarılabilir.    **fileType** Parametre detayları    > **Xml** : Belgenizin XML halini dışarı aktarır    > **Pdf** : Belgenize ait PDF görüntüsünü dışarı aktarır    > **UnifiedPdf** : ***uuids*** parametresinde gönderilen belgeleri tek PDF dosyası halinde dışarı aktarır    > **Excel** : ***uuids*** parametresinde gönderilen belgeleri Excel\'e aktarır
         * @summary Dışarı Aktar
         * @param {ExInvoiceFileExportTypeParameter} fileType 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ExinvoicesIncomingExportFileTypePost(requestParameters: V1ExinvoicesIncomingExportFileTypePostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ExInvoicesApiAxiosParamCreator(configuration).v1ExinvoicesIncomingExportFileTypePost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Daha önce yüklenmiş olan eski belgelerinize bu uç ile ulaşabilirsiniz.
         * @summary Belgeleri listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} startDate Başlangıç tarihi
         * @param {string} endDate Bitiş tarihi
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {InvoiceProfileIdParameter} [profileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ExinvoicesIncomingGet(requestParameters: V1ExinvoicesIncomingGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExInvoicePaginationResult>> {
            const localVarAxiosArgs = await ExInvoicesApiAxiosParamCreator(configuration).v1ExinvoicesIncomingGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye ait HTML görüntüye bu uç ile ulaşabilirsiniz.
         * @summary Belgeyi görüntüler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ExinvoicesIncomingUuidHtmlGet(requestParameters: V1ExinvoicesIncomingUuidHtmlGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ExInvoicesApiAxiosParamCreator(configuration).v1ExinvoicesIncomingUuidHtmlGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Eski belgeye ait PDF dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary PDF İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ExinvoicesIncomingUuidPdfGet(requestParameters: V1ExinvoicesIncomingUuidPdfGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ExInvoicesApiAxiosParamCreator(configuration).v1ExinvoicesIncomingUuidPdfGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Eski belgeye ait XML dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary XML İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ExinvoicesIncomingUuidXmlGet(requestParameters: V1ExinvoicesIncomingUuidXmlGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ExInvoicesApiAxiosParamCreator(configuration).v1ExinvoicesIncomingUuidXmlGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Eski belgelerinizi toplu olarak dışarı aktarmak isterseniz bu ucu kullanabilirsiniz.            **Limit** : Tek bir istek içerisinde maks **100** kayıt dışarı aktarılabilir.    **fileType** Parametre detayları    > **Xml** : Belgenizin XML halini dışarı aktarır    > **Pdf** : Belgenize ait PDF görüntüsünü dışarı aktarır    > **UnifiedPdf** : ***uuids*** parametresinde gönderilen belgeleri tek PDF dosyası halinde dışarı aktarır    > **Excel** : ***uuids*** parametresinde gönderilen belgeleri Excel\'e aktarır
         * @summary Dışarı Aktar
         * @param {FileExportTypeParameter} fileType 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ExinvoicesOutgoingExportFileTypePost(requestParameters: V1ExinvoicesOutgoingExportFileTypePostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ExInvoicesApiAxiosParamCreator(configuration).v1ExinvoicesOutgoingExportFileTypePost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Daha önce yüklenmiş olan eski belgelerinize bu uç ile ulaşabilirsiniz.
         * @summary Belgeleri listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} startDate Başlangıç tarihi
         * @param {string} endDate Bitiş tarihi
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {InvoiceProfileIdParameter} [profileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ExinvoicesOutgoingGet(requestParameters: V1ExinvoicesOutgoingGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExInvoicePaginationResult>> {
            const localVarAxiosArgs = await ExInvoicesApiAxiosParamCreator(configuration).v1ExinvoicesOutgoingGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Belgeye ait HTML görüntüye bu uç ile ulaşabilirsiniz.
         * @summary Belgeyi görüntüler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ExinvoicesOutgoingUuidHtmlGet(requestParameters: V1ExinvoicesOutgoingUuidHtmlGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ExInvoicesApiAxiosParamCreator(configuration).v1ExinvoicesOutgoingUuidHtmlGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Eski belgeye ait PDF dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary PDF İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ExinvoicesOutgoingUuidPdfGet(requestParameters: V1ExinvoicesOutgoingUuidPdfGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ExInvoicesApiAxiosParamCreator(configuration).v1ExinvoicesOutgoingUuidPdfGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Eski belgeye ait XML dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary XML İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ExinvoicesOutgoingUuidXmlGet(requestParameters: V1ExinvoicesOutgoingUuidXmlGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ExInvoicesApiAxiosParamCreator(configuration).v1ExinvoicesOutgoingUuidXmlGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Eski belge yüklemek için bu uç kullanılabilir.            > Eski belgelerinizi **ZIP** dosyası içerisinde yüklemeniz gerekmektedir. İç içe klasörler, iç içe zipler sistem tarafından otomatik ayrıştırılarak yüklenecektir.     > Bundan dolayı bir ZIP içerisinde bir belge ***değil***, ZIP içerisinde yüzlerce belgeyi yükleyebilirsiniz.
         * @summary Eski belge yükler
         * @param {any} file Dosya
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ExinvoicesPost(requestParameters: V1ExinvoicesPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExInvoiceQueue>> {
            const localVarAxiosArgs = await ExInvoicesApiAxiosParamCreator(configuration).v1ExinvoicesPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Eski belgeleri sisteme yükledikten sonra, işlenme (Kayıt edilip edilmediği) durumuna bu uç ile ulaşabilirsiniz.
         * @summary Yükleme kuyruğunu listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} startDate Başlangıç tarihi
         * @param {string} endDate Bitiş tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ExinvoicesQueueGet(requestParameters: V1ExinvoicesQueueGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExInvoiceQueuePaginationResult>> {
            const localVarAxiosArgs = await ExInvoicesApiAxiosParamCreator(configuration).v1ExinvoicesQueueGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Eski belgeleri sisteme yükledikten sonra yükleme sonuç raporuna bu uç ile ulaşabilirsiniz.
         * @summary Yükleme sonucunu indir
         * @param {string} id **Yükleme id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ExinvoicesQueueIdGet(requestParameters: V1ExinvoicesQueueIdGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ExInvoicesApiAxiosParamCreator(configuration).v1ExinvoicesQueueIdGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ExInvoicesApi - factory interface
 * @export
 */
export const ExInvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Eski belgelerinizi toplu olarak dışarı aktarmak isterseniz bu ucu kullanabilirsiniz.            **Limit** : Tek bir istek içerisinde maks **100** kayıt dışarı aktarılabilir.    **fileType** Parametre detayları    > **Xml** : Belgenizin XML halini dışarı aktarır    > **Pdf** : Belgenize ait PDF görüntüsünü dışarı aktarır    > **UnifiedPdf** : ***uuids*** parametresinde gönderilen belgeleri tek PDF dosyası halinde dışarı aktarır    > **Excel** : ***uuids*** parametresinde gönderilen belgeleri Excel\'e aktarır
         * @summary Dışarı Aktar
         * @param {ExInvoiceFileExportTypeParameter} fileType 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExinvoicesIncomingExportFileTypePost(requestParameters: V1ExinvoicesIncomingExportFileTypePostRequest, options?: any): AxiosPromise<void> {
            return ExInvoicesApiFp(configuration).v1ExinvoicesIncomingExportFileTypePost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Daha önce yüklenmiş olan eski belgelerinize bu uç ile ulaşabilirsiniz.
         * @summary Belgeleri listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} startDate Başlangıç tarihi
         * @param {string} endDate Bitiş tarihi
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {InvoiceProfileIdParameter} [profileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExinvoicesIncomingGet(requestParameters: V1ExinvoicesIncomingGetRequest, options?: any): AxiosPromise<ExInvoicePaginationResult> {
            return ExInvoicesApiFp(configuration).v1ExinvoicesIncomingGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye ait HTML görüntüye bu uç ile ulaşabilirsiniz.
         * @summary Belgeyi görüntüler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExinvoicesIncomingUuidHtmlGet(requestParameters: V1ExinvoicesIncomingUuidHtmlGetRequest, options?: any): AxiosPromise<void> {
            return ExInvoicesApiFp(configuration).v1ExinvoicesIncomingUuidHtmlGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Eski belgeye ait PDF dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary PDF İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExinvoicesIncomingUuidPdfGet(requestParameters: V1ExinvoicesIncomingUuidPdfGetRequest, options?: any): AxiosPromise<void> {
            return ExInvoicesApiFp(configuration).v1ExinvoicesIncomingUuidPdfGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Eski belgeye ait XML dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary XML İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExinvoicesIncomingUuidXmlGet(requestParameters: V1ExinvoicesIncomingUuidXmlGetRequest, options?: any): AxiosPromise<void> {
            return ExInvoicesApiFp(configuration).v1ExinvoicesIncomingUuidXmlGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Eski belgelerinizi toplu olarak dışarı aktarmak isterseniz bu ucu kullanabilirsiniz.            **Limit** : Tek bir istek içerisinde maks **100** kayıt dışarı aktarılabilir.    **fileType** Parametre detayları    > **Xml** : Belgenizin XML halini dışarı aktarır    > **Pdf** : Belgenize ait PDF görüntüsünü dışarı aktarır    > **UnifiedPdf** : ***uuids*** parametresinde gönderilen belgeleri tek PDF dosyası halinde dışarı aktarır    > **Excel** : ***uuids*** parametresinde gönderilen belgeleri Excel\'e aktarır
         * @summary Dışarı Aktar
         * @param {FileExportTypeParameter} fileType 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExinvoicesOutgoingExportFileTypePost(requestParameters: V1ExinvoicesOutgoingExportFileTypePostRequest, options?: any): AxiosPromise<void> {
            return ExInvoicesApiFp(configuration).v1ExinvoicesOutgoingExportFileTypePost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Daha önce yüklenmiş olan eski belgelerinize bu uç ile ulaşabilirsiniz.
         * @summary Belgeleri listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} startDate Başlangıç tarihi
         * @param {string} endDate Bitiş tarihi
         * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
         * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
         * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
         * @param {InvoiceProfileIdParameter} [profileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExinvoicesOutgoingGet(requestParameters: V1ExinvoicesOutgoingGetRequest, options?: any): AxiosPromise<ExInvoicePaginationResult> {
            return ExInvoicesApiFp(configuration).v1ExinvoicesOutgoingGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Belgeye ait HTML görüntüye bu uç ile ulaşabilirsiniz.
         * @summary Belgeyi görüntüler
         * @param {string} uuid **Belge uuid**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExinvoicesOutgoingUuidHtmlGet(requestParameters: V1ExinvoicesOutgoingUuidHtmlGetRequest, options?: any): AxiosPromise<void> {
            return ExInvoicesApiFp(configuration).v1ExinvoicesOutgoingUuidHtmlGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Eski belgeye ait PDF dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary PDF İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExinvoicesOutgoingUuidPdfGet(requestParameters: V1ExinvoicesOutgoingUuidPdfGetRequest, options?: any): AxiosPromise<void> {
            return ExInvoicesApiFp(configuration).v1ExinvoicesOutgoingUuidPdfGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Eski belgeye ait XML dosyasını indirmek için bu ucu kullanabilirsiniz.
         * @summary XML İndir
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExinvoicesOutgoingUuidXmlGet(requestParameters: V1ExinvoicesOutgoingUuidXmlGetRequest, options?: any): AxiosPromise<void> {
            return ExInvoicesApiFp(configuration).v1ExinvoicesOutgoingUuidXmlGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Eski belge yüklemek için bu uç kullanılabilir.            > Eski belgelerinizi **ZIP** dosyası içerisinde yüklemeniz gerekmektedir. İç içe klasörler, iç içe zipler sistem tarafından otomatik ayrıştırılarak yüklenecektir.     > Bundan dolayı bir ZIP içerisinde bir belge ***değil***, ZIP içerisinde yüzlerce belgeyi yükleyebilirsiniz.
         * @summary Eski belge yükler
         * @param {any} file Dosya
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExinvoicesPost(requestParameters: V1ExinvoicesPostRequest, options?: any): AxiosPromise<ExInvoiceQueue> {
            return ExInvoicesApiFp(configuration).v1ExinvoicesPost(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Eski belgeleri sisteme yükledikten sonra, işlenme (Kayıt edilip edilmediği) durumuna bu uç ile ulaşabilirsiniz.
         * @summary Yükleme kuyruğunu listeler
         * @param {string} sort Sıralama
         * @param {number} pageSize Sayfadaki kayıt sayısı
         * @param {number} page Görüntülenecek sayfa
         * @param {string} startDate Başlangıç tarihi
         * @param {string} endDate Bitiş tarihi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExinvoicesQueueGet(requestParameters: V1ExinvoicesQueueGetRequest, options?: any): AxiosPromise<ExInvoiceQueuePaginationResult> {
            return ExInvoicesApiFp(configuration).v1ExinvoicesQueueGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Eski belgeleri sisteme yükledikten sonra yükleme sonuç raporuna bu uç ile ulaşabilirsiniz.
         * @summary Yükleme sonucunu indir
         * @param {string} id **Yükleme id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExinvoicesQueueIdGet(requestParameters: V1ExinvoicesQueueIdGetRequest, options?: any): AxiosPromise<void> {
            return ExInvoicesApiFp(configuration).v1ExinvoicesQueueIdGet(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExInvoicesApi - object-oriented interface
 * @export
 * @class ExInvoicesApi
 * @extends {BaseAPI}
 */
export class ExInvoicesApi extends BaseAPI {
    /**
     * Eski belgelerinizi toplu olarak dışarı aktarmak isterseniz bu ucu kullanabilirsiniz.            **Limit** : Tek bir istek içerisinde maks **100** kayıt dışarı aktarılabilir.    **fileType** Parametre detayları    > **Xml** : Belgenizin XML halini dışarı aktarır    > **Pdf** : Belgenize ait PDF görüntüsünü dışarı aktarır    > **UnifiedPdf** : ***uuids*** parametresinde gönderilen belgeleri tek PDF dosyası halinde dışarı aktarır    > **Excel** : ***uuids*** parametresinde gönderilen belgeleri Excel\'e aktarır
     * @summary Dışarı Aktar
     * @param {ExInvoiceFileExportTypeParameter} fileType 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExInvoicesApi
     */
    public v1ExinvoicesIncomingExportFileTypePost(requestParameters: V1ExinvoicesIncomingExportFileTypePostRequest, options?: any) {
        return ExInvoicesApiFp(this.configuration).v1ExinvoicesIncomingExportFileTypePost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Daha önce yüklenmiş olan eski belgelerinize bu uç ile ulaşabilirsiniz.
     * @summary Belgeleri listeler
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {string} startDate Başlangıç tarihi
     * @param {string} endDate Bitiş tarihi
     * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
     * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
     * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
     * @param {InvoiceProfileIdParameter} [profileId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExInvoicesApi
     */
    public v1ExinvoicesIncomingGet(requestParameters: V1ExinvoicesIncomingGetRequest, options?: any) {
        return ExInvoicesApiFp(this.configuration).v1ExinvoicesIncomingGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye ait HTML görüntüye bu uç ile ulaşabilirsiniz.
     * @summary Belgeyi görüntüler
     * @param {string} uuid **Belge uuid**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExInvoicesApi
     */
    public v1ExinvoicesIncomingUuidHtmlGet(requestParameters: V1ExinvoicesIncomingUuidHtmlGetRequest, options?: any) {
        return ExInvoicesApiFp(this.configuration).v1ExinvoicesIncomingUuidHtmlGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Eski belgeye ait PDF dosyasını indirmek için bu ucu kullanabilirsiniz.
     * @summary PDF İndir
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExInvoicesApi
     */
    public v1ExinvoicesIncomingUuidPdfGet(requestParameters: V1ExinvoicesIncomingUuidPdfGetRequest, options?: any) {
        return ExInvoicesApiFp(this.configuration).v1ExinvoicesIncomingUuidPdfGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Eski belgeye ait XML dosyasını indirmek için bu ucu kullanabilirsiniz.
     * @summary XML İndir
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExInvoicesApi
     */
    public v1ExinvoicesIncomingUuidXmlGet(requestParameters: V1ExinvoicesIncomingUuidXmlGetRequest, options?: any) {
        return ExInvoicesApiFp(this.configuration).v1ExinvoicesIncomingUuidXmlGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Eski belgelerinizi toplu olarak dışarı aktarmak isterseniz bu ucu kullanabilirsiniz.            **Limit** : Tek bir istek içerisinde maks **100** kayıt dışarı aktarılabilir.    **fileType** Parametre detayları    > **Xml** : Belgenizin XML halini dışarı aktarır    > **Pdf** : Belgenize ait PDF görüntüsünü dışarı aktarır    > **UnifiedPdf** : ***uuids*** parametresinde gönderilen belgeleri tek PDF dosyası halinde dışarı aktarır    > **Excel** : ***uuids*** parametresinde gönderilen belgeleri Excel\'e aktarır
     * @summary Dışarı Aktar
     * @param {FileExportTypeParameter} fileType 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExInvoicesApi
     */
    public v1ExinvoicesOutgoingExportFileTypePost(requestParameters: V1ExinvoicesOutgoingExportFileTypePostRequest, options?: any) {
        return ExInvoicesApiFp(this.configuration).v1ExinvoicesOutgoingExportFileTypePost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Daha önce yüklenmiş olan eski belgelerinize bu uç ile ulaşabilirsiniz.
     * @summary Belgeleri listeler
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {string} startDate Başlangıç tarihi
     * @param {string} endDate Bitiş tarihi
     * @param {string} [company] Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
     * @param {string} [uuid] UUID numarasına göre filtreleme yapabilirsiniz
     * @param {string} [documentNumber] Belge numarasına göre filtreleme yapabilirsiniz
     * @param {InvoiceProfileIdParameter} [profileId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExInvoicesApi
     */
    public v1ExinvoicesOutgoingGet(requestParameters: V1ExinvoicesOutgoingGetRequest, options?: any) {
        return ExInvoicesApiFp(this.configuration).v1ExinvoicesOutgoingGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Belgeye ait HTML görüntüye bu uç ile ulaşabilirsiniz.
     * @summary Belgeyi görüntüler
     * @param {string} uuid **Belge uuid**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExInvoicesApi
     */
    public v1ExinvoicesOutgoingUuidHtmlGet(requestParameters: V1ExinvoicesOutgoingUuidHtmlGetRequest, options?: any) {
        return ExInvoicesApiFp(this.configuration).v1ExinvoicesOutgoingUuidHtmlGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Eski belgeye ait PDF dosyasını indirmek için bu ucu kullanabilirsiniz.
     * @summary PDF İndir
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExInvoicesApi
     */
    public v1ExinvoicesOutgoingUuidPdfGet(requestParameters: V1ExinvoicesOutgoingUuidPdfGetRequest, options?: any) {
        return ExInvoicesApiFp(this.configuration).v1ExinvoicesOutgoingUuidPdfGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Eski belgeye ait XML dosyasını indirmek için bu ucu kullanabilirsiniz.
     * @summary XML İndir
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExInvoicesApi
     */
    public v1ExinvoicesOutgoingUuidXmlGet(requestParameters: V1ExinvoicesOutgoingUuidXmlGetRequest, options?: any) {
        return ExInvoicesApiFp(this.configuration).v1ExinvoicesOutgoingUuidXmlGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Eski belge yüklemek için bu uç kullanılabilir.            > Eski belgelerinizi **ZIP** dosyası içerisinde yüklemeniz gerekmektedir. İç içe klasörler, iç içe zipler sistem tarafından otomatik ayrıştırılarak yüklenecektir.     > Bundan dolayı bir ZIP içerisinde bir belge ***değil***, ZIP içerisinde yüzlerce belgeyi yükleyebilirsiniz.
     * @summary Eski belge yükler
     * @param {any} file Dosya
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExInvoicesApi
     */
    public v1ExinvoicesPost(requestParameters: V1ExinvoicesPostRequest, options?: any) {
        return ExInvoicesApiFp(this.configuration).v1ExinvoicesPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Eski belgeleri sisteme yükledikten sonra, işlenme (Kayıt edilip edilmediği) durumuna bu uç ile ulaşabilirsiniz.
     * @summary Yükleme kuyruğunu listeler
     * @param {string} sort Sıralama
     * @param {number} pageSize Sayfadaki kayıt sayısı
     * @param {number} page Görüntülenecek sayfa
     * @param {string} startDate Başlangıç tarihi
     * @param {string} endDate Bitiş tarihi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExInvoicesApi
     */
    public v1ExinvoicesQueueGet(requestParameters: V1ExinvoicesQueueGetRequest, options?: any) {
        return ExInvoicesApiFp(this.configuration).v1ExinvoicesQueueGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Eski belgeleri sisteme yükledikten sonra yükleme sonuç raporuna bu uç ile ulaşabilirsiniz.
     * @summary Yükleme sonucunu indir
     * @param {string} id **Yükleme id**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExInvoicesApi
     */
    public v1ExinvoicesQueueIdGet(requestParameters: V1ExinvoicesQueueIdGetRequest, options?: any) {
        return ExInvoicesApiFp(this.configuration).v1ExinvoicesQueueIdGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1ExinvoicesIncomingExportFileTypePostRequest {
    /**
     * 
     */
    fileType: ExInvoiceFileExportTypeParameter;
    /**
     * 
     */
    requestBody: Array<string>;
    }
    export interface V1ExinvoicesIncomingGetRequest {
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    /**
     * Başlangıç tarihi
     */
    startDate: string;
    /**
     * Bitiş tarihi
     */
    endDate: string;
    /**
     * Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
     */
    company?: string;
    /**
     * UUID numarasına göre filtreleme yapabilirsiniz
     */
    uuid?: string;
    /**
     * Belge numarasına göre filtreleme yapabilirsiniz
     */
    documentNumber?: string;
    /**
     * 
     */
    profileId?: InvoiceProfileIdParameter;
    }
    export interface V1ExinvoicesIncomingUuidHtmlGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    }
    export interface V1ExinvoicesIncomingUuidPdfGetRequest {
    /**
     * 
     */
    uuid: string;
    }
    export interface V1ExinvoicesIncomingUuidXmlGetRequest {
    /**
     * 
     */
    uuid: string;
    }
    export interface V1ExinvoicesOutgoingExportFileTypePostRequest {
    /**
     * 
     */
    fileType: FileExportTypeParameter;
    /**
     * 
     */
    requestBody: Array<string>;
    }
    export interface V1ExinvoicesOutgoingGetRequest {
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    /**
     * Başlangıç tarihi
     */
    startDate: string;
    /**
     * Bitiş tarihi
     */
    endDate: string;
    /**
     * Firma Ünvanı veya VKN/TNCK değerine göre filtreleme yapabilirsiniz
     */
    company?: string;
    /**
     * UUID numarasına göre filtreleme yapabilirsiniz
     */
    uuid?: string;
    /**
     * Belge numarasına göre filtreleme yapabilirsiniz
     */
    documentNumber?: string;
    /**
     * 
     */
    profileId?: InvoiceProfileIdParameter;
    }
    export interface V1ExinvoicesOutgoingUuidHtmlGetRequest {
    /**
     * **Belge uuid**
     */
    uuid: string;
    }
    export interface V1ExinvoicesOutgoingUuidPdfGetRequest {
    /**
     * 
     */
    uuid: string;
    }
    export interface V1ExinvoicesOutgoingUuidXmlGetRequest {
    /**
     * 
     */
    uuid: string;
    }
    export interface V1ExinvoicesPostRequest {
    /**
     * Dosya
     */
    file: any;
    }
    export interface V1ExinvoicesQueueGetRequest {
    /**
     * Sıralama
     */
    sort: string;
    /**
     * Sayfadaki kayıt sayısı
     */
    pageSize: number;
    /**
     * Görüntülenecek sayfa
     */
    page: number;
    /**
     * Başlangıç tarihi
     */
    startDate: string;
    /**
     * Bitiş tarihi
     */
    endDate: string;
    }
    export interface V1ExinvoicesQueueIdGetRequest {
    /**
     * **Yükleme id**
     */
    id: string;
    }
