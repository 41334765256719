/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLAttribute, XMLText } from '@/xmldom-decorators';

/*
  27.04.2021 : Fully completed
*/
export class CodeType {
  @XMLAttribute()
  listID?: string;

  @XMLAttribute()
  listAgencyID?: string;

  @XMLAttribute()
  listAgencyName?: string;

  @XMLAttribute()
  listName?: string;

  @XMLAttribute()
  listVersionID?: string;

  @XMLAttribute()
  name?: string;

  @XMLAttribute()
  languageID?: string;

  @XMLAttribute()
  listURI?: string;

  @XMLAttribute()
  listSchemeURI?: string;

  @XMLText()
  value?: string;
}

export default CodeType;
