declare global {
  interface Date {
    firstDate(): Date;
    lastDate(): Date;
  }
}

Date.prototype.firstDate = function () {
  const y = this.getFullYear(),
    m = this.getMonth();
  return new Date(y, m, 1);
};

Date.prototype.lastDate = function () {
  const y = this.getFullYear(),
    m = this.getMonth();
  return new Date(y, m + 1, 0);
};

export {};
