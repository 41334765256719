import { ApiGenericResponse } from '@/interfaces';
import { AnyType } from '@/type';
import { AxiosPromise } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

export const useGetPageData = <T>(asyncFunction, params, immediate = true, successCallback?: (data) => void) => {
  const counter = useRef(0);
  const [value, setValue] = useState<ApiGenericResponse<T>>({
    loading: immediate
  });

  const execute = useCallback(() => {
    counter.current++;
    setValue({ ...value, loading: true });
    return (asyncFunction(params) as AxiosPromise<T>)
      .then((response: AnyType) => {
        if (response.isAxiosError) {
          setValue({ ...value, loading: false, data: undefined, error: response });
        } else {
          setValue({ ...value, loading: false, data: response.data as T });
          successCallback && successCallback(response.data);
        }
      })
      .catch((error) => {
        setValue({ ...value, loading: false, data: undefined, error: error });
      });
  }, [asyncFunction, params, value]);

  const setData = (data: T) => {
    setValue({ ...value, data, loading: false, error: undefined });
  };

  useEffect(() => {
    if (immediate && counter.current === 0) {
      execute();
    }
  }, [execute, immediate]);

  return { ...value, execute, setData };
};

export default useGetPageData;
