import {
  AddDocRefConditionField,
  AddDocRefValueField,
  BarcodeInformation,
  DocumentTemplateCustomizationSettingDto,
  TemplateAdditionalValueFieldType
} from '@/apis/einvoice/interfaces';
import { BooleanComponent } from '@/components';
import { Cancel, Delete, Edit, New, Save, Search } from '@/components/Buttons';
import { PageConst } from '@/constants/page';
import { XML_ESCAPE_CHARS } from '@/constants/regex';
import { barcodeXsltText } from '@/example';
import { EditedState } from '@/interfaces';
import { Invoice } from '@/models/xmlDocuments/invoice';
import { faEquals } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Col, Collapse, Row, Table } from 'antd';
import { Formik, FormikProps } from 'formik';
import { Checkbox, Form, FormItem, Input, Select, Slider } from 'formik-antd';
import { debounce } from 'lodash';
import React, { useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { removeItem, replaceItem } from 'redux-toolbelt-immutable-helpers';
import XML from 'xml.one';
import * as Yup from 'yup';
import { BarcodesModalProps } from '.';
import PreviewModal from './PreviewModal';

const { Option } = Select;
const { Panel } = Collapse;

const AddDocRefTab = ({
  documentTemplateCustomizationSetting,
  onChange
}: {
  documentTemplateCustomizationSetting?: DocumentTemplateCustomizationSettingDto;
  onChange: (data: BarcodeInformation[]) => void;
}) => {
  const createdInvoice = new Invoice().createDummyInvoice();

  const [edited, setEdited] = useState<EditedState>({ isEditing: false });

  let form = (useRef(null) as unknown) as FormikProps<BarcodeInformation>;

  const validatiorAddDocRefScheme = Yup.object().shape<BarcodeInformation>({
    footer: Yup.string().notRequired().max(64).matches(XML_ESCAPE_CHARS),
    header: Yup.string().notRequired().max(64).matches(XML_ESCAPE_CHARS),
    height: Yup.number().required().max(300).min(50),
    width: Yup.number().required().max(20).min(10),
    conditionValue: Yup.string().nullable().required().max(30).min(2).matches(XML_ESCAPE_CHARS),
    valueField: Yup.mixed<AddDocRefValueField>().oneOf(Object.values(AddDocRefValueField)).required(),
    conditionField: Yup.mixed<AddDocRefConditionField>().oneOf(Object.values(AddDocRefConditionField)).required(),
    hideBarcodeValueOnFooter: Yup.boolean().nullable().required(),
    templateAdditionalValueFieldType: Yup.mixed<TemplateAdditionalValueFieldType>().oneOf(Object.values(TemplateAdditionalValueFieldType))
  });

  const onDelete = (record: BarcodeInformation) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const index = documentTemplateCustomizationSetting!.barcodes!.indexOf(record);

    setEdited({ isEditing: false, index: undefined });

    form.resetForm();

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    onChange(removeItem(documentTemplateCustomizationSetting!.barcodes || [], index));
  };

  const onEdit = (record: BarcodeInformation) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const index = documentTemplateCustomizationSetting!.barcodes!.indexOf(record);
    setEdited({ isEditing: true, index: index });
    form.setValues(record);
  };

  const [newModalStateNotes, setNewModalStateNotes] = useState<BarcodesModalProps>({
    visible: false,
    barcodeInformation: undefined
  });

  const [transform, setTransform] = useState<any>();

  const updatePreview = debounce((values) => {
    if (values) {
      setTransform(
        XML.transform(
          createdInvoice.serialize({
            ...createdInvoice,
            additionalDocumentReference: [
              {
                documentTypeCode: 'BARCODE',
                id: { value: '8690632876' }
              }
            ]
          }),
          barcodeXsltText
            .replace('<!--HEADER_CONTENT-->', `<div style="display: block;  width:100%;"><b>${values!.header}</b></div>`)
            .replace('<!--FOOTER_CONTENT-->', `<div style="display: block;  width:100%;"><b>${values!.footer}</b></div>`)
            .replace('BARCODE_WIDTH', `${(values!.width / 1000).toFixed(4)}in`)
            .replace('PRINT_TEXT', values!.hideBarcodeValueOnFooter ? 'false' : 'true')
            .replace('BARCODE_HEIGHT', `${(values!.height / 108).toFixed(2)}in`)
        )
      );
    }
  }, 250);

  return (
    <>
      <Row gutter={PageConst.RowGutter}>
        <Col sm={24}>
          <Alert
            style={{ marginBottom: 10 }}
            description={
              <div>
                <br />
                Örn: <b>cbc:DocumentTypeCode = &apos;KARGO_TAKIP_NO&apos;</b> şartına uygun bir veri olması durumunda seçtiğiniz alandaki (ID, IssueDate) veri
                barkod olarak oluşturulur.
              </div>
            }
            message={
              <div>
                Ek Belgeler (<b>AdditionalDocumentReference</b>) alanında; belirtilen şarta uygun bir değer varsa bu değerin barkod olarak oluşturulması
                sağlanır.
              </div>
            }
            type="info"
            closable
          />
        </Col>
      </Row>

      <PreviewModal closeModal={() => setNewModalStateNotes({ visible: false, barcodeInformation: undefined })} modalState={newModalStateNotes} />

      <Formik<BarcodeInformation>
        validateOnBlur={false}
        innerRef={(instance) => (form = instance)}
        onSubmit={(values, { resetForm }) => {
          if (form.isValid) {
            if (edited.isEditing) {
              onChange(replaceItem(documentTemplateCustomizationSetting?.barcodes || [], edited.index!, values));
              setEdited({ isEditing: false, index: undefined });
              resetForm();
            } else {
              onChange([...(documentTemplateCustomizationSetting?.barcodes || []), { ...values }]);
              resetForm();
            }
          }
        }}
        validationSchema={validatiorAddDocRefScheme}
        initialValues={{
          footer: '',
          header: '',
          width: 15,
          height: 150,
          conditionValue: '',
          valueField: AddDocRefValueField.ID,
          conditionField: AddDocRefConditionField.DocumentType,
          hideBarcodeValueOnFooter: false,
          templateAdditionalValueFieldType: TemplateAdditionalValueFieldType.AdditionalDocumentReference
        }}
      >
        {({ values }) => {
          updatePreview(values);

          return (
            <Form layout="vertical" className="form-in-tab">
              <Row gutter={[PageConst.RowGutter, PageConst.ColGutter]}>
                <Col sm={6}>
                  <FormItem name="header" label={intl.get('TemplateSettings.Header')}>
                    <Input name="header" placeholder={'Header'} tabIndex={0} />
                  </FormItem>
                </Col>
                <Col sm={6}>
                  <FormItem name="footer" label={intl.get('TemplateSettings.Footer')}>
                    <Input name="footer" placeholder={'Footer'} tabIndex={0} />
                  </FormItem>
                </Col>

                <Col sm={4}>
                  <FormItem name="conditionField" label={intl.get('TemplateSettings.ConditionField')} required>
                    <Select name="conditionField" defaultValue={AddDocRefConditionField.DocumentType}>
                      <Option value={AddDocRefConditionField.DocumentType}>{AddDocRefConditionField.DocumentType.toString()}</Option>
                      <Option value={AddDocRefConditionField.DocumentTypeCode}>{AddDocRefConditionField.DocumentTypeCode.toString()}</Option>
                    </Select>
                  </FormItem>
                </Col>
                <Col sm={4}>
                  <FormItem name="conditionValue" label={'Koşul (* Eşittir)'} required>
                    <Input name="conditionValue" placeholder={'KARGO_TAKIP_NO'} tabIndex={0} />
                  </FormItem>
                </Col>
                <Col sm={4}>
                  <FormItem name="valueField" label={'Gösterilecek Değer'} required>
                    <Select name="valueField" defaultValue={AddDocRefValueField.ID}>
                      <Option value={AddDocRefValueField.ID}>{AddDocRefValueField.ID.toString()}</Option>
                      <Option value={AddDocRefValueField.IssueDate}>{AddDocRefValueField.IssueDate.toString()}</Option>
                    </Select>
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={PageConst.RowGutter}>
                <Col sm={6}>
                  <FormItem name="height" label={intl.get('TemplateSettings.BarcodeHeight')} required>
                    <Slider min={75} max={150} name="height" placeholder={'Height'} />
                  </FormItem>
                </Col>
                <Col sm={6}>
                  <FormItem name="width" label={intl.get('TemplateSettings.BarcodeWidth')} required>
                    <Slider min={10} max={20} name="width" placeholder={'Width'} />
                  </FormItem>
                </Col>

                <Col sm={6}>
                  <FormItem name="hideBarcodeValueOnFooter" label={intl.get('TemplateSettings.BarcodeValue')} valuePropName="checked" required>
                    <Checkbox name="hideBarcodeValueOnFooter">Barkodu Değeri Görünmesin?</Checkbox>
                  </FormItem>
                </Col>

                <Col sm={6} style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                  <div>
                    {edited.isEditing ? (
                      <>
                        <Save color="primary" title="Güncelle" onClick={() => form.handleSubmit()} />{' '}
                        <Cancel
                          color="danger"
                          title="Vazgeç"
                          onClick={() => {
                            form.resetForm();
                            setEdited({ isEditing: false, index: undefined });
                          }}
                        />
                      </>
                    ) : (
                      <New title="Ekle" onClick={() => form.handleSubmit()} />
                    )}
                  </div>
                </Col>

                <Collapse defaultActiveKey={[]} style={{ width: '100%', marginBottom: '7.5px' }}>
                  <Panel header="Önizleme" key="1">
                    {transform && transform.documentElement && (
                      <iframe
                        title={'Xlsts Önizleme'}
                        id="xsltsPreview"
                        src="#"
                        srcDoc={transform.documentElement.outerHTML}
                        width={PageConst.LargeModalWidth}
                        height="100%"
                        frameBorder="0"
                        style={{ width: '100%', minHeight: 360, paddingLeft: 10, border: '1px solid #e4e9f0', borderRadius: '5px' }}
                      />
                    )}
                  </Panel>
                </Collapse>
              </Row>
            </Form>
          );
        }}
      </Formik>

      <Row gutter={PageConst.RowGutter}>
        <Col sm={24}>
          <Table<BarcodeInformation>
            bordered
            className="mt-2"
            pagination={false}
            dataSource={
              (documentTemplateCustomizationSetting &&
                documentTemplateCustomizationSetting.barcodes &&
                documentTemplateCustomizationSetting.barcodes.filter(
                  (x) => x.templateAdditionalValueFieldType === TemplateAdditionalValueFieldType.AdditionalDocumentReference
                )) ||
              []
            }
            columns={[
              {
                title: 'Üst Bilgi',
                dataIndex: 'header',
                key: 'header'
              },

              {
                title: 'Alt Bilgi',
                dataIndex: 'footer',
                key: 'footer'
              },
              {
                title: 'Boyut',
                dataIndex: 'height',
                key: 'height',
                width: 110,
                render: (e, record) => (
                  <div>
                    <div>
                      <b>Yükseklik : </b>
                      {record.height}
                    </div>
                    <div>
                      <b>Genişlik : </b>
                      {record.width}
                    </div>
                  </div>
                )
              },
              {
                title: 'Gösterilecek Değer',
                dataIndex: 'valueField',
                key: 'valueField',
                width: 130,
                render: (value) => `cbc:${value}`
              },
              {
                title: 'Koşul',
                dataIndex: 'conditionField',
                key: 'conditionField',
                render: (value, record) => {
                  return (
                    <div>
                      {`cbc:${record.conditionField}`} <FontAwesomeIcon icon={faEquals} className="ml-2 mr-2" /> {`'${record.conditionValue}'`}
                    </div>
                  );
                }
              },

              {
                key: `hideBarcodeValueOnFooter`,
                title: 'Barkod Değerini Gizle?',
                width: 150,
                render: (record) => {
                  return <BooleanComponent value={record.hideBarcodeValueOnFooter} />;
                }
              },
              {
                width: 150,
                align: 'center',
                key: 'proseses',
                title: intl.get('ISLEMLER'),
                render: (text, record) => {
                  return (
                    <div>
                      <Search onlyIcon onClick={() => setNewModalStateNotes({ visible: true, barcodeInformation: record })} /> |{' '}
                      <Edit onlyIcon onClick={() => onEdit(record)} /> <Delete onlyIcon onClick={() => onDelete(record)} />
                    </div>
                  );
                }
              }
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

export default AddDocRefTab;
