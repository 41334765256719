import { ButtonProps } from '@/interfaces';
import { faFilter } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import classnames from 'classnames';
import React from 'react';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';

export const DetailFilter = ({ disabled, onClick, size = 'sm', onlyIcon }: ButtonProps) => {
  return (
    <Tooltip title={intl.get('DETAYLI_FILTRE')} trigger="hover" placement="bottom">
      <Button disabled={disabled} size={size} key="btn-close" onClick={onClick} color={'danger'} className="btn-labeled">
        <FontAwesomeIcon icon={faFilter} className={classnames({ 'btn-label': !onlyIcon })} />
        {!onlyIcon && <span>{intl.get('DETAYLI_FILTRE')}</span>}
      </Button>
    </Tooltip>
  );
};

export default DetailFilter;
