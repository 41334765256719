import createStore from '@/helpers/createStore';
import { Menu } from '@/interfaces';
import { AnyType } from '@/type';
import { Reducer } from 'react';
type Action = { type: 'CHANGE'; key: string; value: AnyType };
type State = {
  isGrayBackground: boolean;
  isContentNoMaxWidth: boolean;
  isAppMaxWidth: boolean;
  isSquaredBorders: boolean;
  isCardShadow: boolean;
  isBorderless: boolean;
  isSidebarOpen: boolean;
  isGrayTopbar: boolean;
  isTopbarFixed: boolean;
  isFooterDark: boolean;
  isMobileView: boolean;
  isMenuCollapsed: boolean;
  isMobileMenuOpen: boolean;
  isMenuShadow: boolean;
  isMenuUnfixed: boolean;
  systemLayoutColor: 'white' | 'gray' | 'blue' | 'dark' | 'image';
  flyoutMenuColor: 'white' | 'gray' | 'blue' | 'dark';
  menuColor: 'white' | 'gray' | 'blue' | 'dark';
  menuLayoutType: string;
  locale: 'tr-TR' | 'en-US';
  menuType: 'default' | 'flyout' | 'compact';
  routerAnimation: 'none' | 'slide-fadein-up' | 'slide-fadein-right' | 'fadein' | 'zoom-fadein';
  menuData: Menu[];
  userSearchVisible: boolean;
  envelopeSearchVisible: boolean;
  theme: 'default' | 'dark';
  primaryColor: string;
  defaultColor: '#1b55e3';
};

const initialState: State = {
  isAppMaxWidth: false,
  isGrayBackground: true,
  isGrayTopbar: true,
  isBorderless: false,
  isSidebarOpen: false,
  isMenuUnfixed: false,
  isMenuCollapsed: false,
  isMobileMenuOpen: false,
  isMobileView: false,
  isFooterDark: false,
  isMenuShadow: true,
  isCardShadow: true,
  isSquaredBorders: false,
  isContentNoMaxWidth: true,
  isTopbarFixed: true,
  menuColor: (localStorage.getItem('apps.menuColor') as AnyType) || 'dark',
  flyoutMenuColor: (localStorage.getItem('apps.flyoutMenuColor') as AnyType) || 'blue',
  systemLayoutColor: 'dark',
  menuLayoutType: 'left',
  locale: (localStorage.getItem('apps.locale') as AnyType) || 'tr-TR',
  menuType: 'default',
  routerAnimation: (localStorage.getItem('apps.routerAnimation') as AnyType) || 'slide-fadein-right',
  menuData: [],
  userSearchVisible: false,
  envelopeSearchVisible: false,
  theme: (localStorage.getItem('apps.theme') as AnyType) || 'default',
  defaultColor: '#1b55e3',
  primaryColor: localStorage.getItem('apps.primaryColor') || '#5597ec' //'#1b55e3'
};

const reducer: Reducer<State, Action> = (state: State, action) => {
  switch (action.type) {
    case 'CHANGE':
      action.key !== 'menuData' && localStorage.setItem(`apps.${action.key}`, action.value);
      return {
        ...state,
        [action.key]: action.value
      };
    default:
      return state;
  }
};
const { Context, Provider, Consumer } = createStore(reducer, initialState);

export { Provider as ThemeProvider, Consumer as ThemeContextConsumer };

export default Context;
