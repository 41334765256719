import { DocumentAnswer } from '@/apis/einvoice/interfaces';
import { faBan, faCheck, faExclamationTriangle, faHourglassStart } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import intl from 'react-intl-universal';
const InvoiceAnswer = ({ answer }: { answer: DocumentAnswer }) => {
  switch (answer) {
    case 'Accepted':
      return (
        <>
          <FontAwesomeIcon icon={faCheck} /> <span>{intl.get('KABUL_EDILDI')}</span>
        </>
      );
    case 'Rejected':
      return (
        <>
          <FontAwesomeIcon icon={faBan} /> <span>{intl.get('REDDEDILDI')}</span>
        </>
      );
    case 'Waiting':
      return (
        <>
          <FontAwesomeIcon icon={faHourglassStart} /> <span>{intl.get('ISLEM_BEKLENIYOR')}</span>
        </>
      );
    case 'None':
    default:
      return (
        <>
          <FontAwesomeIcon icon={faExclamationTriangle} /> <span>{intl.get('DURUM_BILINMIYOR')}</span>
        </>
      );
  }
};

export default InvoiceAnswer;
