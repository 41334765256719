import { AnyType } from '@/type';
import { Alert } from 'antd';
import React, { memo } from 'react';
import styles from './style.module.scss';
export const ConnectorErrorViewer = memo(({ error }: { error: AnyType }) => {
  const errors = error.response && error.response.data && error.response.data.errors;

  if (!Array.isArray(errors)) {
    debugger;

    return (
      <div>
        <p className={styles.error__description}>
          <>
            {Object.keys(errors).map((key, index) => {
              const error = errors[key];

              return (
                <Alert
                  key={`error-${index}`}
                  type="warning"
                  description={error.map((i) => i)}
                  message={key}
                  showIcon
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                />
              );
            })}
          </>
        </p>
      </div>
    );
  }

  return (
    <div>
      <p className={styles.error__description}>
        <>
          {errors.map(({ code, description, detail }, index) => {
            return (
              <Alert
                key={`error-${index}`}
                type="warning"
                description={detail ? `${description}:${detail}` : description}
                message={code}
                showIcon
                style={{ paddingLeft: 0, paddingRight: 0 }}
              />
            );
          })}
        </>
      </p>
    </div>
  );

  // return <Empty className="table__body__center" />;
});

export default ConnectorErrorViewer;
