import {
  V1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoGetRequest,
  V1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoPostRequest
} from '@/apis/einvoice';
import { InfoToastify, RootSpinner, SuccessToastify } from '@/components';
import { Delete, IconButton } from '@/components/Buttons';
import ImageCropper from '@/components/ImageCropper';
import { ErrorViewer } from '@/components/TableBody';
import { useApi, useApiInConfirmation } from '@/hooks';
import { dataURLtoFile } from '@/utils/file';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import { faCrop } from '@fortawesome/pro-regular-svg-icons';
import { Alert } from 'antd';
import { AxiosPromise } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { Card, CardBody } from 'reactstrap';

const CommonImageCropper = ({
  title,
  get,
  post,
  onRemoteImageChanged,
  deleteImage,
  id,
  croppedImageWidth,
  croppedImageHeight
}: {
  title: string;
  id: string;
  get: () => AxiosPromise<any>;
  deleteImage: () => void;
  post: (values: V1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoPostRequest) => AxiosPromise<void>;
  onRemoteImageChanged?: (process: 'post' | 'delete') => void;
  croppedImageWidth?: number;
  croppedImageHeight?: number;
}) => {
  const [fileList, setFileList] = useState<any[]>([]);

  const cropper = useRef<any>(null);

  const { data, call, loading } = useApi<Blob, V1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoGetRequest>({
    asyncFunction: get
  });

  const deleteRequest = useApiInConfirmation<{ id: string }>({
    asyncFunction: deleteImage
  });

  const postApi = useApi<any, V1DefinitionsDocumenttemplatesCustomizationsettingsIdLogoPostRequest>({
    asyncFunction: post,
    successCallback: () => {
      SuccessToastify(intl.getHTML('GORSEL_BASARI_ILE_EKLENDI', { title: title }));
      call({ id });
      cropper.current.clear();
      setFileList([]);
      onRemoteImageChanged && onRemoteImageChanged('post');
    },
    errorCallback: (error) => {
      <ErrorViewer error={error} />;
    }
  });

  useEffect(() => {
    call({ id });
  }, []);

  const addFile = () => {
    const base64 = cropper.current!.cropImage();

    const file = dataURLtoFile(base64, 'data.jpeg');

    postApi.call({
      file: file,
      id: id
    });
  };

  const loadingState = loading || postApi.loading;

  return (
    <RootSpinner loading={loadingState}>
      <Card className="mb-0">
        <CardBody style={{ padding: 15 }}>
          <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'end' }}>
            {fileList.length > 0 && <IconButton style={{ marginRight: '5px' }} icon={faSave} color="success" title={'Güncelle'} onClick={() => addFile()} />}
            <IconButton icon={faCrop} color="info" title="Dosya Seç" onClick={() => cropper.current!.selectImage()} />{' '}
          </div>
          <ImageCropper
            name="company-logo"
            ref={cropper}
            onChange={setFileList}
            hideCropedResult={true}
            croppedImageHeight={croppedImageHeight}
            croppedImageWidth={croppedImageHeight}
          />
          {data ? (
            <div>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                <img src={URL.createObjectURL(data)} style={{ border: '2px dashed #e5e5e5', padding: '10px', borderRadius: '5px' }} />
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                <Delete
                  title="Tasarımdan Kaldır"
                  onClick={() =>
                    deleteRequest.confirm(
                      { id: id },
                      {
                        content: 'İlgili görsel tasarımdan silinecektir. Devam edilsin mi?',
                        title: `Tasarımdan Kaldır`,
                        successCallback: () => {
                          cropper && cropper.current && cropper.current.clear();
                          setFileList([]);
                          call({ id });
                          InfoToastify(title + ' Tasarımdan kaldırıldı');
                          onRemoteImageChanged && onRemoteImageChanged('delete');
                        }
                      }
                    )
                  }
                />
              </div>
            </div>
          ) : (
            <Alert message={intl.getHTML('YUKLENMIS_BIR_ICERIK_BULUNMAMAKTADIR', { title: title })} style={{ marginTop: 10 }} type="warning" showIcon />
          )}
        </CardBody>
      </Card>
    </RootSpinner>
  );
};

export default CommonImageCropper;
