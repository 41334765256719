// tslint:disable
// eslint:disable
/**
 * General API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccountModuleAlias } from '../interfaces';
// @ts-ignore
import { AliasTypeParameter } from '../interfaces';
/**
 * AliasesApi - axios parameter creator
 * @export
 */
export const AliasesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Detay :
         * @summary Hesap etiketlerini getir
         * @param {AliasTypeParameter} aliasType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1EdespatchAliasesAliasTypeGet: async (requestParameters: V1EdespatchAliasesAliasTypeGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'aliasType' is not null or undefined
            if (requestParameters.aliasType === null || requestParameters.aliasType === undefined) {
                throw new RequiredError('aliasType','Required parameter aliasType was null or undefined when calling v1EdespatchAliasesAliasTypeGet.');
            }
            const localVarPath = `/v1/edespatch/aliases/{aliasType}`.replace(`{${"aliasType"}}`, encodeURIComponent(String(requestParameters.aliasType)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detay :
         * @summary Hesap etiketlerini getir
         * @param {AliasTypeParameter} aliasType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1EinvoiceAliasesAliasTypeGet: async (requestParameters: V1EinvoiceAliasesAliasTypeGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'aliasType' is not null or undefined
            if (requestParameters.aliasType === null || requestParameters.aliasType === undefined) {
                throw new RequiredError('aliasType','Required parameter aliasType was null or undefined when calling v1EinvoiceAliasesAliasTypeGet.');
            }
            const localVarPath = `/v1/einvoice/aliases/{aliasType}`.replace(`{${"aliasType"}}`, encodeURIComponent(String(requestParameters.aliasType)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AliasesApi - functional programming interface
 * @export
 */
export const AliasesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Detay :
         * @summary Hesap etiketlerini getir
         * @param {AliasTypeParameter} aliasType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1EdespatchAliasesAliasTypeGet(requestParameters: V1EdespatchAliasesAliasTypeGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountModuleAlias>>> {
            const localVarAxiosArgs = await AliasesApiAxiosParamCreator(configuration).v1EdespatchAliasesAliasTypeGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Detay :
         * @summary Hesap etiketlerini getir
         * @param {AliasTypeParameter} aliasType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1EinvoiceAliasesAliasTypeGet(requestParameters: V1EinvoiceAliasesAliasTypeGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountModuleAlias>>> {
            const localVarAxiosArgs = await AliasesApiAxiosParamCreator(configuration).v1EinvoiceAliasesAliasTypeGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AliasesApi - factory interface
 * @export
 */
export const AliasesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Detay :
         * @summary Hesap etiketlerini getir
         * @param {AliasTypeParameter} aliasType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1EdespatchAliasesAliasTypeGet(requestParameters: V1EdespatchAliasesAliasTypeGetRequest, options?: any): AxiosPromise<Array<AccountModuleAlias>> {
            return AliasesApiFp(configuration).v1EdespatchAliasesAliasTypeGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Detay :
         * @summary Hesap etiketlerini getir
         * @param {AliasTypeParameter} aliasType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1EinvoiceAliasesAliasTypeGet(requestParameters: V1EinvoiceAliasesAliasTypeGetRequest, options?: any): AxiosPromise<Array<AccountModuleAlias>> {
            return AliasesApiFp(configuration).v1EinvoiceAliasesAliasTypeGet(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AliasesApi - object-oriented interface
 * @export
 * @class AliasesApi
 * @extends {BaseAPI}
 */
export class AliasesApi extends BaseAPI {
    /**
     * Detay :
     * @summary Hesap etiketlerini getir
     * @param {AliasTypeParameter} aliasType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AliasesApi
     */
    public v1EdespatchAliasesAliasTypeGet(requestParameters: V1EdespatchAliasesAliasTypeGetRequest, options?: any) {
        return AliasesApiFp(this.configuration).v1EdespatchAliasesAliasTypeGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Detay :
     * @summary Hesap etiketlerini getir
     * @param {AliasTypeParameter} aliasType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AliasesApi
     */
    public v1EinvoiceAliasesAliasTypeGet(requestParameters: V1EinvoiceAliasesAliasTypeGetRequest, options?: any) {
        return AliasesApiFp(this.configuration).v1EinvoiceAliasesAliasTypeGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1EdespatchAliasesAliasTypeGetRequest {
    /**
     * 
     */
    aliasType: AliasTypeParameter;
    }
    export interface V1EinvoiceAliasesAliasTypeGetRequest {
    /**
     * 
     */
    aliasType: AliasTypeParameter;
    }
