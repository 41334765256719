import { V1TagsGetRequest, V1TagsIdDeleteRequest, V1TagsIdPutRequest, V1TagsPostRequest } from '@/apis/earchive';
import { Tag } from '@/apis/earchive/interfaces';
import { Breadcrumbs, InfoToastify, RootSpinner } from '@/components';
import { New, ProcessDropdownButton, Search } from '@/components/Buttons';
import { Delete, Edit } from '@/components/DropdownItems';
import SelectableBaseTable, { CellRendererProps } from '@/components/SelectableBaseTable';
import { PageConst, PageStyle } from '@/constants/page';
import { useApiInConfirmation, useGetPageData } from '@/hooks';
import { Col, Form, Input, Menu, Row } from 'antd';
import { AxiosPromise } from 'axios';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import intl from 'react-intl-universal';
import { Card, CardBody, CardHeader } from 'reactstrap';
import TagModal, { TagModalProps } from './TagModal';
type TableRowProps = CellRendererProps<Tag, Array<Tag>>;

const Tags = ({
  title,
  post,
  put,
  getTags,
  deleteTag
}: {
  title: string;
  getTags: (requestParameters: V1TagsGetRequest) => AxiosPromise<Array<Tag>>;
  deleteTag: (requestParameters: V1TagsIdDeleteRequest) => AxiosPromise<void>;
  post: (requestParameters: V1TagsPostRequest) => AxiosPromise<any>;
  put: (requestParameters: V1TagsIdPutRequest) => AxiosPromise<void>;
}) => {
  const [requestParameter, setRequestParameter] = useState<V1TagsGetRequest>({ query: undefined });
  const { execute, loading, data, setData, error } = useGetPageData<Array<Tag>>(getTags, requestParameter, true);

  const deleteTags = useApiInConfirmation<{ id: string }>({
    asyncFunction: deleteTag
  });

  const [tagModalState, setTagModalState] = useState<TagModalProps>({
    visible: false,
    tag: undefined
  });

  return (
    <RootSpinner loading={loading}>
      <TagModal closeModal={() => setTagModalState({ visible: false, tag: undefined })} refresh={execute} post={post} put={put} modalState={tagModalState} />
      <Helmet title={title} />
      <Card className="h-100">
        <CardHeader className={PageStyle.card__header}>
          <Breadcrumbs />
          <div>
            <New onClick={() => setTagModalState({ visible: true, tag: undefined })} />
            {'  '}
            <Search onClick={() => execute()} />
          </div>
        </CardHeader>
        <CardBody className={PageStyle.virtualized__card__body}>
          <Form layout="vertical">
            <Row gutter={PageConst.RowGutter}>
              <Col sm={24}>
                <Form.Item label={intl.get('ARANACAK_KELIME')}>
                  <Input
                    defaultValue={requestParameter?.query}
                    onChange={(input) => setRequestParameter({ ...requestParameter, query: input.target.value })}
                    onKeyDown={(event) => {
                      if (event.keyCode === 13) {
                        execute();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row className="flex-fill">
            <SelectableBaseTable
              key="tags-table"
              fixed
              error={error}
              data={data || []}
              rowKey="id"
              notSelectable
              requestParameters={requestParameter}
              columns={[
                {
                  key: 'name',
                  title: intl.get('ETIKET_ISMI'),
                  dataKey: 'name',
                  minWidth: 170,
                  width: 170,
                  className: 'base-table-flex',
                  headerClassName: 'base-table-flex',
                  cellRenderer: ({ rowData }: TableRowProps) => {
                    return (
                      <label key={`tag-${rowData.id}`} className={`label `} style={{ backgroundColor: `#${rowData.color}`, cursor: 'pointer' }}>
                        {rowData.name}
                      </label>
                    );
                  }
                },

                {
                  key: 'description',
                  title: intl.get('ACIKLAMA'),
                  dataKey: 'description',
                  width: 200,
                  minWidth: 200,
                  cellRenderer: ({ rowData }: TableRowProps) => {
                    return <p>{rowData.description}</p>;
                  }
                },
                {
                  title: intl.get('ISLEMLER'),
                  minWidth: 95,
                  width: 95,
                  align: 'center',
                  cellRenderer: ({ rowData }: TableRowProps) => {
                    return (
                      <ProcessDropdownButton
                        isRow
                        menu={
                          <Menu>
                            <Edit onClick={() => setTagModalState({ visible: true, tag: rowData })} />
                            <Menu.Divider />
                            <Delete
                              onClick={() =>
                                deleteTags.confirm(
                                  { id: rowData.id },
                                  {
                                    content: intl.getHTML('ETIKET_SILINECEKTIR_DEVAM_EDILSIN_MI', { title: rowData.name }),
                                    title: `Kayıt sil - ${rowData.id}`,
                                    successCallback: () => {
                                      InfoToastify(intl.getHTML('ETIKET_SILINDI', { title: rowData.name }));
                                      data && setData(data.filter((x) => x.id !== rowData.id));
                                      execute();
                                    }
                                  }
                                )
                              }
                            />
                          </Menu>
                        }
                      />
                    );
                  }
                }
              ]}
            />
          </Row>
        </CardBody>
      </Card>
    </RootSpinner>
  );
};

export default Tags;
