export const EInvoiceLayouts = {
  xxl: [
    { w: 5, h: 14, x: 0, y: 0, i: 'einvoice-last-incoming-invoices', moved: false, static: false },
    { w: 14, h: 8, x: 5, y: 0, i: 'einvoice-daily', moved: false, static: false },
    { w: 5, h: 14, x: 19, y: 0, i: 'einvoice-last-outgoing-invoices', moved: false, static: false },
    { w: 5, h: 6, x: 5, y: 8, i: 'einvoice-last-outgoing-invoice-rejected', moved: false, static: false },
    { w: 5, h: 6, x: 10, y: 8, i: 'einvoice-last-outgoing-failed-invoices', moved: false, static: false },
    { w: 4, h: 2, x: 15, y: 8, i: 'einvoice-incoming-total', moved: false, static: false },
    { w: 4, h: 2, x: 15, y: 10, i: 'einvoice-outgoing-total', moved: false, static: false },
    { w: 4, h: 2, x: 15, y: 12, i: 'einvoice-total', moved: false, static: false },
    { w: 6, h: 6, x: 0, y: 14, i: 'einvoice-outgoing-invoice-status', moved: false, static: false },
    { w: 6, h: 6, x: 6, y: 14, i: 'einvoice-answer-stats', moved: false, static: false },
    { w: 6, h: 6, x: 12, y: 14, i: 'einvoice-profile-stats', moved: false, static: false },
    { w: 6, h: 6, x: 18, y: 14, i: 'einvoice-type-stats', moved: false, static: false }
  ],
  xl: [
    { w: 24, h: 8, x: 0, y: 0, i: 'einvoice-daily', moved: false, static: false },
    { w: 6, h: 8, x: 0, y: 8, i: 'einvoice-last-incoming-invoices', moved: false, static: false },
    { w: 6, h: 8, x: 6, y: 8, i: 'einvoice-last-outgoing-invoices', moved: false, static: false },
    { w: 6, h: 8, x: 12, y: 8, i: 'einvoice-last-outgoing-failed-invoices', moved: false, static: false },
    { w: 6, h: 8, x: 18, y: 8, i: 'einvoice-last-outgoing-invoice-rejected', moved: false, static: false },

    { w: 6, h: 8, x: 0, y: 16, i: 'einvoice-outgoing-invoice-status', moved: false, static: false },
    { w: 6, h: 8, x: 6, y: 16, i: 'einvoice-answer-stats', moved: false, static: false },
    { w: 6, h: 8, x: 12, y: 16, i: 'einvoice-type-stats', moved: false, static: false },
    { w: 6, h: 8, x: 18, y: 16, i: 'einvoice-profile-stats', moved: false, static: false },

    { w: 8, h: 2, x: 0, y: 22, i: 'einvoice-incoming-total', moved: false, static: false },
    { w: 8, h: 2, x: 8, y: 22, i: 'einvoice-outgoing-total', moved: false, static: false },
    { w: 8, h: 2, x: 16, y: 22, i: 'einvoice-total', moved: false, static: false }
  ],
  lg: [
    { w: 24, h: 8, x: 0, y: 0, i: 'einvoice-daily', moved: false, static: false },

    { w: 8, h: 8, x: 0, y: 8, i: 'einvoice-last-incoming-invoices', moved: false, static: false },
    { w: 8, h: 8, x: 8, y: 8, i: 'einvoice-last-outgoing-invoices', moved: false, static: false },
    { w: 8, h: 8, x: 16, y: 8, i: 'einvoice-outgoing-invoice-status', moved: false, static: false },

    { w: 8, h: 6, x: 0, y: 16, i: 'einvoice-last-outgoing-failed-invoices', moved: false, static: false },
    { w: 8, h: 6, x: 8, y: 16, i: 'einvoice-last-outgoing-invoice-rejected', moved: false, static: false },

    { w: 8, h: 2, x: 16, y: 16, i: 'einvoice-incoming-total', moved: false, static: false },
    { w: 8, h: 2, x: 16, y: 20, i: 'einvoice-outgoing-total', moved: false, static: false },
    { w: 8, h: 2, x: 16, y: 18, i: 'einvoice-total', moved: false, static: false },

    { w: 8, h: 8, x: 0, y: 22, i: 'einvoice-answer-stats', moved: false, static: false },
    { w: 8, h: 8, x: 8, y: 22, i: 'einvoice-type-stats', moved: false, static: false },
    { w: 8, h: 8, x: 16, y: 22, i: 'einvoice-profile-stats', moved: false, static: false }
  ],
  md: [
    { w: 24, h: 7, x: 0, y: 0, i: 'einvoice-daily', moved: false, static: false },

    { w: 12, h: 8, x: 0, y: 7, i: 'einvoice-last-incoming-invoices', moved: false, static: false },
    { w: 12, h: 8, x: 12, y: 7, i: 'einvoice-last-outgoing-invoices', moved: false, static: false },

    { w: 12, h: 7, x: 0, y: 15, i: 'einvoice-last-outgoing-invoice-rejected', moved: false, static: false },
    { w: 12, h: 7, x: 12, y: 15, i: 'einvoice-last-outgoing-failed-invoices', moved: false, static: false },

    { w: 15, h: 6, x: 0, y: 22, i: 'einvoice-outgoing-invoice-status', moved: false, static: false },
    { w: 9, h: 2, x: 15, y: 22, i: 'einvoice-incoming-total', moved: false, static: false },
    { w: 9, h: 2, x: 15, y: 24, i: 'einvoice-outgoing-total', moved: false, static: false },
    { w: 9, h: 2, x: 15, y: 26, i: 'einvoice-total', moved: false, static: false },

    { w: 12, h: 8, x: 0, y: 28, i: 'einvoice-answer-stats', moved: false, static: false },
    { w: 12, h: 8, x: 12, y: 28, i: 'einvoice-profile-stats', moved: false, static: false },

    { w: 24, h: 6, x: 0, y: 36, i: 'einvoice-type-stats', moved: false, static: false }
  ],
  sm: [
    { w: 24, h: 7, x: 0, y: 0, i: 'einvoice-daily', moved: false, static: false },

    { w: 12, h: 7, x: 0, y: 7, i: 'einvoice-last-incoming-invoices', moved: false, static: false },
    { w: 12, h: 7, x: 12, y: 7, i: 'einvoice-last-outgoing-invoices', moved: false, static: false },

    { w: 12, h: 6, x: 0, y: 14, i: 'einvoice-last-outgoing-invoice-rejected', moved: false, static: false },
    { w: 12, h: 6, x: 12, y: 14, i: 'einvoice-last-outgoing-failed-invoices', moved: false, static: false },

    { w: 12, h: 7, x: 0, y: 20, i: 'einvoice-outgoing-invoice-status', moved: false, static: false },
    { w: 12, h: 7, x: 12, y: 20, i: 'einvoice-answer-stats', moved: false, static: false },

    { w: 12, h: 7, x: 0, y: 27, i: 'einvoice-profile-stats', moved: false, static: false },
    { w: 12, h: 7, x: 12, y: 27, i: 'einvoice-type-stats', moved: false, static: false },

    { w: 12, h: 2, x: 0, y: 34, i: 'einvoice-incoming-total', moved: false, static: false },
    { w: 12, h: 2, x: 12, y: 34, i: 'einvoice-outgoing-total', moved: false, static: false },
    { w: 24, h: 2, x: 0, y: 36, i: 'einvoice-total', moved: false, static: false }
  ],
  xs: [
    { w: 24, h: 6, x: 0, y: 0, i: 'einvoice-daily', moved: false, static: false },

    { w: 24, h: 7, x: 0, y: 6, i: 'einvoice-last-incoming-invoices', moved: false, static: false },

    { w: 24, h: 7, x: 0, y: 13, i: 'einvoice-last-outgoing-invoices', moved: false, static: false },

    { w: 24, h: 5, x: 0, y: 20, i: 'einvoice-last-outgoing-invoice-rejected', moved: false, static: false },

    { w: 24, h: 5, x: 0, y: 25, i: 'einvoice-last-outgoing-failed-invoices', moved: false, static: false },

    { w: 12, h: 7, x: 0, y: 30, i: 'einvoice-outgoing-invoice-status', moved: false, static: false },
    { w: 12, h: 7, x: 12, y: 30, i: 'einvoice-answer-stats', moved: false, static: false },

    { w: 24, h: 5, x: 0, y: 37, i: 'einvoice-profile-stats', moved: false, static: false },

    { w: 24, h: 5, x: 0, y: 42, i: 'einvoice-type-stats', moved: false, static: false },

    { w: 12, h: 2, x: 0, y: 47, i: 'einvoice-incoming-total', moved: false, static: false },
    { w: 12, h: 2, x: 12, y: 47, i: 'einvoice-outgoing-total', moved: false, static: false },
    { w: 24, h: 2, x: 0, y: 49, i: 'einvoice-total', moved: false, static: false }
  ]
};
