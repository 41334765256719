import { FILTER_REGEX } from '@/constants/regex';
import { GroupSelectOption, GroupSelectOptionItem } from '@/interfaces';
import { AnyType } from '@/type';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import './style.scss';
const { OptGroup, Option } = Select;

export const ComputeDefaultSelected = (groups: GroupSelectOption[], filter: AnyType) => {
  const selected: GroupSelectOptionItem[] = [];
  const groupKeys = groups.reduce((groups: string[], group) => {
    groups.push(group.key);
    return groups;
  }, []);

  Object.keys(filter).forEach((key) => {
    if (groupKeys.some((x) => x === key)) {
      const group = groups.find((x) => x.key === key);
      if (group) {
        const item = group.groupItems.find((x) => x.value === filter[key]);
        if (item) {
          selected.push(item);
        }
      }
    }
  });

  return selected;
};

export const ComputeFilterOnChange = (value: GroupSelectOptionItem[], groups: GroupSelectOption[], filter: AnyType) => {
  const prevFilterState = { ...filter };

  groups.forEach((group) => {
    if (value.some((x) => x.group === group.key)) {
      const item = value.find((x) => x.group === group.key);
      if (item) {
        const regexGroups = FILTER_REGEX.exec(item.value || '')?.groups;
        if (regexGroups) {
          prevFilterState[regexGroups.key] = regexGroups.value;
        }
      }
    } else {
      if (Object.keys(prevFilterState).some((x) => x === group.key)) {
        delete prevFilterState[group.key];
      }
    }
  });

  return prevFilterState;
};

const selectedValuesFromProps = (defaultValues?: GroupSelectOptionItem[]) => {
  return (defaultValues || []).reduce((items: GroupSelectOptionItem[], item) => {
    items.push({ ...item, value: item.group + '_' + item.value });
    return items;
  }, []);
};

export const GroupSelect = ({
  groups,
  defaultSelected,
  onChange
}: {
  groups: GroupSelectOption[];
  defaultSelected?: GroupSelectOptionItem[];
  onChange?: (value: GroupSelectOptionItem[]) => void;
}) => {
  const [selected, setSelected] = useState(selectedValuesFromProps(defaultSelected));
  let selectedOption: GroupSelectOptionItem | null = null;

  const handleSelect = (value, option) => {
    selectedOption = option.props as GroupSelectOptionItem;
    const newSelected = selected.filter((x) => x.group !== (selectedOption && selectedOption.group));
    if (selectedOption != null) newSelected.push(selectedOption);

    setSelected(newSelected);

    if (onChange !== undefined) {
      onChange(newSelected);
    }
  };

  const handleDeSelect = (value) => {
    selectedOption = null;

    const changedValue = selected.filter((x) => x.value !== value);

    setSelected(selected.filter((x) => x.value !== value));

    if (onChange) {
      onChange(changedValue);
    }
  };

  //dısarıdan GroupSelect için Select-DeSelect işlemi
  useEffect(() => {
    setSelected(selectedValuesFromProps(defaultSelected));
  }, [defaultSelected]);

  return (
    <Select
      showSearch={false}
      showArrow
      style={{ minWidth: '225px !important' }}
      mode="multiple"
      className={'w-100 custom__group__select'}
      menuItemSelectedIcon={<FontAwesomeIcon icon={faCheck} />}
      value={selected.map((x) => x.value)}
      maxTagCount={0}
      onSelect={handleSelect}
      onDeselect={handleDeSelect}
      maxTagPlaceholder={(x) => {
        return `${x.length} ${intl.get('OGE_SECILDI')}`;
      }}
      placeholder={intl.get('SECINIZ')}
      dropdownClassName="custom__group__select"
    >
      {groups.map((group) => {
        return (
          <OptGroup key={group.key} label={`${group.label}`}>
            {group.groupItems.map((item) => (
              <Option {...item} key={`${item.group}_${item.value}`} value={`${item.group}_${item.value}`}>
                {item.text}
              </Option>
            ))}
          </OptGroup>
        );
      })}
    </Select>
  );
};

export default GroupSelect;
