// tslint:disable
// eslint:disable
/**
 * NES Identity Server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum ProductType {
    EInvoice = 'EInvoice',
    EArchive = 'EArchive',
    EBook = 'EBook',
    EDespatch = 'EDespatch',
    ETicket = 'ETicket',
    ESmm = 'ESmm',
    EMm = 'EMm'
}



