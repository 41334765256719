import { DailyStatistics } from '@/apis/general/interfaces';
import { PageStyle } from '@/constants/page';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton } from 'antd';
import React, { useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Pie from '../Components/Pie';

export const InvoiceTypeCodeStats = ({
  data,
  flattenData,
  loading,
  startDate,
  endDate
}: {
  data?: Array<DailyStatistics>;
  flattenData: any;
  loading: boolean;
  startDate: string;
  endDate: string;
}) => {
  if (loading) {
    return <Skeleton active loading={loading} />;
  }

  const [activeTab, setActiveTab] = useState<'incoming' | 'outgoing'>('incoming');

  const getColorFromType = (profile: string) => {
    switch (profile) {
      case 'SATIS':
        return '#007bff';
      case 'IADE':
        return '#dc3545';
      case 'TEVKIFAT':
        return '#29b2a6';
      case 'TEVKIFATIADE':
        return '#840c17';
      case 'ISTISNA':
        return '#ffc107';
      case 'OZELMATRAH':
        return '#17a2b8';
      case 'IHRACKAYITLI':
        return '#6f42c1';
      case 'SGK':
        return '#e83e8c';
      case 'SEVK':
        return '#41b883';
      case 'MATBUDAN':
        return '#ffc107';
      default:
        return 'black';
    }
  };

  return (
    <Card className="h-100 kit__utils__cardMarked kit__utils__cardMarked--warning">
      <CardHeader className={PageStyle.card__header}>
        <div className="d-flex align-items-center mr-auto">
          <FontAwesomeIcon icon={faPencil} style={{ marginRight: '10px' }} color="#0ab1fc" />
          <span className="font-weight-bold mr-3">Fatura Tipi</span>
        </div>
      </CardHeader>
      <CardBody style={{ padding: '10px 20px' }} className={'overflow-auto align-items-center d-flex'}>
        <Pie
          data={
            data?.length !== 0
              ? Object.keys(flattenData)
                  .filter((x) => x.startsWith('EA:I:T'))
                  .map((x) => {
                    const invoiceType = x.replace('EA:I:T:', '');
                    return {
                      label: invoiceType,
                      value: flattenData[x],
                      color: getColorFromType(invoiceType),
                      url: `/earchive/list?startDate=${startDate}&endDate=${endDate}&invoiceTypeCode=${invoiceType}`
                    };
                  })
              : []
          }
        />
      </CardBody>
    </Card>
  );
};

export default InvoiceTypeCodeStats;
