import { V1IncomingInvoicesEmailSendPostRequest } from '@/apis/einvoice';
import { DocumentInfo, InfoToastify, RootSpinner } from '@/components';
import { Close } from '@/components/Buttons';
import { ErrorViewer } from '@/components/TableBody';
import { MAIL_REGEX } from '@/constants/regex';
import { useApi, usePreviousValue } from '@/hooks';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { faBan, faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Form, Modal, Select } from 'antd';
import { AxiosPromise } from 'axios';
import React, { memo, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';

const { Option } = Select;

interface Validate {
  validateStatus: 'success' | 'warning' | 'error' | 'validating' | undefined;
  message?: string;
}

export interface EmailsModalProps {
  visible: boolean;
  uuids: Array<string>;
  documentNumber?: string;
}
export const SendMailModal = memo(
  ({
    closeModal,
    modalState: { visible, uuids, documentNumber },
    sendMailFn
  }: {
    modalState: EmailsModalProps;
    closeModal: () => void;
    sendMailFn: (requestParameters: V1IncomingInvoicesEmailSendPostRequest) => AxiosPromise<void>;
  }) => {
    const { oidcUser } = useReactOidc();
    const activeData = [oidcUser.profile.email!];

    const [selected, setSelected] = useState<string[]>([]);
    const [search, setSearch] = useState('');
    const [validate, setValidate] = useState<Validate>({ validateStatus: undefined, message: undefined });

    const sendMail = useApi<void, V1IncomingInvoicesEmailSendPostRequest>({
      asyncFunction: sendMailFn,
      successCallback: () => {
        closeModal();
        InfoToastify(intl.getHTML('BELIRTILEN_ADRESLERE_MAIL_GONDERILDI'));
      }
    });

    const prevVisible = usePreviousValue(visible);

    useEffect(() => {
      if (prevVisible === true && !visible) {
        sendMail.clear();
        setSelected([]);
      }
    }, [prevVisible, sendMail, visible]);

    const send = () => {
      if (selected.length === 0) {
        setValidate({
          validateStatus: 'error',
          message: `Geçerli bir electronicMail adresi girilmelidir!`
        });

        return;
      } else {
        setValidate({
          validateStatus: undefined,
          message: undefined
        });
      }

      Modal.confirm({
        icon: <QuestionCircleOutlined />,
        title: `electronicMail gönder  - ${uuids.length == 1 ? uuids[0] : ''}`,
        width: 600,
        okText: (
          <>
            <FontAwesomeIcon icon={faCheck} className="btn-label" />
            <span>{intl.get('EVET')}</span>
          </>
        ),
        cancelText: (
          <>
            <FontAwesomeIcon icon={faBan} className="btn-label" />
            <span>{intl.get('HAYIR')}</span>
          </>
        ),
        okButtonProps: {
          className: 'btn-warning btn-labeled btn-sm',
          prefix: 'btn',
          prefixCls: 'btn'
        },
        cancelButtonProps: {
          className: 'btn-outlined-primary btn-labeled btn-sm',
          prefix: 'btn',
          prefixCls: 'btn'
        },
        content: intl.getHTML('BELIRTILEN_ADRESLERE_MAIL_GONDERILECEK'),
        onOk() {
          sendMail.call({ emailSendDto: { uuids: uuids, emailAdresses: selected } });
        }
      });
    };

    useEffect(() => {
      const input = window.document.querySelector('input#send-electronicMail-input') as HTMLInputElement;
      if (input) {
        input.value = '';
      }
    }, [selected]);

    const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.keyCode === 13) {
        const inputElement = event.target as HTMLInputElement;

        if (MAIL_REGEX.test(inputElement.value)) {
          if (selected.some((x) => x === inputElement.value)) {
            setValidate({ validateStatus: 'error', message: `${inputElement.value} daha önce eklendi!` });
            return;
          }

          setValidate({ validateStatus: undefined, message: undefined });
          setSelected([...selected, inputElement.value]);
        } else {
          if (inputElement.value !== '') {
            setValidate({
              validateStatus: 'error',
              message: `${inputElement.value} geçerli bir electronicMail adresi değildir. Lütfen geçerli bir electronicMail adresi giriniz!`
            });
          }
        }
      }
    };

    const onDeSelect = (value) => {
      setSelected(selected.filter((x) => x !== value));
    };

    const onSelect = (value) => {
      setSelected([...selected, value]);
    };

    useEffect(() => {
      setValidate({ validateStatus: undefined, message: undefined });
    }, [search]);

    return (
      <Modal
        centered
        title={
          <span>
            <FontAwesomeIcon icon={faEnvelope} /> {intl.get('MAIL_GONDER')}
          </span>
        }
        onCancel={closeModal}
        width={900}
        visible={visible}
        footer={[
          <div key="send-electronicMail-modal-footer">
            <Close onClick={closeModal} />
            <Button size={'sm'} key="btn-search" onClick={send} color={'success'} className="btn-labeled">
              <FontAwesomeIcon icon={faEnvelope} className={'btn-label'} />
              <span>{intl.get('MAIL_GONDER')}</span>
            </Button>
          </div>
        ]}
      >
        <RootSpinner loading={sendMail.loading}>
          <DocumentInfo documentNumber={documentNumber || ''} uuid={uuids.length == 1 ? uuids[0] : ''} />
          <Alert message={intl.get('ENTER_KULLANARAK_COKLU_GIRIS_YAPABILIRSINIZ')} style={{ marginTop: 10 }} type="info" showIcon />
          <Form layout="vertical">
            <Form.Item
              validateStatus={validate.validateStatus}
              help={<span>{validate.message}</span>}
              style={{ marginTop: 10 }}
              label={intl.get('ARANACAK_KELIME')}
              hasFeedback
            >
              <Select
                id="send-electronicMail-input"
                mode="multiple"
                value={selected}
                onInputKeyDown={onInputKeyDown}
                onSearch={(value) => {
                  setSearch(value);
                }}
                dropdownStyle={{
                  display:
                    !activeData.some((x) => !selected.some((a) => a === x)) || (search.length > 0 && activeData.every((x) => x.indexOf(search) === -1))
                      ? 'none'
                      : ''
                }}
                placeholder={intl.get('MAIL_ADRESI_GIRINIZ')}
                style={{ width: '100%' }}
                onDeselect={onDeSelect}
                onSelect={onSelect}
              >
                {activeData
                  .filter((x) => !selected.some((a) => a === x))
                  .map((item) => (
                    <Option value={item} key={item}>
                      {item}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Form>
          {sendMail.error && <ErrorViewer error={sendMail.error} />}
        </RootSpinner>
      </Modal>
    );
  }
);
