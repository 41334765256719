import { DailyStatistics } from '@/apis/general/interfaces';
import { getDays } from '@/utils/array';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { Skeleton } from 'antd';
import moment from 'moment';
import React, { useImperativeHandle } from 'react';
import { Responsive } from 'react-grid-layout';
import { SizeMe } from 'react-sizeme';
import DailyLineChart from '../Components/DailyLineChart';
import { Doughnut } from '../Components/Doughnut';
import AnswerStats from './AnswerStats';
import FailedInvoices from './FailedInvoices';
import InvoiceTypeCodeStats from './InvoiceTypeCodeStats';
import LastIncomingInvoices from './LastIncomingInvoices';
import LastOutgoingInvoices from './LastOutgoingInvoices';
import { EInvoiceLayouts } from './Layouts';
import ProfileIdStats from './ProfileIdStats';
import RejectedInvoices from './RejectedInvoices';

const EInvoice = React.forwardRef(
  (
    {
      startDate,
      endDate,
      flattenData,
      data,
      loading,
      generalStats
    }: {
      startDate: string;
      endDate: string;
      flattenData: any;
      loading: boolean;
      data?: DailyStatistics[];
      generalStats: any;
    },
    ref
  ) => {
    const lastIncomingInvoicesRef = React.createRef() as any;
    const lastOutgoingInvoicesRef = React.createRef() as any;
    const rejectedInvoicesRef = React.createRef() as any;
    const failedInvoicesRef = React.createRef() as any;

    useImperativeHandle(ref, () => ({
      refreshData() {
        lastIncomingInvoicesRef.current.refresh();
        lastOutgoingInvoicesRef.current.refresh();
        rejectedInvoicesRef.current.refresh();
        failedInvoicesRef.current.refresh();
      }
    }));

    return (
      <SizeMe>
        {({ size }) => {
          const divWidth = window.document.getElementsByClassName('air__utils__content')[0]?.clientWidth;
          return (
            <Responsive
              width={size !== null ? size.width || divWidth : divWidth}
              draggableHandle=".dashboard_drag_handle"
              layouts={
                JSON.parse(localStorage.getItem('apps.dashboard.einvoice-f4975ba9-072c-439e-8089-d0d5facfc7e5') || '[]').length != 0
                  ? JSON.parse(localStorage.getItem('apps.dashboard.einvoice-f4975ba9-072c-439e-8089-d0d5facfc7e5') || '[]')
                  : EInvoiceLayouts
              }
              // layouts={EInvoiceLayouts}
              breakpoints={{ xxl: 1600, xl: 1200, lg: 1000, md: 800, sm: 600, xs: 400, xxs: 10 }}
              cols={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 24, xxs: 24 }}
              margin={[10, 10]}
              rowHeight={40}
              onLayoutChange={(_, layouts) => {
                localStorage.setItem('apps.dashboard.einvoice-f4975ba9-072c-439e-8089-d0d5facfc7e5', JSON.stringify(layouts));
              }}
            >
              <div key="einvoice-last-incoming-invoices" className="drag-item">
                <span className="dashboard_drag_handle" />
                <LastIncomingInvoices ref={lastIncomingInvoicesRef} />
              </div>
              <div key="einvoice-last-outgoing-invoices" className="drag-item">
                <span className="dashboard_drag_handle" />
                <LastOutgoingInvoices ref={lastOutgoingInvoicesRef} />
              </div>
              <div key="einvoice-last-outgoing-invoice-rejected" className="drag-item">
                <span className="dashboard_drag_handle" />
                <RejectedInvoices ref={rejectedInvoicesRef} />
              </div>

              <div key="einvoice-last-outgoing-failed-invoices" className="drag-item">
                <span className="dashboard_drag_handle" />
                <FailedInvoices ref={failedInvoicesRef} />
              </div>

              <div key="einvoice-incoming-total" className="drag-item">
                <span className="dashboard_drag_handle" />
                {loading && <Skeleton active loading={loading} />}
                {!loading && (
                  <div className="card" style={{ border: '1px solid #bfc2c5' }}>
                    <div className="card-body">
                      <div className="d-flex flex-wrap align-items-center">
                        <div className="mr-auto">
                          <p className="text-uppercase font-weight-bold mb-1">Gelen Fatura</p>
                          <p className="mb-0">Tüm Zamanlar</p>
                        </div>
                        <p className="text-danger font-weight-bold font-size-24 mb-0">{(generalStats && generalStats['EI:I']) || 0}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div key="einvoice-outgoing-total" className="drag-item">
                <span className="dashboard_drag_handle" />
                {loading && <Skeleton active loading={loading} />}
                {!loading && (
                  <div className="card" style={{ border: '1px solid #bfc2c5' }}>
                    <div className="card-body">
                      <div className="d-flex flex-wrap align-items-center">
                        <div className="mr-auto">
                          <p className="text-uppercase font-weight-bold mb-1">Giden Fatura</p>
                          <p className="mb-0">Tüm Zamanlar</p>
                        </div>
                        <p className="text-primary font-weight-bold font-size-24 mb-0">{(generalStats && generalStats['EI:O']) || 0}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div key="einvoice-total" className="drag-item">
                <span className="dashboard_drag_handle" />
                {loading && <Skeleton active loading={loading} />}
                {!loading && (
                  <div className="card" style={{ border: '1px solid #bfc2c5' }}>
                    <div className="card-body">
                      <div className="d-flex flex-wrap align-items-center">
                        <div className="mr-auto">
                          <p className="text-uppercase font-weight-bold mb-1">Toplam</p>
                          <p className="mb-0">Tüm Zamanlar</p>
                        </div>
                        <p className="text-success font-weight-bold font-size-24 mb-0">
                          {((generalStats && generalStats['EI:O']) || 0) + ((generalStats && generalStats['EI:I']) || 0)}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div key="einvoice-daily" className="drag-item">
                <span className="dashboard_drag_handle" />
                <DailyLineChart
                  title="E-Fatura İstatistikleri"
                  icon={faPencil}
                  loading={loading}
                  data={
                    data === undefined ||
                    data.length === 0 ||
                    (data.length !== 0 && !data.some((x) => x.statistics['EI:I'] !== undefined) && !data.some((x) => x.statistics['EI:O'] !== undefined))
                      ? getDays(startDate, endDate).map((item) => {
                          return {
                            date: item,
                            data: [
                              {
                                color: '#dc3545',
                                label: 'Gelen e-Fatura',
                                value: 0
                              },
                              {
                                color: '#007bff',
                                label: 'Giden e-Fatura',
                                value: 0
                              }
                            ]
                          };
                        })
                      : data.map((item) => {
                          return {
                            date: moment(item.date),
                            data: [
                              {
                                color: '#dc3545',
                                label: 'Gelen e-Fatura',
                                value: item.statistics['EI:I'],
                                url: `/einvoice/incoming/list?&startDate=${startDate}&endDate=${endDate}`
                              },
                              {
                                color: '#007bff',
                                label: 'Giden e-Fatura',
                                value: item.statistics['EI:O'],
                                url: `/einvoice/outgoing/list?&startDate=${startDate}&endDate=${endDate}`
                              }
                            ]
                          };
                        })
                  }
                />
              </div>

              <div key="einvoice-profile-stats" className="drag-item">
                <span className="dashboard_drag_handle" />
                <ProfileIdStats startDate={startDate} endDate={endDate} flattenData={flattenData} loading={loading} data={data} />
              </div>
              <div key="einvoice-type-stats" className="drag-item">
                <span className="dashboard_drag_handle" />
                <InvoiceTypeCodeStats startDate={startDate} endDate={endDate} flattenData={flattenData} loading={loading} data={data} />
              </div>
              <div key="einvoice-answer-stats" className="drag-item">
                <span className="dashboard_drag_handle" />
                <AnswerStats startDate={startDate} endDate={endDate} flattenData={flattenData} loading={loading} data={data} />
              </div>

              <div key="einvoice-outgoing-invoice-status" className="drag-item">
                <span className="dashboard_drag_handle" />
                <Doughnut
                  icon={faPencil}
                  loading={loading}
                  title="Giden Fatura Durumları"
                  data={
                    flattenData['EI:O:S:S'] || flattenData['EI:O:S:F'] || flattenData['EI:O:S:W']
                      ? [
                          {
                            color: '#41b883',
                            label: 'Başarılı',
                            value: flattenData['EI:O:S:S'] || 0,
                            url: `/einvoice/outgoing/list?startDate=${startDate}&endDate=${endDate}&invoiceStatus=Succeed`
                          },
                          {
                            color: '#dc3545',
                            label: 'Başarısız',
                            value: flattenData['EI:O:S:F'] || 0,
                            url: `/einvoice/outgoing/list?startDate=${startDate}&endDate=${endDate}&invoiceStatus=Error`
                          },
                          {
                            color: '#ffc107',
                            label: 'İşlem Bekleyen',
                            value: flattenData['EI:O:S:W'] || 0,
                            url: `/einvoice/outgoing/list?startDate=${startDate}&endDate=${endDate}&invoiceStatus=Waiting`
                          }
                        ]
                      : []
                  }
                />
              </div>
            </Responsive>
          );
        }}
      </SizeMe>
    );
  }
);

export default EInvoice;
