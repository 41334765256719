import { Close } from '@/components/Buttons';
import { PageConst } from '@/constants/page';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';
export interface PreviewModalProps {
  visible: boolean;
  data: string;
}
const PreviewModal = ({ closeModal, modalState: { visible, data } }: { closeModal: () => void; modalState: PreviewModalProps }) => {
  return (
    <Modal
      centered
      title={
        <span>
          <FontAwesomeIcon icon={faSearch} /> {'Xlsts Önizleme'}
        </span>
      }
      onCancel={closeModal}
      width={PageConst.LargeModalWidth}
      visible={visible}
      footer={<Close onClick={closeModal} />}
    >
      {data && (
        <iframe
          title={'Xlsts Önizleme'}
          id="xsltsPreview"
          src="#"
          srcDoc={data}
          width={PageConst.LargeModalWidth}
          height="100%"
          frameBorder="0"
          style={{ width: '100%', minHeight: 600, paddingLeft: 10 }}
        />
      )}
    </Modal>
  );
};

export default PreviewModal;
