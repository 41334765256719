/* eslint-disable @typescript-eslint/naming-convention */
import antdData from 'antd/lib/locale-provider/en_US';

export const enUS = Object.assign(
  { antdData: antdData },
  {
    'topBar.issuesHistory': 'Issues History',
    'topBar.typeToSearch': 'Start typing to search...',
    'topBar.actions': 'Actions',
    'topBar.status': 'Status',
    'topBar.profileMenu.hello': 'Hello',
    'topBar.profileMenu.billingPlan': 'Billing Plan',
    'topBar.profileMenu.role': 'Role',
    'topBar.profileMenu.email': 'Email',
    'topBar.profileMenu.telephone': 'telephone',
    'topBar.profileMenu.editProfile': 'Edit Profile',
    'topBar.profileMenu.logout': 'Logout',
    'topBar.projectManagement': 'Project Management',
    'topBar.buyNow': 'Buy Now $24',
    'topBar.bitcoin': 'Bitcoin',
    filtre: 'Filter',
    'tooltip.invoice.smart_search': 'You can search Invoice Number, UUID/ETTN, Company Name or Company Register Number information',
    Toastify: {
      XSLT: {
        BASARIYLA_EKLENDI: 'XSLT added successfuly.'
      },
      SERI: {
        SERI: 'Serie',
        EKLENDI: 'Serie added',
        SILINDI: 'Serie deleted',
        VARSAYILAN_AYARLANDI: 'Serie default marked'
      }
    },
    Menu: {
      EFATURA: {
        BASLIK: 'E-Invoice',
        GIDEN_KUTUSU: {
          BASLIK: 'Outbox',
          FATURALAR: 'Invoices',
          ARSIV: 'Archived Invoices',
          EXCEL_RAPOR_MODULU: {
            BASLIK: 'Excel Report Module',
            RAPOR_LISTESI: 'Reports',
            RAPOR_SABLONLARI: 'Templates'
          }
        }
      }
    }
  }
);

export default enUS;
