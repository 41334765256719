// tslint:disable
// eslint:disable
/**
 * E-Archive API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Tag } from '../interfaces';
// @ts-ignore
import { TagDto } from '../interfaces';
/**
 * TagsApi - axios parameter creator
 * @export
 */
export const TagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Kayıtlı etiket listenize bu uç ile ulaşabilirsiniz.
         * @summary Etiketleri listeler
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TagsGet: async (requestParameters: V1TagsGetRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/tags`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.query !== undefined) {
                localVarQueryParameter['query'] = requestParameters.query;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Kayıtlı etiketi silmek bu uç kullanılabilir.    > **Bilgilendirme** : Etiket silindiğinde; etikete bağlı belgelerden, **etiket tanımı silinir**.
         * @summary Etiket siler
         * @param {string} id **Silinecek etiket id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TagsIdDelete: async (requestParameters: V1TagsIdDeleteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1TagsIdDelete.');
            }
            const localVarPath = `/v1/tags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sorgulanan etiketi getirir
         * @param {string} id **Etiket id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TagsIdGet: async (requestParameters: V1TagsIdGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1TagsIdGet.');
            }
            const localVarPath = `/v1/tags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Kayıtlı olan etiket bilgilerini düzenlemek için bu uç kullanılabilir.
         * @summary Etiket günceller
         * @param {string} id **Etiket id**
         * @param {TagDto} tagDto #### Etiket düzenleme için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TagsIdPut: async (requestParameters: V1TagsIdPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1TagsIdPut.');
            }
            // verify required parameter 'tagDto' is not null or undefined
            if (requestParameters.tagDto === null || requestParameters.tagDto === undefined) {
                throw new RequiredError('tagDto','Required parameter tagDto was null or undefined when calling v1TagsIdPut.');
            }
            const localVarPath = `/v1/tags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.tagDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.tagDto !== undefined ? requestParameters.tagDto : {}) : (requestParameters.tagDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Yeni etiket eklemek için bu uç kullanılabilir.
         * @summary Etiket ekler
         * @param {TagDto} tagDto #### Etiket oluşturmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TagsPost: async (requestParameters: V1TagsPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagDto' is not null or undefined
            if (requestParameters.tagDto === null || requestParameters.tagDto === undefined) {
                throw new RequiredError('tagDto','Required parameter tagDto was null or undefined when calling v1TagsPost.');
            }
            const localVarPath = `/v1/tags`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.tagDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.tagDto !== undefined ? requestParameters.tagDto : {}) : (requestParameters.tagDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagsApi - functional programming interface
 * @export
 */
export const TagsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Kayıtlı etiket listenize bu uç ile ulaşabilirsiniz.
         * @summary Etiketleri listeler
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1TagsGet(requestParameters: V1TagsGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Tag>>> {
            const localVarAxiosArgs = await TagsApiAxiosParamCreator(configuration).v1TagsGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Kayıtlı etiketi silmek bu uç kullanılabilir.    > **Bilgilendirme** : Etiket silindiğinde; etikete bağlı belgelerden, **etiket tanımı silinir**.
         * @summary Etiket siler
         * @param {string} id **Silinecek etiket id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1TagsIdDelete(requestParameters: V1TagsIdDeleteRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TagsApiAxiosParamCreator(configuration).v1TagsIdDelete(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sorgulanan etiketi getirir
         * @param {string} id **Etiket id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1TagsIdGet(requestParameters: V1TagsIdGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag>> {
            const localVarAxiosArgs = await TagsApiAxiosParamCreator(configuration).v1TagsIdGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Kayıtlı olan etiket bilgilerini düzenlemek için bu uç kullanılabilir.
         * @summary Etiket günceller
         * @param {string} id **Etiket id**
         * @param {TagDto} tagDto #### Etiket düzenleme için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1TagsIdPut(requestParameters: V1TagsIdPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TagsApiAxiosParamCreator(configuration).v1TagsIdPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Yeni etiket eklemek için bu uç kullanılabilir.
         * @summary Etiket ekler
         * @param {TagDto} tagDto #### Etiket oluşturmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1TagsPost(requestParameters: V1TagsPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag>> {
            const localVarAxiosArgs = await TagsApiAxiosParamCreator(configuration).v1TagsPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TagsApi - factory interface
 * @export
 */
export const TagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Kayıtlı etiket listenize bu uç ile ulaşabilirsiniz.
         * @summary Etiketleri listeler
         * @param {string} [query] Aranacak kelime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TagsGet(requestParameters: V1TagsGetRequest, options?: any): AxiosPromise<Array<Tag>> {
            return TagsApiFp(configuration).v1TagsGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Kayıtlı etiketi silmek bu uç kullanılabilir.    > **Bilgilendirme** : Etiket silindiğinde; etikete bağlı belgelerden, **etiket tanımı silinir**.
         * @summary Etiket siler
         * @param {string} id **Silinecek etiket id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TagsIdDelete(requestParameters: V1TagsIdDeleteRequest, options?: any): AxiosPromise<void> {
            return TagsApiFp(configuration).v1TagsIdDelete(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sorgulanan etiketi getirir
         * @param {string} id **Etiket id**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TagsIdGet(requestParameters: V1TagsIdGetRequest, options?: any): AxiosPromise<Tag> {
            return TagsApiFp(configuration).v1TagsIdGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Kayıtlı olan etiket bilgilerini düzenlemek için bu uç kullanılabilir.
         * @summary Etiket günceller
         * @param {string} id **Etiket id**
         * @param {TagDto} tagDto #### Etiket düzenleme için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TagsIdPut(requestParameters: V1TagsIdPutRequest, options?: any): AxiosPromise<void> {
            return TagsApiFp(configuration).v1TagsIdPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Yeni etiket eklemek için bu uç kullanılabilir.
         * @summary Etiket ekler
         * @param {TagDto} tagDto #### Etiket oluşturmak için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TagsPost(requestParameters: V1TagsPostRequest, options?: any): AxiosPromise<Tag> {
            return TagsApiFp(configuration).v1TagsPost(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TagsApi - object-oriented interface
 * @export
 * @class TagsApi
 * @extends {BaseAPI}
 */
export class TagsApi extends BaseAPI {
    /**
     * Kayıtlı etiket listenize bu uç ile ulaşabilirsiniz.
     * @summary Etiketleri listeler
     * @param {string} [query] Aranacak kelime
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public v1TagsGet(requestParameters: V1TagsGetRequest, options?: any) {
        return TagsApiFp(this.configuration).v1TagsGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Kayıtlı etiketi silmek bu uç kullanılabilir.    > **Bilgilendirme** : Etiket silindiğinde; etikete bağlı belgelerden, **etiket tanımı silinir**.
     * @summary Etiket siler
     * @param {string} id **Silinecek etiket id**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public v1TagsIdDelete(requestParameters: V1TagsIdDeleteRequest, options?: any) {
        return TagsApiFp(this.configuration).v1TagsIdDelete(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sorgulanan etiketi getirir
     * @param {string} id **Etiket id**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public v1TagsIdGet(requestParameters: V1TagsIdGetRequest, options?: any) {
        return TagsApiFp(this.configuration).v1TagsIdGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Kayıtlı olan etiket bilgilerini düzenlemek için bu uç kullanılabilir.
     * @summary Etiket günceller
     * @param {string} id **Etiket id**
     * @param {TagDto} tagDto #### Etiket düzenleme için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public v1TagsIdPut(requestParameters: V1TagsIdPutRequest, options?: any) {
        return TagsApiFp(this.configuration).v1TagsIdPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Yeni etiket eklemek için bu uç kullanılabilir.
     * @summary Etiket ekler
     * @param {TagDto} tagDto #### Etiket oluşturmak için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public v1TagsPost(requestParameters: V1TagsPostRequest, options?: any) {
        return TagsApiFp(this.configuration).v1TagsPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1TagsGetRequest {
    /**
     * Aranacak kelime
     */
    query?: string;
    }
    export interface V1TagsIdDeleteRequest {
    /**
     * **Silinecek etiket id**
     */
    id: string;
    }
    export interface V1TagsIdGetRequest {
    /**
     * **Etiket id**
     */
    id: string;
    }
    export interface V1TagsIdPutRequest {
    /**
     * **Etiket id**
     */
    id: string;
    /**
     * #### Etiket düzenleme için gönderilmesi gereken model
     */
    tagDto: TagDto;
    }
    export interface V1TagsPostRequest {
    /**
     * #### Etiket oluşturmak için gönderilmesi gereken model
     */
    tagDto: TagDto;
    }
