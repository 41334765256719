import { ButtonProps } from '@/interfaces';
import { faRecycle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React from 'react';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';

export const Refresh = ({ disabled, onClick, title, size = 'sm', onlyIcon }: ButtonProps) => {
  return (
    <Button disabled={disabled} size={size} key="btn-close" onClick={onClick} color={'primary'} className="btn-labeled">
      <FontAwesomeIcon icon={faRecycle} className={classnames({ 'btn-label': !onlyIcon })} />
      {!onlyIcon && <span>{title ? title : intl.get('YENILE')}</span>}
    </Button>
  );
};

export default Refresh;
