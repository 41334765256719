import { DocumentTemplateCustomizationSettingDto } from '@/apis/einvoice/interfaces';
import { faImage, faSignature, faStamp } from '@fortawesome/pro-light-svg-icons';
import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Tabs } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import CommonImageCropper from '../CommonImageCropper';
const { TabPane } = Tabs;

const General = ({
  postLogo,
  getLogo,
  deleteLogo,
  getSignature,
  getStamp,
  deleteStamp,
  deleteSignature,
  postSignature,
  postStamp,
  id,
  documentTemplateCustomizationSetting,
  onChange,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  MainTab,
  onRemoteImageChanged,
  editSignature
}: {
  postLogo;
  getLogo;
  deleteLogo;
  deleteSignature;
  deleteStamp;
  postStamp;
  getStamp;
  postSignature;
  getSignature;
  id?: string;
  documentTemplateCustomizationSetting?: DocumentTemplateCustomizationSettingDto;
  onChange: (data: any) => void;
  MainTab: any;
  onRemoteImageChanged: (process: 'post' | 'delete') => void;
  editSignature: boolean;
}) => {
  return (
    <div className="card-container p-0" style={{ marginTop: 10 }}>
      <Tabs defaultActiveKey={editSignature ? 'company-signature' : 'mainTab'} size={'middle'} type="card">
        <TabPane
          style={{}}
          tab={
            <>
              <FontAwesomeIcon icon={faCog} className="mr-2" />
              Genel Tanımlar
            </>
          }
          key="mainTab"
        >
          <MainTab onChange={onChange} documentTemplateCustomizationSetting={documentTemplateCustomizationSetting} />
        </TabPane>

        <TabPane
          style={{}}
          tab={
            <>
              <FontAwesomeIcon icon={faImage} className="mr-2" />
              {intl.get('FIRMA_LOGOSU')}
            </>
          }
          key="userInfo"
        >
          <Alert style={{ marginBottom: '10px' }} message={intl.get('FIRMA_LOGOSU_ACIKLAMA')} type="info" showIcon />
          <CommonImageCropper id={id || ''} title={'Firma Logosu'} get={getLogo} post={postLogo} deleteImage={deleteLogo} />
        </TabPane>

        <TabPane
          tab={
            <>
              <FontAwesomeIcon icon={faSignature} className="mr-2" />
              {intl.get('YETKILI_IMZA')}
            </>
          }
          key="company-signature"
        >
          <Alert style={{ marginBottom: '10px' }} message={intl.get('YETKILI_IMZA_ACIKLAMA')} type="info" showIcon />
          <CommonImageCropper
            id={id || ''}
            title={'Yetkili İmzası'}
            get={getSignature}
            post={postSignature}
            onRemoteImageChanged={onRemoteImageChanged}
            deleteImage={deleteSignature}
            croppedImageHeight={130}
            croppedImageWidth={90}
          />
        </TabPane>

        <TabPane
          tab={
            <>
              <FontAwesomeIcon icon={faStamp} className="mr-2" />
              {intl.get('FIRMA_KASE')}
            </>
          }
          key="company-seal"
        >
          <Alert style={{ marginBottom: '10px' }} message={intl.get('FIRMA_KASE_ACIKLAMA')} type="info" showIcon />
          <CommonImageCropper
            id={id || ''}
            title={'Firma Kaşesi'}
            get={getStamp}
            post={postStamp}
            deleteImage={deleteStamp}
            croppedImageHeight={130}
            croppedImageWidth={90}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default General;
