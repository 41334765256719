import { XMLElement } from '@/xmldom-decorators';
import { CodeType, IDType } from '.';
import { NS_CAC, NS_CBC } from '../constants';
import { BranchType } from './BranchType';

/*
  27.04.2021 : Fully completed
*/
export class FinancialAccountType {
  @XMLElement({ types: [{ name: 'ID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  id?: IDType;

  @XMLElement({ types: [{ name: 'CurrencyCode', namespaceUri: NS_CBC, itemType: () => CodeType }] })
  currencyCode?: CodeType;

  @XMLElement({ types: [{ name: 'PaymentNote', namespaceUri: NS_CBC, itemType: () => String }] })
  paymentNote?: string;

  @XMLElement({ types: [{ name: 'FinancialInstitutionBranch', namespaceUri: NS_CAC, itemType: () => BranchType }] })
  financialInstitutionBranch?: BranchType;
}
