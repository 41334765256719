//TODO Excel raporu hazırla Api ucu eklenecek.
import { ExcelReportTemplate, ExcelReportTemplatePaginationResult } from '@/apis/earchive/interfaces';
import {
  V1OutgoingReportmoduleReportsPostRequest,
  V1OutgoingReportmoduleTemplatesGetRequest,
  V1OutgoingReportmoduleTemplatesIdDeleteRequest,
  V1OutgoingReportmoduleTemplatesIdGetRequest,
  V1OutgoingReportmoduleTemplatesIdPutRequest,
  V1OutgoingReportmoduleTemplatesPostRequest
} from '@/apis/einvoice';
import { ExcelReportQueue } from '@/apis/einvoice/interfaces';
import { Breadcrumbs, InfoToastify, RootSpinner } from '@/components';
import { HasRoleAndNotifiy } from '@/components/Auth';
import { New, ProcessDropdownButton, Search } from '@/components/Buttons';
import { Delete } from '@/components/DropdownItems';
import LocalPagination from '@/components/Pagination/LocalPagination';
import SelectableBaseTable, { CellRendererProps } from '@/components/SelectableBaseTable';
import { PageStyle } from '@/constants/page';
import { useApiInConfirmation, useGetPageData } from '@/hooks';
import { faFileSpreadsheet, faPencilAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Form, Input, Menu, Row } from 'antd';
import { AxiosPromise } from 'axios';
import moment from 'moment';
import React, { memo, useState } from 'react';
import { Helmet } from 'react-helmet';
import intl from 'react-intl-universal';
import { RouteComponentProps } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';
import CreateExcelReportModal from './CreateExcelReportModal';
import UpsertModal from './UpsertModal';

type TableRowProps = CellRendererProps<ExcelReportTemplate, Array<ExcelReportTemplate>>;

export const ExcelTemplates = memo(
  ({
    title,
    getTemplates,

    props,
    deleteFn,
    postExcelReport,
    putTemplate,
    postTemplate,
    getExcelColumns,
    getTemplatesById,
    reportKey,
    templatesPostPutRole
  }: {
    title: string;
    getTemplates: (requestParameters: V1OutgoingReportmoduleTemplatesGetRequest) => AxiosPromise<ExcelReportTemplatePaginationResult>;
    props: RouteComponentProps;
    deleteFn: (requestParameters: V1OutgoingReportmoduleTemplatesIdDeleteRequest) => AxiosPromise<void>;
    postExcelReport: (requestParameters: V1OutgoingReportmoduleReportsPostRequest) => AxiosPromise<ExcelReportQueue>;

    putTemplate: (requestParameters: V1OutgoingReportmoduleTemplatesIdPutRequest) => AxiosPromise<ExcelReportTemplate>;
    postTemplate: (requestParameters: V1OutgoingReportmoduleTemplatesPostRequest) => AxiosPromise<ExcelReportTemplate>;
    getTemplatesById: (requestParameters: V1OutgoingReportmoduleTemplatesIdGetRequest) => AxiosPromise<ExcelReportTemplate>;
    getExcelColumns: () => AxiosPromise<Array<string>>;
    reportKey: string;
    templatesPostPutRole: string;
  }) => {
    const [requestParameter, setRequestParameter] = useState<V1OutgoingReportmoduleTemplatesGetRequest>({
      query: undefined,
      page: 1,
      pageSize: 10,
      sort: 'CreatedAt desc'
    });

    const templateDelete = useApiInConfirmation<V1OutgoingReportmoduleTemplatesIdDeleteRequest>({
      asyncFunction: deleteFn
    });

    const { execute, loading, data, error } = useGetPageData<ExcelReportTemplatePaginationResult>(getTemplates, requestParameter, true);

    const [createExcelModalState, setCreateExcelModalState] = useState<{ visible: boolean; templateId?: string }>({ visible: false, templateId: undefined });

    const [upsertTemplateModalState, setUpsertTemplateModalState] = useState<{ visible: boolean; templateId?: string }>({
      visible: false,
      templateId: undefined
    });

    return (
      <RootSpinner loading={loading}>
        {createExcelModalState.visible && (
          <CreateExcelReportModal
            post={postExcelReport}
            modalState={createExcelModalState}
            closeModal={() => setCreateExcelModalState({ visible: false, templateId: undefined })}
          />
        )}

        {upsertTemplateModalState.visible && (
          <UpsertModal
            refresh={execute}
            put={putTemplate}
            post={postTemplate}
            getExcelColumns={getExcelColumns}
            getTemplatesById={getTemplatesById}
            reportKey={reportKey}
            modalState={upsertTemplateModalState}
            closeModal={() => setUpsertTemplateModalState({ visible: false, templateId: undefined })}
          />
        )}

        <Helmet title={title} />
        <Card className="h-100">
          <CardHeader className={PageStyle.card__header}>
            <Breadcrumbs />
            <div>
              <New onClick={() => HasRoleAndNotifiy(templatesPostPutRole) && setUpsertTemplateModalState({ templateId: undefined, visible: true })} />{' '}
              <Search onClick={execute} />
            </div>
          </CardHeader>
          <CardBody className={PageStyle.virtualized__card__body}>
            <Form layout="vertical">
              <Row>
                <Col sm={24} xs={24}>
                  <Form.Item label={intl.get('ARANACAK_KELIME')}>
                    <Input
                      defaultValue={requestParameter?.query || ''}
                      onChange={(input) => setRequestParameter({ ...requestParameter, query: input.target.value })}
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                          execute();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Row className="flex-fill">
              <SelectableBaseTable
                fixed
                pageData={data}
                error={error}
                data={(data && data.data) || []}
                rowKey="id"
                notSelectable
                requestParameters={requestParameter}
                columns={[
                  {
                    key: `title`,
                    dataKey: `title`,
                    title: intl.get('BASLIK'),
                    width: 150,
                    minWidth: 150,
                    className: 'base-table-flex',
                    headerClassName: 'base-table-flex'
                  },
                  {
                    key: `createdAt`,
                    minWidth: 150,
                    title: intl.get('OLUSTURMA_TARIHI'),
                    width: 150,
                    cellRenderer: ({ rowData }: TableRowProps) => {
                      return <b>{moment(rowData.createdAt).format('DD.MM.YYYY HH:mm')}</b>;
                    }
                  },
                  {
                    key: `process`,
                    title: intl.get('ISLEMLER'),
                    minWidth: 75,
                    width: 75,
                    align: 'center',
                    cellRenderer: ({
                      rowData,
                      container: {
                        props: { pageData }
                      }
                    }: TableRowProps) => {
                      return (
                        <ProcessDropdownButton
                          isRow
                          menu={
                            <Menu>
                              <Menu.Item onClick={() => setCreateExcelModalState({ visible: true, templateId: rowData.id })}>
                                <FontAwesomeIcon className="mr-2" icon={faFileSpreadsheet} />
                                {'Excel Raporu Hazırla'}
                              </Menu.Item>
                              <Menu.Divider />
                              <Menu.Item
                                onClick={() =>
                                  HasRoleAndNotifiy(templatesPostPutRole) && setUpsertTemplateModalState({ templateId: rowData.id, visible: true })
                                }
                              >
                                <FontAwesomeIcon className="mr-2" icon={faPencilAlt} />
                                {intl.get('DUZENLE')}
                              </Menu.Item>
                              <Menu.Divider />
                              <Delete
                                onClick={() =>
                                  HasRoleAndNotifiy(templatesPostPutRole) &&
                                  templateDelete.confirm(
                                    { id: rowData.id },
                                    {
                                      content: intl.getHTML('SABLON_SILINECEKTIR_DEVAM_EDILSIN_MI', {
                                        title: rowData.title
                                      }),
                                      title: `Şablon Sil - ${rowData.id}`,
                                      successCallback: () => {
                                        execute();
                                        InfoToastify(intl.getHTML('SABLON_SILINDI', { title: rowData.title }));
                                      }
                                    }
                                  )
                                }
                              />
                            </Menu>
                          }
                        />
                      );
                    }
                  }
                ]}
              />
            </Row>
          </CardBody>
          {data && data.data && data.totalCount > 10 && (
            <LocalPagination
              data={data}
              selectedCount={0}
              execute={execute}
              page={requestParameter.page}
              pageSize={requestParameter.pageSize}
              onPageChange={(page, pageSize) => {
                setRequestParameter({ ...requestParameter, page, pageSize });
              }}
            />
          )}
        </Card>
      </RootSpinner>
    );
  }
);

export default ExcelTemplates;
