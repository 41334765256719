/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/camelcase */
import antdData from 'antd/es/locale/tr_TR';

export const trTR = Object.assign(
  { antdData: antdData },
  {
    excel: {
      report: {
        column: {
          earchive: {
            id: 'ETTN',
            created_at: 'Oluşturulma Zamanı',
            issue_date: 'Belge Tarihi',
            issue_time: 'Düzenleme Saati',
            document_number: 'Belge Numarası',
            invoice_type_code: 'Fatura Tipi',
            document_currency_code: 'Para Birimi',
            pricing_exchange_calculation_rate: 'Döviz Kuru',
            line_count_numeric: 'Kalem Sayısı',
            line_extension_amount: 'Mal/Hizmet Tutarı',
            tax_exclusive_amount: 'Vergiler Hariç Tutar',
            tax_inclusive_amount: 'Vergiler Dahil Tutar',
            allowance_total_amount: 'İskonto Tutarı',
            charge_total_amount: 'Artırım Tutarı',
            tax_total_amount: 'Vergi Tutarı',
            payable_amount: 'Ödenecek Tutar',
            note: 'Notlar',
            is_printed: 'Yazdırıldı mı?',
            is_internet: 'İnternet Satış Faturası mı?',
            internet_info_web_site_uri: 'İSB - Web Site',
            internet_info_payment_method: 'İSB - Ödeme Yöntemi',
            internet_info_payment_method_description: 'İSB - Ödeme Aracısı',
            internet_info_payment_date: 'İSB - Ödeme Tarihi',
            internet_info_transporter_name: 'İSB - Taşıyıcı Firma Ünvan',
            internet_info_transporter_register_number: 'İSB - Taşıyıcı Firma VKN/TCKN',
            internet_info_transport_date: 'İSB - Taşıma Tarihi',
            is_canceled: 'İptal Edildi mi?',
            cancel_date: 'İptal Tarihi',
            report_division_status: 'Rapor Durumu',
            archive_document_status: 'Kayıt Durumu',
            send_type: 'Gönderim Tipi',
            transfer_type: 'Transfer Tipi',
            succed_mail_count: 'Başarılı Gönderilen Mail',
            readed_mail_count: 'Okunan Mail',
            failed_mail_count: 'Başarısız Mail',
            succed_sms_count: 'Başarılı Gönderilen SMS',
            readed_sms_count: 'Okunan SMS',
            failed_sms_count: 'Başarısız SMS',
            party_identification: 'Alıcı VKN/TCKN',
            party_name: 'Alıcı Ünvan',
            first_name: 'Alıcı Ad',
            family_name: 'Alıcı Soyad',
            party_tax_scheme_name: 'Alıcı Vergi Dairesi',
            electronic_mail: 'Alıcı Email',
            telephone: 'Alıcı Telefon',
            city_name: 'Alıcı Şehir',
            despatch_document_reference_id_1: 'İrsaliye No - 1',
            despatch_document_reference_id_2: 'İrsaliye No - 2',
            despatch_document_reference_id_3: 'İrsaliye No - 3',
            despatch_document_issue_date_1: 'İrsaliye Tarih - 1',
            despatch_document_issue_date_2: 'İrsaliye Tarih - 2',
            despatch_document_issue_date_3: 'İrsaliye Tarih - 3',
            total_tax_amount_0015_0: '%0 KDV Tutar',
            total_taxable_amount_0015_0: '%0 KDV Matrah',
            total_tax_amount_0015_1: '%1 KDV Tutar',
            total_taxable_amount_0015_1: '%1 KDV Matrah',
            total_tax_amount_0015_8: '%8 KDV Tutar',
            total_taxable_amount_0015_8: '%8 KDV Matrah',

            total_tax_amount_0015_10: '%10 KDV Tutar',
            total_taxable_amount_0015_10: '%10 KDV Matrah',

            total_tax_amount_0015_18: '%18 KDV Tutar',
            total_taxable_amount_0015_18: '%18 KDV Matrah',

            total_tax_amount_0015_20: '%20 KDV Tutar',
            total_taxable_amount_0015_20: '%20 KDV Matrah',

            total_tax_amount_0059_02: '%2 Konaklama Vergisi Tutar',
            total_taxable_amount_0059_02: '%2 Konaklama Vergisi Matrah',

            total_tax_amount_9015_20: '%20 KDV Tevkifat Tutar',
            total_taxable_amount_9015_20: '%20 KDV Matrah',
            total_tax_amount_9015_30: '%30 KDV Tevkifat Tutar',
            total_taxable_amount_9015_30: '%30 KDV Matrah',
            total_tax_amount_9015_40: '%40 KDV Tevkifat Tutar',
            total_taxable_amount_9015_40: '%40 KDV Matrah',
            total_tax_amount_9015_50: '%50 KDV Tevkifat Tutar',
            total_taxable_amount_9015_50: '%50 KDV Matrah',
            total_tax_amount_9015_70: '%70 KDV Tevkifat Tutar',
            total_taxable_amount_9015_70: '%70 KDV Matrah',
            total_tax_amount_9015_90: '%90 KDV Tevkifat Tutar',
            total_taxable_amount_9015_90: '%90 KDV Matrah'
          },
          emm: {
            id: 'ETTN',
            created_at: 'Oluşturulma Zamanı',
            issue_date: 'Belge Tarihi',
            issue_time: 'Düzenleme Saati',
            document_number: 'Belge Numarası',
            document_currency_code: 'Para Birimi',
            pricing_exchange_calculation_rate: 'Döviz Kuru',
            line_count_numeric: 'Kalem Sayısı',
            line_extension_amount: 'Mal/Hizmet Tutarı',
            tax_exclusive_amount: 'Vergiler Hariç Tutar',
            tax_inclusive_amount: 'Vergiler Dahil Tutar',
            allowance_total_amount: 'İskonto Tutarı',
            charge_total_amount: 'Artırım Tutarı',
            tax_total_amount: 'Vergi Tutarı',
            payable_amount: 'Ödenecek Tutar',
            note: 'Notlar',
            is_printed: 'Yazdırıldı mı?',
            is_canceled: 'İptal Edildi mi?',
            cancel_date: 'İptal Tarihi',
            archive_document_status: 'Kayıt Durumu',
            report_division_status: 'Rapor Durumu',
            transfer_type: 'Transfer Tipi',
            succed_mail_count: 'Başarılı Gönderilen Mail',
            readed_mail_count: 'Okunan Mail',
            failed_mail_count: 'Başarısız Mail',
            succed_sms_count: 'Başarılı Gönderilen SMS',
            readed_sms_count: 'Okunan SMS',
            failed_sms_count: 'Başarısız SMS',
            party_identification: 'Alıcı VKN/TCKN',
            party_name: 'Alıcı Ünvan',
            first_name: 'Alıcı Ad',
            family_name: 'Alıcı Soyad',
            party_tax_scheme_name: 'Alıcı Vergi Dairesi',
            electronic_mail: 'Alıcı Email',
            telephone: 'Alıcı Telefon',
            city_name: 'Alıcı Şehir'
          },
          esmm: {
            id: 'ETTN',
            created_at: 'Oluşturulma Zamanı',
            issue_date: 'Belge Tarihi',
            issue_time: 'Düzenleme Saati',
            document_number: 'Belge Numarası',
            document_currency_code: 'Para Birimi',
            pricing_exchange_calculation_rate: 'Döviz Kuru',
            line_count_numeric: 'Kalem Sayısı',
            line_extension_amount: 'Mal/Hizmet Tutarı',
            tax_exclusive_amount: 'Vergiler Hariç Tutar',
            tax_inclusive_amount: 'Vergiler Dahil Tutar',
            allowance_total_amount: 'İskonto Tutarı',
            charge_total_amount: 'Artırım Tutarı',
            tax_total_amount: 'Vergi Tutarı',
            payable_amount: 'Ödenecek Tutar',
            note: 'Notlar',
            is_printed: 'Yazdırıldı mı?',
            is_canceled: 'İptal Edildi mi?',
            cancel_date: 'İptal Tarihi',
            send_type: 'Gönderim Tipi',
            archive_document_status: 'Kayıt Durumu',
            report_division_status: 'Rapor Durumu',
            transfer_type: 'Transfer Tipi',
            succed_mail_count: 'Başarılı Gönderilen Mail',
            readed_mail_count: 'Okunan Mail',
            failed_mail_count: 'Başarısız Mail',
            succed_sms_count: 'Başarılı Gönderilen SMS',
            readed_sms_count: 'Okunan SMS',
            failed_sms_count: 'Başarısız SMS',
            party_identification: 'Alıcı VKN/TCKN',
            party_name: 'Alıcı Ünvan',
            first_name: 'Alıcı Ad',
            family_name: 'Alıcı Soyad',
            party_tax_scheme_name: 'Alıcı Vergi Dairesi',
            electronic_mail: 'Alıcı Email',
            telephone: 'Alıcı Telefon',
            city_name: 'Alıcı Şehir',

            total_tax_amount_0003: 'G.V. Stopaj Toplamı',
            total_tax_amount_0015: 'K.D.V. Toplamı',
            total_tax_amount_9015: 'K.D.V. Tevkifat Toplamı'
          },

          incominginvoice: {
            id: 'ETTN',
            created_at: 'Oluşturulma Zamanı',
            issue_date: 'Belge Tarihi',
            issue_time: 'Düzenleme Saati',
            document_number: 'Belge Numarası',
            invoice_type_code: 'Fatura Tipi',
            profile_id: 'Fatura Senaryosu',
            document_currency_code: 'Para Birimi',
            pricing_exchange_calculation_rate: 'Döviz Kuru',
            line_count_numeric: 'Kalem Sayısı',
            line_extension_amount: 'Mal/Hizmet Tutarı',
            tax_exclusive_amount: 'Vergiler Hariç Tutar',
            tax_inclusive_amount: 'Vergiler Dahil Tutar',
            allowance_total_amount: 'İskonto Tutarı',
            charge_total_amount: 'Artırım Tutarı',
            tax_total_amount: 'Vergi Tutarı',
            payable_amount: 'Ödenecek Tutar',
            note: 'Notlar',
            is_printed: 'Yazdırıldı mı?',
            is_read: 'Okundu mu?',
            is_transferred: 'ERP Transfer Durumu?',
            party_identification: 'Gönderici VKN/TCKN',
            party_name: 'Gönderici Ünvan',
            first_name: 'Gönderici Ad',
            family_name: 'Gönderici Soyad',
            party_tax_scheme_name: 'Gönderici Vergi Dairesi',
            sender_identifier: 'Gönderici Etiket',
            receiver_identifier: 'Alıcı Etiket',
            envelope_code: 'Zarf Durum Kodu',
            envelope_description: 'Zarf Durum Açıklaması',
            envelope_id: 'Zarf Numarası',
            despatch_document_reference_id_1: 'İrsaliye No - 1',
            despatch_document_reference_id_2: 'İrsaliye No - 2',
            despatch_document_reference_id_3: 'İrsaliye No - 3',
            despatch_document_issue_date_1: 'İrsaliye Tarih - 1',
            despatch_document_issue_date_2: 'İrsaliye Tarih - 2',
            despatch_document_issue_date_3: 'İrsaliye Tarih - 3',
            outgoing_answer_document_answer: 'Fatura Cevabı',
            outgoing_answer_document_answer_note: 'Fatura Cevap Notu',
            total_tax_amount_0015_0: '%0 KDV Tutar',
            total_taxable_amount_0015_0: '%0 KDV Matrah',
            total_tax_amount_0015_1: '%1 KDV Tutar',
            total_taxable_amount_0015_1: '%1 KDV Matrah',
            total_tax_amount_0015_8: '%8 KDV Tutar',
            total_taxable_amount_0015_8: '%8 KDV Matrah',

            total_tax_amount_0015_10: '%10 KDV Tutar',
            total_taxable_amount_0015_10: '%10 KDV Matrah',

            total_tax_amount_0015_18: '%18 KDV Tutar',
            total_taxable_amount_0015_18: '%18 KDV Matrah',

            total_tax_amount_0015_20: '%20 KDV Tutar',
            total_taxable_amount_0015_20: '%20 KDV Matrah',

            total_tax_amount_0059_02: '%2 Konaklama Vergisi Tutar',
            total_taxable_amount_0059_02: '%2 Konaklama Vergisi Matrah',

            total_tax_amount_9015_20: '%20 KDV Tevkifat Tutar',
            total_taxable_amount_9015_20: '%20 KDV Matrah',
            total_tax_amount_9015_30: '%30 KDV Tevkifat Tutar',
            total_taxable_amount_9015_30: '%30 KDV Matrah',
            total_tax_amount_9015_40: '%40 KDV Tevkifat Tutar',
            total_taxable_amount_9015_40: '%40 KDV Matrah',
            total_tax_amount_9015_50: '%50 KDV Tevkifat Tutar',
            total_taxable_amount_9015_50: '%50 KDV Matrah',
            total_tax_amount_9015_70: '%70 KDV Tevkifat Tutar',
            total_taxable_amount_9015_70: '%70 KDV Matrah',
            total_tax_amount_9015_90: '%90 KDV Tevkifat Tutar',
            total_taxable_amount_9015_90: '%90 KDV Matrah'
          },
          outgoinginvoice: {
            id: 'ETTN',
            created_at: 'Oluşturulma Zamanı',
            issue_date: 'Belge Tarihi',
            issue_time: 'Düzenleme Saati',
            document_number: 'Belge Numarası',
            invoice_type_code: 'Fatura Tipi',
            profile_id: 'Fatura Senaryosu',
            document_currency_code: 'Para Birimi',
            pricing_exchange_calculation_rate: 'Döviz Kuru',
            line_count_numeric: 'Kalem Sayısı',
            line_extension_amount: 'Mal/Hizmet Tutarı',
            tax_exclusive_amount: 'Vergiler Hariç Tutar',
            tax_inclusive_amount: 'Vergiler Dahil Tutar',
            allowance_total_amount: 'İskonto Tutarı',
            charge_total_amount: 'Artırım Tutarı',
            tax_total_amount: 'Vergi Tutarı',
            payable_amount: 'Ödenecek Tutar',
            note: 'Notlar',
            is_printed: 'Yazdırıldı mı?',
            record_status: 'Durum',
            outgoing_status: 'Zarf Durumu',
            transfer_type: 'Transfer Tipi',
            sender_identifier: 'Gönderici Etiket',
            receiver_identifier: 'Alıcı Etiket',
            party_identification: 'Alıcı VKN/TCKN',
            party_name: 'Alıcı Ünvan',
            first_name: 'Alıcı Ad',
            family_name: 'Alıcı Soyad',
            electronic_mail: 'Alıcı Email',
            telephone: 'Alıcı Telefon',
            city_name: 'Alıcı Şehir',
            party_tax_scheme_name: 'Alıcı Vergi Dairesi',
            envelope_code: 'Zarf Durum Kodu',
            envelope_description: 'Zarf Durum Açıklaması',
            envelope_id: 'Zarf Numarası',
            despatch_document_reference_id_1: 'İrsaliye No - 1',
            despatch_document_reference_id_2: 'İrsaliye No - 2',
            despatch_document_reference_id_3: 'İrsaliye No - 3',
            despatch_document_issue_date_1: 'İrsaliye Tarih - 1',
            despatch_document_issue_date_2: 'İrsaliye Tarih - 2',
            despatch_document_issue_date_3: 'İrsaliye Tarih - 3',
            incoming_answer_document_answer: 'Fatura Cevabı',
            incoming_answer_document_answer_note: 'Fatura Cevap Notu',
            total_tax_amount_0015_0: '%0 KDV Tutar',
            total_taxable_amount_0015_0: '%0 KDV Matrah',
            total_tax_amount_0015_1: '%1 KDV Tutar',
            total_taxable_amount_0015_1: '%1 KDV Matrah',
            total_tax_amount_0015_8: '%8 KDV Tutar',
            total_taxable_amount_0015_8: '%8 KDV Matrah',

            total_tax_amount_0015_10: '%10 KDV Tutar',
            total_taxable_amount_0015_10: '%10 KDV Matrah',

            total_tax_amount_0015_18: '%18 KDV Tutar',
            total_taxable_amount_0015_18: '%18 KDV Matrah',

            total_tax_amount_0015_20: '%20 KDV Tutar',
            total_taxable_amount_0015_20: '%20 KDV Matrah',

            total_tax_amount_0059_02: '%2 Konaklama Vergisi Tutar',
            total_taxable_amount_0059_02: '%2 Konaklama Vergisi Matrah',

            total_tax_amount_9015_20: '%20 KDV Tevkifat Tutar',
            total_taxable_amount_9015_20: '%20 KDV Matrah',
            total_tax_amount_9015_30: '%30 KDV Tevkifat Tutar',
            total_taxable_amount_9015_30: '%30 KDV Matrah',
            total_tax_amount_9015_40: '%40 KDV Tevkifat Tutar',
            total_taxable_amount_9015_40: '%40 KDV Matrah',
            total_tax_amount_9015_50: '%50 KDV Tevkifat Tutar',
            total_taxable_amount_9015_50: '%50 KDV Matrah',
            total_tax_amount_9015_70: '%70 KDV Tevkifat Tutar',
            total_taxable_amount_9015_70: '%70 KDV Matrah',
            total_tax_amount_9015_90: '%90 KDV Tevkifat Tutar',
            total_taxable_amount_9015_90: '%90 KDV Matrah'
          },

          incomingdespatch: {
            id: 'ETTN',
            created_at: 'Oluşturulma Zamanı',
            issue_date: 'Belge Tarihi',
            document_number: 'Belge Numarası',
            despatch_advice_type_code: 'İrsaliye Tipi',
            profile_id: 'İrsaliye Senaryosu',
            line_count_numeric: 'Kalem Sayısı',
            value_amount_currency_id: 'Para Birimi',
            note: 'Notlar',
            is_printed: 'Yazdırıldı mı?',
            is_read: 'Okundu mu?',
            is_transferred: 'ERP Transfer Durumu?',
            party_identification: 'Gönderici VKN/TCKN',
            party_name: 'Gönderici Ünvan',
            first_name: 'Gönderici Ad',
            family_name: 'Gönderici Soyad',
            party_tax_scheme_name: 'Gönderici Vergi Dairesi',
            sender_identifier: 'Gönderici Etiket',
            receiver_identifier: 'Alıcı Etiket',
            envelope_code: 'Zarf Durum Kodu',
            envelope_description: 'Zarf Durum Açıklaması',
            envelope_id: 'Zarf Numarası'
          },
          outgoingdespatch: {
            id: 'ETTN',
            created_at: 'Oluşturulma Zamanı',
            issue_date: 'Belge Tarihi',
            issue_time: 'Düzenleme Saati',
            document_number: 'Belge Numarası',
            despatch_advice_type_code: 'İrsaliye Tipi',
            profile_id: 'İrsaliye Senaryosu',
            line_count_numeric: 'Kalem Sayısı',
            value_amount_currency_id: 'Para Birimi',
            value_amount: 'Tutar',
            note: 'Notlar',
            is_printed: 'Yazdırıldı mı?',
            transfer_type: 'Transfer Tipi',
            record_status: 'Durum',
            outgoing_status: 'Zarf Durumu',
            sender_identifier: 'Gönderici Etiket',
            receiver_identifier: 'Alıcı Etiket',
            party_identification: 'Alıcı VKN/TCKN',
            party_name: 'Alıcı Ünvan',
            first_name: 'Alıcı Ad',
            family_name: 'Alıcı Soyad',
            electronic_mail: 'Alıcı Email',
            telephone: 'Alıcı Telefon',
            city_name: 'Alıcı Şehir',
            party_tax_scheme_name: 'Alıcı Vergi Dairesi',
            is_virtual: 'Sanal Alıcı mı?',
            envelope_code: 'Zarf Durum Kodu',
            envelope_description: 'Zarf Durum Açıklaması',
            envelope_id: 'Zarf Numarası'
          }
        }
      }
    },
    'topBar.issuesHistory': 'Histoire des problèmes',
    'topBar.typeToSearch': 'Chercher...',
    'topBar.actions': 'Actes',
    'topBar.status': 'Statut',
    'topBar.profileMenu.hello': 'Merhaba',
    'topBar.profileMenu.billingPlan': 'Plan de facturation',
    'topBar.profileMenu.role': 'Rôle',
    'topBar.profileMenu.email': 'Email',
    'topBar.profileMenu.phone': 'Téléphone',
    'topBar.profileMenu.editProfile': 'Profili Düzenle',
    'topBar.profileMenu.logout': 'Oturumu Kapat',
    /*
     * MENU
     */
    //#region "MENU"
    einvoice: 'E-Fatura',
    'einvoice-outgoing': 'Giden Kutusu',
    'einvoice-outgoing-list': 'Faturalar',
    'einvoice-outgoing-archive': 'Arşiv',
    'einvoice-outgoing-excel': 'Rapor Modülü',
    'einvoice-outgoing-excel-reports': 'Rapor Listesi',
    'einvoice-outgoing-excel-templates': 'Şablonlar',
    'einvoice-incoming': 'Gelen Kutusu',
    'einvoice-incoming-list': 'Faturalar',
    'einvoice-incoming-archive': 'Arşiv',
    'einvoice-incoming-excel': 'Rapor Modülü',
    'einvoice-incoming-excel-reports': 'Rapor Listesi',
    'einvoice-incoming-excel-templates': 'Şablonlar',
    'einvoice-definations': 'Tanımlamalar',
    'einvoice-notifications': 'Bildirim Ayarları',
    'einvoice-notifications-incomingnotification': 'Gelen Fatura Bildirim',
    'einvoice-notifications-outgoingnotification': 'Giden Fatura Bildirim',
    'einvoice-notifications-invoicestaticnotification': 'Olay Bazlı Bildirimler',
    'einvoice-tags': 'Etiketler',
    'einvoice-definations-series': 'Fatura Serileri',
    'einvoice-definations-templates': 'Fatura Tasarımları',
    'einvoice-definations-templates-dynamic': 'Özelleştir',
    'einvoice-definations-templates-xslts': 'XSLT Yönetimi',
    'einvoice-definations-file__title': 'Dosya Adları',
    'einvoice-management-definitions': 'Tanımlamalar',
    'einvoice-upload': 'Belge Yükle',
    'einvoice-exinvoice': 'Eski Faturalar',
    'einvoice-exinvoice-incoming': 'Gelen Faturalar',
    'einvoice-exinvoice-outgoing': 'Giden Faturalar',
    'einvoice-exinvoice-queue': 'Dosya Kuyruğu',
    'einvoice-exinvoice-upload': 'Belge Yükle',

    edespatch: 'E-İrsaliye',
    'edespatch-processes': 'İrsaliye İşlemleri',
    'edespatch-processes-create': 'İrsaliye Oluştur',
    'edespatch-processes-draft': 'İrsaliye Listesi',
    'edespatch-outgoing': 'Giden Kutusu',
    'edespatch-outgoing-list': 'İrsaliyeler',
    'edespatch-outgoing-archive': 'Arşiv',
    'edespatch-outgoing-excel-reports': 'Rapor Listesi',
    'edespatch-outgoing-excel-templates': 'Şablonlar',
    'edespatch-outgoing-excel': 'Rapor Modülü',
    'edespatch-incoming': 'Gelen Kutusu',
    'edespatch-incoming-list': 'İrsaliyeler',
    'edespatch-incoming-archive': 'Arşiv',
    'edespatch-incoming-excel-reports': 'Rapor Listesi',
    'edespatch-incoming-excel-templates': 'Şablonlar',
    'edespatch-definations-templates': 'İrsaliye Tasarımları',
    'edespatch-incoming-excel': 'Rapor Modülü',
    'edespatch-definations': 'Tanımlamalar',
    'edespatch-notifications': 'Bildirim Ayarları',
    'edespatch-tags': 'Etiketler',
    'edespatch-definations-series': 'İrsaliye Serileri',
    'edespatch-definations-answer-series': 'Yanıt Serileri',
    'edespatch-definations-xslts': 'Şablonlar',
    'edespatch-definations-xslts-despatchadvice': 'İrsaliye Şablonları',
    'edespatch-definations-xslts-receiptadvice': 'Yanıt Şablonları',
    'edespatch-upload': 'Belge Yükle',
    'edespatch-notifications-incomingnotification': 'Gelen İrsaliye Bild.',
    'edespatch-notifications-outgoingnotification': 'Giden İrsaliye Bild.',
    'edespatch-notifications-despatchstaticnotification': 'Olay Bazlı Bildirimler',
    'edespatch-definations-mailing': 'Mail / Sms Ayarları',
    'edespatch-definations-file__title': 'Dosya Adları',

    earchive: 'E-Arşiv',
    'earchive-invoice': 'Giden Kutusu',
    'earchive-invoice-list': 'Faturalar',
    'earchive-invoice-cancel': 'İptal Faturalar',
    'earchive-invoice-excel': 'Rapor Modülü',
    'earchive-invoice-excel-reports': 'Rapor Listesi',
    'earchive-invoice-excel-templates': 'Şablonlar',
    'earchivedocuments-report': 'E-Arşiv Raporları',
    'earchivedocuments-report-list': 'Rapor Listesi ',
    'earchivedocuments-report-create': 'Rapor Oluştur ',

    'earchivedocuments-incoming': 'Gelen e-Arşiv Belgeleri',

    'earchive-definations': 'Tanımlamalar',
    'earchive-notifications': 'Bildirim Ayarları',
    'earchive-tags': 'Etiketler',
    'earchive-definations-series': 'Fatura Serileri',
    'earchive-definations-xslts': 'Şablonlar',
    'earchive-definations-templates': 'Fatura Tasarımları',
    'earchive-definations-file__title': 'Dosya Adları',
    'earchive-definations-mailing': 'Mail / Sms Ayarları',
    'earchive-upload': 'Belge Yükle',
    'earchive-exinvoice': 'Eski Faturalar',
    'earchive-exinvoice-list': 'Faturalar',
    'earchive-exinvoice-queue': 'Dosya Kuyruğu',
    'earchive-exinvoice-upload': 'Belge Yükle',

    companies: 'Firma Listesi',

    productservices: 'Ürün / Hizmet Yönetimi',

    esmm: 'E-Smm',
    'esmm-processes': 'Makbuz İşlemleri',
    'esmm-processes-create': 'Makbuz Oluştur',
    'esmm-processes-draft': 'Makbuz Listesi',
    'esmm-outbox': 'Giden Kutusu',
    'esmm-outbox-list': 'Makbuzlar',
    'esmm-outbox-cancel': 'İptal Makbuzlar',
    'esmm-outbox-excel': 'Rapor Modülü',
    'esmm-outbox-excel-reports': 'Rapor Listesi',
    'esmm-outbox-excel-templates': 'Şablonlar',
    'esmm-definations-templates': 'Makbuz Tasarımları',
    'esmm-definations': 'Tanımlamalar',
    'esmm-notifications': 'Bildirim Ayarları',
    'esmm-tags': 'Etiketler',
    'esmm-definations-series': 'Makbuz Serileri',
    'esmm-definations-xslts': 'Şablonlar',
    'esmm-definations-mailing': 'Mail / Sms Ayarları',
    'esmm-definations-file__title': 'Dosya Adları',
    'esmm-upload': 'Belge Yükle',

    emm: 'E-Mm',
    'emm-processes': 'Makbuz İşlemleri',
    'emm-processes-create': 'Makbuz Oluştur',
    'emm-processes-draft': 'Makbuz Listesi',
    'emm-outbox': 'Giden Kutusu',
    'emm-outbox-list': 'Makbuzlar',
    'emm-outbox-cancel': 'İptal Makbuzlar',
    'emm-outbox-excel': 'Rapor Modülü',
    'emm-outbox-excel-reports': 'Rapor Listesi',
    'emm-definations-templates': 'Makbuz Tasarımları',
    'emm-outbox-excel-templates': 'Şablonlar',
    'emm-definations': 'Tanımlamalar',
    'emm-notifications': 'Bildirim Ayarları',
    'emm-tags': 'Etiketler',
    'emm-definations-series': 'Makbuz Serileri',
    'emm-definations-xslts': 'Şablonlar',
    'emm-definations-mailing': 'Mail / Sms Ayarları',
    'emm-definations-file__title': 'Dosya Adları',
    'emm-upload': 'Belge Yükle',

    management: 'Yönetim Paneli',
    'management-companyinfo': 'Firma Bilgileri',
    'management-template': 'Tasarım Ayarları',
    'management-users': 'Kullanıcı Yönetimi',
    'management-accountants': 'Mali Müşavir Yönetimi',
    'management-marketplace': 'Pazar Yeri Entegrasyonu',
    'management-users-list': 'Kullanıcılar',
    'management-users-new': 'Yeni Kullanıcı',
    'management-apis': 'API Tanımları',
    'management-apis-list': 'API Listesi',
    'management-apis-new': 'Yeni API Anahtarı',

    'management-connector': 'Konnektör Tanımı',
    'management-connector-list': 'Konnektör Bağlantıları',
    'management-connector-queryeditor': 'Sorgu Editörü',
    connector: 'ERP Konnektör',
    'connector-unsentinvoices': "ERP'den Fatura Oluştur",
    'connector-unsentdespatches': "ERP'den İrsaliye Oluştur",

    'market-place-orders': 'Pazar Yeri Siparişleri',
    'market-place-order': 'Alınan Siparişler',
    'market-place-invoices': 'Oluşturulan Faturalar',

    'management-documentarchives': 'Dosya Arşivleri',

    // NES
    'nes-management': 'NES Yönetim Paneli',
    'nes-management-statistics': 'İstatistikler',
    'nes-management-statistics-general': 'Genel İstatistikler',
    'nes-management-statistics-einvoice': 'E-Fatura İstatistikleri',
    'nes-management-statistics-earchive': 'E-Arşiv İstatistikleri',
    'nes-management-statistics-esmm': 'E-Smm İstatistikleri',
    'nes-management-dealers': 'Bayi Listesi',
    'nes-management-autoinvoice': 'Otomatik Fatura',
    // Ayarlar
    settings: 'AYARLAR',
    home: 'Ana Sayfa',
    'invoice-processes': 'Fatura İşlemleri',
    'invoice-processes-create': 'Fatura Oluştur',
    'invoice-processes-create-general': 'Mal / Hizmet Faturası',
    'invoice-processes-create-export': 'İhracat Faturası',
    'invoice-processes-create-taxfree': 'Yolcu Beraber (TaxFree)',
    'invoice-processes-draft': 'Fatura Listesi',
    'invoice-processes-canceled': 'İptal Edilen',
    //#endregion "MENU"

    /*
     * SORGULAR
     */
    //#region "SORGULAR"
    ADRES_SILINECEK_DEVAM_EDILSIN_MI: 'Adres silinecek, devam edilsin mi?',
    ADRES_EKLENECEK_DEVAM_EDILSIN_MI: 'Adres eklenecek, devam edilsin mi?',
    ADRES_GUNCELLENECEK_DEVAM_EDILSIN_MI: 'Adres güncellenecek, devam edilsin mi?',

    ADRES_SILINECEKTIR_DEVAM_EDILSIN_MI: '<b>{title}</b>, Başlıklı adres silinecektir. <br/>İşleme devam edilsin mi?',
    ADRES_SILINDI: '<b>{title}</b>, Başlıklı adres silindi.',

    BILDIRIM_SILINECEKTIR_DEVAM_EDILSIN_MI: '<b>{title}</b>, Başlıklı bildirim silinecektir. <br/>İşleme devam edilsin mi?',
    BILDIRIM_SILINDI: '<b>{title}</b>, Başlıklı bildirim silindi.',

    KODA_AIT_KAYIT_DEVAM_EDILSIN_MI: '<b>{title}</b>, Başlıklı kayıt silinecektir. <br/>İşleme devam edilsin mi?',
    KAYIT_SILINDI: '<b>{title}</b>, Başlıklı kayıt silindi.',

    ADRES_VARSAYILAN_AYARLANACAKTIR_DEVAM_EDILSIN_MI: '<b>{title}</b>, Başlıklı adres varsayılan olarak ayarlanacaktır. <br/>İşleme devam edilsin mi?',
    ADRES_VARSAYILAN_AYARLANDI: '<b>{title}</b>, Başlıklı adres varsayılan ayarlandı',

    BASARI_ILE_SERI_GUNCELLENDI: 'Başarıyla serinin  numarası <b>{nextNumber}</b> numarasıyla güncellendi',
    DEVAM_EDILSIN_MI: 'Devam Edilsin Mi?',

    BELGE_ARSIVE_KALDIRILACAKTIR_DEVAM_EDILSIN_MI: '<b>{documentNumber}</b>, Numaralı belge arşive kaldırılacak. <br/>İşleme devam edilsin mi?',
    BASARI_ILE_ARSIVE_ALINDI: '<b>{documentNumber}</b>, Numaralı belge arşive alındı',

    BELGE_IPTAL_EDILECEKTIR_DEVAM_EDILSIN_MI: '<b>{documentNumber}</b>, Numaralı belge iptal edilecek. <br/>İşleme devam edilsin mi?',
    BASARI_ILE_IPTAL_EDILDI: '<b>{documentNumber}</b>, Numaralı belge iptal edildi',

    BELGE_ARSIVDEN_GERI_ALINACAKTIR_DEVAM_EDILSIN_MI:
      '<b>{documentNumber}</b>, Numaralı arşive alınmış belgeyi geri aldığınızda, faturaralar ekranına taşınacaktır. İşleme devam edilsin mi?',
    BASARI_ILE_ARSIVDEN_GERI_ALINDI: '<b>{documentNumber}</b>, Numaralı belge arşivden geri alınarak faturaralar ekranına taşındı.',

    BELGE_IPTALI_GERI_ALINACAKTIR_DEVAM_EDILSIN_MI:
      '<b>{documentNumber}</b>, Numaralı Belge için yapılan iptal işlemi geri alınacaktır.<br/> İşleme devam edilsin mi?',
    BASARI_ILE_IPTAL_ISLEMI_GERI_ALINDI: '<b>{documentNumber}</b>, Numaralı belgenin iptal işlemi geri alındı.',
    BELGE_OKUNDU_OLARAK_ISARETLENECEKTIR_DEVAM_EDILSIN_MI:
      '<b>{documentNumber}</b>, Numaralı belge okundu olarak işaretlenecektir. <br/>İşleme devam edilsin mi?',
    BELGE_OKUNDU_OLARAK_ISARETLENDI: '<b>{documentNumber}</b>, Numaralı belge <b style="font-style: italic">okundu</b> olarak işaretlendi',

    BELGE_OKUNMADI_OLARAK_ISARETLENECEKTIR_DEVAM_EDILSIN_MI:
      '<b>{documentNumber}</b>, Numaralı belge okunmadı olarak işaretlenecektir. <br/>İşleme devam edilsin mi?',
    BELGE_OKUNMADI_OLARAK_ISARETLENDI: '<b>{documentNumber}</b>, Numaralı belge <b style="font-style: italic">okunmadı</b> olarak işaretlendi',

    BELGE_X_EDILECEKTIR: '<b>{documentNumber}</b>, Numaralı belge {answer} edilecektir. <br/>İşleme devam edilsin mi?',
    BELGE_X_EDILDI: '<b>{documentNumber}</b>, Numaralı belge <b style="font-style: italic">{answer}</b> edildi',

    ILK_KAYIT_TARIHI: 'İlk Kayıt Tarihi',

    BELGE_TRANSFER_EDILDI_ISARETLENECEKTIR_DEVAM_EDILSIN_MI:
      '<b>{documentNumber}</b>, Numaralı belge transfer edildi olarak işaretlenecektir. <br/>İşleme devam edilsin mi?',
    BELGE_TRANSFER_EDILDI_OLARAK_ISARETLENDI: '<b>{documentNumber}</b>, Numaralı belge <b style="font-style: italic">transfer edildi</b> olarak işaretlendi',

    BELGE_TRANSFER_EDILMEDI_ISARETLENECEKTIR_DEVAM_EDILSIN_MI:
      '<b>{documentNumber}</b>, Numaralı belge transfer edilmedi olarak işaretlenecektir. <br/>İşleme devam edilsin mi?',
    BELGE_TRANSFER_EDILMEDI_OLARAK_ISARETLENDI:
      '<b>{documentNumber}</b>, Numaralı belge <b style="font-style: italic">transfer edilmedi</b> olarak işaretlendi',

    BELGE_SILINECEKTIR_DEVAM_EDILSIN_MI: '<b>{partyIdentification}</b>-<b>{name}</b>, İsimli Belge silinecektir. <br/>İşleme devam edilsin mi?',
    BELGE_SILINDI: 'Belge silindi<br/><b>{partyIdentification}</b>-<b>{name}</b>',

    EKLENEN_DOSYA_ZATEN_MEVCUT_TEKRAR_EKLENSIN_MI: 'Eklediğiniz <b>{file}</b> dosya zaten liste de mevcut, tekrar eklensin mi?',

    FIRMA_SILINECEKTIR_DEVAM_EDILSIN_MI: '<b>{partyIdentification}</b>-<b>{name}</b>, İsimli firma silinecektir. <br/>İşleme devam edilsin mi?',
    FIRMA_SILINDI: 'Firma silindi<br/><b>{partyIdentification}</b>-<b>{name}</b>',

    FIRMA_KAYITLI_ADRESLERI_ILE_BIRLIKTE_SILINECEK_DEVAM_EDILSIN_MI: 'Firma kayıtlı adresleri ile birlikte silinecek, devam edilsin mi?',
    IPTAL_EDILECEK_DEVAM_EDILSIN_MI: 'İptal edilecek! Devam edilsin mi?',

    MUSTERI_KAYDINIZ_YAPILACAK_DEVAM_EDILSIN_MI: 'Müşteri kaydı yapılacaktır, devam edilsin mi?',
    MUSTERI_KAYDINIZ_GUNCELLENECEK_DEVAM_EDILSIN_MI: 'Müşteri kaydı güncellenecektir, devam edilsin mi?',

    SUBE_PASIF_EDILECEKTIR_DEVAM_EDILSIN_MI: '<b>{code}</b>` lu şube pasif edilecektir. <br/>İşleme devam edilsin mi?',
    SUBE_PASIF_EDILDI: '<b>{code}</b>` lu şube pasif edildi',

    SUBE_VARSAYILAN_AYARLANACAKTIR_DEVAM_EDILSIN_MI: '<b>{code}</b>` lu şube varsayılan olarak ayarlanacaktır. <br/>İşleme devam edilsin mi?',
    SUBE_VARSAYILAN_AYARLANDI: '<b>{code}</b>` lu şube varsayılan ayarlandı',

    SUBE_AKTIF_EDILECEKTIR_DEVAM_EDILSIN_MI: '<b>{code}</b>` lu şube aktif edilecektir. <br/>İşleme devam edilsin mi?',
    SUBE_AKTIF_EDILDI: '<b>{code}</b>` lu şube aktif edildi',

    SUBE_SILINDI_OLARAK_ISARETLENECEKTIR_DEVAM_EDILSIN_MI: '<b>{code}</b>` lu şube silindi olarak işaretlenecektir. <br/>İşleme devam edilsin mi?',
    SUBE_SILINDI_OLARAK_ISARETLENDI: '<b>{code}</b>` lu şube silindi olarak işaretlendi',

    SERI_PASIF_EDILECEKTIR_DEVAM_EDILSIN_MI: '<b>{serie}</b>, Serisi pasif edilecektir. <br/>İşleme devam edilsin mi?',
    SERI_PASIF_EDILDI: '<b>{serie}</b>, Serisi pasif edildi',

    SERI_VARSAYILAN_AYARLANACAKTIR_DEVAM_EDILSIN_MI: '<b>{serie}</b>, Serisi varsayılan olarak ayarlanacaktır. <br/>İşleme devam edilsin mi?',
    SERI_VARSAYILAN_AYARLANDI: '<b>{serie}</b>, Serisi varsayılan ayarlandı',

    SABLON_VARSAYILAN_AYARLANACAKTIR_DEVAM_EDILSIN_MI: '<b>{title}</b>, Başlıklı şablon varsayılan olarak ayarlanacaktır. <br/>İşleme devam edilsin mi?',
    SABLON_VARSAYILAN_AYARLANDI: '<b>{title}</b>, Başlıklı şablon varsayılan ayarlandı',

    SERI_AKTIF_EDILECEKTIR_DEVAM_EDILSIN_MI: '<b>{serie}</b>, Serisi aktif edilecektir. <br/>İşleme devam edilsin mi?',
    SERI_AKTIF_EDILDI: '<b>{serie}</b>, Serisi aktif edildi',

    SERI_SILINDI_OLARAK_ISARETLENECEKTIR_DEVAM_EDILSIN_MI: '<b>{serie}</b>, Serisi silindi olarak işaretlenecektir. <br/>İşleme devam edilsin mi?',
    SERI_SILINDI_OLARAK_ISARETLENDI: '<b>{serie}</b>, Serisi silindi olarak işaretlendi',

    SERI_KALICI_OLARAK_SILINECEKTIR_DEVAM_EDILSIN_MI: '<b>{serie}</b>, Serisi kalıcı olarak silinecektir. <br/>İşleme devam edilsin mi?',
    SERI_KALICI_OLARAK_SILINDI: '<b>{serie}</b>, Serisi kalıcı olarak silindi',

    SECILI_OLAN_BELGELER_ARSIVE_KALDIRILACAKTIR_DEVAM_EDILSIN_MI: 'Seçili olan belgeler arşive kaldırılacak. <br/>İşleme devam edilsin mi?',
    SECILI_OLAN_URUNLER_SILINECEKTIR_DEVAM_EDILSIN_MI: 'Seçili olan ürünler silinecektir. <br/>İşleme devam edilsin mi?',
    SECILI_OLAN_BELGELER_BASARI_ILE_ARSIVE_ALINDI: 'Seçili olan belgeler arşive alındı',

    SECILI_OLAN_BELGELER_OKUNMADI_OLARAK_ISARETLENECEKTIR_DEVAM_EDILSIN_MI: 'Seçili olan belgeler okunmadı olarak işaretlenecek. <br/>İşleme devam edilsin mi?',
    SECILI_OLAN_BELGELER_OKUNMADI_OLARAK_ISARETLENDI: 'Seçili olan belgeler okunmadı olarak işaretlendi',

    SECILI_OLAN_BELGELER_TRANSFER_EDILMEDI_OLARAK_ISARETLENECEKTIR_DEVAM_EDILSIN_MI:
      'Seçili olan belgeler transfer edilmedi olarak işaretlenecek. <br/>İşleme devam edilsin mi?',
    SECILI_OLAN_BELGELER_TRANSFER_EDILMEDI_OLARAK_ISARETLENDI: 'Seçili olan belgeler transfer edilmedi olarak işaretlendi',

    SECILI_OLAN_BELGELER_TRANSFER_EDILDI_OLARAK_ISARETLENECEKTIR_DEVAM_EDILSIN_MI:
      'Seçili olan belgeler transfer edildi olarak işaretlenecek. <br/>İşleme devam edilsin mi?',
    SECILI_OLAN_BELGELER_TRANSFER_EDILDI_OLARAK_ISARETLENDI: 'Seçili olan belgeler trannsfer edildi olarak işaretlendi',

    SECILI_OLAN_BELGELER_OKUNDU_OLARAK_ISARETLENECEKTIR_DEVAM_EDILSIN_MI: 'Seçili olan belgeler okundu olarak işaretlenecek. <br/>İşleme devam edilsin mi?',
    SECILI_OLAN_BELGELER_OKUNDU_OLARAK_ISARETLENDI: 'Seçili olan belgeler okundu olarak işaretlendi',

    SECILI_OLAN_BELGELER_YAZDIRILDI_OLARAK_ISARETLENECEKTIR_DEVAM_EDILSIN_MI:
      'Seçili olan belgeler yazdırıldı olarak işaretlenecek. <br/>İşleme devam edilsin mi?',
    SECILI_OLAN_BELGELER_YAZDIRILDI_OLARAK_ISARETLENDI: 'Seçili olan belgeler yazdırıldı olarak işaretlendi',

    SECILI_OLAN_BELGELER_YAZDIRILMADI_OLARAK_ISARETLENECEKTIR_DEVAM_EDILSIN_MI:
      'Seçili olan belgeler yazdırılmadı olarak işaretlenecek. <br/>İşleme devam edilsin mi?',
    SECILI_OLAN_BELGELER_YAZDIRILMADI_OLARAK_ISARETLENDI: 'Seçili olan belgeler yazdırılmadı olarak işaretlendi',

    SECILI_OLAN_BELGELER_X_DEVAM_EDILSIN_MI: 'Seçili olan belgeler {x}. <br/>İşleme devam edilsin mi?',
    SECILI_OLAN_BELGELER_X: 'Seçili olan belgeler {x}.',

    SABLON_SILINECEKTIR_DEVAM_EDILSIN_MI: '<b>{title}</b>, Şablonu silinecektir. <br/>İşleme devam edilsin mi?',
    SABLON_SILINDI: '<b>{title}</b>, Şablonu silindi.',

    ETIKET_SILINECEKTIR_DEVAM_EDILSIN_MI: '<b>{title}</b>, Etiketi silinecektir. <br/>İşleme devam edilsin mi?',
    ETIKET_SILINDI: '<b>{title}</b>, Etiketi silindi.',

    //#endregion "SORGULAR"

    RAPOR_SUTUNU_SECMEK_ZORUNLU: 'Lütfen en az {columnLength} Sütun seçiniz.',

    ADLI_KULLANICI_E_FATURA_KAYITLI_DEGIL: '<b>{partyName}</b>, Adlı ünvan E-Fatura kayıtlı değildir.',
    ALICILAR: 'Alıcılar',
    ETIKET: 'Etiket',

    IPTAL: 'İptal',
    seri: 'seri',
    CODE: 'Kod',
    MAIL_DURUMU: 'Mail Durumu',
    BELGE_ADI: 'Belge adı',
    YUKLEME_DURUMU: 'Yükleme durumu',
    BELGEYI_SIL: 'Belgeyi iptal et',

    DOSYALAR_YUKLENDI: 'Dosyalar başarıyla yüklendi.',
    YUKLE: 'Yükle',
    DOSYALAR_YUKLENIRKEN_HATA_OLUSTU: 'Dosyalar yüklenirken bir sorun oluştu.Lütfen tekrar deneyin.',
    SERI_EKLENDI: '<b>{serie}</b>, Serisi eklendi',
    SUBE_EKLENDI: '<b>{code}</b>, Şube eklendi',
    SABLON_EKLENDI: '<b>{title}</b>, Başıklı şablon eklendi',
    SABLON_GUNCELLENDI: '<b>{title}</b>, Başıklı şablon güncellendi',
    BILDIRIM_EKLENDI: '<b>{title}</b>, Başıklı bildirim eklendi',
    BILDIRIM_GUNCELLENDI: '<b>{title}</b>, Başıklı bildirim güncellendi',
    FIRMA_EKLENDI: '<b>{title}</b>, Firma eklendi',
    FIRMA_GUNCELLENDI: '<b>{title}</b>, Firma güncellendi',
    ETIKET_BULUNAMADI: 'Etiket bulunamadı.',

    /*
     * COMPONEN İÇİNDE TYPE İLE KULLANILANLAR
     */
    //#region "Components"
    HEMEN_GONDER: 'Hemen Gönder',
    TASLAK_OLARAK_GONDER: 'Taslak olarak gönder',
    KAYNAK_UYGULAMA: 'İletilen Kaynak Uygulama',
    ARSIVE_KALDIR: "Arşiv'e Kaldır",
    ARSIVDEN_GERI_Al: 'Arşivden Geri Al',
    IPTALI_GERI_Al: 'İptali Geri Al',
    OKUNDU_OLARAK_ISARETLE: 'Okundu Olarak İşaretle',
    OKUNMADI_OLARAK_ISARETLE: 'Okunmadı Olarak İşaretle',
    TRANSFER_EDILDI_OLARAK_ISARETLE: 'Transfer Edildi Olarak İşaretle',
    TRANSFER_EDILMEDI_OLARAK_ISARETLE: 'Transfer Edilmedi Olarak İşaretle',
    gib_query: "GIB'den Sorgula",
    KABUL_ET: 'Kabul Et',
    REDDET: 'Reddet',
    download_xml: 'Xml İndir',
    download: 'İndir',
    download_envelope_xml: 'Zarf Xml İndir',
    download_pdf: 'PDF İndir',
    show_attachment: 'Ekleri Göster',
    BELGE_DETAYLARI: 'Belge Detayları',
    MAIL_DETAYLARI: 'Mailing Detayları',
    RAPOR_DETAYLARI: 'Rapor Detayları',
    IRSALIYE_BILGILERI: 'İrsaliye Bilgileri',
    VERGI_DETAYLARI: 'Vergi Detayları',
    show_history: 'İşlem Geçmişi',
    set_cancel: 'Belgeyi İptal Et',
    export_excel: "Excel'e Aktar",
    company_info: 'Müşteri Detayları',
    PASIF_ET: 'Pasif Et',
    AKTIF_ET: 'Aktif Et',
    SIL: 'Sil',
    KALICI_OLARAK_SIL: 'Kalıcı Olarak Sil',
    VARSAYILAN_OLARAK_AYARLA: 'Varsayılan olarak ayarla',
    set_solved: 'Çözüldü İşaretle',
    mailing_detail: 'Mailing Detayları',
    send_mail: 'Mail Gönder',
    internet_sales_information: 'İnternet Satış Bilgileri',
    excel_report_prepare: 'Excel Raporu Hazırla',
    report_xml_download: 'Raporu XML İndir',
    create_draft: 'Taslak Oluştur',
    edit_invoice: 'Faturayı Düzenle',
    edit: 'Düzenle',
    confirm_and_send: 'Onayla ve Gönder',
    ADRES_LISTESI: 'Adres Listesi',
    receivers: 'Alıcılar',
    //#endregion "Components"

    /*
     * AYLAR
     */
    //#region "AYLAR"

    OCAK: 'Ocak',
    SUBAT: 'Subat',
    MART: 'Mart',
    NISAN: 'Nisan',
    MAYIS: 'Mayıs',
    HAZIRAN: 'Haziran',
    TEMMUZ: 'Temmuz',
    AGUSTOS: 'Ağustos',
    EYLUL: 'Eylül',
    EKIM: 'Ekim',
    KASIM: 'Kasım',
    ARALIK: 'Aralık',

    //#endregion "AYLAR"

    MERHABA: 'Merhaba',

    EVET: 'Evet',
    HAYIR: 'Hayır',

    YENI: 'Yeni',
    KAPAT: 'Kapat',
    YENILE: 'Yenile',
    IPTAL_ET: 'İptai Et',
    GORUNTULE: 'Görüntüle',

    ARA: 'Ara',
    DUZENLE: 'Düzenle',

    BASLIK: 'Başlık',
    FILTRE: 'Filtre',
    DETAYLI_FILTRE: 'Detaylı Filtre',
    AZALAN: 'Azalan',
    ARTAN: 'Artan',
    AKTIF: 'Aktif',
    PASIF: 'Pasif',
    HEPSI: 'Hepsi',

    UYARI: 'Uyarı',
    UYARILAR: 'Uyarılar',

    EKLENDI: 'Eklendi',
    SILINDI: 'Silindi',
    GUNCELLENDI: 'Güncellendi',

    YAZDIRILDI_OLARAK_ISARETLEDI: 'Yazdırıldı Olarak İşaretle',
    YAZDIRILMADI_OLARAK_ISARETLE: 'Yazdırılmadı Olarak İşaretle',
    OKUNDU: 'Okundu',
    OKUNMADI: 'Okunmadı',
    AKTIF_EDILDI: 'Aktif Edildi',
    PASIF_EDILDI: 'Pasif Edildi',
    ADRES_GUNCELLENDI: '{title}, Başlıklı adres güncellendi',
    ADRES_EKLENDI: '{title}, Başıklı adres eklendi',

    ARANACAK_KELIME: 'Aranacak Kelime',

    SIFRE_DEGISTIR: 'Şifre Değiştir',
    CIKIS_YAP: 'Çıkış Yap',

    SECILI_OLANLARI: 'Seçili Olanları',
    KAYIT_BULUNAMADI: 'Kayıt bulunamadı',
    KAYIT_SECILI: 'Kayıt seçili',
    SABLON_SEC: 'Şablon Seç',
    DOSYA_SECILMEDI: 'Dosya seçilmedi',
    SABLON_SECILMEDI: 'Şablon seçilmedi!',
    HERHANGI_BIR_KAYIT_SECILMEDI: 'Herhangi bir kayıt seçilmedi!',

    VARSAYILAN: 'Varsayılan',
    VARSAYILAN_OLARAK_ISARETLENECEK: 'Varsayılan olarak işaretlenecek!',
    VARSAYILAN_AYARLA: 'Varsayılan ayarla',
    VARSAYILAN_OLARAK_AYARLANDI: 'Varsayılan olarak ayarlandı',
    VARSAYILAN_KAYIT_UZERINDE_HERHANGINI_BIR_ISLEM_YAPILAMAZ: 'Varsayılan kayıt üzerinde herhangi bir işlem yapılamamaktadır!',

    AKILLI_ARAMA: 'Akıllı Arama',
    DETAY: 'Detay',
    FIRMA_BILGILERINE_GORE_ARA: 'Firma Bilgilerine Göre Ara',
    FIRMA_BILGILERINE_GORE_ARAMA_DETAYLARI: 'Firma Bilgilerine Göre Arama Detayları',

    BELGE_EKLERI: 'Belge Ekleri',
    BELGE_NUMARASINA_GORE_ARA: 'Belge Numarasına Göre Ara',
    BELGE_NUMARASINA_GORE_ARAMA_DETAYLARI: 'Belge Numarasına Göre Arama Detayları',
    SECENEKLER: 'Seçenekler',
    SECILENLER: 'Seçilenler',
    ISLEMLER: 'İşlemler',

    DISA_AKTAR: 'Dışa Aktar',
    EXCELE_AKTAR: "Excel'e Aktar",

    XML_INDIR: 'Xml İndir',
    ZARF_XML_INDIR: 'Zarf Xml İndir',
    PDF_INDIR: 'Pdf İndir',
    TEK_SAYFA_PDF_INDIR: 'Tek Sayfa Pdf İndir',

    OKUNMA: 'Okunma',
    GORUNTULENME: 'Görüntülenme',
    INDIRILME: 'İndirilme',

    BELGE_EKLENME_TARIHI: 'Belge Tarihi\nOluşturma Zamanı',
    BELGE_SISTEME_ALINMA_TARIHI: 'Belge Tarihi\nSisteme Alınma Zamanı',
    VERI_KAYNAGI_ETTN: 'Veri Kaynağı\nETTN',
    BELGE_IPTAL_TARIHI: 'Belgenin Tarihi\nİptal Zamanı',
    IPTAL_EDILME_ZAMANI: 'İptal Edilme Zamanı',
    GONDERME_TARIHI: 'Gönderme Tarihi',
    EKLENME_ZAMANI: 'Oluşturma Zamanı',

    BELGE_TARIHI: 'Belge Tarihi',
    BELGE_BASLANGIC_TARIHI: 'Belge Başlangıç Tarihi',
    BELGE_BITIS_TARIHI: 'Belge Bitiş Tarihi',
    OLUSTURMA_BASLANGIC_TARIHI: 'Oluşturma Başlangıç Tarihi',
    OLUSTURMA_BITIS_TARIHI: 'Oluşturma Bitiş Tarihi',
    BELGE_TARIH_ARALIGI: 'Belge Tarih Aralığı',
    OLUSTURMA_ZAMAN_ARALIGI: 'Oluşturma Zaman Aralığı',
    BELGE_SAATI: 'Belge Saati',
    SON_BELGE_TARIHI: 'Son Belge Tarihi',
    BELGE_NUMARASI: 'Belge Numarası',
    BELGE_NO: 'Belge No.',
    BELGE_TIPI: 'Belge Tipi',
    BELGE_SAYISI: 'Belge Sayısı',
    BELGE_TUTARI: 'Belge Tutarı',
    BELGE_IPTAL_ET: 'Belgeyi İptal Et',
    BELGE_IPTAL_EDILDI: 'Belge İptal Edildi',
    BELGE_DURUMU: 'Belge Durumu',
    BELGE_BILGILERI: 'Belge Bilgileri',

    HATA_ALDI: 'Hata Aldı',
    HATA_DETAYI: 'Hata Detayı',
    HATA_KODU: 'Hata Kodu',
    HATA_ACIKLAMASI: 'Hata Açıklaması',
    HATA_DETAYI_ICIN_TIKLAYINIZ: 'Hata Detayı İçin Tıklayınız',

    FIRMA_KISI_ADI: 'Firma/Kişi Adı',
    FIRMAYA_GORE: 'Firma/Kişi',
    FIRMA_UNVANI: 'Firma ünvanı',
    FIRMA: 'Firma',
    FIRMA_BILGILERI: 'Firma Bilgileri',
    FIRMA_ADI: 'Firma Adı',
    AD: 'Ad',
    SOYAD: 'Soyad',
    VAZGEC: 'Vazgeç',

    MAIL: 'Mail',
    EMAIL: 'E-Mail',
    MAIL_AYARLARI: 'Mail Ayarları',
    MAIL_BILGILERI: 'Mail Bilgileri',
    ALICI_MAIL: 'Alıcı Mail',
    MAIL_GONDERIM_ACIKLAMALARI: 'Mail Gönderim Açıklamaları',
    SMS_GONDERIM_ACIKLAMALARI: 'SMS Gönderim Açıklamaları',
    MAIL_OKUNDU: 'Mail okundu',
    MAIL_OKUNMADI: 'Mail okunmadı',
    MAILDE_GORULECEK_ISIM: "Mail'de Görüntülenecek İsim",
    MAIL_OTOMATIK_GONDERILSIN: 'Mail otomatik gönderilsin',
    MAIL_NESBILGI_UZERINDEN_GONDERILSIN: 'Mail NESBilgi üzerinden gönderilsin',
    MAIL_GONDEREN_ADRES: 'Mail gönderen adres',
    MAIL_ASAGIDAKI_TARIHTEN_ITIBAREN_GONDERILSIN: 'Mail aşağıdaki tarihten itibaren gönderilsin ',
    MAILING_DETAYLARI: 'Mailing Detayları',

    E_FATURA_MUKELLEF_LISTELER: 'E-Fatura mükellefleri',
    E_IRSALIYE_MUKELLEF_LISTELER: 'E-irsaliye mükellefleri',

    FACEBOOK_ADRES: 'Facebook Adres',
    TWITTER_ADRES: 'Twitter Adres',
    LINKEDIN_ADRES: 'Linkedin Adres',

    SERISINE_AIT_SAYAC_BILGILERI: '<b>{serie}</b>, Serisine ait sayaç bilgileri',
    SAYAC_BILGILERI: 'Sayaç bilgileri',

    IRSALIYE_NO: 'İrsaliye No',
    SIPARIS_NO: 'Sipariş No',
    IRSALIYE_TARIHI: 'İrsaliye Tarihi',
    ISLEM_GECMISI: 'İşlem Geçmişi',

    UUID: 'ETTN',
    UUID_BILGILERI: 'ETTN Bilgileri',
    FATURA_ETTN: 'Fatura ETTN',
    ZARF_ETTN: 'Zarf ETTN',

    OLUSTURMA_ZAMANI: 'Oluşturulma Zamanı',
    IPTAL_EDILDI_MI: 'İptal Edildi mi?',
    IPTAL_KAYDI_MI: 'İptal Kaydı mı?',

    PARA_BIRIMI: 'Para Birimi',
    ONCEKI_DEGER: 'Önceki Değer',
    GUNCELLENEN_DEGER: 'Güncellenen Değer',
    GONDERICI_BIRIM_ETIKET: 'Gönderici Birim Etiket',

    ERP_YE_KAYIT_EDILDI: "ERP'ye Kayıt Edildi",
    ERP_YE_KAYIT_EDILMEDI: "ERP'ye Kayıt Edilmedi",

    ALICI_POSTA_KUTUSU_ETIKETI: 'Alıcı Posta Kutusu Etiketi',

    CEVAP_BILGILERI: 'Cevap Bilgileri',
    TOPLAMLAR: 'Toplamlar',
    VERGI_BILGILERI: 'Vergi Bilgileri',
    VERGI_KODU: 'Vergi Kodu',
    VERGI_ADI: 'Vergi Adı',
    ORAN: 'Oran',
    VERGI_TUTARI: 'Vergi Tutarı',
    INDIRIM_TUTARI: 'İndirim Tutarı',
    VERGI_MATRAHI: 'Vergi Matrahı',
    SECINIZ: 'Seçiniz',
    EKLE: 'Ekle',
    NOT_SILINECEKTIR_DEVAM_EDILSIN_MI: 'Not silinecektir devam edilsin mi ?',
    NOT_SILINDI: 'Not silindi.',

    EKLENME_T: 'Eklenme T.',

    VERGISIZ: 'VERGİSİZ',

    ICERIGI_GORUNTULE: 'İçeriği görüntüle',
    ISLEM_BEKLENIYOR: 'İşlem Bekleniyor',

    ZARF: 'Zarf',
    BELGE_ZARFI: 'Belge Zarfı',
    BELGE_KAYDI: 'Belge Kaydı',
    BELGE_IPTAL_KAYDI: 'Belge İptal Kaydı',
    KAYIT_TIPI: 'Kayıt Tipi',
    CEVAP_ZARFI: 'Cevap Zarfı',
    ZARF_NO: 'Zarf No',
    ZARF_NUMARASI: 'Zarf Numarası',
    DURUM_KODU: 'Durum Kodu',
    DURUM_ACIKLAMASI: 'Durum Açıklaması',
    ZARF_BILGILERI: 'Zarf Bilgileri',

    SISTEM_TARAFINDAN_GONDERILIYOR: 'Sistem tarafından gönderiliyor',
    ZARF_OLUSTURULURKEN_HATA_OLUSTU: 'Zarf oluşturulurken hata oluştu',
    GIB_E_GÖNDERILIYOR: "GIB'e gönderiliyor",
    GIB_E_GONDERILIRKEN_HATA_OLUSTU: "GIB'e gönderilirken hata oluştu",
    GIB_E_GONDERILIRKEN_HATA_OLUSTU_TEKRAR_GONDERILIYOR: "GIB'e gönderilirken hata oluştu, tekrar gönderiliyor",
    GIB_IN_ZARFI_ISLEMESI_BEKLENIYOR: "Gib'in zarfı işlemesi bekleniyor",
    GIB_HATA_TESPIT_ETTI: 'Gib, hata tespit etti',
    GIB_IN_ZARFI_ALICIYA_ILETMESI_BEKLENIYOR: "Gib'in zarfı alıcıya iletmesi bekleniyor.",
    GIB_BELGEYI_ALICIYA_ILETEMEDI: 'Gib Belgeyi alıcıya iletemedi',
    GIN_IN_ZARFI_ALICIYA_ILETMESI_BEKLENIYOR: "Gib'in zarfı alıcıya iletmesi bekleniyor.",
    ALICININ_POSTA_KUTUSU_YANITI_BEKLENIYOR: 'Alıcının posta kutusu yanıtı bekleniyor.',
    ALICI_HATA_TESPIT_ETTI: 'Alıcı, hata tespit etti',
    ZARF_SISTEM_TARAFINDAN_OLUSTURULACAK: 'Zarf sistem tarafından oluşturulacak',
    BASARIYLA_ILETILDI: 'Başarıyla İletildi',
    DURUM_BILINMIYOR: 'Durum bilinmiyor',
    RAPOR_BASARI_ILE_GONDERILDI: 'Rapor Başarı İle Gönderildi.',
    VARSAYILAN_SERI_NUMARASINDAN_DÜSÜK_EKLENEMEZ: 'Varsayılan <b>{nextNumber}</b> numaradan küçük veya eşit pozitif tam sayı eklenemez.',
    DOSYA_EKLE: 'Dosya Ekle',
    RAPOR_OLUSTURULMADI: 'Rapor oluşturulmadı',
    RAPOR_OLUSTURULUYOR: 'Rapor oluşturuluyor',
    RAPOR_OLUSTURULDU_GIB_E_GONDERILECEK: "Rapor oluşturuldu <br/><small>GIB'e gönderilecek</small>",
    RAPOR_IMZALANDI: 'Rapor imzalandı',
    IMZALANMAYI_BEKLIYOR: 'İmzalanmayı bekliyor',
    RAPOR_GONDERILDI_DURUM_SORGULANIYOR: 'Rapor gönderildi. <br/><small>Durum sorgulanıyor</small>',
    RAPOR_GONDERIMI_BASARILI: 'Rapor gönderimi başarılı',
    REPORT_YEAR: 'Rapor Yılı',
    IMZALANDI: 'İmzalandı',

    IPTAL_T: 'İptal T.',
    VERGI_NO: 'Vergi No : ',
    TCKN: 'T.C Kimlik No : ',

    DURUM_KODU_ACIKLAMASI: 'Durum Kodu Açıklaması',

    BASARILI: 'Başarılı',
    ISLENMEYI_BEKLIYOR: 'İşlenmeyi Bekliyor',
    KABUL_EDILDI: 'Kabul Edildi',
    OTOMATIK: 'Otomatik',
    RED_SEBEBI: 'Red Sebebi',
    RED_SEBEBINI_GORMEK_ICIN_TIKLAYINIZ: 'Red Sebebini Görmek İçin Tıklayınız',
    REDDEDILDI: 'Reddedildi',
    ALINDI_YANITI_GONDERILEMEDI: 'Alındı Yanıtı Gönderilemedi',
    ALINDI_YANITININ_GONDERILMESI_BEKLENIYOR: 'Alındı Yanıtının Gönderilmesi Bekleniyor',
    TARIH: 'Tarih',
    ALICI_BILGILERI: 'Alıcı Bilgileri',
    ISLENIYOR: 'İşleniyor',

    TARIH_KRITERI: 'Tarih Kriteri',
    BASLANGIC_TARIHI: 'Başlangıç Tarihi',
    BITIS_TARIHI: 'Bitiş Tarihi',
    SIRALAMA: 'Sıralama',
    GONDERIM_TERCIHI: 'Gönderim Tercihi',
    GONDERIM_ETIKETI: 'Gönderim Etiketi',
    SUBE_KODU: 'Şube Kodu',

    TOPLAM: 'Toplam',
    GOSTERILEN: 'Gösterilen',
    SERI: 'Seri',
    PORTAL_SERISI_MI: 'Portal Serisi Mi?',
    validation: {
      required: 'Bu alanda boş geçilemez!'
    },
    SIRADAKI_NUMARA: 'Sıradaki Numara',
    SAYAC_YILI: 'Sayaç Yılı',

    YENI_EKLE: 'Yeni Ekle',
    KAYDET: 'Kaydet',
    BU_ALAN_BOS_GECILEMEZ: 'Bu alan boş geçilemez',
    BU_ALAN_MINIMUM_MIN_MAXIMUM_MAX_KARAKTER_OLABILIR: '<div>Bu alan minimum <b>{MIN}</b>, maksimum <b>{MAX}</b> karakter olabilir.</div>',
    BU_ALAN_MAXIMUM_MAX_KARAKTER_OLABILIR: '<div>Bu alan maksimum <b>{MAX}</b> karakter olabilir.</div>',
    LUTFEN_GECERLI_BIR_DEGER_GIRINIZ: 'Lütfen geçerli bir değer giriniz!',
    N_HANELI_OLMALIDIR: '{N} haneli olmalıdır!',
    BUYUK_HARF_VEYA_RAKAMLARDAN_OLUSABILIR: 'Büyük harf veya rakamlardan oluşabilir!',
    VERGI_KIMLIK_NUMARASI_GIRILDIGINDE_UNVAN_ZORUNLUDUR: 'Vergi kimlik numarası girilmesi durumunda ünvan zorunludur.',
    TC_KIMLIK_NUMARASI_GIRILDIGINDE_AD_ZORUNLUDUR: 'T.C. kimlik numarası girilmesi durumunda ad zorunludur',
    TC_KIMLIK_NUMARASI_GIRILDIGINDE_SOYAD_ZORUNLUDUR: 'T.C. kimlik numarası girilmesi durumunda soyad zorunludur',

    ALICI: 'Alıcı',

    KUYRUK_DURUMU: 'Kuyruk Durumu',

    MIN_DOSYA_BOYUTU_N_UNIT_OLMALIDIR: 'Asgari dosya boyutu {N} {UNIT} olmalıdır!',
    MAX_DOSYA_BOYUTU_N_UNIT_OLMALIDIR: 'Azami dosya boyutu {N} {UNIT} olmalıdır!',
    SADECE_X_DOSYASI_YUKLEYEBILIRSINIZ: 'Sadece {X} dosyasını yükleyebilirsiniz!',

    GONDERIM_TIPI: 'Gönderim Tipi',
    ELEKTRONIK: 'Elektronik',
    KAGIT: 'Kağıt',
    KAGIT_BELGE: 'Kağıt Belge',
    MAIL_GONDERILMEDI: 'Mail Gönderilmedi',

    BU_SATIS_INTERNET_UZERINDEN_YAPILMISTIR: 'Bu Satış İnternet Üzerinden Yapılmıştır',
    DURUM: 'Durum',

    GONDERIM_TIPLERI: 'GÖNDERİM TİPLERİ',
    SMS: 'SMS',
    SMS_AYARLARI: 'Sms Ayarları',
    SMS_DURUMU: 'SMS DURUMU',
    ARAMA_YAPILABILECEK_ALANLAR: 'Arama yapılabilecek alanlar',

    GONDERICI_BILGILERI: 'Gönderici Bilgileri',
    GONDERICI_VKN_TCKN: 'Gönderici V.K.N / T.C.K.N',
    ALICI_VKN_TCKN: 'Alıcı V.K.N / T.C.K.N',

    BASARILI_GONDERILEN_MAIL_SAYISINI_IFADE_EDER: 'Başarılı Gönderilen Mail Sayısını İfade Eder',
    OKUNAN_MAIL_SAYISINI_IFADE_EDER: 'Okunan Mail Sayısını İfade Eder',
    HATALI_MAIL_SAYISINI_IFADE_EDER: 'Hatalı Mail Sayısını İfade Eder',

    BASARILI_GONDERILEN_SMS_SAYISINI_IFADE_EDER: 'Başarılı Gönderilen SMS Sayısını İfade Eder',
    OKUNAN_SMS_SAYISINI_IFADE_EDER: 'Okunan Sms Sayısını İfade Eder',
    HATALI_SMS_SAYISINI_IFADE_EDER: 'Hatalı Sms Sayısını İfade Eder',

    ILETILEMEYEN_SMS_SAYISINI_IFADE_EDER: 'İletilemeyen SMS Sayısını İfade Eder',
    GIDEN_EARSIV_DETAYLARI: 'Giden E-Arşiv Detayları',

    YENI_RAPOR_SABLONU: 'Yeni Rapor Şablonu',

    KULLANILABILIR_RAPOR_SUTUNLARI: 'Kullanılabilir Sütunlar',
    SECILEN_RAPOR_SUTUNLARI: 'Seçilen Sütunlar',

    VKN_TCKN: 'VKN/TCKN :',
    VKN: 'V.K. No :',
    TC_NO: 'T.C. No :',
    ADRES_ILCE: 'Adres/İlçe',

    SEHIR_ULKE: 'Şehir/Ülke',

    POSTA_KODU_VERGI_DAIRESI: 'Posta Kodu/Vergi Dairesi',
    SEHIR: 'Şehir',
    ADRES: 'Adres',
    KAYITLI_ADRESLER: 'Kayıtlı Adresler',
    YENI_ADRES: 'Yeni Adres',
    ADRES_DUZENLE: 'Adres Düzenle',
    ULKE: 'Ülke',
    POSTA_KODU: 'Posta Kodu',
    ILCE: 'İlçe',
    TELEFON_FAX: 'Telefon/Fax',
    TELEFON: 'Telefon',
    FAX: 'Fax',
    VERGI_DAIRESI: 'Vergi Dairesi',

    MAIL_WEBSITE: 'Mail/Web Site',
    YENI_MUSTERI: 'Yeni Müşteri',
    MUSTERI_BILGILERI: 'Müşteri Bilgileri',
    ADRES_BILGILERI: 'Adres Bilgileri',
    ADRES_BASLIGI: 'Adres Başlığı (Örnek : Merkez)',
    ADRES_CADDE_SOKAK_MAHALLE: 'Adres (Cadde / Sokak / Mahalle)',
    ORNEK_EXCEL_INDIR: 'Örnek Excel İndir',
    MUSTERILERI_EXCELDEN_AKTAR: "Müşterileri Excel'den Aktar",
    YENI_MUSTERI_EKLE: 'Yeni Müşteri Ekle',

    KULLANICI: 'Kullanıcı',
    MESAJ: 'Mesaj',
    IPTAL_EDILDI: 'İptal Edildi',
    TERCIHLERI_KAYDET: 'Tercihleri Kaydet',
    SATIS_TIPI: 'Satış Tipi',
    NORMAL_SATIS: 'Normal Satış',
    INTERNET_SATIS: 'İnternet Satış',

    KREDI_BANKA_BANKA_KARTI: 'KREDİ KARTI / BANKA KARTI',
    EFT_HAVALE: 'EFT / HAVALE',
    KAPIDA_ODEME: 'KAPIDA ÖDEME',
    ODEME_ARACISI: 'ÖDEME ARACISI',
    DIGER: 'Diğer',
    NESBILGI_ONAYI_BEKLIYOR: 'NESBilgi onayı bekliyor.',
    SMS_BASLIGI: 'SMS Başlığı',
    OTOMATIK_SMS: 'SMS Otomatik Olarak Gönderilsin',
    ORNEK_SMS: 'Örnek SMS',
    BELGELERE_SMS_GITSIN: 'Belgelere SMS Gitsin',

    TEMELFATURA: 'Temel Fatura',
    TICARIFATURA: 'Ticari Fatura',
    EARSIVFATURA: 'E-Arşiv Fatura',
    YOLCUBERABERFATURA: 'Yolcu Beraber Fatura',
    IHRACAT: 'İhracat Faturası',
    MEDULA_XML_AKTAR: "Medula XML'i (SGK E-Fatura) Aktar",
    FATURA_SENARYOSU: 'Fatura Senaryosu',
    IRSALIYE_SENARYOSU: 'İrsaliye Senaryosu',

    GELEN_FATURA_OTOMATIK_ARSIVE_ALINSIN: 'Gelen Faturalar Otomatik Arşive Alınsın',
    GIDEN_FATURA_OTOMATIK_ARSIVE_ALINSIN: 'Giden Faturalar Otomatik Arşive Alınsın',

    SON_1_AYDAN_ONCEKI_FATURALARI_OTOMATIK_ARSIVLE: 'Son 1 Aydan Önceki Faturaları Otomatik Arşivle',
    SON_2_AYDAN_ONCEKI_FATURALARI_OTOMATIK_ARSIVLE: 'Son 2 Aydan Önceki Faturaları Otomatik Arşivle',
    SON_3_AYDAN_ONCEKI_FATURALARI_OTOMATIK_ARSIVLE: 'Son 3 Aydan Önceki Faturaları Otomatik Arşivle',

    GELEN_EFATURA_DETAYLARI: 'Gelen E-Fatura Detayları',
    GIDEN_EFATURA_DETAYLARI: 'Giden E-Fatura Detayları',
    VARSAYILAN_EFATURA_OLUSTURMA_AYARLARI: 'Varsayılan E-Fatura Oluşturma Ayarları',

    ALICI_FATURAYA_HENUZ_CEVAP_VERMEDI: 'Alıcı Faturaya Henüz Cevap Vermedi',
    GELEN_EFATURALARA_CEVAP_VERILDIGINDE_VERILEN_CEVAP_OTOMATIK_ONAYLANIM_GONDERILSIN:
      "Gelen e-Faturalara cevap verildiğinde, verilen cevap otomatik 'Onaylanıp/Gönderilsin'",
    MASTER_KULLANICI_DISINDAKI_KULLANICILAR_EFATURA_CEVABI_OLUSTURMASIN: 'MASTER kullanıcı dışındaki kullanıcılar e-Fatura cevabı oluşturamasın!',
    BILDIRIM_TANIMLARI: 'Bildirim Tanımlamaları',
    GIDEN_EFATURA: 'Giden E-Fatura',
    GELEN_EFATURA: 'Gelen E-Fatura',

    OTOMATIK_SMS_ORNEK_GONDERIM: '01.01.2016 tarihindeki, 1234,56 TL alisverisinizin e-arsiv faturasi hazirlanmistir. http://e-fatura.link/abc123',
    GENEL_TANIMLAMALAR: 'Genel Tanımlamalar',
    SMS_UZERINDEN_GORUNTULENEN_FATURA_SAYISINI_IFADE_EDER: 'SMS Üzerinden Görüntülenen Fatura Sayısını İfade Eder',
    FATURALARINIZI_TOPLU_YUKLEMEK_ICIN_BIRDEN_FAZLA_XML_SECEBILIRSINIZ: 'Faturalarınızı toplu yüklemek için birden fazla XML seçebilirsiniz.',

    OTOMATIK_ARSIVLEME_TANIMLARI: 'Otomatik Arşivleme Tanımlamaları',
    DOSYA_BASLIK_TANIMLAMALARI: 'Dosya Başlık Tanımlamaları',
    BASLIK_TANIMLARI: 'Başlık Tanımlamaları',

    SATIS_KANALI: 'Satış Kanalı',
    VARSAYILAN_ODEME_YONTEMI: 'Varsayılan Ödeme Yöntemi',
    ODEME_YONTEMI: 'Ödeme Yöntemi',
    ODEME_YONTEMI_ACIKLAMASI: 'Ödeme Yöntemi Açıklaması',

    RAPOR_DONEMI: 'Rapor Dönemi',

    SIRA_NO: 'Sıra No',
    OLUSTURMA_TARIHI: 'Oluşturma Tarihi',
    TARIH_ARALIGI: 'Tarih Aralığı',
    BOLUM_NUMARASI: 'Bölüm Numarası',

    IPTAL_SAYISI: 'İptal Sayısı',
    RAPOR_DURUMU: 'Rapor Durumu',
    RAPOR_ICERIGI: 'Rapor İçeriği',
    IPTAL_KAYDI: 'İptal Kaydı',
    IPTAL_DURUMU: 'İptal Durumu',
    ODENECEK_TUTAR: 'Ödenecek Tutar',
    KAYIT_TARIHI: 'Kayıt Tarihi',

    FATURA_EKLERINI_GORUNTULEME: 'Fatura Ekleri Görüntüleme',
    SIRA: 'Sıra',
    DOSYA_ADI: 'Dosya Adı',
    DOSYA_TIPI: 'Dosya Tipi',
    INDIR: 'İndir',
    ZARF_DURUMU_SORGULA: 'Zarf Durumunu Sorgula',
    YAZDIR: 'Yazdır',
    MAIL_GONDER: 'Mail Gönder',
    DEGER: 'Değer',
    OZELLIK_ADI: 'Özellik Adı',
    KOSUL_OPERATORU: 'Koşul Operatörü',

    INTERNET_SATIS_BILGILERI: 'İnternet Satış Bilgileri',
    WEB_SITE: 'Web Site',
    ODEME_INTERNET_YONTEMI: 'Ödeme Yöntemi',
    ODEME_TARIHI: 'Ödeme Tarihi',
    TASIYICI_ADI_UNVAN: 'Taşıyıcı Adı / Ünvan',
    TASIYICI_TCKN_VKN: 'Taşıyıcı VKN/TCKN',
    TASIMA_TARIHI: 'Taşıma Tarihi',

    ZARF_SORGU_SONUCU: 'Zarf Sorgu Sonucu',
    KOD: 'Kod',

    CEVAP: 'Cevap',
    FATURA_CEVABI: 'Fatura Cevabı',
    CEVAP_KODU: 'Cevap Kodu',
    CEVAP_ACIKLAMASI: 'Cevap Açıklamsı',
    CEVAP_ZARF_NUMARASI: 'Cevap Zarfı',

    ACIKLAMA: 'Açıklama',
    KULLANICI_ADI: 'Kullanıcı Adı',
    IP: 'Ip',

    LUTFEN_MAILDE_GORUNTULENECEK_ISIM_GIRINIZ: "Lütfen Mail'de Görüntülenecek İsim Giriniz!",
    LUTFEN_BIR_MAIL_ADRESI_GIRINIZ: 'Lütfen Bir Mail Adresi Giriniz!',
    NES_BILGI_ONAYI_BEKLENIYOR: 'NESBilgi Onayı Bekleniyor.',
    NES_BILGI_ONAYLADI: 'NESBilgi Onayladı.',

    OGE_SECILDI: 'Öğe Seçildi',

    FATURA_KAYDI: 'Fatura Kaydı',
    FATURA_IPTAL_KAYDI: 'Fatura İptal Kaydı',

    EXCEL_RAPORU_HAZIRLA: 'Excel Raporu Hazırla',

    EMIN_MISINIZ: 'Emin Misiniz?',

    JSON_INDIR: 'JSON İndir',
    ON_IZLE: 'Ön İzle',
    ONAYLA_VE_GONDER: 'Onayla ve Gönder',
    TASLAK_OLARAK_KAYDET: 'Taslak Olarak Kaydet',
    VOUCHER: 'Makbuz',
    MAKBUZ_BILGILERI: 'Makbuz Bilgileri',
    GENEL_BILGILER: 'Genel Bilgiler',
    ETTN: 'UUID',
    LUTFEN_BIR_DEGER_SECINIZ: 'Lütfen Bir Değer Seçiniz',

    YIL: 'Yıl',
    AY: 'Ay',

    AKTIVASYON_ISTATISTIGI: 'Aktivasyon İstatistiği',
    DEAKTIVASYON_ISTATISTIGI: 'DeAktivasyon İstatistiği',
    KULLANIM_ISTATISTIGI: 'Kullanım İstatistiği',
    AKTIVASYON_KAYDI: 'Aktivasyon Kaydı',
    AKTIVASYON_ISLEMI: 'Aktivasyon İşlemi',
    DEAKTIVASYON_KAYDI: 'DeAktivasyon Kaydı',
    DEAKTIVASYON_ISLEMI: 'DeAktivasyon İşlemi',

    FATURA_DURUMU: 'Fatura Durumu',
    IRSALIYE_DURUMU: 'İrsaliye Durumu',
    BASARILI_OLANLAR: 'Başarılı Olanlar',
    BASARISIZ_OLANLAR: 'Başarısız Olanlar',
    ISLEM_BEKLEYENLER: 'İşlem Bekleyenler',
    IMZA_BEKLEYENLER: 'İmza Bekleyenler',
    HATALI_OLANLAR: 'Hatalı Olanlar',
    IMZALANANLAR: 'İmzalananlar',
    COZULDU_OLANLARI_GOSTER: 'Çözüldü Olanları Göster',

    SENARYO: 'Senaryo',
    YOLCU_BERABER_FATURA: 'Yolcu Beraber Fatura',
    IHRACAT_FATURASI: 'İhracat Faturası',

    CEVAP_DURUMU: 'Cevap Durumu',
    KABUL_EDILENLER: 'Kabul Edilenler',
    REDDEDILENLER: 'Reddedilenler',
    CEVAP_BEKLEYENLER: 'Cevap Bekleyenler',

    RAPOR_OLUSTURULMAMISLAR: 'Rapor Oluşturulmamışlar',

    IPTAL_FATURALAR: 'İptal Faturalar',
    IPTAL_FATURALARDA_LISTELENSIN: "İptal Faturalar'da Listelensin",

    HATA_ALANLAR: 'Hata Alanlar',
    OKUNANLAR: 'Okunanlar',
    OKUNMAYANLAR: 'Okunmayanlar',

    IPTAL_BELGELER: 'İptal Belgeler',
    IPTAL_BELGELERDE_LISTELENSIN: 'İptal Belgeler De Listelensin',

    ERPYE_TRANSFER_DURUMU: "ERP'ye Transfer Durumu",
    AKTARILANLAR: 'Aktarılanlar',
    AKTARILMAYANLAR: 'Aktarılmayanlar',
    SANAL_ALICI: 'Sanal Alıcı',
    ALICI_TIPI: 'Alıcı Tipi',
    ERPYE_KAYIT_DURUMU: "ERP'ye Kayıt Durumu",
    OKUNMA_DURUMU: 'Okunma Durumu',
    PORTAL_SERISI_OLMAYAN_KAYIT_UZERINDE_HERHANGINI_BIR_ISLEM_YAPILAMAZ: 'Portal serisi olmayan kayıt üzerinde herhangi bir işlem yapılamaz.',

    KAYITLI_ALICI: 'Kayıtlı Alıcı',

    EFATURA: 'E-Fatura',
    EARSIV: 'E-Arşiv',
    EDEFTER: 'E-Defter',
    EDESPATCH: 'E-İrsaliye',
    EIRSALIYE: 'E-İrsaliye',
    ETICKET: 'E-Bilet',
    ESMM: 'E-SMM',
    EMM: 'E-MM',
    OKC: 'OKC',
    Belge: 'Belge',

    URUN_TIPI: 'Ürün Tipi',
    KAYITLI_FIRMA_KISI_SAYISI: 'Kayıtlı Firma/Kişi Sayısı',
    AKTIF_FRIMA_KISI_SAYISI: 'Aftif Firma/Kişi Sayısı',
    AKTIVASYON_BEKLEYEN_FIRMA_KISI_SAYISI: 'Aktivasyon Bekleyen Firma/Kişi Sayısı',
    DEAKTIVASYON_YAPILAN_FIRMA_KISI_SAYISI: 'DeAktivasyon Yapılan Firma/Kişi Sayısı',
    ONAYLANMAYI_BEKLEYEN_FIRMA_KISI_SAYISI: 'Onaylanmayı Bekleyen Firma/Kişi Sayısı',

    FATURA_TIPI: 'Fatura Tipi',
    SATIS: 'Satış',
    IADE: 'İade',
    ISTISNA: 'İstisna',
    TEVKIFAT: 'Tevkifat',
    IHRACKAYITLI: 'İhraç Kayıtlı',
    OZELMATRAH: 'Özel Matrah',
    SGK: 'SGK',

    IRSALIYE_TIPI: 'İrsaliye Tipi',
    SEVK: 'Sevk',
    MATBUDAN: 'Matbudan',

    GELEN_ODEMELER: 'Gelen Ödemeler',
    KESILEN_FATURA: 'Kesilen Fatura',
    URUN_ISTATISTIKLERI: 'Ürün İstatistikleri',
    KESILEN_FATURA_GELEN_ODEME_AYLIK_ISTATISTIK: 'Kesilen Fatura / Gelen Ödeme Aylık İstatistik',

    DOVIZ_KURU: 'Döviz Kuru',
    FATURA_SERI: 'Fatura Seri',
    MANUEL_FATURA_NO: 'Manuel Fatura No',
    FATURA_SABLONU: 'Fatura Şablonu',
    YUZDE: 'Yüzde',
    OZEL_MATRAH_NEDENI: 'Özel Metrah Nedeni',
    ECZANE: 'Eczane',
    HASTANE: 'Hastame',
    OPTIK: 'Optik',
    MEDIKAL: 'Medikal',
    ABONELIK: 'Abonelik',
    MAL_HIZMET: 'Mal/Hizmet',
    SGK_FATURA_TIPI: 'Sgk Fatura Tipi',
    SGK_SIRKET_ADI: 'Sgk Şirket Adı',
    SGK_SIRKET_KODU: 'Sgk Şirket Kodu',
    DONEM_BASLANGIC_TARIHI: 'Dönem Başlangıç Tarihi',
    DONEM_BITIS_TARIHI: 'Dönem Bitiş Tarihi',
    'KREDIKARTI/BANKAKARTI': 'Kredi Kartı / Banka Kartı',
    'EFT/HAVALE': 'Eft / Havale',
    KAPIDAODEME: 'Kapıda Ödeme',
    ODEMEARACISI: 'Ödeme Aracısı',
    FIRMA_KIMLIK_BILGILERI: 'Firma Kimlik Bilgileri',

    AKTIF_OLANLARI_GOSTER: 'Aktif Olanları Göster',
    PASIF_OLANLARI_GOSTER: 'Pasif Olanları Göster',
    SILINMIS_OLANLARI_GOSTER: 'Silinmiş Olanları Göster',

    şablon: 'şablon',
    SABLON: 'Şablon',
    KURALLAR: 'Kurallar',
    KURAL_ADI: 'Kural Adı',
    ESIT: 'Eşit',
    ESIT_DEGIL: 'Eşit Değil',
    ARASINDA: 'Arasında',
    BUYUK_ESIT: 'Büyük Eşit',
    KUCUK_ESIT: 'Küçük Eşit',
    BASLANGIC_DEGERI: 'Başlangıç Değeri',
    BITIS_DEGERI: 'Bitiş Değeri',

    MAIL_ADRESI_GIRINIZ: 'Mail Adresi Giriniz',
    ENTER_KULLANARAK_COKLU_GIRIS_YAPABILIRSINIZ: 'Enter kullanarak çoklu giriş yapabilirisiniz...',
    GECERLI_BIR_MAIL_ADRESI_DEGIL_LUTFEN_GECERLI_BIR_MAIL_ADRESI_GIRINIZ: 'Geçerli Bir Mail Adresi Değil! Lütfen Geçerli Bir Mail Adresi Giriniz.',
    BELIRTILEN_ADRESLERE_MAIL_GONDERILECEK: 'Belirtilen adreslere mail gönderilecek.<br/>İşleme devam edilsin mi?',
    BELIRTILEN_ADRESLERE_MAIL_GONDERILDI: '<b>{documentNumber}</b>, Numaralı Belge için belirtilen adreslere mail gönderildi.',
    MAIL_AYARLARINIZ_BASARIYLA_GUNCELLENDI: 'Mail gönderim ayarlarınız başarıyla güncellendi.',
    KAYIT_BASARIYLA_EKLENDI: 'Kayıt başarıyla eklendi.',
    SMS_AYARLARINIZ_BASARIYLA_GUNCELLENDI: 'Sms gönderim ayarlarınız başarıyla güncellendi.',
    YENI_X_EKLE: 'Yeni {x} ekle',
    X_DUZENLE: '{x} düzenle',

    RAPOR_NO: 'Rapor No',
    IPTAL_BELGE_SAYISI: 'İptal belge sayısı',

    ETIKET_ISMI: 'Etiket ismi',
    ETIKET_EKLE: 'Etiket Ekle',
    ETIKET_DUZENLE: 'Etiket Düzenle',
    ETIKETLERI_DUZENLE: 'Etiketleri Düzenle',
    ETIKET_BILGILERI: 'Etiket Bilgileri',
    ETIKET_SIL: 'Etiket Sil',
    RENK: 'Renk',
    ETIKET_EKLENDI: '<b>{title}</b> isimli Etiket Eklendi.',
    ETIKETLER_FATURAYA_EKLENDI: 'Belge veya Belgelere Etiketler Eklendi.',
    ETIKET_GUNCELLENDI: '<b>{title}</b> isimli Etiket Güncellendi.',
    ETIKETLER_SILINDI: 'Belge veya Belgelere seçilen Etiketler silindi.',

    NOTLAR: 'Notlar',
    NOT: 'Not',
    NOT_GUNCELLENDI: 'Not Güncellendi',
    NOT_EKLENDI: 'Not Eklendi',
    KULLANICI_NOTLARI: 'Kullanıcı Notları',

    SUTUN_SEC: 'Sutün Seç',
    YAZDIRMA_BILGISI: 'Yazdırma Bilgisi',
    OKUNDU_BILGISI: 'Okundu Bilgisi',
    TRANSFER_DURUMU: 'Transfer Durumu',

    BILDIRIM_MAILI: 'Bildirim Maili',
    KAYIT_DURUMU: 'Kayıt Durumu',
    PARCALAR: 'Parçalar',
    PARCA_LISTESI: 'Parça Listesi',
    ILK_INDEX: 'İlk İndex',
    SON_INDEX: 'Son İndex',
    PARCA_NUMARASI: 'Parça Numarası',
    BEKLENIYOR: 'Bekleniyor.',
    ONAYLANDI: 'Onaylandı.',
    BELGE_ARSİV: 'Arşiv Detayları',
    TELEFAX: 'TeleFax',
    TERCIHLER_KAYDEDILDI: 'Tercihler kaydedildi',

    //AUTH
    SON_GECERLILIK_TARIHI: 'Son Geçerlilik Tarihi',

    API_ERISIM_ANAHTARI: 'API Erişim Anahtarı',
    SONA_ERME_ZAMANI: 'Sona Erme Zamanı',
    SON_KULLANMA_ZAMANI: 'Son Kullanma Zamanı',
    YETKI_LISTESI: 'Yetki Listesi',
    ERISIMI_SONLANDIR: 'Erişimi Sonlandır',
    API_ANAHTARI_SILINECEKTIR_DEVAM_EDILSIN_MI: 'API anahtarı silinecektir devam edilsin mi ?',
    API_ANAHTARI_SILINDI: 'API anahtarı silindi.',

    TUM_API_ANAHTARLARI_SILINECEKTIR_DEVAM_EDILSIN_MI: 'Tüm API anahtarları silinecektir devam edilsin mi ?',
    TUM_API_ANAHTARLARI_SILINDI: 'Tüm API anahtarları silindi.',
    TUMUNU_SIL: 'Tümünü Sil',
    DOGUM_TARIHI: 'Doğum Tarihi',
    YONETICI: 'Yönetici',
    IKI_ADIMLI_DOGRULAMA_AKTIF: 'İki Adımlı D. Aktif',
    MAIL_DOGRULANDI: 'Mail Doğrulandı',
    MODULLER: 'Modüller',

    KULLANICI_AKTIF_EDILECEKTIR_DEVAM_EDILSIN_MI: '<b>{mail}</b>, Kullanıcısı aktif edilecektir. <br/>İşleme devam edilsin mi?',
    KULLANICI_AKTIF_EDILDI: '<b>{mail}</b>, Kullanıcısı aktif edildi',

    KULLANICI_PASIF_EDILECEKTIR_DEVAM_EDILSIN_MI: '<b>{mail}</b>, Kullanıcısı pasif edilecektir. <br/>İşleme devam edilsin mi?',
    KULLANICI_PASIF_EDILDI: '<b>{mail}</b>, Kullanıcısı pasif edildi',

    KULLANICI_SILINDI_OLARAK_ISARETLENECEKTIR_DEVAM_EDILSIN_MI: '<b>{mail}</b>, Kullanıcısı silindi olarak işaretlenecektir. <br/>İşleme devam edilsin mi?',
    KULLANICI_SILINDI_OLARAK_ISARETLENDI: '<b>{mail}</b>, Kullanıcısı silindi olarak işaretlendi',

    X_MODULU_AKTIF_ET: '{productType} Modülü Aktif Et',
    X_MODULU_PASIF_ET: '{productType} Modülü Pasif Et',

    X_MODULU_AKTIF_EDILECEKTIR_DEVAM_EDILSIN_MI: '{productType} Modülü aktif edilecektir. <br/>İşleme devam edilsin mi?',
    X_MODULU_AKTIF_EDILDI: '<b>{productType}</b> Modülü, <b>{mail}</b> kullanıcısı için aktif edildi',
    X_MODULU_AKTIF_EDILDI_ROL_ACIKLAMA:
      '<b>{productType}</b> Modülüne ait tüm yetkiler, <b>{mail}</b> kullanıcısına tanımlandı. <br/> Kullanıcı yetkilerini İşlemler butonundan düzenleyebilirsiniz.',

    X_MODULU_PASIF_EDILECEKTIR_DEVAM_EDILSIN_MI: '{productType} Modülü pasif edilecektir. <br/>İşleme devam edilsin mi?',
    X_MODULU_PASIF_EDILDI: '<b>{productType}</b> Modülü, <b>{mail}</b> kullanıcısı için pasif edildi',

    SILINMIS_KULLANICI_UZERINDE_HERHANGINI_BIR_ISLEM_YAPILAMAZ: 'Silinmiş kullanıcı üzerinde herhangi bir işlem yapılamaz.',
    PAROLA: 'Parola',
    PAROLA_TEKRAR: 'Parola (Tekrar)',
    PAROLA_REGEX: 'En az 8 Karakter, Bir Büyük Harf, Bir Küçük Harf, Bir rakam ve bir özel karakter içermelidir.',

    CEP_TELEFON_NO: 'Cep Telefon No',
    KULLANICI_OLUSTURULDU: '<b>{user}</b>, Kullanıcısı oluşturuldu',

    '1160:FAILED_XML_SCHEMA_CHECK': '<b>1160 : XML Şema Kontrolünden Geçemedi</b>',

    PAROLA_GUNCELLENDI: '<b>{user}</b>, Kullanıcısının parolası güncellendi',

    KULLANICI_GUNCELLENDI: '<b>{user}</b>, Kullanıcı bilgileri güncellendi',

    PAROLA_GUNCELLE: 'Parola Değiştir',

    //TEMA CONTEXT
    TEMA_AYARLARI: 'Tema Ayarları',
    AYARLAR: 'Ayarlar',
    MENU_ARAYUZU: 'Menü Arayüzü',
    SOL_MENU: 'Sol Menü,',
    UST_KOYU: 'Üstte Koyu',
    UST_MENU: 'Üst Menü',
    MENU_OLMASIN: 'Menü olmasın',
    SOL_MENU_TIPI: 'Menü Tipi',
    FLYOUT: 'Açılır Pencere',
    KOMPAKT: 'Kompakt',
    GECIS_ANIMASYON: 'Geçiş Animasyonu',
    DIL_SECENEKLERI: 'Dil Seçenekleri',

    KULLANICI_NOTU: 'Kulanıcı Notu',

    '422-01': '<b>Geçersiz Değer</b>',
    '422-05': 'En az bir varsayılan kayıt bulunmalıdır',
    '422-10': '<b>Kayıt İşlem Görmüş</b>',
    '422-11': '<b>Kayıt Daha Önce İptal Edilmiş</b>',
    '422-15': '<b>Daha önce kayıtlı</b>',
    '422-16': '<b>Daha Önce Gönderilmemiş</b>',
    '422-20': '<b>Özel Şablon Gerekli</b>',
    DuplicateUserName: 'Kullanıcı sistemde kayıtlı',
    409: 'Kayıt daha önce eklendi',

    'scope.einvoice:outgoing': 'Giden EFaturalar',

    'scope.einvoice:outgoing:list:read': 'Giden EFaturalar Okuma',

    'role.EI:EDITOR': 'Fatura İşlemleri',
    'role.EI:EDITOR:CREATE_EDIT': 'Fatura Oluştur / Düzenle',

    'role.EA:EDITOR': 'Fatura İşlemleri',
    'role.EA:EDITOR:CREATE_EDIT': 'Fatura Oluştur / Düzenle',

    'role.ED:EDITOR': 'İrsaliye İşlemleri',
    'role.ED:EDITOR:CREATE_EDIT': 'İrsaliye Oluştur / Düzenle',

    'role.ESMM:EDITOR': 'Makbuz İşlemleri',
    'role.ESMM:EDITOR:CREATE_EDIT': 'Makbuz Oluştur / Düzenle',

    'role.EMM:EDITOR': 'Makbuz İşlemleri',
    'role.EMM:EDITOR:CREATE_EDIT': 'Makbuz Oluştur / Düzenle',

    'role.EI:OUT': 'Giden Kutusu',
    'role.EI:OUT:INV-ROOT': 'Giden Faturalar',
    'role.EI:OUT:INV': 'Fatura Listesini Görüntüleme',
    'role.EI:OUT:INV:XML': 'XML İndir',
    'role.EI:OUT:INV:ENVELOPE': 'Zarf XML İndir',
    'role.EI:OUT:INV:PDF': 'PDF İndir',
    'role.EI:OUT:INV:EMAIL_SEND': 'Mail Gönder',
    'role.EI:OUT:INV:HTML': 'Fatura Görüntüle',
    'role.EI:OUT:INV:SET_ARCHIVE_STATUS': 'Arşive Kaldır / Arşivden Geri Al',
    'role.EI:OUT:RM': 'Excel Rapor Modülü',
    'role.EI:OUT:RM:REPORTS': 'Rapor Listesi',
    'role.EI:OUT:RM:TEMPLATES': 'Şablonlar',
    'role.EI:OUT:RM:TEMPLATES_POST_PUT': 'Yeni Şablon / Şablon Düzenle',

    'role.EI:INC': 'Gelen Kutusu',
    'role.EI:INC:INV-ROOT': 'Gelen Faturalar',
    'role.EI:INC:INV': 'Fatura Listesini Görüntüleme',
    'role.EI:INC:INV:XML': 'XML İndir',
    'role.EI:INC:INV:ENVELOPE': 'Zarf XML İndir',
    'role.EI:INC:INV:PDF': 'PDF İndir',
    'role.EI:INC:INV:EMAIL_SEND': 'Mail Gönder',
    'role.EI:INC:INV:HTML': 'Fatura Görüntüle',
    'role.EI:INC:INV:SET_ARCHIVE_STATUS': 'Arşive Kaldır / Arşivden Geri Al',
    'role.EI:INC:INV:DOCUMENT_ANSWER': 'Faturaya Cevap Verme',
    'role.EI:INC:INV:SET_TRANSFERRED_STATUS': 'ERPye Transfer Edildi Olarak İşaretle / ERPye Transfer Edilmedi Olarak İşaretle',
    'role.EI:INC:RM': 'Excel Rapor Modülü',
    'role.EI:INC:RM:REPORTS': 'Rapor Listesi',
    'role.EI:INC:RM:TEMPLATES': 'Şablonlar',
    'role.EI:INC:RM:TEMPLATES_POST_PUT': 'Yeni Şablon / Şablon Düzenle',

    'role.EI:DEF:SERIES-ROOT': 'Seri Tanımlamaları',
    'role.EI:DEF': 'Tanımlamalar',
    'role.EI:DEF:SERIES': 'Fatura Serilerini Görüntüleme',
    'role.EI:DEF:SERIES_POST': 'Yeni Seri',
    'role.EI:DEF:SERIES_DELETE': 'Seri Sil',

    'role.EI:DEF:DOCUMENT_TEMPLATES-ROOT': 'Şablon Tanımlamaları',
    'role.EI:DEF:DOCUMENT_TEMPLATES': 'Şablon Listesini Görüntüleme',
    'role.EI:DEF:DOCUMENT_TEMPLATES_POST': 'Yeni Şablon',
    'role.EI:DEF:DOCUMENT_TEMPLATES_PUT': 'Şablon Güncelle',
    'role.EI:DEF:DOCUMENT_TEMPLATES_DELETE': 'Şablon Sil',
    'role.EI:DEF:FILE_EXPORT_TITLES': 'Dosya Adları',

    'role.EI:NOTIFY': 'Bildirim Ayarları',
    'role.EI:NOTIFY:INC:DYNAMIC_RULES': 'Gelen Fatura Bildirim',
    'role.EI:NOTIFY:OUT:DYNAMIC_RULES': 'Giden Fatura Bildirim',

    'role.EI:EX': 'Eski Faturalar',
    'role.EI:EX:INC': 'Gelen Faturalar',
    'role.EI:EX:OUT': 'Giden Faturalar',
    'role.EI:EX:QUEUE': 'Dosya Kuyruğu',
    'role.EI:EX:POST': 'Belge Yükle',

    'role.EI:COMP': 'Firma Listesi',
    'role.EI:COMP:GENERAL': 'Yurt İçi Firma Listesi',
    'role.EI:COMP:EXPORT': 'İhracat Firma Listesi',

    'role.EI:TAGS': 'Etiketler',
    'role.EI:UPLOADS_DOCUMENT': 'Belge Yükle',

    'role.ED:OUT': 'Giden Kutusu',
    'role.ED:OUT:DES-ROOT': 'Giden İrsaliyeler',
    'role.ED:OUT:DES': 'İrsaliye Listesini Görüntüleme',
    'role.ED:OUT:DES:XML': 'XML İndir',
    'role.ED:OUT:DES:ENVELOPE': 'Zarf XML İndir',
    'role.ED:OUT:DES:PDF': 'PDF İndir',
    'role.ED:OUT:DES:EMAIL_SEND': 'Mail Gönder',
    'role.ED:OUT:DES:HTML': 'İrsaliye Görüntüle',
    'role.ED:OUT:DES:SET_ARCHIVE_STATUS': 'Arşive Kaldır / Arşivden Geri Al',
    'role.ED:OUT:RM': 'Excel Rapor Modülü',
    'role.ED:OUT:RM:REPORTS': 'Rapor Listesi',
    'role.ED:OUT:RM:TEMPLATES': 'Şablonlar',
    'role.ED:OUT:RM:TEMPLATES_POST_PUT': 'Yeni Şablon / Şablon Düzenle',

    'role.ED:INC': 'Gelen Kutusu',
    'role.ED:INC:DES-ROOT': 'Gelen İrsaliyeler',
    'role.ED:INC:DES': 'İrsaliye Listesini Görüntüleme',
    'role.ED:INC:DES:XML': 'XML İndir',
    'role.ED:INC:DES:ENVELOPE': 'Zarf XML İndir',
    'role.ED:INC:DES:PDF': 'PDF İndir',
    'role.ED:INC:DES:EMAIL_SEND': 'Mail Gönder',
    'role.ED:INC:DES:HTML': 'İrsaliye Görüntüle',
    'role.ED:INC:DES:SET_ARCHIVE_STATUS': 'Arşive Kaldır / Arşivden Geri Al',
    'role.ED:INC:DES:DOCUMENT_ANSWER': 'İrsaliyeye Yanıt Verme',
    'role.ED:INC:DES:SET_TRANSFERRED_STATUS': 'ERPye Transfer Edildi Olarak İşaretle / ERPye Transfer Edilmedi Olarak İşaretle',
    'role.ED:INC:RM': 'Excel Rapor Modülü',
    'role.ED:INC:RM:REPORTS': 'Rapor Listesi',
    'role.ED:INC:RM:TEMPLATES': 'Şablonlar',
    'role.ED:INC:RM:TEMPLATES_POST_PUT': 'Yeni Şablon / Şablon Düzenle',

    'role.ED:DEF': 'Tanımlamalar',
    'role.ED:DEF:ANSWER_SERIES-ROOT': 'Yanıt Seri Tanımlamaları',
    'role.ED:DEF:ANSWER_SERIES': 'Yanıt Serilerini Görüntüleme',
    'role.ED:DEF:ANSWER_SERIES_POST': 'Yeni Seri',
    'role.ED:DEF:ANSWER_SERIES_DELETE': 'Seri Sil',

    'role.ED:DEF:SERIES-ROOT': 'İrsaliye Seri Tanımlamaları',
    'role.ED:DEF:SERIES': 'İrsaliye Serilerini Görüntüleme',
    'role.ED:DEF:SERIES_POST': 'Yeni Seri',
    'role.ED:DEF:SERIES_DELETE': 'Seri Sil',

    'role.ED:DEF:DOCUMENT_TEMPLATES-ROOT': 'Şablon Tanımlamaları',
    'role.ED:DEF:DOCUMENT_TEMPLATES': 'İrsaliye Şablon Listesini Görüntüleme',
    'role.ED:DEF:DOCUMENT_TEMPLATES_POST': 'Yeni Şablon',
    'role.ED:DEF:DOCUMENT_TEMPLATES_PUT': 'Şablon Güncelle',
    'role.ED:DEF:DOCUMENT_TEMPLATES_DELETE': 'Şablon Sil',

    'role.ED:DEF:ANSWER_DOCUMENT_TEMPLATES-ROOT': 'Yanıt Şablon Tanımlamaları',
    'role.ED:DEF:ANSWER_DOCUMENT_TEMPLATES': 'Yanıt Şablon Listesini Görüntüleme',
    'role.ED:DEF:ANSWER_DOCUMENT_TEMPLATES_POST': 'Yeni Şablon',
    'role.ED:DEF:ANSWER_DOCUMENT_TEMPLATES_PUT': 'Şablon Güncelle',
    'role.ED:DEF:ANSWER_DOCUMENT_TEMPLATES_DELETE': 'Şablon Sil',

    'role.ED:DEF:MAIL_SMS': 'Mail / Sms Ayarları',
    'role.ED:DEF:FILE_EXPORT_TITLES': 'Dosya Adları',

    'role.ED:NOTIFY': 'Bildirim Ayarları',
    'role.ED:NOTIFY:INC:DYNAMIC_RULES': 'Gelen İrsaliye Bildirim',
    'role.ED:NOTIFY:OUT:DYNAMIC_RULES': 'Giden İrsaliye Bildirim',

    'role.ED:COMP:GENERAL': 'Firma Listesi',
    'role.ED:TAGS': 'Etiketler',
    'role.ED:UPLOADS_DOCUMENT': 'Belge Yükle',

    'role.EA:OUT': 'Giden Kutusu',
    'role.EA:OUT:INV-ROOT': 'Giden Faturalar',
    'role.EA:OUT:INV': 'Fatura Listesini Görüntüleme',
    'role.EA:OUT:INV:XML': 'XML İndir',
    'role.EA:OUT:INV:PDF': 'PDF İndir',
    'role.EA:OUT:INV:EMAIL_SEND': 'Mail Gönder',
    'role.EA:OUT:INV:HTML': 'Fatura Görüntüle',
    'role.EA:OUT:INV:CANCEL': 'Faturayı İptal Et',

    'role.EA:INC': 'Gelen Kutusu',
    'role.EA:INC:INV-ROOT': 'Internet Vergi Dairesine Gelen Faturalar',
    'role.EA:INC:INV': 'Fatura Listesini Görüntüleme',

    'role.EA:OUT:CANCELED-ROOT': 'İptal Faturalar',
    'role.EA:OUT:CANCELED': 'İptal Edilen Fatura Listesini Görüntüleme',
    'role.EA:OUT:INV:CANCEL:WITHDRAW': 'İptali Geri Al',

    'role.EA:OUT:RM': 'Excel Rapor Modülü',
    'role.EA:OUT:RM:REPORTS': 'Rapor Listesi',
    'role.EA:OUT:RM:TEMPLATES': 'Şablonlar',
    'role.EA:OUT:RM:TEMPLATES_POST_PUT': 'Yeni Şablon / Şablon Düzenle',

    'role.EA:DEF': 'Tanımlamalar',
    'role.EA:DEF:SERIES-ROOT': 'Seri Tanımlamaları',
    'role.EA:DEF:SERIES': 'Fatura Serilerini Görüntüleme',
    'role.EA:DEF:SERIES_POST': 'Yeni Seri',
    'role.EA:DEF:SERIES_DELETE': 'Seri Sil',

    'role.EA:DEF:DOCUMENT_TEMPLATES-ROOT': 'Şablon Tanımlamaları',
    'role.EA:DEF:DOCUMENT_TEMPLATES': 'Şablon Listesini Görüntüleme',
    'role.EA:DEF:DOCUMENT_TEMPLATES_POST': 'Yeni Şablon',
    'role.EA:DEF:DOCUMENT_TEMPLATES_PUT': 'Şablon Güncelle',
    'role.EA:DEF:DOCUMENT_TEMPLATES_DELETE': 'Şablon Sil',
    'role.EA:DEF:MAIL_SMS': 'Mail / Sms Ayarları',
    'role.EA:DEF:FILE_EXPORT_TITLES': 'Dosya Adları',

    'role.EA:EX': 'Eski Faturalar',
    'role.EA:EX:OUT': 'Faturalar',
    'role.EA:EX:QUEUE': 'Dosya Kuyruğu',
    'role.EA:EX:POST': 'Belge Yükle',

    'role.EA:NOTIFY:OUT:DYNAMIC_RULES': 'Bildirim Ayarları',
    'role.EA:COMP:GENERAL': 'Firma Listesi',
    'role.EA:TAGS': 'Etiketler',
    'role.EA:UPLOADS_DOCUMENT': 'Belge Yükle',

    'role.ESMM:OUT': 'Giden Kutusu',
    'role.ESMM:OUT:V-ROOT': 'Giden Makbuzlar',
    'role.ESMM:OUT:V': 'Makbuz Listesini Görüntüleme',
    'role.ESMM:OUT:V:XML': 'XML İndir',
    'role.ESMM:OUT:V:PDF': 'PDF İndir',
    'role.ESMM:OUT:V:EMAIL_SEND': 'Mail Gönder',
    'role.ESMM:OUT:V:HTML': 'Makbuz Görüntüle',
    'role.ESMM:OUT:V:CANCEL': 'Makbuzu İptal Et',

    'role.ESMM:OUT:CANCELED-ROOT': 'İptal Makbulzar',
    'role.ESMM:OUT:CANCELED': 'İptal Edilen Makbuz Listesini Görüntüleme',
    'role.ESMM:OUT:V:CANCEL:WITHDRAW': 'İptali Geri Al',

    'role.ESMM:OUT:RM': 'Excel Rapor Modülü',
    'role.ESMM:OUT:RM:REPORTS': 'Rapor Listesi',
    'role.ESMM:OUT:RM:TEMPLATES': 'Şablonlar',
    'role.ESMM:OUT:RM:TEMPLATES_POST_PUT': 'Yeni Şablon / Şablon Düzenle',

    'role.ESMM:DEF': 'Tanımlamalar',
    'role.ESMM:DEF:SERIES-ROOT': 'Seri Tanımlamaları',
    'role.ESMM:DEF:SERIES': 'Makbuz Serilerini Görüntüleme',
    'role.ESMM:DEF:SERIES_POST': 'Yeni Seri',
    'role.ESMM:DEF:SERIES_DELETE': 'Seri Sil',

    'role.ESMM:DEF:DOCUMENT_TEMPLATES-ROOT': 'Şablon Tanımlamaları',
    'role.ESMM:DEF:DOCUMENT_TEMPLATES': 'Şablon Listesini Görüntüleme',
    'role.ESMM:DEF:DOCUMENT_TEMPLATES_POST': 'Yeni Şablon',
    'role.ESMM:DEF:DOCUMENT_TEMPLATES_PUT': 'Şablon Güncelle',
    'role.ESMM:DEF:DOCUMENT_TEMPLATES_DELETE': 'Şablon Sil',
    'role.ESMM:DEF:MAIL_SMS': 'Mail / Sms Ayarları',
    'role.ESMM:DEF:FILE_EXPORT_TITLES': 'Dosya Adları',

    'role.ESMM:EX': 'Eski Faturalar',
    'role.ESMM:EX:OUT': 'Faturalar',
    'role.ESMM:EX:QUEUE': 'Dosya Kuyruğu',
    'role.ESMM:EX:POST': 'Belge Yükle',

    'role.ESMM:NOTIFY:OUT:DYNAMIC_RULES': 'Bildirim Ayarları',
    'role.ESMM:COMP:GENERAL': 'Firma Listesi',
    'role.ESMM:TAGS': 'Etiketler',
    'role.ESMM:UPLOADS_DOCUMENT': 'Belge Yükle',

    'role.ADMINISTRATOR': 'Yönetim Paneli',

    'role.EMM:OUT': 'Giden Kutusu',
    'role.EMM:OUT:CRE-ROOT': 'Giden Makbuzlar',
    'role.EMM:OUT:V': 'Makbuzlar Listesini Görüntüleme',
    'role.EMM:OUT:V:XML': 'XML İndir',
    'role.EMM:OUT:V:PDF': 'PDF İndir',
    'role.EMM:OUT:V:EMAIL_SEND': 'Mail Gönder',
    'role.EMM:OUT:V:HTML': 'Makbuz Görüntüle',
    'role.EMM:OUT:V:CANCEL': 'Makbuzu İptal Et',

    'role.EMM:OUT:CANCELED-ROOT': 'İptal Makbulzar',
    'role.EMM:OUT:CANCELED': 'İptal Edilen Makbuz Listesini Görüntüleme',
    'role.EMM:OUT:V:CANCEL:WITHDRAW': 'İptali Geri Al',

    'role.EMM:OUT:RM': 'Excel Rapor Modülü',
    'role.EMM:OUT:RM:REPORTS': 'Rapor Listesi',
    'role.EMM:OUT:RM:TEMPLATES': 'Şablonlar',
    'role.EMM:OUT:RM:TEMPLATES_POST_PUT': 'Yeni Şablon / Şablon Düzenle',

    'role.EMM:DEF': 'Tanımlamalar',
    'role.EMM:DEF:SERIES-ROOT': 'Seri Tanımlamaları',
    'role.EMM:DEF:SERIES': 'Makbuz Serilerini Görüntüleme',
    'role.EMM:DEF:SERIES_POST': 'Yeni Seri',
    'role.EMM:DEF:SERIES_DELETE': 'Seri Sil',

    'role.EMM:DEF:DOCUMENT_TEMPLATES-ROOT': 'Şablon Tanımlamaları',
    'role.EMM:DEF:DOCUMENT_TEMPLATES': 'Şablon Listesini Görüntüleme',
    'role.EMM:DEF:DOCUMENT_TEMPLATES_POST': 'Yeni Şablon',
    'role.EMM:DEF:DOCUMENT_TEMPLATES_PUT': 'Şablon Güncelle',
    'role.EMM:DEF:DOCUMENT_TEMPLATES_DELETE': 'Şablon Sil',
    'role.EMM:DEF:MAIL_SMS': 'Mail / Sms Ayarları',
    'role.EMM:DEF:FILE_EXPORT_TITLES': 'Dosya Adları',

    'role.EMM:EX': 'Eski Faturalar',
    'role.EMM:EX:OUT': 'Faturalar',
    'role.EMM:EX:QUEUE': 'Dosya Kuyruğu',
    'role.EMM:EX:POST': 'Belge Yükle',

    'role.EMM:NOTIFY:OUT:DYNAMIC_RULES': 'Bildirim Ayarları',
    'role.EMM:COMP:GENERAL': 'Firma Listesi',
    'role.EMM:TAGS': 'Etiketler',
    'role.EMM:UPLOADS_DOCUMENT': 'Belge Yükle',

    'role.EA:REPORTS': 'e-Arşiv Raporları',
    'role.EA:REPORTS:CREATE': 'Manuel Rapor Oluştur',
    'role.EA:REPORTS:LIST': 'Rapor Listesi',

    'role.COMPANIES': 'Firma Listesi',

    'productType.EArchive': 'E-Arşiv',
    'productType.EInvoice': 'E-Fatura',
    'productType.ESmm': 'E-Smm',
    'productType.EMm': 'E-Mm',
    'productType.EDespatch': 'E-İrsaliye',

    WaitingToBeSigned: 'İmzalanmayı bekliyor',
    WaitingToBeEnvelopeCreate: 'Zarf oluşturulması bekleniyor',
    WaitingToBeSend: "Gib'e gönderilmeyi bekliyor",
    EnvelopeIsWaitingToBeProcessedByGib: "Gib'in zarfı işlemesi bekleniyor",
    ErrorHasBeenDetectedInEnvelopeByGib: 'Gib zarfta hata tespit etti',
    EnvelopeIsWaitingToBeSendedToReceiverByGib: 'Zarfın alıcıya iletilmesi bekleniyor',
    EnvelopeCouldNotTransferredToReceiverByGib: 'Zarf alıcıya iletilemedi',
    EnvelopeIsBeenWaitingToBeTransferredToReceiverByGib: "Gib'in zarfı alıcıya iletmesi bekleniyor",
    EnvelopeSystemResponseIsBeingWaitedFromReceiver: 'Alıcı posta kutusu yanıt bekleniyor',
    ErrorHasBeenDetectedInEnvelopeByReceiver: 'Alıcı zarfta hata tespit etti',
    EnvelopeHasBeenTransferredToReceiverSuccessfully: 'Alıcıya iletildi',

    DOKUMAN_NO_GORE_ARAMA: 'Döküman No',
    FIRMA_GORE_ARAMA: 'Firma Ünvan / VKN / TCKN',
    UUID_GORE_ARAMA: 'UUID',

    userType: {
      EInvoice: 'Kayıtlı firma',
      EArchive: 'Kayıtlı firma',
      Gib: 'GIB listesinde kayıtlı',
      New: 'Yeni Firma'
    },

    TemplateSettings: {
      Customize: 'Özelleştirme',
      GetFromNote: 'Not Özelleştir',
      GetFromAddDocRef: 'Ek Belge Özelleştir',
      NoteDescription:
        "Not alanında örneğin '##SAP_SIPARIS_NO##12458749558' şeklinde gönderilen notun '##SAP_SIPARIS_NO##' değerinden sonraki değer alınır özelleştirme alanına '12458749558' olarak eklenir.",
      AddDocRefDescription: 'AdditionalDocumentReferance açıklama alanı eklenecek',
      Title: 'Başlık',
      ValueField: 'Koşul Değeri',
      ConditionField: 'Koşul Alanı',
      ConditionValue: 'Okunacak Anahtar Değer',
      Footer: 'Alt Bilgi',
      Header: 'Üst Bilgi',
      HideBarcodeValueOnFooter: 'Barcod Altında Değeri Gizle?',
      Show: 'Göster',
      Hide: 'Gizle',
      Canceled: 'İşlem İptal Edilsin Mi?',
      Save: 'Kaydet',
      Cancel: 'İptal Et',
      Duzenle: 'Düzenle',
      Sil: 'Sil',
      BarcodeHeight: 'Barkod Yüksekliği',
      BarcodeWidth: 'Barkod Genişliği',
      BarcodeValue: 'Barkod Değeri',
      Barcodes: 'Barkod Ayarları'
    },

    OZELLIK_GUNCELLENDI: '<b>{title}</b> isimli Özellik Güncellendi.',
    OZELLIK_EKLENDI: '<b>{title}</b> isimli Özellik Eklendi.',
    OZELLIK: 'Özellik',
    OZELLIK_GUNCELLE: 'Özellik Güncelle',
    OZELLIK_EKLE: 'Yeni Özellik Ekle',
    OZELLIK_SILINECEKTIR_DEVAM_EDILSIN_MI: '<b>{title}</b>, Özelliği silinecektir. <br/>İşleme devam edilsin mi?',
    OZELLIK_SILINDI: '<b>{title}</b>, Özelliği silindi.',

    NOT_SILINECEK_DEVAM_EDILSIN_MI: '<b>{title}</b>, Not Silinecek Devam Edilsin Mi?.',
    NOT_BASARI_ILE_SILINDI: '<b>{title}</b>, Not Başarı İle Silindi?.',
    EK_NOTLAR: 'Ek Notlar',
    ADD_NOTE_DESCRIPTION: 'Faturalarınızda görünmesini istediğiniz notlar varsa bu alandan ekleyebilirsiniz',

    GENEL_GORUNTU_AYARLARI: 'Genel Görüntü Ayarları',
    BANKA_BILGILERI_FATURA_UZERINDE_GORUNSUN: '<b>Banka Bilgileri</b>, fatura üzerinde görünsün.',
    IMZA_FATURA_UZERINDE_GORUNSUN: '<b>İmza</b>, fatura üzerinde görünsün.',
    LOGO_FATURA_UZERINDE_GORUNSUN: '<b>Logo</b>, fatura üzerinde görünsün.',
    KASE_FATURA_UZERINDE_GORUNSUN: '<b>Kaşe</b>, fatura üzerinde görünsün.',
    DOVIZLI_FATURALARDA_TURK_LIRASI_TOPLAMI_GORUNSUN: '<b>Dövizli Faturalarda</b>, Türk Lirası toplamları görünsün.',

    EFATURA_GORUNTU_AYARLARI_GUNCELLENDI: 'E-Fatura Görüntü Ayarları Güncellendi.',
    EARSIV_GORUNTU_AYARLARI_GUNCELLENDI: 'E-Arşiv Görüntü Ayarları Güncellendi.',
    ESMM_GORUNTU_AYARLARI_GUNCELLENDI: 'E-SMM Görüntü Ayarları Güncellendi.',
    EIRSALIYE_GORUNTU_AYARLARI_GUNCELLENDI: 'E-İrsaliye Görüntü Ayarları Güncellendi.',

    KARAKTER_BOYUTU_EN_AZ_ON_OLMALIDIR: 'Karakter boyutu en az 10 olmalıdır',
    KARAKTER_BOYUTU_EN_COK_ONBES_OLMALIDIR: 'Karakter boyutu en fazla 15 olmalıdır',

    KARAKTER_BOYUTU_AYARLARI: 'Karakter Boyutu Ayarları',
    KARAKTER_BOYUTU_AYARLARI_GUNCELLENDI: 'Karakter Boyutu Ayarları Guncellendi.',
    YAZI_TIPI_BOYUTU_KALIN: 'Yazı tipi boyutu kalınlığı?',
    KARAKTER_BOYUTU: 'Karakter Boyutu: ',
    BANKA_BILGILERI_GUNCELLENDI: 'Banka Bilgileri Guncellendi.',
    YENI_COLON_DAHA_ONCE_EKLENMIS: 'Yeni kolon başlıklı bir kolon daha önce eklenmiş. Önce başlığı değiştirin!',
    AYNI_KOLON_DAHA_ONCE_EKLENMIS: 'Aynı Kolon Daha Once Eklenmiş!',
    SUTUNU_SOLA_KAYDIR: ' Sütunu Sola Kaydır',
    SUTUNU_SAGA_KAYDIR: 'Sütunu Sağa Kaydır',
    SOLA_SUTUN_EKLE: 'Sola Sütun Ekle',
    SAGA_SUTUN_EKLE: 'Sağa Sütun Ekle',
    SUTUN_SIL: 'Sütun Sil',
    BANKA_BILGILERI: 'Banka Bilgileri',
    SATIR_EKLE: 'Satır Ekle',

    GORSEL_BASARI_ILE_EKLENDI: '<b>{title}</b> Başarı İle Eklendi.',
    YUKLENMIS_BIR_ICERIK_BULUNMAMAKTADIR: 'Yüklenmiş bir <b>{title}</b> bulunmamaktadır!',
    FIRMA_LOGOSU: 'Firma Logosu',
    FIRMA_LOGOSU_ACIKLAMA: 'Doküman (Fatura/İrsaliye/Makbuz vb) tasarımlarında kullanılacak olan logoyu bu ekrandan yükleyebilirsiniz.',

    YETKILI_IMZA: 'Yetkili İmzası',
    YETKILI_IMZA_ACIKLAMA:
      'e-Arşiv Fatura ve e-Serbest Meslek Makbuzlarında kullanılacak olan imzayı (Yetkili imzası) bu ekrandan yükleyebilirsiniz. (Zorunlu*)',
    FIRMA_KASE: 'Firma Kaşe',
    FIRMA_KASE_ACIKLAMA: 'e-Faturalarda firmanıza ait kaşe kullanmak isterseniz bu ekrandan yükleyebilirsiniz.',

    GORUNTULEMEK_ICIN_TIKLAYABILIRSINIZ: 'Görüntülemek İçin Tıklayabilirsiniz!'
  }
);

export default trTR;
