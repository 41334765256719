// tslint:disable
// eslint:disable
/**
 * E-MM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FileExportTitleDocumentType } from '../interfaces';
// @ts-ignore
import { FileExportTitleExtensionParameter } from '../interfaces';
// @ts-ignore
import { FileExportTitleSettingDto } from '../interfaces';
/**
 * FileExportTitlesApi - axios parameter creator
 * @export
 */
export const FileExportTitlesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Daha önce tanımlanmış olan başlıkları çağırmak için bu uç kullanılabilir.
         * @summary Tanımları getirir
         * @param {FileExportTitleDocumentType} documentType **Döküman tipi**
         * @param {FileExportTitleExtensionParameter} extension **Dosya uzantısı** : *PDF için farklı XML ler için farklı dosya başlık tanımlaması yapabilirsiniz*
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsFileexporttitlesDocumentTypeExtensionGet: async (requestParameters: V1DefinitionsFileexporttitlesDocumentTypeExtensionGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentType' is not null or undefined
            if (requestParameters.documentType === null || requestParameters.documentType === undefined) {
                throw new RequiredError('documentType','Required parameter documentType was null or undefined when calling v1DefinitionsFileexporttitlesDocumentTypeExtensionGet.');
            }
            // verify required parameter 'extension' is not null or undefined
            if (requestParameters.extension === null || requestParameters.extension === undefined) {
                throw new RequiredError('extension','Required parameter extension was null or undefined when calling v1DefinitionsFileexporttitlesDocumentTypeExtensionGet.');
            }
            const localVarPath = `/v1/definitions/fileexporttitles/{documentType}/{extension}`.replace(`{${"documentType"}}`, encodeURIComponent(String(requestParameters.documentType))).replace(`{${"extension"}}`, encodeURIComponent(String(requestParameters.extension)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Dosya başlık tanımlamalarında kullanılabilecek kolonlara bu api ucu ile alınabilir            > **documentType** parametresiyle belge türüne göre *kullanılabilir* başlıkları çekebilirsiniz.
         * @summary Kullanılabilir alanları listeler
         * @param {FileExportTitleDocumentType} documentType **Döküman tipi**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsFileexporttitlesDocumentTypeTitlekeysGet: async (requestParameters: V1DefinitionsFileexporttitlesDocumentTypeTitlekeysGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentType' is not null or undefined
            if (requestParameters.documentType === null || requestParameters.documentType === undefined) {
                throw new RequiredError('documentType','Required parameter documentType was null or undefined when calling v1DefinitionsFileexporttitlesDocumentTypeTitlekeysGet.');
            }
            const localVarPath = `/v1/definitions/fileexporttitles/{documentType}/titlekeys`.replace(`{${"documentType"}}`, encodeURIComponent(String(requestParameters.documentType)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Daha önce tanımlanmış olan başlıkları düzenlemek için bu uç kullanılabilir.
         * @summary Tanımları günceller
         * @param {FileExportTitleSettingDto} fileExportTitleSettingDto #### Dosya başlık tanımlarını düzenleme için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsFileexporttitlesPut: async (requestParameters: V1DefinitionsFileexporttitlesPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileExportTitleSettingDto' is not null or undefined
            if (requestParameters.fileExportTitleSettingDto === null || requestParameters.fileExportTitleSettingDto === undefined) {
                throw new RequiredError('fileExportTitleSettingDto','Required parameter fileExportTitleSettingDto was null or undefined when calling v1DefinitionsFileexporttitlesPut.');
            }
            const localVarPath = `/v1/definitions/fileexporttitles`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.fileExportTitleSettingDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.fileExportTitleSettingDto !== undefined ? requestParameters.fileExportTitleSettingDto : {}) : (requestParameters.fileExportTitleSettingDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileExportTitlesApi - functional programming interface
 * @export
 */
export const FileExportTitlesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Daha önce tanımlanmış olan başlıkları çağırmak için bu uç kullanılabilir.
         * @summary Tanımları getirir
         * @param {FileExportTitleDocumentType} documentType **Döküman tipi**
         * @param {FileExportTitleExtensionParameter} extension **Dosya uzantısı** : *PDF için farklı XML ler için farklı dosya başlık tanımlaması yapabilirsiniz*
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsFileexporttitlesDocumentTypeExtensionGet(requestParameters: V1DefinitionsFileexporttitlesDocumentTypeExtensionGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await FileExportTitlesApiAxiosParamCreator(configuration).v1DefinitionsFileexporttitlesDocumentTypeExtensionGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Dosya başlık tanımlamalarında kullanılabilecek kolonlara bu api ucu ile alınabilir            > **documentType** parametresiyle belge türüne göre *kullanılabilir* başlıkları çekebilirsiniz.
         * @summary Kullanılabilir alanları listeler
         * @param {FileExportTitleDocumentType} documentType **Döküman tipi**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsFileexporttitlesDocumentTypeTitlekeysGet(requestParameters: V1DefinitionsFileexporttitlesDocumentTypeTitlekeysGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await FileExportTitlesApiAxiosParamCreator(configuration).v1DefinitionsFileexporttitlesDocumentTypeTitlekeysGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Daha önce tanımlanmış olan başlıkları düzenlemek için bu uç kullanılabilir.
         * @summary Tanımları günceller
         * @param {FileExportTitleSettingDto} fileExportTitleSettingDto #### Dosya başlık tanımlarını düzenleme için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsFileexporttitlesPut(requestParameters: V1DefinitionsFileexporttitlesPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await FileExportTitlesApiAxiosParamCreator(configuration).v1DefinitionsFileexporttitlesPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * FileExportTitlesApi - factory interface
 * @export
 */
export const FileExportTitlesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Daha önce tanımlanmış olan başlıkları çağırmak için bu uç kullanılabilir.
         * @summary Tanımları getirir
         * @param {FileExportTitleDocumentType} documentType **Döküman tipi**
         * @param {FileExportTitleExtensionParameter} extension **Dosya uzantısı** : *PDF için farklı XML ler için farklı dosya başlık tanımlaması yapabilirsiniz*
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsFileexporttitlesDocumentTypeExtensionGet(requestParameters: V1DefinitionsFileexporttitlesDocumentTypeExtensionGetRequest, options?: any): AxiosPromise<Array<string>> {
            return FileExportTitlesApiFp(configuration).v1DefinitionsFileexporttitlesDocumentTypeExtensionGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Dosya başlık tanımlamalarında kullanılabilecek kolonlara bu api ucu ile alınabilir            > **documentType** parametresiyle belge türüne göre *kullanılabilir* başlıkları çekebilirsiniz.
         * @summary Kullanılabilir alanları listeler
         * @param {FileExportTitleDocumentType} documentType **Döküman tipi**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsFileexporttitlesDocumentTypeTitlekeysGet(requestParameters: V1DefinitionsFileexporttitlesDocumentTypeTitlekeysGetRequest, options?: any): AxiosPromise<Array<string>> {
            return FileExportTitlesApiFp(configuration).v1DefinitionsFileexporttitlesDocumentTypeTitlekeysGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Daha önce tanımlanmış olan başlıkları düzenlemek için bu uç kullanılabilir.
         * @summary Tanımları günceller
         * @param {FileExportTitleSettingDto} fileExportTitleSettingDto #### Dosya başlık tanımlarını düzenleme için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsFileexporttitlesPut(requestParameters: V1DefinitionsFileexporttitlesPutRequest, options?: any): AxiosPromise<void> {
            return FileExportTitlesApiFp(configuration).v1DefinitionsFileexporttitlesPut(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileExportTitlesApi - object-oriented interface
 * @export
 * @class FileExportTitlesApi
 * @extends {BaseAPI}
 */
export class FileExportTitlesApi extends BaseAPI {
    /**
     * Daha önce tanımlanmış olan başlıkları çağırmak için bu uç kullanılabilir.
     * @summary Tanımları getirir
     * @param {FileExportTitleDocumentType} documentType **Döküman tipi**
     * @param {FileExportTitleExtensionParameter} extension **Dosya uzantısı** : *PDF için farklı XML ler için farklı dosya başlık tanımlaması yapabilirsiniz*
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileExportTitlesApi
     */
    public v1DefinitionsFileexporttitlesDocumentTypeExtensionGet(requestParameters: V1DefinitionsFileexporttitlesDocumentTypeExtensionGetRequest, options?: any) {
        return FileExportTitlesApiFp(this.configuration).v1DefinitionsFileexporttitlesDocumentTypeExtensionGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Dosya başlık tanımlamalarında kullanılabilecek kolonlara bu api ucu ile alınabilir            > **documentType** parametresiyle belge türüne göre *kullanılabilir* başlıkları çekebilirsiniz.
     * @summary Kullanılabilir alanları listeler
     * @param {FileExportTitleDocumentType} documentType **Döküman tipi**
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileExportTitlesApi
     */
    public v1DefinitionsFileexporttitlesDocumentTypeTitlekeysGet(requestParameters: V1DefinitionsFileexporttitlesDocumentTypeTitlekeysGetRequest, options?: any) {
        return FileExportTitlesApiFp(this.configuration).v1DefinitionsFileexporttitlesDocumentTypeTitlekeysGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Daha önce tanımlanmış olan başlıkları düzenlemek için bu uç kullanılabilir.
     * @summary Tanımları günceller
     * @param {FileExportTitleSettingDto} fileExportTitleSettingDto #### Dosya başlık tanımlarını düzenleme için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileExportTitlesApi
     */
    public v1DefinitionsFileexporttitlesPut(requestParameters: V1DefinitionsFileexporttitlesPutRequest, options?: any) {
        return FileExportTitlesApiFp(this.configuration).v1DefinitionsFileexporttitlesPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1DefinitionsFileexporttitlesDocumentTypeExtensionGetRequest {
    /**
     * **Döküman tipi**
     */
    documentType: FileExportTitleDocumentType;
    /**
     * **Dosya uzantısı** : *PDF için farklı XML ler için farklı dosya başlık tanımlaması yapabilirsiniz*
     */
    extension: FileExportTitleExtensionParameter;
    }
    export interface V1DefinitionsFileexporttitlesDocumentTypeTitlekeysGetRequest {
    /**
     * **Döküman tipi**
     */
    documentType: FileExportTitleDocumentType;
    }
    export interface V1DefinitionsFileexporttitlesPutRequest {
    /**
     * #### Dosya başlık tanımlarını düzenleme için gönderilmesi gereken model
     */
    fileExportTitleSettingDto: FileExportTitleSettingDto;
    }
