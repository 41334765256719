/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLAttribute, XMLText } from '@/xmldom-decorators';

/*
  27.04.2021 : Fully completed
*/
export class QuantityType {
  @XMLAttribute()
  unitCode: string = '';

  @XMLText({ type: Number })
  value: number = 0;
}

export class QuantityTypeNoRequiredUnitCode {
  @XMLAttribute()
  unitCode?;

  @XMLText({ type: Number })
  value: number = 0;
}

export class MeasureType extends QuantityType {}

export default QuantityType;
