import { OnClickProps } from '@/interfaces';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuItem from 'antd/lib/menu/MenuItem';
import React from 'react';
import intl from 'react-intl-universal';

export const CreateDraft = (props: OnClickProps) => {
  return (
    <MenuItem {...props}>
      <FontAwesomeIcon className={'mr-2'} icon={faPlus} />
      Taslak Oluştur
    </MenuItem>
  );
};

export default CreateDraft;
