import React, { memo } from 'react';
import intl from 'react-intl-universal';
import { SmartTooltip } from '.';

export const TelephoneTelefax = memo(({ telephone, telefax }: { telephone?: string | null; telefax?: string | null }) => {
  return (
    <SmartTooltip
      data={[
        { size: 15, title: intl.get('TELEFON'), value: telephone, makeTooltipTitle: true },
        { size: 15, title: intl.get('FAX'), value: telefax, makeTooltipTitle: true }
      ]}
    />
  );
});

export default TelephoneTelefax;
