import { AuthRole, AuthScope } from '@/interfaces';
import {
  faArchive,
  faArrowUp,
  faBan,
  faBellOn,
  faBook,
  faCloudUpload,
  faCogs,
  faEdit,
  faFile,
  faArrowDown,
  faFileCode,
  faFileExcel,
  faFilePdf,
  faFileSignature,
  faHistory,
  faList,
  faListUl,
  faMailbox,
  faPencil,
  faPencilAlt,
  faPlus,
  faSearch,
  faShare,
  faTable,
  faTags,
  faTrash,
  faUndo,
  faUsers
} from '@fortawesome/pro-regular-svg-icons';

export const EARCHIVE_EDITOR_ROLES: AuthRole = {
  value: 'EA:EDITOR',
  icon: faPencilAlt,
  checkable: false,
  children: [
    {
      value: 'EA:EDITOR:CREATE_EDIT',
      checkable: true,
      icon: faPlus
    }
  ]
};

export const EARCHIVE_OUTGOUNG_ROLES: AuthRole = {
  value: 'EA:OUT',
  icon: faArrowUp,
  checkable: false,
  children: [
    {
      value: 'EA:OUT:INV-ROOT',
      checkable: false,
      icon: faFile,
      children: [
        {
          value: 'EA:OUT:INV',
          checkable: true,
          icon: faFile
        },
        {
          value: 'EA:OUT:INV:XML',
          checkable: true,
          icon: faFileCode
        },
        {
          value: 'EA:OUT:INV:PDF',
          checkable: true,
          icon: faFilePdf
        },
        {
          value: 'EA:OUT:INV:EMAIL_SEND',
          checkable: true,
          icon: faShare
        },
        {
          value: 'EA:OUT:INV:HTML',
          checkable: true,
          icon: faSearch
        },
        {
          value: 'EA:OUT:INV:CANCEL',
          checkable: true,
          icon: faArchive
        }
      ]
    },
    {
      checkable: false,
      value: 'EA:OUT:CANCELED-ROOT',
      icon: faBan,
      children: [
        {
          checkable: true,
          value: 'EA:OUT:CANCELED',
          icon: faBan
        },
        {
          value: 'EA:OUT:INV:CANCEL:WITHDRAW',
          checkable: true,
          icon: faUndo
        }
      ]
    },
    {
      checkable: false,
      value: 'EA:OUT:RM',
      icon: faFileExcel,
      children: [
        {
          value: 'EA:OUT:RM:REPORTS',
          icon: faTable,
          checkable: true
        },
        {
          value: 'EA:OUT:RM:TEMPLATES',
          icon: faTags,
          checkable: true
        },
        {
          value: 'EA:OUT:RM:TEMPLATES_POST_PUT',
          icon: faEdit,
          checkable: true
        }
      ]
    }
  ]
};

export const EARCHIVE_INCOMING_ROLES: AuthRole = {
  value: 'EA:INC',
  icon: faArrowDown,
  checkable: false,
  children: [
    {
      value: 'EA:INC:INV-ROOT',
      checkable: false,
      icon: faFile,
      children: [
        {
          value: 'EA:INC:LIST',
          checkable: true,
          icon: faFile
        }
      ]
    }
  ]
};

export const EARCHIVE_DEFINATIONS_ROLES: AuthRole = {
  value: 'EA:DEF',
  icon: faCogs,
  checkable: false,
  children: [
    {
      value: 'EA:DEF:SERIES-ROOT',
      checkable: false,
      icon: faListUl,
      children: [
        {
          value: 'EA:DEF:SERIES',
          checkable: true,
          icon: faListUl
        },
        {
          value: 'EA:DEF:SERIES_POST',
          icon: faEdit,
          checkable: true
        },
        {
          value: 'EA:DEF:SERIES_DELETE',
          icon: faTrash,
          checkable: true
        }
      ]
    },
    {
      value: 'EA:DEF:DOCUMENT_TEMPLATES-ROOT',
      checkable: false,
      icon: faTags,
      children: [
        {
          value: 'EA:DEF:DOCUMENT_TEMPLATES',
          checkable: true,
          icon: faTags
        },
        {
          value: 'EA:DEF:DOCUMENT_TEMPLATES_POST',
          icon: faEdit,
          checkable: true
        },
        {
          value: 'EA:DEF:DOCUMENT_TEMPLATES_PUT',
          icon: faPencil,
          checkable: true
        },
        {
          value: 'EA:DEF:DOCUMENT_TEMPLATES_DELETE',
          icon: faTrash,
          checkable: true
        }
      ]
    },
    {
      value: 'EA:DEF:MAIL_SMS',
      icon: faMailbox,
      checkable: true
    },
    {
      value: 'EA:DEF:FILE_EXPORT_TITLES',
      icon: faFileSignature,
      checkable: true
    }
  ]
};

export const EARCHIVE_TAGS_ROLES: AuthRole = {
  value: 'EA:TAGS',
  icon: faTags,
  checkable: true
};

export const EARCHIVE_UPLOADS_ROLES: AuthRole = {
  value: 'EA:UPLOADS_DOCUMENT',
  icon: faCloudUpload,
  checkable: true
};

export const EARCHIVE_NOTIFICATIONS_ROLES: AuthRole = {
  value: 'EA:NOTIFY:OUT:DYNAMIC_RULES',
  icon: faBellOn,
  checkable: true
};

export const EARCHIVE_EX_INVOICE_ROLES: AuthRole = {
  value: 'EA:EX',
  icon: faHistory,
  checkable: false,
  children: [
    {
      value: 'EA:EX:OUT',
      checkable: true,
      icon: faBook
    },
    {
      value: 'EA:EX:QUEUE',
      checkable: true,
      icon: faList
    },
    {
      value: 'EA:EX:POST',
      checkable: true,
      icon: faCloudUpload
    }
  ]
};

export const EARCHIVE_ROLES = [
  EARCHIVE_EDITOR_ROLES,
  EARCHIVE_OUTGOUNG_ROLES,
  EARCHIVE_DEFINATIONS_ROLES,
  EARCHIVE_EX_INVOICE_ROLES,
  EARCHIVE_NOTIFICATIONS_ROLES,
  EARCHIVE_TAGS_ROLES,
  EARCHIVE_UPLOADS_ROLES
];

export const EARCHIVE_OUTGOING_SCOPES: AuthScope = {
  value: 'earchive:outgoing',
  checkable: false,
  children: [
    {
      value: 'earchive:outgoing:list:read'
    },
    {
      value: 'earchive:outgoing:list:write'
    },
    {
      value: 'earchive:outgoing:canceled:read'
    },
    {
      value: 'earchive:outgoing:canceled:write'
    },
    {
      value: 'earchive:outgoing:excelreport:read'
    },
    {
      value: 'earchive:outgoing:excelreport:write'
    }
  ]
};

export const EARCHIVE_INCOMING_SCOPES: AuthScope = {
  value: 'earchive:incoming',
  checkable: false,
  children: [
    {
      value: 'earchive:incoming:documents:read'
    },
    {
      value: 'earchive:incoming:documents:write'
    }
  ]
};

export const EARCHOVE_TAG_SCOPES: AuthScope = {
  value: 'earchive:tags',
  checkable: false,
  children: [
    {
      value: 'earchive:tags:read'
    },
    {
      value: 'earchive:tags:write'
    }
  ]
};

export const EARCHIVE_DEFINATIONS_SCOPES: AuthScope = {
  value: 'earchive:definations',
  checkable: false,
  children: [
    {
      value: 'earchive:definations:documentserie:read'
    },
    {
      value: 'earchive:definations:documentserie:write'
    },
    {
      value: 'earchive:definations:documenttemplate:read'
    },
    {
      value: 'earchive:definations:documenttemplate:write'
    },
    {
      value: 'earchive:definations:mailsms:read'
    },
    {
      value: 'earchive:definations:mailsms:write'
    },
    {
      value: 'earchive:definations:filetitle:read'
    },
    {
      value: 'earchive:definations:filetitle:write'
    }
  ]
};

export const EARCHIVE_NOTIFICATION_SCOPES: AuthScope = {
  value: 'earchive:notifications',
  checkable: false,
  children: [
    {
      value: 'earchive:notifications:dynamic:read'
    },
    {
      value: 'earchive:notifications:dynamic:write'
    }
  ]
};

export const EARCHIVE_UPLOAD_SCOPES: AuthScope = {
  value: 'earchive:upload',
  checkable: false,
  children: [
    {
      value: 'earchive:upload:write'
    }
  ]
};

export const EARCHIVE_EXINVOICE_SCOPES: AuthScope = {
  value: 'earchive:ex_invoice',
  checkable: false,
  children: [
    {
      value: 'earchive:exinvoice:read'
    },
    {
      value: 'earchive:exinvoice:write'
    },
    {
      value: 'earchive:exinvoice:queue:write'
    },
    {
      value: 'earchive:exinvoice:queue:read'
    }
  ]
};

export const EARCHIVE_SCOPES = [
  EARCHIVE_OUTGOING_SCOPES,
  EARCHOVE_TAG_SCOPES,
  EARCHIVE_DEFINATIONS_SCOPES,
  EARCHIVE_NOTIFICATION_SCOPES,
  EARCHIVE_UPLOAD_SCOPES,
  EARCHIVE_EXINVOICE_SCOPES
];
