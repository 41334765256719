// tslint:disable
// eslint:disable
/**
 * E-MM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MailSetting } from '../interfaces';
// @ts-ignore
import { MailSettingDto } from '../interfaces';
// @ts-ignore
import { SmsSetting } from '../interfaces';
// @ts-ignore
import { SmsSettingDto } from '../interfaces';
/**
 * MailingApi - axios parameter creator
 * @export
 */
export const MailingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Mail ayarlarınıza bu uç ile ulaşabilirsiniz.
         * @summary Mail ayarlarını getirir
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsMailingEmailSettingsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/definitions/mailing/email/settings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mail ayarlarını güncellemek için bu uç kullanılabilir.
         * @summary Mail ayarlarını günceller
         * @param {MailSettingDto} mailSettingDto #### Mail ayarlarının güncellenmesi için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsMailingEmailSettingsPut: async (requestParameters: V1DefinitionsMailingEmailSettingsPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailSettingDto' is not null or undefined
            if (requestParameters.mailSettingDto === null || requestParameters.mailSettingDto === undefined) {
                throw new RequiredError('mailSettingDto','Required parameter mailSettingDto was null or undefined when calling v1DefinitionsMailingEmailSettingsPut.');
            }
            const localVarPath = `/v1/definitions/mailing/email/settings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.mailSettingDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.mailSettingDto !== undefined ? requestParameters.mailSettingDto : {}) : (requestParameters.mailSettingDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sms ayarlarınıza bu uç ile ulaşabilirsiniz.
         * @summary Sms ayarlarını getirir
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsMailingSmsSettingsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/definitions/mailing/sms/settings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sms ayarlarını güncellemek için bu uç kullanılabilir.
         * @summary Sms ayarlarını günceller
         * @param {SmsSettingDto} smsSettingDto #### Sms ayarlarının güncellenmesi için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsMailingSmsSettingsPut: async (requestParameters: V1DefinitionsMailingSmsSettingsPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'smsSettingDto' is not null or undefined
            if (requestParameters.smsSettingDto === null || requestParameters.smsSettingDto === undefined) {
                throw new RequiredError('smsSettingDto','Required parameter smsSettingDto was null or undefined when calling v1DefinitionsMailingSmsSettingsPut.');
            }
            const localVarPath = `/v1/definitions/mailing/sms/settings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.smsSettingDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.smsSettingDto !== undefined ? requestParameters.smsSettingDto : {}) : (requestParameters.smsSettingDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MailingApi - functional programming interface
 * @export
 */
export const MailingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Mail ayarlarınıza bu uç ile ulaşabilirsiniz.
         * @summary Mail ayarlarını getirir
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsMailingEmailSettingsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailSetting>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(configuration).v1DefinitionsMailingEmailSettingsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Mail ayarlarını güncellemek için bu uç kullanılabilir.
         * @summary Mail ayarlarını günceller
         * @param {MailSettingDto} mailSettingDto #### Mail ayarlarının güncellenmesi için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsMailingEmailSettingsPut(requestParameters: V1DefinitionsMailingEmailSettingsPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(configuration).v1DefinitionsMailingEmailSettingsPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Sms ayarlarınıza bu uç ile ulaşabilirsiniz.
         * @summary Sms ayarlarını getirir
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsMailingSmsSettingsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SmsSetting>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(configuration).v1DefinitionsMailingSmsSettingsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Sms ayarlarını güncellemek için bu uç kullanılabilir.
         * @summary Sms ayarlarını günceller
         * @param {SmsSettingDto} smsSettingDto #### Sms ayarlarının güncellenmesi için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DefinitionsMailingSmsSettingsPut(requestParameters: V1DefinitionsMailingSmsSettingsPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(configuration).v1DefinitionsMailingSmsSettingsPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MailingApi - factory interface
 * @export
 */
export const MailingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Mail ayarlarınıza bu uç ile ulaşabilirsiniz.
         * @summary Mail ayarlarını getirir
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsMailingEmailSettingsGet(options?: any): AxiosPromise<MailSetting> {
            return MailingApiFp(configuration).v1DefinitionsMailingEmailSettingsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Mail ayarlarını güncellemek için bu uç kullanılabilir.
         * @summary Mail ayarlarını günceller
         * @param {MailSettingDto} mailSettingDto #### Mail ayarlarının güncellenmesi için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsMailingEmailSettingsPut(requestParameters: V1DefinitionsMailingEmailSettingsPutRequest, options?: any): AxiosPromise<void> {
            return MailingApiFp(configuration).v1DefinitionsMailingEmailSettingsPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Sms ayarlarınıza bu uç ile ulaşabilirsiniz.
         * @summary Sms ayarlarını getirir
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsMailingSmsSettingsGet(options?: any): AxiosPromise<SmsSetting> {
            return MailingApiFp(configuration).v1DefinitionsMailingSmsSettingsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Sms ayarlarını güncellemek için bu uç kullanılabilir.
         * @summary Sms ayarlarını günceller
         * @param {SmsSettingDto} smsSettingDto #### Sms ayarlarının güncellenmesi için gönderilmesi gereken model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DefinitionsMailingSmsSettingsPut(requestParameters: V1DefinitionsMailingSmsSettingsPutRequest, options?: any): AxiosPromise<void> {
            return MailingApiFp(configuration).v1DefinitionsMailingSmsSettingsPut(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MailingApi - object-oriented interface
 * @export
 * @class MailingApi
 * @extends {BaseAPI}
 */
export class MailingApi extends BaseAPI {
    /**
     * Mail ayarlarınıza bu uç ile ulaşabilirsiniz.
     * @summary Mail ayarlarını getirir
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public v1DefinitionsMailingEmailSettingsGet(options?: any) {
        return MailingApiFp(this.configuration).v1DefinitionsMailingEmailSettingsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mail ayarlarını güncellemek için bu uç kullanılabilir.
     * @summary Mail ayarlarını günceller
     * @param {MailSettingDto} mailSettingDto #### Mail ayarlarının güncellenmesi için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public v1DefinitionsMailingEmailSettingsPut(requestParameters: V1DefinitionsMailingEmailSettingsPutRequest, options?: any) {
        return MailingApiFp(this.configuration).v1DefinitionsMailingEmailSettingsPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sms ayarlarınıza bu uç ile ulaşabilirsiniz.
     * @summary Sms ayarlarını getirir
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public v1DefinitionsMailingSmsSettingsGet(options?: any) {
        return MailingApiFp(this.configuration).v1DefinitionsMailingSmsSettingsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sms ayarlarını güncellemek için bu uç kullanılabilir.
     * @summary Sms ayarlarını günceller
     * @param {SmsSettingDto} smsSettingDto #### Sms ayarlarının güncellenmesi için gönderilmesi gereken model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public v1DefinitionsMailingSmsSettingsPut(requestParameters: V1DefinitionsMailingSmsSettingsPutRequest, options?: any) {
        return MailingApiFp(this.configuration).v1DefinitionsMailingSmsSettingsPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1DefinitionsMailingEmailSettingsPutRequest {
    /**
     * #### Mail ayarlarının güncellenmesi için gönderilmesi gereken model
     */
    mailSettingDto: MailSettingDto;
    }
    export interface V1DefinitionsMailingSmsSettingsPutRequest {
    /**
     * #### Sms ayarlarının güncellenmesi için gönderilmesi gereken model
     */
    smsSettingDto: SmsSettingDto;
    }
