import { OnClickProps } from '@/interfaces';
import { faListAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuItem from 'antd/lib/menu/MenuItem';
import React from 'react';
import intl from 'react-intl-universal';

export const DocumentDetail = (props: OnClickProps) => {
  return (
    <MenuItem {...props}>
      <FontAwesomeIcon className={'mr-2'} icon={faListAlt} />
      {intl.get('BELGE_DETAYLARI')}
    </MenuItem>
  );
};

export default DocumentDetail;
