import ThemeContext from '@/contexts/ThemeContext';
import { AnyType } from '@/type';
import { faEnvelopeOpen, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import { debounce } from 'lodash';
import React, { useContext, useEffect } from 'react';
import style from './style.module.scss';

const Sidebar = () => {
  const [state, dispatch] = useContext(ThemeContext);

  // Ensure `changeSetting` is invoked once after 200 milisecond of debounced calls.
  const changeSetting = debounce((key, value) => {
    dispatch({ type: 'CHANGE', key, value });
  }, 200);

  const changeTheme = () => {
    const { theme } = state;
    const nextTheme = theme === 'default' ? 'dark' : 'default';
    document.querySelector('html')?.setAttribute('data-kit-theme', nextTheme);
    changeSetting('theme', nextTheme);
    localStorage.setItem('apps.theme', nextTheme);
  };

  const changePrimaryColor = () => {
    const { primaryColor } = state;
    const styleElement = document.querySelector('#primaryColor');
    if (styleElement) {
      styleElement.remove();
    }
    const body = document.querySelector('body');
    const styleEl = document.createElement('style');
    const css = document.createTextNode(`:root { --kit-color-primary: ${primaryColor};}`);
    styleEl.setAttribute('id', 'primaryColor');
    styleEl.appendChild(css);
    body?.appendChild(styleEl);
  };

  useEffect(() => {
    document.querySelector('html')?.setAttribute('data-kit-theme', state.theme);
    changePrimaryColor();
  }, [state.theme, state.primaryColor]);

  const ColorPicker = (props: AnyType) => {
    return props.colors.map((item) => {
      return (
        <a
          href="#"
          key={item}
          onClick={() => changeSetting(props.setting, item)}
          className={classNames(`${style.air__sidebar__select__item}`, {
            [style.air__sidebar__select__item__active]: props.value === item,
            [style.air__sidebar__select__item__black]: item === 'dark',
            [style.air__sidebar__select__item__white]: item === 'white',
            [style.air__sidebar__select__item__gray]: item === 'gray',
            [style.air__sidebar__select__item__blue]: item === 'blue',
            [style.air__sidebar__select__item__img]: item === 'image'
          })}
        />
      );
    });
  };

  return (
    <div>
      {/* <div
        className={classNames(style.air__sidebar, {
          [style.air__sidebar__toggled]: state.isSidebarOpen
        })}
      >
        <Scrollbars
          autoHide
          renderThumbVertical={({ ...props }) => (
            <div
              {...props}
              style={{
                width: '5px',
                borderRadius: 'inherit',
                backgroundColor: 'rgba(195, 190, 220, 0.4)',
                left: '1px'
              }}
            />
          )}
        >
          <div className={style.air__sidebar__inner}>
            <a href="#" className={`fe fe-x-circle ${style.air__sidebar__close}`} onClick={() => changeSetting('isSidebarOpen', !state.isSidebarOpen)} />
            <h5>
              <strong> {intl.get('TEMA_AYARLARI')}</strong>
            </h5>
            <div className="air__utils__line" style={{ marginTop: 25, marginBottom: 30 }} />
            <div>
              <p className="text-muted mb-5">
                Tema Ayarları mevcut temanız üzerinde kişiselleştirmeler yapmanıza olanak sağlar. Oldukça esnek bir kullanıma sahiptir.
              </p>
              <div className={style.air__sidebar__type}>
                <div className={style.air__sidebar__type__title}>
                  <span>{intl.get('MENU_ARAYUZU')}</span>
                </div>
                <div className={style.air__sidebar__type__items}>
                  <Radio.Group onChange={(e) => changeSetting('menuLayoutType', e.target.value)} defaultValue={state.menuLayoutType}>
                    <div className="row">
                      <div className="col-6">
                        <div className="mb-2">
                          <Radio value="left">{intl.get('SOL_MENU')}</Radio>
                        </div>
                        <div className="mb-2">
                          <Radio value="top-dark">{intl.get('UST_KOYU')}</Radio>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mb-2">
                          <Radio value="top">{intl.get('UST_MENU')}</Radio>
                        </div>
                        <div className="mb-2">
                          <Radio value="nomenu">{intl.get('MENU_OLMASIN')}</Radio>
                        </div>
                      </div>
                    </div>
                  </Radio.Group>
                </div>
              </div>
              <div className={style.air__sidebar__type}>
                <div className={style.air__sidebar__type__title}>
                  <span>{intl.get('SOL_MENU_TIPI')}</span>
                </div>
                <div className={style.air__sidebar__type__items}>
                  <Radio.Group onChange={(e) => changeSetting('menuType', e.target.value)} defaultValue={state.menuType}>
                    <div className="row">
                      <div className="col-6">
                        <div className="mb-2">
                          <Radio value="default" disabled={state.menuLayoutType !== 'left'}>
                            {intl.get('VARSAYILAN')}
                          </Radio>
                        </div>
                        <div className="mb-2">
                          <Radio value="flyout" disabled={state.menuLayoutType !== 'left'}>
                            {intl.get('FLYOUT')}
                          </Radio>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mb-2">
                          <Radio value="compact" disabled={state.menuLayoutType !== 'left'}>
                            {intl.get('KOMPAKT')}
                          </Radio>
                        </div>
                      </div>
                    </div>
                  </Radio.Group>
                </div>
              </div>
              <div className={`${style.air__sidebar__type} mb-4`}>
                <div className={style.air__sidebar__type__title}>
                  <span> {intl.get('GECIS_ANIMASYON')}</span>
                </div>
                <div className={style.air__sidebar__type__items}>
                  <Select defaultValue={state.routerAnimation} style={{ width: '100%' }} onChange={(value) => changeSetting('routerAnimation', value)}>
                    <Select.Option value="none">Yok</Select.Option>
                    <Select.Option value="slide-fadein-up">Yukarı Kaydır</Select.Option>
                    <Select.Option value="slide-fadein-right">Sağa Kaydır</Select.Option>
                    <Select.Option value="fadein">Fade In</Select.Option>
                    <Select.Option value="zoom-fadein">Yakınlaş</Select.Option>
                  </Select>
                </div>
              </div>
              <div className={`${style.air__sidebar__type} mb-4`}>
                <div className={style.air__sidebar__type__title}>
                  <span>{intl.get('DIL_SECENEKLERI')}</span>
                </div>
                <div className={style.air__sidebar__type__items}>
                  <Select defaultValue={state.locale} style={{ width: '100%' }} onChange={(value) => changeSetting('locale', value)}>
                    <Select.Option value="tr-TR">Türkçe (tr-TR)</Select.Option>
                    <Select.Option value="en-US">English (en-US)</Select.Option>
                  </Select>
                </div>
              </div>
              <div className={style.air__sidebar__item}>
                <div className={style.air__sidebar__label}>Toggle Sol Menü</div>
                <div className={style.air__sidebar__container}>
                  <Switch
                    checked={state.isMenuCollapsed}
                    disabled={state.menuLayoutType !== 'left'}
                    onChange={(value) => {
                      changeSetting('isMenuCollapsed', value);
                    }}
                  />
                </div>
              </div>
              <div className={style.air__sidebar__item}>
                <div className={style.air__sidebar__label}>Hareketli Sol Menü</div>
                <div className={style.air__sidebar__container}>
                  <Switch
                    checked={state.isMenuUnfixed}
                    disabled={state.menuLayoutType !== 'left'}
                    onChange={(value) => {
                      changeSetting('isMenuUnfixed', value);
                    }}
                  />
                </div>
              </div>
              <div className={style.air__sidebar__item}>
                <div className={style.air__sidebar__label}>Sabit Üst Menü</div>
                <div className={style.air__sidebar__container}>
                  <Switch
                    checked={state.isTopbarFixed}
                    onChange={(value) => {
                      changeSetting('isTopbarFixed', value);
                    }}
                  />
                </div>
              </div>
              <div className={style.air__sidebar__item}>
                <div className={style.air__sidebar__label}>Koyu Altbilgi</div>
                <div className={style.air__sidebar__container}>
                  <Switch
                    checked={state.isFooterDark}
                    onChange={(value) => {
                      changeSetting('isFooterDark', value);
                    }}
                  />
                </div>
              </div>
              <div className={style.air__sidebar__item}>
                <div className={style.air__sidebar__label}>Menü Rengi</div>
                <div className={style.air__sidebar__container}>
                  <ColorPicker setting="menuColor" value={state.menuColor} colors={['white', 'gray', 'blue', 'dark']} />
                </div>
              </div>
              <div className={style.air__sidebar__item}>
                <div className={style.air__sidebar__label}>Açılır Menü</div>
                <div className={style.air__sidebar__container}>
                  <ColorPicker setting="flyoutMenuColor" value={state.flyoutMenuColor} colors={['white', 'gray', 'blue', 'dark']} />
                </div>
              </div>
              <div className={style.air__sidebar__item}>
                <div className={style.air__sidebar__label}>Oturum Rengi</div>
                <div className={style.air__sidebar__container}>
                  <ColorPicker setting="systemLayoutColor" value={state.systemLayoutColor} colors={['white', 'gray', 'blue', 'dark', 'image']} />
                </div>
              </div>
              <div className={style.air__sidebar__item}>
                <div className={style.air__sidebar__label}>İçeriği Sığdır(max-width)</div>
                <div className={style.air__sidebar__container}>
                  <Switch
                    checked={state.isContentNoMaxWidth}
                    onChange={(value) => {
                      changeSetting('isContentNoMaxWidth', value);
                    }}
                  />
                </div>
              </div>
              <div className={style.air__sidebar__item}>
                <div className={style.air__sidebar__label}>Uygulama Max Genişlik</div>
                <div className={style.air__sidebar__container}>
                  <Switch
                    checked={state.isAppMaxWidth}
                    onChange={(value) => {
                      changeSetting('isAppMaxWidth', value);
                    }}
                  />
                </div>
              </div>
              <div className={style.air__sidebar__item}>
                <div className={style.air__sidebar__label}>Gri Arkaplan</div>
                <div className={style.air__sidebar__container}>
                  <Switch
                    checked={state.isGrayBackground}
                    onChange={(value) => {
                      changeSetting('isGrayBackground', value);
                    }}
                  />
                </div>
              </div>
              <div className={style.air__sidebar__item}>
                <div className={style.air__sidebar__label}>Gri Üst Bar</div>
                <div className={style.air__sidebar__container}>
                  <Switch
                    checked={state.isGrayTopbar}
                    onChange={(value) => {
                      changeSetting('isGrayTopbar', value);
                    }}
                  />
                </div>
              </div>
              <div className={style.air__sidebar__item}>
                <div className={style.air__sidebar__label}>Kart Kenarlıkları</div>
                <div className={style.air__sidebar__container}>
                  <Switch
                    checked={state.isSquaredBorders}
                    onChange={(value) => {
                      changeSetting('isSquaredBorders', value);
                    }}
                  />
                </div>
              </div>
              <div className={style.air__sidebar__item}>
                <div className={style.air__sidebar__label}>Kart Gölgelendirmeleri</div>
                <div className={style.air__sidebar__container}>
                  <Switch
                    checked={state.isCardShadow}
                    onChange={(value) => {
                      changeSetting('isCardShadow', value);
                    }}
                  />
                </div>
              </div>
              <div className={style.air__sidebar__item}>
                <div className={style.air__sidebar__label}>Kenarlıksız Kart</div>
                <div className={style.air__sidebar__container}>
                  <Switch
                    checked={state.isBorderless}
                    onChange={(value) => {
                      changeSetting('isBorderless', value);
                    }}
                  />
                </div>
              </div>
              <div className={style.air__sidebar__item}>
                <div className={style.air__sidebar__label}>Menü gölgesi</div>
                <div className={style.air__sidebar__container}>
                  <Switch
                    checked={state.isMenuShadow}
                    onChange={(value) => {
                      changeSetting('isMenuShadow', value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div> */}

      <Tooltip title={state.theme === 'default' ? 'Koyu Tema Aç' : 'Koyu Tema Kapat'} placement="left">
        <a
          role="button"
          onFocus={(e) => {
            e.preventDefault();
          }}
          onKeyPress={changeTheme}
          onClick={changeTheme}
          style={{ bottom: 'calc(50% + 120px)' }}
          className={style.air__sidebar__toggleButton}
        >
          {state.theme === 'default' && <i className="fe fe-moon" />}
          {state.theme !== 'default' && <i className="fe fe-sun" />}
        </a>
      </Tooltip>
      {/* <Tooltip title="Birincil Rengi Ayarla" placement="left">
        <a
          style={{ bottom: 'calc(50% + 60px)' }}
          className={`${style.air__sidebar__toggleButton} ${style.color} ${state.primaryColor === state.defaultColor ? style.reset : ''}`}
        >
          <button
            type="button"
            tabIndex={0}
            onFocus={(e) => {
              e.preventDefault();
            }}
            onKeyPress={() => changeSetting('primaryColor', state.defaultColor)}
            onClick={() => changeSetting('primaryColor', state.defaultColor)}
          >
            <i className="fe fe-x-circle" />
          </button>
          <input type="color" id="colorPicker" onChange={(e) => changeSetting('primaryColor', e.target.value)} value={state.primaryColor} />
          <i className="fe fe-package" />
        </a>
      </Tooltip> */}
      <Tooltip title="Mükellef Sorgula" placement="left">
        <a
          role="button"
          onFocus={(e) => {
            e.preventDefault();
          }}
          onKeyPress={() => changeSetting('userSearchVisible', !state.userSearchVisible)}
          onClick={() => changeSetting('userSearchVisible', !state.userSearchVisible)}
          style={{ bottom: 'calc(50% + 60px)' }}
          className={style.air__sidebar__toggleButton}
        >
          <FontAwesomeIcon icon={faSearch} />
        </a>
      </Tooltip>
      <Tooltip title="Zarf Durum Sorgula" placement="left">
        <a
          role="button"
          onFocus={(e) => {
            e.preventDefault();
          }}
          onKeyPress={() => changeSetting('envelopeSearchVisible', !state.envelopeSearchVisible)}
          onClick={() => changeSetting('envelopeSearchVisible', !state.envelopeSearchVisible)}
          style={{ bottom: 'calc(50%)' }}
          className={style.air__sidebar__toggleButton}
        >
          <FontAwesomeIcon icon={faEnvelopeOpen} />
        </a>
      </Tooltip>
    </div>
  );
};

export default Sidebar;
