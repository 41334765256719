/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLArray, XMLElement } from '@/xmldom-decorators';
import { AllowanceChargeType, AmountType, IDType, ItemType, TextType } from '.';
import { NS_CAC, NS_CBC } from '../constants';
import InvoiceLineType from './InvoiceLineType';

/*
  19.04.2022 : Partially completed
*/
export class GoodsItemType {
  @XMLElement({ types: [{ name: 'ID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  id?: IDType;

  @XMLArray({ nested: false, itemTypes: [{ name: 'Description', namespaceUri: NS_CBC, itemType: () => TextType }] })
  description?: TextType[];

  // TODO : HazardousRiskIndicatorType
  // TODO : DeclaredCustomsValueAmountType
  // TODO : DeclaredForCarriageValueAmountType
  // TODO : DeclaredStatisticsValueAmountType
  // TODO : FreeOnBoardValueAmountType
  // TODO : InsuranceValueAmountType
  // TODO : ValueAmountType

  @XMLElement({ types: [{ name: 'ValueAmount', namespaceUri: NS_CBC, itemType: () => AmountType }] })
  valueAmount?: AmountType;

  // TODO : GrossWeightMeasureType
  // TODO : NetWeightMeasureType
  // TODO : ChargeableWeightMeasureType
  // TODO : GrossVolumeMeasureType
  // TODO : NetVolumeMeasureType
  // TODO : QuantityType
  @XMLElement({ types: [{ name: 'RequiredCustomsID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  requiredCustomsID?: IDType;
  // TODO : CustomsStatusCodeType
  // TODO : CustomsTariffQuantityType
  // TODO : CustomsImportClassifiedIndicatorType
  // TODO : ChargeableQuantityType
  // TODO : ReturnableQuantityType
  // TODO : TraceIDType

  @XMLElement({ types: [{ name: 'Item', namespaceUri: NS_CAC, itemType: () => ItemType }] })
  item?: ItemType;

  @XMLArray({ nested: false, itemTypes: [{ name: 'FreightAllowanceCharge', namespaceUri: NS_CAC, itemType: () => AllowanceChargeType }] })
  freightAllowanceCharge?: AllowanceChargeType[] = [];

  @XMLArray({ nested: false, itemTypes: [{ name: 'InvoiceLine', namespaceUri: NS_CAC, itemType: () => InvoiceLineType }] })
  invoiceLine?: InvoiceLineType[] = [];

  // TODO : Temperature
  // TODO : MeasurementDimension
}

export default GoodsItemType;
