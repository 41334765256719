import { AliasInfo } from '@/apis/edespatch/interfaces';
import { UserInfo } from '@/apis/einvoice/interfaces';
import { ErrorWrapper, RootSpinner } from '@/components';
import { SearchInAddon } from '@/components/AddonButtons';
import { Close, Preview } from '@/components/Buttons';
import { PageConst } from '@/constants/page';
import { REGISTER_NUMBER } from '@/constants/regex';
import { ApiGenericResponse } from '@/interfaces';
import { AnyType } from '@/type';
import { WarningOutlined } from '@ant-design/icons';
import { faPencilAlt, faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Col, Modal, Row, Table, Tabs } from 'antd';
import axios from 'axios';
import { Formik, FormikProps } from 'formik';
import { Form, FormItem, Input, Radio } from 'formik-antd';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import intl from 'react-intl-universal';
import * as Yup from 'yup';
const { Column } = Table;
import { EINVOICE_API_ENDPOINT, EDESPATCH_API_ENDPOINT } from 'config';

const EINVOCIE_PATH = EINVOICE_API_ENDPOINT.replace(/\/+$/, '');
const EDESPATCH_PATH = EDESPATCH_API_ENDPOINT.replace(/\/+$/, '');

interface ApiDocumentTypeResponse {
  Einvoice: ApiGenericResponse<Array<UserInfo>>;
  EDespatch: ApiGenericResponse<Array<UserInfo>>;
}
export interface UpsetUserSearchFormikProps {
  inputValue: string;
  aliasType: string;
}
const expandedRowRender = (data: Array<AliasInfo>) => {
  return (
    <Table
      scroll={{ y: 520 }}
      columns={[
        {
          title: 'Etiket',
          dataIndex: 'alias',
          key: 'alias',
          render: (value) => {
            return <b>{value}</b>;
          }
        },
        {
          title: 'Oluşturma Zamanı',
          dataIndex: 'creationTime',
          width: 140,
          key: 'creationTime',
          render: (value) => {
            return moment(value).format('DD.MM.YYYY HH:mm');
          }
        }
      ]}
      loading={false}
      rowSelection={undefined}
      dataSource={data}
      pagination={false}
      bordered
      rowKey={(value) => value.creationTime}
    />
  );
};
const UserSearchScheme = Yup.object().shape<UpsetUserSearchFormikProps>({
  inputValue: Yup.string()
    .nullable()
    .required()
    .test('input-test', 'Lütfen Geçerli VKN/TCKN veya Firma ünvanı giriniz.', (value) => {
      if (value)
        if (value.match('^\\d+$')) {
          return value.match(REGISTER_NUMBER) ? true : false;
        } else {
          return value.length >= 3 ? true : false;
        }
      else return false;
    }),

  aliasType: Yup.string().nullable().required()
});
const UserSearchModal = ({ closeModal, visible }: { visible: boolean; closeModal: () => void }) => {
  let form = (useRef(null) as unknown) as FormikProps<UpsetUserSearchFormikProps>;
  const [search, setSearch] = useState<ApiDocumentTypeResponse>({
    EDespatch: {
      loading: false,
      data: undefined,
      error: undefined
    },
    Einvoice: {
      loading: false,
      data: undefined,
      error: undefined
    }
  });

  const getUserSearch = ({ inputValue, aliasType }: UpsetUserSearchFormikProps) => {
    setSearch({ Einvoice: { ...search, loading: true, error: undefined }, EDespatch: { ...search, loading: true, error: undefined } });
    axios
      .all(
        inputValue.match('^\\d+$') //Tamamı decimalsa VKN/TCKN üzerinden arama yapılıypr
          ? [axios.get(`${EINVOCIE_PATH}/v1/users/${inputValue}/${aliasType}`), axios.get(`${EDESPATCH_PATH}/v1/users/${inputValue}/${aliasType}`)]
          : [
              axios.get(`${EINVOCIE_PATH}/v1/users/search/${inputValue}/${aliasType}`),
              axios.get(`${EDESPATCH_PATH}/v1/users/search/${inputValue}/${aliasType}`)
            ]
      )
      .then((responses: AnyType) => {
        const res: Array<ApiGenericResponse<Array<UserInfo>>> = [];
        responses.forEach((response) => {
          if (response.isAxiosError) {
            const httpStatusCode = response.response.status;
            if (httpStatusCode == 404) {
              res.push({ loading: false, data: [], error: undefined });
            } else {
              res.push({ loading: false, data: undefined, error: response });
            }
          } else {
            if (inputValue.match('^\\d+$')) res.push({ loading: false, data: [response.data], error: undefined });
            else res.push({ loading: false, data: response.data, error: undefined });
          }
        });

        return setSearch({ Einvoice: res[0], EDespatch: res[1] });
      })
      .catch((errors) => {
        setSearch({ Einvoice: { ...search, loading: true, error: undefined }, EDespatch: { ...search, loading: true, error: undefined } });
      });
  };

  const { Einvoice, EDespatch } = search;

  return (
    <Modal
      centered
      title={
        <span>
          <FontAwesomeIcon icon={faSearch} /> {'Mükellef Sorgulama'}
        </span>
      }
      onCancel={closeModal}
      width={PageConst.LargeModalWidth}
      visible={visible}
      footer={<Close onClick={closeModal} />}
    >
      <RootSpinner loading={Einvoice.loading || EDespatch.loading}>
        <Formik<UpsetUserSearchFormikProps>
          validateOnBlur={false}
          innerRef={(instance) => (form = instance)}
          onSubmit={(values) => {
            if (form.isValid) getUserSearch(values);
          }}
          validationSchema={UserSearchScheme}
          initialValues={{ aliasType: 'Pk', inputValue: '' }}
        >
          {({ handleSubmit, values }) => {
            return (
              <Form layout="vertical">
                <Row>
                  <Col>
                    <FormItem name="aliasType" label={intl.get('ETIKET')} required>
                      <Radio.Group name="aliasType">
                        <Radio name="aliasType" value={'Pk'}>
                          Posta Kutusu
                        </Radio>
                        <Radio name="aliasType" value={'Gb'}>
                          Gönderici Birim
                        </Radio>
                      </Radio.Group>
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={PageConst.RowGutter}>
                  <Col sm={24}>
                    <FormItem name="inputValue" label={intl.get('FIRMA_UNVANI') + '/' + intl.get('VKN_TCKN')} required>
                      <Input
                        prefix={<FontAwesomeIcon icon={faPencilAlt} />}
                        name="inputValue"
                        placeholder={'  VKN/TCKN veya Firma ünvanı giriniz.'}
                        tabIndex={0}
                        onKeyDown={(event) => {
                          if (event.keyCode === 13) {
                            handleSubmit();
                          }
                        }}
                        addonAfter={<SearchInAddon onClick={() => handleSubmit()} />}
                      />
                    </FormItem>
                  </Col>
                </Row>

                {(Einvoice.data || EDespatch.data || Einvoice.error || EDespatch.error) && (
                  <Tabs defaultActiveKey="1" animated={false} type="card">
                    <Tabs.TabPane key="1" tab="e-Fatura Mükellef Sonucu">
                      {((Einvoice.data && Einvoice.data.length > 0) || Einvoice.error) && (
                        <Table<UserInfo>
                          scroll={{ y: 520 }}
                          showHeader={Einvoice.data && Einvoice.data !== []}
                          components={ErrorWrapper({ error: Einvoice.error })}
                          style={{ marginTop: '10px' }}
                          bordered
                          pagination={false}
                          rowKey={(row) => row.identifier}
                          dataSource={Einvoice.data && Einvoice.data.filter((x) => x.aliases.length > 0)}
                        >
                          <Column
                            title={''}
                            dataIndex="preview"
                            key="preview"
                            align="center"
                            width={40}
                            render={(_, record: UserInfo) => (
                              <Preview
                                onClick={() =>
                                  Modal.info({
                                    className: 'nes-dynamic-modal',
                                    centered: true,
                                    width: PageConst.MediumModalWidth,
                                    title: `${record.identifier} - ${record.title.substring(0, 20)}... - Etiket Listesi`,
                                    content: expandedRowRender(record.aliases)
                                  })
                                }
                              />
                            )}
                          />
                          <Column title={'VKN / TCKN'} width={100} dataIndex="identifier" key="identifier" render={(value) => <b>{value}</b>} />
                          <Column title={intl.get('FIRMA_UNVANI')} dataIndex="title" key="title" render={(value) => <b>{value}</b>} />
                          <Column
                            title={intl.get('ILK_KAYIT_TARIHI')}
                            width={130}
                            dataIndex="firstCreationTime"
                            key="firstCreationTime"
                            render={(value) => <b>{moment(value).format('DD.MM.YYYY HH:mm')}</b>}
                          />
                        </Table>
                      )}

                      {Einvoice.data && Einvoice.data.length == 0 && (
                        <Alert
                          showIcon
                          icon={<WarningOutlined />}
                          message={`Sorgulanan firmaya ait "e-Fatura" / ${JSON.stringify(values.aliasType)} etiketi bulunamadı!`}
                          type="error"
                        />
                      )}
                    </Tabs.TabPane>

                    <Tabs.TabPane key="2" tab="e-İrsaliye Mükellef Sonucu">
                      {EDespatch.data && EDespatch.data.length > 0 && (
                        <Table
                          scroll={{ y: 520 }}
                          showHeader={EDespatch.data && EDespatch.data !== []}
                          components={ErrorWrapper({ error: EDespatch.error })}
                          style={{ marginTop: '10px' }}
                          rowKey={(row) => row.identifier}
                          bordered
                          pagination={false}
                          dataSource={EDespatch.data && EDespatch.data.filter((x) => x.aliases.length > 0)}
                        >
                          <Column
                            title={''}
                            dataIndex="preview"
                            key="preview"
                            align="center"
                            width={40}
                            render={(_, record: UserInfo) => (
                              <Preview
                                onClick={() =>
                                  Modal.info({
                                    className: 'nes-dynamic-modal',
                                    centered: true,
                                    width: PageConst.MediumModalWidth,
                                    title: `${record.identifier} - ${record.title.substring(0, 20)}... - Etiket Listesi`,
                                    content: expandedRowRender(record.aliases)
                                  })
                                }
                              />
                            )}
                          />
                          <Column title={'VKN / TCKN'} width={100} dataIndex="identifier" key="identifier" render={(value) => <b>{value}</b>} />
                          <Column title={intl.get('FIRMA_UNVANI')} dataIndex="title" key="title" render={(value) => <b>{value}</b>} />
                          <Column
                            title={intl.get('ILK_KAYIT_TARIHI')}
                            dataIndex="firstCreationTime"
                            key="firstCreationTime"
                            width={130}
                            render={(value) => <b>{moment(value).format('DD.MM.YYYY HH:mm')}</b>}
                          />
                        </Table>
                      )}

                      {EDespatch.data && EDespatch.data.length == 0 && (
                        <Alert
                          showIcon
                          icon={<WarningOutlined />}
                          message={`Sorgulanan firmaya ait "e-İrsaliye" / ${JSON.stringify(values.aliasType)} etiketi bulunamadı!`}
                          type="error"
                        />
                      )}
                    </Tabs.TabPane>
                  </Tabs>
                )}
              </Form>
            );
          }}
        </Formik>
      </RootSpinner>
    </Modal>
  );
};

export default UserSearchModal;
