import { V1DefinitionsDocumenttemplatesIdPutRequest, V1DefinitionsDocumenttemplatesPostRequest } from '@/apis/einvoice';
import { DocumentTemplate } from '@/apis/einvoice/interfaces';
import { Close, Save } from '@/components/Buttons';
import { useApi } from '@/hooks';
import { faPencilAlt, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'antd';
import { AxiosPromise } from 'axios';
import { Formik, FormikProps } from 'formik';
import { Checkbox, Form, FormItem, Input } from 'formik-antd';
import React, { useRef } from 'react';
import intl from 'react-intl-universal';
import * as Yup from 'yup';
import { InfoToastify, RootSpinner } from '..';
import SingleUploader from '../SingleUploader';
import { ErrorViewer } from '../TableBody';

export interface UpsertModalProps {
  visible: boolean;
  documentTemplate?: DocumentTemplate;
}

const UpsertScheme = Yup.object().shape({
  title: Yup.string().required(),
  file: Yup.string().required().nullable().required()
});

const UpsertModal = ({
  modalState: { visible, documentTemplate },
  closeModal,
  post,
  put,
  refresh
}: {
  modalState: UpsertModalProps;
  closeModal: () => void;
  post: (requestParameters: V1DefinitionsDocumenttemplatesPostRequest) => AxiosPromise<DocumentTemplate>;
  put: (requestParameters: V1DefinitionsDocumenttemplatesIdPutRequest) => AxiosPromise<DocumentTemplate>;
  refresh: () => Promise<void>;
}) => {
  let form = (useRef(null) as unknown) as FormikProps<V1DefinitionsDocumenttemplatesPostRequest>;

  const callBack = (title: string, isEdit: boolean) => {
    closeModal();
    refresh();
    InfoToastify(intl.getHTML(isEdit ? 'SABLON_GUNCELLENDI' : 'SABLON_EKLENDI', { title }));
  };

  /**
   * Insert işlemi için kullanılan fonksiypn
   */
  const postRequest = useApi<void, V1DefinitionsDocumenttemplatesPostRequest>({
    asyncFunction: post,
    successCallback: (_, request) => request && callBack(request.title, false)
  });

  /**
   * Update işlemi için kullanılan fonksiypn
   */
  const putRequest = useApi<void, V1DefinitionsDocumenttemplatesIdPutRequest>({
    asyncFunction: put,
    successCallback: (_, request) => request && callBack(request.title, true)
  });

  const submitForm = () => {
    if (form && form.isValid) {
      form.handleSubmit();
    }
  };

  const pageLoading = postRequest.loading || putRequest.loading;

  return (
    <Modal
      title={
        <span>
          {!documentTemplate && (
            <>
              <FontAwesomeIcon icon={faPlus} className="mr-2" /> {intl.get('YENI_X_EKLE', { x: intl.get('şablon') })}
            </>
          )}
          {documentTemplate && (
            <>
              <FontAwesomeIcon icon={faPencilAlt} className="mr-2" /> {intl.get('X_DUZENLE', { x: intl.get('SABLON') })}
            </>
          )}
        </span>
      }
      onCancel={closeModal}
      centered
      transitionName="fade"
      maskClosable={false}
      visible={visible}
      afterClose={() => {
        putRequest.clear();
        postRequest.clear();
      }}
      footer={[
        <React.Fragment key="xslt-upsert-modal-footer">
          <Close disabled={pageLoading} onClick={closeModal} />
          <Save disabled={pageLoading} onClick={submitForm} />
        </React.Fragment>
      ]}
      destroyOnClose
    >
      <RootSpinner loading={pageLoading}>
        <Formik<V1DefinitionsDocumenttemplatesPostRequest>
          innerRef={(instance) => (form = instance)}
          onSubmit={(values) => (documentTemplate ? putRequest.call({ ...values, id: documentTemplate.id }) : postRequest.call(values))}
          validationSchema={UpsertScheme}
          initialValues={{ title: documentTemplate?.title || '', isDefault: documentTemplate?.isDefault || false, file: undefined }}
        >
          <Form layout="vertical">
            <FormItem name="title" label={intl.get('SABLON')} hasFeedback required>
              <Input name="title" placeholder={intl.get('SABLON')} tabIndex={0} />
            </FormItem>

            <FormItem name="file" required>
              <SingleUploader readType={'asBinaryString'} onLoad={(values) => form.setFieldValue('file', values)} extensions={'.xslt'} />
            </FormItem>

            <FormItem name="isDefault">
              <Checkbox name="isDefault">{intl.get('VARSAYILAN_OLARAK_AYARLA')}</Checkbox>
            </FormItem>
          </Form>
        </Formik>

        {(postRequest.error || putRequest.error) && <ErrorViewer error={postRequest.error || putRequest.error} />}
      </RootSpinner>
    </Modal>
  );
};

export default UpsertModal;
