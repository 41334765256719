import { OnClickProps } from '@/interfaces';
import { faInbox } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuItem from 'antd/lib/menu/MenuItem';
import React from 'react';
import intl from 'react-intl-universal';
export const MailDetails = (props: OnClickProps) => {
  return (
    <MenuItem {...props}>
      <FontAwesomeIcon className={'mr-2'} icon={faInbox} />
      {intl.get('MAIL_DETAYLARI')}
    </MenuItem>
  );
};

export default MailDetails;
