import { HasRoleAndNotifiy } from '@/components/Auth';
import ThemeContext from '@/contexts/ThemeContext';
import { Menu } from '@/interfaces';
import { AnyType } from '@/type';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Layout } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import intl from 'react-intl-universal';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import style from './style.module.scss';

const { Sider } = Layout;

type State = {
  activeSubmenu: string;
  activeItem: string;
  renderedFlyoutItems: AnyType;
};
type Props = RouteComponentProps;

const MenuLeft = (props: Props) => {
  const { oidcUser } = useReactOidc();

  const [state, setState] = useState<State>({ activeItem: '', activeSubmenu: '', renderedFlyoutItems: {} });
  const [settings, dispatch] = useContext(ThemeContext);

  const flyoutTimers = {};

  const flyoutActive = (settings.menuType === 'flyout' || settings.menuType === 'compact' || settings.isMenuCollapsed) && !settings.isMobileView;

  const activeItemKeys = (activeMenu) => {
    const list: AnyType = [];
    if (activeMenu) {
      const split = activeMenu.split('-');
      for (let i = 0; i < split.length; i++) {
        list.push(_.join(_.concat(_.slice(split, 0, split.length - i)), '-'));
      }
      return list.reverse();
    }

    return list;
  };

  const setActiveItems = () => {
    if (!settings.menuData.length) {
      return;
    }
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item);
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key));
        }
        return flattenedItems;
      }, []);

    const activeItem = _.find(flattenItems(settings.menuData, 'children'), ['url', props.location.pathname]) || {};

    const keys = activeItemKeys(activeItem.key);

    setState({
      ...state,
      activeItem: activeItem.key,
      activeSubmenu: keys[keys.length - 1]
    });
  };

  useEffect(() => {
    setActiveItems();
  }, [props.location.pathname, settings.menuData]);

  const handleSubmenuClick = (e, key) => {
    e.preventDefault();
    const { activeSubmenu } = state;

    if (flyoutActive) {
      return;
    }
    setState({ ...state, activeSubmenu: activeSubmenu === key ? '' : key });
  };

  const handleFlyoutContainerOver = (key) => {
    clearInterval(flyoutTimers[key]);
  };

  const handleFlyoutOut = (key) => {
    if (flyoutActive) {
      flyoutTimers[key] = setTimeout(() => {
        setState((state) => {
          delete state.renderedFlyoutItems[key];
          return {
            ...state,
            renderedFlyoutItems: {
              ...state.renderedFlyoutItems
            }
          };
        });
      }, 100);
    }
  };

  const renderFlyoutMenu = (items, key, itemDimensions) => {
    const { activeItem } = state;
    const left = `${itemDimensions.left + itemDimensions.width - 10}px`;
    const top = `${itemDimensions.top}px`;

    return (
      <div
        style={{ left, top }}
        className={classNames(style.air__menuFlyout, {
          [style.air__menuFlyoutLeft]: settings.menuLayoutType === 'left',
          [style.air__menuFlyout__black]: settings.flyoutMenuColor === 'dark',
          [style.air__menuFlyout__white]: settings.flyoutMenuColor === 'white',
          [style.air__menuFlyout__gray]: settings.flyoutMenuColor === 'gray'
        })}
        key={key}
      >
        <ul className={style.air__menuLeft__list} onMouseEnter={() => handleFlyoutContainerOver(key)} onMouseLeave={() => handleFlyoutOut(key)}>
          {items.map((item) => {
            return (
              <li
                className={classNames(style.air__menuLeft__item, {
                  [style.air__menuLeft__item__active]: activeItem === item.key
                })}
                key={item.key}
              >
                <Link to={item.url} className={style.air__menuLeft__link}>
                  {item.icon && <FontAwesomeIcon className={style.air__menuLeft__icon} icon={item.icon} />}
                  <span>{intl.get(item.key)}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const handleFlyoutOver = (event, key, items) => {
    if (flyoutActive) {
      clearInterval(flyoutTimers[key]);
      const item = event.currentTarget;
      const itemDimensions = item.getBoundingClientRect();
      const element = renderFlyoutMenu(items, key, itemDimensions);
      setState({
        ...state,
        renderedFlyoutItems: {
          ...state.renderedFlyoutItems,
          [key]: element
        }
      });
    }
  };

  const generateMenuItems = () => {
    const { activeSubmenu, activeItem } = state;

    const active = activeItemKeys(activeSubmenu);

    const menuItem = (item: Menu) => {
      const { key, title, icon, url, role } = item;
      if (item.category) {
        return (
          <li className={style.air__menuLeft__category} key={Math.random()}>
            <span>{intl.get(key)}</span>
          </li>
        );
      }

      return (
        <li
          className={classNames(style.air__menuLeft__item, {
            [style.air__menuLeft__item__active]: activeItem === key || active.some((x) => x === key)
          })}
          key={key}
        >
          {url && (
            <Link
              to={url}
              className={style.air__menuLeft__link}
              onClick={(e) => {
                role ? (HasRoleAndNotifiy(role) ? true : e.preventDefault()) : true;
              }}
            >
              {icon && <FontAwesomeIcon className={style.air__menuLeft__icon} icon={icon} />}
              <span>{intl.get(key)}</span>
            </Link>
          )}
          {!url && (
            <a href="#" onClick={(e) => e.preventDefault()} className={style.air__menuLeft__link}>
              {icon && <FontAwesomeIcon className={style.air__menuLeft__icon} icon={icon} />}
              <span>{title}</span>
            </a>
          )}
        </li>
      );
    };

    const submenuItem = (item) => {
      return (
        <li
          className={classNames(style.air__menuLeft__item, style.air__menuLeft__submenu, {
            [style.air__menuLeft__submenu__active]: activeSubmenu === item.key || active.some((x) => x === item.key)
          })}
          key={item.key}
        >
          <a
            href="#"
            className={style.air__menuLeft__link}
            onClick={(e) => handleSubmenuClick(e, item.key)}
            onMouseEnter={(event) => handleFlyoutOver(event, item.key, item.children)}
            onFocus={(event) => handleFlyoutOver(event, item.key, item.children)}
            onMouseLeave={() => handleFlyoutOut(item.key)}
            onBlur={() => handleFlyoutOut(item.key)}
          >
            <FontAwesomeIcon className={style.air__menuLeft__icon} icon={item.icon} />
            <span>{intl.get(item.key)}</span>
            {item.count && <span className="badge text-white bg-blue-light float-right mt-1 px-2">{item.count}</span>}
          </a>
          <ul className={style.air__menuLeft__list}>
            {item.children.map((sub) => {
              if (sub.children) {
                return submenuItem(sub);
              }
              return menuItem(sub);
            })}
          </ul>
        </li>
      );
    };

    return settings.menuData.map((item) => {
      if (item.children) {
        return submenuItem(item);
      }
      return menuItem(item);
    });
  };

  const items = generateMenuItems();

  const changeSetting = (key, value) => {
    dispatch({ type: 'CHANGE', key, value });
  };

  return (
    <Sider width="auto">
      <TransitionGroup>
        {Object.keys(state.renderedFlyoutItems).map((item) => {
          return (
            <CSSTransition key={item} timeout={0} classNames="air__menuFlyout__animation">
              {state.renderedFlyoutItems[item]}
            </CSSTransition>
          );
        })}
      </TransitionGroup>
      <div
        id="menu-div"
        className={classNames(style.air__menuLeft, {
          [style.air__menuLeft__mobileToggled]: settings.isMobileMenuOpen,
          [style.air__menuLeft__toggled]: settings.isMenuCollapsed,
          [style.air__menuLeft__unfixed]: settings.isMenuUnfixed,
          [style.air__menuLeft__shadow]: settings.isMenuShadow,
          [style.air__menuLeft__flyout]: settings.menuType === 'flyout',
          [style.air__menuLeft__compact]: settings.menuType === 'compact',
          [style.air__menuLeft__blue]: settings.menuColor === 'blue',
          [style.air__menuLeft__white]: settings.menuColor === 'white',
          [style.air__menuLeft__gray]: settings.menuColor === 'gray',
          [style.air__menuFlyout__black]: settings.flyoutMenuColor === 'dark' && settings.menuType !== 'default',
          [style.air__menuFlyout__white]: settings.flyoutMenuColor === 'white' && settings.menuType !== 'default',
          [style.air__menuFlyout__gray]: settings.flyoutMenuColor === 'gray' && settings.menuType !== 'default'
        })}
      >
        <div className={style.air__menuLeft__outer}>
          <a href="#" className={style.air__menuLeft__mobileToggleButton} onClick={() => changeSetting('isMobileMenuOpen', !settings.isMobileMenuOpen)}>
            <span />
          </a>
          {/* <a href="#" className={style.air__menuLeft__toggleButton} onClick={() => changeSetting('isMenuCollapsed', !settings.isMenuCollapsed)}>
            <span />
            <span />
          </a> */}
          <a href="#" onClick={(e) => e.preventDefault()} className={style.air__menuLeft__logo}>
            {/* <img src="/img/1- Nes-Yazı.png" alt="Air UI" /> */}
            <div className={style.air__menuLeft__logo__name}>NES PORTAL</div>
            {/* <div className={style.air__menuLeft__logo__descr}>CLOUD SOFTWARE SOLUTİONS</div> */}
          </a>
          <a href="#" onClick={(e) => e.preventDefault()} className={style.air__menuLeft__user}>
            <div className={style.air__menuLeft__user__avatar}>
              <img src="/resources/images/avatars/avatar.png" alt={oidcUser && oidcUser.profile['FullName']} />
            </div>
            <div className={style.air__menuLeft__user__name}>{oidcUser && oidcUser.profile['FullName']}</div>
            <div className={style.air__menuLeft__user__role}>
              {oidcUser &&
              //Hiç rolü yoksa yada 1 tane varsa array olarak dmnmüyor bu da sistemi patlattığı için her durumda arraye çevrildi.
              (Array.isArray(oidcUser.profile['role'] || []) ? oidcUser.profile['role'] || [] : [oidcUser.profile['role']]).some((x) => x === 'ADMINISTRATOR')
                ? 'Yönetici'
                : 'Kullanıcı'}
            </div>
          </a>
          <Scrollbars
            autoHide
            renderThumbVertical={({ ...props }) => (
              <div
                {...props}
                style={{
                  width: '5px',
                  borderRadius: 'inherit',
                  backgroundColor: 'rgba(195, 190, 220, 0.4)',
                  left: '1px'
                }}
              />
            )}
          >
            <div id="menu-left-container" className={style.air__menuLeft__container}>
              <ul className={style.air__menuLeft__list}>
                {/* <li className={style.air__menuLeft__item}>
                  <a href="#" className={style.air__menuLeft__link} onClick={() => changeSetting('isSidebarOpen', !settings.isSidebarOpen)}>
                    <FontAwesomeIcon icon={faCogs} className={style.air__menuLeft__icon} />
                    <span>{intl.get('AYARLAR')}</span>
                  </a>
                </li> */}
                {items}
              </ul>
            </div>
          </Scrollbars>
        </div>
      </div>
      <a href="#" className={style.air__menuLeft__backdrop} onClick={() => changeSetting('isMobileMenuOpen', !settings.isMobileMenuOpen)} />
    </Sider>
  );
};

export default withRouter(MenuLeft);
