import AutoNumeric from 'autonumeric';

declare global {
  interface Number {
    round(precision: number): number;
  }
}

Number.prototype.round = function (precision: number) {
  return Number((AutoNumeric as any)._roundValue(this, (AutoNumeric as any).defaultSettings, precision));
};

export {};
