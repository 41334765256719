import { ActiveStatus as IActiveStatus } from '@/apis/einvoice/interfaces';
import { CheckCircleTwoTone, DeleteTwoTone, StopTwoTone } from '@ant-design/icons';
import React, { memo } from 'react';
import intl from 'react-intl-universal';

export const ActiveStatus = memo(({ activeStatus }: { activeStatus?: IActiveStatus }) => {
  return (
    <div>
      {activeStatus === IActiveStatus.Active && (
        <React.Fragment>
          <CheckCircleTwoTone twoToneColor="#52c41a" />
          <span> {intl.get('AKTIF')} </span>
        </React.Fragment>
      )}
      {activeStatus === IActiveStatus.Passive && (
        <React.Fragment>
          <StopTwoTone twoToneColor="#bfa722" />
          <span> {intl.get('PASIF')} </span>
        </React.Fragment>
      )}

      {activeStatus === IActiveStatus.Deleted && (
        <React.Fragment>
          <DeleteTwoTone twoToneColor="red" />
          <span> {intl.get('SILINDI')} </span>
        </React.Fragment>
      )}
    </div>
  );
});

export default ActiveStatus;
