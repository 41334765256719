/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/naming-convention */
import { DateFormat, IssueDateToday } from '@/constants/page';
import { XMLArray, XMLAttribute, XMLDecoratorDeserializer, XMLDecoratorSerializer, XMLElement, XMLRoot } from '@/xmldom-decorators';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import {
  AllowanceChargeType,
  BillingReferenceType,
  CustomerPartyType,
  DocumentReferenceType,
  ExchangeRateType,
  MonetaryTotalType,
  OrderReferenceType,
  PartyType,
  PaymentMeansType,
  PaymentTermsType,
  PeriodType,
  SupplierPartyType,
  TaxTotalType
} from '../cac';
import CodeType from '../cac/CodeType';
import { DeliveryType } from '../cac/Delivery';
import InvoiceLineType from '../cac/InvoiceLineType';
import { DefaultValue, NS_CAC, NS_CBC, NS_INV } from '../constants';

const serializer = new XMLDecoratorSerializer();
const deSerializer = new XMLDecoratorDeserializer();

// cac:Delivery
@XMLRoot({ name: 'Invoice', namespaceUri: NS_INV })
export class Invoice {
  @XMLAttribute({ type: String, name: 'xmlns', namespaceUri: '' })
  inv: string = NS_INV;

  @XMLAttribute({ type: String, name: 'xmlns:cbc', namespaceUri: '' })
  cbc: string = NS_CBC;
  @XMLAttribute({ type: String, name: 'xmlns:cac', namespaceUri: '' })
  cac: string = NS_CAC;

  @XMLElement({ types: [{ name: 'UBLVersionID', namespaceUri: NS_CBC, itemType: () => String }] })
  ublVersionId: string = '2.1';

  @XMLElement({ types: [{ name: 'CustomizationID', namespaceUri: NS_CBC, itemType: () => String }] })
  customizationID: string = 'TR1.2';

  @XMLElement({ types: [{ name: 'ProfileID', namespaceUri: NS_CBC, itemType: () => String }] })
  profileID: string = 'TEMELFATURA';

  @XMLElement({ types: [{ name: 'ID', namespaceUri: NS_CBC, itemType: () => String }] })
  id: string = '';

  @XMLElement({ types: [{ name: 'CopyIndicator', namespaceUri: NS_CBC, itemType: () => Boolean }] })
  copyIndicator: boolean = false;

  @XMLElement({ types: [{ name: 'UUID', namespaceUri: NS_CBC, itemType: () => String }] })
  uuid: string = uuidv4();

  @XMLElement({ types: [{ name: 'IssueDate', namespaceUri: NS_CBC, itemType: () => String }] })
  issueDate: string = IssueDateToday;

  @XMLElement({ types: [{ name: 'IssueTime', namespaceUri: NS_CBC, itemType: () => String }] })
  issueTime?: string = moment().format(DateFormat.HMS);

  @XMLElement({ types: [{ name: 'InvoiceTypeCode', namespaceUri: NS_CBC, itemType: () => String }] })
  invoiceTypeCode: string = 'SATIS';

  @XMLArray({ nested: false, itemTypes: [{ name: 'Note', namespaceUri: NS_CBC, itemType: () => String }] })
  note?: string[] = [];

  @XMLElement({ types: [{ name: 'DocumentCurrencyCode', namespaceUri: NS_CBC, itemType: () => CodeType }] })
  documentCurrencyCode: CodeType = { value: 'TRY' };

  @XMLElement({ types: [{ name: 'TaxCurrencyCode', namespaceUri: NS_CBC, itemType: () => CodeType }] })
  taxCurrencyCode?: CodeType;

  @XMLElement({ types: [{ name: 'PricingCurrencyCode', namespaceUri: NS_CBC, itemType: () => CodeType }] })
  pricingCurrencyCode?: CodeType;

  @XMLElement({ types: [{ name: 'PaymentCurrencyCode', namespaceUri: NS_CBC, itemType: () => CodeType }] })
  paymentCurrencyCode?: CodeType;

  @XMLElement({ types: [{ name: 'PaymentAlternativeCurrencyCode', namespaceUri: NS_CBC, itemType: () => CodeType }] })
  paymentAlternativeCurrencyCode?: CodeType;

  @XMLElement({ types: [{ name: 'AccountingCost', namespaceUri: NS_CBC, itemType: () => String }] })
  accountingCost?: string;

  @XMLElement({ types: [{ name: 'LineCountNumeric', namespaceUri: NS_CBC, itemType: () => Number }] })
  lineCountNumeric: Number = 0;

  @XMLElement({ types: [{ name: 'InvoicePeriod', namespaceUri: NS_CAC, itemType: () => PeriodType }] })
  invoicePeriod?: PeriodType;

  @XMLElement({ types: [{ name: 'OrderReference', namespaceUri: NS_CAC, itemType: () => OrderReferenceType }] })
  orderReference?: OrderReferenceType;

  @XMLArray({ nested: false, itemTypes: [{ name: 'BillingReference', namespaceUri: NS_CAC, itemType: () => BillingReferenceType }] })
  billingReference?: BillingReferenceType[];

  @XMLArray({ nested: false, itemTypes: [{ name: 'DespatchDocumentReference', namespaceUri: NS_CAC, itemType: () => DocumentReferenceType }] })
  despatchDocumentReference?: DocumentReferenceType[] = [];

  @XMLArray({ nested: false, itemTypes: [{ name: 'ReceiptDocumentReference', namespaceUri: NS_CAC, itemType: () => DocumentReferenceType }] })
  receiptDocumentReference?: DocumentReferenceType[];

  @XMLArray({ nested: false, itemTypes: [{ name: 'OriginatorDocumentReference', namespaceUri: NS_CAC, itemType: () => DocumentReferenceType }] })
  originatorDocumentReference?: DocumentReferenceType[];

  @XMLArray({ nested: false, itemTypes: [{ name: 'ContractDocumentReference', namespaceUri: NS_CAC, itemType: () => DocumentReferenceType }] })
  contractDocumentReference?: DocumentReferenceType[];

  @XMLArray({ nested: false, itemTypes: [{ name: 'AdditionalDocumentReference', namespaceUri: NS_CAC, itemType: () => DocumentReferenceType }] })
  additionalDocumentReference?: DocumentReferenceType[] = [];

  // cac:Signature

  @XMLElement({ types: [{ name: 'AccountingSupplierParty', namespaceUri: NS_CAC, itemType: () => SupplierPartyType }] })
  accountingSupplierParty?: SupplierPartyType = {
    party: {
      partyIdentification: []
    }
  };

  @XMLElement({ types: [{ name: 'AccountingCustomerParty', namespaceUri: NS_CAC, itemType: () => CustomerPartyType }] })
  accountingCustomerParty?: CustomerPartyType;

  @XMLElement({ types: [{ name: 'BuyerCustomerParty', namespaceUri: NS_CAC, itemType: () => CustomerPartyType }] })
  buyerCustomerParty?: CustomerPartyType;

  @XMLElement({ types: [{ name: 'SellerSupplierParty', namespaceUri: NS_CAC, itemType: () => SupplierPartyType }] })
  sellerSupplierParty?: SupplierPartyType;

  @XMLElement({ types: [{ name: 'TaxRepresentativeParty', namespaceUri: NS_CAC, itemType: () => PartyType }] })
  taxRepresentativeParty?: PartyType;

  @XMLArray({ nested: false, itemTypes: [{ name: 'Delivery', namespaceUri: NS_CAC, itemType: () => DeliveryType }] })
  delivery?: DeliveryType[];

  @XMLArray({ nested: false, itemTypes: [{ name: 'PaymentMeans', namespaceUri: NS_CAC, itemType: () => PaymentMeansType }] })
  paymentMeans?: PaymentMeansType[];

  @XMLElement({ types: [{ name: 'PaymentTerms', namespaceUri: NS_CAC, itemType: () => PaymentTermsType }] })
  paymentTerms?: PaymentTermsType;

  @XMLArray({ nested: false, itemTypes: [{ name: 'AllowanceCharge', namespaceUri: NS_CAC, itemType: () => AllowanceChargeType }] })
  allowanceCharge?: AllowanceChargeType[];

  @XMLElement({ types: [{ name: 'TaxExchangeRate', namespaceUri: NS_CAC, itemType: () => ExchangeRateType }] })
  taxExchangeRate?: ExchangeRateType;

  @XMLElement({ types: [{ name: 'PricingExchangeRate', namespaceUri: NS_CAC, itemType: () => ExchangeRateType }] })
  pricingExchangeRate?: ExchangeRateType;

  @XMLElement({ types: [{ name: 'PaymentExchangeRate', namespaceUri: NS_CAC, itemType: () => ExchangeRateType }] })
  paymentExchangeRate?: ExchangeRateType;

  @XMLElement({ types: [{ name: 'PaymentAlternativeExchangeRate', namespaceUri: NS_CAC, itemType: () => ExchangeRateType }] })
  paymentAlternativeExchangeRate?: ExchangeRateType;

  @XMLArray({ nested: false, itemTypes: [{ name: 'TaxTotal', namespaceUri: NS_CAC, itemType: () => TaxTotalType }] })
  taxTotal?: TaxTotalType[];

  @XMLArray({ nested: false, itemTypes: [{ name: 'WithholdingTaxTotal', namespaceUri: NS_CAC, itemType: () => TaxTotalType }] })
  withholdingTaxTotal?: TaxTotalType[];

  @XMLElement({ types: [{ name: 'LegalMonetaryTotal', namespaceUri: NS_CAC, itemType: () => MonetaryTotalType }] })
  legalMonetaryTotal: MonetaryTotalType = DefaultValue;

  @XMLArray({ nested: false, itemTypes: [{ name: 'InvoiceLine', namespaceUri: NS_CAC, itemType: () => InvoiceLineType }] })
  invoiceLine: InvoiceLineType[] = [];

  freeExportInvoice: boolean = false;

  createDummyInvoice = () => {
    const invoice = new Invoice();

    invoice.accountingSupplierParty = {
      party: {
        partyName: { name: 'NES BİLGİ VERİ TEKNOLOJİLERİ VE SAKLAMA HİZMETLERİ ANONİM ŞİRKETİ' },
        partyIdentification: [{ id: { schemeID: 'VKN', value: '1234567801' } }],
        postalAddress: {
          cityName: { value: 'İSTANBUL' },
          citySubdivisionName: { value: 'ÜMRANİYE' },
          country: { name: { value: 'TÜRKİYE' } },
          streetName: 'ATAKENT MAH. SEZEN SOK. NO: 1A'
        },
        partyTaxScheme: { taxScheme: { name: 'ÜMRANİYE' } },
        contact: { electronicMail: 'info@nesbilgi.com.tr', telephone: '0216 688 5100', telefax: '0216 688 5199' }
      }
    };

    invoice.accountingCustomerParty = {
      party: {
        partyName: { name: 'NES BİLGİ VERİ TEKNOLOJİLERİ VE SAKLAMA HİZMETLERİ ANONİM ŞİRKETİ' },
        partyIdentification: [{ id: { schemeID: 'VKN', value: '1234567801' } }],
        postalAddress: {
          cityName: { value: 'İSTANBUL' },
          citySubdivisionName: { value: 'ÜMRANİYE' },
          country: { name: { value: 'TÜRKİYE' } },
          streetName: 'ATAKENT MAH. SEZEN SOK. NO: 1A'
        },
        partyTaxScheme: { taxScheme: { name: 'ÜMRANİYE' } },
        contact: { electronicMail: 'info@nesbilgi.com.tr', telephone: '0216 688 5100', telefax: '0216 688 5199' }
      }
    };

    return invoice;
  };

  serialize = (inv: Invoice) => {
    return serializer
      .serialize(inv, Invoice, { [NS_CBC]: 'cbc', [NS_CAC]: 'cac' })
      .replace('xmlns:xmlns=""', '')
      .replace('xmlns:inv="urn:oasis:names:specification:ubl:schema:xsd:Invoice-2"', '')
      .replaceAll('inv:Invoice', 'Invoice');
  };
}
