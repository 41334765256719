import { PageStyle } from '@/constants/page';
import ThemeContext from '@/contexts/ThemeContext';
import { Menu } from '@/interfaces';
import { AnyType } from '@/type';
import { faChevronRight, faHome } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { reduce } from 'lodash';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Card, CardHeader } from 'reactstrap';

type State = {
  breadcrumb: ReactNode;
  activeMenuItem: Menu;
};

type Props = RouteComponentProps & { noCard?: boolean };

const chevronRightIconStyle = { margin: '0 5px' };

const WrappedBreadcrumbs = (props: Props) => {
  const [settings] = useContext(ThemeContext);

  const [state, setState] = useState<State>({ breadcrumb: [], activeMenuItem: { icon: faHome, key: '' } });

  const getPath = (data, url, parents = []) => {
    const items = reduce(
      data,
      (result: AnyType, entry: AnyType) => {
        if (result.length) {
          return result;
        }
        if (entry.url === url) {
          return [entry].concat(parents);
        }
        if (entry.children) {
          const nested = getPath(entry.children, url, ([entry] as AnyType).concat(parents));
          return (result || []).concat(nested.filter((e) => !!e));
        }
        return result;
      },
      []
    );
    return items.length > 0 ? items : [false];
  };

  const getBreadcrumb = (props: Props, items) => {
    const [activeMenuItem, ...path] = getPath(items, props.location.pathname);

    if (activeMenuItem && path.length) {
      return path.reverse().map((item, index) => {
        return (
          <span key={item.key}>
            <span className="text-muted">{intl.get(item.key)}</span>
            <FontAwesomeIcon size="sm" icon={faChevronRight} className="text-muted" style={chevronRightIconStyle} />
            {index === path.length - 1 && <strong>{intl.get(activeMenuItem.key)}</strong>}
          </span>
        );
      });
    }
    return (
      <span>
        {activeMenuItem && <strong>{intl.get(activeMenuItem.key)}</strong>}
        {!activeMenuItem && <strong>{'BREADCRUMBS'}</strong>}
      </span>
    );
  };

  const setBreadcrumbs = () => {
    const { menuData } = settings;
    setState({
      breadcrumb: getBreadcrumb(props, menuData),
      activeMenuItem: getPath(menuData, props.location.pathname)[0]
    });
  };

  useEffect(() => {
    setBreadcrumbs();
  }, [settings.locale, settings.menuData]);

  if (props.noCard === true) {
    return (
      <Card style={{ marginBottom: '1rem' }}>
        <CardHeader className={PageStyle.card__header}>
          {state.activeMenuItem.icon && <FontAwesomeIcon icon={state.activeMenuItem.icon} color="#0ab1fc" style={{ marginRight: '10px' }} />}
          {state.breadcrumb}
        </CardHeader>
      </Card>
    );
  }

  return (
    <div className="justify-content-center mr-auto utils_custom_title d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block">
      {state.activeMenuItem.icon && <FontAwesomeIcon icon={state.activeMenuItem.icon} color="#0ab1fc" style={{ marginRight: '10px' }} />}
      {state.breadcrumb}
    </div>
  );
};

export const Breadcrumbs = withRouter(WrappedBreadcrumbs);

export default Breadcrumbs;
