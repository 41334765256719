import { faCalendarAlt, faClock } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import moment from 'moment';
import React, { memo } from 'react';
import intl from 'react-intl-universal';

export const DateInfo = memo(({ createdAt, issueDate, canceled }: { createdAt: string; issueDate: string; canceled?: boolean }) => {
  return (
    <div>
      <div>
        <b className="mr-2">
          <Tooltip title={intl.get('BELGE_TARIHI')}>
            <FontAwesomeIcon icon={faCalendarAlt} style={{ fontSize: 15 }} />
          </Tooltip>
        </b>
        {moment(issueDate).format('DD.MM.YYYY')}
      </div>
      <div>
        <b className="mr-2">
          <Tooltip title={canceled === true ? intl.get('IPTAL_EDILME_ZAMANI') : intl.get('EKLENME_ZAMANI')}>
            <FontAwesomeIcon icon={faClock} style={{ fontSize: 15 }} />
          </Tooltip>
        </b>

        {moment(createdAt).format('DD.MM.YYYY HH:mm')}
      </div>
    </div>
  );
});

export default DateInfo;
