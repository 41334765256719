import { CustomizationTableAdditionalInformation, DocumentTemplateCustomizationSettingDto, TemplateAdditionalValueFieldType } from '@/apis/einvoice/interfaces';
import { NewInAddon, SaveInAddon } from '@/components/AddonButtons';
import { Delete, Edit } from '@/components/Buttons';
import { PageConst } from '@/constants/page';
import { XML_ESCAPE_CHARS } from '@/constants/regex';
import { Alert, Col, Row, Table } from 'antd';
import { Formik, FormikProps } from 'formik';
import { Form, FormItem, Input } from 'formik-antd';
import React, { useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { removeItem, replaceItem } from 'redux-toolbelt-immutable-helpers';
import * as Yup from 'yup';

type EditedState = {
  isEditing: boolean;
  index?: number;
};

const NotesTab = ({
  documentTemplateCustomizationSetting,
  onChange
}: {
  documentTemplateCustomizationSetting?: DocumentTemplateCustomizationSettingDto;
  onChange: (data: CustomizationTableAdditionalInformation[]) => void;
}) => {
  const [edited, setEdited] = useState<EditedState>({ isEditing: false });

  let form = (useRef(null) as unknown) as FormikProps<CustomizationTableAdditionalInformation>;

  const validatiorNoteScheme = Yup.object().shape<CustomizationTableAdditionalInformation>({
    title: Yup.string().nullable().required().max(500).matches(XML_ESCAPE_CHARS),
    conditionValue: Yup.string().nullable().required().max(500).matches(XML_ESCAPE_CHARS),
    templateAdditionalValueFieldType: Yup.mixed<TemplateAdditionalValueFieldType>().oneOf(Object.values(TemplateAdditionalValueFieldType))
  });

  const onDelete = (record: CustomizationTableAdditionalInformation) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const index = documentTemplateCustomizationSetting!.customizationTableAdditionalInformations!.indexOf(record);

    setEdited({ isEditing: false, index: undefined });

    form.resetForm();

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    onChange(removeItem(documentTemplateCustomizationSetting!.customizationTableAdditionalInformations || [], index));
  };

  const onEdit = (record: CustomizationTableAdditionalInformation) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const index = documentTemplateCustomizationSetting!.customizationTableAdditionalInformations!.indexOf(record);
    setEdited({ isEditing: true, index: index });
    form.setValues(record);
  };

  return (
    <>
      <Row gutter={PageConst.RowGutter}>
        <Col sm={24}>
          <Alert
            closable
            style={{ marginBottom: 10 }}
            description={
              <div>
                <br />
                Örn : <b>Not</b> alanında;{' '}
                <i>
                  <b>SAP Sipariş No : 12345678901</b>
                </i>{' '}
                şeklinde gönderilen notun <b>SAP Sipariş No : </b> değerinden sonraki kısmı alınarak özelleştirme tablosuna belirtilen başlıkla birlikte
                eklenir.
              </div>
            }
            message={'Not alanında; belirtilen şarta uygun bir değer varsa özelleştirme tablosunda belirttiğiniz başlıkla birlikte eklenir.'}
            type="info"
          />
        </Col>
      </Row>

      <Formik<CustomizationTableAdditionalInformation>
        validateOnBlur={false}
        onSubmit={(values, { resetForm }) => {
          if (form.isValid) {
            if (edited.isEditing) {
              onChange(replaceItem(documentTemplateCustomizationSetting?.customizationTableAdditionalInformations || [], edited.index!, values));
              setEdited({ isEditing: false, index: undefined });
              resetForm();
            } else {
              onChange([...(documentTemplateCustomizationSetting?.customizationTableAdditionalInformations || []), { ...values }]);
              resetForm();
            }
          }
        }}
        innerRef={(instance) => (form = instance)}
        validationSchema={validatiorNoteScheme}
        initialValues={{
          title: '',
          conditionValue: '',
          templateAdditionalValueFieldType: TemplateAdditionalValueFieldType.Note
        }}
      >
        <Form layout="vertical">
          <Row gutter={PageConst.RowGutter}>
            <Col sm={12}>
              <FormItem name="title" label={intl.get('TemplateSettings.Title')} required>
                <Input name="title" placeholder={'SAP Sipariş Numarası'} tabIndex={0} />
              </FormItem>
            </Col>
            <Col sm={12}>
              <FormItem name="conditionValue" label={'Koşul (* İle Başlayan)'} required>
                <Input
                  name="conditionValue"
                  placeholder={'SAP Sipariş No :'}
                  tabIndex={0}
                  addonAfter={edited.isEditing ? <SaveInAddon onClick={() => form.handleSubmit()} /> : <NewInAddon onClick={() => form.handleSubmit()} />}
                />
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Formik>
      <Row gutter={PageConst.RowGutter}>
        <Col sm={24}>
          <Table<CustomizationTableAdditionalInformation>
            bordered
            className="mt-2"
            pagination={false}
            dataSource={
              (documentTemplateCustomizationSetting &&
                documentTemplateCustomizationSetting.customizationTableAdditionalInformations &&
                documentTemplateCustomizationSetting.customizationTableAdditionalInformations.filter(
                  (x) => x.templateAdditionalValueFieldType === TemplateAdditionalValueFieldType.Note
                )) ||
              []
            }
            columns={[
              {
                title: intl.get('TemplateSettings.Title'),
                dataIndex: 'title',
                key: 'title'
              },
              {
                title: 'Koşul (* İle Başlayan)',
                dataIndex: 'conditionValue',
                key: 'conditionValue'
              },
              {
                width: 95,
                align: 'center',
                key: 'proseses',
                title: intl.get('ISLEMLER'),
                render: (text, record) => {
                  return (
                    <div>
                      <Edit onlyIcon onClick={() => onEdit(record)} /> <Delete onlyIcon onClick={() => onDelete(record)} />
                    </div>
                  );
                }
              }
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

export default NotesTab;
