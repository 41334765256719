import { ProductType } from '@/apis/identity/interfaces';
import { HasAccountant, HasProduct, HasRole, HassAccountantAllowUploadDocument, HideMarketPlace } from '@/components/Auth';
import { WORKING_ENVIRONMENT } from '@/config';
import { Menu } from '@/interfaces';
import { flattenItems } from '@/utils/array';
import { faDatabase, faPersonCarry, faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import { faList, faListAlt, faPencil, faShip, faStoreAlt, faFileExcel as farFileExcel, faPaperPlane as farPaperPlan } from '@fortawesome/pro-regular-svg-icons';
import {
  faArchive,
  faArrowAltDown,
  faArrowAltUp,
  faArrowDown,
  faArrowUp,
  faBan,
  faBellOn,
  faBells,
  faBook,
  faCloudUpload,
  faStore,
  faCogs,
  faDownload,
  faFile,
  faFileSignature,
  faHistory,
  faHome,
  faInfoCircle,
  faListUl,
  faMailbox,
  faPaperPlane,
  faPencilAlt,
  faPlus,
  faRocket,
  faTable,
  faTag,
  faTags,
  faUpload,
  faUsers
} from '@fortawesome/pro-solid-svg-icons';

export const LeftMenuData = (): Menu[] => {
  const menu: Menu[] = [
    {
      key: 'home',
      url: '/dashboard',
      icon: faHome
    }
  ];

  menu.push({
    key: 'connector',
    icon: faPencilAlt,
    children: [
      {
        key: 'connector-unsentinvoices',
        url: '/connector/unsentinvoices',
        icon: faDatabase
      },
      {
        key: 'connector-unsentdespatches',
        url: '/connector/unsentdespatches',
        icon: faDatabase
      }
    ]
  });

  if (WORKING_ENVIRONMENT === 'Production' && !HideMarketPlace()) {
    menu.push({
      key: 'market-place-orders',
      url: '/marketplace/orders',
      icon: faStore
    });
  }

  if (HasProduct(ProductType.EInvoice) || HasProduct(ProductType.EArchive)) {
    const invoiceMenuItems = {
      key: 'invoice-processes',
      icon: faPencilAlt,
      children: [
        {
          key: 'invoice-processes-create',
          // url: '/invoice/create',
          icon: faPlus,
          children: [
            {
              key: 'invoice-processes-create-general',
              url: '/invoice/editor/general',
              icon: faPencil
            },
            {
              key: 'invoice-processes-create-export',
              url: '/invoice/editor/export',
              icon: faShip
            },
            {
              key: 'invoice-processes-create-taxfree',
              url: '/invoice/editor/taxfree',
              icon: faPersonCarry
            }
          ]
        },
        {
          key: 'invoice-processes-draft',
          url: '/invoice/draft',
          icon: faListUl
        }
      ]
    };

    if (HasAccountant()) {
      if (HassAccountantAllowUploadDocument()) {
        menu.push(invoiceMenuItems);
      }
    } else {
      menu.push(invoiceMenuItems);
    }
  }

  if (HasProduct(ProductType.EInvoice)) {
    const eInvoiceMenus = {
      key: 'einvoice',
      icon: faPaperPlane,
      children: [
        {
          key: 'einvoice-outgoing',
          icon: faArrowUp,
          children: [
            {
              key: 'einvoice-outgoing-list',
              url: '/einvoice/outgoing/list',
              icon: faFile,
              role: 'EI:OUT:INV'
            },
            {
              key: 'einvoice-outgoing-excel',
              icon: farFileExcel,
              children: [
                {
                  key: 'einvoice-outgoing-excel-reports',
                  url: '/einvoice/outgoing/excel/reports',
                  icon: faTable,
                  role: 'EI:OUT:RM:REPORTS'
                },
                {
                  key: 'einvoice-outgoing-excel-templates',
                  url: '/einvoice/outgoing/excel/templates',
                  icon: faTags,
                  role: 'EI:OUT:RM:TEMPLATES'
                }
              ]
            }
          ]
        },
        {
          key: 'einvoice-incoming',
          icon: faArrowDown,
          children: [
            {
              key: 'einvoice-incoming-list',
              url: '/einvoice/incoming/list',
              icon: faFile,
              role: 'EI:INC:INV'
            },
            {
              key: 'einvoice-incoming-excel',
              icon: farFileExcel,
              children: [
                {
                  key: 'einvoice-incoming-excel-reports',
                  url: '/einvoice/incoming/excel/reports',
                  icon: faTable,
                  role: 'EI:INC:RM:REPORTS'
                },
                {
                  key: 'einvoice-incoming-excel-templates',
                  url: '/einvoice/incoming/excel/templates',
                  icon: faTags,
                  role: 'EI:INC:RM:TEMPLATES'
                }
              ]
            }
          ]
        },
        {
          key: 'einvoice-definations',
          icon: faCogs,
          children: [
            {
              key: 'einvoice-definations-series',
              url: '/einvoice/definations/series',
              icon: faListUl,
              role: 'EI:DEF:SERIES'
            },
            {
              key: 'einvoice-definations-templates',
              icon: faTags,
              url: '/einvoice/definations/custom-templates',
              role: 'EI:DEF:DOCUMENT_TEMPLATES'

              // children: [
              //   {
              //     key: 'einvoice-definations-templates-dynamic',
              //     url: '/einvoice/definations/custom-templates',
              //     icon: faTags,
              //     role: 'EI:DEF:DOCUMENT_TEMPLATES'
              //   },
              //   {
              //     key: 'einvoice-definations-templates-xslts',
              //     url: '/einvoice/definations/xslts',
              //     icon: faTags,
              //     role: 'EI:DEF:DOCUMENT_TEMPLATES'
              //   }
              // ]
            },
            {
              key: 'einvoice-definations-file__title',
              url: '/einvoice/definations/filetitles',
              icon: faFileSignature,
              role: 'EI:DEF:FILE_EXPORT_TITLES'
            }
          ]
        },
        {
          key: 'einvoice-notifications',
          icon: faBellOn,
          children: [
            {
              key: 'einvoice-notifications-incomingnotification',
              url: '/einvoice/notifications/incomingnotification',
              icon: faBells,
              role: 'EI:NOTIFY:INC:DYNAMIC_RULES'
            },
            {
              key: 'einvoice-notifications-outgoingnotification',
              url: '/einvoice/notifications/outgoingnotification',
              icon: faBells,
              role: 'EI:NOTIFY:OUT:DYNAMIC_RULES'
            }
          ]
        },
        {
          key: 'einvoice-exinvoice',
          icon: faHistory,
          children: [
            {
              key: 'einvoice-exinvoice-incoming',
              url: '/einvoice/ex/incoming',
              icon: faDownload,
              role: 'EI:EX:INC'
            },
            {
              key: 'einvoice-exinvoice-outgoing',
              url: '/einvoice/ex/outgoing',
              icon: faUpload,
              role: 'EI:EX:OUT'
            },
            {
              key: 'einvoice-exinvoice-queue',
              url: '/einvoice/ex/queue',
              icon: faList,
              role: 'EI:EX:QUEUE'
            },
            {
              key: 'einvoice-exinvoice-upload',
              url: '/einvoice/ex/upload',
              icon: faCloudUpload,
              role: 'EI:EX:POST'
            }
          ]
        },
        {
          key: 'einvoice-tags',
          url: '/einvoice/tags',
          icon: faTag,
          role: 'EI:TAGS'
        }
      ]
    } as any;

    const eInvoiceUploadsMenu = {
      key: 'einvoice-upload',
      url: '/einvoice/upload',
      icon: faCloudUpload,
      role: 'EI:UPLOADS_DOCUMENT'
    };

    if (HasAccountant()) {
      if (HassAccountantAllowUploadDocument()) {
        eInvoiceMenus.children.push(eInvoiceUploadsMenu);
      }
    } else {
      eInvoiceMenus.children.push(eInvoiceUploadsMenu);
    }

    menu.push(eInvoiceMenus);
  }

  if (HasProduct(ProductType.EArchive)) {
    const eArchiveMenus = {
      key: 'earchive',
      icon: farPaperPlan,
      children: [
        {
          key: 'earchive-invoice',
          icon: faArrowAltUp,
          children: [
            {
              key: 'earchive-invoice-list',
              url: '/earchive/list',
              icon: faBook,
              role: 'EA:OUT:INV'
            },
            {
              key: 'earchive-invoice-cancel',
              url: '/earchive/list/canceled',
              icon: faBan,
              role: 'EA:OUT:CANCELED'
            },
            {
              key: 'earchive-invoice-excel',
              icon: farFileExcel,
              children: [
                {
                  key: 'earchive-invoice-excel-reports',
                  url: '/earchive/excel/reports',
                  icon: faTable,
                  role: 'EA:OUT:RM:REPORTS'
                },
                {
                  key: 'earchive-invoice-excel-templates',
                  url: '/earchive/excel/templates',
                  icon: faTags,
                  role: 'EA:OUT:RM:TEMPLATES'
                }
              ]
            }
          ]
        },
        {
          key: 'earchive-definations',
          icon: faCogs,
          children: [
            {
              key: 'earchive-definations-series',
              url: '/earchive/definations/series',
              icon: faListUl,
              role: 'EA:DEF:SERIES'
            },
            {
              key: 'earchive-definations-templates',
              icon: faTags,
              role: 'EA:DEF:DOCUMENT_TEMPLATES',
              url: '/earchive/definations/custom-templates'
            },
            {
              key: 'earchive-definations-mailing',
              url: '/earchive/definations/mailing',
              icon: faMailbox,
              role: 'EA:DEF:MAIL_SMS'
            },
            {
              key: 'earchive-definations-file__title',
              url: '/earchive/definations/filetitles',
              icon: faFileSignature,
              role: 'EA:DEF:FILE_EXPORT_TITLES'
            }
          ]
        },
        {
          key: 'earchive-exinvoice',
          icon: faHistory,
          children: [
            {
              key: 'earchive-exinvoice-list',
              url: '/earchive/exinvoices',
              icon: faBook,
              role: 'EA:EX:OUT'
            },
            {
              key: 'earchive-exinvoice-queue',
              url: '/earchive/ex/queue',
              icon: faList,
              role: 'EA:EX:QUEUE'
            },
            {
              key: 'earchive-exinvoice-upload',
              url: '/earchive/ex/upload',
              icon: faCloudUpload,
              role: 'EA:EX:POST'
            }
          ]
        },
        {
          key: 'earchive-notifications',
          icon: faBells,
          url: '/earchive/notifications',
          role: 'EA:NOTIFY:OUT:DYNAMIC_RULES'
        },
        {
          key: 'earchive-tags',
          url: '/earchive/tags',
          icon: faTag,
          role: 'EA:TAGS'
        }
      ]
    } as any;

    const eArchiveUploadMenu = {
      key: 'earchive-upload',
      url: '/earchive/upload',
      icon: faCloudUpload,
      role: 'EA:UPLOADS_DOCUMENT'
    };

    if (HasAccountant()) {
      if (HassAccountantAllowUploadDocument()) {
        eArchiveMenus.children.push(eArchiveUploadMenu);
      }
    } else {
      eArchiveMenus.children.push(eArchiveUploadMenu);
    }

    menu.push(eArchiveMenus);
  }

  if (HasProduct(ProductType.EDespatch)) {
    const eDespatchMenus: any = {
      key: 'edespatch',
      icon: faPaperPlane,
      children: []
    };

    const eDespatchDocumentMenus = {
      key: 'edespatch-processes',
      icon: faPencilAlt,
      children: [
        {
          key: 'edespatch-processes-create',
          url: '/edespatch/editor',
          icon: faPlus
        },
        {
          key: 'edespatch-processes-draft',
          url: '/edespatch/draft',
          icon: faListUl
        }
      ]
    };

    if (HasAccountant()) {
      if (HassAccountantAllowUploadDocument()) {
        eDespatchMenus.children.push(eDespatchDocumentMenus);
      }
    } else {
      eDespatchMenus.children.push(eDespatchDocumentMenus);
    }

    eDespatchMenus.children.push(
      ...([
        {
          key: 'edespatch-outgoing',
          icon: faArrowUp,
          children: [
            {
              key: 'edespatch-outgoing-list',
              url: '/edespatch/outgoing/list',
              icon: faFile,
              role: 'ED:OUT:DES'
            },
            {
              key: 'edespatch-outgoing-excel',
              icon: farFileExcel,
              children: [
                {
                  key: 'edespatch-outgoing-excel-reports',
                  url: '/edespatch/outgoing/excel/reports',
                  icon: faTable,
                  role: 'ED:OUT:RM:REPORTS'
                },
                {
                  key: 'edespatch-outgoing-excel-templates',
                  url: '/edespatch/outgoing/excel/templates',
                  icon: faTags,
                  role: 'ED:OUT:RM:TEMPLATES'
                }
              ]
            }
          ]
        },
        {
          key: 'edespatch-incoming',
          icon: faArrowDown,
          children: [
            {
              key: 'edespatch-incoming-list',
              url: '/edespatch/incoming/list',
              icon: faFile,
              role: 'ED:INC:DES'
            },
            {
              key: 'edespatch-incoming-excel',
              icon: farFileExcel,
              children: [
                {
                  key: 'edespatch-incoming-excel-reports',
                  url: '/edespatch/incoming/excel/reports',
                  icon: faTable,
                  role: 'ED:INC:RM:REPORTS'
                },
                {
                  key: 'edespatch-incoming-excel-templates',
                  url: '/edespatch/incoming/excel/templates',
                  icon: faTags,
                  role: 'ED:INC:RM:TEMPLATES'
                }
              ]
            }
          ]
        },
        {
          key: 'edespatch-definations',
          icon: faCogs,
          children: [
            {
              key: 'edespatch-definations-series',
              url: '/edespatch/definations/series',
              icon: faListUl,
              role: 'ED:DEF:SERIES'
            },
            {
              key: 'edespatch-definations-answer-series',
              url: '/edespatch/definations/answerseries',
              icon: faListUl,
              role: 'ED:DEF:ANSWER_SERIES'
            },
            // {
            //   key: 'edespatch-definations-xslts-despatchadvice',
            //   url: '/edespatch/definations/despatchadvicexslts',
            //   icon: faCogs,
            //   role: 'ED:DEF:DOCUMENT_TEMPLATES'
            // },

            {
              key: 'edespatch-definations-templates',
              icon: faTags,
              url: '/edespatch/definations/custom-templates',
              role: 'ED:DEF:DOCUMENT_TEMPLATES'
            },

            {
              key: 'edespatch-definations-xslts-receiptadvice',
              url: '/edespatch/definations/receiptadvicexslts',
              icon: faCogs,
              role: 'ED:DEF:ANSWER_DOCUMENT_TEMPLATES'
            },
            {
              key: 'edespatch-definations-mailing',
              url: '/edespatch/definations/mailing',
              icon: faMailbox,
              role: 'ED:DEF:MAIL_SMS'
            },
            {
              key: 'edespatch-definations-file__title',
              url: '/edespatch/definations/filetitles',
              icon: faFileSignature,
              role: 'ED:DEF:FILE_EXPORT_TITLES'
            }
          ]
        },
        {
          key: 'edespatch-notifications',
          icon: faBellOn,
          children: [
            {
              key: 'edespatch-notifications-incomingnotification',
              url: '/edespatch/notifications/incomingnotification',
              icon: faBells,
              role: 'ED:NOTIFY:INC:DYNAMIC_RULES'
            },
            {
              key: 'edespatch-notifications-outgoingnotification',
              url: '/edespatch/notifications/outgoingnotification',
              icon: faBells,
              role: 'ED:NOTIFY:OUT:DYNAMIC_RULES'
            }
          ]
        },
        {
          key: 'edespatch-tags',
          url: '/edespatch/tags',
          icon: faTag,
          role: 'ED:TAGS'
        }
      ] as any)
    );

    const eDespatchUploadsMenu = {
      key: 'edespatch-upload',
      url: '/edespatch/upload',
      icon: faCloudUpload,
      role: 'ED:UPLOADS_DOCUMENT'
    };

    if (HasAccountant()) {
      if (HassAccountantAllowUploadDocument()) {
        eDespatchMenus.children.push(eDespatchUploadsMenu);
      }
    } else {
      eDespatchMenus.children.push(eDespatchUploadsMenu);
    }

    menu.push(eDespatchMenus);
  }

  if (HasProduct(ProductType.ESmm)) {
    const eSmmMenus = {
      key: 'esmm',
      icon: farPaperPlan,
      children: []
    } as any;

    const eSmmDocumentMenu = {
      key: 'esmm-processes',
      icon: faPencilAlt,
      children: [
        {
          key: 'esmm-processes-create',
          url: '/esmm/editor',
          icon: faPlus
        },
        {
          key: 'esmm-processes-draft',
          url: '/esmm/draft',
          icon: faListUl
        }
      ]
    };

    if (HasAccountant()) {
      if (HassAccountantAllowUploadDocument()) {
        eSmmMenus.children.push(eSmmDocumentMenu);
      }
    } else {
      eSmmMenus.children.push(eSmmDocumentMenu);
    }

    eSmmMenus.children.push(
      ...([
        {
          key: 'esmm-outbox',
          icon: faArrowAltUp,
          children: [
            {
              key: 'esmm-outbox-list',
              url: '/esmm/list',
              icon: faBook,
              role: 'ESMM:OUT:V'
            },
            {
              key: 'esmm-outbox-cancel',
              url: '/esmm/list/canceled',
              icon: faBook,
              role: 'ESMM:OUT:CANCELED'
            },
            {
              key: 'esmm-outbox-excel',
              icon: farFileExcel,
              children: [
                {
                  key: 'esmm-outbox-excel-reports',
                  url: '/esmm/excel/reports',
                  icon: faTable,
                  role: 'ESMM:OUT:RM:REPORTS'
                },
                {
                  key: 'esmm-outbox-excel-templates',
                  url: '/esmm/excel/templates',
                  icon: faTags,
                  role: 'ESMM:OUT:RM:TEMPLATES'
                }
              ]
            }
          ]
        },
        {
          key: 'esmm-definations',
          icon: faCogs,
          children: [
            {
              key: 'esmm-definations-series',
              url: '/esmm/definations/series',
              icon: faListUl,
              role: 'ESMM:DEF:SERIES'
            },
            // {
            //   key: 'esmm-definations-xslts',
            //   url: '/esmm/definations/xslts',
            //   icon: faTags,
            //   role: 'ESMM:DEF:DOCUMENT_TEMPLATES'
            // },

            {
              key: 'esmm-definations-templates',
              icon: faTags,
              url: '/esmm/definations/custom-templates',
              role: 'ESMM:DEF:DOCUMENT_TEMPLATES'
            },

            {
              key: 'esmm-definations-mailing',
              url: '/esmm/definations/mailing',
              icon: faMailbox,
              role: 'ESMM:DEF:MAIL_SMS'
            },
            {
              key: 'esmm-definations-file__title',
              url: '/esmm/definations/filetitles',
              icon: faFileSignature,
              role: 'ESMM:DEF:FILE_EXPORT_TITLES'
            }
          ]
        },
        {
          key: 'esmm-notifications',
          icon: faBells,
          url: '/esmm/notifications',
          role: 'ESMM:NOTIFY:OUT:DYNAMIC_RULES'
        },
        {
          key: 'esmm-tags',
          url: '/esmm/tags',
          icon: faTag,
          role: 'ESMM:TAGS'
        }
      ] as any)
    );

    const eSmmUploadsMenu = {
      key: 'esmm-upload',
      url: '/esmm/upload',
      icon: faCloudUpload,
      role: 'ESMM:UPLOADS_DOCUMENT'
    };

    if (HasAccountant()) {
      if (HassAccountantAllowUploadDocument()) {
        eSmmMenus.children.push(eSmmUploadsMenu);
      }
    } else {
      eSmmMenus.children.push(eSmmUploadsMenu);
    }

    menu.push(eSmmMenus);
  }

  if (HasProduct(ProductType.EMm)) {
    const eMmMenus = {
      key: 'emm',
      icon: farPaperPlan,
      children: []
    } as any;

    const eMmDocumentMenus = {
      key: 'emm-processes',
      icon: faPencilAlt,
      children: [
        {
          key: 'emm-processes-create',
          url: '/emm/editor',
          icon: faPlus
        },
        {
          key: 'emm-processes-draft',
          url: '/emm/draft',
          icon: faListUl
        }
      ]
    };

    if (HasAccountant()) {
      if (HassAccountantAllowUploadDocument()) {
        eMmMenus.children.push(eMmDocumentMenus);
      }
    } else {
      eMmMenus.children.push(eMmDocumentMenus);
    }

    eMmMenus.children.push(
      ...([
        {
          key: 'emm-outbox',
          icon: faArrowAltUp,
          children: [
            {
              key: 'emm-outbox-list',
              url: '/emm/list',
              icon: faBook,
              role: 'EMM:OUT:V'
            },
            {
              key: 'emm-outbox-cancel',
              url: '/emm/list/canceled',
              icon: faBook,
              role: 'EMM:OUT:CANCELED'
            },
            {
              key: 'emm-outbox-excel',
              icon: farFileExcel,
              children: [
                {
                  key: 'emm-outbox-excel-reports',
                  url: '/emm/excel/reports',
                  icon: faTable,
                  role: 'EMM:OUT:RM:REPORTS'
                },
                {
                  key: 'emm-outbox-excel-templates',
                  url: '/emm/excel/templates',
                  icon: faTags,
                  role: 'EMM:OUT:RM:TEMPLATES'
                }
              ]
            }
          ]
        },
        {
          key: 'emm-definations',
          icon: faCogs,
          children: [
            {
              key: 'emm-definations-series',
              url: '/emm/definations/series',
              icon: faListUl,
              role: 'EMM:DEF:SERIES'
            },
            // {
            //   key: 'esmm-definations-xslts',
            //   url: '/esmm/definations/xslts',
            //   icon: faTags,
            //   role: 'ESMM:DEF:DOCUMENT_TEMPLATES'
            // },

            {
              key: 'emm-definations-templates',
              icon: faTags,
              url: '/emm/definations/custom-templates',
              role: 'EMM:DEF:DOCUMENT_TEMPLATES'
            },
            {
              key: 'emm-definations-mailing',
              url: '/emm/definations/mailing',
              icon: faMailbox,
              role: 'EMM:DEF:MAIL_SMS'
            },
            {
              key: 'emm-definations-file__title',
              url: '/emm/definations/filetitles',
              icon: faFileSignature,
              role: 'EMM:DEF:FILE_EXPORT_TITLES'
            }
          ]
        },
        {
          key: 'emm-notifications',
          icon: faBells,
          url: '/emm/notifications',
          role: 'EMM:NOTIFY:OUT:DYNAMIC_RULES'
        },
        {
          key: 'emm-tags',
          url: '/emm/tags',
          icon: faTag,
          role: 'EMM:TAGS'
        }
      ] as any)
    );

    const eMmUploadsMenu = {
      key: 'emm-upload',
      url: '/emm/upload',
      icon: faCloudUpload,
      role: 'EMM:UPLOADS_DOCUMENT'
    };

    if (HasAccountant()) {
      if (HassAccountantAllowUploadDocument()) {
        eMmMenus.children.push(eMmUploadsMenu);
      }
    } else {
      eMmMenus.children.push(eMmUploadsMenu);
    }

    menu.push(eMmMenus);
  }

  if (HasProduct(ProductType.EArchive) || HasProduct(ProductType.ESmm) || HasProduct(ProductType.EMm)) {
    menu.push({
      key: 'earchivedocuments-report',
      icon: faCogs,
      children: [
        {
          key: 'earchivedocuments-report-list',
          url: '/earchivedocuments/report/list',
          icon: faUsers,
          role: 'EA:REPORTS:LIST'
        },
        {
          key: 'earchivedocuments-report-create',
          url: '/earchivedocuments/report/create',
          icon: faPlusCircle,
          role: 'EA:REPORTS:CREATE'
        }
      ]
    });
  }

  menu.push({
    key: 'earchivedocuments-incoming',
    icon: faArrowAltDown,
    url: '/earchive/incoming/list',
    role: 'EA:INC:LIST'
  });

  menu.push({
    key: 'companies',
    url: '/companies',
    icon: faUsers,
    role: 'COMPANIES'
  });

  menu.push({
    key: 'productservices',
    url: '/productservices',
    icon: faListAlt
  });

  if (HasRole('ADMINISTRATOR')) {
    menu.push({
      key: 'management',
      icon: faCogs,
      children: [
        {
          key: 'management-companyinfo',
          url: '/management/company',
          icon: faInfoCircle
        },
        {
          key: 'management-users',
          url: '/management/users',
          icon: faUsers
        },
        {
          key: 'management-accountants',
          url: '/management/accountants',
          icon: faUsers
        },
        WORKING_ENVIRONMENT === 'Production' && !HideMarketPlace()
          ? {
              key: 'management-marketplace',
              url: '/management/marketplaces',
              icon: faStore
            }
          : (null as any),
        {
          key: 'management-connector',
          icon: faDatabase,
          children: [
            {
              key: 'management-connector-list',
              url: '/management/connector',
              icon: faList
            },
            {
              key: 'management-connector-queryeditor',
              url: '/management/connector/queryeditor',
              icon: faDatabase
            }
          ]
        },
        {
          key: 'management-apis',
          icon: faRocket,
          children: [
            {
              key: 'management-apis-list',
              url: '/management/apis',
              icon: faRocket
            },
            {
              key: 'management-apis-new',
              url: '/management/apis/new',
              icon: faPlus
            }
          ]
        },
        {
          key: 'management-documentarchives',
          url: '/management/documentarchives',
          icon: faArchive
        }
      ].filter((x) => x !== null)
    });
  }

  return menu;
};

export const MenuFlatten = () => {
  return flattenItems(LeftMenuData(), 'children');
};
