/* eslint-disable @typescript-eslint/no-inferrable-types */
import { DateFormat, IssueDateToday } from '@/constants/page';
import { XMLElement } from '@/xmldom-decorators';
import moment from 'moment';
import { AddressType, ContactType, IDType, PartyType, PeriodType } from '.';
import { NS_CAC, NS_CBC } from '../constants';
import TextType from './TextType';

/*
    17.04.2022 : Fully completed
*/
export class DespatchType {
  @XMLElement({ types: [{ name: 'ID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  id?: IDType;

  @XMLElement({ types: [{ name: 'ActualDespatchDate', namespaceUri: NS_CBC, itemType: () => String }] })
  actualDespatchDate: string = IssueDateToday;

  @XMLElement({ types: [{ name: 'ActualDespatchTime', namespaceUri: NS_CBC, itemType: () => String }] })
  actualDespatchTime?: string = moment().format(DateFormat.HMS);

  @XMLElement({ types: [{ name: 'Instructions', namespaceUri: NS_CBC, itemType: () => TextType }] })
  instructions?: TextType;

  @XMLElement({ types: [{ name: 'DespatchAddress', namespaceUri: NS_CAC, itemType: () => AddressType }] })
  despatchAddress?: AddressType;

  @XMLElement({ types: [{ name: 'DespatchParty', namespaceUri: NS_CAC, itemType: () => PartyType }] })
  despatchParty?: PartyType;

  @XMLElement({ types: [{ name: 'Contact', namespaceUri: NS_CAC, itemType: () => ContactType }] })
  contact?: ContactType;

  @XMLElement({ types: [{ name: 'EstimatedDespatchPeriod', namespaceUri: NS_CAC, itemType: () => PeriodType }] })
  estimatedDespatchPeriod?: PeriodType;
}

export default DespatchType;
