import { MenuOutlined } from '@ant-design/icons';
import { faArrows } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'antd';
import { arrayMoveImmutable } from 'array-move';
import React from 'react';
import { SortableContainer as SortableContainerHOC, SortableElement, SortableHandle } from 'react-sortable-hoc';
import './style.scss';

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999', padding: 16 }} />);

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableContainer = SortableContainerHOC((props) => <tbody {...props} />);

const SortableTable = ({
  data,
  columns,
  onDataChange,
  onChangeDataIndex,
  components
}: {
  data: any;
  columns: any;
  onDataChange?: any;
  onChangeDataIndex?: (oldIndex: number, newIndex: number) => void;
  components?: any;
}) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable([].concat(data), oldIndex, newIndex).filter((el) => !!el);
      console.log('Sorted items: ', newData);

      onDataChange && onDataChange(newData);
      onChangeDataIndex && onChangeDataIndex(oldIndex, newIndex);
    }
  };

  const DraggableContainer = (props) => <SortableContainer useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />;

  const DraggableBodyRow = ({ style, ...restProps }: any) => {
    const index = data.findIndex((x) => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <Table
      pagination={false}
      bordered
      className="excel-table"
      rowClassName="excel-row"
      dataSource={data}
      columns={[
        {
          title: <FontAwesomeIcon icon={faArrows} />,
          dataIndex: 'sort',
          align: 'center',
          width: 30,
          className: 'drag-visible',
          render: () => <DragHandle />
        },
        ...columns
      ]}
      rowKey="index"
      components={
        components
          ? {
              ...components,
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow
              }
            }
          : {
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow
              }
            }
      }
    />
  );
};

export default SortableTable;
