import { AuthRole, AuthScope } from '@/interfaces';
import { faUsers } from '@fortawesome/pro-regular-svg-icons';

export const COMPANIES_ROLES: AuthRole = {
  value: 'COMPANIES',
  icon: faUsers,
  checkable: true
};
export const GENERAL_COMPANIES_ROLES = [COMPANIES_ROLES];

export const COMPANIES_SCOPES: AuthScope = {
  value: 'companies',
  checkable: false,
  children: [
    {
      value: 'companies:read',
      checkable: true
    },
    {
      value: 'companies:write',
      checkable: true
    }
  ]
};
export const GENERAL_COMPANIES_SCOPES = [COMPANIES_SCOPES];
