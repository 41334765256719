/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLArray, XMLElement } from '@/xmldom-decorators';
import { CountryType, ItemIdentificationType, TextType } from '.';
import { NS_CAC, NS_CBC } from '../constants';
import ItemInstanceType from './ItemInstanceType';

/*
  27.04.2021 : Partially completed
  cac:CommodityClassification
*/
export class ItemType {
  @XMLElement({ types: [{ name: 'Description', namespaceUri: NS_CBC, itemType: () => String }] })
  description?: string;

  @XMLElement({ types: [{ name: 'Name', namespaceUri: NS_CBC, itemType: () => TextType }] })
  name?: TextType = undefined;

  @XMLElement({ types: [{ name: 'Keyword', namespaceUri: NS_CBC, itemType: () => String }] })
  keyword?: string;

  @XMLElement({ types: [{ name: 'BrandName', namespaceUri: NS_CBC, itemType: () => String }] })
  brandName?: string;

  @XMLElement({ types: [{ name: 'ModelName', namespaceUri: NS_CBC, itemType: () => String }] })
  modelName?: string;

  @XMLElement({ types: [{ name: 'BuyersItemIdentification', namespaceUri: NS_CAC, itemType: () => ItemIdentificationType }] })
  buyersItemIdentification?: ItemIdentificationType;

  @XMLElement({ types: [{ name: 'SellersItemIdentification', namespaceUri: NS_CAC, itemType: () => ItemIdentificationType }] })
  sellersItemIdentification?: ItemIdentificationType;

  @XMLElement({ types: [{ name: 'ManufacturersItemIdentification', namespaceUri: NS_CAC, itemType: () => ItemIdentificationType }] })
  manufacturersItemIdentification?: ItemIdentificationType;

  @XMLArray({ nested: false, itemTypes: [{ name: 'AdditionalItemIdentification', namespaceUri: NS_CAC, itemType: () => ItemIdentificationType }] })
  additionalItemIdentification?: ItemIdentificationType[];

  @XMLElement({ types: [{ name: 'OriginCountry', namespaceUri: NS_CAC, itemType: () => CountryType }] })
  originCountry?: CountryType;

  // TODO : cac:CommodityClassification

  @XMLArray({ nested: false, itemTypes: [{ name: 'ItemInstance', namespaceUri: NS_CAC, itemType: () => ItemInstanceType }] })
  itemInstance?: ItemInstanceType[];
}

export default ItemType;
