/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { IDType } from '.';
import { NS_CBC } from '../constants';

/*
  27.04.2021 : Fully completed
*/
export class TaxSchemeType {
  @XMLElement({ types: [{ name: 'ID', namespaceUri: NS_CBC, itemType: () => IDType }] })
  id?: IDType;

  @XMLElement({ types: [{ name: 'Name', namespaceUri: NS_CBC, itemType: () => String }] })
  name?: string;

  @XMLElement({ types: [{ name: 'TaxTypeCode', namespaceUri: NS_CBC, itemType: () => String }] })
  taxTypeCode?: string;
}

export default TaxSchemeType;
