import { SendType } from '@/apis/earchive/interfaces';
import { Badge } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';

export const EArchiveSendType = ({ sendType, isInternet }: { sendType?: SendType; isInternet?: boolean }) => {
  return (
    <div>
      {sendType === 'ELEKTRONIK' && (
        <div>
          <Badge color="green" text={intl.get('ELEKTRONIK')} />
        </div>
      )}
      {sendType === 'KAGIT' && (
        <div>
          <Badge color="orange" text={intl.get('KAGIT')} />
        </div>
      )}
      {isInternet && (
        <div>
          <Badge color="blue" text={intl.get('INTERNET_SATIS')} />
        </div>
      )}
    </div>
  );
};

export default EArchiveSendType;
