import { Tooltip } from 'antd';
import _, { trim } from 'lodash';
import React from 'react';
import intl from 'react-intl-universal';

interface Props {
  partyIdentification?: string;
  firstName?: string | null;
  familyName?: string | null;
  partyName?: string | null;
  fullName?: string | null;
  isVirtual?: boolean | null;
  truncate?: number;
}

export interface Person {
  firstName: string;
  familyName: string;
}

export const getFullName = ({ fullName, partyIdentification, firstName, familyName, partyName }: Props) => {
  if (fullName) return fullName;

  let name = '';
  if (partyIdentification != null && partyIdentification.length === 11) {
    if (firstName) name += firstName;
    if (familyName) name += ` ${familyName}`;
    if (partyName) name += ` / ${partyName}`;
  } else {
    if (partyName) name += partyName;
    if (firstName) name += ` / ${firstName}`;
    if (familyName) name += ` ${familyName}`;
  }

  return name;
};

export const getPersonFromTitle = (title: string): Person => {
  return { firstName: trim(title).split(' ').slice(0, -1).join(' '), familyName: trim(title).split(' ').slice(-1).join(' ') };
};

export const PartyInfo = (props: Props) => {
  const truncate = props.truncate || 20;
  const fullName = getFullName(props);

  const content = (
    <div>
      <div>
        <b>
          {props.partyIdentification != null && props.partyIdentification.length === 10 && intl.get('VKN')}
          {props.partyIdentification != null && props.partyIdentification.length === 11 && intl.get('TC_NO')}
        </b>
        {props.partyIdentification}
      </div>
      <b>
        {_.truncate(fullName, { length: truncate })}
        {props.isVirtual === true && (
          <>
             |{' '}
            <label className={`label label-info`} style={{ fontSize: 10 }}>
              Sanal Alıcı
            </label>
          </>
        )}
      </b>
    </div>
  );

  if (fullName.length > truncate) {
    return (
      <Tooltip placement="bottom" title={fullName} overlayClassName={'antd-tooltip-center'}>
        {content}
      </Tooltip>
    );
  } else {
    return content;
  }
};

export default PartyInfo;
