/* eslint-disable @typescript-eslint/no-inferrable-types */
import { XMLElement } from '@/xmldom-decorators';
import { NS_CBC } from '../constants';
import { MeasureType } from './QuantityType';

/*
  27.04.2021 : Fully completed
*/
export class PeriodType {
  @XMLElement({ types: [{ name: 'StartDate', namespaceUri: NS_CBC, itemType: () => String }] })
  startDate?: string;

  @XMLElement({ types: [{ name: 'StartTime', namespaceUri: NS_CBC, itemType: () => String }] })
  startTime?: string;

  @XMLElement({ types: [{ name: 'EndDate', namespaceUri: NS_CBC, itemType: () => String }] })
  endDate?: string;

  @XMLElement({ types: [{ name: 'EndTime', namespaceUri: NS_CBC, itemType: () => String }] })
  endTime?: string;

  @XMLElement({ types: [{ name: 'DurationMeasure', namespaceUri: NS_CBC, itemType: () => MeasureType }] })
  durationMeasure?: MeasureType;

  @XMLElement({ types: [{ name: 'Description', namespaceUri: NS_CBC, itemType: () => String }] })
  description?: string;
}

export default PeriodType;
