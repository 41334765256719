export type ApplicationResponse = {
  code: number;
  description: string;
};
export const ApplicationResponseCodeList: Array<ApplicationResponse> = [
  {
    code: 1000,
    description: 'ZARF KUYRUGA EKLENDI'
  },
  {
    code: 1100,
    description: 'ZARF ISLENIYOR'
  },
  {
    code: 1110,
    description: 'ZIP DOSYASI DEGIL'
  },
  {
    code: 1111,
    description: 'ZARF ID UZUNLUGU GECERSIZ'
  },
  {
    code: 1120,
    description: 'ZARF ARSIVDEN KOPYALANAMADI'
  },
  {
    code: 1130,
    description: 'ZIP ACILAMADI'
  },
  {
    code: 1131,
    description: 'ZIP BIR DOSYA ICERMELI'
  },
  {
    code: 1132,
    description: 'XML DOSYASI DEGIL'
  },
  {
    code: 1133,
    description: 'ZARF ID VE XML DOSYASININ ADI AYNI OLMALI'
  },
  {
    code: 1140,
    description: 'BELGE AYRISTIRILAMADI'
  },
  {
    code: 1141,
    description: 'ZARF ID YOK'
  },
  {
    code: 1142,
    description: 'ZARF ID VE ZIP DOSYASI ADI AYNI OLMALI'
  },
  {
    code: 1143,
    description: 'GECERSIZ VERSIYON'
  },
  {
    code: 1150,
    description: 'SCHEMATRON KONTROL SONUCU HATALI'
  },
  {
    code: 1160,
    description: 'XML SEMA KONTROLUNDEN GECEMEDI'
  },
  {
    code: 1161,
    description: 'IMZA SAHIBI TCKN VKN ALINAMADI'
  },
  {
    code: 1162,
    description: 'IMZA KAYDEDILEMEDI'
  },
  {
    code: 1163,
    description: 'GONDERILEN ZARF SISTEMDE DAHA ONCE KAYITLI OLAN BIR BELGEYI ICERMEKTEDIR'
  },
  {
    code: 1170,
    description: 'YETKI KONTROL EDILEMEDI'
  },
  {
    code: 1171,
    description: 'GONDERICI BIRIM YETKISI YOK'
  },
  {
    code: 1172,
    description: 'POSTA KUTUSU YETKISI YOK'
  },
  {
    code: 1175,
    description: 'IMZA YETKISI KONTROL EDILEMEDI'
  },
  {
    code: 1176,
    description: 'IMZA SAHIBI YETKISIZ'
  },
  {
    code: 1177,
    description: 'GECERSIZ IMZA'
  },
  {
    code: 1180,
    description: 'ADRES KONTROL EDILEMEDI'
  },
  {
    code: 1181,
    description: 'ADRES BULUNAMADI'
  },
  {
    code: 1182,
    description: 'KULLANICI EKLENEMEDI'
  },
  {
    code: 1183,
    description: 'KULLANICI SILENEMEDI'
  },
  {
    code: 1190,
    description: 'SISTEM YANITI HAZIRLANAMADI'
  },
  {
    code: 1195,
    description: 'SISTEM HATASI'
  },
  {
    code: 1200,
    description: 'ZARF BASARIYLA ISLENDI'
  },
  {
    code: 1210,
    description: 'BELGE BULUNAN ADRESE GONDERILEMEDI'
  },
  {
    code: 1215,
    description: 'BELGE GONDERIMI BASARISIZ. TERKAR GONDERME SONLANDI'
  },
  {
    code: 1220,
    description: 'HEDEFTEN SISTEM YANITI GELMEDI'
  },
  {
    code: 1230,
    description: 'HEDEFTEN SISTEM YANITI BASARISIZ GELDI'
  },
  {
    code: 1300,
    description: 'BASARIYLA TAMAMLANDI'
  }
];
