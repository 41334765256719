// tslint:disable
// eslint:disable
/**
 * General API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MarketPlaceOrderPaginationResult } from '../interfaces';
// @ts-ignore
import { MarketPlacePlatform } from '../interfaces';
// @ts-ignore
import { MarketPlaceSetting } from '../interfaces';
// @ts-ignore
import { MarketPlaceSettingDto } from '../interfaces';
/**
 * MarketPlaceApi - axios parameter creator
 * @export
 */
export const MarketPlaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Detay : Firmaya ait seçilen pazaryerindeki siparişleri listelemek için bu uç kullanılabilir.
         * @summary Belirtilen pazaryerindeki siparişleri listeler
         * @param {string} id Siparişleri çağırmak istediğiniz pazaryeri entegrasyon id değeri
         * @param {string} beginDate Sipariş tarihini başlangıç
         * @param {string} endDate Sipariş tarihini bitiş
         * @param {number} page Kaçıncı sayfa
         * @param {number} pageSize Sayfada gösterilecek kayıt sayısı
         * @param {string} [orderStatus] Pazaryerindeki sipariş durumu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MarketplacesIdOrdersGet: async (requestParameters: V1MarketplacesIdOrdersGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1MarketplacesIdOrdersGet.');
            }
            // verify required parameter 'beginDate' is not null or undefined
            if (requestParameters.beginDate === null || requestParameters.beginDate === undefined) {
                throw new RequiredError('beginDate','Required parameter beginDate was null or undefined when calling v1MarketplacesIdOrdersGet.');
            }
            // verify required parameter 'endDate' is not null or undefined
            if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
                throw new RequiredError('endDate','Required parameter endDate was null or undefined when calling v1MarketplacesIdOrdersGet.');
            }
            // verify required parameter 'page' is not null or undefined
            if (requestParameters.page === null || requestParameters.page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling v1MarketplacesIdOrdersGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling v1MarketplacesIdOrdersGet.');
            }
            const localVarPath = `/v1/marketplaces/{id}/orders`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (requestParameters.beginDate !== undefined) {
                localVarQueryParameter['beginDate'] = (requestParameters.beginDate as any instanceof Date) ?
                    (requestParameters.beginDate as any).toISOString() : requestParameters.beginDate;
            }
            if (requestParameters.endDate !== undefined) {
                localVarQueryParameter['endDate'] = (requestParameters.endDate as any instanceof Date) ?
                    (requestParameters.endDate as any).toISOString() : requestParameters.endDate;
            }
            if (requestParameters.orderStatus !== undefined) {
                localVarQueryParameter['orderStatus'] = requestParameters.orderStatus;
            }
            if (requestParameters.page !== undefined) {
                localVarQueryParameter['page'] = requestParameters.page;
            }
            if (requestParameters.pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = requestParameters.pageSize;
            }

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detay : Firmaya ait pazaryeri entegrasyon bilgilerine bu uç ile ulaşabilirsiniz
         * @summary Pazaryeri entegrasyon listesine bu uç ile erişebilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MarketplacesPlatformsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/marketplaces/platforms`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detay : Firmaya ait pazaryeri entegrasyon bilgilerine bu uç ile ulaşabilirsiniz
         * @summary Pazaryeri entegrasyon bilgilerine bu uç ile erişebilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MarketplacesSettingsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/marketplaces/settings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pazaryeri entegrasyonuna ait bilgiyi silmek için bu uç kullanılabilir.
         * @param {string} id Silinmesini istediğiniz kaydın ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MarketplacesSettingsIdDelete: async (requestParameters: V1MarketplacesSettingsIdDeleteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1MarketplacesSettingsIdDelete.');
            }
            const localVarPath = `/v1/marketplaces/settings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detay : Firmaya ait seçilen pazaryeri entegrasyon bilgilerini güncellemek için bu uç kullanılabilir.
         * @summary Pazaryeri entegrasyon bilgilerini günceller
         * @param {string} id Kayıt id
         * @param {MarketPlaceSettingDto} marketPlaceSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MarketplacesSettingsIdPut: async (requestParameters: V1MarketplacesSettingsIdPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1MarketplacesSettingsIdPut.');
            }
            // verify required parameter 'marketPlaceSettingDto' is not null or undefined
            if (requestParameters.marketPlaceSettingDto === null || requestParameters.marketPlaceSettingDto === undefined) {
                throw new RequiredError('marketPlaceSettingDto','Required parameter marketPlaceSettingDto was null or undefined when calling v1MarketplacesSettingsIdPut.');
            }
            const localVarPath = `/v1/marketplaces/settings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.marketPlaceSettingDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.marketPlaceSettingDto !== undefined ? requestParameters.marketPlaceSettingDto : {}) : (requestParameters.marketPlaceSettingDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detay : Firmaya ait seçilen pazaryeri entegrasyon bilgilerini güncellemek için bu uç kullanılabilir.
         * @summary Pazaryeri entegrasyon bilgilerini günceller
         * @param {MarketPlaceSettingDto} marketPlaceSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MarketplacesSettingsPost: async (requestParameters: V1MarketplacesSettingsPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketPlaceSettingDto' is not null or undefined
            if (requestParameters.marketPlaceSettingDto === null || requestParameters.marketPlaceSettingDto === undefined) {
                throw new RequiredError('marketPlaceSettingDto','Required parameter marketPlaceSettingDto was null or undefined when calling v1MarketplacesSettingsPost.');
            }
            const localVarPath = `/v1/marketplaces/settings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestParameters.marketPlaceSettingDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestParameters.marketPlaceSettingDto !== undefined ? requestParameters.marketPlaceSettingDto : {}) : (requestParameters.marketPlaceSettingDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketPlaceApi - functional programming interface
 * @export
 */
export const MarketPlaceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Detay : Firmaya ait seçilen pazaryerindeki siparişleri listelemek için bu uç kullanılabilir.
         * @summary Belirtilen pazaryerindeki siparişleri listeler
         * @param {string} id Siparişleri çağırmak istediğiniz pazaryeri entegrasyon id değeri
         * @param {string} beginDate Sipariş tarihini başlangıç
         * @param {string} endDate Sipariş tarihini bitiş
         * @param {number} page Kaçıncı sayfa
         * @param {number} pageSize Sayfada gösterilecek kayıt sayısı
         * @param {string} [orderStatus] Pazaryerindeki sipariş durumu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MarketplacesIdOrdersGet(requestParameters: V1MarketplacesIdOrdersGetRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketPlaceOrderPaginationResult>> {
            const localVarAxiosArgs = await MarketPlaceApiAxiosParamCreator(configuration).v1MarketplacesIdOrdersGet(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Detay : Firmaya ait pazaryeri entegrasyon bilgilerine bu uç ile ulaşabilirsiniz
         * @summary Pazaryeri entegrasyon listesine bu uç ile erişebilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MarketplacesPlatformsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketPlacePlatform>>> {
            const localVarAxiosArgs = await MarketPlaceApiAxiosParamCreator(configuration).v1MarketplacesPlatformsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Detay : Firmaya ait pazaryeri entegrasyon bilgilerine bu uç ile ulaşabilirsiniz
         * @summary Pazaryeri entegrasyon bilgilerine bu uç ile erişebilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MarketplacesSettingsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketPlaceSetting>>> {
            const localVarAxiosArgs = await MarketPlaceApiAxiosParamCreator(configuration).v1MarketplacesSettingsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Pazaryeri entegrasyonuna ait bilgiyi silmek için bu uç kullanılabilir.
         * @param {string} id Silinmesini istediğiniz kaydın ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MarketplacesSettingsIdDelete(requestParameters: V1MarketplacesSettingsIdDeleteRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MarketPlaceApiAxiosParamCreator(configuration).v1MarketplacesSettingsIdDelete(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Detay : Firmaya ait seçilen pazaryeri entegrasyon bilgilerini güncellemek için bu uç kullanılabilir.
         * @summary Pazaryeri entegrasyon bilgilerini günceller
         * @param {string} id Kayıt id
         * @param {MarketPlaceSettingDto} marketPlaceSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MarketplacesSettingsIdPut(requestParameters: V1MarketplacesSettingsIdPutRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MarketPlaceApiAxiosParamCreator(configuration).v1MarketplacesSettingsIdPut(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Detay : Firmaya ait seçilen pazaryeri entegrasyon bilgilerini güncellemek için bu uç kullanılabilir.
         * @summary Pazaryeri entegrasyon bilgilerini günceller
         * @param {MarketPlaceSettingDto} marketPlaceSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MarketplacesSettingsPost(requestParameters: V1MarketplacesSettingsPostRequest,options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MarketPlaceApiAxiosParamCreator(configuration).v1MarketplacesSettingsPost(requestParameters,options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MarketPlaceApi - factory interface
 * @export
 */
export const MarketPlaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Detay : Firmaya ait seçilen pazaryerindeki siparişleri listelemek için bu uç kullanılabilir.
         * @summary Belirtilen pazaryerindeki siparişleri listeler
         * @param {string} id Siparişleri çağırmak istediğiniz pazaryeri entegrasyon id değeri
         * @param {string} beginDate Sipariş tarihini başlangıç
         * @param {string} endDate Sipariş tarihini bitiş
         * @param {number} page Kaçıncı sayfa
         * @param {number} pageSize Sayfada gösterilecek kayıt sayısı
         * @param {string} [orderStatus] Pazaryerindeki sipariş durumu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MarketplacesIdOrdersGet(requestParameters: V1MarketplacesIdOrdersGetRequest, options?: any): AxiosPromise<MarketPlaceOrderPaginationResult> {
            return MarketPlaceApiFp(configuration).v1MarketplacesIdOrdersGet(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Detay : Firmaya ait pazaryeri entegrasyon bilgilerine bu uç ile ulaşabilirsiniz
         * @summary Pazaryeri entegrasyon listesine bu uç ile erişebilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MarketplacesPlatformsGet(options?: any): AxiosPromise<Array<MarketPlacePlatform>> {
            return MarketPlaceApiFp(configuration).v1MarketplacesPlatformsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Detay : Firmaya ait pazaryeri entegrasyon bilgilerine bu uç ile ulaşabilirsiniz
         * @summary Pazaryeri entegrasyon bilgilerine bu uç ile erişebilirsiniz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MarketplacesSettingsGet(options?: any): AxiosPromise<Array<MarketPlaceSetting>> {
            return MarketPlaceApiFp(configuration).v1MarketplacesSettingsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pazaryeri entegrasyonuna ait bilgiyi silmek için bu uç kullanılabilir.
         * @param {string} id Silinmesini istediğiniz kaydın ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MarketplacesSettingsIdDelete(requestParameters: V1MarketplacesSettingsIdDeleteRequest, options?: any): AxiosPromise<void> {
            return MarketPlaceApiFp(configuration).v1MarketplacesSettingsIdDelete(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Detay : Firmaya ait seçilen pazaryeri entegrasyon bilgilerini güncellemek için bu uç kullanılabilir.
         * @summary Pazaryeri entegrasyon bilgilerini günceller
         * @param {string} id Kayıt id
         * @param {MarketPlaceSettingDto} marketPlaceSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MarketplacesSettingsIdPut(requestParameters: V1MarketplacesSettingsIdPutRequest, options?: any): AxiosPromise<void> {
            return MarketPlaceApiFp(configuration).v1MarketplacesSettingsIdPut(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Detay : Firmaya ait seçilen pazaryeri entegrasyon bilgilerini güncellemek için bu uç kullanılabilir.
         * @summary Pazaryeri entegrasyon bilgilerini günceller
         * @param {MarketPlaceSettingDto} marketPlaceSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MarketplacesSettingsPost(requestParameters: V1MarketplacesSettingsPostRequest, options?: any): AxiosPromise<void> {
            return MarketPlaceApiFp(configuration).v1MarketplacesSettingsPost(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MarketPlaceApi - object-oriented interface
 * @export
 * @class MarketPlaceApi
 * @extends {BaseAPI}
 */
export class MarketPlaceApi extends BaseAPI {
    /**
     * Detay : Firmaya ait seçilen pazaryerindeki siparişleri listelemek için bu uç kullanılabilir.
     * @summary Belirtilen pazaryerindeki siparişleri listeler
     * @param {string} id Siparişleri çağırmak istediğiniz pazaryeri entegrasyon id değeri
     * @param {string} beginDate Sipariş tarihini başlangıç
     * @param {string} endDate Sipariş tarihini bitiş
     * @param {number} page Kaçıncı sayfa
     * @param {number} pageSize Sayfada gösterilecek kayıt sayısı
     * @param {string} [orderStatus] Pazaryerindeki sipariş durumu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketPlaceApi
     */
    public v1MarketplacesIdOrdersGet(requestParameters: V1MarketplacesIdOrdersGetRequest, options?: any) {
        return MarketPlaceApiFp(this.configuration).v1MarketplacesIdOrdersGet(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Detay : Firmaya ait pazaryeri entegrasyon bilgilerine bu uç ile ulaşabilirsiniz
     * @summary Pazaryeri entegrasyon listesine bu uç ile erişebilirsiniz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketPlaceApi
     */
    public v1MarketplacesPlatformsGet(options?: any) {
        return MarketPlaceApiFp(this.configuration).v1MarketplacesPlatformsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Detay : Firmaya ait pazaryeri entegrasyon bilgilerine bu uç ile ulaşabilirsiniz
     * @summary Pazaryeri entegrasyon bilgilerine bu uç ile erişebilirsiniz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketPlaceApi
     */
    public v1MarketplacesSettingsGet(options?: any) {
        return MarketPlaceApiFp(this.configuration).v1MarketplacesSettingsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pazaryeri entegrasyonuna ait bilgiyi silmek için bu uç kullanılabilir.
     * @param {string} id Silinmesini istediğiniz kaydın ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketPlaceApi
     */
    public v1MarketplacesSettingsIdDelete(requestParameters: V1MarketplacesSettingsIdDeleteRequest, options?: any) {
        return MarketPlaceApiFp(this.configuration).v1MarketplacesSettingsIdDelete(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Detay : Firmaya ait seçilen pazaryeri entegrasyon bilgilerini güncellemek için bu uç kullanılabilir.
     * @summary Pazaryeri entegrasyon bilgilerini günceller
     * @param {string} id Kayıt id
     * @param {MarketPlaceSettingDto} marketPlaceSettingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketPlaceApi
     */
    public v1MarketplacesSettingsIdPut(requestParameters: V1MarketplacesSettingsIdPutRequest, options?: any) {
        return MarketPlaceApiFp(this.configuration).v1MarketplacesSettingsIdPut(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Detay : Firmaya ait seçilen pazaryeri entegrasyon bilgilerini güncellemek için bu uç kullanılabilir.
     * @summary Pazaryeri entegrasyon bilgilerini günceller
     * @param {MarketPlaceSettingDto} marketPlaceSettingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketPlaceApi
     */
    public v1MarketplacesSettingsPost(requestParameters: V1MarketplacesSettingsPostRequest, options?: any) {
        return MarketPlaceApiFp(this.configuration).v1MarketplacesSettingsPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

}
    export interface V1MarketplacesIdOrdersGetRequest {
    /**
     * Siparişleri çağırmak istediğiniz pazaryeri entegrasyon id değeri
     */
    id: string;
    /**
     * Sipariş tarihini başlangıç
     */
    beginDate: string;
    /**
     * Sipariş tarihini bitiş
     */
    endDate: string;
    /**
     * Kaçıncı sayfa
     */
    page: number;
    /**
     * Sayfada gösterilecek kayıt sayısı
     */
    pageSize: number;
    /**
     * Pazaryerindeki sipariş durumu
     */
    orderStatus?: string;
    }
    export interface V1MarketplacesSettingsIdDeleteRequest {
    /**
     * Silinmesini istediğiniz kaydın ID
     */
    id: string;
    }
    export interface V1MarketplacesSettingsIdPutRequest {
    /**
     * Kayıt id
     */
    id: string;
    /**
     * 
     */
    marketPlaceSettingDto: MarketPlaceSettingDto;
    }
    export interface V1MarketplacesSettingsPostRequest {
    /**
     * 
     */
    marketPlaceSettingDto: MarketPlaceSettingDto;
    }
