import { Attachment } from '@/apis/einvoice/interfaces';
import { DocumentInfo, ErrorWrapper } from '@/components';
import { Close, Download } from '@/components/Buttons';
import { Loading } from '@/components/TableBody';
import { useFileDownload } from '@/hooks';
import { DocumentModal } from '@/interfaces';
import { faHistory } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import _ from 'lodash';
import React, { memo } from 'react';
import intl from 'react-intl-universal';

interface DocumentAttachmentRequest {
  /**
   * UUID
   */
  uuid: string;
  /**
   * Attachment index
   */
  index: number;
}

export const DocumentAttachmentsModal = memo(
  ({ closeModal, visible, documentNumber, uuid, data, loading, error, downloadFn }: DocumentModal<Array<Attachment>> & { downloadFn }) => {
    const file = useFileDownload<DocumentAttachmentRequest>(downloadFn);

    const tableLoading = {
      spinning: loading || file.loading,
      indicator: <Loading tip={file.loading ? 'Dosya hazırlanıyor, lütfen bekleyiniz.!' : undefined} />
    };

    return (
      <Modal
        centered
        title={
          <span>
            <FontAwesomeIcon icon={faHistory} /> {intl.get('BELGE_EKLERI')}
          </span>
        }
        onCancel={closeModal}
        width={1024}
        visible={visible}
        footer={[<Close key="close-button" onClick={closeModal} />]}
      >
        <DocumentInfo documentNumber={documentNumber || ''} uuid={uuid || ''} />

        <Table
          showHeader={data !== undefined}
          components={ErrorWrapper({ error })}
          style={{ marginTop: '10px' }}
          scroll={{ x: true, y: 512 }}
          bordered
          loading={tableLoading}
          pagination={false}
          dataSource={data}
          rowKey={(record) => record.index}
        >
          <Table.Column width={50} key="index" title={intl.get('SIRA')} dataIndex="index" />
          <Table.Column key="fileName" title={intl.get('DOSYA_ADI')} dataIndex="fileName" />
          <Table.Column key="mimeCode" title={intl.get('DOSYA_TIPI')} dataIndex="mimeCode" />
          <Table.Column
            key="file"
            title={intl.get('INDIR')}
            width={90}
            render={(text, record: Attachment) => {
              return <Download onClick={() => file.download({ uuid: uuid || '', index: record.index })} />;
            }}
          />
        </Table>
      </Modal>
    );
  },
  (prev, next) => _.isEqual(prev.data, next.data) && prev.visible === next.visible && prev.loading === next.loading
);

export default DocumentAttachmentsModal;
