import { OutgoingInvoicesApiFactory, V1OutgoingInvoicesLastGetRequest } from '@/apis/einvoice';
import { OutgoingInvoice, RecordStatus } from '@/apis/einvoice/interfaces';
import { PageStyle } from '@/constants/page';
import { DocumentPreviewModal, useDocumentModal, useGetPageData } from '@/hooks';
import { BlobResponse } from '@/services/api/constants';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Skeleton, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { useImperativeHandle } from 'react';
import { CardHeader, UncontrolledAlert } from 'reactstrap';
import OutgoingInvoiceListItem from './OutgoingInvoiceListItem';

const api = OutgoingInvoicesApiFactory();
const apiWithBlobResponse = OutgoingInvoicesApiFactory(BlobResponse);

const FailedInvoices = React.forwardRef((_, ref) => {
  const invoicePreview = useDocumentModal<string>(api.v1OutgoingInvoicesUuidHtmlGet);

  const requestParameters: V1OutgoingInvoicesLastGetRequest = {
    archived: false,
    invoiceStatus: RecordStatus.Error
  };

  const { execute, loading, data } = useGetPageData<Array<OutgoingInvoice>>(api.v1OutgoingInvoicesLastGet, requestParameters, true);

  useImperativeHandle(ref, () => ({
    refresh() {
      execute();
    }
  }));

  if (loading) {
    return <Skeleton active loading={loading} />;
  }

  return (
    <div className="card h-100 kit__utils__cardMarked kit__utils__cardMarked--danger">
      <DocumentPreviewModal
        sendMailApi={api.v1OutgoingInvoicesEmailSendPost}
        {...invoicePreview.props}
        pdfDownloadApi={apiWithBlobResponse.v1OutgoingInvoicesUuidPdfGet}
        setPrintedApi={api.v1OutgoingInvoicesBulkOperationPut}
      />

      <CardHeader className={PageStyle.card__header}>
        <div className="d-flex align-items-center mr-auto">
          <FontAwesomeIcon icon={faPencil} style={{ marginRight: '10px' }} color="#0ab1fc" />
          <span className="font-weight-bold mr-3">Hatalı Faturalar</span>
        </div>
      </CardHeader>
      <div className={classNames('card-body p-0 overflow-auto', { 'align-items-center d-flex': (data || []).length === 0 })}>
        {data && data.length > 0 && (
          <>
            <Alert message="Hata almış son 10 e-faturanız bu alanda görünür" banner />

            <ul className="list-unstyled">
              {data.map((item, index) => {
                return (
                  <Tooltip
                    getPopupContainer={() => window.document.body}
                    placement="topRight"
                    title={`${item.outgoingEnvelope ? `${item.outgoingEnvelope?.code}:${item.outgoingEnvelope?.description}` : `${item.outgoingStatus}`}`}
                    key={item.id}
                    trigger="hover"
                  >
                    <OutgoingInvoiceListItem key={item.id} item={item} onClickRow={(item) => invoicePreview.get(item.id, item.documentNumber)} />{' '}
                  </Tooltip>
                );
              })}
            </ul>
          </>
        )}

        {(data || []).length === 0 && (
          <UncontrolledAlert color="warning" className="w-100" fade={false} style={{ textAlign: 'center', margin: '10px 20px' }}>
            Kayıt yok
          </UncontrolledAlert>
        )}
      </div>
    </div>
  );
});

export default FailedInvoices;
