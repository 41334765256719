import { AnyType } from '@/type';
import { DownloadOutlined } from '@ant-design/icons';
import { faFileUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Upload } from 'antd';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import classNames from 'classnames';
import FileSaver from 'file-saver';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import { DangerToastify } from '../Toastify';
import styles from './style.module.scss';

interface Props {
  onLoad?: (file: AnyType, content: string | ArrayBuffer | null) => void;
  extensions?: string;
  readType?: 'asArrayBuffer' | 'asBinaryString' | 'asBase64';
  title?: string;
  onLoadAfterClear?: boolean;
  defaultFiles?: UploadFile<RcFile>[];
  hidden?: boolean;
}

const SingleUploader = forwardRef(({ onLoad, readType = 'asBinaryString', onLoadAfterClear, title, extensions, defaultFiles, hidden }: Props, ref) => {
  const [files, setFiles] = useState(defaultFiles || []);
  const button = useRef<AnyType>(null);

  useImperativeHandle(ref, () => ({
    clearFiles: () => {
      setFiles([]);
    },
    showSelectDialog: () => {
      button.current.click();
    }
  }));

  useEffect(() => {
    if (files.length > 0 && files[0].status !== 'done') {
      setFiles([Object.assign(files[0], { status: 'done' })]);
    }
  }, [files]);

  const handleChange = (info) => {
    if (extensions) {
      const extension = (info.file.name as string).substr(info.file.name.lastIndexOf('.'));

      if (extensions.indexOf(extension) === -1) {
        DangerToastify(`Yalnızca ${extensions} tipinde dosyalar yüklenebilir,(${extension}) tipi yüklenemez!`);
        return false;
      }
    }
    setFiles(info.fileList.slice(-1));

    if (info.fileList.length === 0) {
      onLoad && onLoad(null, null);
      return false;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      onLoad &&
        onLoad(info.file, readType === 'asBase64' ? (e.target as AnyType).result.replace('data:', '').replace(/^.+,/, '') : (e.target as AnyType).result);

      if (onLoadAfterClear) {
        setFiles([]);
      }
    };

    switch (readType) {
      case 'asArrayBuffer':
        reader.readAsArrayBuffer(info.file);
        break;
      case 'asBase64':
        reader.readAsDataURL(info.file);
        break;
      case 'asBinaryString':
        reader.readAsBinaryString(info.file);
        break;
    }
  };

  return (
    <div className={styles.single__uploader_container} style={{ display: hidden === true ? 'none' : 'normal' }}>
      {title && <div style={{ marginBottom: '10px' }}> {title} </div>}
      <div className={styles.single__uploader} onClick={() => button.current.click()}>
        <div className={classNames(styles.single__uploader__input, 'ant-input')}>
          <span>{files.length !== 0 ? `${files.length} dosya seçildi` : 'Dosya seçilmedi'}</span>
        </div>

        <div className={styles.single__uploader__button}>
          <Button size={'md'} color={'info'} className="btn-labeled" type="button">
            <FontAwesomeIcon icon={faFileUpload} className={'btn-label'} />
            <span>Dosya Seç...</span>
          </Button>
        </div>
      </div>
      <Upload
        accept={extensions}
        className={styles.single__uploader__antd}
        beforeUpload={() => false}
        showUploadList={{
          showDownloadIcon: true,
          downloadIcon: <DownloadOutlined />
        }}
        onDownload={(a) => {
          a.originFileObj && FileSaver.saveAs(a.originFileObj, a.fileName);
        }}
        onChange={handleChange}
        listType="picture"
        fileList={files}
        defaultFileList={files}
      >
        <button style={{ display: 'none' }} ref={button} type="button" />
      </Upload>
    </div>
  );
});

export default SingleUploader;
