import { V1DefinitionsMailingEmailSettingsPutRequest } from '@/apis/earchive';
import { MailSettingDto } from '@/apis/earchive/interfaces';
import { InfoToastify } from '@/components';
import { Save } from '@/components/Buttons';
import { ErrorViewer } from '@/components/TableBody';
import { PageStyle } from '@/constants/page';
import { useApi } from '@/hooks';
import { AnyType } from '@/type';
import { faMailbox } from '@fortawesome/pro-light-svg-icons';
import { faHeading } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Skeleton } from 'antd';
import { AxiosPromise } from 'axios';
import { Formik, FormikProps } from 'formik';
import { Checkbox, Form, FormItem, Input } from 'formik-antd';
import React, { memo, useEffect, useRef } from 'react';
import intl from 'react-intl-universal';
import { Card, CardBody, CardHeader } from 'reactstrap';
import * as Yup from 'yup';

const MailSettingScheme = Yup.object().shape<MailSettingDto>({
  title: Yup.string().nullable().required()
});

export const MailSetting = memo(
  ({
    getMail,
    put
  }: {
    getMail: () => AxiosPromise<MailSettingDto>;
    put: (requestParameters: V1DefinitionsMailingEmailSettingsPutRequest) => AxiosPromise<void>;
  }) => {
    let form = (useRef(null) as unknown) as FormikProps<V1DefinitionsMailingEmailSettingsPutRequest>;

    /**
     * maileri getirmek için kullanılan fonksiypn
     */
    const getMailRequest = useApi<MailSettingDto, Array<AnyType>>({
      asyncFunction: getMail
    });

    /**
     * Update işlemi için kullanılan fonksiyon
     */
    const putRequest = useApi<void, V1DefinitionsMailingEmailSettingsPutRequest>({
      asyncFunction: put,
      successCallback: (_, request) => {
        if (request) {
          getMailRequest.setData(request.mailSettingDto);
          InfoToastify(intl.get('MAIL_AYARLARINIZ_BASARIYLA_GUNCELLENDI'));
        }
      }
    });

    useEffect(() => {
      getMailRequest.call([]);
    }, []);

    return (
      <>
        <Card>
          <CardHeader className={PageStyle.card__header}>
            <div className="justify-content-center mr-auto utils_custom_title d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block">
              {<FontAwesomeIcon icon={faMailbox} color="#0ab1fc" style={{ marginRight: '10px' }} />}
              <b>{`Mail Ayarları`}</b>
            </div>
            <Save onClick={() => form.isValid && form.handleSubmit()} />
          </CardHeader>
          <CardBody className={PageStyle.virtualized__card__body}>
            <Skeleton active loading={getMailRequest.loading || putRequest.loading}>
              <Formik<MailSettingDto>
                validateOnBlur={false}
                innerRef={(instance) => (form = instance)}
                onSubmit={(values) => {
                  if (form.isValid) putRequest.call({ mailSettingDto: values });
                }}
                validationSchema={MailSettingScheme}
                enableReinitialize={true}
                initialValues={
                  getMailRequest.data
                    ? getMailRequest.data
                    : {
                        title: '',
                        isAuto: false
                      }
                }
              >
                <Form layout="vertical">
                  <Row>
                    <Col sm={24} xs={24}>
                      <FormItem name="title" label={intl.get('MAILDE_GORULECEK_ISIM')} hasFeedback required>
                        <Input prefix={<FontAwesomeIcon icon={faHeading} />} name="title" placeholder={intl.get('VKN_TCKN')} tabIndex={0} />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={24} sm={24}>
                      <FormItem name="isAuto" required>
                        <Checkbox name="isAuto" tabIndex={0}>
                          {intl.get('MAIL_OTOMATIK_GONDERILSIN')}
                        </Checkbox>
                      </FormItem>
                    </Col>
                  </Row>
                </Form>
              </Formik>
            </Skeleton>
          </CardBody>
        </Card>
        {getMailRequest.error || (putRequest.error && <ErrorViewer error={getMailRequest.error || putRequest.error} />)}
      </>
    );
  }
);

export default MailSetting;
