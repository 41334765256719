/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PageStyle } from '@/constants/page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { Doughnut as ReactDoughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, UncontrolledAlert } from 'reactstrap';
import style from './style.module.scss';

interface DoughnutData {
  url?: string;
  value: number;
  label: string;
  color: string;
}

export const Doughnut = ({ data, title, subTitle, loading, icon }: { data: DoughnutData[]; title: string; subTitle?: string; loading: boolean; icon: any }) => {
  if (loading) {
    return <Skeleton active loading={loading} />;
  }

  const tooltipRef = React.createRef<HTMLDivElement>();
  const myRef = React.createRef() as any;

  const options = {
    animation: false,
    responsive: true,
    cutoutPercentage: 70,
    legend: {
      display: false
    },
    tooltips: {
      enabled: false,
      custom: (tooltipData) => {
        const tooltipEl = tooltipRef.current as any;
        tooltipEl.style.opacity = 1;
        if (tooltipData.opacity === 0) {
          tooltipEl.innerHTML = '';
        }
      },
      callbacks: {
        label: (tooltipItem, itemData) => {
          const tooltipEl = tooltipRef.current as any;

          const dataset = itemData.datasets[0];

          const total = dataset.data.reduce((total, item) => {
            return (total += item);
          }, 0) as number;

          const value = dataset.data[tooltipItem.index] as number;
          const percent = ((100 * value) / total).toFixed(2);
          tooltipEl.innerHTML = `% ${percent}`;
        }
      }
    }
  };
  const [legend, setLegend] = useState(undefined);
  const [tooltip, setTooltip] = useState('');

  const generateLegend = () => {
    if (!myRef) return null;
    if (!myRef.current) return null;

    return myRef.current.chartInstance.generateLegend();
  };

  useEffect(() => {
    if (legend === undefined) {
      const leg = generateLegend();
      setLegend(leg);
    }
  }, []);

  const statsData = {
    labels: data.map((x) => x.label),
    datasets: [
      {
        data: data.map((x) => x.value),
        backgroundColor: data.map((x) => x.color),
        // borderColor: '#fff',
        borderWidth: 2,
        hoverBorderWidth: 0,
        borderAlign: 'inner'
      }
    ]
  };

  const generateLegens: any = () => {
    const dataSet = statsData.datasets[0];
    const labels = statsData.labels;

    const total = dataSet.data.reduce((total, item) => {
      return (total += item);
    }, 0) as number;

    const legends = dataSet.data.map((item, index) => {
      const value = dataSet.data[index];
      const color = dataSet.backgroundColor[index];
      const label = labels[index];
      const percent = ((100 * value) / total).toFixed(2);

      return (
        <Link
          key={index}
          className="legend d-flex align-items-center flex-nowrap"
          onMouseEnter={() => setTooltip(`% ${percent}`)}
          onMouseLeave={() => setTooltip(``)}
          to={data[index].url || '#'}
        >
          <div className="air__utils__tablet mr-3" style={{ backgroundColor: color }} />
          {label} - ( {value} )
        </Link>
      );
    });

    return legends;
  };

  return (
    <Card className="h-100">
      <CardHeader className={PageStyle.card__header}>
        <div style={{ fontSize: '1rem' }}>
          <FontAwesomeIcon icon={icon} style={{ marginRight: '10px' }} color="#0ab1fc" />
          <span className="font-weight-bold mr-3">{title}</span>
          {subTitle && <span className="text-gray-6">( {subTitle} )</span>}
        </div>
      </CardHeader>
      <CardBody style={{ padding: '10px 20px' }} className={'overflow-auto align-items-center d-flex'}>
        {data.length !== 0 && (
          <div className="d-flex flex-wrap align-items-center w-100" style={{ justifyContent: 'center' }}>
            <div className="mr-3 mt-3 mb-3 position-relative">
              <ReactDoughnut ref={myRef} data={statsData} options={options} width={140} height={140} />
              <div className={`${style.tooltip} text-gray-5 font-size-18`} ref={tooltipRef}>
                {tooltip}
              </div>
            </div>
            <div className="air__c9__chartLegend flex-shrink-0">{generateLegens()}</div>
          </div>
        )}

        {data.length === 0 && (
          <UncontrolledAlert color="warning" className="w-100" fade={false} style={{ textAlign: 'center' }}>
            Kayıt olmadığı için grafik üretilmedi
          </UncontrolledAlert>
        )}
      </CardBody>
    </Card>
  );
};

export default Doughnut;
